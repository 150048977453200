import React from "react";

const WhiteCircleLoader = (props) => {
  const {invert = false} = props;
  return (
    <img src={`/assets/images/${invert ? "black" : "white"}-loader-small.svg`} height={20} width={20} />
  );
};

export default WhiteCircleLoader;
