import React, { Fragment, useEffect, useState } from "react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../global/loading";
import {
  sortByField,
  IsArrayNotEmpty,
  IsObjectChecker,
  groupArrayByKey,
  IsTokenChecker,
  getResolvedLanguage,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
  compareString,
} from "../../utils/helper";
import ComponentListRenderer, {
  ContentAndGridComponentListRenderer,
} from "./ContentBlockRenderSwitch";
import constantsVariable from "../../utils/constantsVariable";
import FitchRating from "./fitchRating";

const LandingPageCtaContentBlock = (props) => {
  const { slug, RoleCBPrivileges = {} } = props;
  const [loader, setLoader] = useState(false);
  const { component_listing_dropdowns, component_accordion_en_path } =
    constantsVariable;

  const { accounts } = useMsal();
  const tokenId = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const { roleCBDecisionMaker = false } = RoleCBPrivileges;

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const [allData, setAllData] = useState(null);
  const [componentList, setComponentList] = useState([]);

  const [individualData, setIndividualData] = useState([]);
  const [individualComponentList, setIndividualComponentList] = useState([]);

  const [fitchRating, setFitchRating] = useState([]);
  const [fitchRatingCTA, setfitchRatingCTA] = useState([]);
  const fitchAccordion = "fitch-rating";

  const getDDContractPerformMultiContract = async (
    tokenId,
    accounts,
    urlArray
  ) => {
    var resp = await Promise.all(
      urlArray.map(async (url) => {
        return await cmsDataCommonMethod(tokenId, accounts, url)
          .then((res) => res.data)
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    if (resp.length > 0) {
      const adata = _.get(resp, "0.data", []),
        bdata = _.get(resp, "1.data", []);

      const newData = adata.map((li) => {
        try {
          const adataid = _.get(li, "component_listings.id", "adataid");
          const compData = bdata.find((cli) => {
            const bdataid = _.get(cli, "component_listings.id", "bdataid");
            return adataid == bdataid;
          });
          let newLi = { ...li };
          _.set(newLi, "component_listings.content_block", compData || {});
          return newLi;
        } catch (e) {
          HandleErrorTrackAndToast(e, true);
          return li;
        }
      });

      const indData = bdata.filter((li) => {
        const bdataid = _.get(li, "component_listings.id", "bdataid");
        const actData = adata.find((ali) => {
          const adataid = _.get(ali, "component_listings.id", "adataid");
          return adataid == bdataid;
        });
        return actData === undefined;
      });
      return { newData, indData };
    }
  };

  useEffect(() => {
    setLoader(true);
    if (IsTokenChecker(tokenId)) {
      fetchGoverningDocuments();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const GetGroupedData = (tempData = []) => {
    return groupArrayByKey(tempData, component_accordion_en_path);
  };

  const GetComponentArr = (tempData = []) => {
    let groupData = safeArrayCheck(
      tempData.filter((a, b, c) => UniqueObjectArray(a, b, c))
    );
    return sortByField(
      groupData.map((list) => _.get(list, "component_listings", {}))
    );
  };

  const fetchGoverningDocuments = async () => {
    const fetchApiUrl = [];
    const action_url = `call_to_actions?select=*,component_listings!inner(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next),page_listings!inner(id,title_en,slug)&page_listings.slug=eq.${slug}`;
    const block_url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;

    fetchApiUrl.push(action_url);
    fetchApiUrl.push(block_url);

    if (IsTokenChecker(tokenId) && fetchApiUrl != "") {
      setLoader(true);
      await getDDContractPerformMultiContract(tokenId, accounts, fetchApiUrl)
        .then((resp) => {
          setLoader(false);
          const { newData: individualComps = [], indData = [] } = resp;

          const newData = individualComps.filter(
            (li) =>
              !compareString(li?.component_listings?.css_class, fitchAccordion)
          );

          let tempData = GetGroupedData(newData);
          setAllData(IsObjectChecker(tempData) ? tempData : {});
          setComponentList(GetComponentArr(newData));

          let tempData2 = GetGroupedData(indData);
          setIndividualData(IsObjectChecker(tempData2) ? tempData2 : {});
          setIndividualComponentList(GetComponentArr(indData));

          if (roleCBDecisionMaker) {
            const fitchData = individualComps.filter((li) =>
              compareString(li?.component_listings?.css_class, fitchAccordion)
            );
            let tempData3 = GetGroupedData(fitchData);
            setfitchRatingCTA(IsObjectChecker(tempData3) ? tempData3 : {});
            setFitchRating(GetComponentArr(fitchData));
          }
        })
        .catch((error) => {
          setAllData([]);
          setComponentList([]);
          setIndividualData([]);
          setIndividualComponentList([]);
          setLoader(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };

  const GridListing = (props) => {
    const { data = [] } = props;
    return IsArrayNotEmpty(data) ? (
      <>
        {data.map((item, index) => {
          const img = _.get(item, ["feature_image_url"], "");
          const url = _.get(item, ["url"], "");
          const title = _.get(item, ["title_" + resolvedLanguage], "");
          const button = _.get(item, ["button_text_" + resolvedLanguage], "");
          const open_new_window = _.get(item, ["open_new_window"], false);
          // const new_window = button === "" ? false : open_new_window;
          const internal_url = `${window.location.pathname}${url}`;
          return (
            <div className="col-12 col-lg-4" key={index}>
              <div className="card card-shadow mb-3 mb-lg-5">
                {img && <img src={img} className="card-img-top" alt="..." />}
                <div className="card-body pt-0 px-0">
                  <div className="row">
                    <div className="col-11 ms-1">
                      <ul className="list-group text-center">
                        <li className="list-group-item border-0 pt-4">
                          <Link
                            to={
                              open_new_window ? { pathname: url } : internal_url
                            }
                            target={open_new_window ? "_blank" : "_self"}
                            className="text-18 d-block "
                          >
                            {" "}
                            {title}{" "}
                          </Link>
                          <div className={"mt-2"}>
                            <Link
                              to={
                                open_new_window
                                  ? { pathname: url }
                                  : internal_url
                              }
                              target={open_new_window ? "_blank" : "_self"}
                              className={`
                                ${
                                  button
                                    ? "btn btn-primary"
                                    : "red_color d-block"
                                }
                                  ${img !== null ? "mt-" : ""} `}
                            >
                              {button && <Fragment>{button}</Fragment>}
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    ) : (
      <></>
    );
  };

  const RenderPropertiesGroup = [
    { AdjustmentClassName: `mt-5 mt-lg-0 mb-3 mb-lg-5` },
  ];
  const RenderProperties = { AdjustmentClassName: `mt-5 mt-lg-0` };
  return (
    <>
      {loader && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <ComponentListRenderer
        ComponentList={individualComponentList}
        GroupedData={individualData}
        RenderPropertiesGroup={RenderPropertiesGroup}
        DefaultCssClass={component_listing_dropdowns?.blueTitleBox}
      />
      <ContentAndGridComponentListRenderer
        ContentPath={"content_block.content_"}
        ComponentList={componentList}
        GroupedData={allData}
        RenderProperties={RenderProperties}
        DefaultCssClass={component_listing_dropdowns?.plainText}
        CallBackContent={GridListing}
      />
      <FitchRating
        roleCBDecisionMaker={roleCBDecisionMaker}
        fitchRating={IsArrayNotEmpty(fitchRating) ? fitchRating[0] : {}}
        fitchRatingCTA={fitchRatingCTA}
      />
    </>
  );
};
export default LandingPageCtaContentBlock;
