import React, { useEffect, useState } from "react";
import BreadCrumb from "../global/breadCrumb";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Loading from "../global/loading";
import {
  sortByField,
  groupArrayByKey,
  IsObjectChecker,
  IsTokenChecker,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import constantsVariable from "../../utils/constantsVariable";
import ComponentListRenderer from "../global/ContentBlockRenderSwitch";

const PrivacyPolicyDetail = (props) => {
  const [loading, setLoading] = useState(true);

  const slug = "privacy-policy";
  const { component_accordion_en_path, component_listing_dropdowns } =
    constantsVariable;

  const [groupedDataByAccordion, setGroupedDataByAccordion] = useState({});
  const [componentList, setComponentList] = useState([]);

  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const getContentBlocks = async () => {
    const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));

        const groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        const componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );
        setComponentList(componentArr);

        const groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        setGroupedDataByAccordion(
          IsObjectChecker(groupedData) ? groupedData : {}
        );
      })
      // eslint-disable-next-line
      .catch((error) => {
        setGroupedDataByAccordion({});
        setComponentList([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getContentBlocks();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const RenderProperties = {
    ExcludeTitle: true,
  };
  const DefaultCssClass = component_listing_dropdowns?.plainText;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="col-12">
            <ComponentListRenderer
              ComponentList={componentList}
              GroupedData={groupedDataByAccordion}
              RenderProperties={RenderProperties}
              DefaultCssClass={DefaultCssClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyDetail;
