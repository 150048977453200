import _ from "lodash";
import {
  IsObjectChecker,
  IsStringChecker,
  safeArrayCheck,
} from "../../../utils/helper";

const initialState = [];

const toastMsg = (state = initialState, action) => {
  switch (action.type) {
    case "REPLACE_TOAST_MSG":
      return action.payload;
    case "SEND_TOAST_MSG":
      const sendPayload = action?.payload;
      if (IsObjectChecker(sendPayload) && !_.isEmpty(sendPayload)) {
        const sendToast = safeArrayCheck(state);
        sendToast.push(sendPayload);
        return sendToast;
      } else {
        return state;
      }
    case "DELETE_TOAST_MSG":
      const delPayload = action?.payload;
      if (IsStringChecker(delPayload)) {
        const delToast = safeArrayCheck(state).filter(
          (li) => li.id !== delPayload
        );
        return delToast;
      } else {
        return state;
      }
    case "EMPTY_TOAST_MSG":
      return initialState;
    default:
      return state;
  }
};

export default toastMsg;
