import React, { useEffect } from "react";
import Body from "./global/body";
import Header from "./global/header";
import Footer from "./global/footer";
import CurrentLocation from "../components/global/currentLocation";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cmsDataCommonMethod } from "../services/gbsData";
import { IsTokenChecker } from "../utils/helper";
import { useMsal } from "@azure/msal-react";
import ContentModifier from "./global/contentModifier";
const PageLayout = (props) => {
  // console.log({PageLayout:props});
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);
  // Splash page start
  const [showModal, setShowModal] = useState(false);
  const [splashData, setSplashData] = useState({});
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      if (localStorage.getItem("splash_screen") === null) {
        getSplashPageData();
      }
    }
  }, [idToken]);

  const getSplashPageData = async () => {
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `call_to_actions?select=*,is_active,page_listings!inner(slug)&page_listings.slug=eq.splash-page&is_active=eq.true&order=order_by.asc`
    )
      .then((resp) => {
        
        if (resp?.data?.data?.length > 0) {
          setSplashData(resp?.data?.data[0]);
          setShowModal(true);         
          localStorage.setItem("splash_screen", true);
        }
        else{
          localStorage.setItem("splash_screen", false);
        }
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
  }

  useEffect(()=>{
    document.getElementById("body-container")?.addEventListener("click", checkVideos , false);
    return () => {
      document.getElementById("body-container")?.addEventListener("click", checkVideos , false);
    }
  });
  const checkVideos = (e) =>{
    if(e.target.classList.value.includes('video-iframe-container-search')){
      e.target.childNodes.forEach((el)=>{
        if(el.tagName == "IFRAME"){
          if(el.src.indexOf('players.brightcove.net')>0){
            e.stopImmediatePropagation();
            e.preventDefault();
            window.open(el.src,"_blank");  
          }             
        }
      });
    }
    else if(e.target.classList.value.includes('video-iframe')){
      e.target.childNodes.forEach((el)=>{
        if(el.tagName == "IFRAME"){
          if(el.src.indexOf('players.brightcove.net')>0){
            e.stopImmediatePropagation();
            e.preventDefault();
            window.open(el.src,"_blank");  
          }             
        }
      });
    }
    
  }

// Splash page end
  return (
    <div>
      <Header {...props} />
      <main>
        <section>
          <div id="body-container" className="container p-2 p-sm-4">
            <Body body={props.body} {...props} />
          </div>
        </section>
      </main>
      <Footer {...props} />
      {visible ? (
        <>
          <div className="back_to_top d-none d-lg-block">
            <a href="#" className="fw-bold">
              {moreLangs[i18n.language]?.home_page?.back_to_top}{" "}
              <i
                className="fa fa-long-arrow-up position-relative t_1"
                aria-hidden="true"
              ></i>{" "}
            </a>
          </div>
          <div className="back_to_top d-block d-sm-block d-md-none d-lg-none">
            <a href="#" className="fw-bold">
              {moreLangs[i18n.language]?.home_page?.top}{" "}
              <i
                className="fa fa-long-arrow-up position-relative t_1"
                aria-hidden="true"
              ></i>{" "}
            </a>
          </div>
        </>
      ) : null}

      <Modal
        show={showModal}
        id={"splash-screen-modal"}
        aria-labelledby={"splash-screen-modal"}
        dialogClassName="modal-lg splash-screen-modal"
      >
        <Modal.Header
          className={" blue_bg "}
        >
          <h4 className="modal-title white_color">{splashData['title_' + i18n.language]}</h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
          </button>
        </Modal.Header>
        <Modal.Body className={"modalBodyImage"}>
          <div
            style={{
              maxWidth: "960px",
              position: "relative",
              margin: "0 auto",
            }}
            id="hide_video"
          >
            <ContentModifier str={splashData['content_' + i18n.language]} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PageLayout;
