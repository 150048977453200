import React, { useState, useEffect, Fragment, useRef } from "react";
import Loading from "../../global/loading";
import MultiDropDown from "../../selectBox/multiDropDown";
// import DatePicker from "react-datepicker";
import { cmsDataCommonMethod, gbsDataCommonMethod } from "../../../services/gbsData";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsFunctionChecker,
  IsTokenChecker,
  safeArrayCheck,
  IsArrayNotEmpty,
  IsStringChecker
} from "../../../utils/helper";
// import Moment from "react-moment";
// import { Accordion } from "react-bootstrap";
// import DataGridPagination from "../../global/dataGridPagination";
import CurrentLocation from "../../global/currentLocation";
import { useTranslation } from "react-i18next";
import WhiteCircleLoader from "../../whiteCircleLoader";
// import DatePickerComp from "../../global/datepicker";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import DataGridPagination from "../../global/dataGridPagination";
import { Accordion } from "react-bootstrap";
import { ImpersonationAccountsSwap } from "../../../permissionWrapper";

const PrintableReports = (props) => {
  const { roleAgScientist = false, } = props;
  const asyncTypeaheadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  // const personId = accounts[0].idTokenClaims.gbsPersonId;
  const tokenId = useSelector((state) => state.reducerToken);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  const [CropYears, setCropYears] = useState([]);
  const [CY_MultiValue, setCY_MultiValue] = useState({});
  const [defCropYearArr, setdefCropYearArr] = useState("");
  const [displayAccordian, setDisplayAccordian] = useState(false);

  const [contractNumbers, setContractNumbers] = useState([]);
  const [defContracts, setdefContracts] = useState("");
  const [CN_MultiValue, setCN_MultiValue] = useState({});
  const [, setDisabled] = useState(false);
  const [multiSelectReset, setMultiSelectReset] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [noResultsFound, setNoResultsFound] = useState(false);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fetchCropYear();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
  }, [tokenId]);

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fetchContracts();
    }
  }, [props?.GBSContracts]);

  const getResults = async (query) => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      msal_accounts,
      `get-azure-blob-item-list/osgrowerportal/purpdf?${query}`, "GET"
    )
      .then((resp) => {
        setLoader(false);

        if (resp?.data?.blobMedias?.length > 0) {
          setNoResultsFound(false);
          setSearchResults(resp?.data?.blobMedias);
        }
        else {
          setNoResultsFound(true);
          setSearchResults([]);
        }
        handlerMobFilter("cancel");
      })
      .catch((e) => {
        setLoader(false);
        setNoResultsFound(true);
        setSearchResults([]);
        handlerMobFilter("cancel");
        console.log(e)
      });
  }

  const columns = [
    {
      key: "cropYear",
      name: "Crop Year",
      // cellClass: "enablesTextWrapping",
      width: 370,
    },
    {
      key: "contractNumbers",
      name: "Contract #",
      // headerCellClass: "enablesTextWrappingHeading",
      // cellClass: "enablesTextWrapping",
      width: 400
    },
    {
      key: "label",
      name: "PDF Links",
      width: 400,
      // headerCellClass: "enablesTextWrappingHeading",
      // cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return (
          <><a href={row.value} target="_blank" rel="noreferrer">{row.label}</a></>
        );
      },
    },
  ];

  const handleSearch = (query) => {
    if (/^\d+$/.test(query)) {
      setIsLoading(true);
      gbsDataCommonMethod(
        tokenId,
        accounts,
        `/contracts?r.relationshipnumber=${query}`,
        "get",
        {}
      )
        .then((res) => {
          let data = res.data.filter((a) => {
            return [1000, 1001].includes(a.ContractStatus);
          });
          data = res.data.map((i) => {
            i.ContractRelationshipNumber = `${i.RelationshipNumber} ${i.Name || ""
              } ${i.Name2 || ""} ${i.Name3 || ""}`;
            return i;
          });
          setContractOptions(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setLoader(false);
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        });
    }
  };

  const onAsyncContractNumberChange = (data) => {
    if (data.length === 1) {
      const ContractIds = data[0]?.RelationshipNumber;
      setCN_MultiValue({ contractid: ContractIds });
    }
  };

  const onAsyncContractNumberClear = (onClear) => {
    onClear();
    setCN_MultiValue({ contractid: "" });
    onClearfields();
    setDisabled(true);
  };

  const AsyncContractNumberReset = () => {
    try {
      if (roleAgScientist) {
        const tempClear = asyncTypeaheadRef?.current?.clear;
        if (IsFunctionChecker(tempClear)) {
          onAsyncContractNumberClear(tempClear);
        }
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  };

  const onSubmit = () => {
    console.log(CY_MultiValue, CN_MultiValue);
    getResults(`cropyear=${CY_MultiValue.cropyear}&contractno=${CN_MultiValue.contractid}`)
    // let cropyear = "",contract="" ;
    // if(selectedObj?.cropYear){

    // }
    // Object.keys(selectedObj).map((i)=>{
    //   query += i;
    //   selectedObj[i].map(k=>query)
    // })
  };

  const onClearfields = () => {

  };

  const clearAll = () => {
    AsyncContractNumberReset();
    setMultiSelectReset(false);
    setNoResultsFound(false);
    setSearchResults([]);
    setTimeout(() => {
      setMultiSelectReset(true);
      if(defCropYearArr !== ""){
        setCY_MultiValue({"cropyear": defCropYearArr });
      } 
      else{
        setCY_MultiValue({});
      }
      if(defContracts !== ""){
        setCN_MultiValue({"contractid": defContracts });
      } 
      else{
        setCN_MultiValue({});
      }
    }, 0);
  };

  const fetchContracts = () => {
    // console.log("props?.GBSContracts", props?.GBSContracts)
    if (IsArrayNotEmpty(props?.GBSContracts)) {
      const respContracts = props?.GBSContracts.map(i => {
        i.Relationship_suff = `${i.RelationshipNumber}_${i.RelationshipSuffix}`
        return i;
      });
      setContractNumbers(
        setOptions(
          respContracts,
          "RelationshipNumber",
          false,
          "RelationshipNumber",
          "Name"
        )
      );
      const getContract =
        respContracts.length === 1
          ? respContracts[0]?.RelationshipNumber
          : null;
      const defaultContractNumer = getContract ? `${getContract}` : null;
      if (
        IsStringChecker(defaultContractNumer)
      ) {
        setdefContracts(defaultContractNumer);
        let contractno = { name: respContracts[0]?.RelationshipNumber + " " + respContracts[0]?.Name, id: respContracts[0]?.RelationshipNumber };
        // console.log("contractno", selectedObj)
        setSelectedObj({ ...selectedObj, "contractno": [contractno] });
        setCN_MultiValue({ ...CN_MultiValue, "contractid": defaultContractNumer });
      }
    }
  }
  const fetchCropYear = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then((resp) => {
        const response = safeArrayCheck(resp?.data);
        const cropYear = IsArrayNotEmpty(response)
          ? response.splice(0, 15)
          : [];
        if (IsArrayNotEmpty(cropYear)) {
          setCropYears(setOptions(cropYear, "CropYear", true));
          const currentcropData = response.find((x) => x.CurrentCrop === true);
          const getcurrentcrop =
            currentcropData?.CropYear || cropYear[0]?.CropYear;
          const defaultCropyear = `${getcurrentcrop}`;
          if (
            IsStringChecker(defaultCropyear)
          ) {
            setdefCropYearArr(defaultCropyear);
            // console.log("selectedObj", selectedObj)
            setSelectedObj({ ...selectedObj, cropyear: [{ name: cropYear[0]?.CropYear, id: cropYear[0]?.CropYear }] });
            setCY_MultiValue({ ...CY_MultiValue, "cropyear": defaultCropyear });
          }
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  // creating option for mulit select
  const setOptions = (
    data,
    keyPrram,
    unique,
    concatValue1 = "",
    concatValue2 = ""
  ) => {
    if (unique && keyPrram !== "RelationshipId" && concatValue1 === "") {
      return data
        .map((li) => li[keyPrram])
        .filter(onlyUnique)
        .map((li) => {
          return { name: li, id: li };
        });
    } else {
      let contracts = [];
      if (concatValue1 !== "" && concatValue2 !== "") {
        contracts = data.map((li) => {
          return concatValue1 && concatValue2
            ? {
              name: li[concatValue1] + " " + li[concatValue2],
              id: li[keyPrram],
            }
            : { name: li[keyPrram], id: li[keyPrram] };
        });
      } else if (concatValue1 !== "") {
        contracts = data.map((li) => {
          return { name: li[concatValue1], id: li[keyPrram] };
        });
      }
      return [...new Map(contracts.map((item) => [item["id"], item])).values()];
    }
  };

  // Mobile filter change event
  const [isMobFilter, setisMobFilter] = useState(false);
  const [loading,] = useState(false);
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  return (
    <>
      {(loader) && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={clearAll}>
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 p-1 ${isMobFilter ? "active" : ""
            }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock payment-page-mobile-filter ${isMobFilter ? "show" : ""
            }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="crop_year_label custom_dropdown mb-2 mb-lg-3">
                {multiSelectReset && (
                  <MultiDropDown
                    optionList={CropYears}
                    prompt="Select"
                    value={CY_MultiValue}
                    onChange={(selectedList) =>
                      setSelectedObj({ ...selectedObj, cropyear: selectedList })
                    }
                    onDelete={(selectedList) =>
                      setSelectedObj({ ...selectedObj, cropyear: selectedList })
                    }
                    label="cropyear"
                    usestate={setCY_MultiValue}
                    preSelectedOpt={[defCropYearArr]}
                    // singleSelect={true}
                    disabled=""
                  />
                )}
              </div>
            </div>
            {roleAgScientist ? (
              <div className="col-12 col-lg-3 personal_info">
                <div className="position-relative mb-2 mb-lg-3 mb-lg-0">
                  <span className="text-14 z-index-1">
                    Contract Number & Name
                  </span>
                  {/* {fieldReset && */}
                  <AsyncTypeahead
                    // clearButton
                    ref={asyncTypeaheadRef}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey="ContractRelationshipNumber"
                    minLength={3}
                    onSearch={handleSearch}
                    options={contractOptions}
                    placeholder="Search contract number"
                    // filterBy={["RelationshipNumber", "Name","Name2","Name3"]}
                    className="AG_contracts"
                    onChange={onAsyncContractNumberChange}
                  >
                    {({ onClear, selected }) =>
                      !!selected.length && (
                        <div className="rbt-aux">
                          <ClearButton
                            onClick={() => onAsyncContractNumberClear(onClear)}
                          />
                        </div>
                      )
                    }
                  </AsyncTypeahead>
                  {/* } */}
                  {/* <input type="text" aria-label="epa-pcp" className="form-control mb-3" /> */}
                </div>
              </div>
            ) : (
              <div className="col-12 col-lg-3">
                <div className="contact_number_Label custom_dropdown mb-2 mb-lg-3">
                  {multiSelectReset && (
                    <MultiDropDown
                      optionList={contractNumbers}
                      prompt="Select"
                      value={CN_MultiValue}
                      onChange={(selectedList) =>
                        setSelectedObj({ ...selectedObj, contractno: selectedList })
                      }
                      onDelete={(selectedList) =>
                        setSelectedObj({ ...selectedObj, contractno: selectedList })
                      }
                      label="contractid"
                      usestate={setCN_MultiValue}
                      disabled={false}
                      preSelectedOpt={[defContracts]}
                      optionNotFound={{ contractid: noContractsFound }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            className={`bottom-0  filter_apply_button white_bg ${isMobFilter ? "show d-block " : " d-lg-none "
              }`}
          >
            {/* <button className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none" disabled="">Apply   </button> */}
            <button
              className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
              onClick={onSubmit}
              disabled={!CN_MultiValue.hasOwnProperty("contractid") || !CY_MultiValue.hasOwnProperty("cropyear")}
            >
              Apply{!loading ? "" : <WhiteCircleLoader />}{" "}
            </button>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                disabled={!CN_MultiValue.hasOwnProperty("contractid") || !CY_MultiValue.hasOwnProperty("cropyear")}
                onClick={() => onSubmit()}
              >
                Search{!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={() => clearAll()}
                disabled={!CN_MultiValue.hasOwnProperty("contractid") || !CY_MultiValue.hasOwnProperty("cropyear")}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </nav>
      {/* no record found section End */}
      {
        searchResults.length > 0 ?
          <>
            {
              !displayAccordian && <div
                className="hor-space-20 top-space-20 light_bg  mb-lg-4 bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table"
              >
                <DataGridPagination
                  uniqueIdentifier="PaymentId"
                  gridData={searchResults}
                  columns={columns}
                  isPagination={true}
                  itemsPerPage={10}
                  columnResize={false}
                  classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                  autoRowHeight={true}
                // dynamicHeaderHeight={80}
                // summaryRows = {summaryRows}
                />
              </div>
              }
              {
                <div
                  className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${!displayAccordian ? "d-lg-none" : "desktop-accordion-table"
                    }`}
                >
                  <div className="e-purEdit delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                    <Accordion>
                      {searchResults.length > 0 &&
                        searchResults.map((data) => {
                          return (
                            <Fragment key={data.Id}>
                              <Accordion.Item eventKey={data.Id}>
                                <Accordion.Header>
                                  <div>
                                    Crop Year: {data.cropYear}
                                    Contract #: {data.contractNumbers} <br />
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="tbody">
                                    <div className="tr">
                                      <div className="td" data-header="expand_icon">
                                        <i className="fa fa-plus-circle cursor_pointer table_accordion"></i>
                                      </div>
                                      <div
                                        className="td"
                                        data-header="Crop Year:"
                                      >
                                        <span>{data.cropYear}</span>
                                      </div>
                                      <div className="td" data-header="Contract #:">
                                        <span>
                                          {data.contractNumbers}
                                        </span>
                                      </div>
                                      
                                      <div
                                        className="td"
                                        data-header="Link:"
                                      >
                                        <span>
                                          <a href={data.value}  target="_blank" rel="noreferrer">{data.label}</a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Fragment>
                          );
                        })}
                    </Accordion>
                  </div>
                </div>
            }


          </>
          : (noResultsFound && <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4">
            <i className="fa fa-info-circle pe-2"></i>
            {moreLangs[i18n.resolvedLanguage].no_record_found}
          </div>)
      }
    </>
  );
};

export default PrintableReports;
