import React, { useEffect, useState } from "react";
import "../../css/loading.css";
import { useMsal } from "@azure/msal-react";
import {
  IsTokenChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  compareString,
  trailZeros,
  IsStringChecker,
  IsNumberChecker,
  StringToInteger,
  IsObjectChecker,
} from "../../utils/helper";
import Loading from "../../components/global/loading";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import { gbsDataCommonMethod } from "../../services/gbsData";
import _ from "lodash";
import { ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../permissionWrapper";
import mu from "../../utils/index";
import Harvest from "../home/newharvest";

const HarvestDataQuickView = (props) => {
  const { RoleCBPrivileges = {}, DropDownCBRoles = [] } = props;
  const { roleCBAcreage = false, roleCBDecisionMaker = false } = RoleCBPrivileges;

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const [loader, setLoader] = useState(false);
  const [harvestData, setHarvestData] = useState([]);
  const [asyncData, setAsyncData] = useState([]);

  const LocalTrans = (str, def) => {
    try {
      if (IsStringChecker(str)) {
        return `${_.get(
          moreLangs,
          `${resolvedLanguage}.home_page.${str}`,
          def
        )}`;
      }
      return def;
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return def;
    }
  };

  const getBeds = (data, resultData = []) => {
    try {
      const new_arr = Object.keys(data);
      if (IsArrayNotEmpty(new_arr)) {
        return new_arr.map((list) => {
          const arr = _.get(data, list, []);
          const beds = arr.map((li) => li["HarvestBed"]).join("/");
          const bedHistoryIds = arr.map((li) => li["BedHistoryId"]).join("/");
          return { ...arr[0], beds, bedHistoryIds };
        });
      }
      return resultData;
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return resultData;
    }
  };

  const fetchHarvestData = async () => {
    const filteredContracts = ValidGBSRoleByContracts(
      accounts,
      DropDownCBRoles,
      true,
      false
    );
    const contractids = mu.arrayToString(filteredContracts);
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `deliveries?d.relationshipid=${contractids}&limit=15&status=3400,3401,3402,3403,3404&orderby=IncomingTime.desc`
    )
      .then(async (response) => {
        const resultData = _.get(response, "data", []);
        if (IsArrayNotEmpty(resultData)) {
          const data = _.groupBy(resultData, "DeliveryId");
          const temp = getBeds(data, resultData);
          const sortedArray = _.orderBy(temp, ["IncomingTime"], ["desc"]);
          const display = sortedArray.slice(0, 3);
          setHarvestData(display);
          fetchAsyncData(display);
        } else {
          setHarvestData([]);
          setAsyncData([]);
        }
      })
      .catch((e) => {
        setHarvestData([]);
        setAsyncData([]);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const fetchAsyncData = async (param) => {
    try {
      const data = IsArrayNotEmpty(param)
        ? param.map((li) => {
            return {
              DeliveryId: li.DeliveryId,
              LoadDeliveryTicketNumber: li.LoadDeliveryTicketNumber,
              CropYear: li.CropYear,
              LocationCode: li.LocationCode,
            };
          })
        : [];
      if (IsArrayNotEmpty(data)) {
        const finalData = await Promise.all(
          data.map(async (item, index) => {
            const {
              DeliveryId,
              LoadDeliveryTicketNumber,
              CropYear,
              LocationCode,
            } = item;

            const id = trailZeros(LoadDeliveryTicketNumber, 4);
            const imgUrl = `deliveries/photo-retain/${CropYear}-${LocationCode}/${id}`;
            const imgConfig = {
              "Content-Type": "application/json",
              responseType: "blob",
            };
            await gbsDataCommonMethod(
              idToken,
              accounts,
              imgUrl,
              "GET",
              {},
              imgConfig
            )
              .then((resp) => {
                const testBlob = resp.data;
                data[index]["img"] = testBlob;
              })
              .catch((e) => {
                data[index]["img"] = null;
                HandleErrorTrackAndToast(e, true, "replace", dispatch);
              });

            const calcUrl = `deliveries/${DeliveryId}/calculations?calculationid=36,7`;
            await gbsDataCommonMethod(idToken, accounts, calcUrl)
              .then((resp) => {
                const calculations = _.get(resp, "data", []);
                if (IsArrayNotEmpty(calculations)) {
                  const initialCalc = { poor: 0, class_1: 0 };
                  const finalCalculations = calculations.reduce((acc, item) => {
                    try {
                      const { Value, Key } = item;
                      const calcValue = StringToInteger(Value) || 0;
                      if (compareString(Key, "%Poor")) {
                        const { poor } = acc;
                        if (IsNumberChecker(poor) && poor > 0) {
                          const logic = (calcValue + poor).toFixed(4);
                          return { ...acc, poor: logic };
                        } else {
                          return { ...acc, poor: calcValue };
                        }
                      } else if (compareString(Key, "%Class1")) {
                        return { ...acc, class_1: calcValue };
                      } else {
                        return acc;
                      }
                    } catch (e) {
                      HandleErrorTrackAndToast(e, true);
                      return initialCalc;
                    }
                  }, initialCalc);
                  const { poor = 0, class_1 = 0 } = IsObjectChecker(
                    finalCalculations
                  )
                    ? finalCalculations
                    : initialCalc;
                  const finalCalcs = `${LocalTrans(
                    "poor",
                    "%Poor"
                  )}: ${poor} | ${LocalTrans(
                    "class_1",
                    "%Class1"
                  )}: ${class_1}`;
                  data[index]["calculations"] = finalCalcs;
                } else {
                  data[index]["calculations"] = "";
                }
              })
              .catch((e) => {
                data[index]["calculations"] = "";
                HandleErrorTrackAndToast(e, true, "replace", dispatch);
              });

            return data[index];
          })
        );
        setAsyncData([...finalData]);
      } else {
        setAsyncData([]);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setAsyncData([]);
    }
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      if (roleCBAcreage || roleCBDecisionMaker) {
        setLoader(true);
        fetchHarvestData();
      }
    }

    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);
  const displaySection = roleCBDecisionMaker || roleCBAcreage;
  if (displaySection) {
    return (
      <>
        {loader && (
          <div className="zindex10000">
            <div className="nav-mobile-overlay-display"></div>
            <Loading />
          </div>
        )}
        <Harvest
          notification={null}
          harvestData={harvestData}
          asyncData={asyncData}
          page={"my-harvest"}
          DropDownCBRoles={DropDownCBRoles}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default HarvestDataQuickView;
