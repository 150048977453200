import React, { useEffect, useState } from "react";
import "../../css/loading.css";
import { gbsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import DataGridPagination from "../global/dataGridPagination";
import ExportFile from "../exportFile/exportFile";
import {
  getFilteredData,
  setOptions,
  setObjToString,
  IsTokenChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  getCurrrentCropYear,
  compareString,
} from "../../utils/helper";
import { cascadeFilterFunc } from "../../utils/cascadeFilter";
import { ConstantsVar } from "../../utils/constants";
import MultiDropDown from "../selectBox/multiDropDown";
import DataMobileGridList from "../global/dataMobileGridList";
import WhiteCircleLoader from "../whiteCircleLoader";
import moment from "moment";
import PdfFile from "../exportFile/pdfFile";
import Loading from "../../components/global/loading";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../permissionWrapper";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import { useDispatch } from "react-redux";
import useActiveCropYear from "../customHooks/useActiveCropYear";

const DeliveryApproval = (props) => {
  const { action = null, DropDownCBRoles = [] } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;

  const state = useActiveCropYear();
  const { activeCropYear: currentYear = null, reducerToken: tokenId = null } =
    state;
  const dispatch = useDispatch();

  /*filter default disabled**/
  const [disabled, setDisabled] = useState("disabled");
  const [clearStatus, setClearStatus] = useState(true);
  /***/
  const [multiContId, setMultiContId] = useState([]);
  const [multiValue, setMultiValue] = useState({});
  const [filterItems, setFilterItems] = useState([]);
  const [gridItems, setGridItems] = useState([]);
  const [mobileItems, setMobileItems] = useState([]);
  const [exportItems, setExportItems] = useState([]);
  /*loader for search button**/
  const [loading, setLoading] = useState(false);
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [isReset, setIsReset] = useState(true);
  const [hideExport, setHideExport] = useState(true);
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [ContractOption, setContractOption] = useState([]);
  const [bogNameOption, setBogNameOption] = useState([]);
  const [harvestbedOption, setHarvestbedOption] = useState([]);
  const [verityOption, setVerityOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [preSelectedContract, setPreSelectedContract] = useState([]);

  const ReturnColoredElement = (props) => {
    const {
      PspAcceptance = 0,
      PhiApproved = 0,
      // PspStatus = 0,
      IconTitle = false,
      value = "",
      ...rest
    } = props;
    // console.log(props);
    const operand1 = PspAcceptance,
      operand2 = PhiApproved;
    if (IconTitle) {
      const IconColorDeterminer = (
        operand1 = 0,
        operand2 = 0,
        IconTitle = ""
      ) => {
        if (operand1 > 0 && operand2 > 0) {
          return "fa-check-circle light_green_color";
        } else if (operand1 > 0 || operand2 > 0) {
          if (compareString("PSP Status", IconTitle)) {
            return operand1 > 0
              ? "fa-check-circle light_grey_color"
              : "fa-times-circle red_color";
          } else if (compareString("PHI Status", IconTitle)) {
            return operand2 > 0
              ? "fa-check-circle light_grey_color"
              : "fa-times-circle red_color";
          } else {
            return "fa-times-circle red_color";
          }
        } else {
          return "fa-times-circle red_color";
        }
      };
      const icon = IconColorDeterminer(operand1, operand2, IconTitle);
      return (
        <i
          className={`fa text-20 grid_status_icon ${icon}`}
          aria-hidden="true"
        ></i>
      );
    } else {
      const determiner = operand1 > 0 && operand2 > 0;
      return (
        <span
          className={`${determiner ? "green_dark_color" : "red_dark_color"}`}
          {...rest}
        >
          {value}
        </span>
      );
    }
  };

  const { gridTitle } = {
    ...moreLangs[i18n.resolvedLanguage].deliveryApproval,
  };
  const columns = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      headerCellClass: "enablesTextWrappingHeading",
      width: action === "myHarvest" ? 80 : 100,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={props.row.RelationshipNumber}
          />
        );
      },
    },
    {
      key: "Name",
      name: "Contract Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: action === "myHarvest" ? 135 : 170,
      formatter(props) {
        const concateName =
          props.row.Name != null
            ? props.row.Name
            : "" + (props.row.Name2 != null)
            ? " " + props.row.Name2
            : "" + (props.row.ContractIdentifier != null)
            ? " " + props.row.ContractIdentifier
            : "";
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={concateName}
            title={concateName}
          />
        );
      },
    },
    {
      key: "BogName",
      name: "Bog Name",
      //headerCellClass: 'grid_column_center',
      //cellClass: "text-lg-center",
      width: action === "myHarvest" ? 100 : 120,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={props.row.BogName}
          />
        );
      },
    },
    {
      key: "HarvestBed",
      name: "Harvest Bed",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: action === "myHarvest" ? 90 : 120,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={props.row.HarvestBed}
          />
        );
      },
    },
    {
      key: "VarietyDescription",
      name: "Variety",
      cellClass: "enablesTextWrapping break_all_word",
      //width: (action=='myHarvest')?120:150,
      width: action === "myHarvest" ? 120 : 150,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={props.row.VarietyDescription}
          />
        );
      },
    },
    {
      key: "ExhibitAcres",
      name: "Acres",
      headerCellClass: "text-lg-end",
      cellClass: "text-lg-end",
      width: action === "myHarvest" ? 90 : 100,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={props.row.ExhibitAcres.toFixed(2)}
          />
        );
      },
    },
    {
      key: "Export",
      name: "EU/ NonEU",
      headerCellClass: "text-lg-center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: action === "myHarvest" ? 80 : 100,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={
              props.row.Export === true
                ? " EU "
                : props.row.Export === false
                ? " NonEU "
                : "-"
            }
          />
        );
      },
    },
    {
      key: "PspAcceptance",
      name: "PSP Status",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "text-lg-center enablesTextWrapping",
      width: action === "myHarvest" ? 80 : 100,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            IconTitle={"PSP Status"}
          />
        );
      },
    },
    {
      key: "PhiApproved",
      name: "PHI Status",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "text-lg-center enablesTextWrapping",
      width: action === "myHarvest" ? 80 : 100,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            IconTitle={"PHI Status"}
          />
        );
      },
    },
    {
      key: "PhiDate",
      name: "PHI Date",
      width: action === "myHarvest" ? 100 : 130,
      formatter(props) {
        return (
          <ReturnColoredElement
            PspAcceptance={props.row.PspAcceptance}
            PhiApproved={props.row.PhiApproved}
            PspStatus={props.row.PspStatus}
            value={
              props.row.PhiDate
                ? moment.utc(props.row.PhiDate).format(ConstantsVar.dateFormate)
                : ""
            }
          />
        );
      },
    },
  ];

  const fetchContract = async () => {
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/${personId}/contracts?r.ContractStatus=1000,1001`
    )
      .then((resp) => {
        const data = [...resp.data];
        const filteredData = FilterContractByRole(data, accounts, DropDownCBRoles);
        if (IsArrayNotEmpty(filteredData)) {
          const options = setOptions(
            filteredData,
            "RelationshipId",
            // false,
            "RelationshipNumber",
            "Name"
          );
          setContractOption(options);
          if (options.length === 1) {
            const tempDefaultContract = _.get(options, `0.id`, null);
            setClearStatus(false);
            setTimeout(() => {
              setPreSelectedContract([`${tempDefaultContract}`]);
              setMultiContId({ contract_id: `${tempDefaultContract}` });
              setClearStatus(true);
              setIsReset(false);
              setSearchBtnDisabled(false);
            }, 0);
          }

          if (currentYear > 0) {
            const contractId = filteredData
              .map((x) => x["RelationshipId"])
              ?.toString();
            let url = `contracts/preHarvest?bh.cropyear=${currentYear}&r.id=${contractId}`;
            fetchPreHarvest(url, true, options);
          } else {
            setLoader(false);
            setLoading(false);
            getCurrrentCropYear(
              tokenId,
              accounts,
              `crop-years?currentcrop=1`,
              dispatch
            );
          }
        } else {
          setGridItems([]);
          setFilterItems([]);
          setContractOption([]);
          setBogNameOption([]);
          setHarvestbedOption([]);
          setVerityOption([]);
          setDisabled(true);
          setIsListingHide(true);
          setHideExport(true);
          setLoader(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setGridItems([]);
        setFilterItems([]);
        setContractOption([]);
        setBogNameOption([]);
        setHarvestbedOption([]);
        setVerityOption([]);
        setDisabled(true);
        setIsListingHide(true);
        setHideExport(true);
        setLoading(false);
        setLoader(false);
      });
  };
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      setLoader(true);
      setLoading(true);
      fetchContract();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
  }, [tokenId, currentYear]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const fetchPreHarvest = async (
    url,
    initialLoad = false,
    contractList = []
  ) => {
    await gbsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        let resp_data = [...resp.data];
        let filteredData = FilterContractByRole(
          resp_data,
          accounts,
          DropDownCBRoles
        );
        // console.log({ filteredData });
        let pspTrueContract = filteredData.map((x) => x.RelationshipNumber);
        // .filter((x) => x.PspAcceptance > 0)
        // console.log({ pspTrueContract });
        const overWirteData = filteredData.filter((item) => {
          if (pspTrueContract.includes(item.RelationshipNumber)) {
            return item;
          }
        });
        let resultData = IsArrayNotEmpty(overWirteData)
          ? [...overWirteData]
          : [];
        // console.log({ resultData });
        if (
          IsArrayNotEmpty(resultData) &&
          resp.data !== "Contract and CropYear must be provided"
        ) {
          /*Multicolumn sort*/
          resultData.sort(
            (a, b) =>
              a.RelationshipNumber.localeCompare(b.RelationshipNumber, "en", {
                numeric: true,
              }) ||
              a.BogName.localeCompare(b.BogName, "en", { numeric: true }) ||
              a.HarvestBed.localeCompare(b.HarvestBed, "en", { numeric: true })
          );
          /*End*/
          setFilterItems(resultData);

          /*Converting into Desending order by "PhiDate" date*/
          //loadResultData.sort((a, b) => moment(b.PhiDate) - moment(a.PhiDate));

          /*===============================
            when there are oinly one contract.
            =================================*/
          let defaultListData = [];
          if (contractList.length == 1 && initialLoad == true) {
            defaultListData = resultData.filter((item) => {
              return (
                setObjToString([contractList[0]])
                  ?.toLowerCase()
                  ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !==
                -1
              );
            });
          } else {
            defaultListData = [...resultData];
          }

          if (IsArrayNotEmpty(defaultListData)) {
            setBogNameOption(setOptions(defaultListData, "BogName", "BogName"));
            setHarvestbedOption(
              setOptions(defaultListData, "HarvestBed", "HarvestBed")
            );
            setVerityOption(
              setOptions(defaultListData, "VarietyId", "VarietyDescription")
            );
            setGridItems(defaultListData);
            setDisabled(false);
            setHideExport(false);
            setIsListingHide(false);
            setNoRecordErrorMsg("");
            setisMobFilter(false);
            loadFilter(defaultListData);
          } else {
            setBogNameOption([]);
            setHarvestbedOption([]);
            setVerityOption([]);
            setGridItems([]);
            setDisabled(true);
            setHideExport(true);
            setIsListingHide(true);
          }
        } else {
          setBogNameOption([]);
          setHarvestbedOption([]);
          setVerityOption([]);
          setDisabled(true);
          setHideExport(true);
          setIsListingHide(true);
          setGridItems([]);
          setFilterItems([]);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setBogNameOption([]);
        setHarvestbedOption([]);
        setVerityOption([]);
        setDisabled(true);
        setHideExport(true);
        setIsListingHide(true);
        setGridItems([]);
        setFilterItems([]);
      })
      .finally(() => {
        setLoading(false);
        setLoader(false);
      });
  };

  // Contract select change event
  const handlerContract = (val, initial_load = false) => {
    setMultiValue(null);
    let filter = filterItems.filter((item) => {
      return (
        setObjToString(val)
          ?.toLowerCase()
          ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
      );
    });
    setIsReset(false);
    if (initial_load == false) {
      setIsListingHide(true);
    }
    setSearchBtnDisabled(false);
    if (IsArrayNotEmpty(filter)) {
      setDisabled(false);
      setBogNameOption(setOptions(filter, "BogName", "BogName"));
      setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
      setVerityOption(setOptions(filter, "VarietyId", "VarietyDescription"));
    } else {
      setDisabled(true);
      setBogNameOption([]);
      setHarvestbedOption([]);
      setVerityOption([]);
    }
  };

  const handlerContractDel = (selectedList, removedItem) => {
    setMultiValue(null);
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      setIsReset(true); /*disabled Reset button*/
      setMultiContId(null); /*set null to contract filter */
      setSearchBtnDisabled(true);
      //setMultiValue(null); /*set null to all filter */
    } else {
      let filter = filterItems.filter((item) => {
        return (
          setObjToString(selectedList)
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
      setIsReset(false);
      setSearchBtnDisabled(false);
      if (IsArrayNotEmpty(filter)) {
        setDisabled(false);
        setBogNameOption(setOptions(filter, "BogName", "BogName"));
        setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
        setVerityOption(setOptions(filter, "VarietyId", "VarietyDescription"));
      } else {
        setDisabled(true);
        setBogNameOption([]);
        setHarvestbedOption([]);
        setVerityOption([]);
      }
    }
  };

  // On click search
  const handlerSubmit = () => {
    setLoading(true); /*set loader to search button*/
    let filter = [...filterItems];
    if (multiContId?.contract_id) {
      filter = getFilteredData(
        filter,
        multiContId.contract_id,
        "RelationshipId"
      );
    }
    if (multiValue?.bognames) {
      filter = getFilteredData(filter, multiValue.bognames, "BogName");
    }
    if (multiValue?.harvestbeds) {
      filter = getFilteredData(filter, multiValue.harvestbeds, "HarvestBed");
    }
    if (multiValue?.varityids) {
      filter = getFilteredData(filter, multiValue.varityids, "VarietyId");
    }

    if (
      IsArrayNotEmpty(filter) &&
      filter !== "Contract and CropYear must be provided"
    ) {
      loadFilter(filter);
      setIsListingHide(false);
      setGridItems(filter);
      setHideExport(false);
      setNoRecordErrorMsg("");
      setisMobFilter(false);
    } else {
      setIsListingHide(true);
      setNoRecordErrorMsg(
        `${_.get(
          moreLangs,
          `${resolvedLanguage}.deliveryApproval.no_record_found`,
          "Protective Screen Program has not yet launched for the upcoming harvest."
        )}`
      );
    }
    setLoading(false); /*set loader to search button*/
    //handlerMobFilter("cancel");
  };

  const handlerOtherFilter = (
    selectedList,
    currentItem,
    action,
    request = ""
  ) => {
    let multival = { ...multiValue };
    if (action == "selected") {
      setGridItems([]); /*set empty to grid Item */
      setIsListingHide(true);
      setNoRecordErrorMsg("");
    } else if (action == "deselected") {
      setGridItems([]); /*set empty to grid Item */
      setIsListingHide(true);
    }

    const searchParam = {
      Contract_id: ["RelationshipId"],
      Bog_name: ["BogName", "BogName"],
      Harvest_bed: ["HarvestBed", "HarvestBed"],
      Varity_id: ["VarietyId", "VarietyDescription"],
    };
    let filter = [...filterItems];
    cascadeFilterFunc({
      filter,
      selectedList,
      action,
      request,
      multiContId,
      multival,
      searchParam,
      setBogNameOption,
      setHarvestbedOption,
      setVerityOption,
    });
  };

  function loadFilter(filterData) {
    const overWirteMobData = Object.values(filterData).map((item) => ({
      Contract_Number: item.RelationshipNumber,
      Contract_Name:
        item.Name != null
          ? item.Name
          : "" + (item.Name2 != null)
          ? " " + item.Name2
          : "" + (item.ContractIdentifier != null)
          ? " " + item.ContractIdentifier
          : "",
      Bog_Name: item.BogName,
      Harvest_Bed: item.HarvestBed,
      Variety: item.VarietyDescription,
      Acres: item.ExhibitAcres.toFixed(2),
      "EU/NonEU":
        item.Export == true ? " EU " : item.Export == false ? " NonEU " : "-",
      PspAcceptance: item.PspAcceptance,
      PhiApproved: item.PhiApproved,
      PspStatus: item.PspStatus,
      PHI_Date: item.PhiDate,
    }));
    const exportData = Object.values(overWirteMobData).map((item) => {
      delete item.PspStatus;
      item["PHI_Date"] = item?.PHI_Date
        ? moment.utc(item?.PHI_Date).format(ConstantsVar.dateFormate)
        : "";
      return item;
    });
    setMobileItems(overWirteMobData);
    setExportItems(exportData);
  }

  const handleReset = (e) => {
    e.stopPropagation();
    setIsReset(true); /*disabled Reset button*/
    setPreSelectedContract([]);
    setMultiContId(null); /*set null to contract filter */
    setMultiValue(null); /*set null to all filter */
    setDisabled(true); /*disabled filter dropdown*/
    setSearchBtnDisabled(true); /*disabled search button*/
    setGridItems([]); /*set empty to grid Item */
    setHideExport(true);
    setIsListingHide(true);
    setNoRecordErrorMsg("");
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };
  return (
    <>
      {/*<!-- Delivery approval block starts -->*/}
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/* <!-- Filter Dropdown starts --> */}
      <div className="card-body mob-space-7 ver-space-10 align-flex border-1 border-radius-6 white_bg mb-3">
        <span className="d-contents ver-space-10">
          <i className="fa fa-info-circle margin-right-5 d-none d-lg-inline-block"></i>
          <i className="fa fa-info margin-right-5 d-lg-inline-block d-lg-none info_icon_mobile"></i>
          <span className="mob_blue_text">{gridTitle}</span>
        </span>
      </div>

      <nav className="navbar navbar-expand-lg p-0 mt-2 hidden_print">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>

        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap2" /*style={{ display:"none !important"}}*/
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {/* <span>Contract Number</span>  */}
                {clearStatus && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) => handlerContract(val)}
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled=""
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    preSelectedOpt={preSelectedContract}
                    optionNotFound={{ contract_id: noContractsFound }}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="bog_name custom_dropdown">
                {/* <span>Bog Name</span> */}
                <MultiDropDown
                  optionList={bogNameOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "bogname"
                    )
                  }
                  label="bognames"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "bogname"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="harvest_bed custom_dropdown">
                {/* <span>Crop Year</span> */}
                <MultiDropDown
                  optionList={harvestbedOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "harvestbed"
                    )
                  }
                  label="harvestbeds"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "harvestbed"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="variety custom_dropdown">
                {/* <span>Contract Number</span> */}
                <MultiDropDown
                  optionList={verityOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "varityid"
                    )
                  }
                  label="varityids"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "varityid"
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-2">
              {/*Loader on search button*/}
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                data-category="Web"
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>

              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? "show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            data-category="mobile"
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>
      {isListingHide == false ? (
        <>
          {/* show grid */}
          <div className="row mt-2 hidden_print">
            <div className="col-12 col-lg-6">
              <div className="float-start">
                <h5 className="text-14 fw-bold mt-3 mt-lg-2 mob-text-16">
                  Crop Year : {currentYear || "-"}
                </h5>
              </div>
            </div>
            <div className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} `}>
              <div className="float-end mb-2">
                <span>
                  <PdfFile pdfData={exportItems} fileName="DeliveryApproval" />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="csv"
                    exportData={exportItems}
                    fileName="DeliveryApproval"
                  />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="xlsx"
                    exportData={exportItems}
                    fileName="DeliveryApproval"
                  />
                </span>
              </div>
            </div>
          </div>
          {/* <!-- Filter Dropdown ends --> */}
          {!displayAccordian && (
            <div
              className={`visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block /*${
                isReset ? "d-none" : "d-none d-lg-block"
              }*/`}
            >
              <DataGridPagination
                uniqueIdentifier="Id"
                gridData={gridItems}
                columns={columns}
                isPagination={true}
                itemsPerPage={10}
                columnResize={false}
                classNames="contain-block-size-reset top-mspace-20 bot-mspace-20 border-radius-6"
                autoRowHeight={true}
              />
            </div>
          )}
          {/* <!-- Responsive table starts --> */}
          <div
            className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 ${
              !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
            }  ${hideExport ? "d-none" : "d-lg-block"} `}
          >
            <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
              <DataMobileGridList listingData={mobileItems} />
            </div>
          </div>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
    </>
  );
};

export default DeliveryApproval;
