import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  IsArrayNotEmpty,
  IsStringChecker,
  compareString,
} from "../../../utils/helper";
import { HandleErrorTrackAndToast } from "../../../utils/helper";

export const empty_descriptions = [
  '<div class="ql-editor"><p><br></p></div>',
  '<div class="ql-editor">null</div>',
  '<div class="ql-editor"></div>',
];

export const GetDescriptionValue = (empty, value) => {
  try {
    const found = empty.some((li) => compareString(li, value));
    return found ? "" : value;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return value;
  }
};

const getRegions = (regions = []) => {
  try {
    if (IsArrayNotEmpty(regions)) {
      const regString = regions.join(", ");
      return regString.replace(/-/g, " ");
    }
    return "-";
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    return "-";
  }
};

export const ContactCard = (props = {}) => {
  const {
    contact_image_url,
    phone,
    email_address,
    business_phone,
    URLpath,
    home_phone,
  } = props;
  const { i18n } = useTranslation();
  const name = _.get(props, `name_${i18n.resolvedLanguage}`, "");
  const subtitle = _.get(props, `subtitle_${i18n.resolvedLanguage}`, "");
  const address = _.get(props, `address_${i18n.resolvedLanguage}`, "");
  return (
    <div className="card bot-mspace-20 d-block text-center card-shadow mx-3">
      <div className="position-relative">
        <div className="half_background left-border-radius right-border-radius"></div>
        {contact_image_url && (
          <img
            src={contact_image_url}
            alt={subtitle || ""}
            className="card-img-top card-shadow border-radius-50 news_profile_img position-relative mt-5 img-circle-border"
          />
        )}
      </div>
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-12">
            <ul className="list-group text-center">
              <li className="list-group-item border-0 py-2 px-0">
                {name && URLpath && (
                  <h3>
                    <Link to={URLpath} className="text-26 text_mob_18">
                      {name}
                    </Link>
                  </h3>
                )}
                {subtitle && <h4 className="min-height-21">{subtitle}</h4>}
              </li>
            </ul>
            <div className="horizontal-line mb-3"></div>
            <div className="text-start">
              {address && <address>{parse(address)}</address>}
            </div>
            <ul className="list-unstyled text-start mb-2">
              {home_phone && (
                <li className="mb-1">
                  <a href={`tel:${home_phone}`}>
                    <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    {home_phone}
                  </a>
                </li>
              )}
              {phone && (
                <li className="mb-1">
                  <a href={`tel:${phone}`}>
                    <i
                      className="fa fa-mobile text-16 mob-l-r-space"
                      aria-hidden="true"
                    ></i>{" "}
                    {phone}
                  </a>
                </li>
              )}
              {business_phone && (
                <li className="mb-1">
                  <a href={`tel:${business_phone}`}>
                    <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                    {business_phone}
                  </a>
                </li>
              )}
              {email_address && (
                <li className="mb-1">
                  <a href={`mailTo:${email_address}`}>
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                    {email_address}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const OSContactsDetail = (props = {}) => {
  const {
    contact_image_url,
    phone,
    email_address,
    URLpath,
    business_phone,
    home_phone,
    regions = [],
    showRegion = false,
    showCommittee = false,
  } = props;
  const { i18n } = useTranslation();
  const name = _.get(props, `name_${i18n.resolvedLanguage}`, "");
  const subtitle = _.get(props, `subtitle_${i18n.resolvedLanguage}`, "");
  const address = _.get(props, `address_${i18n.resolvedLanguage}`, "");
  const description = GetDescriptionValue(
    empty_descriptions,
    _.get(props, `description_${i18n.resolvedLanguage}`, "")
  );
  const region = showRegion ? getRegions(regions) : "-";
  const committee = showCommittee
    ? _.get(props, `committees_${i18n.resolvedLanguage}`, null)
    : null;
  return (
    <div className="card bot-mspace-20 card-shadow pt-4 ps-1 ps-sm-3 mt-5 mt-lg-0">
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="text-center text-sm-start">
              {URLpath && (
                <Link
                  to={`${URLpath}`}
                  className="btn btn-primary btn-sm text-14 position-absolute ms-1 start-0 d-block d-lg-none top_20"
                >
                  &lt; Back
                </Link>
              )}
              {contact_image_url && (
                <img
                  src={contact_image_url}
                  alt={subtitle || ""}
                  className="card-img-top border-radius-50 view_img"
                />
              )}
              {name && <h3 className="mt-4">{name}</h3>}
              {subtitle && <h4 className="grey_color mb-3">{subtitle}</h4>}
              {(IsStringChecker(committee) || IsStringChecker(description)) && (
                <div className="horizontal-line"></div>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="text-16 mt-2">
              {address && (
                <address className="person_address">{parse(address)}</address>
              )}
              <ul className="list-unstyled">
                {home_phone && (
                  <li>
                    <a href={`tel:${home_phone}`}>
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                      {home_phone}
                    </a>
                  </li>
                )}
                {phone && (
                  <li>
                    <a href={`tel:${phone}`}>
                      <i
                        className="fa fa-mobile text-16 mob-l-r-space"
                        aria-hidden="true"
                      ></i>{" "}
                      {phone}
                    </a>
                  </li>
                )}
                {business_phone && (
                  <li>
                    <a href={`tel:${business_phone}`}>
                      <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                      {business_phone}
                    </a>
                  </li>
                )}
                {email_address && (
                  <li>
                    <a href={`mailTo:${email_address}`}>
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                      {email_address}
                    </a>
                  </li>
                )}
              </ul>
              {showRegion && (
                <div className="text-start">
                  {region && (
                    <p className="text-capitalize">
                      <span className="fw-bold">{`Regions: `}</span>
                      <span>{region}</span>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showCommittee && IsStringChecker(committee) && (
        <div className="p-3 pt-1 text-16 person_description">
          <p className="text-capitalize">
            <span className="fw-bold">{`Committees: `}</span>
            <span>{committee}</span>
          </p>
        </div>
      )}
      {description && (
        <div className="p-3 pt-1 text-16 person_description">
          {parse(description)}
        </div>
      )}
    </div>
  );
};

export const OSContactsCard = (props = {}) => {
  const {
    contact_image_url,
    phone,
    email_address,
    URLpath,
    business_phone,
    home_phone,
    regions = [],
    showRegion = false,
    showCommittee = false,
  } = props;
  const { i18n } = useTranslation();
  const name = _.get(props, `name_${i18n.resolvedLanguage}`, "");
  const subtitle = _.get(props, `subtitle_${i18n.resolvedLanguage}`, "");
  const address = _.get(props, `address_${i18n.resolvedLanguage}`, "");
  const region = showRegion ? getRegions(regions) : "-";
  const committee = showCommittee
    ? _.get(props, `committees_${i18n.resolvedLanguage}`, null)
    : null;
  const description = GetDescriptionValue(
    empty_descriptions,
    _.get(props, `description_${i18n.resolvedLanguage}`, "")
  );
  const nextPage = description ? true : false;
  return (
    <div className="card bot-mspace-20 d-block text-center card-shadow ms-0 ms-sm-2 ms-lg-3 pt-3">
      {contact_image_url && (
        <img
          src={contact_image_url}
          alt={subtitle || ""}
          className="card-img-top border-radius-50 news_profile_img"
        />
      )}
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-12">
            <ul className="list-group text-center">
              <li className="list-group-item border-0 py-3 px-0">
                {name && (
                  <h3>
                    {nextPage ? (
                      URLpath ? (
                        <Link to={URLpath} className="text-22 text_mob_18">
                          {name}
                        </Link>
                      ) : (
                        <span className="text-22 text_mob_18 span_link_blue">
                          {name}
                        </span>
                      )
                    ) : (
                      <span className="text-22 text_mob_18 span_link_blue">
                        {name}
                      </span>
                    )}
                  </h3>
                )}
                {subtitle && <h4>{subtitle}</h4>}
              </li>
            </ul>
            <div className="horizontal-line mb-3"></div>
            <div className="text-start">
              {address && (
                <address className="person_address">{parse(address)}</address>
              )}
            </div>
            <ul className="list-unstyled text-start">
              {home_phone && (
                <li>
                  <a href={`tel:${home_phone}`}>
                    <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    {home_phone}
                  </a>
                </li>
              )}
              {phone && (
                <li>
                  <a href={`tel:${phone}`}>
                    <i
                      className="fa fa-mobile text-16 mob-l-r-space"
                      aria-hidden="true"
                    ></i>{" "}
                    {phone}
                  </a>
                </li>
              )}
              {business_phone && (
                <li>
                  <a href={`tel:${business_phone}`}>
                    <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                    {business_phone}
                  </a>
                </li>
              )}
              {email_address && (
                <li>
                  <a href={`mailTo:${email_address}`}>
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                    {email_address}
                  </a>
                </li>
              )}
            </ul>
            {showRegion && (
              <div className="text-start">
                {region && (
                  <p className="text-capitalize">
                    <span className="fw-bold">{`Regions: `}</span>
                    <span>{region}</span>
                  </p>
                )}
              </div>
            )}
            {showCommittee && IsStringChecker(committee) && (
              <div className="text-start">
                <p className="text-capitalize">
                  <span className="fw-bold">{`Committees: `}</span>
                  <span>{committee}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
