import React from "react";
import PageLayout from "../pageLayout";
import Body from "./body";
import MyDeliveryDataDetails from "./myDeliveryDataDetails";

const MyDeliveryData = (props) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <PageLayout {...props} body={<Body isLeftMenu ="false" quickLinksSlug="my-harvest" {...props} contentbody={<MyDeliveryDataDetails {...props} />} />} />
  );
};

export default MyDeliveryData;
