import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import DeliveryApproval from "./deliveryApproval";
import { useHistory } from "react-router-dom";
import Events from "../home/siteNotification";
import WebCams from "../global/webCams";
import { HandleErrorTrackAndToast, IsTokenChecker } from "../../utils/helper";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../global/loading";
import { useMsal } from "@azure/msal-react";
import HarvestDataQuickView from "./harvestDataQuickView";

const MyHarvestDetails = (props) => {
  const { RoleCBPrivileges = {}, SpecialRoleExist } = props;
  const dispatch = useDispatch();
  const [harvestLoading, setHarvestLoading] = useState(false);
  const idToken = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();
  
  /*getting url and convert into array*/
  const location = useHistory().location;
  const arrayLocation = location.hash;
  const [seletedTab, setSeletedTab] = useState([]);

  const handlerAccordion = (e) => {
    //e.stopPropagation
    if (e !== null) {
      // if e === null, that means that an accordion item was collapsed rather than expanded. e will be non-null when an item is expanded
      localStorage.setItem("expandedItem", e);
      let selectTab = [...seletedTab, ...e];
      setSeletedTab([...selectTab]);
    } else {
      localStorage.setItem("expandedItem", 0);
    }
  };

  let retainAccordion = "";
  if (arrayLocation != "" || localStorage.expandedItem != "") {
    if (arrayLocation != "") {
      retainAccordion = arrayLocation.replace(/#/g, "");
    } else {
      retainAccordion = localStorage.expandedItem
        ? localStorage.expandedItem.split(",")
        : ["5"];
    }
  }

  let retainAccordionForSet = "";
  useEffect(() => {
    if (arrayLocation != "" || localStorage.expandedItem != "") {
      if (arrayLocation != "") {
        retainAccordionForSet = arrayLocation.replace(/#/g, "");
        setSeletedTab([retainAccordionForSet]);
      } else {
        retainAccordionForSet = localStorage.expandedItem
          ? localStorage.expandedItem.split(",")
          : ["5"];
        setSeletedTab([...retainAccordionForSet]);
      }
    }
  }, []);

  const displaySection = RoleBasedAccessDS(SpecialRoleExist, RoleCBPrivileges);

  useEffect(() => {
    IsTokenChecker(idToken) && getQuickAccess();
  }, [idToken]);

  function RoleBasedAccessDS(special_role, priviledges) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService = false } = priviledges;
        return roleCBThirdPartyFarmService;
      } else {
        const { roleCBAcreage = false, roleCBDecisionMaker = false } =
          priviledges;
        return roleCBAcreage || roleCBDecisionMaker;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  const [isHarvestQuickDataAccess, setIsHarvestQuickDataAccess] = useState(false);
  const getQuickAccess = async () => {
    setHarvestLoading(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `call_to_actions?select=*,is_active,page_listings!inner(slug)&page_listings.slug=eq.harvest-data-quick-view&is_active=eq.true&order=order_by.asc`
    )
      .then((resp) => {
        if (resp?.data?.data?.length > 0) {
          setIsHarvestQuickDataAccess(true);
        }
        setHarvestLoading(false);
      })
      .catch((e) => {
        setHarvestLoading(false);
        setIsHarvestQuickDataAccess(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
      .finally(() => {
        setHarvestLoading(false);
      });
  };
  return (
    <>
      {harvestLoading ? (
        <>
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </>
      ) : null}
      <div className="col-12">
        <div className="mt-5 mb-3 mt-lg-0 mb-lg-4">
          <Events type={"my-harvest"} />
        </div>
        <Accordion
          className="mt-0 harvest_news"
          defaultActiveKey={retainAccordion}
          alwaysOpen
          id="accordionMyFarm"
          onSelect={handlerAccordion}
        >
          {displaySection && (
            <Accordion.Item eventKey="deliveryApproval">
              <Accordion.Header>
                <img
                  src="./assets/images/icon_approved.svg"
                  className="img-30 right-space-10"
                  alt="Contracts_icon"
                />{" "}
                Delivery Approval
              </Accordion.Header>
              <Accordion.Body>
                {seletedTab && seletedTab.indexOf("deliveryApproval") > -1 && (
                  <DeliveryApproval {...props} action="myHarvest" />
                )}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {displaySection && isHarvestQuickDataAccess && (
            <Accordion.Item
              eventKey="harvestDataQuickView"
              className="hidden_print"
            >
              <Accordion.Header>
                <img
                  src="./assets/images/icon_recent_delivery.svg"
                  className="img-30 right-space-10"
                  alt="acerage_icon"
                />{" "}
                Harvest Data Quick View
              </Accordion.Header>
              <Accordion.Body>
                {seletedTab &&
                  seletedTab.indexOf("harvestDataQuickView") > -1 && (
                    <HarvestDataQuickView {...props} />
                  )}
              </Accordion.Body>
            </Accordion.Item>
          )}
          <WebCams />
        </Accordion>
      </div>
    </>
  );
};

export default MyHarvestDetails;
