/**
 * @component_Name : Ospac
 * @description : Ospac
 * @company : Photon
 * @author : Ashish Ranjan/14-10-2022
 **/
import React from 'react'
import PageLayout from "../../pageLayout";
import Body from "./body";

const Ospac = (props) => {
  return (
    <>
      <PageLayout {...props} body={<Body {...props} isLeftMenu="true" />}/>      
    </>
  );
}

export default Ospac