/**
 * @component_Name : AcreageHistory
 * @description : Show Acreage History grid list
 * @company : Photon
 * @author : Ashish Ranjan/22-08-2022
 **/
import React, { useEffect, useState, useCallback } from "react";
import "../../../css/loading.css";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  toDecimal,
  convertFilterArray,
  getFilteredData,
  setOptions,
  setObjToString,
  IsTokenChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  NumConverter,
} from "../../../utils/helper";
import { cascadeFilterFunc } from "../../../utils/cascadeFilter";
import { ConstantsVar } from "../../../utils/constants";
import WhiteCircleLoader from "../../whiteCircleLoader";
import ExportFile from "../../exportFile/exportFile";
import PdfFile from "../../exportFile/pdfFile";
import AcreageHistoryMobile from "./acreageHistoryMobile";
import Loading from "../../../components/global/loading";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../global/currentLocation";
import { useGbsDataCache } from "../../../services/gbsDataCache";
import useActiveCropYear from "../../customHooks/useActiveCropYear";
import moment from "moment";
import DataGridGroupingPagination from "../../global/dataGridGroupingPagination";

const AcreageHistory = (props) => {
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const state = useActiveCropYear();
  const {
    activeCropYear: currentCropYear = null,
    reducerToken: tokenId = null,
  } = state;
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const [listCropYear, setListCropYear] = useState([]);
  const [multiCrop, setMultiCrop] = useState({});
  const [multiContId, setMultiContId] = useState({});
  const [multiValue, setMultiValue] = useState({});
  const [disabled, setDisabled] = useState("disabled");
  const [gridItems, setGridItems] = useState([]);
  const [preSelectedArray, setPreSelectedArray] = useState([]);
  const [disablePreSelected, setDisablePreSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [isReset, setIsReset] = useState(true);
  const [hideExport, setHideExport] = useState(true);
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  /*========================================*/
  const [filterItems, setFilterItems] = useState([]);
  const [ContractOption, setContractOption] = useState([]);
  const [bogNameOption, setBogNameOption] = useState([]);
  const [verityOption, setVerityOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [ContractIds, setContractIds] = useState([]);
  const [reloadCropyear, setReloadCropyear] = useState(true);
  const [exportItems, setExportItems] = useState([]);
  const [pdfExportItems, setPDFExportItems] = useState([]);
  const [mobileItems, setMobileItems] = useState([]);
  /*========================================*/
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set());
  const [preSelectedContract, setPreSelectedContract] = useState(["all"]);
  const [relodContractSelect, setRelodContractSelect] = useState(false);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const { DropDownCBRoles = [] } = props;

  //Cache Implementation
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  useEffect(() => {
    if (data.data) {
      fetchBedsHistory(data?.data, changeEvent === true ? true : false);
    }
    // if (status === "error") {
    //   HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    // }
  }, [data, changeEvent, status]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const refReactDataGrid = useCallback((node) => {
    if (node !== null) {
      if (
        document.querySelectorAll(
          '.rdg [aria-expanded="false"] [aria-colindex="1"] .pointerNone'
        )?.length > 0
      ) {
        document
          .querySelectorAll(
            '.rdg [aria-expanded="false"] [aria-colindex="1"] .pointerNone'
          )
          .forEach((e) => {
            e.parentElement.style.pointerEvents = "none";
          });
      }
    }
  }, []);

  const columns = [
    {
      key: "HistoricalBedId",
      name: "Bed Id",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 80,
    },
    {
      key: "CropYear",
      name: "Crop Year",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 80,
      formatter({ row }) {
        return <span className="enablesTextWrapping">{row.CropYear}</span>;
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce((prev, row) => row.CropYear, 0)}
          </span>
        );
      },
    },
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 90,
      formatter({ row }) {
        return (
          <span className="enablesTextWrapping">{row.RelationshipNumber}</span>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce(
              (prev, { RelationshipNumber }) => RelationshipNumber,
              0
            )}
          </span>
        );
      },
    },
    {
      key: "CatContractName",
      name: "Contract Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 170,
      formatter({ row }) {
        return (
          <span className="enablesTextWrapping">{row.CatContractName}</span>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce(
              (prev, { CatContractName }) => CatContractName,
              0
            )}
          </span>
        );
      },
    },
    {
      key: "BogName",
      name: "Bog Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 105,
      formatter({ row }) {
        return <span className="enablesTextWrapping">{row.BogName}</span>;
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce((prev, { BogName }) => BogName, 0)}
          </span>
        );
      },
    },
    {
      key: "GroupingKey",
      name: "Harvest Bed",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 80,
      formatter({ row }) {
        return <span className="enablesTextWrapping">{row.HarvestBed}</span>;
      },
      groupFormatter(props) {
        const { childRows, isExpanded } = props;
        const temp = childRows.reduce((prev, { HarvestBed }) => HarvestBed, 0);
        return (
          <>
            {childRows.length > 1 ? (
              <span className="jc-between">
                <span className="d-block">{temp}</span>
                <span className="d-block">
                  <i
                    className={`fa ${
                      isExpanded == true ? "fa-minus-circle" : "fa-plus-circle"
                    } cursor_pointer table_accordion`}
                  ></i>
                </span>
              </span>
            ) : (
              <span className="pointerNone">{temp}</span>
            )}
          </>
        );
      },
    },
    {
      key: "LegacyVarietyCode",
      // key: "VarietyDescription",
      name: "Var",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 80,
      formatter({ row }) {
        return (
          <span className="enablesTextWrapping">{row.LegacyVarietyCode}</span>
        );
      },
      groupFormatter({ childRows }) {
        let isEqual = childRows
          .map((i) => i.LegacyVarietyCode)
          .every((val, i, arr) => val === arr[0]);
        return (
          <span className="enablesTextWrapping">
            {isEqual
              ? childRows.reduce(
                  (prev, { LegacyVarietyCode }) => LegacyVarietyCode,
                  0
                )
              : "MX"}
          </span>
        );
      },
    },
    {
      key: "PlantDate",
      name: "Plant Year",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 80,
      formatter({ row }) {
        const PlantDate = _.get(row, "PlantDate", null);
        return (
          <span className="enablesTextWrapping">
            {PlantDate ? moment.utc(PlantDate).format("YYYY") : ""}
          </span>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce((prev, { PlantDate }) => {
              if (prev && PlantDate) {
                return moment(prev).isSameOrBefore(PlantDate)
                  ? moment.utc(PlantDate).format("YYYY")
                  : moment.utc(prev).format("YYYY");
              } else if (prev || PlantDate) {
                if (prev) {
                  return moment.utc(prev).format("YYYY");
                } else {
                  return moment.utc(PlantDate).format("YYYY");
                }
              } else {
                return "";
              }
            }, "")}
          </span>
        );
      },
    },
    {
      key: "ProductionStatus",
      name: "Production Status",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 100,
      formatter({ row }) {
        return (
          <span className="enablesTextWrapping">{row.ProductionStatus}</span>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce(
              (prev, { ProductionStatus }) => ProductionStatus,
              0
            )}
          </span>
        );
      },
    },
    {
      key: "ExhibitAcres",
      name: "Acres",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-end",
      width: 80,
      formatter({ row }) {
        return (
          <span className="jc-end">
            <span>
              {toDecimal(
                NumConverter(row.ExhibitAcres)?.toFixed(2),
                2,
                "",
                "0.00"
              )}
            </span>
          </span>
        );
      },
      groupFormatter({ childRows }) {
        let totalAcres = 0;
        totalAcres = childRows.reduce(
          (prev, { ExhibitAcres }) => prev + ExhibitAcres,
          0
        );
        return (
          <span className="jc-end">
            <span>
              {toDecimal(NumConverter(totalAcres)?.toFixed(2), 2, "", "0.00")}
            </span>
          </span>
        );
      },
    },
    {
      key: "ProductionStatus",
      name: "Active Acres",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-end",
      width: 80,
      formatter({ row }) {
        return (
          <span className="jc-end">
            <span>
              {row.ProductionStatus == "Active"
                ? toDecimal(
                    NumConverter(row.ExhibitAcres)?.toFixed(2),
                    2,
                    "",
                    "0.00"
                  )
                : 0}
            </span>
          </span>
        );
      },
      groupFormatter({ childRows }) {
        let totalAcres = 0;
        totalAcres = childRows.reduce(
          (prev, { ProductionStatus, ExhibitAcres }) =>
            ProductionStatus == "Active" ? prev + ExhibitAcres : 0,
          0
        );
        return (
          <span className="jc-end">
            <span>
              {toDecimal(NumConverter(totalAcres)?.toFixed(2), 2, "", "0.00")}
            </span>
          </span>
        );
      },
    },
    {
      key: "AdjustedAcres",
      name: "Adj. Active Acres",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-end",
      width: 80,
      formatter({ row }) {
        return (
          <span className="jc-end">
            <span>
              {row.ProductionStatus == "Active"
                ? toDecimal(
                    NumConverter(row.AdjustedAcres)?.toFixed(2),
                    2,
                    "",
                    "0.00"
                  )
                : 0}
            </span>
          </span>
        );
      },
      groupFormatter({ childRows }) {
        let totalAcres = childRows.reduce(
          (prev, { ProductionStatus, AdjustedAcres }) =>
            ProductionStatus == "Active" ? prev + AdjustedAcres : 0,
          0
        );
        return (
          <span className="jc-end">
            <span>
              {toDecimal(NumConverter(totalAcres)?.toFixed(2), 2, "", "0.00")}
            </span>
          </span>
        );
      },
    },
    {
      key: "MapPage",
      name: "Map Page",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-end",
      formatter({ row }) {
        return (
          <span className="jc-end">
            <span>{row.MapPage}</span>
          </span>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="jc-end">
            <span>{childRows.reduce((prev, { MapPage }) => MapPage, 0)}</span>
          </span>
        );
      },
    },
    {
      key: "MapPageOf",
      name: "Map Of",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-end",
      formatter({ row }) {
        return (
          <span className="jc-end">
            <span>{row.MapPageOf}</span>
          </span>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="jc-end">
            <span>
              {childRows.reduce((prev, { MapPageOf }) => MapPageOf, 0)}
            </span>
          </span>
        );
      },
    },
    {
      key: "MapId",
      name: "Map Id",
      width: 90,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return <span className="enablesTextWrapping">{row.MapId}</span>;
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce((prev, { MapId }) => MapId, 0)}
          </span>
        );
      },
    },
    {
      key: "sumMapAcres",
      name: "Map Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-end",
      formatter({ row }) {
        return (
          <span className="jc-end">
            <span>
              {toDecimal(
                NumConverter(row.sumMapAcres)?.toFixed(2),
                2,
                "",
                "0.00"
              )}
            </span>
          </span>
        );
      },
      groupFormatter({ childRows }) {
        let totalMapAcres = childRows.reduce(
          (prev, { sumMapAcres }) => sumMapAcres,
          0
        );
        return (
          <span className="jc-end">
            <span>
              {toDecimal(
                NumConverter(totalMapAcres)?.toFixed(2),
                2,
                "",
                "0.00"
              )}
            </span>
          </span>
        );
      },
    },
  ];

  /**/
  // useEffect(() => {
  //   if (ContractOption.length > 0 && ContractOption.length == 1) {
  //     setRelodContractSelect(true);
  //     handlerContract(ContractOption, true);
  //     setTimeout(() => {
  //       setPreSelectedContract([ContractOption[0].id]);
  //       setRelodContractSelect(false);
  //     }, 0);
  //   }
  // }, [ContractOption]);

  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    setLoader(true);
    IsTokenChecker(tokenId) && fetchCropYear();
    setDisplayAccordian(
      localStorage.getItem("safariLowerVersion") === "true" || false
    );
  }, [tokenId]);

  useEffect(() => {
    setBogNameOption([]);
    setVerityOption([]);
    if (IsTokenChecker(tokenId) && currentCropYear > 0) {
      setPreSelectedArray([`${currentCropYear}`]);
      setMultiCrop({ cropyear: `${currentCropYear}` });
      const fetchContract = async () => {
        await gbsDataCommonMethod(
          tokenId,
          accounts,
          `persons/${personId}/contracts`
        )
          .then((resp) => {
            const data = [...resp.data];
            const filteredData = FilterContractByRole(
              data,
              accounts,
              DropDownCBRoles
            );
            let contractId = filteredData
              .map((x) => x["RelationshipId"])
              ?.toString(); //'2821'
            contractId = _.orderBy(contractId.split(","), [], ["asc"]);
            let url = `bed-histories?cropyear=${currentCropYear}&relationshipid=${contractId}&includesplitbeds=1`;
            setContractIds(contractId);
            setSearchURL(url);
            setChangeEvent(true);
            // fetchBedsHistory(url,true);
          })
          .catch((error) => {
            setLoading(false);
            /*setLoader(false);*/
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          });
      };
      fetchContract();
    }
  }, [currentCropYear, tokenId]);

  /*validation on mention filter options*/
  useEffect(() => {
    const ContractAccess = multiContId && multiContId?.contract_id;
    const CropAccess = multiCrop && multiCrop?.cropyear;
    if (ContractAccess && CropAccess) {
      setSearchBtnDisabled(false);
      setDisabled(false);
      setIsReset(false);
    } else if (ContractAccess || CropAccess) {
      setDisabled(true);
      setIsReset(false);
      setSearchBtnDisabled(true);
    } else {
      setDisabled(true);
      setIsReset(true);
      setSearchBtnDisabled(true);
    }
  }, [multiContId, multiCrop]); /*End*/

  // console.log({ multiContId, multiCrop, multiValue });

  const fetchBedsHistory = async (data) => {
    let resultData = [...data];
    let filteredData = FilterContractByRole(
      resultData,
      accounts,
      DropDownCBRoles,
      "Id"
    );
    filteredData.forEach((r) => {
      // You are adding a property to the object through concatenation of interpolation
      r.GroupingMapCrop = `${r.CropYear}_${r.MapId}`;
      r.GroupingKey = `${r.CropYear}_${r.Id}_${r.HarvestBed}`;
      r.VarietyDesc = r.VarietyDescription;

      r.VarietyDescription = r.VarietyDescription.toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
    });

    let sumData = [];
    if (filteredData.length > 0) {
      const resultArray = [...filteredData];
      const groupedData = _.groupBy(resultArray, "GroupingMapCrop");
      Object.values(groupedData).map((x) => {
        sumData[x[0].GroupingMapCrop] = x.reduce(
          (prev, { ExhibitAcres }) => prev + ExhibitAcres,
          0
        );
      });
    }

    let elementIndex = [];
    /*Multicolumn sort*/
    filteredData.sort(
      (a, b) =>
        b.CropYear - a.CropYear ||
        a.RelationshipNumber.localeCompare(b.RelationshipNumber, "en", {
          numeric: true,
        }) ||
        a.HarvestBed.localeCompare(b.HarvestBed, "en", { numeric: true })
    );
    /*End*/
    filteredData.forEach((x) => {
      x.sumMapAcres = sumData[x.GroupingMapCrop];
      elementIndex.push(x);
    });

    if (
      filteredData.length > 0 &&
      data !== "Contract and CropYear must be provided"
    ) {
      let contractList = setOptions(
        filteredData,
        "Id",
        "RelationshipNumber",
        "Name"
      );
      setFilterItems(elementIndex);
      /*===============================
      when there are oinly one contract.
      =================================*/
      contractList = _.orderBy(
        contractList,
        ["ContractStatus", "RelationshipNumber"],
        ["asc", "asc"]
      );
      // if (contractList.length == 1 && initialLoad == true) {
      //   setContractOption(contractList);
      //   const defaultListData = filteredData.filter((item) => {
      //     return (
      //       setObjToString([contractList[0]])
      //         ?.toLowerCase()
      //         ?.indexOf(item?.Id?.toString().toLowerCase()) !== -1
      //     );
      //   });
      //   if (defaultListData.length > 0) {
      //     setIsListingHide(false);
      //     loadFilter(defaultListData);
      //     setGridItems(defaultListData);
      //     setHideExport(false);
      //     setNoRecordErrorMsg("");
      //     setisMobFilter(false);
      //   }
      // } else {
      setContractOption(contractList);
      // }
      OtherFilterClick();
      /*End*/
    } else {
      setRelodContractSelect(true);
      setTimeout(() => {
        setPreSelectedContract([]);
        setRelodContractSelect(false);
      }, 100);
      setContractOption([]);
      setBogNameOption([]);
      setVerityOption([]);
      setDisabled(true);
      setHideExport(true);
      setGridItems([]);
      setFilterItems([]);
    }
    setLoader(false);
  };

  function OtherFilterClick() {
    //need to update
    setTimeout(() => {
      if (
        document.querySelector(
          "#bognames .optionListContainer .highlightOption"
        ) !== null
      ) {
        document
          .querySelector("#bognames .optionContainer .highlightOption")
          .click();
      }
    }, 500);
    setTimeout(() => {
      if (
        document.querySelector(
          "#varietyids .optionListContainer .highlightOption"
        ) !== null
      ) {
        document
          .querySelector("#varietyids .optionContainer .highlightOption")
          .click();
      }
    }, 500);
  }

  const fetchCropYear = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then((resp) => {
        const cropYear = resp.data.length > 0 && resp.data.splice(0, 15);
        const multiSelectCropYears = convertFilterArray(cropYear, {
          id: "CropYear",
          name: "CropYear",
        });
        setListCropYear(multiSelectCropYears);
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  // Contract select change event
  const handlerContract = (val, initial_load = false) => {
    setMultiValue(null); /*set null to all filter */

    let filter = filterItems.filter((item) => {
      return (
        setObjToString(val)
          ?.toLowerCase()
          ?.indexOf(item?.Id?.toString().toLowerCase()) !== -1
      );
    });
    if (initial_load == false) {
      setIsListingHide(true);
    }
    setBogNameOption(setOptions(filter, "BogName", "BogName"));
    setVerityOption(setOptions(filter, "VarietyId", "VarietyDesc"));
  };

  const handlerContractDel = (selectedList, removedItem) => {
    setMultiValue(null); /*set null to all filter */
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setMultiContId(null); /*set null to contract filter */
    } else {
      let filter = filterItems.filter((item) => {
        return (
          setObjToString(selectedList)
            ?.toLowerCase()
            ?.indexOf(item?.Id?.toString().toLowerCase()) !== -1
        );
      });
      setBogNameOption(setOptions(filter, "BogName", "BogName"));
      setVerityOption(setOptions(filter, "VarietyId", "VarietyDesc"));
    }
  };

  const handlerCropYear = (val, type) => {
    setPreSelectedContract([]);
    setMultiValue(null); /*set null to all filter */
    let cropyears = "";
    const cropIds = val.map((x) => x.id);
    const maxYear = Math.max(...cropIds);
    setIsListingHide(true);

    if (type === "selected") {
      setDisablePreSelected(false);
      setPreSelectedArray([]);
    }
    if (val.length > 1) {
      if (val.length == 15 && type === "deSelected") {
        cropyears = currentCropYear;
        setReloadCropyear(false);
        setTimeout(() => {
          setPreSelectedArray([currentCropYear]);
          setDisablePreSelected(true);
          setReloadCropyear(true);
        }, 0);
      } else {
        cropyears = setObjToString(val);
        setDisablePreSelected(false);
      }
    } else if (val.length == 1) {
      cropyears = setObjToString(val);
      setReloadCropyear(false);
      setTimeout(() => {
        setPreSelectedArray([maxYear]);
        setDisablePreSelected(true);
        setReloadCropyear(true);
      }, 0);
    }
    if (cropyears !== "") {
      const url = `bed-histories?cropyear=${_.orderBy(
        cropyears.toString().split(","),
        [],
        ["asc"]
      )}&relationshipid=${_.orderBy(
        ContractIds.toString().split(","),
        [],
        ["asc"]
      )}&includesplitbeds=1`;
      setSearchURL(url);
      url === searchURL && setChangeEvent(changeEvent + 1);
      //fetchBedsHistory(url);
    }
  };

  // On click search
  const handlerSubmit = () => {
    setLoading(true); /*set loader to search button*/
    let filter = [...filterItems];
    if (multiContId?.contract_id) {
      filter = getFilteredData(filter, multiContId.contract_id, "Id");
    }
    if (multiValue?.bognames) {
      filter = getFilteredData(filter, multiValue.bognames, "BogName");
    }
    if (multiValue?.varietyids) {
      filter = getFilteredData(filter, multiValue.varietyids, "VarietyId");
    }
    if (
      filter.length > 0 &&
      filter !== "Contract and CropYear must be provided"
    ) {
      const defaultListData = filter.map((list) => {
        return {
          ...list,
          CatContractName: `${list?.Name || ""} ${list?.Name2 || ""} ${
            list?.ContractIdentifier || ""
          }`.trim(),
        };
      });
      loadFilter(defaultListData);
      setIsListingHide(false);
      setGridItems(defaultListData);
      setHideExport(false);
      setNoRecordErrorMsg("");
      setisMobFilter(false);
    } else {
      setIsListingHide(true);
      setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
    }
    setLoading(false); /*set loader to search button*/
  };

  function loadFilter(filterData) {
    let sortedData = _.groupBy(filterData, (item) => {
      return [item["GroupingKey"]];
    });

    const MobileItems = Object.values(sortedData).map((item) => {
      try {
        let isEqual = item
          .map((i) => i.LegacyVarietyCode)
          .every((val, i, arr) => val === arr[0]);
        let variety = "";
        if (isEqual) variety = item[0].LegacyVarietyCode;
        else variety = "MX";

        const PlantDate = _.get(item, "0.PlantDate", null);
        const Adjusted_Active_Acres_Val = toDecimal(
          NumConverter(
            item.reduce(
              (prev, { ProductionStatus, AdjustedAcres }) =>
                ProductionStatus == "Active" ? prev + AdjustedAcres : 0,
              0
            )
          ).toFixed(2),
          2,
          "",
          "0.00"
        );
        const AcresVal = toDecimal(
          NumConverter(
            item.reduce((prev, { ExhibitAcres }) => prev + ExhibitAcres, 0)
          ).toFixed(2),
          2,
          "",
          "0.00"
        );
        const MapAcresVal = toDecimal(
          NumConverter(item[0].sumMapAcres).toFixed(2),
          2,
          "",
          "0.00"
        );
        const ActiveAcresVal = toDecimal(
          NumConverter(
            item.reduce(
              (prev, { ProductionStatus, ExhibitAcres }) =>
                ProductionStatus == "Active" ? prev + ExhibitAcres : 0,
              0
            )
          ).toFixed(2),
          2,
          "",
          "0.00"
        );
        return {
          //item.reduce((prev, { ExhibitAcres }) => prev + ExhibitAcres, 0)
          Crop_Year: item[0].CropYear,
          Contract_Number: item[0].RelationshipNumber,
          Contract_Name: item[0].Name,
          Name2: item[0].Name2,
          Identifier: item[0].ContractIdentifier,
          CatContractName: `${item[0]?.Name || ""} ${item[0]?.Name2 || ""} ${
            item[0]?.ContractIdentifier || ""
          }`.trim(),
          Bog_Name: item[0].BogName,
          Harvest_Bed: item[0].HarvestBed,
          Variety: variety, //item[0].LegacyVarietyCode,
          Plant_Year: PlantDate ? moment.utc(PlantDate).format("YYYY") : null,
          Production_Status: item[0].ProductionStatus,
          Acres: AcresVal,
          Active_Acres: ActiveAcresVal,
          Adjusted_Active_Acres: Adjusted_Active_Acres_Val,
          Map: item[0].MapPage,
          Map_Of: item[0].MapPageOf,
          Map_Id: item[0].MapId,
          Map_Acres: MapAcresVal,
        };
      } catch (error) {
        HandleErrorTrackAndToast(error, true);
        return {};
      }
    });
    setMobileItems(MobileItems);

    const overWirteMobData = Object.values(sortedData).map((item) => {
      try {
        const PlantDate = _.get(item, "0.PlantDate", null);
        const Adjusted_Active_Acres_Val = toDecimal(
          NumConverter(
            item.reduce(
              (prev, { ProductionStatus, AdjustedAcres }) =>
                ProductionStatus == "Active" ? prev + AdjustedAcres : 0,
              0
            )
          ).toFixed(2),
          2,
          "",
          "0.00"
        );
        const AcresVal = toDecimal(
          NumConverter(
            item.reduce((prev, { ExhibitAcres }) => prev + ExhibitAcres, 0)
          ).toFixed(2),
          2,
          "",
          "0.00"
        );
        const MapAcresVal = toDecimal(
          NumConverter(item[0].sumMapAcres).toFixed(2),
          2,
          "",
          "0.00"
        );
        const ActiveAcresVal = toDecimal(
          NumConverter(
            item.reduce(
              (prev, { ProductionStatus, ExhibitAcres }) =>
                ProductionStatus == "Active" ? prev + ExhibitAcres : 0,
              0
            )
          ).toFixed(2),
          2,
          "",
          "0.00"
        );
        let isEqual = item
          .map((i) => i.LegacyVarietyCode)
          .every((val, i, arr) => val === arr[0]);
        return {
          //item.reduce((prev, { ExhibitAcres }) => prev + ExhibitAcres, 0)
          Crop_Year: item[0].CropYear,
          Contract_Number: item[0].RelationshipNumber,
          Contract_Name: item[0].Name,
          Name2: item[0].Name2,
          Identifier: item[0].ContractIdentifier,
          Bog_Name: item[0].BogName,
          Harvest_Bed: item[0].HarvestBed,
          Variety: isEqual ? item[0].VarietyDescription : "Mixed",
          Plant_Year: PlantDate ? moment.utc(PlantDate).format("YYYY") : null,
          Production_Status: item[0].ProductionStatus,
          Acres: AcresVal,
          Active_Acres: ActiveAcresVal,
          Adjusted_Active_Acres: Adjusted_Active_Acres_Val,
          Map: item[0].MapPage,
          Map_Of: item[0].MapPageOf,
          Map_Id: item[0].MapId,
          Map_Acres: MapAcresVal,
        };
      } catch (error) {
        HandleErrorTrackAndToast(error, true);
        return {};
      }
    });
    setPDFExportItems(overWirteMobData);

    const ExportWriteData = IsArrayNotEmpty(overWirteMobData)
      ? overWirteMobData.map((list) => {
          return {
            ...list,
            Acres: { t: "n", v: list.Acres, z: "0.00" },
            Active_Acres: { t: "n", v: list.Active_Acres, z: "0.00" },
            Adjusted_Active_Acres: {
              t: "n",
              v: list.Adjusted_Active_Acres,
              z: "0.00",
            },
            Map_Acres: { t: "n", v: list.Map_Acres, z: "0.00" },
          };
        })
      : [];
    setExportItems(ExportWriteData);
  }

  const handlerOtherFilter = (
    selectedList,
    currentItem,
    action,
    request = ""
  ) => {
    let multival = { ...multiValue };
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    if (action == "selected") {
      setNoRecordErrorMsg("");
    }

    const searchParam = {
      Contract_id: ["Id"],
      Bog_name: ["BogName", "BogName"],
      Varity_id: ["VarietyId", "VarietyDesc"],
      Map_id: ["MapId", "MapId"],
    };
    let filter = [...filterItems];
    cascadeFilterFunc({
      filter,
      selectedList,
      action,
      request,
      multiContId,
      multival,
      searchParam,
      setBogNameOption,
      setVerityOption,
    });
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setIsReset(true); /*disabled Reset button*/
    setMultiContId(null); /*set null to contract filter */
    setMultiValue(null); /*set null to all filter */
    setMultiCrop(null);
    setDisabled(true); /*disabled filter dropdown*/
    setSearchBtnDisabled(true); /*disabled search button*/
    setGridItems([]); /*set empty to grid Item */
    setHideExport(true);
    setIsListingHide(true);
    setNoRecordErrorMsg("");
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  return (
    <>
      {/*<!-- Acreage history block starts -->*/}
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"></span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>

        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3 hide_first_option">
              <div className="crop_year_label custom_dropdown">
                {/*<!-- <span>Crop Year</span> -->*/}
                {reloadCropyear && (
                  <MultiDropDown
                    optionList={listCropYear}
                    prompt="Select"
                    value={multiCrop}
                    onChange={(val) => handlerCropYear(val, "selected")}
                    label="cropyear"
                    usestate={setMultiCrop}
                    disabled=""
                    onDelete={(val, removedItem) =>
                      handlerCropYear(val, "deSelected", removedItem)
                    }
                    preSelectedOpt={preSelectedArray}
                    disablePreSelected={disablePreSelected}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {/*<!-- <span>Contract Number</span> -->*/}
                {relodContractSelect == false && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) => handlerContract(val)}
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled=""
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    preSelectedOpt={preSelectedContract}
                    optionNotFound={{ contract_id: noContractsFound }}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="bog_name custom_dropdown">
                {/*<!-- <span>Bog Name</span> -->*/}
                <MultiDropDown
                  optionList={bogNameOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "bogname"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "bogname"
                    )
                  }
                  label="bognames"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="variety custom_dropdown">
                {/*<!-- variety -->*/}
                <MultiDropDown
                  optionList={verityOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "varityid"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "varityid"
                    )
                  }
                  label="varietyids"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                data-category="Web"
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>

        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? "show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            data-category="mobile"
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>
      {/*<!-- Filter Dropdown ends -->*/}
      {isListingHide == false ? (
        <>
          <div className="row mt-2">
            <div className="col-12 col-lg-6">
              <div className="float-start">
                <h5 className="text-14 fw-bold mt-3 mt-lg-2 mob-text-16">
                  Total Bed Count: {mobileItems.length}
                </h5>
              </div>
            </div>
            <div className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} `}>
              <div className="float-end mb-2">
                <span>
                  <PdfFile pdfData={pdfExportItems} fileName="AcreageHistory" />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="csv"
                    exportData={exportItems}
                    fileName="AcreageHistory"
                  />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="xlsx"
                    exportData={exportItems}
                    fileName="AcreageHistory"
                  />
                </span>
              </div>
            </div>
          </div>

          {/*<!-- Responsive table starts -->*/}
          {!displayAccordian && (
            <div
              className={`visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block d-none d-lg-block /*${
                isReset ? "d-none" : "d-none d-lg-block"
              }*/`}
            >
              <DataGridGroupingPagination
                uniqueIdentifier="HistoricalBedId"
                gridData={gridItems}
                columns={columns}
                isPagination={true}
                itemsPerPage={10}
                columnResize={false}
                classNames="contain-block-size-reset bot-mspace-20 border-radius-6 acreage_grid_bg_white acreage-history-table"
                groupByKey={["GroupingKey"]}
                getExpandedGroupIds={expandedGroupIds}
                setExpandedGroupIds={setExpandedGroupIds}
                refReactDataGrid={refReactDataGrid}
                minimumRowHeight={80}
                dynamicHeaderHeight={80}
                autoRowHeight={true}
              />
            </div>
          )}
          {/* <!-- Responsive table starts --> */}
          <div
            className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0  ${
              !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
            }  ${hideExport ? "d-none" : "d-lg-block"} `}
          >
            <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
              <AcreageHistoryMobile listingData={mobileItems} />
            </div>
          </div>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
    </>
  );
};

export default AcreageHistory;
