import React, { Fragment, useEffect, useState, useRef } from "react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../global/loading";
import parse from "html-react-parser";
import {
  sortByField,
  IsArrayNotEmpty,
  IsObjectChecker,
  groupArrayByKey,
  IsTokenChecker,
  IsStringChecker,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import { Accordion } from "react-bootstrap";
import { BreadcrumbWithParams } from "../global/breadCrumb";
import LeftMenu from "./leftMenu";
import QuickLinks from "../home/quickLinks";
import { Link } from "react-router-dom";

const DetailPageCtaContentBlock = (props) => {
  const {
    slugId,
    slug = "",
    breadcrumbtitle,
    pagetitleDisplay = false,
    isLeft = false,
    quickLinks = false,
    ...rest
  } = props;
  const [loader, setLoader] = useState(false);
  const { accounts } = useMsal();
  const tokenId = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [printClass] = useState({ ["gm"]: "", ["fca"]: "" });
  const accordian = useRef(null);
  let count = 0;
  const [, setGridDesingData] = useState([]);
  const getDDContractPerformMultiContract = async (
    tokenId,
    accounts,
    urlArray
  ) => {
    var resp = await Promise.all(
      urlArray.map(async (url) => {
        return await cmsDataCommonMethod(tokenId, accounts, url)
          .then((res) => res.data)
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    if (resp.length > 0) {
      let newData = [];
      resp.map((x) => newData.push(x.data));
      return [...resp[0].data, ...resp[1].data];
    }
  };

  useEffect(() => {
    setLoader(true);
    if (IsTokenChecker(tokenId)) {
      fetchGoverningDocuments();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const fetchGoverningDocuments = async () => {
    const fetchApiUrl = [];
    fetchApiUrl.push(
      `call_to_actions?select=*,component_listings!inner(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon),page_listings!inner(id,title_en,title_fr,title_es,slug)&page_listings.id=eq.${slugId}&is_active=eq.true`
    );
    fetchApiUrl.push(
      `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,title_fr,title_es,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.id=eq.${slugId}&is_active=eq.true`
    );
    if (IsTokenChecker(tokenId) && fetchApiUrl != "") {
      setLoader(true);
      await getDDContractPerformMultiContract(tokenId, accounts, fetchApiUrl)
        .then((resp) => {
          setLoader(false);
          let tempData = [...resp];
          let groupedData = groupArrayByKey(
            tempData,
            "component_listings.accordion_name_en"
          );
          setPageTitle(tempData[0]);
          let groupData = safeArrayCheck(
            tempData.filter((a, b, c) => UniqueObjectArray(a, b, c))
          );
          let componentArr = sortByField(
            groupData.map((list) => _.get(list, "component_listings", {}))
          );
          setAllData(IsObjectChecker(groupedData) ? groupedData : []);
          setComponentList(componentArr);
          setGridDesingData(IsObjectChecker(groupedData) ? groupedData : []);
        })
        .catch((error) => {
          setLoader(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };

  const DesignComponentList = ({ data = null }) => {
    const designTemplate =
      data[0].component_listings?.css_class?.toString()?.toLowerCase() || "";
    if (designTemplate === "grid") {
      return <Fragment>{setDesignTemplate(designTemplate, data)}</Fragment>;
    } else {
      return (
        <>
          {data != null &&
            Object.values(data)?.map((item, index) => {
              const designTemplate =
                item.component_listings?.css_class?.toString()?.toLowerCase() ||
                "";
              if (designTemplate === "accordion") {
                count = count + 1;
              }
              return (
                <Fragment key={index}>
                  {setDesignTemplate(designTemplate, item)}
                </Fragment>
              );
            })}
        </>
      );
    }
  };

  const setDesignTemplate = (designID, item) => {
    switch (designID) {
      case "plain-text":
        return (
          <>
            <h5 className="fw-bold pb-3 hidden_print">
              {_.get(
                item.component_listings,
                ["accordion_name_" + i18n.language],
                ""
              )}
            </h5>
            <p className="pb-2 hidden_print">
              {parse(item?.["content_" + i18n.language] || "")}
            </p>
          </>
        );
        break;
      case "accordion":
        let imgURL = _.get(item.component_listings, ["icon_url"], "") || "";
        return (
          <Fragment key={item.id}>
            <Accordion
              defaultActiveKey={count === 1 ? item.id : ""}
              key={item.id}
            >
              <Accordion.Item
                ref={accordian}
                eventKey={count === 1 ? item.id : "-"}
                key={item.id}
                className={`${printClass.gm}`}
              >
                <Accordion.Header>
                  {imgURL != "" && (
                    <img
                      src={imgURL}
                      className="width-30 right-space-10"
                      alt={_.get(
                        item.component_listings,
                        ["accordion_name_" + i18n.language],
                        ""
                      )}
                    />
                  )}
                  {_.get(
                    item.component_listings,
                    ["accordion_name_" + i18n.language],
                    ""
                  )}
                </Accordion.Header>
                <Accordion.Body>
                  {item?.component_listings?.show_print_icon && (
                    <div className="row">
                      <div className="col-12">
                        <div className="float-end mb-2">
                          <span className="float-end">
                            <button
                              className="printButonCss printIconPadding d-none d-lg-inline-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title=""
                              data-bs-original-title="Print"
                              aria-label="Print"
                            >
                              <img
                                src={`${window.location.origin}/assets/images/print_icon.svg`}
                                className="img-40 me-2"
                                alt="Print_Icon"
                                onClick={() => {
                                  window.print();
                                }}
                              />
                            </button>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      {parse(item?.["content_" + i18n.language] || "")}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Fragment>
        );
        break;
      case "grid":
        const contentBlock = item.filter((i) => !i.hasOwnProperty("title_en"));
        let ctaList = item.filter((i) => i.hasOwnProperty("title_en"));
        ctaList = _.orderBy(ctaList || [], ["order_by"], ["asc"]);
        return (
          <>
            <div className="row">
              <div className="col-12 ">
                {ctaList.length > 0 && (
                  <h4 className="fw-bold mt-3 mt-lg-0">
                    {
                      ctaList[0].component_listings[
                        `accordion_name_${i18n.language}`
                      ]
                    }
                  </h4>
                )}
                {contentBlock.length === 1 &&
                  parse(contentBlock[0][`content_${i18n.language}`] || "")}
                <div className="row">
                  {ctaList.map((i, index) => {
                    const img = _.get(i, ["feature_image_url"], "");
                    const url = _.get(i, ["url"], "#");
                    const title = _.get(i, ["title_" + i18n.language], "");
                    const button = _.get(
                      i,
                      ["button_text_" + i18n.language],
                      ""
                    );
                    const open_new_window = _.get(
                      i,
                      ["open_new_window"],
                      false
                    );
                    const internal_url = `${window.location.pathname}${url}`;
                    return (
                      <div className="col-12 col-lg-4" key={index}>
                        <div className="card card-shadow mb-3 mb-lg-5">
                          {img && (
                            <img src={img} className="card-img-top" alt="..." />
                          )}
                          <div className="card-body pt-0 px-0">
                            <div className="row">
                              <div className="col-11 ms-1">
                                <ul className="list-group text-center">
                                  <li className="list-group-item border-0 pt-4">
                                    <Link
                                      to={
                                        open_new_window
                                          ? { pathname: url }
                                          : internal_url
                                      }
                                      target={
                                        open_new_window ? "_blank" : "_self"
                                      }
                                      className="text-18 d-block "
                                    >
                                      {" "}
                                      {title}{" "}
                                    </Link>
                                    <div className={"mt-2"}>
                                      <Link
                                        to={
                                          open_new_window
                                            ? { pathname: url }
                                            : internal_url
                                        }
                                        target={
                                          open_new_window ? "_blank" : "_self"
                                        }
                                        className={`
                                                                            ${
                                                                              button
                                                                                ? "btn btn-primary"
                                                                                : "red_color d-block"
                                                                            }
                                                                        ${
                                                                          img !==
                                                                          null
                                                                            ? "mt-"
                                                                            : ""
                                                                        } `}
                                      >
                                        {button ? (
                                          <Fragment>{button}</Fragment>
                                        ) : (
                                          <Fragment>
                                            <i className="fa fa-file-o"></i>
                                            {` PDF`}
                                          </Fragment>
                                        )}
                                      </Link>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
        break;
      case "blue-title-box":
        return (
          <div className="col-12 col-lg-12 zooming_block">
            <div className="row">
              <div className="col-12">
                <div className="card-shadow white_bg border-radius-6 mt-5 mt-lg-0">
                  <h4 className="card-header white_color ver-space-15 left-border-radius right-border-radius">
                    {_.get(
                      item.component_listings,
                      ["accordion_name_" + i18n.language],
                      ""
                    )}
                  </h4>
                  <div className="card-body mb-4 payments_equity">
                    {parse(item?.["content_" + i18n.language] || "")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "slider":
        break;
    }
  };

  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {(isLeft || quickLinks) && (
        <div className="row">
          <div className="row">
            <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
              <BreadcrumbWithParams
                params={
                  breadcrumbtitle ? breadcrumbtitle.replace(/-/g, " ") : null
                }
                {...rest}
              />
            </div>
          </div>
          <div className="row">
            <div className="d-none d-lg-block col-lg-2 hidden_print">
              <LeftMenu {...rest} slug={slug} />
              <QuickLinks {...rest} slug={slug} />
            </div>
            <div className="col-12 col-lg-10 cta-detail-page">
              {pagetitleDisplay && (
                <div className="row">
                  <div className="col-12">
                    <h4 className="fw-bold pt-5 pt-lg-2 pb-4 hidden_print">
                      {pageTitle?.page_listings?.["title_" + i18n.language]}
                    </h4>
                  </div>
                </div>
              )}
              {IsArrayNotEmpty(componentList) && (
                <>
                  {componentList?.map((item, index) => {
                    const name_en = item["accordion_name_en"] || "";
                    const data = IsStringChecker(name_en)
                      ? sortByField(_.get(allData, name_en, []))
                      : [];
                    return (
                      <Fragment key={index}>
                        <DesignComponentList data={data} />
                      </Fragment>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {isLeft === false && quickLinks === false && (
        <>
          <div className=" col-12 col-lg-12 d-none d-lg-block">
            <BreadcrumbWithParams
              params={
                breadcrumbtitle ? breadcrumbtitle.replace(/-/g, " ") : null
              }
              {...rest}
            />
          </div>
          <div className="cta-detail-page">
            {pagetitleDisplay && (
              <div className="row">
                <div className="col-12">
                  <h4 className="fw-bold pt-5 pt-lg-2 pb-4 hidden_print">
                    {pageTitle?.page_listings?.["title_" + i18n.language]}
                  </h4>
                </div>
              </div>
            )}
            {IsArrayNotEmpty(componentList) && (
              <>
                {componentList?.map((item, index) => {
                  const name_en = item["accordion_name_en"] || "";
                  const data = IsStringChecker(name_en)
                    ? sortByField(_.get(allData, name_en, []))
                    : [];
                  return (
                    <Fragment key={index}>
                      <DesignComponentList data={data} />
                    </Fragment>
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default DetailPageCtaContentBlock;
