/**
 * @component_Name : ChangePassword
 * @description : Change Password
 * @company : Photon
 * @author : Ashish Ranjan/05-12-2022
 **/
import React, { useState, useRef, Fragment, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { b2cPolicies } from "../../configs/authConfig";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import Loading from "../global/loading";
import {
  getResolvedLanguage,
  IsStringChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  IsArrayNotEmpty,
  getCookie,
  IsTokenChecker,
} from "../../utils/helper";
import { InputGroup, Button, Alert, Modal } from "react-bootstrap";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import { useSelector, useDispatch } from "react-redux";
import { cmsDataCommonMethod, gbsDataCommonMethod } from "../../services/gbsData";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
  try {
    if (typeof phone === "undefined") return true;
    else return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const ChangePassword = (props) => {
  const internationPhone = useRef(null)

  const [forminputs, setFormInputs] = useState({ InternationalPhone: "" });
  const [currentMFAPhone, setCurrentMFAPhone] = useState("");

  const InternationalPhoneValid = isPhoneValid(forminputs.InternationalPhone);

  const { RolePBPrivileges = {}, RoleCBPrivileges } = props || {};

  const { ori_user_access = false, imp_user_access = false } =
    RetainOrginalUserImpersonation();
  const rolePBAllowImpersonation = ori_user_access || imp_user_access;

  function RetainOrginalUserImpersonation() {
    const { rolePBAllowImpersonation: imp = false } = RolePBPrivileges || {};
    try {
      let impersonate_item;
      if (getCookie("selected_one") === 'true')
        impersonate_item = localStorage.getItem("selected_one");
      else
        localStorage.removeItem("selected_one");
      if (IsStringChecker(impersonate_item)) {
        const impersonate_item_json = JSON.parse(impersonate_item);
        if (impersonate_item_json) {
          const { rolePBAllowImpersonation: ori } = impersonate_item_json;
          return { ori_user_access: ori, imp_user_access: imp };
        }
        return { ori_user_access: imp, imp_user_access: false };
      }
      return { ori_user_access: imp, imp_user_access: false };
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return { ori_user_access: false, imp_user_access: false };
    }
  }

  const { i18n } = useTranslation();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const { accounts, instance } = useMsal();
  const {
    email,
    gbsPersonId: PersonId,
    name,
    username,
  } = _.get(accounts, "0.idTokenClaims", {});
  const localAccountId = _.get(accounts, `0.localAccountId`, "");

  const [user_impersonation_loading, setUserImpersonationLoading] =
    useState(false);
  const [user_data_loading, setUserDataLoading] = useState(false);
  const [user_data_options, setUserDataOptions] = useState([]);
  const [selected_user, setSelectedUser] = useState({});
  const async_typeahead_ref = useRef(null);

  const [loader, setLoader] = useState(false);
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });
  const [confirmPopup, setConfirmPopup] = useState(false);


  const handleProfileEdit = () => {
    /* 
    if(inProgress === InteractionStatus.None){
      instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
    } */
    instance.acquireTokenRedirect(b2cPolicies.authorities.forgotPassword);
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getPhoneNumber();
    }
  }, [idToken]);

  const getPhoneNumber = async () => {
    const url = `get-b2c-login-mobile-number`;
    const data = {
      "objectId": localAccountId
    }
    setLoader(true);
    await cmsDataCommonMethod(idToken, accounts, url, 'POST', data)
      .then((resp) => {
        const tempData = _.get(resp, "data", []);
        // console.log(tempData);
        if (tempData.status === 200) {
          setCurrentMFAPhone(tempData?.mobilePhone || '')
          setFormInputs({ InternationalPhone: tempData?.mobilePhone || '' })
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  }

  const handlePhoneEdit = async () => {
    const url = `update-b2c-login-mobile-number`;
    const data = {
      "objectId": localAccountId,
      "mobilePhone": forminputs.InternationalPhone
    }
    setLoader(true);
    await cmsDataCommonMethod(idToken, accounts, url, 'POST', data)
      .then((resp) => {
        const tempData = _.get(resp, "data", []);
        // console.log(tempData);
        if (tempData.status === 200) {
          setAlertOpen({
            isActive: true,
            message: 'MFA Mobile Phone Updated Successfully',
          });
          setCurrentMFAPhone(forminputs.InternationalPhone);
          // setFormInputs({ InternationalPhone: '' })
        }
        handleClose();
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  function GetUserOptions(options) {
    try {
      if (IsArrayNotEmpty(options)) {
        return options.map((li) => {
          const {
            email,
            firstName,
            lastName,
            middleName,
          } = li;
          const names = [firstName, middleName, lastName];
          const full_name = names.reduce((acc, li, i) => {
            if (IsStringChecker(li)) {
              acc = `${acc}${i === 0 ? "" : " "}${li}`;
            }
            return acc;
          }, "");
          const user_impersonation = `${full_name} (${email})`;
          return { ...li, user_impersonation };
        });
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const onUserDataSearch = (query) => {
    if (IsStringChecker(query)) {
      setUserDataLoading(true);
      gbsDataCommonMethod(
        idToken,
        accounts,
        `persons/search-person/${query}`,
        "GET"
      )
        .then((res) => {
          const data = safeArrayCheck(res?.data);
          const options = GetUserOptions(data);
          setUserDataOptions(options);
        })
        .catch((e) => {
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        })
        .finally(() => {
          setUserDataLoading(false);
        });
    }
  };

  const onAsyncUserImpersonationChange = (option) => {
    if (IsArrayNotEmpty(option)) {
      setSelectedUser(option[0]);
    } else {
      setSelectedUser({});
    }
  };

  const onAsyncUserImpersonationClear = (onClear) => {
    onClear();
    setSelectedUser({});
  };

  const onImpersonateUser = async () => {
    try {
      const { gbsPersonId } = selected_user;
      if (gbsPersonId) {
        setUserImpersonationLoading(true);
        gbsDataCommonMethod(
          idToken,
          accounts,
          `persons/impersonate-person/${gbsPersonId}`,
          "GET"
        )
          .then((res) => {
            // console.log({ res });
            const data = res?.data;
            // console.log({ data });
            const { localAccountId: ImpersonationId } = data;
            if (ImpersonationId) {
              delete data.localAccountId;
              const imp_name = data?.name,
                imp_username = data?.extranetUsername;
              const stringfied = JSON.stringify({
                rolePBAllowImpersonation,
                UserId: localAccountId,
                OriginalUser: {
                  PersonId,
                  email,
                  name,
                  username,
                  RoleCBPrivileges,
                  RolePBPrivileges,
                },
                ImpersonationId,
                name: imp_name,
                username: imp_username,
                idTokenClaims: data,
              });
              document.cookie = "selected_one=true; path=/";
              window.location.reload();
              localStorage.setItem("selected_one", stringfied);
            } else {
              localStorage.removeItem("selected_one");
            }
          })
          .catch((e) => {
            HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
            localStorage.removeItem("selected_one");
          })
          .finally(() => {
            setUserImpersonationLoading(false);
          });
      }
      setUserImpersonationLoading(false);
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setUserImpersonationLoading(false);
    }
  };

  const handleClose = () => {
    setConfirmPopup(false);
  };

  return (
    <>
      {alertOpen.isActive && (
        <Alert
          variant="success"
          onClose={() => setAlertOpen({ isActive: false, message: "" })}
          dismissible
        >
          <b>{alertOpen.message}</b>
        </Alert>
      )}
      {(user_impersonation_loading || loader) && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/* <!-- Profile Picture tab starts --> */}
      <div
        className={`white_bg border-radius-6 p-4 ${rolePBAllowImpersonation ? "mb-4" : ""
          }`}
      >
        <div className="row mb-3 mb-md-2">
          <div className="col-12 col-lg-3">
            <h5 className="my-0 my-lg-4 text-uppercase">Username</h5>
          </div>
          <div className="col-12 col-lg-4">
            <h4 className="fw-bold my-2 my-lg-4">{email}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-3">
            <h5 className="text-uppercase">Password</h5>
          </div>
          <div className="col-12 col-lg-4">
            <div className="password-container">
              <Button
                type="button"
                onClick={handleProfileEdit}
                className={`profileButton btn btn-primary mb-4`}
              >
                Reset/Change
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-3">
            <h5 className="text-uppercase">MFA Phone</h5>
          </div>
          <div className="col-12 col-lg-5 personal_info mfa-update">
            <div className="position-relative mb-2 mb-lg-0 international-number">
              <span className="text-14">PHONE NUMBER</span>
              <PhoneInput
                ref={internationPhone}
                defaultCountry="us"
                disableDialCodePrefill={true}
                value={forminputs.InternationalPhone}
                name="InternationalPhone"
                onChange={(e) => {
                  // console.log(e);
                  setFormInputs({ InternationalPhone: e });
                }}
              />
            </div>
            <div className="password-container mfa-button-container">
              <Button
                type="button"
                onClick={() => { setConfirmPopup(true) }}
                disabled={!InternationalPhoneValid || currentMFAPhone?.toString().replace(" ","") === forminputs.InternationalPhone}
                className={`profileButton btn btn-primary`}
              >
                Update
              </Button>
            </div>
            {!InternationalPhoneValid &&
              forminputs.InternationalPhone !== "" && (
                <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                  Phone number is not valid
                </span>
              )}
          </div>
        </div>
        {rolePBAllowImpersonation && (
          <Fragment>
            <hr className="user-impersonation-segment" />
            <div className="row mb-2">
              <div className="col">
                <h4
                  className={`fw-bold`}
                >
                  {_.get(
                    moreLangs,
                    `${resolvedLanguage}.profile_page.user_impersonation`,
                    "User Impersonation"
                  )}
                  <span className="user-impersonation-info">
                    (Search by Name/Email and Select an User to Impersonate)
                  </span>
                </h4>
              </div>
            </div>
            
            <div className="row mb-0 mb-lg-4">
              <div className="col-12 col-lg-3">
                <InputGroup.Text className="text-uppercase user-impersonation-format">
                  User Email/Name<span className="text-14 mandatoryField red_dark_color">*</span>
                </InputGroup.Text>
              </div>
              <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                <AsyncTypeahead
                  // clearButton
                  ref={async_typeahead_ref}
                  id="async-user-impersonation"
                  isLoading={user_data_loading}
                  labelKey="user_impersonation"
                  minLength={3}
                  onSearch={onUserDataSearch}
                  options={user_data_options}
                  placeholder="Select an User"
                  // filterBy={["RelationshipNumber", "Name","Name2","Name3"]}
                  className="user-impersonation-dropdown"
                  onChange={onAsyncUserImpersonationChange}
                >
                  {({ onClear, selected }) =>
                    !!selected.length && (
                      <div className="rbt-aux">
                        <ClearButton
                          onClick={() => onAsyncUserImpersonationClear(onClear)}
                        />
                      </div>
                    )
                  }
                </AsyncTypeahead>
              </div>
              <div className="col-12 col-lg-3">
                <Button
                  variant="primary"
                  disabled={!Object.keys(selected_user).length}
                  onClick={onImpersonateUser}
                  style={{
                    boxShadow: `0 0 5px #ccc`,
                  }}
                >
                  Impersonate
                </Button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <Modal
        className="grower-logout-warn-modal"
        show={confirmPopup}
        animation={false}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header className="blue_bg " closeButton>
          <Modal.Title>          
          <h4 className="modal-title white_color">Are you sure</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grower-logout-warn-modalbody">
          <p className="grower-logout-warn-modaltext">Do you want to update your MFA phone number from this {currentMFAPhone} to {forminputs.InternationalPhone}</p>
        </Modal.Body>
        <Modal.Footer className="grower-logout-warn-modalfoot">
          <Button
            className="grower-logout-warn-modalbtn"
            onClick={handlePhoneEdit}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <!-- Profile Picture tab ends -->			 */}
    </>
  );
};
export default ChangePassword;
