import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import Moment from "react-moment";
import "moment/min/locales.js";
import { useTranslation } from "react-i18next";
import { getResolvedLanguage, IsStringChecker, HandleErrorTrackAndToast } from "../../../utils/helper";

const EventTime = ({
  event_date_from = null,
  event_date_to = null,
  locale = true,
  timezone = true,
  date_format = "dddd, MMMM Do YYYY",
  time_format = "h:mm A",
}) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const [fromFormat, setFromFormat] = useState(date_format);
  const [toFormat, setToFormat] = useState(date_format);

  useEffect(() => {
    if (IsStringChecker(event_date_from) && IsStringChecker(event_date_to)) {
      try {
        const a = moment(event_date_to),
          b = moment(event_date_from);
        const diff = a.diff(b, "hours", true);
        if (diff < 24) {
          setFromFormat(`${date_format}, ${time_format}`);
          setToFormat(time_format);
        } else {
          setFromFormat(date_format);
          setToFormat(date_format);
        }
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
      }
    }
  }, [event_date_from, event_date_to, date_format, time_format]);

  if (IsStringChecker(event_date_from) && IsStringChecker(event_date_to)) {
    return (
      <Fragment>
        <Moment
          className="text-capitalize"
          utc={timezone}
          local={timezone}
          locale={locale ? resolvedLanguage : "en"}
          format={fromFormat}
        >
          {event_date_from}
        </Moment>
        {` - `}
        <Moment
          className="text-capitalize"
          utc={timezone}
          local={timezone}
          locale={locale ? resolvedLanguage : "en"}
          format={toFormat}
        >
          {event_date_to}
        </Moment>
      </Fragment>
    );
  } else {
    return <Fragment />;
  }
};

export default EventTime;
