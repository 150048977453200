/**
 * @component_Name : ExhibitMaps
 * @description : Show ExhibitMaps grid list
 * @company : Photon
 * @author : Ashish Ranjan/22-08-2022
 **/
import React, {
  useState,
  useEffect /*, Suspense, lazy*/,
  Fragment,
} from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { ConstantsVar } from "../../../utils/constants";
import {
  IsArrayNotEmpty,
  toDecimal,
  getResolvedLanguage,
} from "../../../utils/helper";
import "../../../css/loading.css";
import _ from "lodash";
import moment from "moment";
import CurrentLocation from "../../global/currentLocation";
import { useTranslation } from "react-i18next";

const ExhibitMapsPagination = ({ itemsPerPage, listingData, ongoingYear }) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const tokenId = useSelector((state) => state.reducerToken);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState(null);
  //const [autoPlay, setAutoPlay] = useState(true);
  /*Store array for sorting column */
  let endOffset = 0;
  const listLength = listingData.length;
  // console.log(listingData);
  useEffect(() => {
    const getPagination = async () => {
      if (listLength > 0) {
        const groupedData = _.groupBy(listingData, "GroupingKey");

        let convertToArray = Object.values(groupedData).map((x) => {
          x[0]["totalExhibitAcres"] = x.reduce(
            (prev, { ExhibitAcres }) => prev + ExhibitAcres,
            0
          );
          return x[0];
        });
        const filteredArray = convertToArray.filter((li) => li.ExhibitMapDate);
        filteredArray.sort(
          (a, b) =>
            b.CropYear.toString().localeCompare(a.CropYear.toString(), "en", {
              numeric: true,
            }) ||
            a.MapId.toString().localeCompare(b.MapId.toString(), "en", {
              numeric: true,
            })
        );
        setItems([...filteredArray]);
        // Fetch items from another resources.
        endOffset = itemOffset + itemsPerPage;
        setCurrentItems(filteredArray.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(filteredArray.length / itemsPerPage));
      } else {
        setCurrentItems([]);
      }
    };
    getPagination();
  }, [tokenId, itemOffset, itemsPerPage, endOffset, listingData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  ongoingYear;
  const no_record_found = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_record_found`,
    "Record not found"
  )}`;
  return IsArrayNotEmpty(currentItems) ? (
    <>
      {/*<!-- Responsive table starts -->*/}
      <div className="exhibit_maps_table mb-4 my_acreage_exhibit  d-none d-lg-block">
        <div className="table mobile-accordion mb-0">
          <div className="tbody">
            {currentItems.map((item, index) => {
              const exhibitMapDate = item.ExhibitMapDate
                ? moment
                    .utc(item.ExhibitMapDate)
                    .format(ConstantsVar.dateFormate)
                : null;
              if (exhibitMapDate) {
                return (
                  <Fragment key={`${index}`}>
                    <div className="rh">
                      <span>
                        <a className="width-85 d-block grey_text_color">
                          Crop Year: {item.CropYear}, Map Id: {item.MapId}
                        </a>
                        <span className="float-end icon_toggle"></span>
                      </span>
                    </div>
                    <div className="tr">
                      <div className="td ps-0 ps-lg-3" data-header="Pool:">
                        <div className="card-body card-odd p-0 p-lg-1 pt-0 pt-lg-3">
                          <h5 className="text-14 overflow-hidden">
                            <span className="float-start pe-1">
                              Crop Year:{" "}
                            </span>
                            <span className="fw-bold">{item.CropYear}</span>
                          </h5>
                          <h5 className="text-14 overflow-hidden">
                            <span className="float-start pe-1">
                              Contract Number:{" "}
                            </span>
                            <span className="fw-bold">
                              {item.RelationshipNumber}
                            </span>
                          </h5>
                          <h5 className="text-14 overflow-hidden d-flex">
                            <span className="float-start pe-1">
                              Contract Name:{" "}
                            </span>
                            <span className="fw-bold float-end">
                              {item.Name != null ? item.Name : ""}{" "}
                              {item.Name2 != null ? " " + item.Name2 : ""}{" "}
                              {item.ContractIdentifier != null
                                ? " " + item.ContractIdentifier
                                : ""}{" "}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="td ps-0 ps-lg-3" data-header="Crop Year:">
                        <div className="card-body card-odd p-0 p-lg-1 pt-0 pt-lg-3">
                          <h5 className="text-14 overflow-hidden">
                            <span className="float-start pe-1">Map: </span>
                            <span className="fw-bold">
                              {item.MapPage ? item.MapPage : "-"}{" "}
                              {item.MapPageOf ? " of " + item.MapPageOf : "-"}
                            </span>
                          </h5>
                          <h5 className="text-14 overflow-hidden">
                            <span className="float-start pe-1">MapID: </span>
                            <span className="fw-bold">{item.MapId}</span>
                          </h5>
                          <h5 className="text-14 overflow-hidden">
                            <span className="float-start pe-1">
                              Map Acres:{" "}
                            </span>
                            <span className="fw-bold">
                              {toDecimal(item.totalExhibitAcres.toFixed(2), 2)}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="td ps-3" data-header="Contract Number:">
                        <div className="card-body card-even p-0 p-lg-1 pt-0 pt-lg-3">
                          <h5 className="text-14 overflow-hidden mb-1">
                            <span className="float-start pe-1">
                              Exhibit Date:{" "}
                            </span>{" "}
                            <span className="fw-bold">
                              {exhibitMapDate || "-"}
                            </span>
                          </h5>
                          {/* <h5 className="text-14 overflow-hidden mb-1"><span className="float-start pe-1">Current: </span> <span className="fw-bold">{(item.CropYear == ongoingYear) ? 'Yes' : ''}</span></h5> */}
                          <h5 className="text-14 overflow-hidden d-flex align-items-center">
                            <span className="float-start pe-1">
                              Click to Download PDF:{" "}
                            </span>{" "}
                            <span className="fw-bold">
                              <a
                                href={item.ExhibitMapPdfUrl}
                                target={"_blank"}
                                className="border-1 border-radius-6 excelIconPadding cursor_pointer position-relative d-inline-block top-0"
                                data-bs-toggle="tooltip"
                                title="PDF"
                                rel="noreferrer"
                              >
                                <i
                                  className="fa fa-file-pdf-o blue_color text-16"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </h5>
                          <h5 className="text-14 overflow-hidden d-flex align-items-center">
                            <span className="float-start pe-1"> </span>{" "}
                            <span className="fw-bold"></span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              } else {
                return <Fragment key={index} />;
              }
            })}
          </div>
        </div>
      </div>
      {/*ReactPaginate*/}
      <div className="row d-none d-lg-block">
        <nav
          aria-label="Page navigation example"
          className="mt-3 overflow-hidden"
        >
          {currentItems.length > 0 && pageCount > 1 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              //{...forcePageObj}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination float-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={null}
            />
          )}
        </nav>
      </div>
      {/*<!-- Responsive table ends -->*/}
    </>
  ) : (
    <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
      <i className="fa fa-info-circle pe-2"></i>
      {no_record_found}
    </div>
  );
};

export default ExhibitMapsPagination;
