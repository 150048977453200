import React, { useState, useEffect /*, Suspense, lazy*/ } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
import {
  IsArrayNotEmpty,
  IsStringChecker,
  IsTokenChecker,
  NumConverter,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import { ConstantsVar } from "../../../utils/constants";
import "../../../css/loading.css";
import ImageView from "../../global/imageView";

const RecentDeliveryPagination = (props) => {
  const {
    itemsPerPage,
    listingData,
    harvestArray,
    RoleCBPrivileges = {},
    imgData = [],
  } = props;
  const { roleCBPayment = false } = RoleCBPrivileges;
  const {
    defaultEventImageURL,
    defaultLoadingImageURL,
    dateFormate,
    timeFormate,
  } = ConstantsVar;

  const tokenId = useSelector((state) => state.reducerToken);
  const [currentItems, setCurrentItems] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState(null);
  //const [autoPlay, setAutoPlay] = useState(true);
  /*Store array for sorting column */
  let endOffset = 0;
  const pagingArray = Object.keys(listingData).map((x) => x);
  const listLength = pagingArray.length;

  useEffect(() => {
    const getPagination = async () => {
      if (listLength > 0) {
        const listingArray = [...pagingArray];
        setItems([...listingArray]);
        // Fetch items from another resources.
        endOffset = itemOffset + itemsPerPage;
        setCurrentItems(listingArray.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(listingArray.length / itemsPerPage));
      } else {
        setCurrentItems([]);
      }
    };
    IsTokenChecker(tokenId) && getPagination();
  }, [tokenId, itemOffset, itemsPerPage, endOffset, listingData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const imgOptimizer = (harvestImage = null) => {
    try {
      if (harvestImage) {
        return harvestImage.size <= 215 ? defaultEventImageURL : harvestImage;
      }
      return defaultEventImageURL;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultEventImageURL;
    }
  };

  return (
    <>
      {/*<!-- Responsive table starts -->*/}
      <div className="exhibit_maps_table exhibit_maps_table_my_harvest mb-2 d-none d-lg-block">
        <div className="table mobile-accordion mb-0">
          <div className="tbody">
            {
              listLength > 0
                ? Object.values(currentItems).map((data, index) => {
                    const LDTicketNumber =
                      listingData[data].LoadDeliveryTicketNumber;
                    const harvestImageData = IsArrayNotEmpty(imgData)
                      ? imgData.find((li) => li.id === listingData[data].Id)
                      : {};
                    const getHarvestImage = _.get(
                      harvestImageData,
                      "img",
                      null
                    );
                    const harvestImage = imgOptimizer(getHarvestImage);
                    const deliveryStatus =
                      listingData[data].ReceiptStatus == "Paid" ||
                      listingData[data].ReceiptStatus == "Released" ||
                      listingData[data].ReceiptStatus == "Inventoried"
                        ? "Completed"
                        : "Pending";
                    const deliveryClass =
                      listingData[data].ReceiptStatus == "Paid" ||
                      listingData[data].ReceiptStatus == "Released" ||
                      listingData[data].ReceiptStatus == "Inventoried"
                        ? "light_green_bg "
                        : "orange_bg";
                    return (
                      <div key={`${index}`}>
                        <div className="rh">
                          <span>
                            <a className="width-85 d-block grey_text_color">
                              Delivery #:{" "}
                              {listingData[data].LoadDeliveryTicketNumber},
                              Contract: {listingData[data].RelationshipNumber}
                            </a>
                            <span className="float-end icon_toggle"></span>
                          </span>
                        </div>
                        <div className="tr p-0">
                          <div
                            className="td overflow-hidden width-35"
                            data-header="Pool:"
                          >
                            {getHarvestImage === null ? (
                              <img
                                src={defaultLoadingImageURL}
                                className="card-img-top cranberry_thumb cursor_pointer"
                                name="element_name_attribute"
                                alt="Recent_Delivery_Image_Loading"
                              />
                            ) : (
                              <>
                                <span
                                  className={`${deliveryClass} text-center ver-space-10 d-inline-block width-100 white_color left-border-radius status_style text-uppercase`}
                                >
                                  {deliveryStatus}
                                </span>
                                {IsStringChecker(harvestImage) ? (
                                  <img
                                    src={harvestImage}
                                    className="card-img-top cranberry_thumb cursor_pointer no_image_pe_1"
                                    alt="Recent_Delivery_Image_No"
                                  />
                                ) : (
                                  <ImageView
                                    url={harvestImage}
                                    className="img-fluid mx-auto d-block width-99_5"
                                  />
                                )}
                              </>
                            )}
                            {/*<!-- Modal starts-->*/} {/*<!-- Modal ends -->*/}
                          </div>
                          <div
                            className="td ps-0 ps-lg-1 width-30"
                            data-header="Crop Year:"
                          >
                            <div className="card-body card-odd p-0 p-lg-2 pt-0 pt-lg-3">
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Delivery #:
                                </span>{" "}
                                <span className="fw-bold">
                                  {" "}
                                  {listingData[data].LoadDeliveryTicketNumber}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Facility:
                                </span>{" "}
                                <span className="fw-bold">
                                  {listingData[data].FacilityName}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Date &amp; Time In:
                                </span>{" "}
                                <span className="fw-bold">
                                  <Moment utc format={dateFormate}>
                                    {listingData[data].IncomingTime}
                                  </Moment>{" "}
                                  <Moment utc format={timeFormate}>
                                    {listingData[data].IncomingTime}
                                  </Moment>
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Contract #:
                                </span>{" "}
                                <span className="fw-bold">
                                  {listingData[data].RelationshipNumber}{" "}
                                  {listingData[data].Name}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  BedID(s):
                                </span>{" "}
                                <span className="fw-bold">
                                  {harvestArray[LDTicketNumber].bed}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Variety:
                                </span>{" "}
                                <span className="fw-bold">
                                  {(deliveryStatus == "Completed" ||
                                    deliveryStatus == "Pending") &&
                                    listingData[data].LegacyVarietyCode}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  EU/NonEU:
                                </span>{" "}
                                <span className="fw-bold">
                                  {(deliveryStatus == "Completed" ||
                                    deliveryStatus == "Pending") &&
                                    listingData[data].DomesticExportType}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Fresh/Process:
                                </span>{" "}
                                <span className="fw-bold">
                                  {(deliveryStatus == "Completed" ||
                                    deliveryStatus == "Pending") &&
                                    listingData[data].ProcessedFreshType}
                                </span>
                              </h5>
                            </div>
                          </div>
                          <div
                            className="td ps-1"
                            data-header="Contract Number:"
                          >
                            <div className="card-body card-even p-0 p-lg-1 pt-0 pt-lg-3">
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Moisture:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].PercentMoist}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Dry Trash:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].PercentDryTrash}{" "}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Trash Total:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].PercentTotalTrash}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Clean Dry Delivery Weight:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    Math.round(
                                      listingData[data].CleanDryWeight
                                    )}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Poor:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].PercentPoor}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Class 1:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].ClassOne}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Class 1 Less Allowance:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data]
                                      .PercentClassOneLessAllowance}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Defect Total:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].PercentDefectTotal}
                                </span>
                              </h5>
                            </div>
                          </div>
                          <div
                            className="td ps-1"
                            data-header="Contract Number:"
                          >
                            <div className="card-body card-even p-0 p-lg-1 pt-0 pt-lg-3">
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  % Usable:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].PercentUsable}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Delivery Weight (lbs):
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    Math.round(NumConverter(harvestArray[LDTicketNumber]?.deliveryWeight))}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Total Barrels:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    harvestArray[LDTicketNumber].totalBarrels}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  Firmness:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].FirmnessAverage}{" "}
                                </span>
                              </h5>
                              <h5 className="overflow-hidden">
                                <span className="float-start pe-1">
                                  &quot;% &gt; 1/2&quot;:
                                </span>{" "}
                                <span className="fw-bold">
                                  {deliveryStatus == "Completed" &&
                                    listingData[data].PercentGreaterThanHalf}
                                </span>
                              </h5>
                              {roleCBPayment && (
                                <h5 className="overflow-hidden  pt-0 pt-lg-4">
                                  <span className="float-start pe-1">
                                    Preliminary Assessment $s:
                                  </span>{" "}
                                  <span className="fw-bold">
                                    {deliveryStatus == "Completed" &&
                                      listingData[data]
                                        .PreliminaryAssessment}{" "}
                                  </span>
                                </h5>
                              )}
                              {roleCBPayment && (
                                <h5 className="overflow-hidden">
                                  <span className="float-start pe-1">
                                    Preliminary Incentive $s:{" "}
                                  </span>{" "}
                                  <span className="fw-bold">
                                    {deliveryStatus == "Completed" &&
                                      listingData[data].PreliminaryIncentive}
                                  </span>
                                </h5>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : "" /*<Loading />*/
            }
            {/*<!-- end Card 1 block starts -->*/}
          </div>
        </div>
      </div>
      {/*ReactPaginate*/}
      <div className="row d-none d-lg-block">
        <nav
          aria-label="Page navigation example"
          className="mt-3 overflow-hidden"
        >
          {currentItems.length > 0 && pageCount > 1 && (
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              //{...forcePageObj}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination float-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={null}
            />
          )}
        </nav>
      </div>
      {/*<!-- Responsive table ends -->*/}
    </>
  );
};

export default RecentDeliveryPagination;
