import React, { useEffect, useState } from "react";
import BreadCrumb from "../global/breadCrumb";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { gbsDataCommonMethod } from "../../services/gbsData";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import Loading from "../global/loading";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";

const FeedbackLanding = (props) => {
  const { accounts } = useMsal();
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getPersonDetails();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const hubspotFormBind = (personData) => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "6335042",
          formId: "bafed733-edac-41e3-9add-023422db5700",
          target: ".hubspotForm",
          onFormReady: () => {
            let Dom = document.querySelector('iframe').contentWindow.document;
            Dom.querySelector("[name='grower_contact_us_first_name']").value = personData[0].FirstName || '';
            Dom.querySelector("[name='grower_contact_us_last_name']").value = personData[0].LastName || '';
            Dom.querySelector("[name='grower_contact_us_email']").value = personData[0].Email || '';
            setTimeout(() => {
              Dom.querySelector("[name='grower_contact_us_first_name']").focus();
              Dom.querySelector("[name='grower_contact_us_last_name']").focus();
              Dom.querySelector("[name='grower_contact_us_email']").focus();
              Dom.querySelector(".actions .hs-button").focus();
            }, 500);
            window.setInterval(function () {
              Dom.querySelector('.actions .hs-button').style.removeProperty('pointer-events');
              Dom.querySelector('.actions .hs-button').style.removeProperty('opacity');
              if(Dom.querySelector("[name='grower_contact_us_email']").value !== ''){
                Dom.querySelectorAll(".hs_grower_contact_us_email .input .hs-error-msg").forEach(li=>li.remove());
                if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(Dom.querySelector("[name='grower_contact_us_email']").value)){
                  let ul = document.createElement("ul");
                  ul.className = ['no-list hs-error-msgs inputs-list'];
                  let li = document.createElement("li");
                  let lab = document.createElement('label')
                  lab.className=['hs-error-msg'];
                  lab.append('The Email field contains an invalid email address.')
                  li.append(lab)
                  ul.append(li)
                  Dom.querySelector("[name='grower_contact_us_email']").parentElement.appendChild(ul);
                  Dom.querySelector('.actions .hs-button').style.pointerEvents='none';
                  Dom.querySelector('.actions .hs-button').style.opacity='.5';
                }
                else{
                  Dom.querySelectorAll(".hs_grower_contact_us_email .input .hs-error-msg").forEach(li=>li.remove());
                }
              }
              else{
                Dom.querySelectorAll(".hs_grower_contact_us_email .input .hs-error-msg").forEach(li=>li.remove());
              }      
            }, 300)
          },
          onFormSubmitted:()=>{
            setTimeout(()=>{
              document.querySelector('iframe').classList.add('height100');
            },100)
          }
        });
      }
    });
  };
  const getPersonDetails = async () => {
    setLoading(true);
    await gbsDataCommonMethod(tokenId, accounts, `persons/${personId}`)
      .then((resp) => {
        hubspotFormBind(resp.data);
        setLoading(false);
      })
      .catch((e) => {
        hubspotFormBind([]);
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  return (
    <>
      <BreadCrumb {...props} />
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="feedback-form">
        <h3 className="title">
          {moreLangs[i18n.resolvedLanguage].feedback.feedback_form}
        </h3>
        <div className="hubspotForm"></div>
      </div>
    </>
  );
};

export default FeedbackLanding;
