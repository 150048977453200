import React, { useEffect, useState, Fragment } from "react";
import BreadCrumb from "../../global/breadCrumb";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import DataGridPagination from "../../global/dataGridPagination";
import moment from "moment";
import Moment from "react-moment";
import { Accordion } from "react-bootstrap";
import CurrentLocation from "../../global/currentLocation";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsTokenChecker,
  IsStringChecker,
  IsNumberChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../../utils/helper";
import { ImpersonationAccountsSwap } from "../../../permissionWrapper";

const PickupPageDetail = (props) => {
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);

  const DateFormat = "MM/DD/YYYY";
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", 366);

  const getEntries = async () => {
    if (IsNumberChecker(personId)) {
      const url = `persons/${personId}/pickup-page-entries`;
      await gbsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          let tempForms = safeArrayCheck(_.get(resp, "data", []));
          let sortedForms = tempForms.sort(
            (a, b) => moment(b.PickupDate) - moment(a.PickupDate)
          );
          let tempEntries = IsArrayNotEmpty(sortedForms)
            ? sortedForms.map((li, i) => {
                return { ...li, id: i };
              })
            : [];
          setEntries(tempEntries);
        })
        .catch((error) => {
          setEntries([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setEntries([]);
    }
  };
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getEntries();
      setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const columns = [
    {
      key: "AddedBy",
      name: "Added By",
      cellClass: "enablesTextWrapping",
      width: "15%",
      formatter(props) {
        return (
          <>{IsStringChecker(props.row.AddedBy) ? props.row.AddedBy : "-"}</>
        );
      },
    },
    {
      key: "PickupDate",
      name: "Date",
      width: "20%",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        return (
          <>
            {IsStringChecker(props.row.PickupDate) ? (
              <Moment utc format={DateFormat}>{props.row.PickupDate}</Moment>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      key: "Description",
      name: "Description",
      cellClass: "enablesTextWrapping",
      width: "40%",
      formatter(props) {
        return (
          <>
            {IsStringChecker(props.row.Description)
              ? props.row.Description
              : "-"}
          </>
        );
      },
    },
    {
      key: "FileName",
      name: "Attachment",
      cellClass: "enablesTextWrapping",
      width: "25%",
      formatter(props) {
        return (
          <>
            {IsTokenChecker(
              props.row.FileUrl,
              props.row.AttachmentFriendlyName
            ) ? (
              <span className="blue_color">
                <a href={props.row.FileUrl} target={"_blank"} rel="noreferrer">
                  <i className="fa fa-file-o"></i>
                  {` ${props.row.AttachmentFriendlyName}`}
                </a>
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="col-12">
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-4">
              <h3 className="mob-fw-bold py-3">
                {_.get(
                  moreLangs,
                  `${resolvedLanguage}.menu_pickup_page.title`,
                  "Pick Up Page"
                )}
              </h3>
              <div className="d-none d-lg-block">
                {!displayAccordian && (IsArrayNotEmpty(entries) ? (
                   
                  <div className="visible_print_mob_desktop hor-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block">
                    <DataGridPagination
                      uniqueIdentifier="id"
                      gridData={entries}
                      columns={columns}
                      isPagination={true}
                      itemsPerPage={10}
                      columnResize={false}
                      classNames="contain-block-size-reset top-mspace-20 bot-mspace-20 border-radius-6"
                      dynamicHeight={40}
                    />
                  </div>
                ) : (
                  <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                    <i className="fa fa-info-circle pe-2"></i>
                    {`${_.get(
                      moreLangs,
                      `${resolvedLanguage}.noRecordErrorMsg`,
                      "Record not found"
                    )}`}
                  </div>
                ))}
              </div>
              <div
                className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
              >
                <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                  <Accordion defaultActiveKey="0">
                    {IsArrayNotEmpty(entries) ? (
                      entries.map((data, index) => {
                        const {
                          id = null,
                          AttachmentFriendlyName = null,
                          Description = null,
                          AddedBy = null,
                          PickupDate = null,
                          FileUrl = null,
                        } = data;
                        return (
                          <Fragment key={id}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                <a className="width-85 d-block grey_text_color">
                                  {`Attachment: `}
                                  <i className="fa fa-file-o"></i>
                                  {` ${AttachmentFriendlyName}, Date: `}
                                  <Moment utc format={DateFormat}>
                                    {PickupDate}
                                  </Moment>
                                </a>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="tbody">
                                  <div className="tr">
                                    <div className="td"></div>
                                    {IsStringChecker(AddedBy) && (
                                      <div
                                        className="td"
                                        data-header="Added By:"
                                      >
                                        <span>{AddedBy}</span>
                                      </div>
                                    )}
                                    {IsStringChecker(PickupDate) && (
                                      <div className="td" data-header="Date:">
                                        <span>
                                          <Moment utc format={DateFormat}>
                                            {PickupDate}
                                          </Moment>
                                        </span>
                                      </div>
                                    )}
                                    {IsTokenChecker(Description) && (
                                      <div
                                        className="td"
                                        data-header="Description:"
                                      >
                                        <span>{Description}</span>
                                      </div>
                                    )}
                                    {IsTokenChecker(
                                      FileUrl,
                                      AttachmentFriendlyName
                                    ) && (
                                      <div
                                        className="td"
                                        data-header="Attachment:"
                                      >
                                        <span className="blue_color">
                                          <a
                                            href={FileUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <i className="fa fa-file-o"></i>
                                            {` ${AttachmentFriendlyName}`}
                                          </a>
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                        <i className="fa fa-info-circle pe-2"></i>
                        {`${_.get(
                          moreLangs,
                          `${resolvedLanguage}.noRecordErrorMsg`,
                          "Record not found"
                        )}`}
                      </div>
                    )}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickupPageDetail;
