import { useEffect, useRef, useReducer } from 'react';
import axios from "axios";
import _ from "lodash";
import {  GbsBaseUrl } from "../utils/constants";
import { HandleErrorTrackAndToast } from '../utils/helper';

export const useGbsDataCache = (idToken = null,
    accounts = [],
    url = "",
    method = "GET",
    requestData = {},    
    config = { "Content-Type": "application/json" }) => {
    const cacheData = useRef({})
    const initialState = {
      status: 'idle',
      error: null,
      data: [],
    }
    const [state, dispatch] = useReducer((state, action) => {
      switch (action.type) {
        case 'FETCHING':
          return { ...initialState, status: 'fetching' }
        case 'FETCHED':
          return { ...initialState, status: 'fetched', data: action.payload }
        case 'FETCH_ERROR':
          return { ...initialState, status: 'error', error: action.payload }
        default:
          return state
      }
    }, initialState)
    useEffect(() => {
      let revokeRequest = false
      if (!url || !url.trim()) return;
      const renderData = async () => {
        dispatch({ type: 'FETCHING' });
        if (cacheData.current[url]) {
          const data = cacheData.current[url]
          dispatch({ type: 'FETCHED', payload: data })
        } else {
          try {
            const localAccountId = _.get(accounts, `0.localAccountId`, "");
            const data = await axios({
              url: `${GbsBaseUrl}${url}`,
              data: requestData,
              method: method,
              headers: {
                Authorization: `Bearer ${idToken}`,
                UserId: localAccountId,
              },
              ...config,
            }); 
            cacheData.current[url] = data;
            if (revokeRequest) return
            dispatch({ type: 'FETCHED', payload: data })
          } catch (error) {
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);  
            const regex = new RegExp(GbsBaseUrl,"g");      
            let url = error?.config?.url?.replace(regex,'');
            delete cacheData.current[url];
            if (revokeRequest) return
            dispatch({ type: 'FETCH_ERROR', payload: error.message })
          }
        }
      }
      renderData()
      return function cleanup() {
        revokeRequest = true
      }
    }, [url])
    return state
  }