import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import SubSubMenu from "./subSubMenu";

const SubMenu = ({ data, item, index, i18n }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  return (
    <>
      <i
        className={
          showSubmenu
            ? "fa fa-plus dropdown-toggle nav-icon-dm show"
            : "fa fa-plus dropdown-toggle nav-icon-dm "
        }
        data-bs-toggle="dropdown"
        aria-expanded={showSubmenu ? "true" : "false"}
        onClick={() => setShowSubmenu(!showSubmenu)}
      ></i>
      <ul
        className={
          showSubmenu
            ? "dropdown-menu oceanspray_submenu show"
            : "dropdown-menu oceanspray_submenu"
        }
        aria-labelledby={`navbarDropdown-${index}`}
      >
        {data[item].map((sublink, subindex) =>
          subindex > 0 ? (
            sublink.side_nav_items.length > 0 ? (
              <Fragment key={subindex}>
                <li
                  className="nav-item dropdown drop-down02 multi_level_menu d-lg-none d-sm-block"
                >
                  <Link
                    className={`nav-link dropdown-toggle ${sublink.new_content && 'new-content-flag'}`}
                    to={sublink["link"]}
                    id={`navbarDropdown-${subindex}`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {sublink["title_" + i18n.language]}
                    {sublink.new_content && <img className="new-content-flag-img" src={`${window.location.origin}/assets/images/new_burst_gradient.png`}/>}
                  </Link>
                  <SubSubMenu data={_.orderBy(sublink.side_nav_items,['order_by','asc'])} i18n={i18n} item={sublink} index={subindex}/>
                </li>
                <li className="d-none d-lg-block">
                  <Link to={sublink.link} className={`dropdown-item ${sublink.new_content && 'new-content-flag'}`}>
                    {sublink["title_" + i18n.resolvedLanguage]}
                    {sublink.new_content && <img className="new-content-flag-img" src={`${window.location.origin}/assets/images/new_burst_gradient.png`}/>}
                  </Link>
                </li>
              </Fragment>
            ) : (
              <li key={subindex}>
                <Link to={sublink.link} className={`dropdown-item ${sublink.new_content && 'new-content-flag'}`}>
                  {sublink["title_" + i18n.resolvedLanguage]}
                  {sublink.new_content && <img className="new-content-flag-img" src={`${window.location.origin}/assets/images/new_burst_gradient.png`}/>}
                </Link>
              </li>
            )
          ) : <Fragment key={subindex} />
        )}
      </ul>
    </>
  );
};

export default SubMenu;
