import React from "react";
import BreadCrumb from "../../global/breadCrumb";
import LandingPageCtaContentBlock from "../../global/landingPageCtaContentBlock";

const FormsLanding = (props) => {
  return (
    <>
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        <LandingPageCtaContentBlock slug={"forms"} {...props} />
      </div>
    </>
  );
};

export default FormsLanding;
