/**
 * @component_Name : MyAcreage
 * @description : My acreage ->  MyAcreage
 * @company : Photon
 * @author : Ashish Ranjan/18-08-2022
 **/
import React from "react";
import PageLayout from "../../pageLayout";
import Body from "./body";
import MyAcreageDetails from "./myAcreageDetails";

const MyAcreage = (props) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <PageLayout {...props} body={<Body isLeftMenu ="true"  quickLinksSlug="my-acreage" {...props}  contentbody={<MyAcreageDetails {...props} />} />} />
  );
};

export default MyAcreage;
