import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";
import { cmsDataCommonMethod } from "../../services/gbsData";
import {
  IsTokenChecker,
  IsStringChecker,
  IsArrayNotEmpty,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../utils/helper";
import {
  ContractsFromJSONParser,
  RoleCheckerForArrayData,
  RoleCheckerForDocumentLinkArr,
  ImpersonationAccountsSwap
} from "../../permissionWrapper";
import CurrentLocation from "../global/currentLocation";
import { Accordion } from "react-bootstrap";

const QuickLinks = (props) => {
  const {
    slug,
    className = null,
    designType = "",
    RoleAccessDefinitions = null,
    SpecialRoleExist,
  } = props;
  const idToken = useSelector((state) => state.reducerToken);

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const [quickLinks, setQuickLinks] = useState([]);

  function IsLinkADocument(url = "") {
    try {
      if (IsStringChecker(url)) {
        const regExps = [];
        regExps.push(new RegExp(`https://`, "g"));
        regExps.push(new RegExp(`http://`, "g"));
        return regExps.some((regExp) => {
          return url.match(regExp);
        });
      }
      return false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "home_page"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return defaultValue;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultValue;
    }
  };

  const GetQuicKLinks = async () => {
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `quick_links?select=*,page_listing_id,page_listings!inner(slug)&page_listings.slug=eq.${slug}&order=order_by.asc`
    )
      .then((resp) => {
        const tempData = _.get(resp, "data.data", []);
        const pages = tempData.filter((li) => !IsLinkADocument(li?.url));
        const docs = tempData.filter((li) => IsLinkADocument(li?.url));
        // console.log({ ql: tempData, pages, docs });

        // const result = tempData;
        const resulta = RoleCheckerForArrayData(
          pages,
          "url",
          gbsRolesByPerson,
          gbsRolesByContract,
          SpecialRoleExist,
          RoleAccessDefinitions
        );
        const resultb = RoleCheckerForDocumentLinkArr(docs, "url", SpecialRoleExist, RoleAccessDefinitions);
        const result = _.orderBy([...resultb, ...resulta], "order_by", "asc");
        // console.log({ QuickLinks: result });
        setQuickLinks(safeArrayCheck(result));
      })
      .catch((error) => {
        setQuickLinks([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (IsTokenChecker(idToken, slug)) {
      GetQuicKLinks();
    }
  }, [idToken, slug]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);
  const heading = LocalTranslator("quick_links", "Quick Links");
  return (
    <>
      {IsArrayNotEmpty(quickLinks) && designType !== "accordion" ? (
        <div
          className={`card bot-mspace-20 Quick_link card-shadow ${
            IsStringChecker(className) ? className : ``
          }`}
        >
          <h4 className="card-header white_color ver-space-15">
            <i className="fa fa-link fa-rotate-90 text-18"></i>
            <span className="left-space-5">{heading}</span>
          </h4>
          <div className="card-body">
            <ul className="list-group">
              {quickLinks.map((link, index) => {
                const { url = null, open_new_window = false } = link;
                const title = _.get(link, `title_${resolvedLanguage}`, "");
                const subtitle = _.get(
                  link,
                  `subtitle_${resolvedLanguage}`,
                  ""
                );
                return (
                  <li className="list-group-item" key={index}>
                    {IsStringChecker(url) ? (
                      <>
                        <Link
                          to={open_new_window ? { pathname: url } : url}
                          target={open_new_window ? "_blank" : "_self"}
                          className="text-18"
                          rel="noreferrer"
                        >
                          {title}
                        </Link>
                        <p>{subtitle}</p>
                      </>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        IsArrayNotEmpty(quickLinks) && (
          <>
            <Accordion className="quickLinkAccorion" defaultActiveKey="0">
              <Accordion.Item>
                <Accordion.Header>
                  <i className="fa fa-link fa-rotate-90 text-18"></i> {heading}
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="list-group">
                    {quickLinks.map((link, index) => {
                      const { url = null, open_new_window = false } = link;
                      const title = _.get(
                        link,
                        `title_${resolvedLanguage}`,
                        ""
                      );
                      const subtitle = _.get(
                        link,
                        `subtitle_${resolvedLanguage}`,
                        ""
                      );
                      return (
                        <li className="list-group-item" key={index}>
                          {IsStringChecker(url) ? (
                            <>
                              <Link
                                to={open_new_window ? { pathname: url } : url}
                                target={open_new_window ? "_blank" : "_self"}
                                className="text-18"
                                rel="noreferrer"
                              >
                                {title}
                              </Link>
                              <p>{subtitle}</p>
                            </>
                          ) : (
                            ""
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )
      )}
    </>
  );
};

export default QuickLinks;
