/**
 * @component_Name : DataGridLoadMore
 * @description : library for grid with LoadMore
 * @author : Ashish Ranjan/11-08-2022
 *
 */
import React, { useState, useEffect, useRef } from "react";
import DataGrid from "react-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { IsTokenChecker } from "../../utils/helper";
import { cmsDataCommonMethod } from "../../services/gbsData";
import {
  IsNumberChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import { groupBy as rowGrouper } from "lodash";

const DataGridLoadMore = ({
  uniqueIdentifier,
  gridData,
  columns,
  isPagination,
  itemsPerPage,
  columnResize,
  classNames,
  summaryRows,
  defaultSort = "",
  groupByKey = "",
  getExpandedGroupIds = "",
  setExpandedGroupIds = "",
  dynamicHeaderHeight = 50,
  autoRowHeight = false,
  paddingRowHeight = 0,
  dynamicHeight = 0,
  urlMoreData = "",
  accounts = "",
  currentResultCount = "",
  setCurrentResultCount = "",
  conponentName = ""
}) => {
  const [sortColumns, setSortColumns] = useState([]);
  const [ColumnsArray, setColumnsArray] = useState([...columns]);
  const tokenId = useSelector((state) => state.reducerToken);
  const [currentItems, setCurrentItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [rows, setRows] = useState(0);
  const data_grid = useRef();
  let endOffset = 0;
  const dataPerScroll =
    gridData.length < itemsPerPage ? gridData.length : itemsPerPage;
  //row key generator
  const rowKeyGetter = (row) => {
    if (row != undefined && row[uniqueIdentifier] != undefined) {
      return row?.[uniqueIdentifier];
    }
  };

  useEffect(() => {
    if (gridData.length > 0 && dataPerScroll > 0) {
      setRows(createRows(dataPerScroll));
    }
  }, [gridData]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  function createRows(numberOfRows) {
    const rows = [];
    for (let i = 0; i < numberOfRows; i++) {
      if (gridData[i] != undefined) {
        rows[i] = gridData?.[i];
      }
    }
    return rows;
  }

  /*bydefault sort on first load*/
  useEffect(() => {
    if (defaultSort != "") {
      const y = [
        { ["columnKey"]: defaultSort[0], ["direction"]: defaultSort[1] },
      ];
      setSortColumns(...sortColumns, y);
    }
    setColumnsArray([...columns]);
  }, []);
  /*End sort on first load*/

  useEffect(() => {
    if (gridData.length > 0) {
      if (isPagination === false) {
        const newgridData1 = sorting(gridData, sortColumns);
        setCurrentItems([...newgridData1]);
        gridColumnsData([...newgridData1]);
      }
    } else {
      setCurrentItems([]);
    }
  }, [tokenId, itemsPerPage, gridData, sortColumns, endOffset]);

  const gridColumnsData = (data) => {
    let datas = [...ColumnsArray];
    ColumnsArray.map((col) => {
      const longestGenre = Math.max(
        ...data.map((item) => item[col.key]?.toString()?.length)
      );
      if (col.minWidth < longestGenre * 10) {
        datas = datas.map((obj) =>
          obj.key !== col.key
            ? obj
            : {
                ...obj,
                width: longestGenre * 10,
              }
        );
      } else if (col.minWidth) {
        datas = datas.map((obj) =>
          obj.key !== col.key
            ? obj
            : {
                ...obj,
                width: col.minWidth,
              }
        );
      }
    });
    setColumnsArray(datas);
  };

  // Sort by column field
  const sortByField = (field, reverse) => {
    const key = (x) => {
      return x[field];
    };
    reverse = !reverse ? 1 : -1;
    return (first, second) => {
      return (
        (first = key(first)),
        (second = key(second)),
        reverse * ((first > second) - (second > first))
      );
    };
  };
  // Sorting values
  const sorting = (arrayData, sortParam = []) => {
    if (sortParam.length === 0) {
      return arrayData;
    }
    const { columnKey, direction } = sortParam[0];
    const newOrder = direction === "ASC" ? "ASC" : "DESC";
    if (newOrder === "ASC") {
      arrayData = arrayData.sort(sortByField(columnKey, false));
    } else {
      arrayData = arrayData.sort(sortByField(columnKey, true));
    }
    return arrayData;
  };

  function isAtBottom({ currentTarget }) {
    return (
      currentTarget.scrollTop + 10 >=
      currentTarget.scrollHeight - currentTarget.clientHeight
    );
  }

  function loadMoreRows(newRowsCount, length) {
    /*  newRowsCount 10
        length 30 */
    if (urlMoreData == "") {
      return new Promise((resolve) => {
        const newRows = [];
        for (let i = 0; i < newRowsCount && i + length < gridData.length; i++) {
          newRows[i] = gridData[i + length];
        }
        setTimeout(() => resolve(newRows), 1000);
      });
    } else {
      const offset = `&offset=${length}`;
      urlMoreData = urlMoreData + offset;
      return new Promise(async (resolve) => {
        if (IsTokenChecker(tokenId) && urlMoreData != "") {
          await cmsDataCommonMethod(tokenId, accounts, urlMoreData)
            .then((resp) => {
              const result = resp.data.data;
              setCurrentResultCount(result.length);
              const newRows = [...result];
              resolve(newRows);
            })
            // eslint-disable-next-line no-console
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            });
        }
      });
    }
  }

  const handleScroll = async (event) => {
    if (currentResultCount >= itemsPerPage && dataPerScroll > 0) {
      if (isLoading || !isAtBottom(event)) return;
      setIsLoading(true);
      const newRows = await loadMoreRows(dataPerScroll, rows.length);
      setRows([...rows, ...newRows]);
      setIsLoading(false);
    }
  };

  const minimumHeight = 35;
  const MinimumHeightProvider = (height = 0) => {
    return IsNumberChecker(height) && height > minimumHeight
      ? height
      : minimumHeight;
  };
  const rowHeaderHeight = MinimumHeightProvider(dynamicHeaderHeight);
  const rowHeight = MinimumHeightProvider(dynamicHeight);

  const defaultRowHeightSetter = (
    autoRowHeight = false,
    staticHeight = 0,
    paddingRowHeight = 0,
    newHeight = 0
  ) => {
    try {
      const height = MinimumHeightProvider(newHeight);
      if (autoRowHeight) {
        return IsNumberChecker(paddingRowHeight)
          ? height + paddingRowHeight
          : height;
      } else {
        return MinimumHeightProvider(staticHeight);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return minimumHeight;
    }
  };
  const rowHeightSetter = (row) => {
    if (autoRowHeight) {
      try {
        var heightArr = Object.keys(row.row).map((columnName) => {
          let columnProperty = ColumnsArray.filter((obj) => {
            if (obj.key === columnName) return obj;
          });
          let columnWidth = columnProperty[0]?.width || "";
          let columnValue = row.row[columnName] || "";
          const regex = /(<([^>]+)>)/gi;
          columnValue = columnValue.toString().replace(regex, "");
          if (
            columnValue != "" &&
            columnProperty[0]?.hasOwnProperty("dateFormate")
          ) {
            columnValue = columnValue.substr(
              0,
              columnProperty[0].dateFormate.length
            );
          }
          if (
            columnWidth.toString().search("%") === -1 &&
            columnValue !== "" &&
            columnWidth !== "" &&
            columnWidth < columnValue.toString().length * 10
          ) {
            let value = (columnValue.toString().length * 10) / columnWidth;
            return Math.ceil(value) * 30 > minimumHeight
              ? Math.ceil(value) * 30
              : minimumHeight;
          } else {            
            if(data_grid?.current?.element?.offsetWidth !== null && data_grid?.current?.element?.offsetWidth !== undefined 
              && parseFloat("."+columnWidth.toString().replace("%","")) !== NaN){
              let width = data_grid?.current?.element?.offsetWidth * parseFloat("."+columnWidth.toString().replace("%",""));
              if(width !== NaN){
                let value = (columnValue.toString().length * 10) / width;
                return Math.ceil(value) * 30 > minimumHeight
                  ? Math.ceil(value) * 30
                  : minimumHeight;
              }
              console.log(conponentName);
              if(conponentName === "archive"){
                setTimeout(() => {
                 
                  document.querySelector('[role="grid"]').style.overflow = "hidden !important"
                }, 100);                
              }
            }
            else return minimumHeight;
            // console.log("minimumHeight",minimumHeight, minHeight)
            // return minHeight === '' ? minimumHeight : minHeight;
          }
        });
        const height = heightArr.sort(function (a, b) {
          return b - a;
        })[0];
        return defaultRowHeightSetter(true, 0, paddingRowHeight, height);
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        return defaultRowHeightSetter(true, 0, paddingRowHeight);
      }
    } else {
      return defaultRowHeightSetter(false, rowHeight);
    }
  };
  return (
    <>
      {/* Data grid section start */}
      {currentItems.length > 0 && (
        <DataGrid
          ref={data_grid}
          rowKeyGetter={rowKeyGetter}
          columns={ColumnsArray}
          rows={dataPerScroll > 0 ? rows : gridData}
          defaultColumnOptions={{
            sortable: true,
            resizable: columnResize,
          }}
          sortColumns={dataPerScroll == 0 ? sortColumns : []}
          onSortColumnsChange={dataPerScroll == 0 ? setSortColumns : []}
          summaryRows={summaryRows}
          className={`${classNames} test`}
          direction="ltr"
          /*group by functionality*/
          groupBy={groupByKey != "" ? groupByKey : ""}
          rowGrouper={groupByKey != "" ? rowGrouper : ""}
          expandedGroupIds={
            getExpandedGroupIds != "" ? getExpandedGroupIds : ""
          }
          onExpandedGroupIdsChange={
            setExpandedGroupIds != "" ? setExpandedGroupIds : ""
          }
          headerRowHeight={rowHeaderHeight}
          rowHeight={rowHeightSetter}
          onRowsChange={setRows}
          onScroll={handleScroll}
        />
      )}
      {isLoading && (
        <div className="loadMoreOuter">
          <div className={`loadMoreRowsClassname`}>Loading more rows...</div>
        </div>
      )}
      {/* Data grid section end */}
    </>
  );
};

export default DataGridLoadMore;
