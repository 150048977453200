import React, { useEffect, useState } from "react";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Loading from "../../global/loading";
import QuickLinks from "../quickLinks";
import LeftMenu from "../../global/leftMenu";
import {
  IsTokenChecker,
  groupArrayByKey,
  InlineStyleSetter,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import BreadCrumb from "../../global/breadCrumb";
import ComponentListRenderer from "../../global/ContentBlockRenderSwitch";
import constantsVariable from "../../../utils/constantsVariable";
import CalendarEvents from "../../global/calendarEvents";

// const PaymentGrid = ({ fontSize1 }) => {
//   return (
//     <div
//       style={fontSize1 && { fontSize: fontSize1 }}
//       className="hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 max_content_table payments_equity_table"
//     >
//       <div className="table-responsive">
//         <table className="grid_block mb-0 width-100">
//           <thead>
//             <tr>
//               <td></td>
//               <td>North America</td>
//               <td>South America</td>
//             </tr>
//             <tr className="blue_bg">
//               <th>Total Proceeds to Farmers</th>
//               <th>$34.19</th>
//               <th>$34.19</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Less: Cash Advanced to Date</td>
//               <td>(27.21)</td>
//               <td>(28.46)</td>
//             </tr>
//             <tr>
//               <td>Less: Stock Retain (Qualified)</td>
//               <td>(2.00)</td>
//               <td>(2.00)</td>
//             </tr>
//             <tr>
//               <td>Less: Planned Allocated Retain (Non-Qualified)</td>
//               <td>(3.50)</td>
//               <td>(3.50)</td>
//             </tr>
//             <tr>
//               <td>Plus: Reduction in Allocated Retain</td>
//               <td>1.15</td>
//               <td>1.15</td>
//             </tr>
//             <tr className="blue_bg border-bottom-15px">
//               <td>Equals: Final Cash Payment</td>
//               <td>$2.63/bbl</td>
//               <td>$1.38/bbl</td>
//             </tr>
//             <tr className="blue_bg border-radius-6">
//               <td className="b-left-border-radius">
//                 Total PY17 Cran A Cash Total
//               </td>
//               <td>$29.84/bbl</td>
//               <td className="b-right-border-radius">$29.84/bbl</td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

const Body = (props) => {
  const {DropDownCBRoles} = props;
  const isPaymentIsEnabled = ( DropDownCBRoles?.includes("OscGcnPayments") || DropDownCBRoles?.includes("OscGcnDecisionMaker")) || false;
  const slug = "my-payments-and-equity";
  const { component_accordion_en_path, component_listing_dropdowns } =
    constantsVariable;

  const [loading, setLoading] = useState(true);

  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const [classforH5, setClassForH5] = useState("increase_decrease_font");
  const [points, setPoints] = useState({
    x: 0,
    y: 5,
    z: 5,
    zoom: 1,
    fontSize: 12,
    fontSize1: 14,
    fontSize2: 16,
    fontSize3: 18,
    fontSize4: 28,
  });
  const [disablePointerIn, setDisablePointerIn] = useState(false);
  const [disablePointerOut, setDisablePointerOut] = useState(true);

  const [allData, setAllData] = useState({});
  const [componentList, setComponentList] = useState([]);

  const getContentBlock = async () => {
    // const url = "content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,css_class,show_print_icon,icon_url)&component_listings.component_id=eq.e7bb6796-8248-46d9-a723-08ec31d3fb89"

    const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));
        let groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        let groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        let componentArr = groupData.map((list) =>
          _.get(list, "component_listings", {})
        );

        setAllData(groupedData);
        setComponentList(componentArr);
      })
      .catch((e) => {
        setComponentList([]);
        setAllData({});
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getContentBlock();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const ZoomIn = () => {
    // console.log("in" + points.zoom);
    if (points.y <= 5 && points.z >= 5 && points.z < 10) {
      setPoints({
        x: points.x + 1,
        y: points.y - 1,
        z: points.z + 1,
        zoom: points.zoom + 0.1,
        fontSize: points.fontSize + 2,
        fontSize1: points.fontSize1 + 2,
        fontSize2: points.fontSize2 + 2,
        fontSize3: points.fontSize3 + 2,
        fontSize4: points.fontSize4 + 2,
      });
      if (points.x == 0) {
        setClassForH5("increase_decrease_font1");
      }
      if (points.x == 1) {
        setClassForH5("increase_decrease_font2");
      }
      if (points.x == 2) {
        setClassForH5("increase_decrease_font3");
      }
      if (points.x == 3) {
        setClassForH5("increase_decrease_font4");
      }
      setDisablePointerIn(false);
      setDisablePointerOut(false);
    } else {
      setDisablePointerIn(true);
      setDisablePointerOut(false);
    }
  };

  const ZoomOut = () => {
    if (points.z > 5 && points.z <= 10) {
      setPoints({
        x: points.x - 1,
        y: points.y + 1,
        z: points.z - 1,
        zoom: points.zoom - 0.1,
        fontSize: points.fontSize - 2,
        fontSize1: points.fontSize1 - 2,
        fontSize2: points.fontSize2 - 2,
        fontSize3: points.fontSize3 - 2,
        fontSize4: points.fontSize4 - 2,
      });
      if (points.x == 2) {
        setClassForH5("increase_decrease_font1");
      }
      if (points.x == 3) {
        setClassForH5("increase_decrease_font2");
      }
      if (points.x == 4) {
        setClassForH5("increase_decrease_font3");
      }
      if (points.x == 5) {
        setClassForH5("increase_decrease_font4");
      }
      setDisablePointerIn(false);
      setDisablePointerOut(false);
    } else {
      setDisablePointerIn(false);
      setDisablePointerOut(true);
    }
  };

  const Normal = () => {
    setClassForH5("increase_decrease_font");
    setDisablePointerIn(false);
    setDisablePointerOut(true);
    setPoints({
      x: 0,
      y: 5,
      z: 5,
      zoom: 1,
      fontSize: 12,
      fontSize1: 14,
      fontSize2: 16,
      fontSize3: 18,
      fontSize4: 28,
    });
  };

  const { fontSize1 = null, fontSize2 = null } = points;
  const RenderPropertiesGroup = [
    {
      AdjustmentClassName: `mt-5 mt-lg-4`,
      // BlueTitleBoxClassNames: {
      //   HeaderInlineStyle: InlineStyleSetter({
      //     fontSize: fontSize2,
      //   }),
      //   textClassName: `${classforH5} visible_m_t_1 px-2 px-lg-0 ql-editor`,
      //   ContentInlineStyle: InlineStyleSetter({
      //     fontSize: fontSize1,
      //   }),
      // },
    },
    {
      AdjustmentClassName: `mt-5 mt-lg-4`,
      BlueTitleBoxClassNames: {
        HeaderInlineStyle: InlineStyleSetter({
          fontSize: fontSize2,
        }),
        textClassName: `${classforH5} visible_m_t_1 px-2 px-lg-0 ql-editor`,
        ContentInlineStyle: InlineStyleSetter({
          fontSize: fontSize1,
        }),
      },
    },
  ];
  const DefaultCssClass = component_listing_dropdowns?.blueTitleBox;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-8 d-none d-lg-block m_l_0_print">
            <BreadCrumb {...props} />
          </div>
          <div className="col-12 col-lg-2">
            <div className="float-end pt-5 pt-lg-4 hidden_print d-none d-lg-block">
              <i
                className={
                  disablePointerOut
                    ? "fa fa-minus blue_color cursor_pointer me-2 font-zoom-out text-14 disablePointer"
                    : "fa fa-minus blue_color cursor_pointer me-2 font-zoom-out text-14"
                }
                onClick={ZoomOut}
              ></i>
              <span
                className="blue_color cursor_pointer position-relative me-2 font-zoom-normal text-24 fw-bold"
                onClick={Normal}
              >
                A
              </span>
              <i
                className={
                  disablePointerIn
                    ? "fa fa-plus blue_color cursor_pointer me-2 font-zoom-in text-14 disablePointer"
                    : "fa fa-plus blue_color cursor_pointer me-2 font-zoom-in text-14"
                }
                onClick={ZoomIn}
              ></i>
              <img
                src={`${window.location.origin}/assets/images/icon_print_light_grey.svg`}
                className="img-40 me-2 mb-2 ms-2 cursor_pointer"
                alt="Print_Icon"
                onClick={() => {
                  window.print();
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <div className="bot-mspace-20 Quick_link">
              <LeftMenu {...props} slug={slug} />
              <QuickLinks {...props} slug={slug} />
            </div>
          </div>
          
          <div className="col-12 col-lg-10 zooming_block ">
          {isPaymentIsEnabled && <div className="mt-5 mt-lg-0"> <CalendarEvents slug="my-payments-and-equity" className="calendar-payment" title="Calendar Events for Payments"/></div>}
            <div className="row">
              <div className="col-12">
                <ComponentListRenderer
                  ComponentList={componentList}
                  GroupedData={allData}
                  RenderPropertiesGroup={RenderPropertiesGroup}
                  DefaultCssClass={DefaultCssClass}
                />
                {/* <div className="card-shadow white_bg border-radius-6 mt-5 mt-lg-0">
                  {IsStringChecker(title) && (
                    <h4
                      style={fontSize2 && { fontSize: fontSize2 }}
                      className="card-header white_color ver-space-15 left-border-radius right-border-radius"
                    >
                      {title}
                    </h4>
                  )}
                  <div className="card-body mb-4 payments_equity">
                    <div
                      style={fontSize1 && { fontSize: fontSize1 }}
                      className={classforH5 + " visible_m_t_1"}
                    >
                      {!_.isEmpty(blockOne)
                        ? parse(blockOne["content_" + resolvedLanguage])
                        : null}
                      <PaymentGrid fontSize1={fontSize1} />
                      {!_.isEmpty(blockTwo)
                        ? parse(blockTwo["content_" + resolvedLanguage])
                        : null}
                    </div>
                    <div className="mob-bottom-line"></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
