import React from "react";
import BreadCrumb from "../../global/breadCrumb";
import LeftMenu from "../../global/leftMenu";
import LandingPageCtaContentBlock from "../../global/landingPageCtaContentBlock";

const CoopFinanceLanding = (props) => {
  const { slug, sideNav = "", ...rest } = props;
  return (
    <>
      <div className="row">
        <div className="row">
          <div
            className={`col-12 ${
              sideNav !== "" && "offset-lg-2 col-lg-10"
            } d-none d-lg-block`}
          >
            <BreadCrumb {...rest} />
          </div>
        </div>
        <div className="row">
          {sideNav !== "" && (
            <div className="d-none d-lg-block col-lg-2">
              <LeftMenu {...rest} slug="coop-finance" />
            </div>
          )}
          <div className="col-12 col-lg-10">
            <LandingPageCtaContentBlock slug={slug} {...rest} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CoopFinanceLanding;
