import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const Logout = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = "selected_one=false; path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].localAccountId),
      postLogoutRedirectUri: "/",
    };
    // console.log("Logged Out => ", logoutRequest);

    // instance.logout(logoutRequest);
    instance.logoutRedirect(logoutRequest);
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};

export default Logout;
