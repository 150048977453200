import React, { Fragment, useState } from "react";
import _ from "lodash";
import { useMsal } from "@azure/msal-react";
import {
  IsArrayNotEmpty,
  HandleErrorTrackAndToast,
  IsStringChecker,
  IsArrayChecker,
  IsObjectChecker
} from "../../../utils/helper";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import WhiteCircleLoader from "../../whiteCircleLoader";
import MultiDropDown from "../../selectBox/multiDropDown";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import parse from "html-react-parser";

const SurveyWidgeForm = (props) => {

  const { surveyData, OrderedQuestions, requiredObjects, setSelectedObj, selectedObj, initialSelectedObj, resolvedLanguage, GBSRegions } = props;

  const { accounts } = useMsal();
  const user_id = _.get(accounts, `0.localAccountId`, "");
  const user_fullname = _.get(accounts, `0.name`, "");
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.reducerToken);
  const [multiValue, setMultiValue] = useState({});
  const [validation, setValidation] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState(false);
  const [optionalObject] = useState(OrderedQuestions.filter(e => e.is_required === false))
  const formSubmit = (e) => {
    e.preventDefault();
    // console.log("multiValue", selectedObj, requiredObjects)
    let validation = requiredObjects.filter((i) => {
      if (IsStringChecker(multiValue[i.id]) || IsObjectChecker(multiValue[i.id]) || IsArrayChecker(multiValue[i.id]))
        return Object.keys(multiValue).includes(i.id?.toString())
    });
    if (validation.length === requiredObjects.length) {
      setValidation(false);
      const masterPostData = [];
      Object.keys(selectedObj).map((i) => {
        selectedObj[i].map(item => {
          if (item.value !== "NaN") {
            masterPostData.push({
              survey_id: surveyData.id,
              user_id,
              user_fullname,
              survey_question_id: i,
              survey_option_id: !item.hasOwnProperty("optionType") ? item.optionID : null,
              survey_option_comment: item.value,
              survey_type: surveyData.survey_type,
              is_active: true,
            })
          }
        })

      });
      setLoader(true);
      SubmitSurveyData(masterPostData);
      // console.log("masterPostData", masterPostData);
    }
    else {
      setValidation(true);
    }
    return false;
  }

  const SubmitSurveyData = async (masterData = []) => {
    var SubmittedSurveyData = await Promise.all(
      masterData.map(async (postData) => {
        return await cmsDataCommonMethod(
          idToken,
          accounts,
          `custom-survey-entries`,
          "POST",
          postData
        )
          .then(() => {
            return true;
          })
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
            return false;
          });
      })
    ).catch((e) => {
      HandleErrorTrackAndToast(e, true, "replace", dispatch);
      return false;
    });
    const DataSubmitted = () => {
      if (IsArrayNotEmpty(SubmittedSurveyData)) {
        return SubmittedSurveyData.every((li) => !!li);
      } else {
        return false;
      }
    };
    const success = DataSubmitted();
    if (success) {
      setSubmitted(true);
      reset();
      setTimeout(() => {
        setSubmitted(false);
      }, 5000);
      setLoader(false);
    } else {
      setSubmitted(false);
      setLoader(false);
    }
  };
  const same = (e, questionID, optionID) => {

    let createObj = multiValue[questionID] || [];
    if (e.target.checked) {
      let index = createObj.findIndex((e) => e.optionID === optionID);
      if (createObj.length === 0 || index === -1) {
        createObj.push({ value: "0.0000", "optionID": optionID });
      }
      else
        createObj.map((e, i) => e.optionID === optionID && (createObj[i].value = "0.0000"));
    }
    else {
      let index = createObj.findIndex((e) => e.optionID === optionID);
      createObj.splice(index, 1);
    }
    setMultiValue({ ...multiValue, [questionID]: createObj });
    setSelectedObj({ ...selectedObj, [questionID]: createObj });
    if (e.target.checked) {

      e.target.parentElement.parentElement.querySelectorAll("input[type='number']").forEach((e) => {
        // console.log(e.setAttribute("disabled", "disabled"));
        e.value = null;
      })
    }
    else {
      e.target.parentElement.parentElement.querySelectorAll("input[type='number']").forEach((e) => {
        // console.log(e.removeAttribute("disabled"))
        e.value = null;
      })
    }

  }
  const numberUpdateRight = (e, questionID, optionID) => {
    // console.log(e);
    let createObj = multiValue[questionID] || [];
    let index = createObj.findIndex((e) => e.optionID === optionID);
    if (createObj.length === 0 || index === -1) {
      createObj.push({ value: (parseFloat(e.target.value) / 100).toFixed(4), "optionID": optionID });
    }
    else {
      createObj[index].value = (parseFloat(e.target.value) / 100).toFixed(4);
    }
    setMultiValue({ ...multiValue, [questionID]: createObj });
    setSelectedObj({ ...selectedObj, [questionID]: createObj });
    e.target.parentElement.previousElementSibling.firstChild.checked = false;
    e.target.parentElement.previousElementSibling.previousElementSibling.querySelector(".form-control").value = null;

  }
  const numberUpdateLeft = (e, questionID, optionID) => {
    let createObj = multiValue[questionID] || [];
    let index = createObj.findIndex((e) => e.optionID === optionID);
    if (createObj.length === 0 || index === -1) {
      createObj.push({ value: `-${(parseFloat(e.target.value) / 100).toFixed(4)}`, "optionID": optionID });
    }
    else {
      createObj[index].value = `-${(parseFloat(e.target.value) / 100).toFixed(4)}`;
    }
    setSelectedObj({ ...selectedObj, [questionID]: createObj });
    setMultiValue({ ...multiValue, [questionID]: createObj });
    e.target.parentElement.nextElementSibling.firstChild.checked = false;
    e.target.parentElement.nextElementSibling.nextElementSibling.querySelector(".form-control").value = null;

  }
  const [resetValues, setResetValues] = useState(true);

  const reset = () => {
    setResetValues(false);
    setValidation(false);
    setMultiValue({});
    setSelectedObj({ ...initialSelectedObj })
    setTimeout(() => {
      setResetValues(true);
    }, 100);
  }

  const designTemplate = (item, index) => {
    if (item.survey_design_template === "contract-based-region") {
      const options = [];
      item.survey_options.map((i) => {
        if (surveyData.survey_type === "grower") {

          GBSRegions.includes(i.option_en?.replace(/ /g, "-")?.toLowerCase()) &&
            options.push({ name: i?.['option_' + resolvedLanguage], id: i.id, option_value: i.option_value });

          // options.push({ name: i?.['option_' + resolvedLanguage], id: i.id })
        }
        else {
          options.push({ name: i?.['option_' + resolvedLanguage], id: i.id, option_value: i.option_value })
        }
      });
      // console.log(options[0].name,"options[0].name")
      return (
        <div key={index}>
          <div className="single-select">
            <span className="label-txt text-14">{item?.['question_' + resolvedLanguage]}{item.is_required && <span className="required">*</span>}</span>
            <div className="mb-2 mt-2" data-label="Select Region">
              <MultiDropDown
                optionList={options}
                prompt="Select"
                value={multiValue}
                onChange={(val) => {
                  setSelectedObj({ ...selectedObj, [item.id]: [{ value: val[0].option_value, "optionID": val[0].id }] });
                  setMultiValue({ ...multiValue, [item.id]: { value: val[0].option_value, "optionID": val[0].id } });
                }}
                label={`${item.id}`}
                usestate={setMultiValue}
                preSelectedOpt={[options[0]?.id]}
                disabled=""
                onDelete={() => { }}
                singleSelect={true}
              />
            </div>
          </div>
        </div>
      )
    }
    else if (item.survey_design_template === "above-or-below-grid" && item?.survey_options?.length > 0) {
      return (
        <div className="above-or-below-grid" key={index}>
          <label className="label-txt text-14">{item?.['question_' + resolvedLanguage]}{item.is_required && <span className="required">*</span>}</label>
          <table className="table">
            <thead>
              <tr>
                <td>Compare To</td>
                <td>Below</td>
                <td>Same</td>
                <td>Above</td>
              </tr>
            </thead>
            <tbody>
              {
                item?.survey_options.map((i, k) => <tr key={k}>
                  <td>{i?.['option_' + resolvedLanguage]}</td>
                  <td><input
                    min="0.1"
                    step="0.1"
                    max="100.0"
                    className="form-control width60" type="number" maxLength="4" onChange={(e) => numberUpdateLeft(e, `${item.id}`, i.id)} /> <span>%</span></td>
                  <td><input className="checkbox" type="checkbox" onChange={(e) => same(e, `${item.id}`, i.id)} /></td>
                  <td><input
                    min="0.1"
                    step="0.1"
                    max="1000.0"
                    className="form-control width60" type="number" pattern="\d*" maxLength="4" onChange={(e) => numberUpdateRight(e, `${item.id}`, i.id)} /> <span>%</span></td>
                </tr>)
              }
            </tbody>
          </table>
        </div>
      )
    }
    else if (item.survey_design_template === "single-select-cranberry" && item?.survey_options?.length > 0) {
      const rating = [], options = _.orderBy(item.survey_options, ['order_by'], ['desc']);
      options.map((i) => {
        let val = parseInt(i.option_value || 0);
        let raring = "";
        for (let i = 0; i < val; i++)
          raring += "🍒";
        rating.push({ name: `${i?.['option_' + resolvedLanguage]}`, id: i.id, option_value: i.option_value, imageValue: raring })
      })
      // console.log("rating", rating, options)
      return (
        <div className="single-select single-select-rating" key={index}>
          <span className="label-txt text-14">{item?.['question_' + resolvedLanguage]}{item.is_required && <span className="required">*</span>}</span>
          <div className="mb-2 mt-2">
            <MultiDropDown
              optionList={rating}
              prompt="Select"
              value={multiValue}
              onChange={(val) => {
                setSelectedObj({ ...selectedObj, [item.id]: [{ value: val[0].option_value, "optionID": val[0].id }] });
                setMultiValue({ ...multiValue, [item.id]: { value: val[0].option_value, "optionID": val[0].id } })
              }}
              label={`${item.id}`}
              usestate={setMultiValue}
              disabled=""
              onDelete={() => { }}
              singleSelect={true}
              showImage={true}
            />
          </div>
        </div>
      )
    }
    else if (item.survey_design_template === "comment") {
      return (
        <div key={index}>
          <p className="label-txt text-14">{item?.['question_' + resolvedLanguage]}{item.is_required && <span className="required">*</span>}</p>
          <textarea onChange={(e) => {
            setSelectedObj({ ...selectedObj, [item.id]: [{ optionType: "comment", value: e.target.value }] });
            setMultiValue({ ...multiValue, [`${item.id}`]: [{ optionType: "comment", value: e.target.value }] })
          }} maxLength={500} className="form-control mb-2" rows={3} value={multiValue[0]?.value}> </textarea>
        </div>
      )
    }
    else if (item.survey_design_template === "single-select") {
      const options = [];
      item.survey_options.map((i) => {
        options.push({ name: i?.['option_' + resolvedLanguage], id: i.id, option_value: i.option_value })
      });
      return (
        <div key={index}>
          <div className="single-select">
            <span className="label-txt text-14">{item?.['question_' + resolvedLanguage]}{item.is_required && <span className="required">*</span>}</span>
            <div className="mb-2 mt-2" data-label="Select Region">
              <MultiDropDown
                optionList={options}
                prompt="Select"
                value={multiValue}
                onChange={(val) => {
                  setSelectedObj({ ...selectedObj, [item.id]: [{ value: val[0].option_value, "optionID": val[0].id }] })
                }}
                label={`${item.id}`}
                usestate={setMultiValue}
                // preSelectedOpt={[options[0]?.id]}
                disabled=""
                onDelete={() => { }}
                singleSelect={true}
              />
            </div>
          </div>
        </div>
      )
    }
    else if (item.survey_design_template === "multi-select") {
      const options = [];
      item.survey_options.map((i) => {
        options.push({ name: i?.['option_' + resolvedLanguage], id: i.id, option_value: i.option_value })
      });
      return (
        <div key={index}>
          <div className="single-select">
            <span className="label-txt text-14">{item?.['question_' + resolvedLanguage]}{item.is_required && <span className="required">*</span>}</span>
            <div className="mb-2 mt-2" data-label="Select Region">
              <MultiDropDown
                optionList={options}
                prompt="Select"
                value={multiValue}
                onChange={(val) => {
                  let arrObj = [];

                  val.map(i => arrObj.push({ value: i.option_value, "optionID": i.id }))
                  setSelectedObj({ ...selectedObj, [item.id]: arrObj })
                }}
                label={`${item.id}`}
                usestate={setMultiValue}
                // preSelectedOpt={[options[0]?.id]}
                disabled=""
                onDelete={() => { }}
                singleSelect={false}
              />
            </div>
          </div>
        </div>
      )
    }
  }
  if (!resetValues) {
    return <></>
  }
  else
    return (
      <>
        <form onSubmit={formSubmit}>
          <div className="card-body">
            <div className="grower-survey">
              {
                OrderedQuestions.map((item, index) => {
                  if (item.is_required)
                    return designTemplate(item, index);
                })
              }
              {
                optionalObject.length > 0 && <>
                  <Accordion className="aditional-detail-accordion" defaultActiveKey="0">
                    <Accordion.Item>
                      <Accordion.Header>
                        Optional Detail
                      </Accordion.Header>
                      <Accordion.Body>
                        {
                          OrderedQuestions.map((item, index) => {
                            if (!item.is_required)
                              return designTemplate(item, index);
                          })
                        }
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </>
              }
              {requiredObjects.length > 0 && <p className="text-end"><span className="required">*</span> fields are required.</p>}
              {validation && <p className="error">Please fill all required fields</p>}
              {submitted && <p className="success">{surveyData?.['survey_title_' + resolvedLanguage]} is submitted successfully</p>}
              <div className="submit-buttons text-end mb-2">
                <button className="btn btn-primary" disabled={loader} type="submit">Submit {loader ? <WhiteCircleLoader /> : ""}</button>
                <button className="btn btn-default card-shadow ms-2" type="button" onClick={reset}>Reset</button>
              </div>
              {surveyData?.['survey_notes_' + resolvedLanguage] && <div className="ql-editor">{parse(surveyData?.['survey_notes_' + resolvedLanguage])}</div> }
            </div>
          </div>
        </form>
      </>
    )
};

export default SurveyWidgeForm;
