import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import { NotificationContext } from "../contextVariable";
import moment from "moment";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
  IsStringChecker,
} from "../../../utils/helper";

const NotificationState = (props) => {
  const { accounts } = useMsal();
  const localAccountId = _.get(accounts, "0.localAccountId", "");
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const [notificationLoader, setNotificationLoader] = useState(false);
  const [notificationServiceCalled, setNotificationServiceCalled] =
    useState(false);

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getNotification();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const getNotification = async () => {
    if (IsStringChecker(tokenId)) {
      setNotificationLoader(true);
      await gbsDataCommonMethod(
        tokenId,
        accounts,
        `persons/notifications/${localAccountId}`,
        "get"
      )
        .then((res) => {
          if (res?.data) {
            let response = res?.data?.sort(
              (a, b) => moment(b.createdAt).utc() - moment(a.createdAt).utc()
            );
            response = response.filter((item) => {
              return item.read === false;
            });
            setNotificationSuccess(response || []);
            setNotificationServiceCalled(true);
          }
        })
        .catch((e) => {
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        })
        .finally(() => {
          setNotificationLoader(false);
        });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationSuccess,
        notificationLoader,
        notificationServiceCalled,
        getNotification,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationState;
