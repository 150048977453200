import Moment from "react-moment";
import parse from "html-react-parser";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useSelector, useDispatch } from "react-redux";
import { CommentImageUrl } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import { HandleErrorTrackAndToast } from "../../utils/helper";

const Comments = ({ comment, postId, refresh, setRefresh }) => {
  const [toggleReply, setToggleReply] = useState(false);
  const [text, setText] = useState("");
  const { accounts } = useMsal();
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingReply, setLoadingReply] = useState(false);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(comment);
    for (let i = 0; i < comment.news_event_comment_likes.length; i++) {
      if (comment.news_event_comment_likes[i].like_name != accounts[0].name) {
        setLiked(false);
        continue;
      } else {
        setLiked(true);
      }
    }
  }, [refresh]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const idToken = useSelector((state) => state.reducerToken);
  const submitComment = async (id) => {
    const body = {
      user_id: accounts[0].localAccountId,
      comment_name: accounts[0].name,
      comment: text,
      comments_id: id,
    };
    setLoadingReply(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `news-event-comments/` + postId,
      "POST",
      body
    )
      .then(() => {
        setRefresh(refresh + 1);
        setText("");
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
    setLoadingReply(false);
  };
  const clickOnLikePost = async (id, comment) => {
    setLoading(true);
    const body = {
      user_id: accounts[0].localAccountId,
      like_name: accounts[0].name,
      news_event_comments_id: id,
    };
    if (liked && comment.news_event_comment_likes.length > 0) {
      let like_id = 0;
      for (let i = 0; i < comment.news_event_comment_likes.length; i++) {
        if (comment.news_event_comment_likes[i].like_name != accounts[0].name) {
          continue;
        } else {
          like_id = comment.news_event_comment_likes[i].id;
        }
      }

      await cmsDataCommonMethod(
        idToken,
        accounts,
        `news-event-comment-likes/${like_id}?userid=` +
          accounts[0].localAccountId,
        "DELETE",
        body
      )
        .then(() => {
          setRefresh(refresh + 1);
        })
        .catch((e) => {
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLiked(false);
        });
    } else {
      await cmsDataCommonMethod(
        idToken,
        accounts,
        `news-event-comments/` + id + `/like`,
        "POST",
        body
      )
        .then(() => {
          setRefresh(refresh + 1);
        })
        .catch((e) => {
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLiked(true);
        });
    }
    setLoading(false);
  };

  return (
    <div className="d-flex mt-4 mb-1 pb-3 reply_section">
      <div className="col-2 col-sm-1">
        <img
          src={CommentImageUrl + comment.user_id + ".jpg"}
          className="card-img-top border-radius-50 comment_profile_img"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
          }}
        />
      </div>
      <div className="col-10 col-sm-11">
        <p className="mb-1 ms-2 ms-sm-3 light_grey_color">
          {comment.comment_name}{" "}
          <span className="d-block d-sm-inline-block float-sm-end">
            <Moment format="MMM DD, YYYY">{comment.created_at}</Moment> at{" "}
            <Moment local utc format="h:mm a">
              {comment.created_at}
            </Moment>
          </span>
        </p>
        <p className="mb-1 ms-2 ms-sm-3 m-0-p">{parse(comment.comment)}</p>
        <p className="mb-0 ms-2 ps-1 ms-sm-3 ps-sm-1">
          <a className="fw-bold cursor_pointer">
            {!loading ? (
              <i
                onClick={() => clickOnLikePost(comment.id, comment)}
                className="fa fa-thumbs-up"
                aria-hidden="true"
                style={
                  comment.news_event_comment_likes?.filter(i=>i.like_name == accounts[0].name).length > 0
                    ? { color: "blue" }
                    : null
                }
              ></i>
            ) : (
              <img  src="/assets/images/red-loader-small.svg" height={20} width={20}/>
            )}{" "}
            {comment.news_event_comment_likes.length}
            <span
              id="reply_btn"
              className="btn btn-default btn-sm border-1 blue_color ms-3"
              onClick={() => setToggleReply(!toggleReply)}
            >
              <i className="fa fa-reply" aria-hidden="true"></i>{" "}
              {moreLangs[i18n.language]?.home_page?.reply}
            </span>
          </a>
        </p>

        <div
          className={toggleReply ? "reply_block active" : "reply_block"}
          id="reply_block"
        >
          <i
            className="fa fa-times-circle-o cursor_pointer close_click float-end blue_color text-16 m-1 test"
            aria-hidden="true"
            onClick={() => setToggleReply(!toggleReply)}
          >
            {" "}
          </i>

          <textarea
            className="form-control"
            placeholder={
              moreLangs[i18n.language]?.home_page?.enter_your_reply_here
            }
            rows="3"
            value={text}
            onChange={(val) => setText(val.target.value)}
          />
          <button
            className="btn btn-primary float-end mt-2 mb-4"
            style={{ marginRight: "4px" }}
            onClick={() => submitComment(comment.id)}
          >
            {loadingReply ? (
              <>
                {moreLangs[i18n.language]?.home_page?.submit}
                <img
                  src={`${window.location.origin}/assets/images/white-loader-small.svg`}
                  height={20}
                  width={20}
                  style={{ color: "white" }}
                />
              </>
            ) : (
              moreLangs[i18n.language]?.home_page?.submit
            )}
          </button>
          {comment.news_event_comments
            .map((reply, index) => (
              reply.is_active && <div className="row mt-4 mb-3 pb-3 clear-fix" key={index}>
                <div className="col-2 col-sm-1">
                  <img
                    src={CommentImageUrl + reply.user_id + ".jpg"}
                    alt="comment_profile_img"
                    className="card-img-top border-radius-50 comment_profile_img"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                    }}
                  />
                </div>
                <div className="col-10 col-sm-11">
                  <p className="mb-1 ms-2 ms-sm-3 light_grey_color">
                    {reply.comment_name}{" "}
                    <span className="d-block d-sm-inline-block float-sm-end">
                      <Moment format="MMM DD, YYYY">{reply.created_at}</Moment>{" "}
                      at{" "}
                      <Moment local utc format="h:mm a">
                        {reply.created_at}
                      </Moment>
                    </span>
                  </p>
                  <p className="mb-1 ms-2 ms-sm-3">{reply.comment}</p>
                </div>
              </div>
            ))
            .reverse()}
        </div>
      </div>
    </div>
  );
};

export default Comments;
