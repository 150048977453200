import React from "react";
import PageLayout from "../pageLayout";
import Body from "./body";
import UserprofileDetails from "./userprofileDetails";

const UserProfile = (props) => {
  return (
    <PageLayout {...props} body={<Body isLeftMenu ="false" {...props} contentbody={<UserprofileDetails {...props} />} />} />
  );
};

export default UserProfile;
