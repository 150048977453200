import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { getCurrrentCropYear } from "../../utils/helper";

const useActiveCropYear = () => {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { activeCropYear: currentYear = null, reducerToken: tokenId = null } =
    state;
  // console.log("Hook currentYear => ", currentYear);

  useMemo(() => {
    if (currentYear === null) {
     return getCurrrentCropYear(
        tokenId,
        accounts,
        `crop-years?currentcrop=1`,
        dispatch
      );
    }
  }, [currentYear]);

  return state;
};

export default useActiveCropYear;
