import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
} from "@azure/msal-react";
// import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"; //65417 - App Insights - NK
import "./app.css";
import { loginRequest } from "./configs/authConfig";
import { reactPlugin } from "./configs/appInsights"; //65417 - App Insights - NK
import callAdJwtToken from "./services/adMsal";

import IdleLogoutLogic from "./components/idleLogoutLogic"; // 55546 - Auto Logout Logic
import ImageState from "./components/context/profile/imageState";
import NotificationState from "./components/context/notifications/notificationState";
// import CurrentLocation from "./components/global/currentLocation";
import WrapperComponent from "./WrapperComponent"; //60844 - NK

import { IsArrayNotEmpty, IsObjectChecker, IsStringChecker } from "./utils/helper";
import routes from "./routes"; //65575 - NK

const App = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const results = useSelector((state) => state.reducerToken);
  // const { i18n } = useTranslation();
  // const [, moreLangs] = CurrentLocation();
  const ref = useRef();
  // const [visible, setVisible] = useState(false);

  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 500) {
  //     setVisible(true);
  //   } else if (scrolled <= 500) {
  //     setVisible(false);
  //   }
  // };

  // window.addEventListener("scroll", toggleVisible);

  const dispatch = useDispatch();

  if (inProgress === "none" && !isAuthenticated && accounts.length == 0) {
    instance.loginRedirect(loginRequest);
  }

  useEffect(() => {
    if (isAuthenticated && instance && accounts.length) {
      if (results.data == undefined || !results.data) {
        dispatch(callAdJwtToken(instance, accounts));
      }
    }
  }, [isAuthenticated]);
  return (
    <AuthenticatedTemplate>
      <NotificationState>
        <ImageState>
          <AppInsightsContext.Provider value={reactPlugin}>
            <div className="app" ref={ref}>
              <Switch>
                {IsArrayNotEmpty(routes) ? (
                  routes.map((list, index) => {
                    const {
                      title,
                      path,
                      exact = false,
                      CheckRoleCB = false,
                      CheckSpecialRoleCB = false,
                      IsCommonPage = false,
                      CommonPageCBRoles = [],
                      component: Component,
                      ...rest
                    } = list;
                    const PageTitle =
                      IsObjectChecker(title) && !_.isEmpty(title)
                        ? title
                        : defaultPageTilte;
                    const routePath = IsStringChecker(path) ? path : "/";
                    return (
                      <Route
                        key={index}
                        exact={exact}
                        path={routePath}
                        {...rest}
                        render={(routeProps) => (
                          <WrapperComponent
                            CheckRoleCB={CheckRoleCB}
                            CheckSpecialRoleCB={CheckSpecialRoleCB}
                            IsCommonPage={IsCommonPage}
                            CommonPageCBRoles={CommonPageCBRoles}
                            {...PageTitle}
                            {...routeProps}
                            child={(props) => <Component {...props} />}
                          />
                        )}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
                <Route path="/community/ag-sciences">
                  <Redirect to={"/community/ag-sciences/region"} />
                </Route>
              </Switch>
              {/* {visible ? (
                <>
                  <div className="back_to_top d-none d-lg-block">
                    <a href="#" className="fw-bold">
                      {moreLangs[i18n.language]?.home_page?.back_to_top}{" "}
                      <i
                        className="fa fa-long-arrow-up position-relative t_1"
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </div>
                  <div className="back_to_top d-block d-sm-block d-md-none d-lg-none">
                    <a href="#" className="fw-bold">
                      {moreLangs[i18n.language]?.home_page?.top}{" "}
                      <i
                        className="fa fa-long-arrow-up position-relative t_1"
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                  </div>
                </>
              ) : null} */}
              <IdleLogoutLogic />
            </div>
          </AppInsightsContext.Provider>
        </ImageState>
      </NotificationState>
    </AuthenticatedTemplate>
  );
};

export default App;