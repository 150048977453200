/**
 * @component_Name : ProfilePicture
 * @description : Profile Picture
 * @company : Photon
 * @author : Ashish Ranjan/27-09-2022
 **/
import React, { useState, useContext, useEffect, useRef } from "react";
import imageContext from "../context/profile/imageContext";
import { useMsal } from "@azure/msal-react";
import { CommentImageUrl } from "../../utils/constants";
import WhiteCircleLoader from "../whiteCircleLoader";
import { getObjectLength } from "../../utils/helper";
import { Alert } from "react-bootstrap";
import CurrentLocation from "../global/currentLocation";
import { useTranslation } from "react-i18next";
import {
  // ImpersonationAccountsSwap,
  // LocalAccountIDSwapImpersonate,
  DetermineImpersonation,
} from "../../permissionWrapper";

const ProfilePicture = () => {
  const impersonated = DetermineImpersonation();
  // const { accounts: msal_accounts } = useMsal();
  // const accounts = ImpersonationAccountsSwap(msal_accounts);
  // const localAccountId = LocalAccountIDSwapImpersonate(accounts);
  const { accounts } = useMsal();
  const localAccountId = _.get(accounts, "0.localAccountId", "");
  const ref = useRef();
  const context = useContext(imageContext);
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const { statusSuccess, loading, currentProfileImage, updateProfileImage } =
    context;
  const [fileInput, setFileInput] = useState({});
  const [validateError, setValidateError] = useState();
  const profileName = accounts[0].username
    ? accounts[0].username
    : accounts[0].name;
  const mainProfileImage = localAccountId
    ? CommentImageUrl + localAccountId + ".jpg"
    : "";
  const [profileImage, setProfileImage] = useState(
    mainProfileImage !== null
      ? mainProfileImage + "?" + new Date().getTime()
      : mainProfileImage
  );
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });
  const [submitclicked, setSubmitclicked] = useState(false);

  useEffect(() => {
    if (statusSuccess == true) {
      setProfileImage(currentProfileImage);
      setFileInput({});
      ref.current.value = "";
    }
  }, [statusSuccess]);

  const fileHandler = (e) => {
    setDisableSubmitCancel(true);
    const pic = e.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(pic.name)) {
      setValidateError(
        "Please upload file having extensions .jpg|.jpeg|.png only."
      );
      ref.current.value = "";
      setFileInput({});
      return false;
    } else if (pic.size > 1024 * 1024 * 2) {
      setValidateError("Image should not be more than 2 mb");
      ref.current.value = "";
      setFileInput({});
    } else {
      setDisableSubmitCancel(false);
      setFileInput({ [e.target.name]: e.target.files[0] });
      setValidateError();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (getObjectLength(fileInput) > 0) {
      updateProfileImage(fileInput);
      setSubmitclicked(true);
    }
  };

  useEffect(() => {
    if (!loading && submitclicked) {
      setDisableSubmitCancel(true);
      setSubmitclicked(false);
      setAlertOpen({
        isActive: true,
        message: `${
          moreLangs[i18n.resolvedLanguage].userProfile
            .notification_preferences_save_msg
        }`,
      });
      setTimeout(() => {
        setAlertOpen({ isActive: false, message: "" });
      }, 5000);
    }
  }, [loading]);

  const returnBtn = () => {
    ref.current.value = "";
    setFileInput({});
    setDisableSubmitCancel(true);
  };

  function SubmitBtnDisabled() {
    if (impersonated) {
      return true;
    } else {
      if (loading || disableSubmitCancel) {
        return true;
      }
      return false;
    }
  }

  const submit_disabled = SubmitBtnDisabled();
  return (
    <>
      {/* <!-- Profile Picture tab starts --> */}
      {alertOpen.isActive && (
        <Alert
          variant="success"
          onClose={() => setAlertOpen({ isActive: false, message: "" })}
          dismissible
        >
          <b>{alertOpen.message}</b>
        </Alert>
      )}
      <div className="white_bg border-radius-6 p-4 mb-4">
        <div className="row">
          <div className="text-center">
            <img
              className="img-fluid border-radius-50 file-img"
              src={profileImage}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
              }}
            />
            <h4 className="fw-bold blue_color py-3">{profileName}</h4>
            <form encType="multipart/form-data" onSubmit={submitHandler}>
              <div>
                <input
                  type="file"
                  name="profilePic"
                  id="profilePic"
                  required
                  onChange={fileHandler}
                  ref={ref}
                  disabled={impersonated}
                  className={impersonated ? "cursor-not-allowed" : ""}
                />
              </div>
              {validateError && (
                <p className="red_dark_color position-relative d-inline-block pt-3 mb-0 t_5 text-capitalize">
                  {validateError}
                </p>
              )}
              <div className="py-3">
                <button
                  disabled={submit_disabled}
                  type="submit"
                  className={`btn btn-primary me-3 ${submit_disabled ? "cursor-not-allowed" : ""}`}
                >
                  Save {!loading ? "" : <WhiteCircleLoader />}{" "}
                </button>
                <button
                  className={`btn text-16 btn-primary me-3 ${disableSubmitCancel ? "cursor-not-allowed" : ""}`}
                  disabled={disableSubmitCancel}
                  onClick={returnBtn}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- Profile Picture tab ends -->			 */}
    </>
  );
};
export default ProfilePicture;
