import React from "react";
import { Accordion } from "react-bootstrap";
import QuickLinks from "../../quickLinks";
import LeftMenu from "../../../global/leftMenu";
import StockPledge from "./stockPledge";
// import { Link } from "react-router-dom";
import EquityQuota from "./equityQuota";
import EquityBalances from "./equityBalances";
import Breadcrumb from "../../../global/breadCrumb";

const body = (props) => {
  return (
    <div className="row">
      <div className="row">
        <div className=" col-12 offset-lg-2 col-lg-10 d-none d-lg-block m_l_0_print">
          <Breadcrumb {...props} />
        </div>
      </div>
      <div className="row">
        <div className="d-none d-lg-block col-lg-2">
          <LeftMenu {...props} slug="my-payments-and-equity" />
          <div className=" bot-mspace-20 Quick_link ">
            <QuickLinks {...props} slug={"equity"} />
          </div>
        </div>
        <div className="col-12 col-lg-10">
          <Accordion
            className=" mt-5 mb-3 mt-lg-0 mb-lg-4"
            id="accordionequity"
          >
            <Accordion.Item eventKey="Equity Quota">
              <Accordion.Header>
                <img
                  src="../../../assets/images/badge-percent.png"
                  className="width-35"
                  alt="equity_quota_icon"
                />{" "}
                Common Stock Quota
              </Accordion.Header>
              <Accordion.Body>
                <EquityQuota {...props} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="Equity Balances">
              <Accordion.Header>
                <img
                  src="../../../assets/images/icon_equity.jpg"
                  className="width-35 right-space-5"
                  alt="equity_quota_icon"
                />{" "}
                Equity Balances
              </Accordion.Header>
              <Accordion.Body>
                <EquityBalances {...props} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="Stock Pledge"
              className="my_equity_stock_pledge_table"
            >
              <Accordion.Header>
                <img
                  src="../../../assets/images/landmark-solid.svg"
                  className="width-30 right-space-5"
                  alt="stock_pledge_icon"
                />{" "}
                Stock Pledge
              </Accordion.Header>
              <Accordion.Body>
                <StockPledge {...props} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default body;
