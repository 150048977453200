import React, { useEffect, useState, useRef } from "react";
import BreadCrumb from "../global/breadCrumb";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../global/loading";
import {
  getproper_Atag_URLfromRichContent,
  IsStringChecker,
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import { Link, useLocation } from "react-router-dom";
import { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import {
  ContentModifierLogic,
  // RemoveNBSPfromString,
} from "../global/contentModifier";
import CurrentLocation from "../global/currentLocation";
import { dateFormate } from "../../utils/constantsVariable";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";

const SearchDetails = (props) => {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const searchVal = search ? decodeURI(new URLSearchParams(search).get("q")) : "";
  const [authorMasterData, setAuthorMasterData] = useState([]);
  const [authorDataCountNull, setAuthorDataCountNull] = useState(false);
  const [searchAllResults, setSearchAllResults] = useState({});
  const [newsResults, setNewsResults] = useState({});
  const [eventResults, setEventResults] = useState({});
  const [authorResults, setAuthorResults] = useState([]);

  const [initiated, setInitiated] = useState(false)

  const searchtext = sessionStorage.getItem("searchtext") || 10;
  const searchlimit = sessionStorage.getItem("searchlimit") || 10;
  const searchloadmorelimit = sessionStorage.getItem("searchloadmorelimit") || JSON.stringify({ all: 0, news: 0, events: 0 });
  const searchResultcount = sessionStorage.getItem("searchResultcount") || JSON.stringify({ all: 0, news: 0, events: 0 });
  const searchloadmorelimitreached = sessionStorage.getItem("searchloadmorelimitreached") || JSON.stringify({ all: false, news: false, events: false });
  const searchcategory = sessionStorage.getItem("searchcategory");

  const searchpageno = sessionStorage.getItem("searchpageno") || JSON.stringify({ all: 0, news: 0, events: 0 });

  // console.log("selectedPage.all", searchpageno);
  const [limit, setLimit] = useState(parseInt(searchlimit) ? parseInt(searchlimit) : 10);
  const [offset, setOffset] = useState(JSON.parse(searchloadmorelimit));
  const [searchCount, setSearchCount] = useState(JSON.parse(searchResultcount));
  const [selectedTab, setSelectedTab] = useState(searchcategory || 'all');
  const [offsetReached, setOffsetReached] = useState(JSON.parse(searchloadmorelimitreached));
  const [selectedPage, setSelectedPage] = useState(JSON.parse(searchpageno));

  //console.log("Testing Data",JSON.parse(searchloadmorelimitreached), offsetReached);

  const searchSelectedRef = useRef(null);

  const alreadySelected = sessionStorage.getItem("selecteddetailpagesearch") ? JSON.parse(sessionStorage.getItem("selecteddetailpagesearch")) : null;

  useEffect(() => {
    // console.log("selected DOM", searchSelectedRef.current);
    setTimeout(() => {
      searchSelectedRef.current?.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        // sessionStorage.removeItem("selecteddetailpagesearch")
      }, 100);
    }, 100);
  }, [searchSelectedRef.current]);

  // This will run one time after the component mounts
  useEffect(() => {
    // callback function to call when event triggers
    const onPageLoad = () => {
      //console.log('page loaded');
      // do something else
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  useEffect(() => {
    if (IsTokenChecker(idToken) && initiated) {
      // console.log("Testing Data11");
      sessionStorage.removeItem("selecteddetailpagesearch");
      sessionStorage.removeItem("searchcategory");
      sessionStorage.setItem("searchloadmorelimit",JSON.stringify({ all: 0, news: 0, events: 0 }));
      sessionStorage.setItem("searchResultcount",JSON.stringify({ all: 0, news: 0, events: 0 }));
      sessionStorage.setItem("searchloadmorelimitreached",JSON.stringify({ all: false, news: false, events: false }));
      sessionStorage.setItem("searchpageno",JSON.stringify({ all: 0, news: 0, events: 0 }));
      sessionStorage.setItem("searchtext", searchVal);
      setSelectedPage(...{ all: 0, news: 0, events: 0 });
      setOffset(...{ all: 0, news: 0, events: 0 });
      setOffsetReached(...{ all: false, news: false, events: false });
      setSelectedTab("all");
      setSearchAllResults(...{});
      setNewsResults(...{});
      setEventResults(...{});
      setAuthorResults([]);
      getSearchAllTabResult(0, 0, false, true);
    }
  }, [searchVal]);

  useEffect(() => {
    if (IsTokenChecker(idToken) && initiated) {
      // console.log("Testing Data22");
      sessionStorage.removeItem("selecteddetailpagesearch");
      if (selectedTab === "news") {
        setOffset({ ...offset, news: 0 });
        setSelectedPage({ ...offset, news: 0 });
        setOffsetReached({ ...offsetReached, news: false });
        setNewsResults({});
        getSearchNewsTabResult(0, 0, false, true);
      }
      else if (selectedTab === "events") {
        setOffset({ ...offset, events: 0 });
        setSelectedPage({ ...offset, events: 0 });
        setOffsetReached({ ...offsetReached, events: false });
        setEventResults({});
        getSearchEventTabResult(0, 0, false, true);
      }
      else {
        setOffset({ ...offset, all: 0 });
        //console.log("selectedPage.all", "triggered")
        setSelectedPage({ ...offset, all: 0 });
        setOffsetReached({ ...offsetReached, all: false });
        setSearchAllResults({});
        setAuthorResults([]);
        getSearchAllTabResult(0, 0, false, true);
      }
    }
  }, [limit]);

  useEffect(() => {
    if (IsTokenChecker(idToken) && !initiated) {
      // console.log("Testing Data33");
      // setOffset({ all: 0, news: 0, events: 0 });
      // setOffsetReached({ all: false, news: false, events: false });
      if(searchtext !== searchVal){
        console.log("Testing Data3322");
        sessionStorage.setItem("searchtext", searchVal);
        sessionStorage.removeItem("selecteddetailpagesearch");
        sessionStorage.setItem("searchloadmorelimit",JSON.stringify({ all: 0, news: 0, events: 0 }));
        sessionStorage.setItem("searchResultcount",JSON.stringify({ all: 0, news: 0, events: 0 }));
        sessionStorage.setItem("searchloadmorelimitreached",JSON.stringify({ all: false, news: false, events: false }));
        sessionStorage.setItem("searchpageno",JSON.stringify({ all: 0, news: 0, events: 0 }));
        setSelectedPage(...{ all: 0, news: 0, events: 0 });
      }
      else{
        sessionStorage.setItem("searchtext", searchVal);
      }
      sessionStorage.setItem("searchcategory", selectedTab);
      getAuthorDetails();      
      if (selectedTab === "news") {
        Object.keys(newsResults).length === 0 && getSearchNewsTabResult(selectedPage.news, selectedPage.news, true);
      }
      else if (selectedTab === "events") {
        Object.keys(eventResults).length === 0 && getSearchEventTabResult(selectedPage.events, selectedPage.events, true);
      }
      else {
        // getTotalCount("all");
        //console.log("selectedPage.all", selectedPage.all)
        Object.keys(searchAllResults).length === 0 && getSearchAllTabResult(selectedPage.all, selectedPage.all, true);
      }

      setInitiated(true);
    }
  }, [idToken]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const handlerTab = (params) => {
    sessionStorage.setItem("searchcategory", params);

    if (params === "news") {
      Object.keys(newsResults).length === 0 && getSearchNewsTabResult(selectedPage.news, selectedPage.news, true);
    }
    if (params === "events") {
      Object.keys(eventResults).length === 0 && getSearchEventTabResult(selectedPage.events, selectedPage.events, true);
    }
    if (params === "all" || params === "author") {
      Object.keys(searchAllResults).length === 0 && getSearchAllTabResult(selectedPage.events, selectedPage.events, true);
    }
    setSelectedTab(params);
  };

  const applyParentElementForIframeVideo = (o) => {
    return o.map((i) => {
      let el = i['description_' + i18n.language];
      if (el.indexOf("https://players.brightcove.net/") > -1) {
        let element = document.createElement('div');
        element.innerHTML = el;
        let a = element.querySelectorAll("iframe");
        a.forEach((e, i) => {
          if (!e.parentElement.classList.contains('video-iframe')) {
            let b = document.createElement('div');
            b.className = 'video-iframe-container-search';
            b.innerHTML = e.outerHTML;
            element.querySelectorAll("iframe")[i].outerHTML = b.outerHTML;
          }
        });
        i['description_' + i18n.language] = element.innerHTML;
      }
      return i;
    })
  }

  const getAuthorDetails = async () => {
    setLoading(true);
    const url = `authors`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        setAuthorMasterData(
          _.orderBy(resp?.data?.data || [], ["order_by"], ["asc"])
        );
      })
      .catch((error) => {
        setContent({});
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const RegulateFileURL = (val) => {
    try {
      if (IsStringChecker(val)) {
        if (val.includes("http") || val.includes("www.")) {
          return val;
        } else {
          return val;
        }
      } else {
        return val;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return val;
    }
  };
  const isExternalURL = (val) => {
    if (val.includes("http") || val.includes("www.")) {
      return "_blank";
    } else {
      return "_self";
    }
  };
  const compareHeightSearchedResult = (text = "", search) => {
    // text = ContentModifierLogic(RemoveNBSPfromString(text));
    text = ContentModifierLogic(text);
    let indexses = [...text.matchAll(new RegExp(search, "gi"))].map(
      (a) => a.index
    );
    let length = 17;
    let b = text;
    let count = 0;
    indexses.map((a) => {
      b = b.replace(
        b.substring(a + count, a + count + search.length),
        `<strong>${b.substring(a + count, a + count + search.length)}</strong>`
      );
      count += length;
    });
    return b;
  };

  const getSearchAllTabResult = async (offset, pageNo = 0, initial = false, limitChanged = false) => {
    // console.log("searchVal", searchVal);
    if (initial) {
      offset = parseInt(limit) * parseInt(offset);
    }
    if (!Object.keys(searchAllResults).includes(pageNo.toString()) || limitChanged) {
      // offset === 0 && (setSearchAllResults([]), setAuthorResults([]));
      setLoading(true);
      // const url = `rpc/site-search?q=${searchVal}&limit=${limit}&offset=${offset}&order=ts_rank.desc`;
      const url = `rpc/site-search?q=${searchVal}&limit=${limit}&offset=${offset}&order=posting_date.desc`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {

          setSelectedPage({ ...selectedPage, all: pageNo });
          let data = resp?.data?.data || [];
          data = constructObject(data);
          // let oldSearchedData = offset === 0 ? [] : searchAllResults;
          let removeDuplicate = _.uniqBy([...data], "src_id");
          removeDuplicate = applyParentElementForIframeVideo(removeDuplicate);
          setSearchAllResults({ ...searchAllResults, [pageNo]: removeDuplicate });
          setSearchCount({ ...searchCount, all: data[0]?.record_count || 0});
          let authorData = data.filter(
            (i) =>
              i.author_name !== null &&
              i.author_name !== undefined &&
              i.author_name !== ""
          );
          let oldSearchedAuthorData = offset === 0 ? [] : authorResults;
          let removeAuthorDuplicate = _.uniqBy([...oldSearchedAuthorData, ...authorData], "src_id");
          setAuthorResults([...removeAuthorDuplicate]);
          let authorCout = 0,
            authRes = offset === 0 ? [] : oldSearchedAuthorData;
          authorMasterData.map((author) => {
            let authorResult = [...authRes, ...authorData].filter(
              (item) => author.title_en === item.author_name
            );
            authorResult.length > 0 && authorCout++;
          });
          setAuthorDataCountNull(authorCout > 0 || authorData.length > 0 ? false : true);
          if (data.length < limit) {
            sessionStorage.setItem("searchloadmorelimitreached", JSON.stringify({ ...offsetReached, all: true }));
            setOffsetReached({ ...offsetReached, all: true });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
    }
    else {
      setSelectedPage({ ...selectedPage, all: pageNo });
    }

  };

  const getSearchNewsTabResult = async (offset, pageNo = 0, initial = false, limitChanged = false) => {
    if (initial) {
      offset = parseInt(limit) * parseInt(offset);
    }
    if (!Object.keys(newsResults).includes(pageNo.toString()) || limitChanged) {
      setLoading(true);
      const url = `rpc/site-search?q=${searchVal}&subtitle_en_var=news&subtitle_es_var=news&subtitle_fr_var=news&limit=${limit}&offset=${offset}&order=posting_date.desc`;
      // const url = `rpc/site-search?q=${searchVal}&subtitle_en=ilike.*news*&subtitle_es=ilike.*news*&subtitle_fr=ilike.*news*&limit=${limit}&offset=${offset}&order=ts_rank.desc`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          setSelectedPage({ ...selectedPage, news: pageNo });
          let data = resp?.data?.data || [];
          data = constructObject(data);
          let removeDuplicate = _.uniqBy([...data], "src_id");
          removeDuplicate = applyParentElementForIframeVideo(removeDuplicate);
          setNewsResults({ ...newsResults, [pageNo]: removeDuplicate });
          setSearchCount({ ...searchCount, news: data[0]?.record_count || 0});
          if (data.length < limit) {
            sessionStorage.setItem("searchloadmorelimitreached", JSON.stringify({ ...offsetReached, news: true }));
            setOffsetReached({ ...offsetReached, news: true });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
    }
    else {
      setSelectedPage({ ...selectedPage, news: pageNo });
    }
  };

  const getSearchEventTabResult = async (offset, pageNo = 0, initial = false, limitChanged = false) => {
    if (initial) {
      offset = parseInt(limit) * parseInt(offset);
    }
    if (!Object.keys(eventResults).includes(pageNo.toString()) || limitChanged) {
      setLoading(true);
      const url = `rpc/site-search?q=${searchVal}&subtitle_en_var=upcoming-event&subtitle_es_var=upcoming-event&subtitle_fr_var=upcoming-event&limit=${limit}&offset=${offset}&order=posting_date.desc`;
      // const url = `rpc/site-search?q=${searchVal}&subtitle_en=ilike.*upcoming-event*&subtitle_es=ilike.*upcoming-event*&subtitle_fr=ilike.*upcoming-event*&limit=${limit}&offset=${offset}&order=ts_rank.desc`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          setSelectedPage({ ...selectedPage, events: pageNo });
          let data = resp?.data?.data || [];
          data = constructObject(data);
          let removeDuplicate = _.uniqBy([...data], "src_id");
          removeDuplicate = applyParentElementForIframeVideo(removeDuplicate);
          setEventResults({ ...eventResults, [pageNo]: removeDuplicate });
          setSearchCount({ ...searchCount, events: data[0]?.record_count || 0});
          if (data.length < limit) {
            sessionStorage.setItem("searchloadmorelimitreached", JSON.stringify({ ...offsetReached, events: true }));
            setOffsetReached({ ...offsetReached, events: true });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          errorMethod();
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
    else {
      setSelectedPage({ ...selectedPage, events: pageNo });
    }
  };

  const loadmoreAllClick = (offsetVal) => {
    // console.log(offsetVal * limit);
    // return;
    sessionStorage.setItem("searchpageno", JSON.stringify({ ...offset, all: parseInt(offsetVal) }));
    sessionStorage.setItem("searchloadmorelimit", JSON.stringify({ ...offset, all: parseInt(offsetVal * limit) }));
    sessionStorage.setItem("searchlimit", limit);
    // setOffset({ ...offset, all: offsetVal * limit });
    getSearchAllTabResult(offsetVal * limit, offsetVal);
    window.scrollTo(0, 0);
  };
  const loadmoreEventClick = (offsetVal) => {
    sessionStorage.setItem("searchpageno", JSON.stringify({ ...offset, events: parseInt(offsetVal) }));
    sessionStorage.setItem("searchloadmorelimit", JSON.stringify({ ...offset, events: parseInt(offsetVal * limit) }));
    sessionStorage.setItem("searchlimit", limit);
    getSearchEventTabResult(offsetVal * limit, offsetVal);
    window.scrollTo(0, 0);
  };
  const loadmoreNewsClick = (offsetVal) => {
    sessionStorage.setItem("searchpageno", JSON.stringify({ ...offset, news: parseInt(offsetVal) }));
    sessionStorage.setItem("searchloadmorelimit", JSON.stringify({ ...offset, news: parseInt(offsetVal * limit) }));
    sessionStorage.setItem("searchlimit", limit);
    getSearchNewsTabResult(offsetVal * limit, offsetVal);
    window.scrollTo(0, 0);
  };
  const combineData = (item, index, authorData) => {
    return (
      <>
        {authorData.length > 0 && (
          <Accordion.Item eventKey={index}>
            <Accordion.Header className="accordion-header-desktop-font-16 fw-bold mb-md-0">
              {`${item.title_en} (${authorData.length})`}
            </Accordion.Header>
            <Accordion.Body>
              <div className="list-group site-search-hover">
                {authorData.map((item, index) => (
                  <div key={index} className="list-group-item">
                    <div className="row ">
                      <div className="col-9 col-sm-8">
                        <h5 className="mb-0 mt-2 text-12 text-uppercase">
                          <strong>
                            {item[`subtitle_${i18n.language}`] || ""}
                          </strong>
                        </h5>
                      </div>
                      <div className="col-3 col-sm-4">
                        <p className="float-end ">
                          <Moment utc format={dateFormate}>
                            {item?.posting_date}
                          </Moment>
                        </p>
                      </div>
                    </div>
                    {isExternalURL(item.url || "") === "_self" ? (
                      <Link
                        className="text-22 blue_color"
                        target={isExternalURL(item.url || "")}
                        to={RegulateFileURL(item.url || "") || ""}
                        onClick={() => sessionStorage.setItem("selecteddetailpagesearch", JSON.stringify(item))}
                      >
                        {item[`url_label_${i18n.language}`]}
                      </Link>
                    ) : (
                      <a
                        className="text-22 blue_color"
                        target={"_blank"}
                        rel="noreferrer"
                        href={RegulateFileURL(item.url || "#") || ""}
                      >
                        {item[`url_label_${i18n.language}`]}
                      </a>
                    )}
                    <div className="description">
                      <HTMLEllipsis
                        unsafeHTML={getproper_Atag_URLfromRichContent(
                          item[`description_${i18n.language}`] || ""
                        )}
                        maxLine="3"
                        ellipsis="..."
                        basedOn="letters"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </>
    );
  };

  const constructObject = (data) => {
    data.map((i) => {
      i[`subtitle_en`] = i[`subtitle_en`]
        ?.replace(/[{}]/g, "")
        .replace(/,/g, ", ");
      i[`subtitle_fr`] = i[`subtitle_fr`]
        ?.replace(/[{}]/g, "")
        .replace(/,/g, ", ");
      i[`subtitle_es`] = i[`subtitle_es`]
        ?.replace(/[{}]/g, "")
        .replace(/,/g, ", ");
      i[`description_en`] = compareHeightSearchedResult(
        i[`description_en`] || "",
        searchVal
      );
      i[`description_fr`] = compareHeightSearchedResult(
        i[`description_fr`] || "",
        searchVal
      );
      i[`description_es`] = compareHeightSearchedResult(
        i[`description_es`] || "",
        searchVal
      );
    });
    return data;
  };
  const tabs = [{ "name": "all", icon: 'fa fa-search' }, { "name": "news", icon: 'fa fa-newspaper-o' }, { "name": "events", icon: 'fa fa-calendar' }, { "name": "author", icon: 'fa fa-user' }];
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row search-result-page">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        {/* <div className="alert alert-primary" role="alert"> */}
        {/* {`Search results for "${searchVal}" in Current Extranet : ${searchAllResults.length} occurences found.`} */}
        {/* {`${moreLangs[i18n.resolvedLanguage]?.forum_page?.forum_search_page?.search_result_text1} "${searchText}" ${moreLangs[i18n.resolvedLanguage]?.forum_page?.forum_search_page?.search_result_text2} : ${forumSearchResults.length} ${moreLangs[i18n.resolvedLanguage]?.forum_page?.forum_search_page?.search_result_text3}.`} */}
        {/* </div> */}
        <div className="row mt-2 mt-lg-0">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <ul
                  className="nav nav-pills site_search_tabs"
                  id="pills-tab"
                  role="tablist"
                >
                  {tabs.map((i, k) =>
                    <li key={k} className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${selectedTab === i.name ? " active" : ""
                          }`}
                        id={`${i.name}-tab`}
                        data-bs-target={`#${i.name}-tab`}
                        type="button"
                        role="tab"
                        aria-controls={i.name}
                        aria-selected={selectedTab === i.name ? true : false}
                        onClick={() => handlerTab(i.name)}
                      >
                        <i className={i.icon} aria-hidden="true"></i>{" "}
                        {moreLangs[i18n.resolvedLanguage].search_page[i.name]}
                      </button>
                    </li>
                  )}
                </ul>
                {selectedTab !== "author" &&
                  <span className="initial-loadmore-search mt-3">
                    <span>View by: </span>
                    <select
                      className="form-select text-14 initial-loadmore-search-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setLimit(e.target.value);
                        setSearchAllResults({});
                        setNewsResults({});
                        setEventResults({});
                        setAuthorResults([]);
                        sessionStorage.setItem("searchlimit", parseInt(e.target.value || 10));
                        sessionStorage.setItem("searchloadmorelimit", JSON.stringify({ all: 0, news: 0, events: 0 }));
                        sessionStorage.setItem("searchloadmorelimitreached", JSON.stringify({ all: false, news: false, events: false }));
                        sessionStorage.setItem("searchpageno", JSON.stringify({ all: 0, news: 0, events: 0 }));
                        //console.log("selectedPage.all", "searchpageno");
                        setSelectedPage({ all: 0, news: 0, events: 0 });
                      }
                      }
                      value={limit}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                  </span>
                }
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              {selectedTab === "all" &&
                <div
                  className={`mt-3 tab-pane fade ${selectedTab === "all" ? " show active" : ""}`}
                  id="all"
                  role="tabpanel"
                  aria-labelledby="all-search-tab"
                >
                  <div className="list-group site-search-hover">
                    {searchAllResults[selectedPage.all]?.length > 0 &&
                      _.uniqWith([...searchAllResults[selectedPage.all]], _.isEqual).map(
                        (item, index) => (
                          <div key={index} className="list-group-item" ref={alreadySelected?.src_id.toString() === item.src_id.toString() ? searchSelectedRef : null}>
                            <div className="row ">
                              <div className="col-9 col-sm-8">
                                <h5 className="mb-0 mt-2 text-12 text-uppercase">
                                  <strong>
                                    {item[`subtitle_${i18n.language}`] || ""}
                                  </strong>
                                </h5>
                              </div>
                              <div className="col-3 col-sm-4">
                                <p className="float-end ">
                                  <Moment utc format={dateFormate}>
                                    {item?.posting_date}
                                  </Moment>
                                </p>
                              </div>
                            </div>
                            {isExternalURL(item.url || "") === "_self" ? (
                              <Link
                                className="text-22 blue_color"
                                target={isExternalURL(item.url || "")}
                                to={RegulateFileURL(item.url || "") || ""}
                                onClick={() => sessionStorage.setItem("selecteddetailpagesearch", JSON.stringify(item))}
                              >
                                {item[`url_label_${i18n.language}`]}
                              </Link>
                            ) : (
                              <a
                                className="text-22 blue_color"
                                target={"_blank"}
                                rel="noreferrer"
                                href={RegulateFileURL(item.url || "#") || ""}
                              >
                                {item[`url_label_${i18n.language}`]}
                              </a>
                            )}
                            <div className="description">
                              <HTMLEllipsis
                                unsafeHTML={getproper_Atag_URLfromRichContent(
                                  item[`description_${i18n.language}`] || ""
                                )}
                                maxLine="3"
                                ellipsis="..."
                                basedOn="letters"
                              />
                            </div>
                          </div>
                        )
                      )}
                    {
                      searchCount.all > 0 && <div className="search-pagination mt-3"><ReactPaginate
                        previousLabel={window.innerWidth > 768 ? "Previous" : "<"}
                        nextLabel={window.innerWidth > 768 ? "Next" : ">"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(searchCount.all / limit || 1)}
                        //{...forcePageObj}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={(event) => loadmoreAllClick(event.selected)}
                        containerClassName={"pagination float-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        renderOnZeroPageCount={null}
                        forcePage={selectedPage.all}
                      /></div>
                    }

                    {/* {searchAllResults.length > 0 && !offsetReached.all && (
                      <div className="m-4">
                        <button
                          onClick={() => loadmoreAllClick(offset.all)}
                          className="btn btn-primary width-100"
                        >
                          {moreLangs[i18n.resolvedLanguage].home_page.load_more}
                        </button>
                      </div>
                    )} */}
                    {searchCount.all === 0 && (
                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                        <i className="fa fa-info-circle pe-2"></i>
                        {
                          moreLangs[i18n.resolvedLanguage].search_page
                            .no_results_found
                        }
                      </div>
                    )}
                  </div>
                </div>
              }
              {selectedTab === "news" &&
                <div
                  className={`mt-3 tab-pane site-search-hover fade ${selectedTab === "news" ? " show active" : ""
                    }`}
                  id="news"
                  role="tabpanel"
                  aria-labelledby="news-search-tab"
                >
                  {newsResults[selectedPage.news]?.length > 0 &&
                    _.uniqWith([...newsResults[selectedPage.news]], _.isEqual).map(
                      (item, index) => (
                        <div key={index} className="list-group-item" ref={alreadySelected?.src_id.toString() === item.src_id.toString() ? searchSelectedRef : null}>
                          <div className="row ">
                            <div className="col-9 col-sm-8">
                              <h5 className="mb-0 mt-2 text-12 text-uppercase">
                                <strong>
                                  {item[`subtitle_${i18n.language}`] || ""}
                                </strong>
                              </h5>
                            </div>
                            <div className="col-3 col-sm-4">
                              <p className="float-end ">
                                <Moment utc format={dateFormate}>
                                  {item?.posting_date}
                                </Moment>
                              </p>
                            </div>
                          </div>
                          {isExternalURL(item.url || "") === "_self" ? (
                            <Link
                              className="text-22 blue_color"
                              target={isExternalURL(item.url || "")}
                              to={RegulateFileURL(item.url || "") || ""}
                              onClick={() => sessionStorage.setItem("selecteddetailpagesearch", JSON.stringify(item))}
                            >
                              {item[`url_label_${i18n.language}`]}
                            </Link>
                          ) : (
                            <a
                              className="text-22 blue_color"
                              target={"_blank"}
                              rel="noreferrer"
                              href={RegulateFileURL(item.url || "#") || ""}
                            >
                              {item[`url_label_${i18n.language}`]}
                            </a>
                          )}
                          <div className="description">
                            <HTMLEllipsis
                              unsafeHTML={getproper_Atag_URLfromRichContent(
                                item[`description_${i18n.language}`] || ""
                              )}
                              maxLine="3"
                              ellipsis="..."
                              basedOn="letters"
                            />
                          </div>
                        </div>
                      ))}
                  {
                    searchCount.news > 0 && <div className="search-pagination mt-3"><ReactPaginate
                      previousLabel={window.innerWidth > 768 ? "Previous" : "<"}
                      nextLabel={window.innerWidth > 768 ? "Next" : ">"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(searchCount.news / limit || 1)}
                      //{...forcePageObj}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={(event) => loadmoreNewsClick(event.selected)}
                      containerClassName={"pagination float-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={null}
                      forcePage={selectedPage.news}
                    /></div>
                  }
                  {searchCount.news === 0  && (
                    <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                      <i className="fa fa-info-circle pe-2"></i>
                      {
                        moreLangs[i18n.resolvedLanguage].search_page
                          .no_results_found
                      }
                    </div>
                  )}
                </div>
              }
              {selectedTab === "events" &&
                <div
                  className={`mt-3 tab-pane site-search-hover fade ${selectedTab === "events" ? " show active" : ""
                    }`}
                  id="events"
                  role="tabpanel"
                  aria-labelledby="events-search-tab"
                >
                  {eventResults[selectedPage.events]?.length > 0 &&
                    _.uniqWith([...eventResults[selectedPage.events]], _.isEqual).map(
                      (item, index) => (
                        <div key={index} className="list-group-item" ref={alreadySelected?.src_id.toString() === item.src_id.toString() ? searchSelectedRef : null}>
                          <div className="row ">
                            <div className="col-9 col-sm-8">
                              <h5 className="mb-0 mt-2 text-12 text-uppercase">
                                <strong>
                                  {item[`subtitle_${i18n.language}`] || ""}
                                </strong>
                              </h5>
                            </div>
                            <div className="col-3 col-sm-4">
                              <p className="float-end ">
                                <Moment utc format={dateFormate}>
                                  {item?.posting_date}
                                </Moment>
                              </p>
                            </div>
                          </div>
                          {isExternalURL(item.url || "") === "_self" ? (
                            <Link
                              className="text-22 blue_color"
                              target={isExternalURL(item.url || "")}
                              to={RegulateFileURL(item.url || "") || ""}
                              onClick={() => sessionStorage.setItem("selecteddetailpagesearch", JSON.stringify(item))}
                            >
                              {item[`url_label_${i18n.language}`]}
                            </Link>
                          ) : (
                            <a
                              className="text-22 blue_color"
                              target={"_blank"}
                              rel="noreferrer"
                              href={RegulateFileURL(item.url || "#") || ""}
                            >
                              {item[`url_label_${i18n.language}`]}
                            </a>
                          )}
                          <div className="description">
                            <HTMLEllipsis
                              unsafeHTML={getproper_Atag_URLfromRichContent(
                                item[`description_${i18n.language}`] || ""
                              )}
                              maxLine="3"
                              ellipsis="..."
                              basedOn="letters"
                            />
                          </div>
                        </div>
                      ))}
                  {
                    searchCount.events > 0 && <div className="search-pagination mt-3"><ReactPaginate
                      previousLabel={window.innerWidth > 768 ? "Previous" : "<"}
                      nextLabel={window.innerWidth > 768 ? "Next" : ">"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(searchCount.events / limit || 1)}
                      //{...forcePageObj}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={(event) => loadmoreEventClick(event.selected)}
                      containerClassName={"pagination float-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      renderOnZeroPageCount={null}
                      forcePage={selectedPage.events}
                    /></div>
                  }
                  {searchCount.events === 0 && (
                    <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                      <i className="fa fa-info-circle pe-2"></i>
                      {
                        moreLangs[i18n.resolvedLanguage].search_page
                          .no_results_found
                      }
                    </div>
                  )}
                </div>
              }
              {selectedTab === "author" &&
                <div
                  className={`mt-3 tab-pane site-search-hover fade ${selectedTab === "author" ? " show active" : ""
                    }`}
                  id="author"
                  role="tabpanel"
                  aria-labelledby="author-search-tab"
                >
                  <Accordion className="" defaultActiveKey="0" alwaysOpen>
                    {authorMasterData.map((author, index) => {
                      let authorResult = authorResults.filter(
                        (item) => author.title_en === item.author_name
                      );
                      // let count = authorResult.length;
                      return (
                        <Fragment key={index}>
                          {combineData(author, index, authorResult)}
                        </Fragment>
                      );
                    })}
                  </Accordion>
                  {authorDataCountNull && (
                    <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                      <i className="fa fa-info-circle pe-2"></i>
                      {
                        moreLangs[i18n.resolvedLanguage].search_page
                          .no_results_found
                      }
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchDetails;
