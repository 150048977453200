import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import {
  OSContactsDetail,
  empty_descriptions,
  GetDescriptionValue,
} from "../community/regions/contactCard";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import { BreadCrumbForOSContacts } from "../global/breadCrumb";

// import { toDecimal } from "../../../utils/helper";
const OSContractsDetail = (props) => {
  const { showRegion = false, showCommittee = false, ...rest } = props;
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();
  const [ContactDetail, setContactDetail] = useState({});
  const [ContactRightNav, setContactRightNav] = useState([]);
  const { contactID, pageSection } = useParams();
  /*getting url and convert into array*/
  const [getPath] = CurrentLocation();
  const URLpath = getPath.split(`/${contactID}/${pageSection}`)[0];
  /*Translation*/
  const { i18n } = useTranslation();

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getContacts();
      getContactSideNav();
    }
  }, [contactID, tokenId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const getContacts = async () => {
    setContactDetail([]);
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      accounts,
      `contact_cards?select=*,name_en,name_fr,name_es&id=eq.${contactID}`
    )
      .then((res) => {
        setContactDetail(res?.data?.data[0] || []);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const getContactSideNav = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      accounts,
      `contact_cards?select=*,name_en,name_fr,name_es&page_sections=cs.{${pageSection}}&order=order_by,lastname_en`
    )
      .then((res) => {
        setContactRightNav(res?.data?.data || []);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className=" col-12 col-lg-12 d-none d-lg-block">
        <BreadCrumbForOSContacts {...rest} ContactDetail={ContactDetail} />
      </div>
      {ContactDetail.name_en && (
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="row">
              <OSContactsDetail
                {...ContactDetail}
                showRegion={showRegion}
                showCommittee={showCommittee}
                URLpath={URLpath}
              />
            </div>
          </div>
          <div className="col-4 d-none d-lg-block">
            <div className="card bot-mspace-20 Quick_link card-shadow">
              <h4 className="card-header white_color ver-space-15">
                <span className="left-space-5">
                  <i className="fa fa-users pe-2" aria-hidden="true"></i>
                  Additional Members
                </span>
              </h4>
              <div className="card-body">
                <ul className="list-group">
                  {ContactRightNav?.map((item, index) => {
                    const description = GetDescriptionValue(
                      empty_descriptions,
                      _.get(item, `description_${i18n.resolvedLanguage}`, "")
                    );
                    const nextPage = description ? true : false;
                    return item.id != contactID ? (
                      <li key={index} className="list-group-item">
                        {nextPage ? (
                          <Link
                            to={`${URLpath}/${item.id}/${pageSection}/${item[
                              `name_${i18n.resolvedLanguage}`
                            ]
                              .trim()
                              .replace(/ /g, "-")}`}
                            href="#"
                            className="text-18"
                          >
                            {item[`name_${i18n.resolvedLanguage}`].trim()}
                          </Link>
                        ) : (
                          <span className="text-18 span_link_blue">
                            {item[`name_${i18n.resolvedLanguage}`].trim()}
                          </span>
                        )}
                        <p className="min-height-21">
                          {item[`subtitle_${i18n.resolvedLanguage}`].trim()}
                        </p>
                      </li>
                    ) : (
                      <></>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OSContractsDetail;
