import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  IsTokenChecker,
  IsStringChecker,
  IsArrayNotEmpty,
  getResolvedLanguage,
  lowStr,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import CurrentLocation from "../../global/currentLocation";
import { CommentImageUrl } from "../../../utils/constants";
import { ForumContentToModify } from "../../global/contentModifier";
import moment from "moment";

const ForumWidgetDetails = (props) => {
  const { className = null, forum_data = [] } = props;

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "home_page"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return defaultValue;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultValue;
    }
  };

  const heading = LocalTranslator("recent_forum_posts", "Recent Forum Posts");
  const replies_text = LocalTranslator("replies", "Replies");
  const views_text = LocalTranslator("views", "Views");
  const last_post_text = LocalTranslator("last_post", "Last Post");
  const ForumWidgetTimeFormat = "MM/DD/YYYY hh:mm A";
  return IsArrayNotEmpty(forum_data) ? (
    <div
      className={`card bot-mspace-20 Quick_link card-shadow ${
        IsStringChecker(className) ? className : ``
      }`}
    >
      <h4 className="card-header white_color ver-space-15">
        <i className="fa fa-comments text-18"></i>
        <span className="left-space-5">{heading}</span>
      </h4>
      <div className="card-body">
        {forum_data.map((list, index) => {
          const {
            id = "",
            forum_widget_details = {},
            title = "",
            user_fullname = "",
            created_at = "",
            last_comment_name = "",
            last_comment_created_at = "",
            comment_count = "",
            post_views = "",
          } = list;
          const { user_id = "", description = "" } = forum_widget_details;

          const GetLastPostFormat = (name = null, time = null) => {
            try {
              const msgTime = time
                ? moment
                    .utc(last_comment_created_at)
                    .local().format(ForumWidgetTimeFormat)
                : null;
              if (IsStringChecker(name) && IsStringChecker(msgTime)) {
                return `${name} (${msgTime})`;
              } else if (IsStringChecker(name) || IsStringChecker(msgTime)) {
                return IsStringChecker(name) ? name : msgTime;
              } else {
                return "-";
              }
            } catch (e) {
              HandleErrorTrackAndToast(e, true);
              return "-";
            }
          };

          const GetForumLink = (ind = null, content = null) => {
            try {
              if (ind && IsStringChecker(content)) {
                return `${"/community/forums/grower-forum/"}${ind}/${lowStr(
                  content
                ).replace(/ /g, "-").replace(/\//g,'-')}`;
              }
              return null;
            } catch (e) {
              HandleErrorTrackAndToast(e, true);
              return null;
            }
          };

          const forum_type = _.get(
            list,
            `forum_type_title_${resolvedLanguage}`,
            null
          );
          const forumLink = GetForumLink(id, title);
          const lastPost = GetLastPostFormat(
            last_comment_name,
            last_comment_created_at
          );
          const forumCreated = created_at
            ? moment.utc(created_at).local().format(ForumWidgetTimeFormat)
            : null;
          return (
            <Fragment key={index}>
              <div
                className={`forum-widget-cards ${index === 0 ? "" : "mt-3"} ${
                  index + 1 === forum_data.length ? "" : "mb-3"
                }`}
                key={index}
              >
                <div className="row mb-3">
                  <div className="col">
                    <div className="d-flex align-items-center justify-content-lg-start pe-1 pe-lg-4 float-start float-lg-none me-2">
                      <img
                        src={`${CommentImageUrl}${user_id}.jpg`}
                        alt="user_forum"
                        className="card-img-top border-radius-50 forum_widget_profile_img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                        }}
                      />
                      <div className="float-start px-2 px-lg-3 d-block print_font">
                        {IsStringChecker(user_fullname) && (
                          <p className="forum_widget_uname mb-0">
                            <span className="fw-bold">{user_fullname}</span>
                          </p>
                        )}
                        <p className="forum_widget_timetag">
                          {IsStringChecker(forumCreated)
                            ? `${forumCreated}`
                            : null}
                          {IsTokenChecker(forumCreated, forum_type)
                            ? " | "
                            : null}
                          {IsStringChecker(forum_type) && (
                            <span className="fw-bold">{forum_type}</span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {IsStringChecker(title) && <h5 className="fw-bold">{title}</h5>}
                <div className="Forum-Content-HTML-Ellipsis">
                  <ForumContentToModify
                    str={description}
                    forumLink={forumLink}
                  />
                </div>
                <div className="Forum-Bottom-Section">
                  <p className="forum-widget-views-replies">
                    <span className="fw-bold">{comment_count || 0}</span>{" "}
                    {` ${replies_text};`}
                  </p>
                  <p className="forum-widget-views-replies">
                    <span className="fw-bold">{post_views || 0}</span>
                    {` ${views_text};`}
                  </p>
                  <p className="forum-widget-last-post">
                    {`${last_post_text}: `}
                    <span className="fw-bold">{lastPost}</span>
                  </p>
                </div>
              </div>
              {index + 1 === forum_data.length ? (
                <></>
              ) : (
                <hr className="Forum-Widget-Segment" />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ForumWidgetDetails;
