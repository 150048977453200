import React from "react";
import Body from "./body";
import PageLayout from "../../pageLayout";
import Main from "./main";

const NewsDetails = (props) => {
  return (
    <PageLayout {...props} body={<Body {...props} contentbody={<Main {...props} />} />} fullWidth={false} />
  );
};

export default NewsDetails;
