import React, { Fragment, useEffect, useState } from "react";
import BreadCrumb from "../../global/breadCrumb";
import Loading from "../../global/loading";
import { useHistory } from "react-router-dom";
import {
  gbsDataCommonMethod,
  cmsDataCommonMethod,
} from "../../../services/gbsData";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../global/currentLocation";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsObjectChecker,
  IsTokenChecker,
  IsStringChecker,
  IsNumberChecker,
  checkValidEmailRegex,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../../utils/helper";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import MultiDropDown from "../../selectBox/multiDropDown";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import { FilterContractByRole } from "../../../permissionWrapper";

const OSAcademySignupDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);

  const dispatch = useDispatch();
  const { CommonPageCBRoles = [] } = props;

  const { accounts } = useMsal();
  const personId = _.get(accounts, `0.idTokenClaims.gbsPersonId`, null);
  const user_id = _.get(accounts, `0.localAccountId`, null);
  const tokenId = useSelector((state) => state.reducerToken);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const history = useHistory();

  const [defaultContract, setDefaultContract] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [contract, setContract] = useState({});

  const [courses, setCourses] = useState([]);
  const [signedUp, setSignedUp] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const initialPostData = {
    firstname: "",
    lastname: "",
    shipping_address: "",
    mobile_phone: "",
    email: "",
    position_farm: "",
    leadership_programs: "",
    goals: "",
    other_course: false,
    other_course_interest: "",
    course_interest_names: "",
    // contract_numbers: "",
  };
  const initialErrorData = {
    firstname_err: null,
    lastname_err: null,
    shipping_address_err: null,
    // mobile_phone_err: null,
    email_err: null,
    position_farm_err: null,
    leadership_programs_err: null,
    goals_err: null,
    course_interest_names_err: null,
    // contract_numbers_err: null,
  };
  const [postData, setPostData] = useState(initialPostData);
  const [errorData, setErrorData] = useState(initialErrorData);

  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const IsBooleanChecker = (bool = null) => {
    try {
      return typeof bool === "boolean";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const toBool = (val) => {
    try {
      return String(val).toLowerCase() === "true";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return val;
    }
  };

  const SafeFieldChecker = (value = null) => {
    try {
      if (IsBooleanChecker(value)) {
        return value;
      } else if (IsStringChecker(value)) {
        return value;
      } else if (IsNumberChecker(value)) {
        return value;
      } else {
        return ``;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  // const mobileNumChecker = (str = null) => {
  //   try {
  //     if (str) {
  //       return !isNaN(str);
  //     } else {
  //       return false;
  //     }
  //   } catch (e) {
  //     HandleErrorTrackAndToast(e, true);
  //     return false;
  //   }
  // };

  function ArgumentsToStringArr() {
    const args = [...arguments];
    try {
      if (IsArrayNotEmpty(args)) {
        return args.filter((li) => SafeFieldChecker(li));
      } else {
        return [];
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const StringBuilder = (arr = [], separator = ",") => {
    try {
      if (IsArrayNotEmpty(arr)) {
        return arr.join(separator);
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  const MultiDropDownFix = () => {
    setClearStatus(true);
    setTimeout(() => {
      setClearStatus(false);
    }, 0);
  };

  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "os_academy"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const getAcademyApplications = async (data = {}) => {
    const {
      Email = null,
      FirstName = null,
      LastName = null,
      MailingAddress_Street1 = null,
      MailingAddress_Street2 = null,
      MailingAddress_Street3 = null,
      MailingAddress_City = null,
      MailingAddress_PostalCode = null,
      // MailingAddress_State = null,
      MobilePhone = null,
    } = data;

    // const url = `academy_applications?select=*&user_id=eq.${user_id}&email_address=eq.${Email}`;
    const url = `academy_applications?select=*&user_id=eq.${user_id}`;
    // const url = `academy_applications?select=*&email_address=eq.${Email}`;
    await cmsDataCommonMethod(tokenId, accounts, url)
      .then((res) => {
        const applicationData = safeArrayCheck(_.get(res, "data.data", []));
        if (IsArrayNotEmpty(applicationData)) {
          setPostData(initialPostData);
          setSignedUp(true);
          const is_approved = applicationData.some((li) => li.is_approved);
          setIsApproved(is_approved);
        } else {
          const shipping_address = StringBuilder(
            ArgumentsToStringArr(
              MailingAddress_Street1,
              MailingAddress_Street2,
              MailingAddress_Street3,
              MailingAddress_City
            ),
            ", "
          );

          let resultData = {
            ...initialPostData,
            firstname: SafeFieldChecker(FirstName),
            lastname: SafeFieldChecker(LastName),
            mobile_phone: SafeFieldChecker(MobileNumberFormatter(MobilePhone)),
            email: SafeFieldChecker(Email),
            shipping_address: StringBuilder(
              ArgumentsToStringArr(shipping_address, MailingAddress_PostalCode),
              " - "
            ),
          };

          setPostData(resultData);
          setSignedUp(false);
          setIsApproved(false);
          FetchContract();
          FetchCourses();
        }
        setErrorData(initialErrorData);
      })
      .catch((error) => {
        SetDefaultData();
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const FetchPreData = async () => {
    if (IsNumberChecker(personId)) {
      setLoading(true);
      await gbsDataCommonMethod(tokenId, accounts, `persons/${personId}`)
        .then((resp) => {
          const data = _.get(resp, `data.0`, {});
          if (IsObjectChecker(data)) {
            getAcademyApplications(data);
          } else {
            SetDefaultData();
          }
        })
        .catch((error) => {
          SetDefaultData();
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };

  const FetchContract = async () => {
    if (IsNumberChecker(personId)) {
      await gbsDataCommonMethod(
        tokenId,
        accounts,
        `persons/${personId}/contracts`
      )
        .then((resp) => {
          const data = _.get(resp, `data`, []);
          const filteredData = FilterContractByRole(
            data,
            accounts,
            CommonPageCBRoles
          );
          const options_arr = IsArrayNotEmpty(filteredData)
            ? filteredData
                .map((list) => {
                  const { RelationshipNumber = null, Name = null } = list;
                  return {
                    name: StringBuilder(
                      ArgumentsToStringArr(RelationshipNumber, Name),
                      " "
                    ),
                    id: RelationshipNumber,
                  };
                })
                .filter((li) => li.id)
            : [];
          const options = safeArrayCheck(options_arr);
          setContracts(options);
          if (options.length === 1) {
            const tempDefaultContract = _.get(options, `0.id`, null);
            setDefaultContract(
              tempDefaultContract ? [`${tempDefaultContract}`] : []
            );
            if (tempDefaultContract) {
              setContract({ contract_numbers: `${tempDefaultContract}` });
            }
          } else {
            setDefaultContract([]);
            setContract({ contract_numbers: `` });
          }
        })
        .catch((error) => {
          setContracts([]);
          setDefaultContract([]);
          setContract({ contract_numbers: `` });
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoading(false);
          MultiDropDownFix();
        });
    }
  };

  const FetchCourses = async () => {
    setLoading(true);
    // const url = `academy_courses?select=*,academy_course_types(id,title_en,title_fr,title_es,slug,order_by),academy_schedules(id,schedule_date_from,schedule_date_to)&order=order_by.asc`;
    const url = `academy_courses?select=*&is_active=eq.true&order=order_by.asc`;
    await cmsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        let slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));
        let courseData = slugRefinedData.map((list) => {
          const { title_en = null } = list;
          return { name: title_en, id: title_en };
        });
        setCourses(safeArrayCheck(courseData));
      })
      .catch((error) => {
        setCourses([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const SetDefaultData = () => {
    setPostData(initialPostData);
    setErrorData(initialErrorData);
    setSignedUp(false);
    setIsApproved(false);
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      FetchPreData();
    }
  }, [tokenId, personId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const onMultiSelectChange = (selectedItem, type) => {
    const added = _.get(selectedItem, "id", null);
    setErrorData((prev) => {
      return {
        ...prev,
        [`${type}_err`]: ErrorHandlingLogic(type, added),
      };
    });
  };

  const onMultiSelectRemove = (removedList, removedItem, type) => {
    const removed = _.get(removedItem, "id", null);
    if (removed === "all" || !IsArrayNotEmpty(removedList)) {
      UpdatePostData(type, "");
    }
  };

  const onCheckBoxClick = (e) => {
    const { target: { name = null, value = null } = {} } = e;
    const booleanValue = SafeFieldChecker(toBool(value));
    UpdatePostData(name, !booleanValue);
  };

  const MobileNumberFormatter = (number = "") => {
    try {
      if (IsStringChecker(number)) {
        const num = number.trim().replace(/[^0-9]/g, "");
        if (num.length <= 3) {
          return num;
        } else if (num.length > 3 && num.length <= 6) {
          return num.replace(/(\d{3})(\d{1})/, "$1-$2");
        } else {
          return num.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
        }
      }
      return "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };
  const onValueChange = (e) => {
    const { target: { name = null, value = null } = {} } = e;
    if (name === "mobile_phone") {
      let val = MobileNumberFormatter(value);
      UpdatePostData(name, val);
    } else UpdatePostData(name, value);
  };

  const ErrorHandlingLogic = (name, value_exist = null) => {
    try {
      switch (name) {
        case "firstname":
          return value_exist
            ? value_exist.length <= 500
              ? null
              : "First Name should not exceed 500 characters"
            : "First Name is required!";
        case "lastname":
          return value_exist
            ? value_exist.length <= 500
              ? null
              : "Last Name should not exceed 500 characters"
            : "Last Name is required!";
        // case "mobile_phone":
        //   return value_exist
        //     ? mobileNumChecker(value_exist)
        //       ? value_exist.length === 10
        //         ? null
        //         : "Mobile Phone Number should be 10 digits!"
        //       : "Please enter a valid Mobile Phone Number"
        //     : "Mobile Phone Number is required!";
        case "email":
          return value_exist
            ? checkValidEmailRegex.test(value_exist)
              ? value_exist.length <= 500
                ? null
                : "Email should not exceed 500 characters"
              : "Please enter a valid Email"
            : "Email is required!";
        case "shipping_address":
          return value_exist ? null : "Shipping Address is required!";
        case "position_farm":
          return value_exist
            ? value_exist.length <= 500
              ? null
              : "Position/Farm Role should not exceed 500 characters"
            : "Position/Farm Role is required!";
        case "leadership_programs":
          return value_exist ? null : "This Field is required!";
        case "goals":
          return value_exist ? null : "This Field is required!";
        // case "contract_numbers":
        //   return value_exist ? null : "Contract Number & Name is required!";
        case "course_interest_names":
          return value_exist ? null : "Elective Classes is required!";
        default:
          return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const UpdatePostData = (name = null, value = "") => {
    if (IsStringChecker(name)) {
      const value_exist = SafeFieldChecker(value);
      const error_name = `${name}_err`;
      setPostData((prev) => {
        return {
          ...prev,
          [name]: value_exist,
        };
      });
      setErrorData((prev) => {
        return {
          ...prev,
          [error_name]: ErrorHandlingLogic(name, value_exist),
        };
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (IsTokenChecker(user_id)) {
      // console.log({ postData, contract });
      let errorAssign = Object.keys(postData).reduce((accumulator, list) => {
        let value_exist = SafeFieldChecker(_.get(postData, list, null));
        let name = `${list}_err`;
        return {
          ...accumulator,
          [name]: ErrorHandlingLogic(list, value_exist),
        };
      }, {});
      const errData = Object.values(errorAssign);
      const error = errData.some((li) => li);
      // console.log("errorAssign => ", { error }, errorAssign);
      if (error) {
        setErrorData(errorAssign);
        setShowModal(false);
      } else {
        let reqContracts = contract?.contract_numbers
          ? { ...contract }
          : { contract_numbers: "" };
        let requestData = { ...postData, ...reqContracts };
        delete requestData.other_course;
        requestData.user_id = user_id;

        // console.log("Request Data", requestData);
        const url = "academy-applications";
        setLoading(true);
        await cmsDataCommonMethod(tokenId, accounts, url, "POST", requestData)
          .then(() => {
            setSuccess(true);
            setShowModal(true);
          })
          .catch((err) => {
            setSuccess(false);
            setShowModal(false);
            HandleErrorTrackAndToast(err, true, "dispatch", dispatch);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      HandleErrorTrackAndToast(`Error in User ID: ${user_id}`, true);
      setShowModal(false);
    }
  };

  const onModalHide = () => {
    setShowModal(false);
    onBack();
  };

  const onBack = () => {
    history.push("/os-academy");
  };

  const onCancelClick = () => {
    history.push("/os-academy");
  };

  const {
    firstname = null,
    lastname = null,
    shipping_address = null,
    mobile_phone = null,
    email = null,
    position_farm = null,
    leadership_programs = null,
    goals = null,
    other_course = false,
    other_course_interest = null,
    contract_numbers = null,
  } = postData;
  const {
    firstname_err = null,
    lastname_err = null,
    shipping_address_err = null,
    // mobile_phone_err = null,
    email_err = null,
    position_farm_err = null,
    leadership_programs_err = null,
    goals_err = null,
    course_interest_names_err = null,
    // contract_numbers_err = null,
  } = errorData;

  // console.log("postData => ", postData);
  // console.log("errorData => ", errorData);

  const ErrorMsg = ({ msg = null }) => {
    return IsStringChecker(msg) ? (
      <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
        {msg}
      </span>
    ) : (
      <Fragment />
    );
  };

  const title = LocalTranslator("sign_up_title", "OS Academy Application");
  const leadership_field = LocalTranslator(
    "leadership_field",
    "What other leadership development programs have you previously participated in, if any? Please list the name of the program and year of completion. (Ex: WSCGALDP, New Jersey Ag Leadership Development Program, Cornell LEADS Program, Next Wave, etc.) Completion of a previous LDP will count towards 3 of your elective requirements."
  );
  const goals_field = LocalTranslator(
    "goals_field",
    "What goals you are looking to achieve upon completion of this program?"
  );
  const class_field = LocalTranslator(
    "class_field",
    "What elective classes are you most interested in taking? Please select all classes you would be interested in attending. This will help us with scheduling."
  );
  const other_field = LocalTranslator("other_field", "Other, Please Specify");
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  const signedup_approved = LocalTranslator(
    "signedup_approved",
    "You have already submitted your application."
  );
  const signup_done_approval_pending = LocalTranslator(
    "signup_done_approval_pending",
    "You have already submitted your application for enrollment and is pending with approval. Please, come back again."
  );
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          {signedUp ? (
            <div className="card-body mob-space-7 ver-space-10 align-flex border-1 border-radius-6 white_bg mb-3">
              <span className="d-contents ver-space-10">
                <i className="fa fa-info-circle margin-right-5 d-none d-lg-inline-block"></i>
                <i className="fa fa-info margin-right-5 d-lg-inline-block d-lg-none info_icon_mobile"></i>
                <span className="mob_blue_text">
                  {isApproved
                    ? signedup_approved
                    : signup_done_approval_pending}
                </span>
              </span>
            </div>
          ) : (
            <div className="personal_info os_academy_app">
              <form onSubmit={onSubmit}>
                <h4 className="fw-bold pt-2 pb-4">{title}</h4>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="position-relative mb-2 mb-lg-0">
                      <span className="text-14 red_star">First Name</span>
                      <input
                        type="text"
                        aria-label="First Name"
                        className="form-control mb-2"
                        name={"firstname"}
                        value={firstname}
                        // onChange={onValueChange}
                        disabled={true}
                      />
                    </div>
                    <ErrorMsg msg={firstname_err} />
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="position-relative mb-2 mb-lg-0">
                      <span className="text-14 red_star">Last Name</span>
                      <input
                        type="text"
                        aria-label="Last Name"
                        className="form-control mb-2"
                        name={"lastname"}
                        value={lastname}
                        // onChange={onValueChange}
                        disabled={true}
                      />
                    </div>
                    <ErrorMsg msg={lastname_err} />
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="position-relative mb-2 mb-lg-0">
                      <span className="text-14 ps-2">MOBILE PHONE NUMBER</span>
                      <input
                        type="tel"
                        aria-label="MOBILE PHONE NUMBER"
                        className="form-control mb-2"
                        name={"mobile_phone"}
                        value={mobile_phone}
                        onChange={onValueChange}
                        // disabled={true}
                        maxLength={12}
                      />
                    </div>
                    {/* <ErrorMsg msg={mobile_phone_err} /> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="position-relative mb-2 mb-lg-0">
                      <span className="text-14 red_star">E-MAIL ADDRESS</span>
                      <input
                        type="text"
                        aria-label="E-MAIL ADDRESS"
                        className="form-control mb-2"
                        name={"email"}
                        value={email}
                        // onChange={onValueChange}
                        disabled={true}
                      />
                    </div>
                    <ErrorMsg msg={email_err} />
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="position-relative mb-2 mb-lg-0">
                      <span className="text-14 red_star">
                        Position/Farm role
                      </span>
                      <input
                        type="text"
                        aria-label="Position or Farm role"
                        className="form-control mb-2"
                        name={"position_farm"}
                        value={position_farm}
                        onChange={onValueChange}
                      />
                    </div>
                    <ErrorMsg msg={position_farm_err} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="position-relative mb-2 mb-lg-0 shipping_address">
                      <span className="text-14 red_star">SHIPPING ADDRESS</span>
                      <textarea
                        aria-label="SHIPPING ADDRESS"
                        className="form-control mb-2 pt-4 fw-bold height_55px"
                        name={"shipping_address"}
                        value={shipping_address}
                        onChange={onValueChange}
                        // disabled={true}
                      />
                    </div>
                    <ErrorMsg msg={shipping_address_err} />
                  </div>
                  <div className="col-12 col-lg-6">
                    {contracts.length > 0 && (
                      <div className="no-red-star os_contract_number_Label custom_dropdown white_bg mb-2">
                        {!clearstatus && (
                          <MultiDropDown
                            optionList={contracts}
                            prompt="Select"
                            value={contract}
                            // onChange={(selectedList, selectedItem) =>
                            //   onMultiSelectChange(
                            //     selectedItem,
                            //     "contract_numbers"
                            //   )
                            // }
                            // onDelete={(removedList, removedItem) =>
                            //   onMultiSelectRemove(
                            //     removedList,
                            //     removedItem,
                            //     "contract_numbers"
                            //   )
                            // }
                            label="contract_numbers"
                            usestate={setContract}
                            disabled=""
                            preSelectedOpt={defaultContract}
                            optionNotFound={{ contract: noContractsFound }}
                          />
                        )}
                      </div>
                    )}
                    {contracts.length === 0 && (
                      <div className="mb-2 mb-lg-0">
                        <span className="ps-2 text-14">contract #</span>
                        <input
                          type="number"
                          aria-label="contract number"
                          className="form-control mb-2 number-field"
                          name={"contract_numbers"}
                          value={contract_numbers}
                          onChange={onValueChange}
                        />
                      </div>
                    )}
                    {/* <ErrorMsg msg={contract_numbers_err} /> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="fw-bold position-relative ps-3 red_star mt-3 mt-lg-0">
                      {leadership_field}
                    </p>
                    <textarea
                      aria-label="Program name"
                      className="form-control mb-2 pt-4 height_55px"
                      value={leadership_programs}
                      name={"leadership_programs"}
                      onChange={onValueChange}
                    />
                    <ErrorMsg msg={leadership_programs_err} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="fw-bold position-relative ps-3 red_star">
                      {goals_field}
                    </p>
                    <textarea
                      aria-label="Goals"
                      className="form-control mb-2 pt-4 height_55px"
                      value={goals}
                      name={"goals"}
                      onChange={onValueChange}
                    />
                    <ErrorMsg msg={goals_err} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="fw-bold">{class_field}</p>
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <div className="elective_classes_label custom_dropdown white_bg mb-2">
                          {!clearstatus && (
                            <MultiDropDown
                              optionList={courses}
                              prompt="Select"
                              value={postData}
                              onChange={(selectedList, selectedItem) =>
                                onMultiSelectChange(
                                  selectedItem,
                                  "course_interest_names"
                                )
                              }
                              onDelete={(removedList, removedItem) =>
                                onMultiSelectRemove(
                                  removedList,
                                  removedItem,
                                  "course_interest_names"
                                )
                              }
                              label="course_interest_names"
                              usestate={setPostData}
                              disabled=""
                              preSelectedOpt={[]}
                            />
                          )}
                        </div>
                        <ErrorMsg msg={course_interest_names_err} />
                        <fieldset className="input_block mb-2 d-flex mt-3 mt-lg-0">
                          <input
                            id="other_option"
                            className="position-relative t_1 me-1 cursor_pointer top_checkbox"
                            type="checkbox"
                            name={"other_course"}
                            value={other_course}
                            checked={other_course}
                            onChange={onCheckBoxClick}
                          />
                          <label
                            htmlFor="other_option"
                            className="cursor_pointer"
                          >
                            {other_field}
                          </label>
                        </fieldset>
                        <fieldset className="output_block">
                          {other_course && (
                            <textarea
                              aria-label="Other Course Interest"
                              className="form-control mb-2 pt-4"
                              name={"other_course_interest"}
                              value={other_course_interest}
                              onChange={onValueChange}
                            />
                          )}
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="horizontal-line my-4"></div>
                {!showModal && (
                  <div className="overflow-hidden mb-4">
                    <button
                      className="btn btn-default text-16 float-end me-3 white_bg card-shadow"
                      onClick={onCancelClick}
                    >
                      {LocalTranslator("cancel_text", "Cancel", "button_text")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary min-width-95 float-end mob-t-2  me-3"
                    >
                      {LocalTranslator("submit", "Submit", "home_page")}
                    </button>
                  </div>
                )}
              </form>
            </div>
          )}
        </div>
        <Modal
          show={showModal}
          onHide={onModalHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fw-bold mb-0" id="contained-modal-title-vcenter">
              {success
                ? LocalTranslator("enroll_modal_success_title", "Success")
                : LocalTranslator(
                    "enroll_modal_error_title",
                    "Sorry! Unable to submit the application."
                  )}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <p>
                {success
                  ? LocalTranslator(
                      "enroll_modal_success_body",
                      `Your application has been submitted successfully. Thank you!`
                    )
                  : LocalTranslator(
                      "enroll_modal_error_body",
                      `There seems to be some problem with application submission now. Please, try again later.`
                    )}
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onModalHide}>
              {LocalTranslator("closeBtn", "Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default OSAcademySignupDetail;
