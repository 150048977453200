/**
 * @component_Name : PaymentEstimator
 * @description : Payment Estimator
 * @company : Photon
 * @author : Nivethithan Kannan/02-01-2024
 **/
import React from "react";
import PageLayout from "../../../pageLayout";
import PaymentEstimatorDetail from "./paymentEstimatorDetail";

const PaymentEstimator = (props) => {
  return <PageLayout {...props} body={<PaymentEstimatorDetail {...props} />} />;
};

export default PaymentEstimator;
