/**
 * @component_Name : DataGridPagination
 * @description : library for grid with pagination
 * @author : Ashish Ranjan/08-26-2022
 *
 */
import React, { useState, useEffect } from "react";
import DataGrid from "react-data-grid";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { IsNumberChecker, HandleErrorTrackAndToast } from "../../utils/helper";
import { groupBy as rowGrouper } from "lodash";

const DataGridGroupingPagination = ({
  uniqueIdentifier,
  gridData,
  columns,
  isPagination,
  itemsPerPage,
  columnResize,
  classNames,
  summaryRows,
  defaultSort = "",
  groupByKey = "",
  getExpandedGroupIds = "",
  setExpandedGroupIds = "",
  refReactDataGrid,
  dynamicHeaderHeight = 50,
  autoRowHeight = false,
  paddingRowHeight = 0,
  dynamicHeight = 0,
  totalRecordCount = null,
  getNextpage=null,
  minimumRowHeight = 35
}) => {
  const [sortColumns, setSortColumns] = useState([]);
  const tokenId = useSelector((state) => state.reducerToken);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [dataLen, setDataLen] = useState(0);
  const [items, setItems] = useState(null);
  let endOffset = 0;

  //row key generator
  const rowKeyGetter = (row) => {
    return row[uniqueIdentifier];
  };

  /*bydefault sort on first load*/
  useEffect(() => {
    if (defaultSort != "") {
      const y = [
        { ["columnKey"]: defaultSort[0], ["direction"]: defaultSort[1] },
      ];
      setSortColumns(...sortColumns, y);
    }
  }, []);
  /*End sort on first load*/

  useEffect(() => {
    if (gridData.length > 0) {
      if (isPagination === true) {
        const sortedData = sorting(gridData, sortColumns);
        let groupByData = _.groupBy(sortedData, (item) => {
          return [item["GroupingKey"]];
        });

        let convertToarray = [];
        Object.keys(groupByData).map((x) => {
          convertToarray.push(groupByData[x]);
        });
        setDataLen(convertToarray.length);
        setItems([...convertToarray]);
        /* Fetch items from another resources.*/
        endOffset = itemOffset + itemsPerPage;
        const fetchData = convertToarray.slice(itemOffset, endOffset);
        let extractArray = [];
        fetchData.map((x) => {
          x.map((y) => {
            extractArray.push(y);
          });
        });
        setCurrentItems(extractArray);
        if(totalRecordCount === null){
          setPageCount(Math.ceil(convertToarray.length / itemsPerPage));
        }         
        else{
          setPageCount(Math.ceil(totalRecordCount / itemsPerPage));
        }
      } /* else if(isPagination===false){     
         const newgridData1 = sorting(gridData, sortColumns);
         setCurrentItems([...newgridData1]);
       } */
    } else {
      setCurrentItems([]);
    }
  }, [tokenId, itemOffset, itemsPerPage, gridData, sortColumns, endOffset]);

  // Pagination page change event
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    getNextpage && totalRecordCount !== null && getNextpage(event);
  };

  // Sort by column field
  const sortByField = (field, reverse) => {
    const key = (x) => {
      return x[field];
    };
    reverse = !reverse ? 1 : -1;
    return (first, second) => {
      return (
        (first = key(first)),
        (second = key(second)),
        reverse * ((first > second) - (second > first))
      );
    };
  };
  // Sorting values
  const sorting = (arrayData, sortParam = []) => {
    if (sortParam.length === 0) {
      return arrayData;
    }
    const { columnKey, direction } = sortParam[0];
    const newOrder = direction === "ASC" ? "ASC" : "DESC";
    if (newOrder === "ASC") {
      arrayData = arrayData.sort(sortByField(columnKey, false));
    } else {
      arrayData = arrayData.sort(sortByField(columnKey, true));
    }
    return arrayData;
  };
  
  const minimumHeight = minimumRowHeight;
  const MinimumHeightProvider = (height = 0) => {
    return IsNumberChecker(height) && height > minimumHeight
      ? height
      : minimumHeight;
  };
  const rowHeaderHeight = MinimumHeightProvider(dynamicHeaderHeight);
  const rowHeight = MinimumHeightProvider(dynamicHeight);

  const defaultRowHeightSetter = (
    autoRowHeight = false,
    staticHeight = 0,
    paddingRowHeight = 0,
    newHeight = 0
  ) => {
    try {
      const height = MinimumHeightProvider(newHeight);
      if (autoRowHeight) {
        return IsNumberChecker(paddingRowHeight)
          ? height + paddingRowHeight
          : height;
      } else {
        return MinimumHeightProvider(staticHeight);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return minimumHeight;
    }
  };
  const rowHeightSetter = (row) => {
    if (autoRowHeight) {
      let isChild = false, rows;
      if (row.row.hasOwnProperty("childRows")) {
        rows = row.row.childRows[0];
        isChild = false;
      }
      else {
        rows = row.row;
        isChild = true;
      }
      var height = Object.keys(rows).map((columnName) => {
        let columnProperty = columns.filter((obj) => {
          if (isChild) {
            if (obj.hasOwnProperty('childkey')) {
              if (obj.key === columnName) {
                columnName = obj.childkey;
                return obj;
              }
            }
            else if (obj.key === columnName)
              return obj
          }
          else if (obj.key === columnName)
            return obj
        });
        let columnWidth = columnProperty[0]?.width || "";
        let columnValue = rows[columnName] || "";
        const regex = /(<([^>]+)>)/ig;
        columnValue = columnValue.toString().replace(regex, '');
        if (columnValue != '' && columnProperty[0]?.hasOwnProperty('dateFormate')) {
          columnValue = columnValue.substr(0, columnProperty[0].dateFormate.length);
        }
        if (columnWidth.toString().search("%") === -1 && columnValue !== '' && columnWidth !== '' && columnWidth < columnValue.toString().length * 10) {
          let value = ((columnValue.toString().length * 10) / (columnWidth));
          return Math.ceil(value) * 30 > 35 ? Math.ceil(value) * 30 : 35
        }
        else {
          return 35;
        }
      });

      height = height.sort(function (a, b) { return b - a })[0];
      return defaultRowHeightSetter(true, 0, paddingRowHeight, height);
    } else {
      return defaultRowHeightSetter(false, rowHeight);
    }
  };
  return (
    <>
      {/* Data grid section start */}
      {currentItems.length > 0 && (
        <DataGrid
          rowKeyGetter={rowKeyGetter}
          columns={columns}
          rows={currentItems}
          defaultColumnOptions={{
            sortable: true,
            resizable: columnResize,
          }}
          // selectedRows={selectedRows}
          // onSelectedRowsChange={setSelectedRows}
          // onRowsChange={setRows}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          summaryRows={summaryRows}
          className={classNames}
          direction="ltr"
          /*group by functionality*/
          groupBy={groupByKey != "" ? groupByKey : ""}
          rowGrouper={groupByKey != "" ? rowGrouper : ""}
          expandedGroupIds={
            getExpandedGroupIds != "" ? getExpandedGroupIds : ""
          }
          onExpandedGroupIdsChange={
            setExpandedGroupIds != "" ? setExpandedGroupIds : ""
          }
          ref={refReactDataGrid}
          headerRowHeight={rowHeaderHeight}
          rowHeight={rowHeightSetter}
        />
      )}
      {/* Data grid section end */}

      {/* Pagination section start */}
      {(dataLen > itemsPerPage && isPagination == true) || totalRecordCount !== null ? (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          //{...forcePageObj}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination float-end"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          renderOnZeroPageCount={null}
        />
      ) : (
        ""
      )}
      {/* Pagination section end */}
    </>
  );
};

export default DataGridGroupingPagination;