import React, { useState, useEffect } from "react";
import LeftMenu from "../../../global/leftMenu";
import QuickLinks from "../../quickLinks";
import BreadCrumb from "../../../global/breadCrumb";
import _ from "lodash";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import Loading from "../../../global/loading";
import Payment from "./payment";
import Equity from "./equity";
import PoolEarnings from "./poolEarnings";
import Tax from "./tax";
import {
  getCurrentYear,
  IsTokenChecker,
  IsArrayNotEmpty,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../../../utils/helper";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../../permissionWrapper";

const Body = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const changeTab = (index) => {
    setSelectedTab(index);
  };

  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, `0.idTokenClaims.gbsPersonId`, null);
  const tokenId = useSelector((state) => state.reducerToken);

  const { DropDownCBRoles = [] } = props;

  const [loading, setLoading] = useState(false);

  const [cropYears, setCropYears] = useState([]);
  const [contracts, setContracts] = useState([]);

  const [selectedCropYear, setSelectedCropYear] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);

  const onlyUnique = (value, index, self) => {
    try {
      return self.indexOf(value) === index;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const setOptions = (data, keyPrram, unique, concatValue1, concatValue2) => {
    try {
      if (unique && keyPrram !== "RelationshipId") {
        return data
          .map((li) => li[keyPrram])
          .filter(onlyUnique)
          .map((li) => {
            return { name: li, id: li };
          });
      } else {
        const contracts = data.map((li) => {
          return concatValue1 && concatValue2
            ? {
                name: li[concatValue1] + " " + li[concatValue2],
                id: li[keyPrram],
              }
            : { name: li[keyPrram], id: li[keyPrram] };
        });
        return [
          ...new Map(contracts.map((item) => [item["id"], item])).values(),
        ];
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  const fetchCropYear = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then((resp) => {
        const data = safeArrayCheck(_.get(resp, `data`, []));
        if (IsArrayNotEmpty(data)) {
          const currentCrop = data.find((x) => x.CurrentCrop === true);
          const getcurrentcrop = _.get(currentCrop, `CropYear`, null);
          const current_crop_year = getcurrentcrop || getCurrentYear();

          const cropYears = data.splice(0, 15);
          const options = setOptions(cropYears, "CropYear", true);

          setCropYears(options);
          setSelectedCropYear(current_crop_year);
        } else {
          setCropYears([]);
          setSelectedCropYear(null);
        }
      })
      .catch((error) => {
        setCropYears([]);
        setSelectedCropYear(null);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const fetchContract = async () => {
    setLoading(true);
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/${personId}/contracts`
    )
      .then((resp) => {
        const data = safeArrayCheck(_.get(resp, `data`, []));
        let filteredData = FilterContractByRole(data, accounts, DropDownCBRoles);
        filteredData = _.orderBy(filteredData,
          ["ContractStatus", "RelationshipNumber"],
          ["asc", "asc"]
        );
        const options = IsArrayNotEmpty(filteredData)
          ? setOptions(
              filteredData,
              "RelationshipId",
              false,
              "RelationshipNumber",
              "Name"
            )
          : [];
        setContracts(options);
        if (options.length === 1) {
          const tempDefaultContract = _.get(options, `0.id`, null);
          setSelectedContract(tempDefaultContract || null);
        } else {
          setSelectedContract(null);
        }
      })
      .catch((error) => {
        setContracts([]);
        setSelectedContract(null);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId) && personId) {
      fetchContract();
    }
  }, [tokenId, personId]);

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fetchCropYear();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className=" col-12 offset-lg-2 col-lg-10 d-none d-lg-block m_l_0_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu {...props} slug="my-payments-and-equity" />
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props} slug={"statement-report"} />
            </div>
          </div>
          <div className="col-12 col-lg-10">
            <ul
              className="nav nav-pills mt-5 mt-lg-4 mb-3 bordered_nav_tabs statement_tabs"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={selectedTab == 0 ? "nav-link active" : "nav-link "}
                  id="pills-payments-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-payments"
                  type="button"
                  role="tab"
                  aria-controls="pills-payments"
                  aria-selected="true"
                  onClick={() => changeTab(0)}
                >
                  Payments
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={selectedTab == 1 ? "nav-link active" : "nav-link "}
                  id="pills-equity-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-equity"
                  type="button"
                  role="tab"
                  aria-controls="pills-equity"
                  aria-selected="false"
                  onClick={() => changeTab(1)}
                >
                  Equity
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={selectedTab == 2 ? "nav-link active" : "nav-link "}
                  id="pills-pool-earnings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-pool-earnings"
                  type="button"
                  role="tab"
                  aria-controls="pills-pool-earnings"
                  aria-selected="false"
                  onClick={() => changeTab(2)}
                >
                  Pool Earnings
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={selectedTab == 3 ? "nav-link active" : "nav-link "}
                  id="pills-tax-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-tax"
                  type="button"
                  role="tab"
                  aria-controls="pills-tax"
                  aria-selected="false"
                  onClick={() => changeTab(3)}
                >
                  Tax
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className={
                  selectedTab == 0
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-payments"
                role="tabpanel"
                aria-labelledby="pills-payments-tab"
              >
                <Payment
                  cropYears={cropYears}
                  contracts={contracts}
                  selectedCropYear={selectedCropYear}
                  selectedContract={selectedContract}
                />
              </div>
              <div
                className={
                  selectedTab == 1
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-equity"
                role="tabpanel"
                aria-labelledby="pills-equity-tab"
              >
                <Equity
                  contracts={contracts}
                  selectedContract={selectedContract}
                />
              </div>
              <div
                className={
                  selectedTab == 2
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-pool-earnings"
                role="tabpanel"
                aria-labelledby="pills-pool-earnings-tab"
              >
                <PoolEarnings
                  cropYears={cropYears}
                  contracts={contracts}
                  selectedCropYear={selectedCropYear}
                  selectedContract={selectedContract}
                />
              </div>
              <div
                className={
                  selectedTab == 3
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-tax"
                role="tabpanel"
                aria-labelledby="pills-tax-tab"
              >
                <Tax
                  cropYears={cropYears}
                  contracts={contracts}
                  selectedCropYear={selectedCropYear}
                  selectedContract={selectedContract}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
