import React from "react";
import Breadcrumb from "../global/breadCrumb";
import LeftMenu from "../global/leftMenu";
import QuickLinks from "../home/quickLinks";

const Body = (props) => {
  return (
    <>
      <div className="row">
        {/*Add breadCrumb*/}
        <div className="row">
          <div className=" col-12 offset-lg-2 col-lg-10 d-none d-lg-block">
            <Breadcrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu {...props} slug="my-farm" />
			
            {/* <!-- Quick Links starts --> */}
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props} slug={'my-farm'}/>
            </div>
            {/* <!-- Quick Links ends --> */}
          </div>

          <div className="col-12 col-lg-10">
            {/* <!-- Accordion blocks starts --> */}
            {props.contentbody}
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
