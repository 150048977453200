import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../global/breadCrumb";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import CurrentLocation from "../global/currentLocation";
import { OSContactsCard } from "../community/regions/contactCard";
import {
  getResolvedLanguage,
  IsObjectChecker,
  groupArrayByKey,
  IsTokenChecker,
  IsStringChecker,
  sortByField,
  UniqueObjectArray,
  safeArrayCheck,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import constantsVariable from "../../utils/constantsVariable";
import ComponentListRenderer from "../global/ContentBlockRenderSwitch";

const OSContacts = (props) => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const {
    tabList = [],
    page_slug = null,
    showRegion = false,
    showCommittee = false,
    ...rest
  } = props;
  const [selectedTab, setSelectedTab] = useState(tabList[0]);
  const [ContactList, setContactList] = useState([]);

  const [groupedDataByAccordion, setGroupedDataByAccordion] = useState({});
  const [componentList, setComponentList] = useState([]);

  const [getPath] = CurrentLocation();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const tokenId = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();

  const { component_accordion_en_path, component_listing_dropdowns } =
    constantsVariable;

  const handlerTab = (params) => {
    setSelectedTab(params);
  };

  const getContentBlocks = async () => {
    const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${page_slug}`;
    await cmsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));

        const groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        const componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );
        setComponentList(componentArr);

        const groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        setGroupedDataByAccordion(
          IsObjectChecker(groupedData) ? groupedData : {}
        );
      })
      .catch((error) => {
        setGroupedDataByAccordion({});
        setComponentList([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const getContacts = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      accounts,
      "contact_cards?select=*,name_en,name_fr,name_es&order=order_by,lastname_en"
    )
      .then((res) => {
        setContactList(res?.data?.data || []);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getContacts();
      IsStringChecker(page_slug) && getContentBlocks();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const RenderPropertiesGroup = [
    {
      AdjustmentClassName: `mt-5 mt-lg-0`,
    },
  ];
  const DefaultCssClass = component_listing_dropdowns?.blueTitleBox;
  return (
    <>
      {loader && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        {/*Add breadCrumb*/}
        <div className="row">
          <div className=" col-12 col-lg-12 d-none d-lg-block">
            <Breadcrumb {...rest} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ComponentListRenderer
              ComponentList={componentList}
              GroupedData={groupedDataByAccordion}
              RenderPropertiesGroup={RenderPropertiesGroup}
              DefaultCssClass={DefaultCssClass}
            />
          </div>
          <div className="col-12">
            <div className="accordion-body mt-3 p-0">
              {/*<!-- Tabs for Delivery data starts -->*/}
              <ul
                className={`nav nav-pills mt-5 mt-lg-4 mb-3 bordered_nav_tabs ${
                  IsStringChecker(page_slug) ? "" : "statement_tabs"
                }`}
                id="pills-tab"
                role="tablist"
              >
                {tabList?.map((item, index) => (
                  <li className="nav-item" key={index} role="presentation">
                    <button
                      className={`nav-link ${
                        selectedTab === item ? " active" : ""
                      }`}
                      id="pills-receipts-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-receipts"
                      type="button"
                      role="tab"
                      aria-controls="pills-receipts"
                      aria-selected={selectedTab === item ? true : false}
                      onClick={() => handlerTab(item)}
                    >
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="tab-content" id="pills-tabContent">
                {/*<!-- Receipts tab -->*/}
                {tabList?.map((tabName, index) => (
                  <div
                    key={index}
                    className={`tab-pane fade ${
                      selectedTab === tabName ? " show active" : ""
                    }`}
                    id="pills-receipts"
                    role="tabpanel"
                    aria-labelledby="pills-receipts-tab"
                  >
                    <div className="row person_cards">
                      {/* <div className="d-block d-lg-flex justify-content-center atlantic_provinces_block"> */}
                      {ContactList?.filter(
                        (item) =>
                          item.page_sections.indexOf(
                            tabName.replace(/ /g, "-").toLowerCase()
                          ) > -1
                      )?.map((contact, ind) => (
                        // <div className="col-12 col-lg-4" key={index}>
                        <OSContactsCard
                          key={ind}
                          URLpath={`${getPath}/${contact.id}/${tabName
                            .replace(/ /g, "-")
                            .toLowerCase()}/${contact[
                            `name_${resolvedLanguage}`
                          ]
                            .trim()
                            .replace(/ /g, "-")}`}
                          {...contact}
                          showRegion={showRegion}
                          showCommittee={showCommittee}
                        />
                        // </div>
                      ))}
                    </div>
                  </div>
                  // </div>
                ))}

                {/*<!-- Receipts tab ends -->*/}
              </div>
              {/*<!-- Tabs for Delivery data ends -->*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OSContacts;
