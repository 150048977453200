/**
 * @component_Name : ForumsDetailBody
 * @description : Here user can see the forum detailed contents and user can do reply, delete and edit forum post and comments
 * @company : Photon
 * @author : Gobi
 **/
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../services/gbsData";
import Loading from "../global/loading";
import { useParams, useHistory } from "react-router-dom";
import CurrentLocation from "../global/currentLocation";
import parse from "html-react-parser";
import moment from "moment";
import Moment from "react-moment";
import { CommentImageUrl, htmlEncodeDecode } from "../../utils/constants";
import { Modal, Alert } from "react-bootstrap";
import ReactQuill from "react-quill";
import {
  getproper_Atag_URLfromRichContent,
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import { BreadCrumbForForum } from "../global/breadCrumb";
const ForumsDetailBody = (props) => {
  const history = useHistory();
  const { threadId, forumType } = useParams();
  const [commentId, setCommentId] = useState("");
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();

  const tokenId = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [forumPostReply, setForumPostReply] = useState([]);
  const [originalPost, setOriginalPost] = useState({});
  const [searchText, setSearchText] = useState("");
  const [descriptionLenght, setDescriptionLength] = useState(0);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link','image'],
    ],
  };

  // reply event
  const [mode, setMode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState({
    title: "",
    message: "",
    description: "",
    forum_type_id: threadId,
    user_id: accounts[0]?.localAccountId,
    user_fullname: accounts[0]?.name,
  });
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });
  const [serviceError, setServiceError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [sendMessageItem, 
    // setSendMessageItem
  ] = useState({});

  const handleClose = () => {
    setShowModal(false);
    setServiceError({ isError: false, errorMessage: "" });
    setValue({
      title: "",
      message: "",
      description: "",
      forum_type_id: threadId,
      user_id: accounts[0]?.localAccountId,
      user_fullname: accounts[0]?.name,
    });
  };
  const handleShow = () => {
    setMode("reply");
    setShowModal(true);
    setDescriptionLength(0)
  };
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getAllForums();
      postViewCount();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  const getAllForums = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      accounts,
      `forum_posts?select=id,title,description,user_id,user_fullname,created_at,forum_comments(id,user_id,user_fullname,description,created_at,updated_at,is_active)&id=eq.${threadId}&forum_comments.is_active=eq.true&forum_comments.order=created_at.desc`
    )
      .then((res) => {
        let response = res?.data?.data || [];
        if (response.length > 0) {
          setForumPostReply(response[0]?.forum_comments);
          setOriginalPost(response[0]);
        } else {
          setForumPostReply([]);
          setOriginalPost({});
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const postViewCount = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      accounts,
      `forum-posts/${threadId}/views`,
      "post",
      { user_id: accounts[0].localAccountId }
    )
      .then((res) => {
        if (res?.data?.status === "success") {
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const sortFunction = (e) => {
    let ordered = [];
    if (e.target.value === "old") {
      ordered = forumPostReply.sort(
        (a, b) => moment(a.updated_at).utc() - moment(b.updated_at).utc()
      );
    } else {
      ordered = forumPostReply.sort(
        (a, b) => moment(b.updated_at).utc() - moment(a.updated_at).utc()
      );
    }
    setForumPostReply([...ordered]);
  };

  const handleSearchSubmit = (event) => {
    history.push(`/community/forums/search?q=${searchText}`);
    event.preventDefault();
  };

  const rteChange = (content, delta, source, editor) => {
    if (editor.getText() !== "\n" || content.search("<img") > -1){
      setValue({ ...value, description: content });
      setDescriptionLength(new Blob([content]).size/(1024*1024));
    } 
    else{
      setValue({ ...value, description: "" });
      setDescriptionLength(0);
    }    
    return;
  };

  const replyThread = async () => {
    forumUpdateServiceCall(`forum-posts/${threadId}/comments`, "post", value);
  };

  const editComments = (obj) => {
    setValue({ ...value, description: obj.description });
    setCommentId(obj.id);
    setShowModal(true);
    setMode("editComment");
    setDescriptionLength(new Blob([obj.description]).size/(1024*1024));
  };

  const saveEditComments = async () => {
    forumUpdateServiceCall(`forum-comments/${commentId}`, "put", {
      description: value.description,
    });
  };
  const dltComment = (obj) => {
    setShowModal(true);
    setCommentId(obj.id);
    setMode("deleteComment");
  };
  const deleteComment = () => {
    forumUpdateServiceCall(`forum-comments/${commentId}`, "put", {
      is_active: "f",
    });
  };

  const editPost = (obj) => {
    setValue({ ...value, title: obj.title, description: obj.description });
    setCommentId(obj.id);
    setShowModal(true);
    setMode("editPost");
  };
  const saveEditPost = async () => {
    forumUpdateServiceCall(`forum-posts/${threadId}`, "put", {
      title: value.title,
      description: value.description,
    });
  };
  const dltPost = () => {
    setShowModal(true);
    setMode("deletePost");
  };
  const deletePost = () => {
    forumUpdateServiceCall(`forum-posts/${threadId}`, "put", {
      is_active: "f",
    });
  };
  // const sendMessagePopup = (item) => {
  //   setShowModal(true);
  //   setMode("sendMessage");
  //   setSendMessageItem(item);
  // };

  const getencodeHtml =(htmlData) => {
    let htmlDecodeData=htmlData;
    if(htmlDecodeData !=undefined && htmlDecodeData != null){
         for(const htmlTag of htmlEncodeDecode){
            htmlDecodeData=htmlDecodeData.replaceAll(htmlTag.encodekey, htmlTag.decodeValue);
        }
    }
    return htmlDecodeData;
  }

  const sendMessage = async () => {
    let msg = getencodeHtml(`This message was sent from the community forums. <br />Forum Name: ${forumType === 'classifieds' && 'Classifieds'}<br /> Title: <a href="${location.pathname}">${originalPost?.title}</a><br/><br/><p>Message:<br/> ${value.message}</p>`);
    setLoader(true);
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      "persons/notifications",
      "post",
      {
        uuid: sendMessageItem?.user_id,
        data: [
          {
            msg: msg,
            url: location.pathname,
            title: `Forum Message from ${accounts[0].name}`,
            read: false,
          }
        ],
      }
    )
      .then((res) => {
        setLoader(false);
        if (res?.data?.status === "success") {
          handleClose();
          setAlertOpen({
            isActive: true,
            message: `${
              moreLangs[i18n.resolvedLanguage].forum_page
                .send_message_success_msg
            }`,
          });
          // setTimeout(() => {
          //   setAlertOpen({isActive:false,message:""});
          // }, 4000);
        }
      })
      .catch((e) => {
        setLoader(false);
        setServiceError({
          isError: true,
          errorMessage: e?.response?.data?.status,
        });
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  //Common method for update comments and post
  const forumUpdateServiceCall = async (url, method, data) => {
    // let descriptionSize = new Blob([data.description]).size;
    // if(descriptionSize > 5242880){
    //   return false;
    // }

    setLoader(true);
    await cmsDataCommonMethod(tokenId, accounts, url, method, data)
      .then((res) => {
        setLoader(false);
        if (res?.data?.status === "success") {
          if (mode === "deletePost") {
            history.goBack();
          } else {
            handleClose();
            getAllForums();
          }
          let message =
            mode === "reply"
              ? moreLangs[i18n.resolvedLanguage]?.forum_page
                  .reply_success_message
              : mode === "editComment"
              ? moreLangs[i18n.resolvedLanguage]?.forum_page
                  .edit_reply_success_message
              : mode === "editPost"
              ? moreLangs[i18n.resolvedLanguage]?.forum_page
                  .edit_post_success_message
              : mode === "deleteComment"
              ? `${
                  moreLangs[i18n.resolvedLanguage]?.forum_page
                    .delete_comment_success_message
                }`
              : "";
          setAlertOpen({ isActive: true, message: message });
          // setTimeout(() => {
          //   setAlertOpen({isActive:false,message:""});
          // }, 4000);
        }
      })
      .catch((e) => {
        setLoader(false);
        setServiceError({
          isError: true,
          errorMessage: e?.response?.data?.status,
        });
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/* breadcrumb */}
      <div className=" col-12 col-lg-12 d-none d-lg-block">
        <BreadCrumbForForum {...props} originalPost={originalPost} />
      </div>
      {alertOpen.isActive && (
        <Alert
          className="mt-m-5 -mb-m-20"
          variant="success"
          onClose={() => setAlertOpen({ isActive: false, message: "" })}
          dismissible
        >
          <b>{alertOpen.message}</b>
        </Alert>
      )}
      {/* Detail section */}
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="overflow-hidden mt-5 mt-lg-3 p-0">
              <div className="float-none float-sm-start back_btn">
                <button
                  onClick={() => history.goBack()}
                  className="btn btn-primary transparent_btn me-3 text-14"
                >
                  {moreLangs[i18n.resolvedLanguage]?.back}
                </button>
              </div>
              <div className="float-none float-sm-end mb-0 mb-sm-2">
                <div className="row">
                  <div className="col-auto pe-2 sm-width-75">
                    <form onSubmit={handleSearchSubmit}>
                      <span className="input-group border-1 border-radius-6 my-2 my-lg-0">
                        <span
                          className="input-group-text border-0 white_bg"
                          id="basic-addon1"
                        >
                          <i
                            className="fa fa-search blue_color text-18"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <input
                          onChange={(e) => setSearchText(e.target.value)}
                          type="text"
                          className="form-control border-0 py-2 left-space-0"
                          placeholder={
                            moreLangs[i18n.resolvedLanguage]?.forum_page
                              .search_forum
                          }
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </span>
                    </form>
                  </div>
                  {/* <div className="col-auto pe-0 pe-sm-3 mb-2 sm-width-25">
                    <span>
                      <button
                        href="#"
                        className="btn btn-primary position-relative sm-top-10 width-100"
                        onClick={handleShow}
                      >
                        {
                          moreLangs[i18n.resolvedLanguage]?.button_text
                            .reply_text
                        }
                      </button>
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card card-shadow mt-0 mb-0 mb-lg-3 p-0">
              <h4 className="card-header white_color ver-space-10 left-border-radius right-border-radius">
                <span className="ver-space-5 d-inline-block mb-2 mb-lg-0 text-capitalize">
                  {originalPost?.title}
                </span>
                <div className="float-none float-sm-end">
                  <select
                    onChange={(e) => {
                      sortFunction(e);
                    }}
                    className="form-select text-14 filter_text d-inline-block sm_width-78_p float-end"
                    aria-label="Default select example"
                  >
                    <option value="new">
                      {moreLangs[i18n.resolvedLanguage]?.sort_newest}
                    </option>
                    <option value="old">
                      {moreLangs[i18n.resolvedLanguage]?.sort_oldest}
                    </option>
                  </select>
                </div>
                <span className="float-start float-sm-end p-2 ps-0 text-14">
                  {moreLangs[i18n.resolvedLanguage]?.home_page?.sort_by}:{" "}
                </span>
              </h4>
              {originalPost?.id !== "" && (
                <div className="card-body p-0 white_bg border-radius-6 mb-3">
                  <div className="row m-2 m-lg-3">
                    <div className="col-9 col-sm-8">
                      <div className="d-flex align-items-center justify-content-lg-start pe-1 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none me-2">
                        <img
                          src={CommentImageUrl + originalPost?.user_id + ".jpg"}
                          alt="user_forum"
                          className="card-img-top border-radius-50 news_profile_img float-start"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                          }}
                        />
                        <h5 className="float-start px-2 px-lg-3 d-block print_font">
                          <span className="fw-bold">
                            {originalPost?.user_fullname}
                          </span>
                          <span className="d-block pt-1 text-14">
                            {originalPost?.title}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-3 col-sm-4">
                      <p className="float-end mt-3 mt-sm-0">
                        <Moment local utc format="MM/DD/YYYY hh:mm A">
                          {originalPost?.created_at}
                        </Moment>
                      </p>
                    </div>
                    <div className="pt-4 forum-detail-rich-content">
                      {parse(
                        getproper_Atag_URLfromRichContent(
                          originalPost?.description || ""
                        )
                      )}
                    </div>
                    <div>
                      {/* {accounts[0].localAccountId !== originalPost?.user_id && forumType === 'classifieds'&& (
                        <button
                          type="button"
                          className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                          onClick={() => {
                            sendMessagePopup(originalPost);
                          }}
                        >
                          {moreLangs[i18n.resolvedLanguage]?.send_message}
                        </button>
                      )} */}
                      {accounts[0].localAccountId === originalPost?.user_id && (
                        <button
                          type="button"
                          className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                          onClick={() => {
                            dltPost(originalPost);
                          }}
                        >
                          {moreLangs[i18n.resolvedLanguage]?.delete}
                        </button>
                      )}
                      {accounts[0].localAccountId === originalPost?.user_id && (
                        <button
                          type="button"
                          className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                          onClick={() => {
                            editPost(originalPost);
                          }}
                        >
                          {moreLangs[i18n.resolvedLanguage]?.edit}
                        </button>
                      )}
                      <button
                        className="btn btn-primary float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                        onClick={handleShow}
                      >
                        {
                          moreLangs[i18n.resolvedLanguage]?.button_text
                            .reply_text
                        }
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* reply section */}
            {forumPostReply.map((item, index) => (
              <Fragment key={index}>
                <div className="card card-shadow mt-5 mt-lg-0 mb-0 mb-lg-3"></div>
                <div className="card-body p-0 white_bg border-radius-6 mb-3 ms-4">
                  <div className="row m-2 m-lg-3">
                    <div className="col-9 col-sm-8">
                      <div className="d-flex align-items-center justify-content-lg-start pe-1 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none me-2">
                        <img
                          src={CommentImageUrl + item?.user_id + ".jpg"}
                          alt="user_forum"
                          className="card-img-top border-radius-50 news_profile_img float-start"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                          }}
                        />
                        <h5 className="float-start px-2 px-lg-3 d-block print_font">
                          <span className="fw-bold">{item.user_fullname}</span>
                          <span className="d-block pt-1 text-14">
                            {originalPost.title}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-3 col-sm-4">
                      <p className="float-end mt-3 mt-sm-0">
                        <Moment local utc format="MM/DD/YYYY hh:mm A">
                          {item?.updated_at}
                        </Moment>
                      </p>
                    </div>
                    <div className="pt-4 forum-detail-rich-content">
                      {parse(
                        getproper_Atag_URLfromRichContent(
                          item?.description || ""
                        )
                      )}
                    </div>
                    <div>
                      {/* {accounts[0].localAccountId !== item?.user_id && forumType === 'classifieds' && (
                        <button
                          type="button"
                          className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                          onClick={() => {
                            sendMessagePopup(item);
                          }}
                        >
                          {moreLangs[i18n.resolvedLanguage]?.send_message}
                        </button>
                      )} */}
                      {accounts[0].localAccountId === item?.user_id && (
                        <button
                          type="button"
                          className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                          onClick={() => {
                            dltComment(item);
                          }}
                        >
                          {moreLangs[i18n.resolvedLanguage]?.delete}
                        </button>
                      )}
                      {accounts[0].localAccountId === item?.user_id && (
                        <button
                          type="button"
                          className="btn btn-primary transparent_btn float-end my-1 me-1 my-sm-3 me-sm-3 text-14"
                          onClick={() => {
                            editComments(item);
                          }}
                        >
                          {moreLangs[i18n.resolvedLanguage]?.edit}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      {/* Modal Popup start */}
      {showModal && (
        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          dialogClassName={`${
            mode !== "deleteComment" && mode !== "deletePost" && "modal-lg"
          }`}
          keyboard={false}
        >
          <Modal.Header closeButton className="blue_bg">
            <Modal.Title>
              <h4 className="modal-title white_color">
                {mode === "reply"
                  ? moreLangs[i18n.resolvedLanguage]?.forum_page
                      .reply_to_post_text
                  : mode === "editComment"
                  ? moreLangs[i18n.resolvedLanguage]?.forum_page.edit_reply_text
                  : mode === "editPost"
                  ? moreLangs[i18n.resolvedLanguage]?.forum_page.edit_post_text
                  : mode === "deleteComment"
                  ? `${
                      moreLangs[i18n.resolvedLanguage]?.forum_page
                        .delete_comment_text
                    }`
                  : mode === "deletePost"
                  ? `${
                      moreLangs[i18n.resolvedLanguage]?.forum_page
                        .delete_comment_text
                    }`
                  : mode === "sendMessage"
                  ? `${moreLangs[i18n.resolvedLanguage]?.send_message}`
                  : ""}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div
              className={`white_bg p-lg-4 border-radius-6 ${
                (mode === "deleteComment" || mode === "deletePost") &&
                "modal-padding-0"
              }`}
            >
              {mode === "editPost" && (
                <div className="row mt-2">
                  <div className="col-12 col-sm-12">
                    <div className="m-2 m-lg-0 mb-3">
                      <label
                        htmlFor="subject_Text"
                        className="form-label text-16 fw-bold"
                      >
                        {
                          moreLangs[i18n.resolvedLanguage]?.forum_page
                            .subject_text
                        }
                        :
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        value={value.title}
                        placeholder={
                          moreLangs[i18n.resolvedLanguage]?.forum_page
                            .subject_text
                        }
                        onChange={(e) =>
                          setValue({ ...value, title: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {mode !== "deleteComment" &&
                mode !== "deletePost" &&
                mode !== "sendMessage" && (
                  <div className="row">
                    <div className="col-12 col-sm-12">
                      <div className="forums-new-thread m-2 m-lg-0 mb-3">
                        <label
                          htmlFor="post_Text"
                          className="form-label text-16 fw-bold mt-2"
                        >
                          {
                            moreLangs[i18n.resolvedLanguage]?.forum_page
                              .post_text
                          }
                          :
                        </label>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          // placeholder={
                          //   moreLangs[i18n.resolvedLanguage]?.button_text
                          //     .post_text
                          // }
                          value={value.description}
                          onChange={rteChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
              {mode === "sendMessage" && (
                <>
                  <div className="row">
                    <div className="col-5 col-lg-3">
                      <h5 className="mt-4 mb-0 text-uppercase text-14 text-end fw-bold me-2">
                        {
                          moreLangs[i18n.resolvedLanguage]?.forum_page
                            .Send_message_to
                        }
                      </h5>
                    </div>
                    <div className="col-7 col-lg-9">
                      <h4 className="mt-4 mb-0 text-14">
                        {sendMessageItem?.user_fullname}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5 col-lg-3">
                      <h5 className="text-uppercase mt-4 mb-0 pt-2 text-14 text-end fw-bold me-2">
                        {moreLangs[i18n.resolvedLanguage]?.forum_page.message}
                      </h5>
                    </div>
                    <div className="col-7 col-lg-9">
                      <textarea
                        type="text"
                        value={value.message}
                        onChange={(e) =>
                          setValue({ ...value, message: e.target.value })
                        }
                        className="form-control mt-4 mb-4 text-14 width-70"
                        id="subject"
                        placeholder={
                          moreLangs[i18n.resolvedLanguage]?.forum_page.message
                        }
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
              {serviceError.isError && (
                <p className="errorClass">{serviceError.errorMessage}</p>
              )}
              
              {
                (mode !== "deleteComment" && mode !== "deletePost") && <p className="">{moreLangs[i18n.resolvedLanguage]?.forum_page.description_size} {descriptionLenght.toFixed(2)}MB</p>
              }
              {
                descriptionLenght >= 5 && <p className="forum-detail-error">{moreLangs[i18n.resolvedLanguage]?.forum_page.description_size_warning}</p>
              }              
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button
              className="btn btn-primary"
              id="ok_button"
              disabled={
                (mode === "deleteComment" || mode === "deletePost"
                  ? false
                  : mode === "sendMessage"
                  ? value.message === ""
                  : value.description === "") 
                  
                  || descriptionLenght >= 5
                  
                // (mode === "deleteComment" || mode === "deletePost") ? false : (mode ==="sendMessage" && value.message ==="") ? ((mode === "editPost" && value.description === "") || value.description === "") : false
              }
              onClick={() =>
                mode === "reply"
                  ? replyThread()
                  : mode === "editComment"
                  ? saveEditComments()
                  : mode === "editPost"
                  ? saveEditPost()
                  : mode === "deleteComment"
                  ? deleteComment()
                  : mode === "deletePost"
                  ? deletePost()
                  : mode === "sendMessage"
                  ? sendMessage()
                  : ""
              }
            >
              {mode === "reply"
                ? moreLangs[i18n.resolvedLanguage]?.button_text.reply_text
                : mode === "editComment"
                ? moreLangs[i18n.resolvedLanguage]?.button_text
                    .upload_reply_text
                : mode === "editPost"
                ? moreLangs[i18n.resolvedLanguage]?.button_text.upload_post_text
                : mode === "deleteComment"
                ? moreLangs[i18n.resolvedLanguage]?.delete
                : mode === "deletePost"
                ? moreLangs[i18n.resolvedLanguage]?.delete
                : mode === "sendMessage"
                ? moreLangs[i18n.resolvedLanguage]?.button_text.send
                : ""}
            </button>
            <button
              className="btn btn-default card-shadow ms-2"
              id="ok_button"
              onClick={handleClose}
            >
              {moreLangs[i18n.resolvedLanguage]?.button_text.cancel_text}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ForumsDetailBody;
