import React, { useState, useEffect } from "react";
import Breadcrumb from "../../global/breadCrumb";
import LeftMenu from "../../global/leftMenu";
import QuickLinks from "../../home/quickLinks";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import CurrentLocation from "../../global/currentLocation";
import { useTranslation } from "react-i18next";
import MyPeopleDetails from "./myPeopleDetails";
import MyPeopleSearch from "./myPeopleSearch";
import Events from "../../home/siteNotification";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
} from "../../../utils/helper";
import Loading from "../../global/loading";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import { roleCBDecisionMaker as CBDecisionMaker, roleCBThirdPartyFarmService as CBThirdPartyFarmService } from "../../../utils/roles";

const MyPeopleLandingPage = (props) => {
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  const tokenId = useSelector((state) => state.reducerToken);

  const [selectedTab, setSelectedTab] = useState("myProfile");
  const [myPeopleData, setMypeopleData] = useState([]);
  const [myPeopleDetailData, setMyPeopleDetailData] = useState([]);

  const { DropDownCBRoles = [], RoleCBPrivileges } = props;
  const { roleCBDecisionMaker = false } = RoleCBPrivileges;

  const handlerTab = (params) => {
    setSelectedTab(params);
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId) && personId) {
      const fetchContract = async () => {
        setLoader(true);
        await gbsDataCommonMethod(
          tokenId,
          accounts,
          `persons/${personId}/contracts?r.ContractStatus=1000,1001`
        )
          .then((resp) => {
            let resultData = [...resp.data];
            const roles = roleCBDecisionMaker
            ? [...DropDownCBRoles, CBDecisionMaker, CBThirdPartyFarmService]
            : [...DropDownCBRoles, CBThirdPartyFarmService];
            let filteredData = FilterContractByRole(
              resultData,
              accounts,
              roles,
            );
            filteredData = _.orderBy(filteredData,
              ["ContractStatus", "RelationshipNumber"],
              ["asc", "asc"]
            );
            const contracts = filteredData.map((li) => li?.RelationshipId);
            getMyPeopleData(tokenId, accounts, contracts);
          })
          .catch((error) => {
            setLoader(false);
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          });
      };
      fetchContract();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId, personId]);

  const getMyPeopleData = async (tokenId, accounts, contracts) => {
    var resp = await Promise.all(
      contracts.map(async (id) => {
        return await gbsDataCommonMethod(
          tokenId,
          accounts,
          `contracts/${id}/positions`
        )
          .then((res) => res.data)
          .catch((error) => {
            setLoader(false);
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    if (IsArrayNotEmpty(resp)) {
      let peoplelist = [];
      resp.map((people) => {
        peoplelist.push(...people);
      });
      if (IsArrayNotEmpty(peoplelist)) {
        const ActivePeopleData = peoplelist.filter(
          (li) => li.ContractStatus === 1000 || li.ContractStatus === 1001
        );
        const peopleData = IsArrayNotEmpty(ActivePeopleData)
          ? JSON.parse(JSON.stringify(ActivePeopleData))
          : [];
        const filteredData = FilterContractByRole(peopleData, accounts, [
          CBDecisionMaker,
        ]);
        // console.log({ peopleData, filteredData });
        setMypeopleData(filteredData);
        setMyPeopleDetailData(peopleData);
      } else {
        setMypeopleData([]);
        setMyPeopleDetailData([]);
      }
    } else {
      setMypeopleData([]);
      setMyPeopleDetailData([]);
    }
    setLoader(false);
  };
  return (
    <>
      {loader && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <Events type={"my-people"} />
        {/*Add breadCrumb*/}
        <div className="row">
          <div className=" col-12 offset-lg-2 col-lg-10 d-none d-lg-block">
            <Breadcrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu  {...props} slug="my-farm" />
            {/* <!-- Quick Links starts --> */}
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props}  slug={"my-people"} />
            </div>
            {/* <!-- Quick Links ends --> */}
          </div>
          <div className="col-12 col-lg-10">
            {/* <!-- Accordion blocks starts --> */}
            <div className="accordion-body mt-3 p-0">
              <div className="col-12">
                <ul
                  className="nav nav-pills mt-5 mt-lg-4 mb-3 bordered_nav_tabs my-people-tabs"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        selectedTab === "myProfile" ? " active" : ""
                      }`}
                      id="pills-notifications-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-myProfile"
                      type="button"
                      role="tab"
                      aria-controls="pills-myProfile"
                      aria-selected={selectedTab === "myProfile" ? true : false}
                      onClick={() => handlerTab("myProfile")}
                    >
                      {moreLangs[i18n.resolvedLanguage]?.my_people?.my_people}
                    </button>
                  </li>
                  {roleCBDecisionMaker && (
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          selectedTab === "search" ? " active" : ""
                        }`}
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-search"
                        type="button"
                        role="tab"
                        aria-controls="pills-search"
                        aria-selected={selectedTab === "search" ? true : false}
                        onClick={() => handlerTab("search")}
                      >
                        {moreLangs[i18n.resolvedLanguage]?.my_people?.search}
                      </button>
                    </li>
                  )}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      selectedTab === "myProfile" ? " show active" : ""
                    }`}
                    id="pills-myProfile"
                    role="tabpanel"
                    aria-labelledby="pills-myProfile-tab"
                  >
                    {IsArrayNotEmpty(myPeopleDetailData) && (
                      <MyPeopleDetails
                        myPeopleDetailData={[...myPeopleDetailData]}
                        {...props}
                        roleCBDecisionMaker={roleCBDecisionMaker}
                      />
                    )}
                  </div>
                  {roleCBDecisionMaker && (
                    <div
                      className={`tab-pane fade ${
                        selectedTab === "search" ? " show active" : ""
                      }`}
                      id="pills-search"
                      role="tabpanel"
                      aria-labelledby="pills-search-tab"
                    >
                      {IsArrayNotEmpty(myPeopleData) && (
                        <MyPeopleSearch data={[...myPeopleData]} {...props} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPeopleLandingPage;
