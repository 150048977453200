import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  getCurrentYear,
  IsTokenChecker,
  setObjToString,
  toDecimal,
  getResolvedLanguage,
  HandleErrorTrackAndToast
} from "../../../utils/helper";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../global/currentLocation";
// import DatePicker from "react-datepicker";
import { dateFormate } from "../../../utils/constantsVariable";
import WhiteCircleLoader from "../../whiteCircleLoader";
import Loading from "../../global/loading";
import { Typeahead, AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import moment from "moment";
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataGridGroupingPagination from "../../global/dataGridGroupingPagination";
// import Moment from "react-moment";
import DatePickerComp from "../../global/datepicker";
import { useGbsDataCache } from "../../../services/gbsDataCache";
import { ImpersonationAccountsSwap } from "../../../permissionWrapper";

const EnterOrEditPesticideApplication = (props) => {
  const cropYear = getCurrentYear();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  const [multiValue, setMultiValue] = useState({});

  const [multiValueUpdate, setMultiValueUpdate] = useState({});
  // const [updateValues, setUpdateValues] = useState({"rateFormulatedProductPerAcre":"","applicationDate":"", "rateUnitMeasurePerAcre_1" :"", "pesticideUseMethods_1":"","pesticideUseTypes_1":""});

  const [disabled, setDisabled] = useState(false);
  const [loading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [singleSelections, setSingleSelections] = useState([]);
  const [typeAheadOptions, setTypeAheadOptions] = useState([]);

  const [contracts, setContracts] = useState([]);
  const [contractNumbers, setContractNumbers] = useState([]);
  const [defaultContract, setDefaultContract] = useState("");
  const [defContracts, setdefContracts] = useState([""]);
  const [CN_MultiValue, setCN_MultiValue] = useState({});

  const [rateUnitMeasurePerAcre, setRateUnitMeasurePerAcre] = useState([]);
  const [pesticideUseMethods, setPesticideUseMethods] = useState([]);
  const [pesticideUseTypes, setPesticideUseTypes] = useState([]);

  const [applicationDate, setApplicationDate] = useState(null);
  const typeheadRef = useRef();
  const asyncTypeaheadRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);

  // const reactDataGrid = useRef();
  const datepicker = useRef(null);
  // const datepicker_1 = useRef(null);

  const [bedHistory, setBedHistory] = useState([]);
  const [bogOrSection, setBogOrSection] = useState([]);
  const [HarvestBed, setHarvestBed] = useState([]);
  const [, setExhibitAcres] = useState([]);
  const [, setBedId] = useState([]);
  const [fieldReset, setFieldReset] = useState(true);
  const [fieldBedReset, setFieldBedReset] = useState(true);
  const [resetAll, setResetAll] = useState(true);

  const [bedTableData, setBedTableData] = useState([]);

  const [rateFormulatedProductPerAcre, setRateFormulatedProductPerAcre] =
    useState("");
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(false);
  const [showModal, setShowModal] = useState({
    isOpen: false,
    message: "",
    onsubmit: null,
  });
  const [updateModal, setUpdateModal] = useState({
    isOpen: false,
    message: "",
    onsubmit: null,
  });
  // Data Grid grouping
  const [expandedGroupIds, setExpandedGroupIds] = useState(() => new Set());
  const [gridItems, setGridItems] = useState([]);
  const [originalPesticideData, setOriginalPesticideData] = useState([]);
  const [, setSelectedItem] = useState([]);

  //Add bed Table data
  const [aditionalHarvestBed, setAditionalHarvestBed] = useState([]);
  const [remainingBedHistory, setRemainingBedHistory] = useState([]);

  //Edit Child
  const [filterBedTableData, setFilterBedTableData] = useState([]);
  const [bedDetailsByEditedRow, setBedDetailsByEditedRow] = useState([]);

  // error
  const [duplicateError, setDuplicateError] = useState(false);

  const dateFormat = (date) =>{
    date = date?.split("T")[0]?.split("-");
    return `${date[1]}/${date[2]}/${date[0]}`;
  }
  const columns = [
    {
      key: "GroupingKey",
      name: "",
      headerCellClass: "text-lg-center",
      width: 30,
      cellClass: "text-lg-center",
      groupFormatter(props) {
        return (
          <>
            {props.isExpanded == true ? (
              <i className="fa fa-minus-circle cursor_pointer table_accordion"></i>
            ) : (
              <i className="fa fa-plus-circle cursor_pointer table_accordion"></i>
            )}
          </>
        );
      },
    },
    {
      key: "ContractName",
      name: "Contract #",
      cellClass: "enablesTextWrapping",
      width: 100,
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">{row.ContractName}</span>
          </>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce((prev, { ContractName }) => ContractName, 0)}
          </span>
        );
      },
    },
    {
      key: "AppliedDate",
      name: "Application Date",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 100,
      dateFormate: dateFormate,
      formatter({ row }) {
        return (
          // <Moment format={dateFormate}>
            <>{dateFormat(row.AppliedDate)}</>
          // </Moment>
          // <>{moment(row.AppliedDate).format(dateFormate)}</>
        );
      },
      groupFormatter({ childRows }) {
        return (
          // <>{moment(childRows.reduce((prev, { AppliedDate }) => AppliedDate, 0)).format(dateFormate)}</>
          // <Moment format={dateFormate}>
            <>{dateFormat(childRows.reduce((prev, { AppliedDate }) => AppliedDate, 0))}</>
          // </Moment>
        );
      },
    },
    {
      key: "PesticideCodeName",
      name: "EPA Or PCP Reg.# / Chemical",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">{row.PesticideCodeName}</span>
          </>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce(
              (prev, { PesticideCodeName }) => PesticideCodeName,
              0
            )}
          </span>
        );
      },
    },
    {
      key: "Rate",
      name: "Rate (Formulated Product Per Acre)",
      minWidth: 80,
      headerCellClass: "enablesTextWrappingHeading",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">{row.Rate}</span>
          </>
        );
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { Rate }) => Rate, 0)}</>;
      },
    },
    {
      key: "Description",
      name: "Rate Unit Of Measure Per Acres(E.G. Oz/Acre)",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">{row.Description}</span>
          </>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce((prev, { Description }) => Description, 0)}
          </span>
        );
      },
    },
    {
      key: "MethodName",
      name: "Method Of Application",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">{row.MethodName}</span>
          </>
        );
      },
      groupFormatter({ childRows }) {
        return <>{childRows.reduce((prev, { MethodName }) => MethodName, 0)}</>;
      },
    },
    {
      key: "TypeName",
      name: "Fruit Type",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">{row.TypeName}</span>
          </>
        );
      },
      groupFormatter({ childRows }) {
        return (
          <span className="enablesTextWrapping">
            {childRows.reduce((prev, { TypeName }) => TypeName, 0)}
          </span>
        );
      },
    },
    {
      key: "HarvestBedList",
      childkey: "HarvestBed",
      name: "Bed(S)",
      width: 130,
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">{row.HarvestBed}</span>
          </>
        );
      },
      groupFormatter({ childRows }) {
        // let totalBeds = "";
        // totalBeds = childRows.reduce((prev, { HarvestBedList }) => HarvestBedList, 0).split(",") || [];
        // return <span className='enablesTextWrapping'>{totalBeds.splice(0,(totalBeds.length > 5 ? 5 : totalBeds.length))}</span>;
        // console.log(childRows.reduce((prev, { HarvestBedList }) => HarvestBedList, 0))

        return (
          <span className="enablesTextWrapping text-center">
            {childRows.reduce((prev, { HarvestBedList }) => HarvestBedList, 0)}
          </span>
        );
      },
    },
    {
      key: "ExhibitAcres",
      name: "Total Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      groupFormatter({ childRows }) {
        let totalAcres = 0;
        totalAcres = childRows.reduce(
          (prev, { ExhibitAcres }) => prev + ExhibitAcres,
          0
        );
        return <>{toDecimal(totalAcres.toFixed(2), 2)}</>;
      },
    },
    {
      key: "AcresTreated",
      name: "Treated Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      groupFormatter({ childRows }) {
        let totalAcres = 0;
        totalAcres = childRows.reduce(
          (prev, { AcresTreated }) => prev + AcresTreated,
          0
        );
        return <>{toDecimal(totalAcres.toFixed(2), 2)}</>;
      },
    },
    {
      key: "Id",
      name: "Action",
      width: 90,
      formatter(row) {
        return (
          <div className="width-70px d-none d-lg-block justify-center">
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
            >
              <i
                onClick={() => editRow(row, "child")}
                className="fa fa-pencil blue_color cursor_pointer mx-1 p-1"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-bottom`}>Delete</Tooltip>}
            >
              <i
                onClick={() => deleteRow(row, "child")}
                className="fa fa-trash blue_color cursor_pointer mx-1 p-1"
              ></i>
            </OverlayTrigger>
            {/* <i onClick={() => editRow(row, "child")} className="fa fa-pencil blue_color cursor_pointer mx-1 p-1" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Edit" aria-label="Edit"></i>
                    <i onClick={() => deleteRow(row, "child")} className="fa fa-trash blue_color cursor_pointer mx-1 p-1" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Delete" aria-label="Delete"></i> */}
          </div>
        );
      },
      groupFormatter(row) {
        return (
          <div className="width-70px d-none d-lg-block justify-center">
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
            >
              <i
                onClick={() => editRow(row, "master")}
                className="fa fa-pencil blue_color cursor_pointer mx-1 p-1"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-bottom`}>Delete</Tooltip>}
            >
              <i
                onClick={() => deleteRow(row, "master")}
                className="fa fa-trash blue_color cursor_pointer mx-1 p-1"
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"bottom"}
              overlay={<Tooltip id={`tooltip-bottom`}>Add New Beds</Tooltip>}
            >
              <i
                onClick={() => addBed(row, "master")}
                className="fa fa-plus blue_color cursor_pointer mx-1 p-1"
              ></i>
            </OverlayTrigger>
            {/* <i onClick={() => addBed(row, "master")} className="fa fa-plus blue_color cursor_pointer mx-1 p-1" aria-hidden="true" data-bs-toggle="tooltip" data-bs-placement="bottom" title="" data-bs-original-title="Delete" aria-label="Delete"><span className='ps-1 gotham'>Add Bed</span></i> */}
          </div>
        );
      },
    },
  ];
  // Data grid related Events and methods
  const addBed = async (obj, mode) => {
    let selectedRow = obj.row?.childRows ? obj.row?.childRows[0] : obj.row;
    let childRows =
      obj.row?.childRows.length > 0 ? obj.row?.childRows : obj.Beds;
    if (mode === "mobile") selectedRow = obj;
    if(props.roleAgScientist){
      setChangeEvent(changeEvent+1);
      setContractGetMode({obj, mode, childRows, selectedRow});
      await setSearchURL(`contracts/${selectedRow.RelationshipId}`);
    }
    else{
      let item = contracts.filter(
        (i) => i.RelationshipId === selectedRow.RelationshipId
      );
      addBedMode(obj, mode, childRows, selectedRow, item);
    }
  };

  const addBedMode = (obj, mode, childRows, selectedRow, item) =>{
    setLoader(true);
    commonAjaxCall(
      `bed-histories?cropyear=${cropYear}&relationshipid=${item[0].RelationshipId}&includesplitbeds=1`,
      "get",
      {},
      (resp) => {
        if (resp.data.length > 0) {
          setAditionalHarvestBed([]);
          setRemainingBedHistory(resp.data);
          let bedIds = setOptions(resp.data, "HarvestBed", true)?.sort(
            (a, b) => a.name - b.name
          );
          let bedHistory = childRows.map((i) => i.HarvestBed) || [];
          let newArr = bedIds.filter((i) => bedHistory?.indexOf(i.name) === -1);
          if (newArr.length === 0) selectedRow.isAditionalBedAvailabel = false;
          else {
            selectedRow.isAditionalBedAvailabel = true;
            setAditionalHarvestBed(newArr);
          }
          selectedRow.aditionalBedTableData = [];
          setUpdateModal({
            isOpen: true,
            message: `Contract #: ${item[0].RelationshipNumber} - ${item[0].Name}`,
            onsubmit: addBedrecord,
            selectedRow: selectedRow,
            updateType: "add",
          });
        } else {
        }
        setLoader(false);
      }
    );
  }
  const addBedrecord = async (obj) => {
    setLoader(true);
    var resp = await Promise.all(
      obj.aditionalBedTableData?.map(async (item) => {
        return await gbsDataCommonMethod(
          tokenId,
          accounts,
          `pesticides/e-pur/use-master/${obj.Id}/add-bed`,
          "post",
          { bed_id: item.BedId, acres_treated: item.EnteredAcres }
        )
          .then((res) => res.data)
          .catch((error) => {
            setLoader(false);
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    if (resp.length > 0) {
      onClearSearch();
      setGridItems([]);
      props.roleAgScientist ? getEPURDataForAGScientist(pageNo) : props.getEPURsPesticideData();
      props.setISDataAdded(true);
      setUpdateModal({
        isOpen: true,
        message: "Bed added successfully",
        onsubmit: null,
        selectedRow: null,
        updateType: "",
      });
    }
    setLoader(false);
  };
  // Add aditonal Bed field change
  const aditionalBogBedFilter = (selectedList, removedItem, isRemoved) => {
    if (selectedList.length === 0 || (removedItem.id === "all" && isRemoved)) {
      updateModal.selectedRow.aditionalBedTableData = [];
      setUpdateModal({ ...updateModal });
    } else {
      setTimeout(() => {
        const bogBed = setObjToString(selectedList);
        let bedData = remainingBedHistory.filter((i) => {
          return bogBed.split(",").indexOf(i.HarvestBed) > -1;
        });
        let groupedArray = _.groupBy(bedData, "HarvestBed");
        bedData = Object.keys(groupedArray).map((i) => {
          let count = _.sumBy(groupedArray[i], "ExhibitAcres");
          return {
            id: i,
            BedId: groupedArray[i][0].BedId,
            isValid: true,
            HarvestBed: groupedArray[i][0].HarvestBed,
            ExhibitAcres: countDecimals(count) > 2 ? count.toFixed(2) : count,
            EnteredAcres: countDecimals(count) > 2 ? count.toFixed(2) : count,
          };
        });
        updateModal.selectedRow.aditionalBedTableData = bedData;
        updateModal.selectedRow.isValid = updateAditionalValidationCheck(
          updateModal.selectedRow.aditionalBedTableData
        );
        setUpdateModal({ ...updateModal });
      }, 0);
    }
  };
  const validationMaxCheckAddBed = (val, item) => {
    try {
      let index = updateModal.selectedRow?.aditionalBedTableData.findIndex(
        (x) => x.id === item.id
      );
      if (parseFloat(val) > item.ExhibitAcres) {
        updateModal.selectedRow?.aditionalBedTableData.splice(index, 1, {
          ...item,
          EnteredAcres: parseFloat(val),
          isValid: false,
        });
      } else {
        updateModal.selectedRow?.aditionalBedTableData.splice(index, 1, {
          ...item,
          EnteredAcres: parseFloat(val),
          isValid: true,
        });
      }
      updateModal.selectedRow.isValid = updateAditionalValidationCheck(
        updateModal.selectedRow.aditionalBedTableData
      );
    } catch (e) {
      console.log(e);
    }

    setUpdateModal({ ...updateModal });
  };
  const updateAditionalValidationCheck = (data) => {
    let count = 0;
    data.map((i) => {
      i.EnteredAcres > i.ExhibitAcres && count++;
    });
    return count === 0 ? true : false;
  };
  const editRow = async (obj, type, mode) => {
    let selectedRow = obj.row?.childRows ? obj.row?.childRows[0] : obj.row;
    if (mode === "mobile") selectedRow = obj;
    if(props.roleAgScientist){
      setChangeEvent(changeEvent+1);
      setContractGet({obj, type, mode, selectedRow});
      await setSearchURL(`contracts/${selectedRow.RelationshipId}`);
    }
    else{
      let selectContract = contracts.filter(
        (i) => i.RelationshipId === selectedRow.RelationshipId
      );
      editRowMode(obj, type, mode, selectedRow, selectContract)
    }
  };

  const editRowMode = (obj, type, mode, selectedRow, selectContract) =>{
    let item = selectContract;
    if (type === "master") {
      setSelectedItem(selectedRow);
      // let date = moment(selectedRow.AppliedDate);
      // date = new Date(Date.UTC(date.year(), date.month(),date.date(), date.hour(),date.minute(), date.second()));
      setApplicationDate(new Date(selectedRow.AppliedDate?.split("T")[0]?.replaceAll("-","/") || ""));
      getPesticideByCropYearAndRegion(
        item[0].Region,
        true,
        selectedRow.PesticideId
      );
      setRateFormulatedProductPerAcre(selectedRow.Rate);
      selectedRow.pesticideUseTypes_1 = selectedRow.TypeId;
      selectedRow.applicationDate_1 = new Date(selectedRow.AppliedDate?.split("T")[0]?.replaceAll("-","/") || "");
      selectedRow.CodeName_1 = [{ PesticideId: selectedRow.PesticideId }];
      selectedRow.rateFormulatedProductPerAcre_1 = selectedRow.Rate;
      selectedRow.pesticideUseMethods_1 = selectedRow.MethodId;
      selectedRow.rateUnitMeasurePerAcre_1 = selectedRow.RateUomId;
      // setTimeout(() => {
      //   datepicker_1?.current !== null && dateInputMask(datepicker_1?.current?.input);
      // }, 500);
    } else {
      // getBogOrSection(item[0].RelationshipId, selectedRow);
      selectedRow.AcresTreated_1 = selectedRow.AcresTreated;
      selectedRow.BedId_1 = selectedRow.BedId;
      selectedRow.ExhibitAcres_1 = selectedRow.ExhibitAcres;
      selectedRow.isValid = true;
      commonAjaxCall(
        `bed-histories?cropyear=${cropYear}&relationshipid=${item[0].RelationshipId}&includesplitbeds=1`,
        "get",
        {},
        (resp) => {
          if (resp.data.length > 0) {
            setFilterBedTableData([]);
            setBedDetailsByEditedRow(resp.data);
            let bedIds = setOptions(resp.data, "HarvestBed", true)?.sort(
              (a, b) => a.name - b.name
            );
            let bedHistory = bedIds.filter(
              (i) =>
                gridItems
                  .filter((i) => i.Id === selectedRow.Id)
                  .map((i) => i.HarvestBed)
                  .toString()
                  ?.indexOf(i.name) === -1
            );
            selectedRow.isFilterDataAvailable = false;
            selectedRow.isValid = true;
            if (bedHistory.length > 0) {
              selectedRow.selectedBed = {
                name: selectedRow.HarvestBed,
                id: selectedRow.HarvestBed,
              };
              bedHistory.unshift(selectedRow.selectedBed);
              selectedRow.isFilterDataAvailable = true;
              selectedRow.isValid = true;

              bedHistory = bedHistory?.sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, {
                  numeric: true,
                  sensitivity: 'base'
                });
              });
              setFilterBedTableData(bedHistory);
            }

          } else {
          }
          setLoader(false);
        }
      );
    }
    setUpdateModal({
      isOpen: true,
      message: `Contract #: ${item[0].RelationshipNumber} - ${item[0].Name}`,
      onsubmit: updateRecord,
      selectedRow: selectedRow,
      updateType: type,
    });
  }
  //Edit mobile child record
  const editChildRecordMobile = (selectedRow, parent) => {
    selectedRow.AcresTreated_1 = selectedRow.AcresTreated;
    selectedRow.BedId_1 = selectedRow.BedId;
    selectedRow.ExhibitAcres_1 = selectedRow.ExhibitAcres;
    selectedRow.isValid = true;
    selectedRow.AppliedDate = parent.AppliedDate;
    selectedRow.PesticideCodeName = parent.PesticideCodeName;
    selectedRow.Rate = parent.Rate;
    selectedRow.Description = parent.Description;
    selectedRow.MethodName = parent.MethodName;
    selectedRow.TypeName = parent.TypeName;
    commonAjaxCall(
      `bed-histories?cropyear=${cropYear}&relationshipid=${parent.RelationshipId}&includesplitbeds=1`,
      "get",
      {},
      (resp) => {
        if (resp.data.length > 0) {
          setFilterBedTableData([]);
          setBedDetailsByEditedRow(resp.data);
          let bedIds = setOptions(resp.data, "HarvestBed", true)?.sort(
            (a, b) => a.name - b.name
          );
          let bedHistory = bedIds.filter(
            (i) =>
              originalPesticideData
                ?.filter((i) => i.Id === parent.Id)[0]
                ?.Beds?.map((i) => i.HarvestBed)
                .toString()
                ?.indexOf(i.name) === -1
          );
          selectedRow.isFilterDataAvailable = false;
          selectedRow.isValid = true;
          if (bedHistory.length > 0) {
            selectedRow.selectedBed = {
              name: selectedRow.HarvestBed,
              id: selectedRow.HarvestBed,
            };
            bedHistory.unshift(selectedRow.selectedBed);
            selectedRow.isFilterDataAvailable = true;
            selectedRow.isValid = true;
            setFilterBedTableData(bedHistory);
          }
          setUpdateModal({
            isOpen: true,
            message: `Contract #: ${parent.RelationshipNumber} - ${parent.Name}`,
            onsubmit: updateRecord,
            selectedRow: selectedRow,
            updateType: "child",
          });
        } else {
        }
        setLoader(false);
      }
    );
  };

  const updateBedChange = (selectedList, selectedItem, selectedRow) => {
    let bedData = bedDetailsByEditedRow.filter(
      (item) => item.HarvestBed === selectedItem.id
    );
    let count = _.sumBy(bedData, "ExhibitAcres");
    selectedRow.ExhibitAcres_1 =
      countDecimals(count) > 2 ? count.toFixed(2) : count;
    selectedRow.AcresTreated_1 =
      countDecimals(count) > 2 ? count.toFixed(2) : count;
    selectedRow.BedId_1 = bedData[0]?.BedId || "";
    setUpdateModal({ ...updateModal, selectedRow: selectedRow });
  };

  const aceresTreatedEditChange = (val, selectedRow) => {
    selectedRow.AcresTreated_1 = val;
    parseFloat(val) > selectedRow.ExhibitAcres_1
      ? (selectedRow.isValid = false)
      : (selectedRow.isValid = true);
    setUpdateModal({ ...updateModal, selectedRow: selectedRow });
  };
  const deleteRow = (childRows, type, mode) => {
    setShowModal({
      isOpen: true,
      message: "Are you sure you'd like to delete?",
      onsubmit: deleteRecordData,
      type: "delete",
      selectedRow: childRows,
      deleteType: type,
      mode: mode,
    });
  };

  const updateRecord = (obj, updateType) => {
    let request = {},
      url = "";
    if (updateType === "master") {
      request = {
        date_applied: moment(obj.applicationDate_1).format(dateFormate),
        pesticide_id: obj.CodeName_1[0]?.PesticideId,
        rate: obj.rateFormulatedProductPerAcre_1,
        method_id: obj.pesticideUseMethods_1,
        type_id: obj.pesticideUseTypes_1,
        rate_uom_id: obj.rateUnitMeasurePerAcre_1,
      };
      let requestTest = {
        relationship_id: obj.RelationshipId,
        crop_year: cropYear,
        date_applied: moment(obj.applicationDate_1).format(dateFormate),
        pesticide_id: obj.CodeName_1[0]?.PesticideId,
        rate: obj.rateFormulatedProductPerAcre_1,
        method_id: obj.pesticideUseMethods_1,
        type_id: obj.pesticideUseTypes_1,
        rate_uom_id: obj.rateUnitMeasurePerAcre_1,
      };
      setDuplicateError(false);
      if (!validataeRecordWithTable(requestTest)) {
        setDuplicateError(true);
        // setShowModal({ isOpen: true, message: "An entry for this combination crop year, contract, application date and product already exists. If you need to edit that application please use the Review and Edit grid at the bottom of the screen.", type: "validation", deleteType: '', mode: '' });
        return false;
      }
      url = `pesticides/e-pur/use-master/${obj.Id}`;
    } else {
      request = {
        bed_id: obj.BedId_1,
        acres_treated: obj.AcresTreated_1,
      };
      url = `pesticides/e-pur/use-bed/${obj.PesticideUseBedId}`;
    }
    commonAjaxCall(url, "put", request, (resp) => {
      setLoader(false);
      if (resp?.data?.status === "success") {
        onClearSearch();
        props.roleAgScientist ? getEPURDataForAGScientist(1) : props.getEPURsPesticideData();        
        props.setISDataAdded(true);
        setUpdateModal({
          isOpen: true,
          message: "Record updated successfully",
          onsubmit: null,
          selectedRow: null,
          updateType: "",
        });
      }
    });
  };

  const deleteRecordData = (obj, deleteType, mode) => {
    let recordId = obj.row?.childRows
      ? obj.row?.childRows[0].Id
      : obj.row?.PesticideUseBedId;
    if (mode === "mobile")
      recordId = deleteType === "master" ? obj.Id : obj.PesticideUseBedId;
    let url =
      deleteType === "master"
        ? `pesticides/e-pur/use-master/${recordId}/soft-delete`
        : `pesticides/e-pur/use-bed/${recordId}/soft-delete`;

    commonAjaxCall(url, "put", {}, (resp) => {
      setLoader(false);
      // onModalHide();
      if (resp?.data?.status === "success") {
        if (deleteType === "master") {
          setOriginalPesticideData([]);
          setGridItems([]);
        }
        props.roleAgScientist ? getEPURDataForAGScientist(pageNo) : props.getEPURsPesticideData();
        props.setISDataAdded(true);
        setShowModal({ isOpen: true, message: "Record deleted successfully" });
      }
    });
  };

  // Data grid grouping
  const refReactDataGrid = useCallback((node) => {
    if (node !== null) {
      if (
        document.querySelectorAll(
          '.rdg [aria-expanded="false"] [aria-colindex="1"] .pointerNone'
        )?.length > 0
      ) {
        document
          .querySelectorAll(
            '.rdg [aria-expanded="false"] [aria-colindex="1"] .pointerNone'
          )
          .forEach((e) => {
            e.parentElement.style.pointerEvents = "none";
          });
      }
      if (
        document.querySelectorAll(
          '.rdg [aria-expanded="false"] [aria-colindex="1"]'
        ).length > 0
      ) {
        document
          .querySelectorAll('.rdg [aria-expanded="false"] [aria-colindex="1"]')
          .forEach((e) => {
            e.parentElement.childNodes.forEach((j) => {
              j.style.borderTop = "1px solid #ccc";
            });
          });
      }
    }
  }, []);


  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getRateUnitMeasurePerAcre();
      getPesticideUseMethods();
      getPesticideUseTypes();
      if(props.roleAgScientist){
        getEPURDataForAGScientist();
      }
      setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    }
  }, [tokenId]);

//AG Scientist role start
  const limit = 10;
  const [pageNo,setPageNumber] = useState(1);
  const [totalPageCount,setTotalPageCount] = useState(null); 
  const [reloadGrid, setReloadGrid] = useState(true);
  //Cache Implementation
  const [paymentSearchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    paymentSearchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  const [contractGet, setContractGet] = useState("");
  const [contractGetMode, setContractGetMode] = useState("");

  useEffect(() => {
    setLoader(false);
    if (data.data) {
      if(Array.isArray(data.data)){
        if(contractGet !== ""){
          setContracts(data.data);   
          let {obj, type, mode,selectedRow} = contractGet;       
          editRowMode(obj, type, mode,selectedRow,data.data);
          setContractGet("");
          
        }    
        if(contractGetMode !== ""){
          setContracts(data.data); 
            
          let {obj, mode,childRows, selectedRow} = contractGetMode;  
          addBedMode(obj, mode, childRows, selectedRow, data.data);
          setContractGetMode("");
        }    
      }
    }
    // if(status === 'error'){
      // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    // }
  }, [data,changeEvent, status]);
  //AG Scientist role End
  
  const getEPURDataForAGScientist = (page = null, isReload = false, isMobile = false) =>{
    setLoader(true);
    // setIsPesticideGet(true);
    // setSearchURL(`pesticides/e-pur?cropyear=${cropYear}&limit=${limit}&page=${pageNo}&isfinalise=0&showtotal=1`);
    commonAjaxCall(
      `pesticides/e-pur?cropyear=${cropYear}&limit=${limit}&page=${page || pageNo}&isfinalise=0&showtotal=1`,
      "get",
      {},
      (resp) => {
        setLoader(false);
        if(Array.isArray(resp.data)){
          let records = [...resp.data];
          setTotalPageCount(records[0].total);
          records = records.splice(1);
          if(!isMobile)
            getPesticideePur(records);
          else{
            records.length === 0 && setMobilePageOff(false);
            setOriginalPesticideData([...originalPesticideData,...records ]);
          }
          if(isReload){
            setReloadGrid(false);
            setTimeout(() => {
              setReloadGrid(true);
            }, 100);
          }          
        }
      }
    );
  } 
  const getNextpagecount = (e) =>{
    // setLoader(true);
    // setIsPesticideGet(true);
    getEPURDataForAGScientist(e.selected + 1)
    setPageNumber(e.selected+1);
    // setSearchURL(`pesticides/e-pur?cropyear=${cropYear}&limit=${limit}&page=${e.selected+1}&isfinalise=0&showtotal=1`);
  }
  const [mobilePageNo, setMobilePageNo] = useState(1);
  const [mobilePageOff, setMobilePageOff] = useState(true);
  const loadMoreData = () =>{    
    getEPURDataForAGScientist(mobilePageNo+1,false,true);
    setMobilePageNo(mobilePageNo+1);
  }
  useEffect(() => {
    fetchContract();
  }, [props.GBSContracts]);
  useEffect(() => {
    getPesticideePur();
  }, [props.ePURsRawData, ]);

  useEffect(() => {
    setdefContracts([defaultContract]);
    setCN_MultiValue({ contractid: defaultContract });
  }, [defaultContract]);
  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  // Fetching Contracts
  const fetchContract = () => {
    let contracts = props.GBSContracts.filter(i => [1000, 1001].includes(i.ContractStatus))
    if (contracts.length === 1) {
      const getcurrentcrop =
        contracts.length === 1
          ? contracts[0].RelationshipId
          : 0;
      setDefaultContract(getcurrentcrop.toString());
      if (getcurrentcrop !== 0) {
        getPesticideByCropYearAndRegion(contracts[0].Region);
        getBogOrSection(contracts[0].RelationshipId);
      }
    }
    setContracts(contracts);
    setContractNumbers(
      setOptions(
        contracts,
        "RelationshipId",
        false,
        "RelationshipNumber",
        "Name"
      )
    );
  };

  const handlecontractFilterChange = (selectedList, selectedItem) => {
    //Clearing fields EPA,  bog/section and Bed
    typeheadRef.current?.clear();
    setSingleSelections([]);
    setFieldReset(false);
    setFieldReset(false);
    setHarvestBed([]);
    setTimeout(() => {
      setFieldReset(true);
      setFieldBedReset(true);
    }, 0);
    setBedTableData([]);

    let item = contracts.filter((i) => i.RelationshipId === selectedItem.id);
    item.length > 0 && getPesticideByCropYearAndRegion(item[0].Region);
    getBogOrSection(selectedItem.id);
  };
  const handlerOtherFilter = () => {
    setDisabled(false);
  };

  const getPesticideByCropYearAndRegion = (
    selectedContractRegion,
    isUpdate = false,
    PesticideId
  ) => {
    commonAjaxCall(
      `pesticides/${cropYear}/region/${selectedContractRegion}`,
      "get",
      {},
      (resp) => {
        if (resp.data.length > 0) {
          // {option.Code}-{option.Name}
          let data = resp.data?.map((option) => {
            option.CodeName = `${option.Code}-${option.Name}`;
            return option;
          });
          setTypeAheadOptions(data);
          if (isUpdate) {
            let filter = data.filter(
              (item) => item.PesticideId === PesticideId
            );
            setSingleSelections(filter);
          }
        } else {
          setTypeAheadOptions([]);
        }

        setLoader(false);
      }
    );
  };

  const getRateUnitMeasurePerAcre = () => {
    commonAjaxCall(`pesticides/rate-uoms`, "get", {}, (resp) => {
      if (resp.data.length > 0) {
        setRateUnitMeasurePerAcre(
          setOptions(resp.data, "Id", false, "Description").sort((a, b) =>
            a.Id - b.Id
          )
        );
      } else {
        setRateUnitMeasurePerAcre([]);
      }
      setLoader(false);
    });
  };

  const getPesticideUseMethods = () => {
    commonAjaxCall(`pesticides/use-methods`, "get", {}, (resp) => {
      if (resp.data.length > 0) {
        setPesticideUseMethods(
          setOptions(resp.data, "Id", false, "MethodName").sort((a, b) =>
            a.name?.localeCompare(b?.name)
          )
        );
      } else {
        setRateUnitMeasurePerAcre([]);
      }
      setLoader(false);
    });
  };

  const getPesticideUseTypes = () => {
    commonAjaxCall(`pesticides/use-types`, "get", {}, (resp) => {
      if (resp.data.length > 0) {
        setPesticideUseTypes(
          setOptions(resp.data, "Id", false, "TypeName").sort((a, b) =>
            a.name?.localeCompare(b?.name)
          )
        );
      } else {
        setPesticideUseTypes([]);
      }
      setLoader(false);
    });
  };

  const getBogOrSection = (contractId) => {
    commonAjaxCall(
      `bed-histories?cropyear=${cropYear}&relationshipid=${contractId}&includesplitbeds=1`,
      "get",
      {},
      (resp) => {
        if (resp.data.length > 0) {
          setBedHistory(resp.data);
          setBogOrSection(
            setOptions(resp.data, "BogName", true).sort((a, b) =>
              a.name?.localeCompare(b?.name)
            )
          );

          setExhibitAcres(
            setOptions(resp.data, "ExhibitAcres", true)?.sort((a, b) =>
              a.name.toString()?.localeCompare(b?.name.toString())
            )
          );
          let data = resp.data?.sort((a, b) =>
            a.HarvestBed?.toString()?.localeCompare(b?.HarvestBed?.toString())
          );
          setBedId(
            setOptions(data, "BedId", true)
          );
        } else {
          setBogOrSection([]);
        }
        setLoader(false);
      }
    );
  };

  const bedSort = (data = []) =>{
    return data?.sort((a, b) =>
      a.HarvestBed?.toString()?.localeCompare(b?.HarvestBed?.toString())
    );
  }
  //Set Pesticide Data
  const getPesticideePur = (AGScientistData = null) => {
    let data = (AGScientistData || props.ePURsRawData).filter(
      (a) => a.IsFinalise === false || a.IsFinalise === null
    );
    props.roleAgScientist ? setOriginalPesticideData(data) : setOriginalPesticideData(_.orderBy(data, ["AppliedDate"], ["asc"]));
    let PesticideArray = [];
    data?.map((item, index) => {
      if (item.Beds?.length > 0) {
        item.Beds = bedSort(item.Beds);
        item.Beds.map((i, k) => {
          let beds = item.Beds?.map((i) => i.HarvestBed).filter(i=>i !== null);
          let BedList = beds.length > 5 ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ")?.trim()+ '...' : beds?.toString()?.replace(/,/g, ", ")?.trim();
          let HarvestBedList = BedList; //beds.length > 5 ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ") !==""  ? beds.splice(0, 5)?.toString()?.replace(/,/g, ", ")+ '...' : "" : item.Beds?.map((i) => i.HarvestBed).filter(i=>i !== null)?.toString()?.replace(/,/g, ", ")?.trim();

          let obj = {
            Id: item.Id,
            RelationshipId: item.RelationshipId,
            RelationshipNumber: item.RelationshipNumber,
            Name: item.Name,
            CropYear: item.CropYear,
            AppliedDate: item.AppliedDate,
            PesticideId: item.PesticideId,
            PesticideCode: item.PesticideCode,
            PesticideName: item.PesticideName,
            PesticideCodeName: `${item.PesticideCode}-${item.PesticideName}`,
            Rate: item.Rate,
            RateUomId: item.RateUomId,
            Description: item.Description,
            MethodId: item.MethodId,
            MethodName: item.MethodName,
            TypeId: item.TypeId,
            TypeName: item.TypeName,
            IsFinalise: item.IsFinalise,
            HarvestBedList: HarvestBedList,
            GroupingKey: `${index}_${item.RelationshipNumber}`,
            ContractName: `${item.RelationshipNumber} - ${item.Name}`,
            PesticideUseBedId: i.PesticideUseBedId,
            ExhibitAcres: i.ExhibitAcres,
            AcresTreated: i.AcresTreated,
            BogName: i.BogName,
            BedId: i.BedId,
            HarvestBed: i.HarvestBed,
            UniqueID: `${index}_${k}_${item.RelationshipNumber}`,
          };
          PesticideArray.push(obj);
        });
      }
    });
    PesticideArray = props.roleAgScientist ?  PesticideArray : _.orderBy(PesticideArray, ["AppliedDate"], ["desc"]);
    setGridItems(PesticideArray);
  };

  // Bog or section
  const bogSectionChangeEvent = (selectedList) => {
    const bogSection = setObjToString(selectedList);
    const bedData = bedHistory.filter((i) => {
      return bogSection.indexOf(i.BogName) > -1;
    });

    setFieldBedReset(false);
    setBedTableData([]);
    setTimeout(() => {
      setFieldBedReset(true);
      setHarvestBed(setOptions(bedData, "HarvestBed", true)?.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      })
      );
      // setHarvestBed(
      //   setOptions(bedData, "HarvestBed", true)?.sort((a, b) => a.name - b.name)
      // );
    }, 0);
  };
  const bogSectionRemoveEvent = () => {
    setFieldBedReset(false);
    setTimeout(() => {
      setFieldBedReset(true);
      setHarvestBed([]);
      setBedTableData([]);
    }, 0);
  };

  //Bed field
  const bogBedFilter = (selectedList, removedItem, isRemoved) => {
    if (selectedList.length === 0 || (removedItem.id === "all" && isRemoved)) {
      setBedTableData([]);
    } else {
      setBedTableData([]);
      setTimeout(() => {
        const bogBed = setObjToString(selectedList);
        let bedData = bedHistory.filter((i) => {
          return (
            bogBed.split(",").indexOf(i.HarvestBed) > -1 &&
            multiValue?.bogOrSection?.indexOf(i.BogName) > -1
          );
        });
        let groupedArray = _.groupBy(bedData, "HarvestBed");
        bedData = Object.keys(groupedArray).map((i) => {
          let count = _.sumBy(groupedArray[i], "ExhibitAcres");
          return {
            id: i,
            BedId: groupedArray[i][0].BedId,
            isValid: true,
            HarvestBed: groupedArray[i][0].HarvestBed,
            ExhibitAcres: countDecimals(count) > 2 ? count.toFixed(2) : count,
            EnteredAcres: countDecimals(count) > 2 ? count.toFixed(2) : count,
          };
        });
        setBedTableData(bedData);
      }, 0);
    }
  };

  const validationMaxCheck = (val, item) => {
    let index = bedTableData.findIndex((x) => x.id === item.id);
    try {
      if (parseFloat(val) > item.ExhibitAcres) {
        bedTableData.splice(index, 1, {
          ...item,
          EnteredAcres: parseFloat(val),
          isValid: false,
        });
      } else {
        bedTableData.splice(index, 1, {
          ...item,
          EnteredAcres: parseFloat(val),
          isValid: true,
        });
      }
      setBedTableData([...bedTableData]);
      setTimeout(() => {
        checkAllTreatedAcres();
      }, 0);
    } catch (e) {
      console.log(e);
    }
  };
  const validataeRecordWithTable = (submittedData) => {
    const sameData = gridItems.filter((item) => {
      return (
        item.RelationshipId.toString() ===
        submittedData.relationship_id.toString() &&
        item.PesticideId.toString() === submittedData.pesticide_id.toString() &&
        parseFloat(item.Rate).toString() ===
        parseFloat(submittedData.rate).toString() &&
        item.MethodId.toString() === submittedData.method_id.toString() &&
        item.TypeId.toString() === submittedData.type_id.toString() &&
        item.RateUomId.toString() === submittedData.rate_uom_id.toString() &&
        moment(item.AppliedDate).isSame(submittedData.date_applied)
      );
    });
    return sameData.length > 0 ? false : true;
  };
  // Pesticide Save Action
  const onSave = () => {
    let bedData = bedTableData?.map((i) => {
      return { bed_id: i.BedId, acres_treated: i.EnteredAcres };
    });
    let request = {
      relationship_id: CN_MultiValue.contractid,
      crop_year: cropYear,
      date_applied: moment(applicationDate).format(dateFormate),
      pesticide_id: singleSelections[0]?.PesticideId,
      rate: rateFormulatedProductPerAcre,
      method_id: multiValue.pesticideUseMethods,
      type_id: multiValue.pesticideUseTypes,
      rate_uom_id: multiValue.rateUnitMeasurePerAcre,
      data: bedData,
    };
    if (!validataeRecordWithTable(request)) {
      setShowModal({
        isOpen: true,
        message: moreLangs[i18n.resolvedLanguage].ePUR.duplicateErrorMessage,
        type: "validation",
        deleteType: "",
        mode: "",
      });
    } else {
      commonAjaxCall(`pesticides/e-pur`, "post", request, (resp) => {
        if (resp?.data?.status === "success") {
          setLoader(false);
          props.roleAgScientist ? getEPURDataForAGScientist(1,true) : props.getEPURsPesticideData();
          props.setISDataAdded(true);
          setShowModal({
            isOpen: true,
            message: "Your Application has been saved successfully",
          });
          onClearSearch();
        }
      });
    }
  };

  const onClearSearch = () => {
    typeheadRef.current?.clear();
    // setMultiValue(null);
    // setCN_MultiValue(null);
    setRateFormulatedProductPerAcre("");
    setApplicationDate("");
    // datepickerRef.current?.clear();
    setSingleSelections([]);
    setFieldReset(false);
    setFieldBedReset(false);
    setResetAll(false);
    setHarvestBed([]);
    setTimeout(() => {
      setResetAll(true);
      setFieldReset(true);
      setFieldBedReset(true);
    }, 0);
    setBedTableData([]);
  };
  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  // creating option for mulit select
  const setOptions = (
    data,
    keyPrram,
    unique,
    concatValue1 = "",
    concatValue2 = ""
  ) => {
    if (unique && keyPrram !== "RelationshipId" && concatValue1 === "") {
      return data
        .map((li) => li[keyPrram])
        .filter(onlyUnique)
        .map((li) => {
          return { name: li, id: li };
        });
    } else {
      let contracts = [];
      if (concatValue1 !== "" && concatValue2 !== "") {
        contracts = data.map((li) => {
          return concatValue1 && concatValue2
            ? {
              name: li[concatValue1] + " " + li[concatValue2],
              id: li[keyPrram],
            }
            : { name: li[keyPrram], id: li[keyPrram] };
        });
      } else if (concatValue1 !== "") {
        contracts = data.map((li) => {
          return { name: li[concatValue1], id: li[keyPrram] };
        });
      }
      return [...new Map(contracts.map((item) => [item["id"], item])).values()];
    }
  };

  const commonAjaxCall = async (
    url = "",
    method = "GET",
    data = {},
    successMethod
  ) => {
    // return;
    setLoader(true);
    await gbsDataCommonMethod(tokenId, accounts, url, method, data)
      .then(successMethod)
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const decimalConverter = (val) => {
    let newVal =
      val.indexOf(".") >= 0
        ? val.substr(0, val.indexOf(".")) + val.substr(val.indexOf("."), 3)
        : val.indexOf("-") >= 0
          ? ""
          : val;
    return newVal;
  };

  const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  };

  const checkAllTreatedAcres = () => {
    let count = 0;
    bedTableData.map((i) => {
      i.EnteredAcres > i.ExhibitAcres && count++;
    });
    count > 0 ? setDisableSubmitCancel(true) : setDisableSubmitCancel(false);
  };

  const onModalHide = () => {
    if (showModal.type !== "validation") onClearSearch();
    setShowModal({ isOpen: false, message: "" });
    setUpdateModal({ isOpen: false, message: "" });
    setDuplicateError(false);
  };

  // useEffect(()=>{
  //   const input = document.querySelectorAll('#applicationDate')[0];    
  //   dateInputMask(input);
  // },[datepicker, datepicker_1])
  const handleSearch = (query) => {    
    if(/^\d+$/.test(query)){
      setIsLoading(true);
      gbsDataCommonMethod(tokenId, accounts, `/contracts?r.relationshipnumber=${query}`, "get",{})
      .then((res)=>{        
       let data = res.data.filter((a) => {
          return [1000, 1001].includes(a.ContractStatus);
        });
        data = res.data.map((i)=>{
          i.ContractRelationshipNumber = `${i.RelationshipNumber} ${i.Name || ''} ${i.Name2 || ''} ${i.Name3 || ''}`;
          return i;
        })
        setContractOptions(data);
        setIsLoading(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
    }    
  };
  const contractTypehead = (data) =>{
    if(data.length ===1){
      setCN_MultiValue({contractid:data[0].RelationshipId});
      getPesticideByCropYearAndRegion(data[0].Region);
      getBogOrSection(data[0].RelationshipId);
    }    
  }
  useEffect(()=>{
    if(props.isDataAdded){
      props.setISDataAdded(false);
      if(props.roleAgScientist){
        getEPURDataForAGScientist();
      }
      else{
        props.getEPURsPesticideData();
      }
    }
  },[props]);
  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="card-shadow white_bg border-radius-6 mt-4 mt-lg-0">
        <h4 className="card-header white_color ver-space-15 left-border-radius right-border-radius pb-4 px-1 px-lg-3 pb-lg-3">
          <span className="d-block d-sm-inline-block">
            {
              moreLangs[i18n.resolvedLanguage].ePUR
                .enterEditPesticideApplication
            }
          </span>{" "}
          <span className="float-start float-sm-end text-14 d-block">
            Crop Year {cropYear}
          </span>
        </h4>
        <div className="card-body mb-4 pb-0 enter_edit_app p-1">
          <div className="row mt-4 mt-lg-4">
            <div className="">
              {/* <!-- Personal info starts --> */}
              <div className="row">
                {!props.roleAgScientist &&
                  <div className="col-12 col-lg-6">
                    <div className="contact_number_Label custom_dropdown mb-2 mb-lg-3 os_contract_number_Label ">
                      {resetAll && (
                        <MultiDropDown
                          optionList={contractNumbers}
                          prompt="Select"
                          value={CN_MultiValue}
                          onChange={(selectedList, selectedItem) => {
                            handlecontractFilterChange(
                              selectedList,
                              selectedItem
                            );
                          }}
                          label="contractid"
                          usestate={setCN_MultiValue}
                          disabled={disabled}
                          onDelete={(selectedList, removedItem) =>
                            handlecontractFilterChange(selectedList, removedItem)
                          }
                          singleSelect={true}
                          preSelectedOpt={defContracts}
                          optionNotFound={{ contractid: noContractsFound }}
                        />
                      )}
                    </div>
                  </div>
                }
                {props.roleAgScientist && <div className="col-12 col-lg-6 personal_info">
                  {/* {console.log("props.GBSContracts",props.GBSContracts,CN_MultiValueTypeHead)} */}
                  <div className="position-relative mb-2 mb-lg-3 mb-lg-0">
                    <span className="text-14 z-index-1 mandatoryField ps-3">
                      Contract Number & Name
                    </span>
                    {/* {fieldReset && */}
                    <AsyncTypeahead
                      ref={asyncTypeaheadRef}
                      id="async-example"
                      isLoading={isLoading}
                      labelKey="ContractRelationshipNumber"
                      minLength={3}
                      onSearch={handleSearch}
                      options={contractOptions}
                      placeholder="Search contract number"
                      // filterBy={["RelationshipNumber", "Name","Name2","Name3"]}
                      className="AG_contracts"
                      onChange={contractTypehead}
                      >
                      {({ onClear, selected }) =>
                        !!selected.length && (
                          <div className="rbt-aux">
                            <ClearButton
                              onClick={() => {
                                onClear();
                                setCN_MultiValue({contractid:""});
                              }}
                            />
                          </div>
                        )
                      }
                      </AsyncTypeahead>
                    {/* } */}
                    {/* <input type="text" aria-label="epa-pcp" className="form-control mb-3" /> */}
                  </div>
                </div>
                }
                <div className="col-12 col-lg-6">
                  <div className="position-relative mb-2 mb-lg-3 mb-lg-0 datepicker-field">
                    <span className="lable-text text-14 z-index-1 mandatoryField  ps-3">
                      Application Date
                    </span>
                    <DatePickerComp
                      ref={datepicker}
                      id="applicationDate"
                      selected={applicationDate}
                      onChange={(date) => setApplicationDate(date)}
                      placeholderText={dateFormate}
                      className="datepicker_input form-control z-index-1 Testing-Data"
                      disabled={disabled}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- Personal info ends --> */}

              {/* <!-- Phone number starts --> */}
              <div className="row">
                <div className="col-12 col-lg-6 personal_info">
                  <div className="position-relative mb-2 mb-lg-3 mb-lg-0">
                    <span className="text-14 z-index-1 mandatoryField ps-3">
                      EPA or PCP REG.#/CHEMICAL
                    </span>
                    {/* {fieldReset && */}
                    <Typeahead
                      ref={typeheadRef}
                      id="basic-typeahead-single"
                      labelKey="CodeName"
                      onChange={setSingleSelections}
                      options={typeAheadOptions}
                      selected={singleSelections}
                      minLength={1}
                      filterBy={["Name", "Code"]}
                      className=""
                    />
                    {/* } */}
                    {/* <input type="text" aria-label="epa-pcp" className="form-control mb-3" /> */}
                  </div>
                </div>
                <div className="col-12 col-lg-6 personal_info">
                  <div className="position-relative mb-2 mb-lg-0">
                    <span className="text-14 mandatoryField  ps-3">
                      RATE (Formulated product per acre)
                    </span>
                    <input
                      step=".01"
                      onChange={(e) =>
                        setRateFormulatedProductPerAcre(
                          decimalConverter(e.target.value)
                        )
                      }
                      value={rateFormulatedProductPerAcre}
                      type="number"
                      name="rateFormulatedProductPerAcre"
                      aria-label="rate"
                      className="form-control mb-2 mb-lg-3"
                      disabled=""
                    />
                  </div>
                </div>
              </div>
              {/* <!-- Phone number ends --> */}

              {/* <!-- Select contract/Farm name --> */}
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="rate_unit_per_acre custom_dropdown mb-2 mb-lg-3 os_contract_number_Label ">
                    {resetAll && (
                      <MultiDropDown
                        optionList={rateUnitMeasurePerAcre}
                        prompt="Select"
                        value={multiValue}
                        onChange={(selectedList, selectedItem) =>
                          handlerOtherFilter(selectedList, selectedItem)
                        }
                        label="rateUnitMeasurePerAcre"
                        usestate={setMultiValue}
                        disabled={disabled}
                        onDelete={(selectedList, removedItem) =>
                          handlerOtherFilter(selectedList, removedItem)
                        }
                        singleSelect={true}
                        optionNotFound={{ rateUnitMeasurePerAcre: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="method_of_application custom_dropdown mb-2 mb-lg-3 os_contract_number_Label ">
                    {resetAll && (
                      <MultiDropDown
                        optionList={pesticideUseMethods}
                        prompt="Select"
                        value={multiValue}
                        onChange={(selectedList, selectedItem) =>
                          handlerOtherFilter(selectedList, selectedItem)
                        }
                        label="pesticideUseMethods"
                        usestate={setMultiValue}
                        disabled={disabled}
                        onDelete={(selectedList, removedItem) =>
                          handlerOtherFilter(selectedList, removedItem)
                        }
                        singleSelect={true}
                        optionNotFound={{ pesticideUseMethods: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- Select contract/Farm name ends --> */}

              {/* <!-- Select contract/Farm name --> */}
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="fruit_type custom_dropdown mb-2 mb-lg-3 os_contract_number_Label ">
                    {resetAll && (
                      <MultiDropDown
                        optionList={pesticideUseTypes}
                        prompt="Select"
                        value={multiValue}
                        onChange={(selectedList, selectedItem) =>
                          handlerOtherFilter(selectedList, selectedItem)
                        }
                        label="pesticideUseTypes"
                        usestate={setMultiValue}
                        disabled={disabled}
                        onDelete={(selectedList, removedItem) =>
                          handlerOtherFilter(selectedList, removedItem)
                        }
                        singleSelect={true}
                        optionNotFound={{ pesticideUseTypes: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="bog_section custom_dropdown mb-2 mb-lg-3 os_contract_number_Label ">
                    {fieldReset && (
                      <MultiDropDown
                        optionList={bogOrSection}
                        prompt="Select"
                        value={multiValue}
                        onChange={(selectedList, selectedItem) => {
                          handlerOtherFilter(selectedList, selectedItem);
                          bogSectionChangeEvent(selectedList, selectedItem);
                        }}
                        label="bogOrSection"
                        usestate={setMultiValue}
                        disabled={disabled}
                        onDelete={(selectedList, removedItem) => {
                          bogSectionRemoveEvent(selectedList, removedItem);
                          handlerOtherFilter(selectedList, removedItem);
                        }}
                        optionNotFound={{ bogOrSection: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- Select contract/Farm name ends --> */}
              {HarvestBed.length > 0 && (
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="bed_label custom_dropdown mb-2 mb-lg-3 onchange_bed_label os_contract_number_Label ">
                      {fieldBedReset && (
                        <MultiDropDown
                          optionList={HarvestBed}
                          prompt="Select"
                          value={multiValue}
                          onChange={(selectedList, selectedItem) =>
                            bogBedFilter(selectedList, selectedItem, false)
                          }
                          label="bogBed"
                          usestate={setMultiValue}
                          disabled={disabled}
                          onDelete={(selectedList, removedItem) =>
                            bogBedFilter(selectedList, removedItem, true)
                          }
                          optionNotFound={{ bogBed: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {bedTableData.length > 0 && (
                <div className="personal_info hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 inserted_table p-0">
                  <div className="table-responsive">
                    <table className="table table-striped grid_block mb-0">
                      <thead>
                        <tr className="blue_bg">
                          <th>Bed</th>
                          <th>Acres</th>
                          <th>Acres Treated</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bedTableData.map((i, k) => (
                          <tr key={k}>
                            <td className="w-15">{i.HarvestBed}</td>
                            <td className="w-15">{i.ExhibitAcres}</td>
                            <td>
                              <input
                                className="d-inline-block form-control"
                                step={0.01}
                                onChange={(e) =>
                                  validationMaxCheck(e.target.value, i)
                                }
                                max={i.ExhibitAcres}
                                type="number"
                                defaultValue={i.EnteredAcres}
                              />
                              {!i.isValid && (
                                <span className="numberError">
                                  Acres treated can not exceed total bed acres
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              <div className="horizontal-line my-4"></div>

              <div className="overflow-hidden mb-4">
                <div className="allFieldRequired pe-1">
                  <span className="red_star_label position-relative"></span>All
                  fields required
                </div>
                
                <button
                  className="btn btn-default text-16 float-end me-3 white_bg card-shadow border-1"
                  onClick={() => onClearSearch()}
                // disabled={disableSubmitCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary text-16 float-end px-4 me-3"
                  onClick={onSave}
                  disabled={
                    CN_MultiValue?.contractid === "" ||
                    applicationDate === "" ||
                    singleSelections.length == 0 ||
                    rateFormulatedProductPerAcre === "" ||
                    Object.keys(multiValue).length !== 5 ||
                    disableSubmitCancel
                  }
                >
                  Save{!loading ? "" : <WhiteCircleLoader />}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Desktop Grid */}
      {!displayAccordian && reloadGrid && 
        <div
          className={`visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block d-none d-lg-block`}
        >
          <DataGridGroupingPagination
            uniqueIdentifier="UniqueID"
            gridData={gridItems}
            columns={columns}
            isPagination={true}
            itemsPerPage={10}
            columnResize={false}
            classNames="contain-block-size-reset bot-mspace-20 border-radius-6 acreage_grid_bg_white edit-epur-table"
            groupByKey={["GroupingKey"]}
            getExpandedGroupIds={expandedGroupIds}
            setExpandedGroupIds={setExpandedGroupIds}
            refReactDataGrid={refReactDataGrid}
            autoRowHeight={true}
            dynamicHeaderHeight={80}
            paddingRowHeight={-10}
            totalRecordCount = {totalPageCount}
            getNextpage={getNextpagecount}
          />
        </div>
      }
      {/* Mobile Accordion */}
      {originalPesticideData.length > 0 && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 desktop-accordion-table ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
        >
          <div className="e-purEdit delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion>
              {originalPesticideData.length > 0 &&
                Object.values(originalPesticideData).map((data, index) => {
                  return (
                    <Fragment key={data.Id}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          <div>
                            Application Date:{" "}
                            {
                              dateFormat(data.AppliedDate)
                              // <Moment format={dateFormate}>
                              //   {data.AppliedDate}
                              // </Moment>
                            }
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div className="td" data-header="expand_icon">
                                <i className="fa fa-plus-circle cursor_pointer table_accordion"></i>
                              </div>
                              <div className="td" data-header="Contract #:">
                                <span>
                                  {data.RelationshipNumber} - {data.Name}
                                </span>
                              </div>
                              <div
                                className="td"
                                data-header="Application Date:"
                              >
                                <span>
                                  {
                                    dateFormat(data.AppliedDate)
                                    // <Moment format={dateFormate}>
                                    //   {data.AppliedDate}
                                    // </Moment>
                                  }
                                </span>
                              </div>
                              <div
                                className="td"
                                data-header="EPA or PCP Reg.#/Chemical:"
                              >
                                <span>
                                  {data.PesticideCode}-{data.PesticideName}
                                </span>
                              </div>
                              <div
                                className="td"
                                data-header="Rate(formulated product per acre):"
                              >
                                <span>{data.Rate}</span>
                              </div>
                              <div
                                className="td"
                                data-header="Rate unit of measure per acres(e.g. oz/acre):"
                              >
                                <span>{data.Description}</span>
                              </div>
                              <div
                                className="td"
                                data-header="Method of Application:"
                              >
                                <span>{data.MethodName}</span>
                              </div>
                              <div className="td" data-header="Fruit Type:">
                                <span>{data.TypeName}</span>
                              </div>
                              {/* <div className="td epurs_nested_table cursor_pointer" data-header="Bed(s):"> */}
                              {/* <span>{data.Beds?.map(i => i.HarvestBed).toString().replace(/,/g, ", ")}<span className="icon_change active"></span> </span> */}
                              <Accordion
                                defaultActiveKey="0"
                                className="ePURs-BedAccordian"
                              >
                                <Accordion.Item eventKey={1}>
                                  <Accordion.Header>
                                    <div
                                      className="td epurs_nested_table cursor_pointer"
                                      data-header="Bed(s):"
                                    >
                                      <span>
                                        {data.Beds.length > 5 ? data.Beds?.map((i) => i.HarvestBed).splice(0, 5)?.toString()?.replace(/,/g, ", ") + '...' : data.Beds?.map((i) => i.HarvestBed)
                                          ?.toString()
                                          ?.replace(/,/g, ", ")
                                          ?.trim()}
                                      </span>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="td d-block d-lg-none mt-3 p-1 border-radius-6 show">
                                      <div className="tr epurs_border alternate-highlight">
                                        {data.Beds.map((item, index) => (
                                          <div
                                            className="odd_even_bg"
                                            key={index}
                                          >
                                            <div>
                                              <span>Bed(s):</span>
                                              <span className="bed_a1">
                                                {item.HarvestBed}
                                              </span>
                                            </div>
                                            <div>
                                              <span>Treated Acres:</span>
                                              <span className="treated_acre1">
                                                {item.AcresTreated}
                                              </span>
                                            </div>
                                            <div>
                                              <span></span>
                                              <span className="action-items">
                                                <div
                                                  className="action_edit"
                                                  onClick={() =>
                                                    editChildRecordMobile(
                                                      item,
                                                      data
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-pencil blue_color cursor_pointer"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  Edit
                                                </div>
                                                <div
                                                  className="action_delete"
                                                  onClick={() =>
                                                    deleteRow(
                                                      item,
                                                      "child",
                                                      "mobile"
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-trash blue_color cursor_pointer"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  Delete
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                              <div className="td" data-header="Total Acres:">
                                <span>
                                  {_.sumBy(data.Beds, "ExhibitAcres")?.toFixed(
                                    2
                                  )}
                                </span>
                              </div>
                              <div className="td" data-header="Treated Acres:">
                                <span>
                                  {_.sumBy(data.Beds, "AcresTreated")?.toFixed(
                                    2
                                  )}
                                </span>
                              </div>
                              <div className="td" data-header="Action:">
                                <div className="width-100px">
                                  <div
                                    className="mb-2"
                                    onClick={() =>
                                      editRow(data, "master", "mobile")
                                    }
                                  >
                                    <i
                                      className="fa fa-pencil blue_color cursor_pointer mx-1 p-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Edit
                                  </div>
                                  <div
                                    className="mb-2"
                                    onClick={() =>
                                      deleteRow(data, "master", "mobile")
                                    }
                                  >
                                    <i
                                      className="fa fa-trash blue_color cursor_pointer mx-1 p-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Delete
                                  </div>
                                  <div
                                    className="mb-2"
                                    onClick={() => addBed(data, "mobile")}
                                  >
                                    <i
                                      className="fa fa-plus blue_color cursor_pointer mx-1 p-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Add Beds
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
            {/* Load More button for AG Scientist */}
            {props.roleAgScientist && totalPageCount > 10 && mobilePageOff  && <div> <button className="btn btn-primary width-100" onClick={loadMoreData}>Load More</button></div> }
          </div>
        </div>
      )}
      {/* Delete Modal Popup  */}
      {showModal.isOpen && (
        <Modal
          show={showModal.isOpen}
          onHide={onModalHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <h4 className="fw-bold mb-0" id="contained-modal-title-vcenter">
              {showModal.message}
            </h4>
          </Modal.Header>
          <Modal.Footer>
            {showModal.type === "delete" ? (
              <>
                <button
                  className="btn btn-default card-shadow ms-2"
                  onClick={() => onModalHide()}
                  id="ok_button"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary card-shadow ms-2"
                  onClick={() =>
                    showModal.onsubmit(
                      showModal.selectedRow,
                      showModal.deleteType,
                      showModal.mode
                    )
                  }
                  id="ok_button"
                >
                  Delete
                </button>
              </>
            ) : (
              <button
                className="btn btn-default card-shadow ms-2"
                onClick={() => onModalHide()}
                id="ok_button"
              >
                Close
              </button>
            )}
          </Modal.Footer>
        </Modal>
      )}
      {/* Update Modal Popup */}
      {updateModal.isOpen && (
        <Modal
          show={updateModal.isOpen}
          onHide={onModalHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          className="epur-edit-popup"
        >
          <Modal.Header closeButton>
            <h4 className="fw-bold mb-0" id="contained-modal-title-vcenter">
              {updateModal.message}
            </h4>
          </Modal.Header>
          {updateModal.updateType !== "" && (
            <Modal.Body>
              {updateModal.updateType === "master" ? (
                <>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <div className="position-relative mb-2 mb-lg-0 datepicker-field">
                        <span className="text-14 z-index-2 mandatoryField  ps-3">
                          Application Date
                        </span>
                        <DatePickerComp
                          id="applicationDate_1"
                          selected={applicationDate}
                          onChange={(date) => {
                            setApplicationDate(date);
                            updateModal.selectedRow.applicationDate_1 = date;
                          }}
                          placeholderText={dateFormate}
                          className="datepicker_input form-control z-index-1 newimage"
                          disabled={disabled}
                          maxDate={new Date()}
                        />
                        {/* <DatePicker
                          ref={datepicker_1}
                          id="applicationDate_1"
                          selected={applicationDate}
                          onChange={(date) => {
                            setApplicationDate(date);
                            updateModal.selectedRow.applicationDate_1 = date;
                          }}
                          placeholderText={dateFormate}
                          className="datepicker_input form-control z-index-1"
                          disabled={disabled}
                          maxDate={new Date()}
                        /> */}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 personal_info mb-3">
                      <div className="position-relative mb-3 mb-lg-0">
                        <span className="text-14 z-index-2 mandatoryField ps-3">
                          EPA or PCP REG.#/CHEMICAL
                        </span>
                        {/* {fieldReset && */}
                        <Typeahead
                          ref={typeheadRef}
                          id="basic-typeahead-single"
                          labelKey="CodeName"
                          onChange={(data) => {
                            setSingleSelections(data);
                            updateModal.selectedRow.CodeName_1 = data;
                          }}
                          options={typeAheadOptions}
                          selected={singleSelections}
                          minLength={1}
                          filterBy={["Name", "Code"]}
                          className="z-index-1"
                        />
                        {/* } */}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-6 personal_info">
                      <div className="position-relative mb-2 mb-lg-0">
                        <span className="text-14 mandatoryField  ps-3">
                          RATE (Formulated product per acre)
                        </span>
                        <input
                          step=".01"
                          onChange={(e) => {
                            let val = decimalConverter(e.target.value);
                            updateModal.selectedRow.rateFormulatedProductPerAcre_1 =
                              val;
                            setRateFormulatedProductPerAcre(val);
                          }}
                          value={rateFormulatedProductPerAcre}
                          type="number"
                          name="rateFormulatedProductPerAcre"
                          aria-label="rate"
                          className="form-control mb-3"
                          disabled=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="rate_unit_per_acre custom_dropdown mb-3 os_contract_number_Label ">
                        {resetAll && (
                          <MultiDropDown
                            optionList={rateUnitMeasurePerAcre}
                            prompt="Select"
                            value={multiValueUpdate}
                            onChange={(selectedList, selectedItem) => {
                              handlerOtherFilter(selectedList, selectedItem);
                              updateModal.selectedRow.rateUnitMeasurePerAcre_1 =
                                selectedItem.id;
                            }}
                            label="rateUnitMeasurePerAcre_1"
                            usestate={setMultiValueUpdate}
                            disabled={disabled}
                            onDelete={(selectedList, removedItem) => {
                              handlerOtherFilter(selectedList, removedItem);
                              updateModal.selectedRow.rateUnitMeasurePerAcre_1 =
                                "";
                            }}
                            singleSelect={true}
                            preSelectedOpt={[updateModal.selectedRow.RateUomId]}
                            optionNotFound={{ rateUnitMeasurePerAcre_1: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="method_of_application custom_dropdown mb-3 os_contract_number_Label ">
                        {resetAll && (
                          <MultiDropDown
                            optionList={pesticideUseMethods}
                            prompt="Select"
                            value={multiValueUpdate}
                            onChange={(selectedList, selectedItem) => {
                              handlerOtherFilter(selectedList, selectedItem);
                              updateModal.selectedRow.pesticideUseMethods_1 =
                                selectedItem.id;
                            }}
                            label="pesticideUseMethods_1"
                            usestate={setMultiValueUpdate}
                            disabled={disabled}
                            onDelete={(selectedList, removedItem) => {
                              handlerOtherFilter(selectedList, removedItem);
                              updateModal.selectedRow.pesticideUseMethods_1 =
                                "";
                            }}
                            singleSelect={true}
                            preSelectedOpt={[updateModal.selectedRow.MethodId]}
                            optionNotFound={{ pesticideUseMethods_1: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="fruit_type custom_dropdown mb-3 os_contract_number_Label ">
                        {resetAll && (
                          <MultiDropDown
                            optionList={pesticideUseTypes}
                            prompt="Select"
                            value={multiValueUpdate}
                            onChange={(selectedList, selectedItem) => {
                              handlerOtherFilter(selectedList, selectedItem);
                              updateModal.selectedRow.pesticideUseTypes_1 =
                                selectedItem.id;
                            }}
                            label="pesticideUseTypes_1"
                            usestate={setMultiValueUpdate}
                            disabled={disabled}
                            onDelete={(selectedList, removedItem) => {
                              handlerOtherFilter(selectedList, removedItem);
                              updateModal.selectedRow.pesticideUseTypes_1 = "";
                            }}
                            singleSelect={true}
                            preSelectedOpt={[updateModal.selectedRow.TypeId]}
                            optionNotFound={{ pesticideUseTypes_1: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : updateModal.updateType === "child" ? (
                <>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">Application Date </label>:{" "}
                      {dateFormat(updateModal.selectedRow.AppliedDate)}
                      {/* {moment(updateModal.selectedRow.AppliedDate).format(
                        dateFormate
                      )} */}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      {" "}
                      <label className="fw-bold">
                        EPA OR PCP REG.#/CHEMICAL{" "}
                      </label>
                      : {updateModal.selectedRow.PesticideCodeName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">
                        RATE (FORMULATED PRODUCT PER ACRE)
                      </label>{" "}
                      : {updateModal.selectedRow.Rate}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">
                        RATE Unit of measures per acres(E.G OZ/Acre){" "}
                      </label>
                      : {updateModal.selectedRow.Description}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold"> Method of Application </label>
                      : {updateModal.selectedRow.MethodName}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">Fruit Type</label>:
                      {updateModal.selectedRow.TypeName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="personal_info hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 inserted_table p-m-0 enter_edit_app-mobile">
                      <div className="table-responsive">
                        <table className="table table-striped grid_block mb-0">
                          <thead>
                            <tr className="blue_bg">
                              <th>Bed</th>
                              <th>Acres</th>
                              <th>Acres Treated</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="mobile-width">
                                <div
                                  className={`bed_label custom_dropdown mb-3 onchange_bed_label disable_remove table-multiselect`}
                                >
                                  {updateModal.selectedRow
                                    .isFilterDataAvailable ? (
                                    resetAll && (
                                      <MultiDropDown
                                        optionList={filterBedTableData}
                                        prompt="Select"
                                        value={multiValueUpdate}
                                        onChange={(
                                          selectedList,
                                          selectedItem
                                        ) => {
                                          updateBedChange(
                                            selectedList,
                                            selectedItem,
                                            updateModal.selectedRow
                                          );
                                          updateModal.selectedRow.pesticideUseMethods_1 =
                                            selectedItem.id;
                                        }}
                                        label="pesticideUseMethods_1"
                                        usestate={setMultiValueUpdate}
                                        disabled={disabled}
                                        onDelete={(
                                          selectedList,
                                          removedItem
                                        ) => {
                                          updateBedChange(
                                            selectedList,
                                            removedItem,
                                            updateModal.selectedRow
                                          );
                                          updateModal.selectedRow.pesticideUseMethods_1 =
                                            "";
                                        }}
                                        singleSelect={true}
                                        preSelectedOpt={[
                                          updateModal.selectedRow.HarvestBed,
                                        ]}
                                      />
                                    )
                                  ) : (
                                    <>{updateModal.selectedRow.HarvestBed}</>
                                  )}
                                </div>
                              </td>
                              <td className="w-15">
                                {updateModal.selectedRow.ExhibitAcres_1}
                              </td>
                              <td className="w-15">
                                <input
                                  className="d-inline-block form-control"
                                  step={0.01}
                                  onChange={(e) => {
                                    aceresTreatedEditChange(
                                      e.target.value,
                                      updateModal.selectedRow
                                    );
                                  }}
                                  max={updateModal.selectedRow.ExhibitAcres_1}
                                  type="number"
                                  value={updateModal.selectedRow.AcresTreated_1}
                                />
                                {!updateModal.selectedRow.isValid && (
                                  <span className="numberError">
                                    Acres treated can not exceed total bed acres
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : updateModal.updateType === "add" ? (
                <>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">Application Date </label>:{" "}
                      {dateFormat(updateModal.selectedRow.AppliedDate)}
                      {/* {moment(updateModal.selectedRow.AppliedDate).format(
                        dateFormate
                      )} */}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      {" "}
                      <label className="fw-bold">
                        EPA OR PCP REG.#/CHEMICAL{" "}
                      </label>
                      : {updateModal.selectedRow.PesticideName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">
                        RATE (FORMULATED PRODUCT PER ACRE)
                      </label>{" "}
                      : {updateModal.selectedRow.Rate}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">
                        RATE Unit of measures per acres(E.G OZ/Acre){" "}
                      </label>
                      : {updateModal.selectedRow.Description}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold"> Method of Application </label>
                      : {updateModal.selectedRow.MethodName}
                    </div>
                    <div className="col-12 col-lg-6 mb-3">
                      <label className="fw-bold">Fruit Type</label>:
                      {updateModal.selectedRow.TypeName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3">
                      {updateModal.selectedRow.isAditionalBedAvailabel ? (
                        <div className="bed_label custom_dropdown mb-3 onchange_bed_label ">
                          {fieldBedReset && (
                            <MultiDropDown
                              optionList={aditionalHarvestBed}
                              prompt="Select"
                              value={multiValue}
                              onChange={(selectedList, selectedItem) =>
                                aditionalBogBedFilter(
                                  selectedList,
                                  selectedItem,
                                  false
                                )
                              }
                              label="bogBed"
                              usestate={setMultiValue}
                              disabled={disabled}
                              onDelete={(selectedList, removedItem) =>
                                aditionalBogBedFilter(
                                  selectedList,
                                  removedItem,
                                  true
                                )
                              }
                              optionNotFound={{ bogBed: moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound }}
                            />
                          )}
                        </div>
                      ) : (
                        <p className="no-record-to-add">
                          <i className="fa fa-info-circle pe-2"></i>All beds
                          already added
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {updateModal.selectedRow.aditionalBedTableData.length >
                      0 && (
                        <div className="personal_info hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 inserted_table enter_edit_app p-m-0">
                          <div className="table-responsive">
                            <table className="table table-striped grid_block mb-0">
                              <thead>
                                <tr className="blue_bg">
                                  <th>Bed</th>
                                  <th>Acres</th>
                                  <th>Acres Treated</th>
                                </tr>
                              </thead>
                              <tbody>
                                {updateModal.selectedRow.aditionalBedTableData.map(
                                  (i, k) => (
                                    <tr key={k}>
                                      <td className="w-15">{i.HarvestBed}</td>
                                      <td className="w-15">{i.ExhibitAcres}</td>
                                      <td>
                                        <input
                                          className="d-inline-block form-control"
                                          step={0.01}
                                          onChange={(e) =>
                                            validationMaxCheckAddBed(
                                              e.target.value,
                                              i
                                            )
                                          }
                                          max={i.ExhibitAcres}
                                          type="number"
                                          defaultValue={i.EnteredAcres}
                                        />
                                        {!i.isValid && (
                                          <span className="numberError">
                                            Acres treated can not exceed total bed
                                            acres
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* <!-- Select contract/Farm name ends --> */}
              {duplicateError && (
                <p className="duplicateError">
                  {moreLangs[i18n.resolvedLanguage].ePUR.duplicateErrorMessage}
                </p>
              )}
            </Modal.Body>
          )}
          <Modal.Footer>
            {updateModal.updateType === "" ||
              (updateModal.selectedRow?.hasOwnProperty(
                "isAditionalBedAvailabel"
              ) &&
                !updateModal.selectedRow?.isAditionalBedAvailabel) ? (
              ""
            ) : (
              <button
                disabled={
                  updateModal.updateType === "master"
                    ? updateModal.selectedRow.pesticideUseTypes_1 === "" ||
                    updateModal.selectedRow.applicationDate_1 === "" ||
                    updateModal.selectedRow.CodeName_1.length == 0 ||
                    updateModal.selectedRow.rateFormulatedProductPerAcre_1 ===
                    "" ||
                    updateModal.selectedRow.pesticideUseMethods_1 === "" ||
                    updateModal.selectedRow.rateUnitMeasurePerAcre_1 === ""
                    : updateModal.updateType === "child"
                      ? updateModal.selectedRow.AcresTreated_1 === "" ||
                      !updateModal.selectedRow.isValid
                      : !updateModal.selectedRow.isValid
                }
                className="btn btn-primary card-shadow ms-2 px-4"
                onClick={() =>
                  updateModal.onsubmit(
                    updateModal.selectedRow,
                    updateModal.updateType
                  )
                }
                id="ok_button"
              >
                {updateModal.updateType === "add" ? "Add" : "Update"}
              </button>
            )}
            <button
              className="btn btn-default card-shadow ms-2"
              onClick={() => onModalHide()}
              id="ok_button"
            >
              {updateModal.updateType === "" ? "Close" : "Cancel"}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default EnterOrEditPesticideApplication;
