import React, { useEffect, useState } from "react";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import { BreadcrumbWithParams } from "../../global/breadCrumb";
import News from "../../home/news";
import Loading from "../../global/loading";
import CurrentLocation from "../../global/currentLocation";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContactCard from "./contactCard";
import _ from "lodash";
import {
  lowStr,
  sortByField,
  IsArrayNotEmpty,
  getResolvedLanguage,
  groupArrayByKey,
  IsStringChecker,
  IsTokenChecker,
  IsNumberChecker,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
  compareString,
} from "../../../utils/helper";
import ComponentListRenderer from "../../global/ContentBlockRenderSwitch";
import constantsVariable from "../../../utils/constantsVariable";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import { EasternCanadaRegionConsolidation } from "../../../utils/constants";

const RegionDetails = (props) => {
  const { slugs: ec_slugs = [], slug: ec_slug } =
    EasternCanadaRegionConsolidation;
  const { SpecialRoleExist, RoleCBPrivileges, DropDownCBRoles = [] } = props;
  const { roleCBThirdPartyFarmService = false } = RoleCBPrivileges;
  const spl_component_restrict = "Field Updates";

  const params = useParams();
  const history = useHistory();
  const region = RegionConsolidator(
    SpecialRoleExist,
    roleCBThirdPartyFarmService,
    _.get(params, "region", "")
  );
  const [, moreLangs] = CurrentLocation();

  const [enableAside, setEnableAside] = useState(false);
  const [enableNews, setEnableNews] = useState(false);
  const [resultFound, setResultFound] = useState(true);
  const [regionSnapShot, setRegionSnapShot] = useState([]);
  const [scientist, setScientist] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [groupedData, setGroupedData] = useState({});

  const { component_accordion_en_path, component_listing_dropdowns } =
    constantsVariable;

  const [loading, setLoading] = useState(false);

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const [preCategories, setPreCategories] = useState(null);
  const allowedCategories = IsStringChecker(region)
    ? ["harvest-news", region]
    : ["harvest-news"];

  function RegionConsolidator(special_role, privilegde, str) {
    if (special_role && privilegde) {
      return ec_slugs.some((it) => compareString(it, str)) ? ec_slug : str;
    } else {
      return str;
    }
  }

  function RegionArrayCreator(region_arr) {
    try {
      const temp = region_arr.map((it) => {
        const { RegionDesc } = it;
        const region = lowStr(RegionDesc).replace(/ /g, "-");
        const found = ec_slugs.some((it) => compareString(it, region));
        return found ? ec_slug : region;
      });
      return _.uniq(temp.filter((li) => IsStringChecker(li)));
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function CategoriesFilter(catagories, allowed_categories) {
    try {
      if (IsArrayNotEmpty(allowed_categories)) {
        return catagories.filter((li) => {
          return allowed_categories.some((it) => compareString(it, li?.slug));
        });
      }
      return catagories;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const getCategories = async () => {
    var url = `news_event_categories`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let tempCategories = safeArrayCheck(
          sortByField(_.get(resp, "data.data", []))
        );
        // console.log({ tempCategories, allowedCategories });
        const ec_region = tempCategories.find((li) =>
          compareString(li?.slug, ec_slug)
        );
        let filtered_categories =  [];
        if (ec_region) {
          const altered_regions = tempCategories.map((li) => {
            return ec_slugs.some((it) => compareString(li?.slug, it))
              ? ec_region
              : li;
          });
          filtered_categories = CategoriesFilter(
            _.uniqBy(altered_regions, "slug"),
            allowedCategories
          );
          // console.log({ filtered_categories });
          setPreCategories(filtered_categories);
        } else {
          altered_regions = tempCategories.filter((li) => {
            return !ec_slugs.some((it) => compareString(li?.slug, it));
          });
          const filtered_categories = CategoriesFilter(
            altered_regions,
            allowedCategories
          );
          // console.log({ filtered_categories });
          setPreCategories(filtered_categories);
        }

        let newscategory = sessionStorage.getItem("newscategory");
        if(newscategory !== null){
          if(filtered_categories.filter(i=>i.slug === newscategory).length > 0){
            sessionStorage.setItem("newscategory", newscategory);
          }
          else{
            sessionStorage.setItem("newscategory", region);
          }
        }

      })
      .catch((e) => {
        setPreCategories(null);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
      .finally(() => {
        setEnableNews(true);
      });
  };

  const accordionNameBuilder = (accordion = null) => {
    if (accordion) {
      const lowAccordion = lowStr(accordion).replace(/ /g, "_");
      const accordionPath = `${resolvedLanguage}.community_ag_science_pages.${lowAccordion}`;
      return `${_.get(moreLangs, accordionPath, accordion)}`;
    } else {
      return "";
    }
  };

  const altNameBuilder = (accordion = null, modify = true) => {
    const iconPath = `${resolvedLanguage}.community_ag_science_pages.icon`;
    const iconTrailName = "Icon";
    if (modify) {
      if (accordion) {
        const lowAccordion = lowStr(accordion).replace(/ /g, "_");
        const accordionPath = `${resolvedLanguage}.community_ag_science_pages.${lowAccordion}`;
        return `${_.get(moreLangs, accordionPath, accordion)} ${_.get(
          moreLangs,
          iconPath,
          iconTrailName
        )}`;
      } else {
        return "";
      }
    } else {
      if (accordion) {
        return `${accordion} ${_.get(moreLangs, iconPath, iconTrailName)}`;
      } else {
        return "";
      }
    }
  };

  const getRegionSnapshots = async () => {
    if (region) {
      const url = `call_to_actions?select=*,page_listings!inner(slug)&page_listings.slug=eq.${region}`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          let tempRegionSnapShot = safeArrayCheck(
            sortByField(_.get(resp, "data.data", []))
          );
          setRegionSnapShot(tempRegionSnapShot);
        })
        .catch((error) => {
          setRegionSnapShot([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    } else {
      setRegionSnapShot([]);
    }
  };

  const getScientist = async () => {
    if (region) {
      const url = `contact_cards?select=*,name_en,name_fr,name_es&is_ag_scientist=eq.true&regions=cs.{${region}}`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          let tempScientist = safeArrayCheck(
            sortByField(_.get(resp, "data.data", []))
          );
          setScientist(tempScientist);
        })
        .catch((error) => {
          setScientist([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    } else {
      setScientist([]);
    }
  };

  function RoleBasedContentBlockAccessArr(special_role, privilegde, temp_arr) {
    try {
      if (special_role && privilegde) {
        if (IsArrayNotEmpty(temp_arr)) {
          return temp_arr.filter(
            (li) =>
              !compareString(li?.accordion_name_en, spl_component_restrict)
          );
        }
        return [];
      }
      return temp_arr;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function RoleBasedContentBlockAccessObj(special_role, privilegde, temp_obj) {
    try {
      if (special_role && privilegde) {
        const keys = Object.keys(temp_obj);
        if (IsArrayNotEmpty(keys)) {
          const key = keys.find((li) =>
            compareString(li, spl_component_restrict)
          );
          if (key) {
            delete temp_obj[key];
            return temp_obj;
          }
          return temp_obj;
        }
        return {};
      }
      return temp_obj;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {};
    }
  }

  const getContentBlocks = async (special_role, privilegde) => {
    if (region) {
      const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${region}`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));

          const groupData = safeArrayCheck(
            slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
          );
          const temp_componentArr = sortByField(
            groupData.map((list) => _.get(list, "component_listings", {}))
          );

          const componentArr = RoleBasedContentBlockAccessArr(
            special_role,
            privilegde,
            temp_componentArr
          );
          setComponentList(componentArr);

          const temp_groupedData = groupArrayByKey(
            slugRefinedData,
            component_accordion_en_path
          );

          const groupedData = RoleBasedContentBlockAccessObj(
            special_role,
            privilegde,
            temp_groupedData
          );
          setGroupedData(groupedData);
        })
        .catch((error) => {
          setGroupedData({});
          setComponentList([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setGroupedData({});
      setComponentList([]);
    }
  };

  const fetchContract = async (region) => {
    setLoading(true);
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `persons/${personId}/contracts`
    )
      .then((resp) => {
        const resultData = safeArrayCheck(resp?.data);
        const filteredData = IsArrayNotEmpty(resultData)
          ? FilterContractByRole(resultData, accounts, DropDownCBRoles)
          : [];
        // console.log({ filteredData });
        if (IsArrayNotEmpty(filteredData)) {
          const region_filter_arr = RegionArrayCreator(filteredData);
          // console.log({ region_filter_arr });
          if (IsArrayNotEmpty(region_filter_arr)) {
            const arr = region_filter_arr.some((it) =>
              compareString(it, region)
            );
            if (arr) {
              // console.log({ arr });
              SpecialRoleFetches();
            } else {
              history.push("/");
            }
          } else {
            history.push("/");
          }
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        history.push("/");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function SpecialRoleFetches() {
    setEnableAside(true);
    setRegionSnapShot([]);
    getCategories();
    getScientist();
    getContentBlocks(true, true);
  }

  // removing news sesstion
  if(sessionStorage.getItem("newsfrom") !== "region"){
    sessionStorage.setItem("newsfrom","region");
    sessionStorage.removeItem("newslimit");
    sessionStorage.removeItem("newscategory");
    sessionStorage.removeItem("newsloadmorelimit");
    sessionStorage.removeItem("selecteddetailpage");
  }
  useEffect(() => {
    
    if (IsTokenChecker(idToken) && IsStringChecker(region)) {
      if (SpecialRoleExist && roleCBThirdPartyFarmService) {
        fetchContract(region);
      } else {
        setLoading(true);
        setEnableAside(true);
        setEnableNews(true);
        getScientist();
        getRegionSnapshots();
        getContentBlocks(false, false);
      }
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [region, idToken, SpecialRoleExist, roleCBThirdPartyFarmService]);

  const URLpathGenerator = (id = null, name = null) => {
    try {
      if (IsNumberChecker(id) && IsStringChecker(name)) {
        const nameOrg = name.trim().replace(/ /g, "-");
        return `/community/ag-sciences/region/${id}/ag-team/${nameOrg}`;
      } else {
        return "";
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const allowNews = enableNews && resultFound;
  const data_exist =
    IsArrayNotEmpty(scientist) ||
    allowNews ||
    IsArrayNotEmpty(componentList) ||
    IsArrayNotEmpty(regionSnapShot);
  const DefaultCssClass = component_listing_dropdowns?.accordion;
  const RenderProperties = {
    AdjustmentClassName: `mt-0`,
  };
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
            {enableAside && (
              <BreadcrumbWithParams
                params={region ? region.replace(/-/g, " ") : null}
                {...props}
              />
            )}
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            {enableAside && <LeftMenu {...props} slug="ag-sciences" />}
            {enableAside && <QuickLinks {...props} slug="ag-sciences" />}
          </div>
          <div className="col-12 col-lg-10 zooming_block">
            {data_exist ? (
              <>
                <div className="row">
                  <div className="col-12 mb-5">
                    <Accordion className="mt-5 mt-lg-0 harvest_news">
                      {IsArrayNotEmpty(scientist) && (
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <img
                              src="/assets/images/icon_scientist.png"
                              className="width-30 right-space-10"
                              alt={altNameBuilder("Scientist")}
                            />
                            {accordionNameBuilder("Scientist")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="d-block d-lg-flex justify-content-center atlantic_provinces_block person_cards">
                              {scientist.map((list, index) => {
                                const URLpath = URLpathGenerator(
                                  _.get(list, "id", null),
                                  _.get(list, `name_${resolvedLanguage}`, null)
                                );
                                return (
                                  <ContactCard
                                    key={_.get(list, "id", index)}
                                    {...list}
                                    URLpath={
                                      IsStringChecker(URLpath) ? URLpath : null
                                    }
                                  />
                                );
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                      {allowNews && (
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            <img
                              src="/assets/images/icon_regional.png"
                              className="width-30 right-space-10"
                              alt={altNameBuilder("Regional News & Events")}
                            />
                            {accordionNameBuilder("Regional News & Events")}
                          </Accordion.Header>
                          <Accordion.Body>
                            <News
                              selectedNews={region}
                              {...props}
                              setResultFound={setResultFound}
                              preCategories={preCategories}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                      )}
                    </Accordion>
                    <ComponentListRenderer
                      ComponentList={componentList}
                      GroupedData={groupedData}
                      RenderProperties={RenderProperties}
                      DefaultCssClass={DefaultCssClass}
                    />
                  </div>
                </div>
                {IsArrayNotEmpty(regionSnapShot) && (
                  <div className="row">
                    {regionSnapShot.map((list) => {
                      const { id, feature_image_url, url, open_new_window } =
                        list;
                      const title = _.get(
                        list,
                        `title_${resolvedLanguage}`,
                        ""
                      );
                      const subtitle = _.get(
                        list,
                        `subtitle_${resolvedLanguage}`,
                        ""
                      );
                      return (
                        <div key={id} className="col-12 col-lg-3">
                          <div className="card bot-mspace-20 card-shadow mb-5">
                            {IsStringChecker(feature_image_url) && (
                              <img
                                src={feature_image_url}
                                className="card-img-top"
                                alt={title || ""}
                              />
                            )}
                            <div className="card-body pt-0 px-0">
                              <div className="row">
                                <div className="col-11 ms-1">
                                  <ul className="list-group text-center">
                                    <li className="list-group-item border-0 pt-4">
                                      {IsStringChecker(title) && (
                                        <Link
                                          to={
                                            open_new_window
                                              ? { pathname: url }
                                              : url
                                          }
                                          target={
                                            open_new_window ? "_blank" : "_self"
                                          }
                                          className="text-18"
                                        >
                                          {title}
                                        </Link>
                                      )}
                                      {IsStringChecker(subtitle) && (
                                        <Link
                                          to={
                                            open_new_window
                                              ? { pathname: url }
                                              : url
                                          }
                                          target={
                                            open_new_window ? "_blank" : "_self"
                                          }
                                          // className="text-18"
                                        >
                                          <p className="red_color mt-2">
                                            <i
                                              className="fa fa-file-pdf-o me-1"
                                              aria-hidden="true"
                                            ></i>
                                            {subtitle}
                                          </p>
                                        </Link>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              <div className="p-2 red_color text-center border-1 border-radius-6 mt-5 mt-lg-2">
                <i className="fa fa-info-circle pe-2"></i>
                {_.get(
                  moreLangs,
                  `${resolvedLanguage}.community_ag_science_pages.no_data_to_show`,
                  "Thank you for checking. We are adding details here for you soon."
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegionDetails;
