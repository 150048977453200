/**
 * @component_Name : ExhibitMapsMobile 
 * @description : Show ExhibitMapsMobile as accodion
 * @company : Photon
 * @author : Ashish Ranjan/22-08-2022
 **/

import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import _ from "lodash";
import { toDecimal } from "../../../utils/helper";
const ExhibitMapsMobile = ({ listingData, ongoingYear }) => {

  // console.log("listingData",listingData)
  
  const listLength = listingData.length;
  ongoingYear;
  let convertToArray;
  if(listLength > 0){
    const groupedData = _.groupBy(listingData, "GroupingKey");
    convertToArray = Object.values(groupedData).map((x) => {
      x[0]['totalExhibitAcres'] = x.reduce((prev, { ExhibitAcres }) => prev + ExhibitAcres, 0);
      return (x[0]);
    });
    convertToArray.sort((a, b) => (b.CropYear.toString().localeCompare(a.CropYear.toString(), "en", { numeric: true }) || a.MapId.toString().localeCompare(b.MapId.toString(), "en", { numeric: true })));
  } 
  return (
    <>
      <Accordion defaultActiveKey="0">
        {(listLength > 0 && convertToArray.map((item, index) => {
          return (
            <Fragment key={index}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                Crop Year: {item.CropYear}, Map Id: {item.MapId}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td"></div>
                      <div className="td" data-header="Crop Year"><span>{item.CropYear}</span></div>
                      <div className="td" data-header="Contract Number"><span>{item.Contract_Number}</span></div>
                      <div className="td" data-header="Contract Name"><span>{item.Contract_Name}</span></div>
                      <div className="td" data-header="Map"><span> {(item.MapPage)?item.MapPage:'-'} {(item.MapPageOf)?' of '+item.MapPageOf:'-'} </span></div>
                      <div className="td" data-header="MapID"><span>{item.MapId}</span></div>
                      <div className="td" data-header="Map Acres"><span>{toDecimal(item.totalExhibitAcres.toFixed(2), 2)}</span></div>
                      <div className="td" data-header="Exhibit Date"><span>{item.ExhibitMapDate}</span></div>
                      {/* <div className="td" data-header="Current"><span>{(item.CropYear == ongoingYear) ? 'Yes' : ''}</span></div> */}
                      <div className="td" data-header="Click to Download PDF"><span className="fw-bold">
                        <a href={item.ExhibitMapPdfUrl} target={"_blank"} className="border-1 border-radius-6 excelIconPadding cursor_pointer position-relative d-inline-block top-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="PDF" rel="noreferrer"><i className="fa fa-file-pdf-o blue_color text-16" aria-hidden="true"></i></a></span></div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Fragment>
          );
        }))}
      </Accordion>
    </>
  );
};

export default ExhibitMapsMobile;