import React, { useState, useEffect, Fragment } from "react";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import BreadCrumb from "../../global/breadCrumb";
import Loading from "../../global/loading";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  sortByField,
  IsArrayNotEmpty,
  getResolvedLanguage,
  groupArrayByKey,
  IsObjectChecker,
  IsStringChecker,
  IsTokenChecker,
  compareString,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import _ from "lodash";
import Carousel from "react-bootstrap/Carousel";
import constantsVariable from "../../../utils/constantsVariable";
import ComponentListRenderer from "../../global/ContentBlockRenderSwitch";

const PoliciesAndFormDetails = (props) => {
  const { SpecialRoleExist, RoleCBPrivileges } = props;
  const { roleCBThirdPartyFarmService = false } = RoleCBPrivileges;

  const slug = "policies-and-forms";
  const dispatch = useDispatch();
  const { component_accordion_en_path, component_listing_dropdowns } =
    constantsVariable;

  const [loading, setLoading] = useState(true);
  const [groupedDataByAccordion, setGroupedDataByAccordion] = useState({});
  const [componentList, setComponentList] = useState([]);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [silderAccordion, setSliderAccordion] = useState({});
  const [sliderData, setSliderData] = useState([]);
  const [sliderMobileData, setSliderMobileData] = useState([]);
  const [gridGroupRender, setGridGroupRender] = useState([]);

  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const getSliderAccordion = (accumulator, list, index, original) => {
    // console.log("accumulator, list, index, original => ", accumulator, list, index, original);
    try {
      const length = original.length;
      const slide = [];
      if (index + 3 < length) {
        for (let i = 0; i <= 3; i++) {
          slide.push(_.get(original, index + i, {}));
        }
        return [...accumulator, slide];
      } else {
        return accumulator;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  const getContentBlocks = async () => {
    const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));

        const groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        const componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );
        setComponentList(componentArr);

        const groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        setGroupedDataByAccordion(
          IsObjectChecker(groupedData) ? groupedData : {}
        );
      })
      // eslint-disable-next-line
      .catch((error) => {
        setGroupedDataByAccordion({});
        setComponentList([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const getSliderData = (groupObject, sliderData) => {
    return IsObjectChecker(groupObject) && IsStringChecker(sliderData)
      ? sortByField(_.get(groupObject, sliderData, []), "order_by", 1)
      : [];
  };

  const getGridData = (groupObject, gridData) => {
    return IsObjectChecker(groupObject) && IsStringChecker(gridData)
      ? sortByField(_.get(groupObject, gridData, []), "order_by", 1)
      : [];
  };

  const getContentActions = async () => {
    // const url = `call_to_actions?select=*,page_listings!inner(slug)&page_listings.slug=eq.${slug}&order=order_by.asc`;
    const url = `call_to_actions?select=*,page_listings!inner(slug),component_listings(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon)&page_listings.slug=eq.${slug}&order=order_by.asc`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let slugRefinedData = _.get(resp, "data.data", []);

        let groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        let componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );

        let groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );

        let sliderData = IsArrayNotEmpty(componentArr)
          ? componentArr.find((list) =>
              compareString(list.css_class, component_listing_dropdowns.slider)
            )
          : {};
        let gridTempData = IsArrayNotEmpty(componentArr)
          ? componentArr.find((list) =>
              compareString(list.css_class, component_listing_dropdowns.grid)
            )
          : {};

        const isThirdPartyFarmService =
          SpecialRoleExist && roleCBThirdPartyFarmService;
        let sliderComputed = isThirdPartyFarmService
          ? []
          : getSliderData(groupedData, sliderData?.accordion_name_en);
        if (IsArrayNotEmpty(sliderComputed)) {
          setSliderAccordion(sliderData);
          setSliderMobileData(sliderComputed);
          if (sliderComputed.length <= 4) {
            setSliderData([sliderComputed]);
          } else {
            setSliderData(sliderComputed.reduce(getSliderAccordion, []));
          }
        } else {
          setSliderAccordion({});
          setSliderData([]);
          setSliderMobileData([]);
        }
        setGridGroupRender(
          getGridData(groupedData, gridTempData?.accordion_name_en)
        );
        setLoading(false);
      })
      // eslint-disable-next-line
      .catch((error) => {
        setSliderAccordion({});
        setSliderData([]);
        setSliderMobileData([]);
        setGridGroupRender([]);
        setLoading(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getContentBlocks();
      getContentActions();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  /* Bootstrap Carousal Start*/
  const [carousalIndex, setCarousalIndex] = useState(0);
  const PrevIcon = () => (
    <span
      aria-hidden="true"
      name="prev"
      className="carousel-control-prev-icon multi-carousal-prev"
    />
  );
  const NextIcon = () => (
    <span
      aria-hidden="true"
      name="next"
      className="carousel-control-next-icon multi-carousal-next"
    />
  );
  const dsettings = {
    activeIndex: carousalIndex,
    prevIcon: <PrevIcon />,
    nextIcon: <NextIcon />,
    id: "carouselExampleControls1",
    className: "region_list mb-4",
    interval: null,
    indicators: false,
    slide: false,
    wrap: false,
    touch: true,
  };
  const msettings = {
    activeIndex: carousalIndex,
    prevIcon: <PrevIcon />,
    nextIcon: <NextIcon />,
    id: "carouselExampleControls1",
    className: "region_list mb-4",
    interval: null,
    indicators: false,
    slide: true,
    wrap: false,
    touch: true,
  };
  const handleCarousalMobileSelect = (selectedIndex) => {
    setCarousalIndex(selectedIndex);
  };
  const setResizeInnerWidth = () => {
    setInnerWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", setResizeInnerWidth);
    return () => {
      window.removeEventListener("resize", setResizeInnerWidth);
    };
  }, []);
  /* Bootstrap Carousal End*/

  const { icon_url: sliderIcon = null } = silderAccordion;
  const sliderTitle = _.get(
    silderAccordion,
    `accordion_name_${resolvedLanguage}`,
    ""
  );
  const RenderPropertiesGroup = [
    {
      AdjustmentClassName: `mt-5 mt-lg-0`,
    },
    {
      AdjustmentClassName: `mt-0`,
    },
  ];
  const DefaultCssClass = component_listing_dropdowns?.accordion;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <LeftMenu {...props} slug="ag-sciences" />
            <QuickLinks {...props} slug="ag-sciences" />
          </div>
          <div className="col-12 col-lg-10">
            <div className="row">
              <div className="col-12">
                <ComponentListRenderer
                  ComponentList={componentList}
                  GroupedData={groupedDataByAccordion}
                  RenderPropertiesGroup={RenderPropertiesGroup}
                  DefaultCssClass={DefaultCssClass}
                />
                <Accordion className="mt-0 mb-4 harvest_news">
                  {IsStringChecker(sliderTitle) && (
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        {IsStringChecker(sliderIcon) && (
                          <img
                            src={sliderIcon}
                            className="width-30 right-space-10"
                            alt=""
                          />
                        )}
                        {sliderTitle}
                      </Accordion.Header>
                      <Accordion.Body>
                        {innerWidth <= 993 ? (
                          <>
                            {IsArrayNotEmpty(sliderMobileData) && (
                              <Carousel
                                {...msettings}
                                onSelect={(index) =>
                                  handleCarousalMobileSelect(index)
                                }
                              >
                                {sliderMobileData.map((list, index) => {
                                  if (IsObjectChecker(list)) {
                                    const {
                                      url = "/",
                                      feature_image_url = "/",
                                      open_new_window,
                                    } = list;
                                    const title = _.get(
                                      list,
                                      `title_${resolvedLanguage}`,
                                      ""
                                    );
                                    return (
                                      <Carousel.Item key={index}>
                                        <div>
                                          {IsStringChecker(url) && (
                                            <Link
                                              to={
                                                open_new_window
                                                  ? { pathname: url }
                                                  : url
                                              }
                                              target={
                                                open_new_window
                                                  ? "_blank"
                                                  : "_self"
                                              }
                                              className="position-relative d-block me-1"
                                            >
                                              {IsStringChecker(
                                                feature_image_url
                                              ) && (
                                                <img
                                                  src={feature_image_url}
                                                  className="img-fluid border-radius-6"
                                                />
                                              )}
                                              <div className="position-absolute outer-grey-bg"></div>
                                              {IsStringChecker(title) && (
                                                <h4 className="position-absolute white_color fw-bold">
                                                  {title}
                                                </h4>
                                              )}
                                            </Link>
                                          )}
                                        </div>
                                      </Carousel.Item>
                                    );
                                  } else {
                                    return (
                                      <Carousel.Item
                                        key={index}
                                      ></Carousel.Item>
                                    );
                                  }
                                })}
                              </Carousel>
                            )}
                          </>
                        ) : (
                          <>
                            {IsArrayNotEmpty(sliderData) && (
                              <Carousel
                                {...dsettings}
                                onSelect={(index) =>
                                  handleCarousalMobileSelect(index)
                                }
                              >
                                {sliderData.map((list, index) => {
                                  if (IsArrayNotEmpty(list)) {
                                    return (
                                      <Carousel.Item key={index}>
                                        <div className={`multi-card-row`}>
                                          {list.map((li, ind) => {
                                            const {
                                              url = "/",
                                              feature_image_url = "/",
                                              open_new_window,
                                            } = li;
                                            const title = _.get(
                                              li,
                                              `title_${resolvedLanguage}`,
                                              ""
                                            );
                                            if (IsObjectChecker(li)) {
                                              return (
                                                <div
                                                  className={`multi-card-col ${
                                                    sliderMobileData.length <= 4
                                                      ? "col-3"
                                                      : ""
                                                  }`}
                                                  key={ind}
                                                >
                                                  {IsStringChecker(url) && (
                                                    <Link
                                                      to={
                                                        open_new_window
                                                          ? { pathname: url }
                                                          : url
                                                      }
                                                      target={
                                                        open_new_window
                                                          ? "_blank"
                                                          : "_self"
                                                      }
                                                      className="position-relative d-block me-1"
                                                    >
                                                      {IsStringChecker(
                                                        feature_image_url
                                                      ) && (
                                                        <img
                                                          src={
                                                            feature_image_url
                                                          }
                                                          className="img-fluid border-radius-6"
                                                        />
                                                      )}
                                                      <div className="position-absolute outer-grey-bg"></div>
                                                      {IsStringChecker(
                                                        title
                                                      ) && (
                                                        <h4 className="position-absolute white_color fw-bold">
                                                          {title}
                                                        </h4>
                                                      )}
                                                    </Link>
                                                  )}
                                                </div>
                                              );
                                            } else {
                                              <Fragment key={ind}></Fragment>;
                                            }
                                          })}
                                        </div>
                                      </Carousel.Item>
                                    );
                                  } else {
                                    return (
                                      <Carousel.Item
                                        key={index}
                                      ></Carousel.Item>
                                    );
                                  }
                                })}
                              </Carousel>
                            )}
                          </>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
            </div>
            <div className="row">
              {IsArrayNotEmpty(gridGroupRender) &&
                gridGroupRender.map((list, index) => {
                  const {
                    id,
                    open_new_window,
                    url = "/",
                    feature_image_url = "/",
                  } = list;
                  const title = _.get(list, `title_${resolvedLanguage}`, "");
                  const subtitle = _.get(
                    list,
                    `subtitle_${resolvedLanguage}`,
                    ""
                  );
                  return (
                    <div
                      className="col-12 col-lg-4"
                      key={id || `paper-${index}-${id}`}
                    >
                      <div className="card card-shadow mb-3 mb-lg-5">
                        {IsStringChecker(feature_image_url) && (
                          <img
                            src={feature_image_url}
                            className="card-img-top"
                            alt=""
                          />
                        )}
                        <div className="card-body pt-0 px-0">
                          <div className="row">
                            <div className="col-11 ms-1">
                              <ul className="list-group text-center">
                                <li className="list-group-item border-0 pt-4">
                                  {IsStringChecker(url) &&
                                    IsStringChecker(title) && (
                                      <Link
                                        to={
                                          open_new_window
                                            ? { pathname: url }
                                            : url
                                        }
                                        target={
                                          open_new_window ? "_blank" : "_self"
                                        }
                                        className="text-18"
                                      >
                                        {title}
                                      </Link>
                                    )}
                                  {IsStringChecker(url) &&
                                    IsStringChecker(subtitle) && (
                                      <Link
                                        to={
                                          open_new_window
                                            ? { pathname: url }
                                            : url
                                        }
                                        target={
                                          open_new_window ? "_blank" : "_self"
                                        }
                                        // className="text-18"
                                      >
                                        <p className="red_color mt-2">
                                          <i
                                            className="fa fa-file-pdf-o me-1"
                                            aria-hidden="true"
                                          ></i>
                                          {subtitle}
                                        </p>
                                      </Link>
                                    )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PoliciesAndFormDetails;
