import React, { useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import useLogoutPathBuilder from "./global/logoutPathBuilder";
import {
  IsTokenChecker,
  IsStringChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
} from "../utils/helper";
import CurrentLocation from "./global/currentLocation";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import callAdJwtToken from "../services/adMsal";

const IdleLogoutLogic = () => {
  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const toLogout = useLogoutPathBuilder();

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const [warnUser, setWarnUser] = useState(false); // Controls Warning Modal

  const seconds = 1000; // for milliseconds to seconds conversion

  // const duration = 20; //duration = 600 in seconds for 10 Minutes (10 * 60 = 600)
  const duration = 600; //duration = 600 in seconds for 10 Minutes (10 * 60 = 600)
  const timer = duration * seconds;

  // const warning = 10; //warning = 480 in seconds for 8 Minutes (8 * 60 = 480)
  const warning = 480; //warning = 480 in seconds for 8 Minutes (8 * 60 = 480)
  const aware = warning * seconds;

  // const jwtDuration = 15; //warning = 3600 in seconds for 60 Minutes (60 * 60 = 3600)
  const jwtDuration = 3600; //warning = 3600 in seconds for 60 Minutes (60 * 60 = 3600)
  const jwtSession = jwtDuration * seconds;

  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "logout_modal"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return defaultValue;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultValue;
    }
  };

  const onPrompt = () => {
    openWarnModal();
  };

  const onIdle = () => {
    logout();
  };

  const onMessage = () => {
    closeWarnModal();
    idleTimer.reset();
  };

  //   Determiners
  const logout = () => {
    closeWarnModal();
    history.push(toLogout);
  };

  const keepMeSignedIn = () => {
    closeWarnModal();
    idleTimer.message();
    idleTimer.reset();
  };

  const openWarnModal = () => {
    setWarnUser(true);
  };

  const closeWarnModal = () => {
    setWarnUser(false);
  };

  const idleTimer = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: timer,
    promptTimeout: aware,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    crossTab: true,
    syncTimers: 200,
    onMessage,
    name: "idle-timer",
  });

  const onJWTIDLE = () => {
    // console.log("onJWTIDLE", jwtTimer);
    dispatch(callAdJwtToken(instance, accounts));
    jwtTimer.reset();
  };
  const jwtTimer = useIdleTimer({
    onIdle: onJWTIDLE,
    timeout: jwtSession,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    crossTab: true,
    syncTimers: 200,
    name: "jwt-timer",
    events: [],
  });

  const title = LocalTranslator("modal_title", "Session timeout warning");
  const content = LocalTranslator(
    "modal_content",
    "Your session is about to expire."
  );
  const btn = LocalTranslator("modal_button", "Keep me signed in");
  return (
    <>
      <Modal
        className="grower-logout-warn-modal"
        show={warnUser}
        onHide={keepMeSignedIn}
        animation={false}
        // backdrop="static"
        // keyboard={false}
      >
        <Modal.Header className="grower-logout-warn-modalhead" closeButton>
          <Modal.Title className="grower-logout-warn-modaltitle">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grower-logout-warn-modalbody">
          <p className="grower-logout-warn-modaltext">{content}</p>
        </Modal.Body>
        <Modal.Footer className="grower-logout-warn-modalfoot">
          <Button
            className="grower-logout-warn-modalbtn"
            onClick={keepMeSignedIn}
          >
            {btn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IdleLogoutLogic;
