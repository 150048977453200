import React from "react";

const Body = (props) => (
  <>

    {props.contentbody}
  </>
);

export default Body;
