/**
 * @component_Name : DDReceiptsMobile -Delivery Data Receipts component
 * @description : Show Delivery Data Receipts as accordion
 * @company : Photon
 * @author : Ashish Ranjan/15-07-2022
 **/
import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
const DDReceiptsMobile = ({ listingData }) => {
  const pagingArray = Object.keys(listingData).map((x) => x);
  const listLength = pagingArray.length;
  return (
    <>
      <Accordion defaultActiveKey="0">
        {listLength > 0 &&
          Object.values(listingData).map((data, index) => {
            let delivery = data["Delivery_# - Shipping_Memo"].split("-");
            return (
              <Fragment key={index}>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    Contract #: {data["Contract_#"].split("&")[0]}, Delivery #:{" "}
                    {delivery[0]}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tbody">
                      <div className="tr">
                        <div className="td"></div>
                        <div className="td" data-header="Facility #:">
                          {data["Facility_#"]}
                        </div>
                        <div
                          className="td"
                          data-header="Delivery # - Shipping Memo:"
                        >
                          {data["Delivery_# - Shipping_Memo"]}
                        </div>
                        <div className="td" data-header="Bed:">
                          {data["Bed"]}
                        </div>
                        <div className="td" data-header="Bed%:">
                          {data["Bed%"] || `0`}
                        </div>
                        <div className="td" data-header="Date In:">
                          {data["Date_In"]}
                        </div>
                        <div className="td" data-header="Time In:">
                          {data["Time_In"]}
                        </div>
                        <div className="td" data-header="Fresh/Processed:">
                          {data["Fresh/Process"]}
                        </div>
                        <div className="td" data-header="Contract #:">
                          {data["Contract_#"]}
                        </div>
                        <div className="td" data-header="Variety:">
                          {data["Variety"]}
                        </div>
                        <div className="td" data-header="Delivery Weight:">
                          {data["Delivery_Weight"]}
                        </div>
                        <div className="td" data-header="Total Barrels:">
                          {data["Total_Barrels"]}
                        </div>
                        <div className="td" data-header="Firmness:">
                          {data["Firmness"]}
                        </div>
                        <div className="td" data-header='% >1/2:'>
                          {data["%_GreaterThanHalf"]}
                        </div>
                        <div className="td" data-header="% Dry Trash:">
                          {data["%_Dry_Trash"]}
                        </div>
                        <div className="td" data-header="% Moisture:">
                          {data["%_Moisture"]}
                        </div>
                        <div className="td" data-header="% Total Trash:">
                          {data["%_Total_Trash"]}
                        </div>
                        <div className="td" data-header="% Poor:">
                          {data["%_Poor"]}
                        </div>
                        <div className="td" data-header="% Class1:">
                          {data["%_Class1"]}
                        </div>
                        <div className="td" data-header="% Usable:">
                          {data["%_Usable"]}
                        </div>
                        <div className="td" data-header="Photo:">
                          <a
                            href={data["Photo"]}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <img
                              src="../../assets/images/single-cranberry.png"
                              className="width-25"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Fragment>
            );
          })}
      </Accordion>
    </>
  );
};

export default DDReceiptsMobile;
