import React from "react";
import Breadcrumb from "../../global/breadCrumb";
import LandingPageCtaContentBlock from "../../global/landingPageCtaContentBlock";
const Body = (props) => {
  const { isListing, contentbody, ...rest } = props;
  return (
    <>
      <div className="row">
        {isListing ? (
          <>
            <div className="col-12 d-none d-lg-block">
              <Breadcrumb {...rest} />
            </div>
            <LandingPageCtaContentBlock slug={"grower-benefits"} {...rest} />
          </>
        ) : (
          contentbody
        )}
      </div>
    </>
  );
};

export default Body;
