import React from "react";
import PageLayout from "../pageLayout";
import Body from "./body";
import MyFarmDetails from "./myFarmDetails";

const MyFarm = (props) => {
  return <PageLayout {...props} body={<Body {...props} contentbody={<MyFarmDetails {...props} />} />} />;
};

export default MyFarm;
