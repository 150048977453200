/**
 * @component_Name : Truncate Content
 * @description : Truncate contents for News & Events, Forum Widget
 * @company : Photon
 * @author : Nivethithan Kannan/14-07-2023
 **/

import {
  IsArrayNotEmpty,
  IsStringChecker,
  NumConverter,
  compareString,
  HandleErrorTrackAndToast
} from "../../utils/helper";

export const ElementToTruncate = "#text";
export const IMGElementToTruncate = "IMG";
export const VideoElementToTruncate = "PRE";
export const VideoGroupElementToTruncate = `class="video-iframe-group"`;
const Truncator = (str, chars) => {
  try {
    const temp = str.slice(0, chars).split(" ");
    if (IsArrayNotEmpty(temp)) {
      if (temp.length > 1) {
        const text = temp.splice(0, temp.length - 2);
        return text.join(" ");
      } else {
        return text[0];
      }
    }
    return str;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return str;
  }
};

const VideoAccess = (nodeName, nodeValue) => {
  if (nodeValue) {
    if (
      compareString(nodeName, VideoElementToTruncate) ||
      nodeValue.includes(VideoGroupElementToTruncate)
    ) {
      return 0;
    } else {
      return 1;
    }
  } else {
    if (nodeValue == null && compareString(nodeName, IMGElementToTruncate)) {
      return 2;
    }
    return 0;
  }
};

export const WidthBasedCharCount = (page) => {
  const Width = window.outerWidth;
  if (compareString(page, "forum-post")) {
    return 150;
  } else {
    if (Width >= 993) {
      return 1000;
    } else {
      return 500;
    }
  }
};

export const StringToHTML = (decodedText = "") => {
  try {
    if (IsStringChecker(decodedText)) {
      let temp = document.createElement("template");
      let html = `<div>${decodedText}</div>`;
      temp.innerHTML = html;
      return temp?.content?.childNodes[0];
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

const TruncateContent = (
  element,
  TruncateAppendix = "...",
  CharactersPerLine = 500,
  PerFactor = 10
) => {
  try {
    // console.log("-----------------------------------------------");
    const Element = StringToHTML(element);
    const CharactersLine = NumConverter(CharactersPerLine);
    const TruncateText = IsStringChecker(TruncateAppendix)
      ? TruncateAppendix
      : "...";
    const PerLine = NumConverter(CharactersLine / PerFactor, "round");
    if (Element) {
      var Characters = CharactersLine;
      var found = false;
      const FilterHTMLDom = (ELM) => {
        if (ELM.nextSibling) {
          const sibling = ELM.nextSibling;
          sibling.remove();
          FilterHTMLDom(ELM);
        } else {
          const parent = ELM.parentNode;
          if (parent) {
            FilterHTMLDom(parent);
          }
        }
      };
      const Truncated = (chars, Elem) => {
        found = true;
        const nodeValue = Elem?.nodeValue;
        // const actual = NumConverter(nodeValue?.length) || 0;
        // console.log({ chars, actual, Elem, nodeValue });
        if (chars === 0) {
          // console.log("Last Element: ", Elem, { Elem });
          FilterHTMLDom(Elem);
        } else {
          let newString = Truncator(nodeValue, chars);
          const modifiedNode = document.createTextNode(
            `${newString}${TruncateText}`
          );
          const parent = Elem?.parentNode;
          parent.replaceChild(modifiedNode, Elem);
          // console.log("Last Element: ", modifiedNode, { modifiedNode });
          FilterHTMLDom(modifiedNode);
        }
      };
      const TruncateLogic = (Elem) => {
        const nodeName = Elem?.nodeName;
        const nodeValue = Elem?.nodeValue;
        // console.log({ Elem, nodeName, nodeValue });
        if (compareString(nodeName, ElementToTruncate)) {
          if (Characters > 0) {
            const Length = NumConverter(nodeValue?.length) || 0;
            // console.log({ Characters, Length });
            if (Characters <= Length) {
              if (Characters === Length) {
                Characters = 0;
                Truncated(0, Elem);
              } else {
                Truncated(Characters, Elem);
                Characters = 0;
              }
            } else {
              Characters = Characters - Length;
            }
          }
        }
      };
      const TruncateImgLogic = (Elem) => {
        // console.log("IMG Elem: ", Elem);
        if (Characters > 0) {
          if (Characters <= PerLine) {
            Characters = 0;
            Truncated(0, Elem);
          } else {
            Characters = Characters - PerLine;
          }
        }
      };
      const RecursiveFunction = (Elem) => {
        if (!found) {
          const children = Elem?.childNodes;
          const nodeValue = Elem?.nodeValue;
          const nodeName = Elem?.nodeName;
          // console.log({
          //   Elem,
          //   nodeName,
          //   original: NumConverter(nodeValue?.length),
          //   nodeValue,
          //   children,
          // });
          const access = VideoAccess(nodeName, nodeValue);
          // console.log({ access });
          if (access == 1) {
            TruncateLogic(Elem);
          } else if (access == 2) {
            TruncateImgLogic(Elem);
          }
          if (children && children.length > 0) {
            children.forEach((elem) => RecursiveFunction(elem));
          }
        }
      };
      RecursiveFunction(Element);
      // console.log("Modified Element: ", Element, { Element });
      return Element.outerHTML;
    }
    return element;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return element;
  }
};

export default TruncateContent;
