import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import {
  IsTokenChecker,
  IsArrayNotEmpty,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  IsObjectChecker,
} from "../../../utils/helper";
// import forum_data from "./forumWidgetTemporaryData";
import ForumWidgetDetails from "./forumWidgetDetails";

const ForumWidget = (props) => {
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const [forumData, setForumData] = useState([]);

  const ObjDataChecker = (data = {}) => {
    try {
      if (IsObjectChecker(data) && !_.isEmpty(data)) {
        return data;
      }
      return {};
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {};
    }
  };

  const GetForumDetailData = async (tokenId, acc, urlArray = []) => {
    if (IsArrayNotEmpty(urlArray)) {
      const masterData = [...urlArray];
      var ForumDetailData = await Promise.all(
        masterData.map(async (list) => {
          const threadId = _.get(list, "id", null);
          if (threadId) {
            const url = `forum_posts?select=id,title,description,user_id,user_fullname,created_at,forum_comments(id,user_id,user_fullname,description,created_at,updated_at,is_active)&id=eq.${threadId}&forum_comments.is_active=eq.true&forum_comments.order=created_at.desc`;
            return await cmsDataCommonMethod(tokenId, acc, url)
              .then((res) => {
                const tempData = safeArrayCheck(_.get(res, "data.data", []));
                const forum_widget_details = ObjDataChecker(tempData[0]);
                return { ...list, forum_widget_details };
              })
              .catch((error) => {
                HandleErrorTrackAndToast(error, true, "replace", dispatch);
                return { ...list, forum_widget_details: {} };
              });
          } else {
            return { ...list, forum_widget_details: {} };
          }
        })
      ).catch((e) => {
        HandleErrorTrackAndToast(e, true, "replace", dispatch);
        return [...urlArray];
      });
      setForumData(safeArrayCheck(ForumDetailData));
    } else {
      setForumData([]);
    }
  };

  const GetForumData = async () => {
    const masterURL = "v_forum_landing?select=*&limit=5";
    await cmsDataCommonMethod(idToken, accounts, masterURL)
      .then((resp) => {
        const tempData = _.get(resp, "data.data", []);
        const masterData = IsArrayNotEmpty(tempData)
          ? tempData.filter((list) => {
              if (ObjDataChecker(list)) {
                return list;
              }
            })
          : [];
        const ForumArray = IsArrayNotEmpty(masterData)
          ? masterData.map((list) => {
              return { ...list, forum_widget_details: {} };
            })
          : [];
        GetForumDetailData(idToken, accounts, ForumArray);
      })
      .catch((error) => {
        setForumData([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      GetForumData();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  return <ForumWidgetDetails forum_data={forumData} {...props} />;
};

export default ForumWidget;
