import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { IsTokenChecker, getResolvedLanguage, getproper_Atag_URLfromRichContent, safeArrayCheck, HandleErrorTrackAndToast } from '../../utils/helper';
import Calendar from "react-calendar";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { cmsDataCommonMethod } from '../../services/gbsData';
import { useMsal } from '@azure/msal-react';
import { Modal } from 'react-bootstrap';
import parse from "html-react-parser";

const CalendarEvents = ({ className, title, slug }) => {
    const { accounts } = useMsal();
    const idToken = useSelector((state) => state.reducerToken);
    const { i18n } = useTranslation();
    const resolvedLanguage = getResolvedLanguage(i18n);
    const [defaultCalendar, setDefaultCalendar] = useState([]);
    const [calendar, setCalendar] = useState(null);
    const [activeStartDate, setActiveStartDate] = useState(new Date());
    const [allCalendarData, setAllCalendarData] = useState([]);
    const [, setLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();

    const tileDisabled = ({ date, view }) => {
        if (view === "month") {
            return !(SearchDate(date).length > 0);
        } else {
            return false;
        }
    };

    const tileClassName = (props) => {
        let { date, view } = props;
        if (view === "month") {
            let dateObj = SearchDate(date);
            dateObj = _.orderBy(dateObj,["id"],['asc']);
            // dateObj = dateObj.length > 0 ? dateObj[0] : [];
            if(dateObj.length > 0){
                dateObj = dateObj.map(i=>i.date_color).toString().replace(/(,)/g, ' ');
            }
            else{
                dateObj = "";
            }
            return `cal-tile ${dateObj}`;
        } else {
            return `os-cal-tile`;
        }
    };

    const weekDayFormat = (locale, date) => {
        return moment(date).locale(locale).format("dd");
    };

    const onDayClick = (value) => {
        setShowModal(true);
        let filterData = defaultCalendar.filter((i) => compareDate(i.displayDate, value));
        setCalendar(value);
        setAllCalendarData(_.orderBy(filterData, ["id"], ["asc"]));
    }

    useEffect(() => {
        if (IsTokenChecker(idToken)) {
            getCourseListing();
        }
    }, [idToken]);

    const SearchDate = (date = null) => {
        try {
            if (date) {
                let found = defaultCalendar.filter((li) => compareDate(li.displayDate, date));
                // let found = defaultCalendar.find((li) => compareDate(li.displayDate, date));
                return found.length > 0 ? found : [];
            } else {
                return false;
            }
        } catch (e) {
            HandleErrorTrackAndToast(e, true);
            return false;
        }
    };

    const compareDate = (date1 = null, date2 = null) => {
        try {
            if (date1 && date2) {
                let str1 = date1.toDateString();
                let str2 = date2.toDateString();
                return str1 === str2;
            } else {
                return false;
            }
        } catch (e) {
            HandleErrorTrackAndToast(e, true);
            return false;
        }
    };
    const getDaysArray = (start, end) => {
        for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    };
    const getCourseListing = async () => {
        setLoading(true);
        const url = `calendar_entries?select=*,page_listings!inner(id,title_en,slug)&page_listings.slug=eq.${slug}`;
        await cmsDataCommonMethod(idToken, accounts, url)
            .then((resp) => {
                let slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));
                setAllCalendarData(slugRefinedData);
                let count = 0;
                let calendarData = slugRefinedData.reduce((accumulator, value) => {
                    value.date_from = TimeFixer(value.date_from);
                    value.date_to = TimeFixer(value.date_to);
                    let daylist  = getDaysArray(value.date_from,value.date_to);
                    let obj = [];
                    daylist.map((i)=>{
                        value.itemId = count++;
                        value.displayDate =i;
                        obj.push({...value});
                    });
                    return [...accumulator, ...obj];
                }, []);
                setDefaultCalendar(calendarData)
            })
            .catch((error) => {
                HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const onActiveStartDateChange = ({ activeStartDate }) => {
        setActiveStartDate(activeStartDate);
    };
    const TimeFixer = (date = null) => {
        try {
            if (date) {
                // let converted = moment.utc(date).local();
                return new Date(date);
            } else {
                return null;
            }
        } catch (e) {
            HandleErrorTrackAndToast(e, true);
            return date;
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };


    return (
        <>
            <div className={`calendar-events`}>
                <h4 className="card-header white_color ver-space-15 left-border-radius right-border-radius">{title}</h4>
                <Calendar
                    className={`os-calendar ${className}`}
                    calendarType={"US"}
                    minDetail={"decade"}
                    formatShortWeekday={weekDayFormat}
                    tileClassName={tileClassName}
                    locale={resolvedLanguage}
                    activeStartDate={activeStartDate}
                    onActiveStartDateChange={onActiveStartDateChange}
                    onChange={onDayClick}
                    value={calendar}
                    tileDisabled={tileDisabled}
                    showNeighboringMonth={false}
                // view={"month"}
                // selectRange={true}
                // returnValue={"range"}
                // allowPartialRange={true}
                />
            </div>
            {showModal && (
                <Modal
                    show={showModal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName='modal-lg calendar-popup'
                >
                    <Modal.Header closeButton className="blue_bg">
                        <Modal.Title>
                            <h4 className="modal-title white_color">{title}</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            allCalendarData.map((i,index) => <>
                                <div key={index} className='event-Dtails'>
                                    <div>
                                        <h2 className="title">{i.title_en || ""}</h2>
                                    </div>
                                    <div className='description'>
                                        {parse(getproper_Atag_URLfromRichContent(i.description_en || ""))}
                                    </div>
                                </div>
                            </>)
                        }
                    </Modal.Body>
                    <Modal.Footer className="border-0">

                    </Modal.Footer>
                </Modal>
            )}
        </>
    )
}

export default CalendarEvents;