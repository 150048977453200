/**
 * @component_Name : DatePickerComp
 * @description : Custom Date picker component for Datepicker with year and month dropdown 
 * @company : Photon
 * @author : Gobi
 **/
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { dateInputMask } from "../../utils/helper";

const DatePickerComp = ({ ref = undefined, id = "datepicker", selected = undefined, onChange = undefined, placeholderText = undefined, className = undefined, disabled = undefined, maxDate = undefined, minDate = undefined, defaultValue = undefined }) => {
  
  useEffect(()=>{
    const input = document.querySelectorAll('#'+id)[0];    
    dateInputMask(input);
  },[]);

  return (
    <>
      <DatePicker
        ref={ref}
        id={id}
        selected={selected}
        onChange={(date) => {
          onChange(date)
        }}
        placeholderText={placeholderText}
        className={className}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        defaultValue={defaultValue}
      />
    </>
  );
};

export default DatePickerComp;
