import React, { useState, useEffect, Fragment } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import { gbsDataCommonMethod } from "../../services/gbsData";
import { useHistory } from "react-router-dom";
import Loading from "../global/loading";
import DataGridPagination from "../global/dataGridPagination";
import CurrentLocation from "../global/currentLocation";
import { useTranslation } from "react-i18next";
import {
  IsTokenChecker,
  toDecimal,
  toDecimalORCurrency,
  getResolvedLanguage,
  IsArrayNotEmpty,
  IsStringChecker,
  StringToInteger,
  IsNumberChecker,
  compareString,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  NumConverter,
} from "../../utils/helper";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../permissionWrapper";
import { roleCBAcreage as CBAcreage, roleCBPayment as CBPayment, roleCBDecisionMaker as CBDecisionMaker } from "../../utils/roles";

const MyFarmDetails = (props) => {
  const { DropDownCBRoles = [] } = props;
  const dispatch = useDispatch();

  const contractsColumn = [
    {
      key: "Contract",
      name: "Contract #",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: 200,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "ContractIdentifier",
      name: "Contract Identifier",
      width: 135,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-start",
    },
    {
      key: "TotalAcreage",
      name: "Total Acres",
      width: 120,
      headerCellClass: "grid_column_left",
      cellClass: "text-lg-end",
      formatter(props) {
        return toDecimal(
          NumConverter(props?.row?.TotalAcreage)?.toFixed(2),
          2,
          "",
          "0.00"
        );
      },
    },
    {
      key: "Status",
      name: "Status",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-start",
    },
    {
      key: "ContractBeginDate",
      name: "Contract Begin Date",
      width: 150,
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "ContractEndDate",
      name: "Contract Renew Date",
      width: 150,
      headerCellClass: "enablesTextWrappingHeading",
    },
  ];
  const paymentsEquitycolumn = [
    {
      key: "Contract",
      name: "Contract #",
      width: 155,
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: 275,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "LatestPaymentAmount",
      name: "Most Recent Payment",
      width: 175,
      headerCellClass: "grid_column_right",
      cellClass: "text-lg-end",
    },
    {
      key: "AreAmount",
      name: "Total ARE",
      headerCellClass: "grid_column_right",
      width: 175,
      cellClass: "text-lg-end",
    },
    {
      key: "SharesAmount",
      name: "Common Shares",
      width: 175,
      headerCellClass: "grid_column_right",
      cellClass: "text-lg-end",
    },
    // {
    //   key: "CalendarYear",
    //   name: "Calendar Year",
    //   headerCellClass: "enablesTextWrappingHeading",
    //   width: 150,
    // },
  ];
  const [loading, setLoading] = useState(false);

  const AcreageRole = DropDownCBRoles.find((li) =>
    compareString(li, CBAcreage)
  );
  const PaymentRole = DropDownCBRoles.find((li) =>
    compareString(li, CBPayment)
  );
  const DecisionMakerRole = DropDownCBRoles.find((li) =>
    compareString(li, CBDecisionMaker)
  );
  const RoleCBPrivileges = _.get(props, "RoleCBPrivileges", {});

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, `0.idTokenClaims.gbsPersonId`, null);
  const tokenId = useSelector((state) => state.reducerToken);

  const location = useHistory().location;
  const arrayLocation = location.hash;

  const [contracts, setContracts] = useState([]);
  const [payments, setPayments] = useState([]);

  const safeStringRenderer = (str) => {
    try {
      if (IsStringChecker(str)) {
        return str;
      } else {
        return "-";
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "-";
    }
  };

  const fetchContract = async () => {
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/${personId}/contracts`
    )
      .then((resp) => {
        var tempResponse = safeArrayCheck(_.get(resp, "data", []));
        const roleFilteredResponse = FilterContractByRole(
          tempResponse,
          accounts,
          [AcreageRole, DecisionMakerRole]
        );
        let finalArray = roleFilteredResponse.map((item, index) => {
          const {
            Name = null,
            Name2 = null,
            Name3 = null,
            RelationshipNumber: Contract = null,
            ContractStatusDesc: Status = null,
            ContractIdentifier = null,
            TotalAcreage = null,
            ContractBeginDate = null,
            ContractEndDate = null,
            ContractStatus = null,
          } = item;
          const ContractName = safeStringRenderer(
            `${IsStringChecker(Name) ? `${Name}` : ``}${
              IsStringChecker(Name2) ? ` ${Name2}` : ``
            }${IsStringChecker(Name3) ? ` ${Name3}` : ``}`
          );
          return {
            Index: index,
            Contract: safeStringRenderer(Contract),
            ContractName: ContractName,
            ContractIdentifier: safeStringRenderer(ContractIdentifier),
            TotalAcreage: TotalAcreage,
            Status: safeStringRenderer(Status),
            ContractBeginDate: ContractBeginDate
              ? `${moment(ContractBeginDate).format("MM/DD/YYYY")}`
              : "-",
            ContractEndDate:
              ContractStatus !== 1003
                ? ContractEndDate
                  ? `${moment(ContractEndDate).format("MM/DD/YYYY")}`
                  : "-"
                : "-",
          };
        });
        const result_Arr = IsArrayNotEmpty(finalArray)
          ? _.orderBy(finalArray, ["Status", "Contract"], ["asc", "asc"])
          : [];
        setContracts(result_Arr);
      })
      .catch((error) => {
        setContracts([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const fetchPaymentEquity = async () => {
    setLoading(true);
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/${personId}/contracts/payments-equities`
    )
      .then((resp) => {
        var tempResponse = safeArrayCheck(_.get(resp, "data", []));
        const roleFilteredResponse = FilterContractByRole(
          tempResponse,
          accounts,
          [PaymentRole, DecisionMakerRole]
        );
        let finalArray = roleFilteredResponse.map((item, index) => {
          const {
            Name = null,
            RelationshipNumber: Contract = null,
            LatestPaymentAmount = null,
            AreAmount = null,
            SharesAmount = null,
            CalendarYear = null,
          } = item;
          return {
            Index: index,
            Contract: safeStringRenderer(Contract),
            ContractName: safeStringRenderer(Name),
            SharesAmount: IsNumberChecker(SharesAmount)
              ? toDecimal(SharesAmount, 0)
              : "-",
            CalendarYear: StringToInteger(CalendarYear) || "-",
            AreAmount: IsNumberChecker(AreAmount)
              ? toDecimalORCurrency(AreAmount, 2, "$")
              : "-",
            LatestPaymentAmount: IsNumberChecker(LatestPaymentAmount)
              ? toDecimalORCurrency(LatestPaymentAmount, 2, "$")
              : "-",
          };
        });
        const result_Arr = IsArrayNotEmpty(finalArray)
          ? _.orderBy(finalArray, ["Contract", "CalendarYear"], ["asc", "desc"])
          : [];
        setPayments(result_Arr);
      })
      .catch((error) => {
        setPayments([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      const { roleCBAcreage = false, roleCBPayment = false, roleCBDecisionMaker = false } = RoleCBPrivileges;
      if (roleCBDecisionMaker || roleCBAcreage) {
        fetchContract();
      }
      if (roleCBDecisionMaker || roleCBPayment) {
        fetchPaymentEquity();
      }
      setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };    
  }, [tokenId]);

  const handlerAccordion = (e) => {
    //e.stopPropagation
    if (e !== null) {
      // if e === null, that means that an accordion item was collapsed rather than expanded. e will be non-null when an item is expanded
      localStorage.setItem("expandedItem", e);
    } else {
      localStorage.setItem("expandedItem", [5]);
    }
  };

  let retainAccordion = "";
  if (arrayLocation != "" || localStorage.expandedItem != "") {
    if (arrayLocation != "") {
      retainAccordion = arrayLocation.replace(/#/g, "");
    } else {
      retainAccordion = localStorage.expandedItem
        ? localStorage.expandedItem.split(",")
        : "5";
    }
  }

  const noDataContracts = !IsArrayNotEmpty(contracts);
  const noDataPayments = !IsArrayNotEmpty(payments);
  const noDataMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_record_found`,
    "Record not found"
  )}`;
  const { roleCBAcreage = false, roleCBPayment = false, roleCBDecisionMaker = false } = RoleCBPrivileges;
  const AcreageAccess = roleCBDecisionMaker || roleCBAcreage;
  const PaymentAccess = roleCBDecisionMaker || roleCBPayment;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <Accordion
        defaultActiveKey={retainAccordion}
        alwaysOpen
        id="accordionMyFarm"
        onSelect={handlerAccordion}
        className="harvest_news bot-mspace-30"
      >
        {AcreageAccess && (
          <Accordion.Item
            eventKey="Contracts"
            className="my_acreage_contracts_table"
          >
            <Accordion.Header>
              <img
                src="./assets/images/icon_my_contracts.svg"
                className="svg-image right-space-10"
                alt="Contracts_icon"
              />{" "}
              Contracts/Acreage
            </Accordion.Header>

            <Accordion.Body>
              {
                !displayAccordian && <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
                {IsArrayNotEmpty(contracts) && (
                  <DataGridPagination
                    uniqueIdentifier="Index"
                    gridData={contracts}
                    columns={contractsColumn}
                    isPagination={true}
                    itemsPerPage={10}
                    columnResize={false}
                    classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                    dynamicHeight={80}
                  />
                )}
                {noDataContracts && (
                  <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
                    <i className="fa fa-info-circle pe-2"></i>
                    {noDataMsg}
                  </div>
                )}
              </div>
              }
              <div
                className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
              >
                <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                  <Accordion
                    defaultActiveKey="0"
                    className="table mobile-accordion accordion_space"
                  >
                    {IsArrayNotEmpty(contracts) ? (
                      contracts.map((data, index) => {
                        const {
                          Contract = null,
                          ContractName = null,
                          ContractIdentifier = null,
                          TotalAcreage = null,
                          Status = null,
                          ContractBeginDate = null,
                          ContractEndDate = null,
                        } = data;
                        return (
                          <Fragment key={index}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                Contract #: {Contract}
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="tbody">
                                  <div className="tr">
                                    <div className="td"></div>
                                    <div
                                      className="td"
                                      data-header="Contract Name:"
                                    >
                                      <span>{ContractName}</span>
                                    </div>
                                    <div
                                      className="td"
                                      data-header="Contract Identifier:"
                                    >
                                      <span>{ContractIdentifier}</span>
                                    </div>
                                    <div
                                      className="td"
                                      data-header="Total Acres:"
                                    >
                                      <span>{TotalAcreage}</span>
                                    </div>

                                    <div className="td" data-header="Status:">
                                      <span>{Status}</span>
                                    </div>
                                    <div
                                      className="td"
                                      data-header="Contract Begin Date:"
                                    >
                                      <span>{ContractBeginDate}</span>
                                    </div>
                                    <div
                                      className="td"
                                      data-header="Contract Renew Date:"
                                    >
                                      <span>{ContractEndDate}</span>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                        <i className="fa fa-info-circle pe-2"></i>
                        {noDataMsg}
                      </div>
                    )}
                  </Accordion>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {PaymentAccess && (
          <Accordion.Item
            eventKey="PaymentandEquity"
            className="my_payments_and_equity_table"
          >
            <Accordion.Header>
              <img
                src="./assets/images/icon_my_payments_and_equity.svg"
                className="img-30 right-space-10"
                alt="payments_icon"
              />{" "}
              Payments and Equity
            </Accordion.Header>
            <Accordion.Body>
             {!displayAccordian &&  <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
                {IsArrayNotEmpty(payments) && (
                  <DataGridPagination
                    uniqueIdentifier="Index"
                    gridData={payments}
                    columns={paymentsEquitycolumn}
                    isPagination={true}
                    itemsPerPage={10}
                    columnResize={false}
                    classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                    dynamicHeight={50}
                  />
                )}
                {noDataPayments && (
                  <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
                    <i className="fa fa-info-circle pe-2"></i>
                    {noDataMsg}
                  </div>
                )}
              </div>
              }
              <div
                className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
              >
                <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                  <Accordion
                    defaultActiveKey="0"
                    className="table mobile-accordion accordion_space"
                  >
                    {IsArrayNotEmpty(payments) ? (
                      payments.map((data, index) => {
                        const {
                          Contract = null,
                          ContractName = null,
                          SharesAmount = null,
                          // CalendarYear = null,
                          AreAmount = null,
                          LatestPaymentAmount = null,
                        } = data;
                        return (
                          <Fragment key={index}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                Contract #: {Contract}
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="tbody">
                                  <div className="tr">
                                    <div className="td"></div>
                                    <div
                                      className="td"
                                      data-header="Contract Name:"
                                    >
                                      <span>{ContractName}</span>
                                    </div>
                                    <div
                                      className="td"
                                      data-header="Most Recent Payment:"
                                    >
                                      <span>{LatestPaymentAmount}</span>
                                    </div>
                                    <div
                                      className="td"
                                      data-header="Total ARE:"
                                    >
                                      <span>{AreAmount}</span>
                                    </div>
                                    <div
                                      className="td"
                                      data-header="Common Shares:"
                                    >
                                      <span>{SharesAmount}</span>
                                    </div>
                                    {/* <div
                                      className="td"
                                      data-header="Calendar Year:"
                                    >
                                      <span>{CalendarYear}</span>
                                    </div> */}
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                        <i className="fa fa-info-circle pe-2"></i>
                        {noDataMsg}
                      </div>
                    )}
                  </Accordion>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </>
  );
};

export default MyFarmDetails;
