/** 
@CreatedBy : Ashish Ranjan/05-07-2022
@updatedBy : Gobi - Functionality implementation
@Function : Keeping quality component
@Description : Show Keeping quality details as grid and accodion
*/
import React, { useState, useEffect, useRef, Fragment } from "react";
import "../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import ExportFile from "../../exportFile/exportFile";
import { KeepingQualityPdfFile as PdfFile } from "../../exportFile/pdfFile";
import { Accordion } from "react-bootstrap";
import WhiteCircleLoader from "../../whiteCircleLoader";
import Moment from "react-moment";
import moment from "moment";
import Loading from "../../../components/global/loading";
import DataGridPagination from "../../global/dataGridPagination";
import {
  IsTokenChecker,
  toDecimal,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  compareString,
} from "../../../utils/helper";
import { dateFormate, notFoundMsg } from "../../../utils/constantsVariable";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import _ from "lodash";

const KeepingQuality = (props) => {
  const { DropDownCBRoles = [], noContractsFound = "" } = props;

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);

  const tokenId = useSelector((state) => state.reducerToken);
  const sampleTypeRef = useRef();
  const sampleStatusRef = useRef();
  const dispatch = useDispatch();

  const [multiValue, setMultiValue] = useState({});
  const [CY_MultiValue, setCY_MultiValue] = useState({});
  const [CN_MultiValue, setCN_MultiValue] = useState({});

  const [defCropYearArr, setdefCropYearArr] = useState([]);
  const [contractPreselect, setContractPreselect] = useState([]);
  const [keepingResult, setKeepingResult] = useState([]);
  const [keepingResultMobile, setKeepingResultMobile] = useState([]);
  const [keepingResultStatus, setKeepingResultStatus] = useState(true);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [filterItems, setFilterItems] = useState([]);

  const [CropYears, setCropYears] = useState([]);
  const [SampleTypes, setSampleTypes] = useState([]);
  const [SampleStatuss, setSampleStatus] = useState([]);
  const [ContractNumbers, setContractNumbers] = useState([]);
  const [ContractIds, setContractIds] = useState([]);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);
  const [disableClear, setDisableClear] = useState(true);

  const [hideExport, setHideExport] = useState(true);

  const [disabled, setDisabled] = useState(true);
  const [CNDisabled, setCNDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [clearstatus] = useState(false);
  // const [clearstatus, setClearStatus] = useState(false);

  const StatusTypeFilter = [
    "Withdrawn",
    "Voided",
    "Deleted",
    "Pending At Receipt",
  ];

  const ValueReturner = (num = "", places = 2) => {
    try {
      if (num === null || num === undefined) {
        return "-";
      } else {
        const temp = parseFloat(num, places);
        return temp !== NaN ? toDecimal(temp, places, "", `0`) : `0`;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "-";
    }
  };

  const columns = [
    {
      key: "CropYear",
      name: "Crop Year",
      width: 100,
      // headerCellClass: "enablesTextWrappingHeading text-lg-end",
      // cellClass: "text-lg-end",
    },
    {
      key: "RelationshipNumber",
      name: "Contract #",
      width: 120,
    },
    {
      key: "Name",
      name: "Contract Name",
      minWidth: 260,
    },
    {
      key: "SampleType",
      name: "Sample Type",
      minWidth: 120,
    },
    {
      key: "LocationCode",
      name: "Location Code",
      width: 140,
    },
    {
      key: "SampleNumber",
      name: "Sample Number",
      width: 150,
    },
    {
      key: "StatusType",
      name: "Status",
      minWidth: 180,
    },
    {
      key: "Crates",
      name: "Units",
      width: 120,
      formatter(props) {
        return <>{ValueReturner(props.row.Crates, 2)}</>;
      },
    },
    {
      key: "Weighting",
      name: "Weighting",
      width: 120,
      formatter(props) {
        return <>{ValueReturner(props.row.Weighting, 3)}</>;
      },
    },
    {
      key: "Date",
      name: "Date",
      headerCellClass: "grid_column_center",
      cellClass: "text-lg-center",
      width: 100,
      formatter(props) {
        return (
          <Moment utc format={dateFormate}>
            {props.row.Date}
          </Moment>
        );
      },
    },
    {
      key: "PercentAtReceipt",
      name: "% at Receipt",
      width: 140,
      formatter(props) {
        return <>{ValueReturner(props.row.PercentAtReceipt, 2)}</>;
      },
    },
    {
      key: "PercentAt3Week",
      name: "% at 3 Week",
      width: 140,
      formatter(props) {
        return <>{ValueReturner(props.row.PercentAt3Week, 2)}</>;
      },
    },
    {
      key: "PercentAtLate",
      name: "% at Late",
      width: 110,
      formatter(props) {
        return <>{ValueReturner(props.row.PercentAtLate, 2)}</>;
      },
    },
    {
      key: "PercentUncolored3Week",
      name: "% Uncolored 3 Week",
      width: 180,
      formatter(props) {
        return <>{ValueReturner(props.row.PercentUncolored3Week, 2)}</>;
      },
    },
    {
      key: "PhotoLink",
      name: "3 Week Photo",
      width: 140,
      cellClass: "text-lg-center enablesTextWrapping",
      formatter(props) {
        let temp = _.get(props, "row.PhotoLink", null);
        // console.log(props, temp)
        return temp ? (
          <span>
            <a href={temp} target={"_blank"} rel="noreferrer">
              <img
                src="../../assets/images/single-cranberry.png"
                className="width-25"
              />
            </a>
          </span>
        ) : (
          "No Photo Available"
        );
      },
    },
  ];

  const getCropYearAndContracts = async (urlArray = []) => {
    var resp = await Promise.all(
      urlArray.map(async (url) => {
        return await gbsDataCommonMethod(tokenId, accounts, url)
          .then((res) => res.data)
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
            return [];
          });
      })
    ).catch((e) => {
      HandleErrorTrackAndToast(e, true, "replace", dispatch);
      return [];
    });

    if (IsArrayNotEmpty(resp)) {
      const initialCropyears = resp[0];
      const initialContracts = resp[1];
      if (
        IsArrayNotEmpty(initialContracts) &&
        IsArrayNotEmpty(initialCropyears)
      ) {
        const currentcropData = initialCropyears.find(
          (x) => x.CurrentCrop === true
        );
        const getcurrentcrop = currentcropData?.CropYear || 0;
        const cropYear = initialCropyears.splice(0, 15);

        setCropYears(setOptions(cropYear, "CropYear", true));
        setdefCropYearArr([`${getcurrentcrop}`]);
        setCY_MultiValue({ cropyear: `${getcurrentcrop}` });

        const filteredContracts = FilterContractByRole(
          initialContracts,
          accounts,
          DropDownCBRoles
        );
        if (IsArrayNotEmpty(filteredContracts)) {
          setContractNumbers(
            setOptions(
              filteredContracts,
              "RelationshipId",
              true,
              "RelationshipNumber",
              "Name"
            )
          );
          const contractIds = filteredContracts
            .map((li) => li.RelationshipId)
            .join();
          setContractIds(contractIds);
          setCNDisabled(false);
          setContractPreselect(["all"]);

          let url = `keeping-quality?contractid=${contractIds}&cropyear=${`${getcurrentcrop}`}`;
          getKeepingQuality(url);
        }
      }
    }
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      const urlArray = [`crop-years`, `persons/${personId}/contracts`];
      getCropYearAndContracts(urlArray);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  // Contract select change event
  const onContractNumberChange = (val) => {
    setMultiValue(null);
    let filter = filterItems.filter((item) => {
      return (
        setObjToString(val)
          ?.toLowerCase()
          ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
      );
    });
    setDisableSubmitCancel(false);
    setDisableClear(false);
    setDisabled(false);
    setSampleTypes(setOptions(filter, "SampleType", true));
    setSampleStatus(setOptions(filter, "StatusType", true));
    setHideExport(true);
    setKeepingResultStatus(false);
  };
  // Contract deselect change event
  const onContractNumberRemove = (selectedList, removedItem) => {
    setMultiValue(null);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisableSubmitCancel(true);
      setDisableClear(true);
      setDisabled(true);
    } else {
      let filter = filterItems.filter((item) => {
        return (
          setObjToString(selectedList)
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
      setDisableSubmitCancel(false);
      setDisableClear(false);
      setDisabled(false);
      setSampleTypes(setOptions(filter, "SampleType", true));
      setSampleStatus(setOptions(filter, "StatusType", true));
    }
    setHideExport(true);
    setKeepingResultStatus(false);
  };
  //crop year select change event
  const onCropYearChange = (val) => {
    onClearfields();
    setCN_MultiValue(null);
    let cropyears = setObjToString(val);
    if (cropyears !== "") {
      setCNDisabled(false);
      setDisableClear(false);
      // setDisableSubmitCancel(false)
      let url = `keeping-quality?contractid=${ContractIds}&cropyear=${cropyears}`;
      getKeepingQuality(url);
    }
  };
  //crop year deselect change event
  const onCropYearRemove = (selectedList, removedItem) => {
    setCN_MultiValue(null);
    onClearfields();
    if (selectedList.length === 0 || removedItem.id === "all") {
      onClearfields();
      // setContractNumbers([]);
      setCNDisabled(true);
      setDisableClear(true);
    } else {
      let cropyears = setObjToString(selectedList);
      if (cropyears !== "") {
        setCNDisabled(false);
        setDisableClear(false);
        let url = `keeping-quality?contractid=${ContractIds}&cropyear=${cropyears}`;
        getKeepingQuality(url);
      }
    }
  };

  // On click search
  const onAllSearch = () => {
    let filter = [...filterItems];
    if (CN_MultiValue?.contractid) {
      filter = filter.filter((item) => {
        return (
          CN_MultiValue?.contractid
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
    }
    if (multiValue?.sampletypes) {
      filter = filter.filter((item) => {
        return (
          multiValue?.sampletypes
            ?.toLowerCase()
            ?.indexOf(item?.SampleType?.toLowerCase()) !== -1
        );
      });
    }
    if (multiValue?.samplestatuses) {
      filter = filter.filter((item) => {
        return (
          multiValue?.samplestatuses
            ?.toLowerCase()
            ?.indexOf(item?.StatusType?.toLowerCase()) !== -1
        );
      });
    }
    if (
      IsArrayNotEmpty(filter) &&
      filter !== "Contract and CropYear must be provided"
    ) {
      setKeepingResult(filter);
      setKeepingResultMobile(filter);
      setKeepingResultStatus(true);
      setHideExport(false);
      setNoResultsFound(false);
    } else {
      setNoResultsFound(true);
    }
    handlerMobFilter("cancel");
  };

  const onClearfields = () => {
    setDisabled(true);
    setKeepingResult([]);
    setHideExport(true);
    setDisableSubmitCancel(true);
    setDisableClear(true);
    setKeepingResultStatus(false);
    setMultiValue(null);
    setCN_MultiValue(null);
    setSampleTypes([]);
    setSampleStatus([]);
    setNoResultsFound(false);
  };
  // on click clear
  const onClearSearch = () => {
    setCY_MultiValue(null);
    setCN_MultiValue(null);
    setContractPreselect([]);
    setdefCropYearArr([]);
    setCNDisabled(true);
    // setCropYears(setOptions(cropYear, "CropYear", true));
    setDisabled(true);
    setKeepingResult([]);
    setFilterItems([]);
    setHideExport(true);
    setDisableSubmitCancel(true);
    setDisableClear(true);
    setKeepingResultStatus(false);
    setHideExport(true);
    setMultiValue(null);
    setSampleTypes([]);
    setSampleStatus([]);
    setNoResultsFound(false);
    // if(clear){
    //   setCropYears(setOptions(cropYear, "CropYear", true));
    //   setCropYears(JSON.parse(JSON.stringify(CropYears)));
    // }
  };
  // Get Grid API values
  const getKeepingQuality = async (url) => {
    setLoader(true);
    setLoading(true);
    await gbsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        let resultData = [...resp.data];
        let filteredData = FilterContractByRole(
          resultData,
          accounts,
          DropDownCBRoles
        );
        if (
          filteredData.length > 0 &&
          resp.data !== "Contract and CropYear must be provided"
        ) {
          let tempData = filteredData.sort((a, b) => {
            return moment(b.Date) - moment(a.Date);
          });
          // setSampleTypes(setOptions(resultData, "SampleType", true));
          // setSampleStatus(setOptions(resultData, "StatusType", true));
          // setDisabled(false);
          tempData = tempData.filter((li) => {
            const found = StatusTypeFilter.find((it) =>
              compareString(it, li?.StatusType)
            );
            return !found;
          });

          setFilterItems(tempData);
        } else {
          // setSampleTypes([]);
          // setSampleStatus([]);
          // setDisabled(true);
          setFilterItems([]);
          setHideExport(true);
          setKeepingResult([]);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setFilterItems([]);
        setHideExport(true);
        setKeepingResult([]);
      })
      .finally(() => {
        setLoading(false);
        setLoader(false);
      });
  };
  // convert object to string
  const setObjToString = (val) => {
    let ids = val.map((x) => x.id);
    ids = ids.filter((x) => x != "all" && x).toString();
    return ids;
  };
  // creating option for mulit select
  const setOptions = (data, keyPrram, unique, concatValue1, concatValue2) => {
    if (unique && keyPrram !== "RelationshipId") {
      return data
        .map((li) => li[keyPrram])
        .filter(onlyUnique)
        .map((li) => {
          return { name: li, id: li };
        });
    } else {
      const contracts = data.map((li) => {
        return concatValue1 && concatValue2
          ? {
              name: li[concatValue1] + " " + li[concatValue2],
              id: li[keyPrram],
            }
          : { name: li[keyPrram], id: li[keyPrram] };
      });
      return [...new Map(contracts.map((item) => [item["id"], item])).values()];
    }
  };
  // Converting raw data to Arranged Data
  const ArrangedData = (resultData) => {
    let arrangedData = resultData.map((li) => {
      return {
        // "Id":li.Id,
        "Crop Year": li.CropYear,
        "Contract #": li.RelationshipNumber,
        "Contract Name": li.Name,
        "Sample Type": li.SampleType,
        "Location Code": li.LocationCode,
        "Sample Number": li.SampleNumber,
        Status: li.StatusType,
        Units:li.Crates !== null &&  li.Crates >= 0 ? parseFloat(ValueReturner(li.Crates, 2)) : null,
        // Units: toDecimalORCurrency(li.Crates || "", 4) || "-",
        Weighting:li.Weighting !== null &&  li.Weighting >= 0 ? parseFloat(ValueReturner(li.Weighting, 3)) : null,
        Date: moment.utc(li.Date).format(dateFormate) || "",
        "% at Receipt":li.PercentAtReceipt !== null &&  li.PercentAtReceipt >= 0 ? parseFloat(ValueReturner(li.PercentAtReceipt)) : null,
        // "% at Receipt": toDecimalORCurrency(li.PercentAtReceipt || "", 4) || "-",
        "Test Date At Receipt":
          "Invalid date" === li.TestDateAtReceipt ||
          null === li.TestDateAtReceipt
            ? "-"
            : moment.utc(li.TestDateAtReceipt).format(dateFormate) || "",
        "% at 3 Week": li.PercentAt3Week !== null && li.PercentAt3Week >= 0 ? parseFloat(ValueReturner(li.PercentAt3Week)) : null,
        // "% at 3 Week": toDecimalORCurrency(li.PercentAt3Week || "", 4) || "-",
        "Test Date At 3 Week":
          "Invalid date" === li.TestDateAt3Week || null === li.TestDateAt3Week
            ? "-"
            : moment.utc(li.TestDateAt3Week).format(dateFormate) || "",
        "% at Late": li.PercentAtLate !== null && li.PercentAtLate >= 0 ? parseFloat(ValueReturner(li.PercentAtLate)) : null,
        // "% at Late": toDecimalORCurrency(li.PercentAtLate || "", 4) || "-",
        "Test Date At Late":
          "Invalid date" === li.TestDateAtLate || null === li.TestDateAtLate
            ? "-"
            : moment.utc(li.TestDateAtLate).format(dateFormate) || "",
        "% Uncolored at 3 Week": li.PercentUncolored3Week !== null && li.PercentUncolored3Week >= 0 ? parseFloat(ValueReturner(li.PercentUncolored3Week)) : null ,
        // "% Uncolored at 3 Week": toDecimalORCurrency(li.PercentUncolored3Week || "", 4) || "-",
      };
    });
    // console.log("arrangedData", arrangedData)
    return arrangedData;
  };
  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  // common handler for multiselect
  const handlerOtherFilter = () => {
    setKeepingResultStatus(false);
    setHideExport(true);
  };
  // Mobile filter change event
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const export_kepingquality_format = [
    {
      index: 7,
      format: "#,##0.00_);($#,##0.00)",
    },
    {
      index: 8,
      format: "#,##0.0000_);($#,##0.0000)",
    },
    {
      index: 10,
      format: "#,##0.00_);($#,##0.00)",
    },
    {
      index: 12,
      format: "#,##0.00_);($#,##0.00)",
    },
    {
      index: 14,
      format: "#,##0.00_);($#,##0.00)",
    },
    {
      index: 16,
      format: "#,##0.00_);($#,##0.00)",
    },
  ];

  return (
    <>
      {/*<!-- Keeping quality block starts -->*/}
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="crop_year_label custom_dropdown">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={CropYears}
                    prompt="Select"
                    value={CY_MultiValue}
                    onChange={(val) => onCropYearChange(val)}
                    onDelete={(selectedList, removedItem) =>
                      onCropYearRemove(selectedList, removedItem)
                    }
                    label="cropyear"
                    usestate={setCY_MultiValue}
                    preSelectedOpt={defCropYearArr}
                    disabled=""
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="contract_number_Label custom_dropdown">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={ContractNumbers}
                    prompt="Select"
                    value={CN_MultiValue}
                    onChange={(val) => onContractNumberChange(val)}
                    onDelete={(selectedList, removedItem) =>
                      onContractNumberRemove(selectedList, removedItem)
                    }
                    label="contractid"
                    usestate={setCN_MultiValue}
                    disabled={CNDisabled}
                    optionNotFound={{ contract_id: noContractsFound }}
                    preSelectedOpt={contractPreselect}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div
                className="sample_type_label custom_dropdown"
                ref={sampleTypeRef}
              >
                <MultiDropDown
                  optionList={SampleTypes}
                  prompt="Select"
                  value={multiValue}
                  onChange={() => handlerOtherFilter()}
                  label="sampletypes"
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(selectedList, removedItem)
                  }
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div
                className="sample_status_label custom_dropdown"
                ref={sampleStatusRef}
              >
                <MultiDropDown
                  optionList={SampleStatuss}
                  prompt="Select"
                  value={multiValue}
                  onChange={() => handlerOtherFilter()}
                  label="samplestatuses"
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(selectedList, removedItem)
                  }
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={onAllSearch}
                disabled={disableSubmitCancel}
              >
                Search{!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={onClearSearch}
                disabled={disableClear}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? "show d-block " : " d-lg-none "
          }`}
        >
          {/* <button className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none" disabled="">Apply   </button> */}
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={onAllSearch}
            disabled={disableSubmitCancel}
          >
            Apply{!loading ? "" : <WhiteCircleLoader />}{" "}
          </button>
        </div>
      </nav>
      {/*<!-- Filter Dropdown ends -->*/}

      {/* show grid with Export buttons*/}
      <div className="row mt-2 hidden_print">
        <div className={`col-12 d-none ${hideExport ? "" : "d-lg-block"} `}>
          <div className="float-end mb-2">
            <span>
              <PdfFile
                pdfData={ArrangedData(keepingResult)}
                fileName="KeepingQuality"
              />
            </span>
            <span>
              <ExportFile
                downloadFormate="csv"
                exportData={ArrangedData(keepingResult)}
                fileName="KeepingQuality"
              />
            </span>
            <span>
              <ExportFile
                downloadFormate="xlsx"
                exportData={ArrangedData(keepingResult)}
                fileName="KeepingQuality"
                formatArray={
                  export_kepingquality_format
                }
              />
            </span>
          </div>
        </div>
      </div>
      {/*<!-- Desktop table starts -->*/}
      {keepingResult.length > 0 && keepingResultStatus && (
        <div
          className={`
      hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table`}
        >
          <DataGridPagination
            uniqueIdentifier="Id"
            gridData={keepingResult}
            columns={columns}
            isPagination={true}
            itemsPerPage={10}
            columnResize={false}
            classNames="contain-block-size-reset bot-mspace-20 border-radius-6 table-column-z-index-0"
            dynamicHeight={55}
            dynamicHeaderHeight={70}
            // autoRowHeight={true}
            // dynamicHeaderHeight={35}
            // paddingRowHeight={-20}
          />
        </div>
      )}
      {/*<!-- Desktop table ends -->*/}
      {/* <!-- Mobile table starts --> */}
      {keepingResultMobile.length > 0 && keepingResultStatus && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  d-lg-none  ${
            hideExport ? "d-none" : "d-lg-block"
          } `}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space">
            <Accordion defaultActiveKey="0">
              {keepingResultMobile.length > 0 &&
                Object.values(keepingResultMobile).map((data, index) => {
                  return (
                    <Fragment key={data.id}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          Crop Year: {data.CropYear}, Contract:{" "}
                          {data.RelationshipNumber}{" "}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div
                                className="td"
                                data-header="Contract #:"
                              ></div>
                              <div className="td" data-header="Contract #:">
                                {data.RelationshipNumber}
                              </div>
                              <div className="td" data-header="Contract Name:">
                                <span>{data.Name}</span>
                              </div>
                              <div className="td" data-header="Sample Type:">
                                {data.SampleType}
                              </div>
                              <div className="td" data-header="Location Code:">
                                {" "}
                                {data.LocationCode}
                              </div>
                              <div className="td" data-header="Sample Number:">
                                {data.SampleNumber}
                              </div>
                              <div className="td" data-header="Status:">
                                {data.StatusType}
                              </div>
                              <div className="td" data-header="Units:">
                                {ValueReturner(data.Crates, 2)}
                              </div>
                              <div className="td" data-header="Weighting:">
                                {ValueReturner(data.Weighting, 3)}
                              </div>
                              <div className="td" data-header="Date:">
                                <Moment utc format={dateFormate}>
                                  {data.Date}
                                </Moment>
                              </div>
                              <div className="td" data-header="% at Receipt:">
                                {ValueReturner(data.PercentAtReceipt, 2)}
                              </div>
                              <div className="td" data-header="% at 3 Week:">
                                {ValueReturner(data.PercentAt3Week, 2)}
                              </div>
                              <div className="td" data-header="% at Late:">
                                {ValueReturner(data.PercentAtLate, 2)}
                              </div>
                              <div
                                className="td"
                                data-header="% Uncolored 3 Week:"
                              >
                                {ValueReturner(data.PercentUncolored3Week, 2)}
                              </div>
                              <div
                                className="td"
                                data-header="3 Week Photo:"
                              >
                                {
                                   data.PhotoLink ? <a href={data.PhotoLink} target={"_blank"} rel="noreferrer">
                                   <img
                                     src="../../assets/images/single-cranberry.png"
                                     className="width-25"
                                   />
                                 </a>
                                 : <>No Photo Available</>
                                }
                                
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
          </div>
        </div>
      )}
      {/* <!-- Mobile table ends --> */}
      {/* no record found section start */}
      {noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
          <i className="fa fa-info-circle pe-2"></i>
          {notFoundMsg}
        </div>
      )}
      {/* no record found section End */}
      {/*<!-- Keeping quality block ends -->*/}
    </>
  );
};
export default KeepingQuality;
