/**
 * @component_Name : GoverningDoc
 * @description : Governing Doc
 * @company : Photon
 * @author : Ashish Ranjan/28-10-2022
 **/
import React from "react";
import PageLayout from "../../pageLayout";
import Body from "./body";
import GoverningDocumentsDetails from "./governingDocumentsDetails";

const GoverningDocuments = (props) => {
  const { isLeftMenu = true, ...rest } = props;
  return (
    <>
      <PageLayout
        {...rest}
        body={
          <Body
            isLeftMenu={isLeftMenu}
            {...rest}
            contentbody={<GoverningDocumentsDetails {...rest} />}
          />
        }
      />
    </>
  );
};

export default GoverningDocuments;
