import React, { useState, useEffect, Fragment } from "react";
import "../../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import MultiDropDown from "../../../selectBox/multiDropDown";
import { Accordion } from "react-bootstrap";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import Moment from "react-moment";
import moment from "moment";
import Loading from "../../../../components/global/loading";
import DataGridPagination from "../../../global/dataGridPagination";
import {
  IsTokenChecker,
  toDecimalORCurrency,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  IsArrayNotEmpty,
  IsStringChecker,
} from "../../../../utils/helper";
import { dateFormate } from "../../../../utils/constantsVariable";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../../../components/global/currentLocation";
import {
  ValidGBSRoleByContracts,
  FilterContractByRole,
  ImpersonationAccountsSwap
} from "../../../../permissionWrapper";
import { useGbsDataCache } from "../../../../services/gbsDataCache";

export const Deferments = (props) => {
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const dispatch = useDispatch();
  // const personId = accounts[0].idTokenClaims.gbsPersonId;
  const tokenId = useSelector((state) => state.reducerToken);

  const [ContractIds, setContractIds] = useState([]);
  const [CY_MultiValue, setCY_MultiValue] = useState({});
  const [preSelectedCropyear, setPreSelectedCropyear] = useState([]);
  const [CropYears, setCropYears] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);

  const [defermentsData, setDefermentsData] = useState([]);
  const [defermentsDataStatus, setDefermentsDataStatus] = useState(true);
  const [filterItems, setFilterItems] = useState([]);

  const { DropDownCBRoles = [] } = props;

  //Defining Grid Column
  const columnsArray = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      headerCellClass: "enablesTextWrappingHeading",
      width: 100,
    },
    {
      key: "Name",
      name: "Contract Name",
      cellClass: "enablesTextWrapping",
      width: 140,
    },
    {
      key: "Description",
      name: "Description",
      cellClass: "enablesTextWrapping",
      width: 165,
      formatter(props) {
        return <>{props.row.Description || "-"}</>;
      },
    },
    {
      key: "DefermentPercentage",
      name: "% Deferred",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center enablesTextWrapping",
      width: 100,
      formatter(props) {
        return (
          <>
            {props.row.DefermentPercentage
              ? props.row.DefermentPercentage * 100 + "%"
              : "-"}
          </>
        );
      },
    },
    {
      key: "DefferedDate",
      name: "Deferred Date",
      width: 100,
      dateFormate: dateFormate,
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      formatter(props) {
        return (
          <Moment utc format={dateFormate}>
            {props.row.DefferedDate}
          </Moment>
        );
      },
    },
    {
      key: "YtdDeferment",
      name: "Amount Deferred",
      headerCellClass: "grid_column_right enablesTextWrappingHeading",
      cellClass: "text-lg-end",
      width: 130,
      formatter(props) {
        return (
          <>
            {toDecimalORCurrency(props.row.YtdDeferment, 2, "$", true) || "-"}
          </>
        );
      },
    },
    {
      key: "ExpectedPayoutDate",
      name: "Expected payout date",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 120,
      dateFormate: dateFormate,
      formatter(props) {
        return (
          <>
            {props.row.ExpectedPayoutDate
              ? props.row.ExpectedPayoutDate.substring(0, 2) +
                "/" +
                props.row.ExpectedPayoutDate.substring(2)
              : "-"}
          </>
        );
      },
    },
    {
      key: "DefermentPeriod",
      name: "Month Deferred",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 100,
      formatter(props) {
        return <>{props.row.DefermentPeriod || "-"}</>;
      },
    },
  ];

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  useEffect(() => {
    if (data?.data) {
      getDeferments(data?.data);
    }
    // if (status === "error") {
    //   HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    // }
  }, [data, changeEvent, status]);
  //Cache Implementation End
  const [displayAccordian, setDisplayAccordian] = useState(false);

  async function fetCropYearAndContract() {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then(async (resp) => {
        const cropyears = safeArrayCheck(resp?.data);
        if (IsArrayNotEmpty(cropyears)) {
          const getcurrentcrop = cropyears.find((x) => x.CurrentCrop === true);
          const currentcropData =
            getcurrentcrop?.CropYear || cropyears[0]?.CropYear;
          const tempCropyears = [...cropyears].splice(0, 15);
          setCropYears(setOptions(tempCropyears, "CropYear", true));
          const filteredContracts = ValidGBSRoleByContracts(
            accounts,
            DropDownCBRoles,
            true,
            false
          );
          if (IsArrayNotEmpty(filteredContracts)) {
            const contractids = filteredContracts.join();
            setContractIds(contractids);
            const defaultContractNumber = `${_.orderBy(
              contractids.split(","),
              [],
              ["asc"]
            )}`;
            if (defaultContractNumber && currentcropData) {
              setClearStatus(true);
              setTimeout(() => {
                setPreSelectedCropyear([`${currentcropData}`]);
                setCY_MultiValue({ cropyear: `${currentcropData}` });
                setDisableSubmitCancel(false);
                setClearStatus(false);
              }, 0);

              const url = `payments/deferments?relationshipid=${defaultContractNumber}&p.cropyear=${currentcropData}`;
              setSearchURL(url);
              url === searchURL && setChangeEvent(changeEvent + 1);
            }
          }
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      setLoader(true);
      fetCropYearAndContract();

      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
  }, [tokenId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  // creating option for mulit select
  const setOptions = (data, keyPrram) => {
    return data
      .map((li) => li[keyPrram])
      .filter(onlyUnique)
      .map((li) => {
        return { name: li, id: li };
      });
  };

  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  // Get Grid API values
  const getDeferments = async (data) => {
    let resultData = [...data];
    let filteredData = FilterContractByRole(resultData, accounts, DropDownCBRoles);
    if (
      filteredData.length > 0 &&
      data !== "Contract and CropYear must be provided"
    ) {
      setFilterItems(filteredData);
    } else {
      setDefermentsData([]);
    }
    setLoading(false);
    setLoader(false);
  };

  //crop year select change event
  const onCropYearChange = (val) => {
    setNoResultsFound(false);
    let cropyear = setObjToString(val);
    let cropyears = `${_.orderBy(cropyear.split(","), [], ["asc"])}`;
    let contracts = `${_.orderBy(ContractIds.split(","), [], ["asc"])}`;
    if (IsStringChecker(cropyears) && IsStringChecker(contracts)) {
      setDisableSubmitCancel(false);
      let url = `payments/deferments?relationshipid=${contracts}&p.cropyear=${cropyears}`;
      setSearchURL(url);
      url === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  //crop year deselect change event
  const onCropYearRemove = (selectedList, removedItem) => {
    setNoResultsFound(false);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisableSubmitCancel(true);
    } else {
      let cropyear = setObjToString(selectedList);
      let cropyears = `${_.orderBy(cropyear.split(","), [], ["asc"])}`;
      let contracts = `${_.orderBy(ContractIds.split(","), [], ["asc"])}`;
      if (IsStringChecker(cropyears) && IsStringChecker(contracts)) {
        setDisableSubmitCancel(false);
        let url = `payments/deferments?relationshipid=${contracts}&p.cropyear=${cropyears}`;
        setSearchURL(url);
        url === searchURL && setChangeEvent(changeEvent + 1);
      }
    }
  };

  // convert object to string
  const setObjToString = (val) => {
    let ids = val.map((x) => x.id);
    ids = ids.filter((x) => x != "all" && x).toString();
    return ids;
  };

  // Mobile filter change event
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  // on click clear
  const onClearSearch = () => {
    setDefermentsData([]);
    setDefermentsDataStatus(false);
    setNoResultsFound(false);
    setFilterItems([]);
    setCY_MultiValue(null);
    setPreSelectedCropyear([]);
    setDisableSubmitCancel(true);
  };

  // On click search
  const onAllSearch = () => {
    let filter = [...filterItems];
    if (
      filter.length > 0 &&
      filter !== "Contract and CropYear must be provided"
    ) {
      setDefermentsData(filter);
      setDefermentsDataStatus(true);
      setNoResultsFound(false);
    } else {
      setDefermentsData([]);
      setDefermentsDataStatus(false);
      setNoResultsFound(true);
    }
    handlerMobFilter("cancel");
  };
  return (
    <>
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 p-1 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock payment-page-mobile-filter ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="crop_year_label custom_dropdown">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={CropYears}
                    prompt="Select"
                    value={CY_MultiValue}
                    onChange={(val) => onCropYearChange(val)}
                    onDelete={(selectedList, removedItem) =>
                      onCropYearRemove(selectedList, removedItem)
                    }
                    label="cropyear"
                    usestate={setCY_MultiValue}
                    preSelectedOpt={preSelectedCropyear}
                    disabled=""
                  />
                )}
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={onAllSearch}
                disabled={disableSubmitCancel}
              >
                Search{!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={onClearSearch}
                disabled={disableSubmitCancel}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? "show d-block " : " d-lg-none "
          }`}
        >
          {/* <button className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none" disabled="">Apply   </button> */}
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={onAllSearch}
            disabled={disableSubmitCancel}
          >
            Apply{!loading ? "" : <WhiteCircleLoader />}{" "}
          </button>
        </div>
      </nav>
      {/*<!-- Desktop table starts -->*/}
      {defermentsData.length > 0 &&
        defermentsDataStatus &&
        !displayAccordian && (
          <div
            className={`
      hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table`}
          >
            <DataGridPagination
              uniqueIdentifier="Id"
              gridData={defermentsData}
              columns={columnsArray}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
              autoRowHeight={true}
            />
          </div>
        )}
      {/*<!-- Desktop table ends -->*/}
      {/* <!-- Mobile table starts --> */}
      {defermentsData.length > 0 && defermentsDataStatus && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${
            !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
          }`}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion defaultActiveKey="0">
              {defermentsData.length > 0 &&
                Object.values(defermentsData).map((data, index) => {
                  return (
                    <Fragment key={data.id}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          {`Contract #: ${data.RelationshipNumber}, 
                      Deferred Date: ${moment(data.DefferedDate).format(
                        dateFormate
                      )}`}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div
                                className="td"
                                data-header="Contract #:"
                              ></div>
                              <div
                                className="td"
                                data-header="Contract Number:"
                              >
                                {data.RelationshipNumber}
                              </div>
                              <div className="td" data-header="Contract Name:">
                                <span>{data.Name}</span>
                              </div>
                              <div className="td" data-header="Description:">
                                {data.Description}
                              </div>
                              <div className="td" data-header="% Deferred:">
                                {" "}
                                {data.DefermentPercentage
                                  ? data.DefermentPercentage * 100 + "%"
                                  : "-"}
                              </div>
                              <div className="td" data-header="Deferred Date:">
                                <Moment utc format={dateFormate}>
                                  {data.DefferedDate}
                                </Moment>
                              </div>
                              <div
                                className="td"
                                data-header="Amount Deferred:"
                              >
                                {data.YtdDeferment
                                  ? toDecimalORCurrency(
                                      data.YtdDeferment,
                                      2,
                                      "$",
                                      true
                                    )
                                  : "-"}
                              </div>
                              <div
                                className="td"
                                data-header="Expected Payout Date:"
                              >
                                {data.ExpectedPayoutDate
                                  ? data.ExpectedPayoutDate.substring(0, 2) +
                                    "/" +
                                    data.ExpectedPayoutDate.substring(2)
                                  : "-"}
                              </div>
                              <div className="td" data-header="Month Deferred:">
                                {data.DefermentPeriod || "-"}
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
          </div>
        </div>
      )}
      {/* <!-- Mobile table ends --> */}
      {/* no record found section start */}
      {defermentsData.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
          <i className="fa fa-info-circle pe-2"></i>
          {moreLangs[i18n.language].payments_page.defermentsNotFoundMsg}
        </div>
      )}
      {/* no record found section End */}
      {/*<!-- Keeping quality block ends -->*/}
    </>
  );
};
