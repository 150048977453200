import React, { useState, useEffect } from "react";
import ImageContext from "./imageContext";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import { HandleErrorTrackAndToast } from "../../../utils/helper";

const ImageState = (props) => {
  const { accounts } = useMsal();
  const localAccountId = _.get(accounts, "0.localAccountId", "");
  const tokenId = useSelector((state) => state.reducerToken);
  const [statusSuccess, setStatusSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentProfileImage, setCurrentProfileImage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const updateProfileImage = async (fileInput) => {
    let value = URL.createObjectURL(fileInput["profilePic"]);
    setCurrentProfileImage(value);
    setLoading(true);
    const formData = new FormData();
    formData.append("uploaded_file", fileInput["profilePic"]);
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/portal-account/avatars/${localAccountId}`,
      "POST",
      formData,
      { "Content-type": "multipart/form-data" }
    )
      .then((resp) => {
        setLoading(false);
        if (resp.data.status == "success") {
          setStatusSuccess(true);
          setTimeout(() => {
            setStatusSuccess(false);
          }, 0);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
    setLoading(false);
  };

  return (
    <ImageContext.Provider
      value={{
        statusSuccess,
        loading,
        currentProfileImage,
        updateProfileImage,
      }}
    >
      {props.children}
    </ImageContext.Provider>
  );
};

export default ImageState;
