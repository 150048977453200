import React from "react";
import PageLayout from "../pageLayout";
import Body from "./body";
import MyHarvestDetails from "./myHarvestDetails";

const MyHarvest = (props) => {
  return (
    <PageLayout {...props} body={<Body {...props} isLeftMenu ="true" quickLinksSlug="my-harvest" contentbody={<MyHarvestDetails {...props} />} />} />
  );
};

export default MyHarvest;
