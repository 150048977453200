/**
 * @component_Name : useToaster
 * @description : Toast Message Renderer Based on Redux Array Value
 * @company : Photon
 * @author : Nivethithan Kannan/23-02-2023
 **/
import React, { Fragment } from "react";
import { Row, Col, Toast, ToastContainer } from "react-bootstrap";
import {
  IsStringChecker,
  getResolvedLanguage,
  IsArrayNotEmpty,
  GetUniqueID,
} from "../../utils/helper";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import { useSelector, useDispatch } from "react-redux";
import { DeleteToastMsg } from "../../services/redux/actions";
import constantsVariable from "../../utils/constantsVariable";
import { HandleErrorTrackAndToast } from "../../utils/helper";

const useToaster = () => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { ToastProperties = [] } = state;

  const TranslatedDataProvider = (prop = null, def = "") => {
    try {
      const data = _.get(moreLangs, `${resolvedLanguage}.${prop}`, "");
      return IsStringChecker(data) ? data : def;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return def;
    }
  };

  const setShowToast = (id) => {
    dispatch(DeleteToastMsg(id));
  };

  const { defaultToastProperties = {}, defaultToastMsgProperties = {} } =
    constantsVariable;
  const defaultTitle = TranslatedDataProvider("toast_msg.title", `Error`);
  const defaultMessage = TranslatedDataProvider(
    "toast_msg.message",
    `Sorry, an error has occured`
  );
  return (
    <Row>
      <Col xs={6}>
        <ToastContainer
          containerPosition={defaultToastProperties.containerPosition}
          position={defaultToastProperties.position}
          className="p-3"
        >
          {IsArrayNotEmpty(ToastProperties) ? (
            ToastProperties.map((toastProperties) => {
              const {
                id = GetUniqueID(),
                title = "",
                message = "",
                bg = defaultToastMsgProperties.bg,
                color = defaultToastMsgProperties.color,
                display = defaultToastMsgProperties.display,
                delay = defaultToastMsgProperties.delay,
                autoHide = defaultToastMsgProperties.autoHide,
              } = toastProperties;
              switch (display) {
                case "content":
                  return (
                    <Toast
                      className={`border border-2 ${
                        IsStringChecker(color) ? `border-${color}` : ""
                      }`}
                      key={id}
                      bg={bg}
                      delay={delay}
                      autohide={autoHide}
                      onClose={() => setShowToast(id)}
                      show={defaultToastProperties.showToast}
                      animation={defaultToastProperties.animation}
                    >
                      <Toast.Body
                        className={`${
                          IsStringChecker(color) ? `text-${color}` : ""
                        } toaster-flex`}
                      >
                        <strong className="me-auto">
                          {IsStringChecker(message) ? message : defaultMessage}
                        </strong>
                        <span
                          className="cursor-pointer"
                          onClick={() => setShowToast(id)}
                        >
                          <i className="fa fa-times-circle"></i>
                        </span>
                      </Toast.Body>
                    </Toast>
                  );
                case "complete":
                  return (
                    <Toast
                      className={`border border-2 ${
                        IsStringChecker(color) ? `border-${color}` : ""
                      }`}
                      key={id}
                      bg={bg}
                      delay={delay}
                      autohide={autoHide}
                      onClose={() => setShowToast(id)}
                      show={defaultToastProperties.showToast}
                      animation={defaultToastProperties.animation}
                    >
                      <Toast.Header
                        closeButton={defaultToastProperties.closeButton}
                        closeLabel={defaultToastProperties.closeLabel}
                        closeVariant={color}
                      >
                        <strong className={`me-auto`}>
                          {IsStringChecker(title) ? title : defaultTitle}
                        </strong>
                      </Toast.Header>
                      <Toast.Body
                        className={`${
                          IsStringChecker(color) ? `text-${color}` : ""
                        }`}
                      >
                        {IsStringChecker(message) ? message : defaultMessage}
                      </Toast.Body>
                    </Toast>
                  );
                default:
                  return <Fragment key={id} />;
              }
            })
          ) : (
            <Fragment />
          )}
        </ToastContainer>
      </Col>
    </Row>
  );
};

export default useToaster;
