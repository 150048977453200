import React, { useEffect,useState } from "react";
import { Accordion } from "react-bootstrap";
import ContractPerformanceData from "./myDeliveryData/contractPerformanceData";
import DeliveryData from "./myDeliveryData/deliveryData";
import KeepingQuality from "./myDeliveryData/keepingQuality";
import RecentDelivery from "./myDeliveryData/recentDelivery";
import { useHistory } from "react-router-dom";
import Events from "../home/siteNotification";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import { getResolvedLanguage } from "../../utils/helper";
import { ImpersonationAccountsSwap } from "../../permissionWrapper";

const MyDeliveryDataDetails = (props) => {
  const { RoleCBPrivileges = {}, SpecialRoleExist } = props;

  /*getting url and convert into array*/
  const location = useHistory().location;
  const arrayLocation = location.hash;
  const [seletedTab, setSeletedTab] = useState([]);
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);

  function RoleBasedAccessDS(special_role, priviledges) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService = false } = priviledges;
        return roleCBThirdPartyFarmService;
      } else {
        const { roleCBAcreage = false, roleCBDecisionMaker = false } =
          priviledges;
        return roleCBAcreage || roleCBDecisionMaker;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;

  const handlerAccordion = (e) => {
    //e.stopPropagation
    if (e !== null) {
      // if e === null, that means that an accordion item was collapsed rather than expanded. e will be non-null when an item is expanded
      localStorage.setItem("expandedDeliveryItem", e);
      let selectTab = [...seletedTab, ...e];
      setSeletedTab([...selectTab]);

    } else {
      localStorage.setItem("expandedDeliveryItem", 0);
    }
  };

  let retainAccordion = "";
  if (arrayLocation != "" || localStorage.expandedDeliveryItem != "") {
    if (arrayLocation != "") {
      retainAccordion = arrayLocation.replace(/#/g, "");
    } else {
      retainAccordion = localStorage.expandedDeliveryItem
        ? localStorage.expandedDeliveryItem.split(",")
        : ["5"];
    }
  }

  let retainAccordionForSet = "";
  useEffect(() => {
    if (arrayLocation != "" || localStorage.expandedDeliveryItem != "") {
      if (arrayLocation != "") {
        retainAccordionForSet = arrayLocation.replace(/#/g, "");
        setSeletedTab([retainAccordionForSet]);
      } else {
        retainAccordionForSet = localStorage.expandedDeliveryItem
          ? localStorage.expandedDeliveryItem.split(",")
          : ["5"];
          setSeletedTab([...retainAccordionForSet]);
      }
    }
  }, []);
  
  const displaySection = RoleBasedAccessDS(SpecialRoleExist, RoleCBPrivileges);
  return (
    <>
      <div className="col-12">
        <div className="mt-5 mb-3 mt-lg-0 mb-lg-4">
          <Events type={"my-delivery-data"} />
          {/*<!-- Accordion blocks starts -->*/}
          <Accordion
            defaultActiveKey={retainAccordion}
            alwaysOpen
            id="accordionMyAcreage"
            onSelect={handlerAccordion}
            className="accordion mt-0"
          >
            {displaySection && <Accordion.Item eventKey="recentDelivery">
              <Accordion.Header>
                <img
                  src="../assets/images/icon_harvest_data_black.svg"
                  className="img-30 right-space-10"
                  alt="Contracts_icon"
                />{" "}
                Recent Deliveries
              </Accordion.Header>

              <Accordion.Body>
                {/*RecentDeliveries*/}
                {seletedTab.indexOf("recentDelivery") > -1 && <RecentDelivery {...props} noContractsFound={noContractsFound} />}
                
                {/*End RecentDeliveries*/}
              </Accordion.Body>
            </Accordion.Item>}

            {displaySection && <Accordion.Item eventKey="deliveryData">
              <Accordion.Header>
                <img
                  src="../assets/images/icon_folder.svg"
                  className="img-30 right-space-10"
                  alt="acerage_icon"
                />{" "}
                Delivery Data
              </Accordion.Header>

              <Accordion.Body>
                {/*DeliveryData*/}
                {seletedTab.indexOf("deliveryData") > -1 &&  <DeliveryData {...props} noContractsFound={noContractsFound} /> }
                {/*End DeliveryData*/}
              </Accordion.Body>
            </Accordion.Item>}

            {displaySection && accounts[0]?.idTokenClaims?.gbsKqQualified != 0 && (
              <Accordion.Item eventKey="keepingQuality">
                <Accordion.Header>
                  <img
                    src="../assets/images/icon_like.svg"
                    className="img-30 right-space-10"
                    alt="acerage_icon"
                  />{" "}
                  Keeping Quality
                </Accordion.Header>

                <Accordion.Body>
                  {/*Keeping Quality*/}
                  {seletedTab.indexOf("keepingQuality") > -1 && <KeepingQuality {...props} noContractsFound={noContractsFound} />}
                  {/*End KeepingQuality*/}
                </Accordion.Body>
              </Accordion.Item>
            )}
            
            {displaySection && <Accordion.Item eventKey="contractPerformanceReport">
              <Accordion.Header>
                <img
                  src="../assets/images/icon_good_quality.svg"
                  className="img-30 right-space-10"
                  alt="acerage_icon"
                />{" "}
                Contract Performance Report
              </Accordion.Header>

              <Accordion.Body>
                {/*ContractPerformanceReport*/}
                {seletedTab.indexOf("contractPerformanceReport") > -1 && <ContractPerformanceData {...props} noContractsFound={noContractsFound} />}
                {/*End ContractPerformanceReport*/}
              </Accordion.Body>
            </Accordion.Item>}
          </Accordion>
          {/*<!-- Accordion blocks ends -->*/}
        </div>
      </div>
    </>
  );
};

export default MyDeliveryDataDetails;
