import React from "react";
import Events from "./siteNotification";
import Harvest from "./newharvest"; //New Quick View
// import Harvest from "./harvest"; //Old Quick View
// import Weather from "./weather";
import News from "./news";
import UpcomingEvents from "./upcomingEvents";
import QuickLinks from "./quickLinks";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import _ from "lodash";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../services/gbsData";
import mu from "../../utils/index";
import Loading from "../../components/global/loading";
import {
  IsArrayNotEmpty,
  IsTokenChecker,
  trailZeros,
  getResolvedLanguage,
  IsStringChecker,
  compareString,
  IsNumberChecker,
  StringToInteger,
  IsObjectChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import { ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../permissionWrapper";
import CurrentLocation from "../global/currentLocation";
import ForumWidget from "./forumWidget/forumWidget";
import PollWidget from "./pollsWidget/pollsWidget";
{/*Survey uncomment once live went */}
import SurveyWidget from "./surveyWidget/surveysWidget";

const Body = (props) => {
  const [harvestLoading, setHarvestLoading] = useState(false);

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const { CommonPageCBRoles = [], RoleCBPrivileges, SpecialRoleExist } = props;

  const [siteNotification, setSiteNotification] = useState(null);
  const [cookies, setCookie] = useCookies([]);

  const [harvestData, setHarvestData] = useState([]);
  const [asyncData, setAsyncData] = useState([]);

  useEffect(()=>{
    sessionStorage.setItem("newsfrom","home");
  },[])
  
  const getNew = async () => {
    await cmsDataCommonMethod(
      idToken,
      msal_accounts,
      `site_notifications?select=id,content_en,content_es,content_fr,publish_date_from,publish_date_to,page_listings(slug)&page_listings.slug=eq./&order=created_at.desc`
    )
      .then((resp) => {
        if (cookies.dismissed_site_notifications !== undefined) {
          let noti = _.filter(
            resp.data.data,
            (notification) =>
              !cookies.dismissed_site_notifications.includes(notification.id)
          );
          noti == [] ? setSiteNotification(null) : setSiteNotification(noti);
        } else {
          setSiteNotification(resp.data.data);
        }
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const handleDismiss = (id) => {
    if (cookies.dismissed_site_notifications !== undefined) {
      let ids = cookies.dismissed_site_notifications;
      if (!ids.includes(id)) {
        ids = [...ids, id];
        setCookie("dismissed_site_notifications", JSON.stringify(ids));
        let noti = _.filter(
          siteNotification,
          (notification) => !ids.includes(notification.id)
        );
        if (JSON.stringify(noti) === "[]") {
          noti = null;
        }
        setSiteNotification(noti);
      }
    } else {
      let ids = [];
      ids.push(id);
      setCookie("dismissed_site_notifications", JSON.stringify(ids));
      let noti = _.filter(
        siteNotification,
        (notification) => !ids.includes(notification.id)
      );
      if (JSON.stringify(noti) === "[]") {
        noti = null;
      }
      setSiteNotification(noti);
    }
  };

  const getBeds = (data, resultData = []) => {
    try {
      const new_arr = Object.keys(data);
      if (IsArrayNotEmpty(new_arr)) {
        return new_arr.map((list) => {
          const arr = _.get(data, list, []);
          const beds = arr.map((li) => li["HarvestBed"]).join("/");
          const bedHistoryIds = arr.map((li) => li["BedHistoryId"]).join("/");
          return { ...arr[0], beds, bedHistoryIds };
        });
      }
      return resultData;
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return resultData;
    }
  };

  const fetchHarvestData = async () => {
    setHarvestLoading(true);
    const filteredContracts = ValidGBSRoleByContracts(
      accounts,
      CommonPageCBRoles,
      true,
      false
    );
    const contractids = mu.arrayToString(filteredContracts);
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `deliveries?d.relationshipid=${contractids}&limit=15&status=3400,3401,3402,3403,3404&orderby=IncomingTime.desc`
    )
      .then(async (response) => {
        const resultData = _.get(response, "data", []);
        if (IsArrayNotEmpty(resultData)) {
          const data = _.groupBy(resultData, "DeliveryId");
          const temp = getBeds(data, resultData);
          const sortedArray = _.orderBy(temp, ["IncomingTime"], ["desc"]);
          const display = sortedArray.slice(0, 3);
          setHarvestData(display);
          setHarvestLoading(false);
          fetchAsyncData(display);
        } else {
          setHarvestData([]);
          setAsyncData([]);
          setHarvestLoading(false);
        }
      })
      .catch((e) => {
        setHarvestData([]);
        setAsyncData([]);
        setHarvestLoading(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const LocalTrans = (str, def) => {
    try {
      if (IsStringChecker(str)) {
        return `${_.get(
          moreLangs,
          `${resolvedLanguage}.home_page.${str}`,
          def
        )}`;
      }
      return def;
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return def;
    }
  };

  const fetchAsyncData = async (param) => {
    try {
      const data = IsArrayNotEmpty(param)
        ? param.map((li) => {
            return {
              DeliveryId: li.DeliveryId,
              LoadDeliveryTicketNumber: li.LoadDeliveryTicketNumber,
              CropYear: li.CropYear,
              LocationCode: li.LocationCode,
            };
          })
        : [];
      if (IsArrayNotEmpty(data)) {
        const finalData = await Promise.all(
          data.map(async (item, index) => {
            const {
              DeliveryId,
              LoadDeliveryTicketNumber,
              CropYear,
              LocationCode,
            } = item;

            const id = trailZeros(LoadDeliveryTicketNumber, 4);
            const imgUrl = `deliveries/photo-retain/${CropYear}-${LocationCode}/${id}`;
            const imgConfig = {
              "Content-Type": "application/json",
              responseType: "blob",
            };
            await gbsDataCommonMethod(
              idToken,
              accounts,
              imgUrl,
              "GET",
              {},
              imgConfig
            )
              .then((resp) => {
                const testBlob = resp.data;
                data[index]["img"] = testBlob;
              })
              .catch((e) => {
                data[index]["img"] = null;
                HandleErrorTrackAndToast(e, true, "replace", dispatch);
              });

            const calcUrl = `deliveries/${DeliveryId}/calculations?calculationid=36,7`;
            await gbsDataCommonMethod(idToken, accounts, calcUrl)
              .then((resp) => {
                const calculations = _.get(resp, "data", []);
                if (IsArrayNotEmpty(calculations)) {
                  const initialCalc = { poor: 0, class_1: 0 };
                  const finalCalculations = calculations.reduce((acc, item) => {
                    try {
                      const { Value, Key } = item;
                      const calcValue = StringToInteger(Value) || 0;
                      if (compareString(Key, "%Poor")) {
                        const { poor } = acc;
                        if (IsNumberChecker(poor) && poor > 0) {
                          const logic = (calcValue + poor).toFixed(4);
                          return { ...acc, poor: logic };
                        } else {
                          return { ...acc, poor: calcValue };
                        }
                      } else if (compareString(Key, "%Class1")) {
                        return { ...acc, class_1: calcValue };
                      } else {
                        return acc;
                      }
                    } catch (e) {
                      HandleErrorTrackAndToast(e, true);
                      return initialCalc;
                    }
                  }, initialCalc);
                  const { poor = 0, class_1 = 0 } = IsObjectChecker(
                    finalCalculations
                  )
                    ? finalCalculations
                    : initialCalc;
                  const finalCalcs = `${LocalTrans(
                    "poor",
                    "%Poor"
                  )}: ${poor} | ${LocalTrans(
                    "class_1",
                    "%Class1"
                  )}: ${class_1}`;
                  data[index]["calculations"] = finalCalcs;
                } else {
                  data[index]["calculations"] = "";
                }
              })
              .catch((e) => {
                data[index]["calculations"] = "";
                HandleErrorTrackAndToast(e, true, "replace", dispatch);
              });

            return data[index];
          })
        );
        setAsyncData([...finalData]);
      } else {
        setAsyncData([]);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setAsyncData([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  function RoleBasedAccessNDS(special_role, priviledges) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService = false } = priviledges;
        return !roleCBThirdPartyFarmService;
      } else {
        return true;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function RoleBasedAccessDS(special_role, priviledges) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService = false } = priviledges;
        return roleCBThirdPartyFarmService;
      } else {
        const { roleCBAcreage = false, roleCBDecisionMaker = false } =
          priviledges;
        return roleCBAcreage || roleCBDecisionMaker;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      const access = RoleBasedAccessDS(SpecialRoleExist, RoleCBPrivileges);
      const access2 = RoleBasedAccessNDS(SpecialRoleExist, RoleCBPrivileges);
      getQuickAccess();
      if (access2) {
        getNew();
      }
      if (access) {
        fetchHarvestData();
      }
    }

    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken, SpecialRoleExist, RoleCBPrivileges]);
  const displaySection = RoleBasedAccessDS(SpecialRoleExist, RoleCBPrivileges);
  const [isHarvestQuickDataAccess, setIsHarvestQuickDataAccess] =
    useState(false);
  const getQuickAccess = async () => {
    setHarvestLoading(true);
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `call_to_actions?select=*,is_active,page_listings!inner(slug)&page_listings.slug=eq.harvest-data-quick-view&is_active=eq.true&order=order_by.asc`
    )
      .then((resp) => {
        if (resp?.data?.data?.length > 0) {
          setIsHarvestQuickDataAccess(true);
        }
        setHarvestLoading(false);
      })
      .catch((e) => {
        setHarvestLoading(false);
        setIsHarvestQuickDataAccess(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
      .finally(() => {
        setHarvestLoading(false);
      });
  };

  // Event Effect
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    // console.log(window.innerWidth,windowWidth)
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  const onResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const harvest_access = displaySection && isHarvestQuickDataAccess;
  const other_access = RoleBasedAccessNDS(SpecialRoleExist, RoleCBPrivileges);
  return (
    <>
      {harvestLoading ? (
        <>
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </>
      ) : null}
      <div className="row">
        <div className="col-12 col-lg-12 order-lg-first order-last">
          <Events click={() => handleDismiss} type={"/"} />
        </div>
        {/* <div className="col-lg-4 weather-report mb-2">
          <Weather />
        </div> */}
      </div>
      <div className="row">
        <div className="col-12 col-lg-4 d-lg-none mb-4">
          {windowWidth <= 992 && (
            <QuickLinks {...props} slug={"/"} designType="accordion" />
          )}
          {windowWidth <= 992 && (
            <SurveyWidget {...props} slug={"/"} designType="accordion" />
          )}
        </div>
        <div className="col-lg-8">
          {harvest_access && (
            <Harvest
              notification={siteNotification}
              harvestData={harvestData}
              asyncData={asyncData}
              CommonPageCBRoles={CommonPageCBRoles}
            />
          )}
          {other_access && (
            <News {...props} selectedNews={"news"} newsFor={"home"} />
          )}
        </div>
        {/* <div className="col-12 col-lg-4" style={{ marginTop: 30 }}> */}
        <div className="col-12 col-lg-4">
          {other_access && <UpcomingEvents {...props} />}
          {other_access && <PollWidget {...props} slug={"/"} />}
          <div className="d-block-desktop">
            <QuickLinks {...props} slug={"/"} />
          </div>
          {other_access && <SurveyWidget {...props} slug={"/"} />}
          {other_access && <ForumWidget {...props} />}
          {/* <div className="d-lg-none weather-report-bottom">
            <Weather />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Body;
