import React, { useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import Loading from "../../global/loading";
import { useLocation } from "react-router-dom";
import {
  IsStringChecker,
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import EventTime from "../../community/events/eventTime";
import EventLocation from "../../community/events/eventLocation";
// import { defaultEventImageURL } from "../../../utils/constantsVariable";
import ContentModifier from "../../global/contentModifier";
import { BreadCrumbForNewsEvents } from "../../global/breadCrumb";
const Main = (props) => {
  const { i18n } = useTranslation();
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.reducerToken);
  const { postId } = useParams();
  const [harvest, setHarvest] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const newsEvents = IsStringChecker(pathname)
    ? pathname.includes("community/news-events")
      ? true
      : false
    : false;
  const [points, setPoints] = useState({
    x: 0,
    y: 5,
    z: 5,
    zoom: 1,
    fontSize: 12,
    fontSize1: 14,
    fontSize2: 16,
    fontSize3: 18,
    fontSize4: 28,
  });
  const [disablePointerIn, setDisablePointerIn] = useState(false);
  const [disablePointerOut, setDisablePointerOut] = useState(true);
  const componentRef = useRef();

  const newsDetail = async () => {
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `news_events?select=id,is_active,event_date_from,event_date_to,event_location,title_en,slug,title_fr,title_es,description_en,description_fr,show_comments,description_es,news_event_comment_likes(id,like_name),like_count,posting_date,post_image,url,authors(title_en,title_fr,title_es,author_image_url,job_title),news_event_comments!fk_news_events_id(id,comment,comment_name,comments_id,created_at,like_count,news_event_comments(comment,created_at,like_count,comment_name),news_event_comment_likes(id,like_name))&id=eq.${postId}`
    )
      .then((resp) => {
        const results =
          resp.data.data?.length > 0
            ? applyParentElementForIframeVideo(resp.data.data)
            : [];
        setHarvest(results[0]);
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const applyParentElementForIframeVideo = (o) => {
    return o.map((i) => {
      let el = i["description_" + i18n.language];
      if (
        el.indexOf("https://players.brightcove.net/") > -1 &&
        el.indexOf("video-iframe") === -1
      ) {
        // console.log(i.title_en);
        let element = document.createElement("div");
        element.innerHTML = el;
        let a = element.querySelectorAll("iframe");
        a.forEach((e, i) => {
          if (!e.classList.contains("ql-video")) {
            let b = document.createElement("div");
            b.className = "video-iframe";
            b.innerHTML = e.outerHTML;
            element.querySelectorAll("iframe")[i].outerHTML = b.outerHTML;
          }
        });
        i["description_" + i18n.language] = element.innerHTML;
      }
      return i;
    });
  };
  useEffect(() => {
    IsTokenChecker(idToken) && newsDetail();
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);
  const ZoomIn = () => {
    if (points.y <= 5 && points.z >= 5 && points.z < 10) {
      setPoints({
        x: points.x + 1,
        y: points.y - 1,
        z: points.z + 1,
        zoom: points.zoom + 0.1,
        fontSize: points.fontSize + 2,
        fontSize1: points.fontSize1 + 2,
        fontSize2: points.fontSize2 + 2,
        fontSize3: points.fontSize3 + 2,
        fontSize4: points.fontSize4 + 2,
      });
      setDisablePointerIn(false);
      setDisablePointerOut(false);
    } else {
      setDisablePointerIn(true);
      setDisablePointerOut(false);
    }
  };
  const ZoomOut = () => {
    if (points.z > 5 && points.z <= 10) {
      setPoints({
        x: points.x - 1,
        y: points.y + 1,
        z: points.z - 1,
        zoom: points.zoom - 0.1,
        fontSize: points.fontSize - 2,
        fontSize1: points.fontSize1 - 2,
        fontSize2: points.fontSize2 - 2,
        fontSize3: points.fontSize3 - 2,
        fontSize4: points.fontSize4 - 2,
      });
      setDisablePointerIn(false);
      setDisablePointerOut(false);
    } else {
      setDisablePointerIn(false);
      setDisablePointerOut(true);
    }
  };
  const Normal = () => {
    setDisablePointerIn(false);
    setDisablePointerOut(true);
    setPoints({
      x: 0,
      y: 5,
      z: 5,
      zoom: 1,
      fontSize: 12,
      fontSize1: 14,
      fontSize2: 16,
      fontSize3: 18,
      fontSize4: 28,
    });
  };
  return harvest.event_date_from == null ? (
    <>
      <div className="row mt-3">
        <div className="col-12 offset-lg-1 col-lg-6 d-none d-lg-block ">
          <BreadCrumbForNewsEvents
            {...props}
            type={true}
            harvest={harvest}
            newsEvents={newsEvents}
          />
        </div>
        <div className="col-12 col-lg-4 d-none d-lg-block ">
          <div className="float-end  pt-5 pt-lg-4">
            <i
              className={
                disablePointerOut
                  ? "fa fa-minus blue_color cursor_pointer me-2 font-zoom-out text-14 disablePointer"
                  : "fa fa-minus blue_color cursor_pointer me-2 font-zoom-out text-14"
              }
              onClick={ZoomOut}
            ></i>

            <span
              className="blue_color cursor_pointer position-relative me-2 font-zoom-normal text-24 fw-bold"
              onClick={Normal}
            >
              A
            </span>

            <i
              className={
                disablePointerIn
                  ? "fa fa-plus blue_color cursor_pointer me-2 font-zoom-in text-14 disablePointer"
                  : "fa fa-plus blue_color cursor_pointer me-2 font-zoom-in text-14"
              }
              onClick={ZoomIn}
            ></i>

            <img
              src={`${window.location.origin}/assets/images/icon_print_light_grey.svg`}
              className="img-40 me-2 mb-2 ms-2 cursor_pointer"
              alt="Print_Icon"
              onClick={() => {
                window.print();
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {harvest.authors !== undefined ? (
          <div
            className="col-12 col-lg-10 offset-0 offset-lg-1"
            ref={componentRef}
          >
            <div className="row">
              <div className="col-12">
                <div className="card bot-mspace-20 card-shadow news_block mt-4 mt-lg-0">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-lg-7 order-lg-first order-last">
                        <h3
                          className="mt-sm-3"
                          style={{ fontSize: points.fontSize4 }}
                        >
                          {harvest["title_" + i18n.language]}
                        </h3>

                        <p style={{ fontSize: points.fontSize1 }}>
                          <Moment format="MMM DD, YYYY">
                            {harvest.posting_date}
                          </Moment>
                        </p>
                      </div>
                      <div className="col-12 col-lg-5">
                        <div className="d-flex align-items-center justify-content-lg-end pe-0 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none">
                          <img
                            src={
                              harvest.authors.author_image_url ===
                                "image-url-here" ||
                              harvest.authors.author_image_url == null
                                ? `${window.location.origin}/assets/images/os-avatar.jpg`
                                : harvest.authors.author_image_url
                            }
                            alt="chamber_person"
                            className="card-img-top border-radius-50 news_profile_img"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = `{${window.location.origin}/assets/images/profie-img.jpg`;
                            }}
                          />
                          <h6
                            className="float-start ps-2 d-block"
                            style={{ fontSize: points.fontSize }}
                          >
                            <span className="fw-bold">
                              {harvest.authors["title_" + i18n.language]}
                            </span>
                            <span className="d-block pt-1">
                              {" "}
                              {harvest.authors["job_title"]}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="row News-Tile-Videos mt-2">
                      <div className="col-12 desktop-news-detail-padding order-lg-first order-last">
                        <div className="row">
                          {IsStringChecker(harvest.post_image) && (
                            <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                              <img
                                src={
                                  harvest.post_image === null ||
                                  harvest.post_image === ""
                                    ? `${window.location.origin}/assets/images/os-avatar.jpg`
                                    : harvest.post_image
                                }
                                className="card-img-top"
                                alt="upcoming_news"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = `${window.location.origin}assets/images/profie-img.jpg`;
                                }}
                              />
                            </div>
                          )}
                          <div
                            className={`col-12 ${
                              IsStringChecker(harvest.post_image) && `col-lg-8`
                            }`}
                          >
                            <p
                              className="ql-editor"
                              style={{ fontSize: points.fontSize1 }}
                            >
                              {/* <div
                            dangerouslySetInnerHTML={{
                              __html: harvest["description_" + i18n.language],
                            }}
                          ></div> */}
                              <div
                                className="quill-editor ql-container ql-snow"
                                style={{
                                  height: "auto",
                                  border: "none",
                                  fontFamily: "unset",
                                  fontSize: points.fontSize1,
                                }}
                              >
                                <ContentModifier
                                  str={harvest["description_" + i18n.language]}
                                  imgClass={false}
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="nav-mobile-overlay-display"></div>
            <Loading />
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div className="row mt-3">
        <div className="col-12 offset-lg-1 col-lg-6 d-none d-lg-block ">
          <BreadCrumbForNewsEvents
            {...props}
            type={false}
            harvest={harvest}
            newsEvents={newsEvents}
          />
        </div>
        <div className="col-12 col-lg-4 d-none d-lg-block ">
          <div className="float-end  pt-5 pt-lg-4">
            <i
              className={
                disablePointerOut
                  ? "fa fa-minus blue_color cursor_pointer me-2 font-zoom-out text-14 disablePointer"
                  : "fa fa-minus blue_color cursor_pointer me-2 font-zoom-out text-14"
              }
              onClick={ZoomOut}
            ></i>

            <span
              className="blue_color cursor_pointer position-relative me-2 font-zoom-normal text-24 fw-bold"
              onClick={Normal}
            >
              A
            </span>

            <i
              className={
                disablePointerIn
                  ? "fa fa-plus blue_color cursor_pointer me-2 font-zoom-in text-14 disablePointer"
                  : "fa fa-plus blue_color cursor_pointer me-2 font-zoom-in text-14"
              }
              onClick={ZoomIn}
            ></i>

            <img
              src={`${window.location.origin}/assets/images/icon_print_light_grey.svg`}
              className="img-40 me-2 mb-2 ms-2 cursor_pointer"
              alt="Print_Icon"
              onClick={() => {
                window.print();
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-10 offset-0 offset-lg-1 ">
          <div className="card bot-mspace-20 card-shadow news_block mt-5 mt-lg-0">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-7 order-lg-first order-last">
                  <h3
                    className="mt-sm-2"
                    style={{ fontSize: points.fontSize4 }}
                  >
                    {harvest["title_" + i18n.language]}
                  </h3>
                  {/* <p className="fw-bold mb-0">Cranberry Festival 2019</p> */}
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <div className="upcoming_location">
                        {harvest.event_date_from !== null &&
                          harvest.event_date_to !== null && (
                            <>
                              <i className="fa fa-clock-o marker_icon"></i>
                              <p>
                                <EventTime
                                  event_date_from={harvest.event_date_from}
                                  event_date_to={harvest.event_date_to}
                                  locale={true}
                                  timezone={true}
                                />
                              </p>
                            </>
                          )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="upcoming_location">
                        {harvest.event_location !== null &&
                          harvest.event_location?.replace(
                            /(<([^>]+)>)/gi,
                            ""
                          ) !== "" && (
                            <>
                              <i className="fa fa-map-marker marker_icon ms-1"></i>
                              {IsStringChecker(harvest.event_location) && (
                                <EventLocation
                                  event_location={harvest.event_location}
                                />
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div className="d-flex align-items-center justify-content-lg-end pe-0 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none">
                    <img
                      src={
                        harvest.authors.author_image_url === "image-url-here" ||
                        harvest.authors.author_image_url == null
                          ? `${window.location.origin}/assets/images/os-avatar.jpg`
                          : harvest.authors.author_image_url
                      }
                      alt="chamber_person"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `{${window.location.origin}/assets/images/profie-img.jpg`;
                      }}
                      className="card-img-top border-radius-50 news_profile_img float-start"
                    />
                    <h6
                      className="float-start ps-2 d-block print_font"
                      style={{ fontSize: points.fontSize }}
                    >
                      <span className="fw-bold">
                        {harvest.authors["title_" + i18n.language]}
                      </span>
                      <span className="d-block pt-1">
                        {harvest.authors["job_title"]}
                      </span>{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row News-Tile-Videos mt-2">
                <div className="col-12 desktop-news-detail-padding order-lg-first order-last">
                  <div className="row">
                    {IsStringChecker(harvest.post_image) && (
                      <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                        <img
                          src={harvest.post_image}
                          className="card-img-top"
                          alt="upcoming_news"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `${window.location.origin}assets/images/profie-img.jpg`;
                          }}
                        />
                        {/* <img
                    src={
                      IsStringChecker(harvest.post_image)
                        ? harvest.post_image
                        : defaultEventImageURL
                    }
                    className="card-img-top mob-width-100 width-50_p mb-1 me-2 float-start"
                    alt="upcoming_news"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${window.location.origin}assets/images/profie-img.jpg`;
                    }}
                  /> */}
                      </div>
                    )}
                    <div
                      className={`col-12 ${
                        IsStringChecker(harvest.post_image) && `col-lg-8`
                      }`}
                    >
                      {/* <p className="mt-2 mt-lg-0">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: harvest["description_" + i18n.language],
                      }}
                    ></div>
                  </p> */}
                      <p
                        className="ql-editor"
                        style={{ fontSize: points.fontSize1 }}
                      >
                        {/* <div
                            dangerouslySetInnerHTML={{
                              __html: harvest["description_" + i18n.language],
                            }}
                          ></div> */}
                        <div
                          className="quill-editor ql-container ql-snow"
                          style={{
                            height: "auto",
                            border: "none",
                            fontFamily: "unset",
                            fontSize: points.fontSize1,
                          }}
                        >
                          <ContentModifier
                            str={harvest["description_" + i18n.language]}
                            imgClass={false}
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
