import React from "react";
import Breadcrumb from "../global/breadCrumb";
import LeftMenu from "../global/leftMenu";
import QuickLinks from "../home/quickLinks";

const Body = (props) => {
  const { isLeftMenu, quickLinksSlug, contentbody, ...rest } = props;
  return (
    <>
      {isLeftMenu == "true" ? (
        <div className="row">
          {/*Add breadCrumb*/}
          <div className="row">
            <div className=" col-12 offset-lg-2 col-lg-10 d-none d-lg-block m_l_0_print">
              <Breadcrumb {...rest} />
            </div>
          </div>
          {/*End breadCrumb*/}
          <div className="row">
            <div className="d-none d-lg-block col-lg-2 hidden_print">
              <LeftMenu {...rest} slug="my-harvest" />
              {/* <!-- Quick Links starts --> */}
              <div className=" bot-mspace-20 Quick_link ">
                <QuickLinks {...rest} slug={quickLinksSlug} />
              </div>
              {/* <!-- Quick Links ends --> */}
            </div>

            <div className="col-12 col-lg-10">
              {/* <!-- Accordion blocks starts --> */}
              {contentbody}
            </div>
          </div>
        </div>
      ) : (
        /*Without left menu*/
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <Breadcrumb {...rest} />
          </div>

          {contentbody}
        </div>
      )}
    </>
  );
};

export default Body;
