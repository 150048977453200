/**
 * @component_Name : UserProfile
 * @description : Show Profile, Notifications, Username and Password tabs
 * @company : Photon
 * @author : Ashish Ranjan/20-09-2022
 **/

import React, { useState, useEffect } from "react";
import Profile from "./profile";
import ProfilePicture from "./profilePicture";
import { Accordion } from "react-bootstrap";
import UserNotification from "./userNotifications";
import CurrentLocation from "../global/currentLocation";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import NotificationPreferences from "./notificationPreferences";
import ChangePassword from "./changePassword";
import DataSharing from "./dataSharing";
import { HandleErrorTrackAndToast, IsTokenChecker, IsArrayNotEmpty, safeArrayCheck } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../global/loading";
import { gbsDataCommonMethod } from "../../services/gbsData";
import { ContractsFromJSONParser, FilterContractByRole, ImpersonationAccountsSwap } from "../../permissionWrapper";
import { useMsal } from "@azure/msal-react";
import { ContractBasedRoles } from "../../utils/roles";
import _ from "lodash";

const UserprofileDetails = (props) => {
  const { RolePBPrivileges = {}, CommonPageCBRoles = [] } = props;
  const { rolePBAgScientist } = RolePBPrivileges;

  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const gbsRolesByContract = ContractsFromJSONParser(accounts, false);

  const { roleCBDecisionMaker, roleCBAcreage } = ContractBasedRoles;

  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState("profile");
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const { tabName } = useParams();
  const arrayLocation = location.hash;
  const handlerTab = (params) => {
    setSelectedTab(params);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [profileData, setProfileData] = useState({});
  const [contractNumbers, setContractNumbers] = useState([]);
  const [roleBasedAccess, setRoleBasedAccess] = useState([]);

  useEffect(() => {
    if (tabName) setSelectedTab(tabName);
    if (IsTokenChecker(tokenId)) {
      fetchContract();
    }
  }, [tabName, tokenId]);

  let retainAccordion = "";
  useEffect(() => {
    if (tabName) {
      setSelectedTab(tabName);
      retainAccordion = tabName;
    }
  }, [tabName]);

  const handlerAccordion = (e) => {
    //e.stopPropagation
    if (e !== null) {
      localStorage.setItem("expandedItem", e);
    } else {
      localStorage.setItem("expandedItem", [5]);
    }
  };

  if (tabName) {
    retainAccordion = tabName;
  } else if (arrayLocation != "" || localStorage.expandedItem != "") {
    if (arrayLocation != "") {
      retainAccordion = arrayLocation.replace(/#/g, "");
    } else {
      retainAccordion = localStorage.expandedItem
        ? localStorage.expandedItem.split(",")
        : "5";
    }
  }

  // Event Effect
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    // console.log(window.innerWidth,windowWidth)
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  const onResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Fetching Contracts
  const fetchContract = async () => {
    if (tokenId != "") {
      setLoader(true);
      await gbsDataCommonMethod(
        tokenId,
        accounts,
        `/persons/${personId}/contracts`
      )
        .then((resp) => {
          const data = safeArrayCheck(_.get(resp, `data`, []));
          let filteredData = FilterContractByRole(
            data,
            accounts,
            CommonPageCBRoles
          );
          filteredData = _.orderBy(
            filteredData,
            ["ContractStatus", "RelationshipNumber"],
            ["asc", "asc"]
          );
          if (IsArrayNotEmpty(filteredData)) {
            setLoader(false);
            let access = false;
            let data = filteredData.filter((cont) => {
              gbsRolesByContract.map((a) => {
                if (a.contract === cont.RelationshipId) {
                  let DecisionMaker = a.roles.filter(
                    (a) => a === roleCBDecisionMaker
                  );
                  let Acreage = a.roles.filter((a) => a === roleCBAcreage);
                  if (DecisionMaker.length > 0) {
                    access = true;
                    cont.ContractRole = "active";
                  } else if (Acreage.length > 0) {
                    access = true;
                    cont.ContractRole = "disabled";
                  } else {
                    cont.ContractRole = "";
                  }
                }
                return cont;
              });
              return [1000, 1001].includes(cont.ContractStatus);
            });
            setContractNumbers(data);
            setRoleBasedAccess(access);
          } else {
            setLoader(false);
            setRoleBasedAccess(false);
            setContractNumbers([]);
          }
        })
        .catch((error) => {
          setLoader(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };

  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {windowWidth > 992 && (
        <div className="accordion-body mt-3 p-0 d-none d-lg-block">
          <div className="col-12">
            {/*<!-- Tabs for User profile starts -->*/}
            <ul
              className={`nav nav-pills mt-5 mt-lg-4 mb-3 bordered_nav_tabs user_profile_tabs ${
                ((!rolePBAgScientist && !roleBasedAccess) || true) && "datasharing-disabled"
              }`}
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedTab === "profile" ? " active" : ""
                  }`}
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected={selectedTab === "profile" ? true : false}
                  onClick={() => handlerTab("profile")}
                >
                  {moreLangs[i18n.resolvedLanguage]?.userProfile?.profile}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedTab === "notifications" ? " active" : ""
                  }`}
                  id="pills-notifications-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-notifications"
                  type="button"
                  role="tab"
                  aria-controls="pills-notifications"
                  aria-selected={selectedTab === "notifications" ? true : false}
                  onClick={() => handlerTab("notifications")}
                >
                  {moreLangs[i18n.resolvedLanguage]?.userProfile?.notifications}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedTab === "userpwd" ? " active" : ""
                  }`}
                  id="pills-user-pwd-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-user-pwd"
                  type="button"
                  role="tab"
                  aria-controls="pills-user-pwd"
                  aria-selected={selectedTab === "userpwd" ? true : false}
                  onClick={() => handlerTab("userpwd")}
                >
                  {
                    moreLangs[i18n.resolvedLanguage]?.userProfile
                      ?.username_and_password
                  }
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedTab === "profilePicture" ? " active" : ""
                  }`}
                  id="pills-profile-picture-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile-picture"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile-picture"
                  aria-selected={
                    selectedTab === "profilePicture" ? true : false
                  }
                  onClick={() => handlerTab("profilePicture")}
                >
                  {
                    moreLangs[i18n.resolvedLanguage]?.userProfile
                      ?.profile_picture
                  }
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    selectedTab === "notificationPreferences" ? " active" : ""
                  }`}
                  id="pills-notification-preferences-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-notification-preferences"
                  type="button"
                  role="tab"
                  aria-controls="pills-notification-preferences"
                  aria-selected={
                    selectedTab === "notificationPreferences" ? true : false
                  }
                  onClick={() => handlerTab("notificationPreferences")}
                >
                  {
                    moreLangs[i18n.resolvedLanguage]?.userProfile
                      ?.notification_preferences
                  }
                </button>
              </li>
              {(rolePBAgScientist || roleBasedAccess) && false && (
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      selectedTab === "dataSharing" ? " active" : ""
                    }`}
                    id="pills-notification-preferences-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-notification-preferences"
                    type="button"
                    role="tab"
                    aria-controls="pills-dataSharing"
                    aria-selected={selectedTab === "dataSharing" ? true : false}
                    onClick={() => handlerTab("dataSharing")}
                  >
                    {
                      moreLangs[i18n.resolvedLanguage]?.userProfile
                        ?.data_sharing
                    }
                  </button>
                </li>
              )}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {/*<!-- Profile tab -->*/}
              <div
                className={`tab-pane fade ${
                  selectedTab === "profile" ? " show active" : ""
                }`}
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <Profile {...props} setPrifleData={setProfileData} />
              </div>
              {/*<!-- Profile tab ends -->*/}

              {/*<!-- notifications tab -->*/}
              <div
                className={`tab-pane fade ${
                  selectedTab === "notifications" ? " show active" : ""
                }`}
                id="pills-notifications"
                role="tabpanel"
                aria-labelledby="pills-notifications-tab"
              >
                <UserNotification {...props} />
              </div>
              {/*<!-- Notifications tab ends -->*/}

              {/*<!-- userpwd tab -->*/}
              <div
                className={`tab-pane fade ${
                  selectedTab === "userpwd" ? " show active" : ""
                }`}
                id="pills-userpwd"
                role="tabpanel"
                aria-labelledby="pills-userpwd-tab"
              >
                <ChangePassword {...props} />
                {/* <userpwd /> */}
              </div>
              {/*<!-- userpwd tab ends -->*/}

              {/*<!-- profilePicture tab -->*/}
              <div
                className={`tab-pane fade ${
                  selectedTab === "profilePicture" ? " show active" : ""
                }`}
                id="pills-profile-picture"
                role="tabpanel"
                aria-labelledby="pills-profile-picture-tab"
              >
                <ProfilePicture {...props} />
              </div>
              {/*<!-- profilePicture tab ends -->*/}

              {/*<!-- Notification Preferences tab -->*/}
              <div
                className={`tab-pane fade ${
                  selectedTab === "notificationPreferences"
                    ? " show active"
                    : ""
                }`}
                id="pills-notification-preferences-tab"
                role="tabpanel"
                aria-labelledby="pills-notification-preferences-tab"
              >
                <NotificationPreferences {...props} profileData={profileData} />
              </div>
              {/*<!-- Notification Preferences tab ends -->*/}
              {/*<!-- Data Sharing tab -->*/}
              {(rolePBAgScientist || roleBasedAccess) && false && (
                <div
                  className={`tab-pane fade ${
                    selectedTab === "dataSharing" ? " show active" : ""
                  }`}
                  id="pills-dataSharing-tab"
                  role="tabpanel"
                  aria-labelledby="pills-dataSharing-tab"
                >
                  <DataSharing
                    isAgScientist={rolePBAgScientist}
                    contractNumbers={contractNumbers}
                    {...props}
                  />
                </div>
              )}
              {/*<!-- Data Sharing tab ends -->*/}
            </div>
            {/*<!-- Tabs for User profile ends -->*/}
          </div>
          {/*<!-- Tabs for User profile ends -->*/}
        </div>
      )}
      {windowWidth <= 992 && (
        <div className="accordion-body mt-3 p-0 d-block d-lg-none">
          {/*<!-- Accordion -->*/}
          <Accordion
            defaultActiveKey={retainAccordion}
            alwaysOpen
            id="accordionUserProfile"
            onSelect={handlerAccordion}
          >
            <>
              {/*<!-- Accordion -->*/}
              <Accordion.Item eventKey="profile" key="0">
                <Accordion.Header>
                  <i className="fa fa-user mx-1 me-2" aria-hidden="true"></i>{" "}
                  {moreLangs[i18n.resolvedLanguage]?.userProfile?.profile}{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <Profile {...props} setPrifleData={setProfileData} />
                </Accordion.Body>
              </Accordion.Item>
              {/*<!-- Notifications -->*/}
              <Accordion.Item eventKey="notifications" key="1">
                <Accordion.Header>
                  <i className="fa fa-bell me-2" aria-hidden="true"></i>{" "}
                  {moreLangs[i18n.resolvedLanguage]?.userProfile?.notifications}{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <UserNotification {...props} />
                </Accordion.Body>
              </Accordion.Item>
              {/*<!-- Username and Password -->*/}
              <Accordion.Item eventKey="userpwd" key="2">
                <Accordion.Header>
                  <i className="fa fa-unlock me-2" aria-hidden="true"></i>{" "}
                  {
                    moreLangs[i18n.resolvedLanguage]?.userProfile
                      ?.username_and_password
                  }{" "}
                </Accordion.Header>
                <Accordion.Body>
                  <ChangePassword {...props} />
                </Accordion.Body>
              </Accordion.Item>
              {/*<!-- Profile Picture -->*/}
              <Accordion.Item eventKey="profilePicture" key="3">
                <Accordion.Header>
                  <i
                    className="fa fa-picture-o me-2 text-14"
                    aria-hidden="true"
                  ></i>{" "}
                  {
                    moreLangs[i18n.resolvedLanguage]?.userProfile
                      ?.profile_picture
                  }{" "}
                </Accordion.Header>
                <Accordion.Body>
                  {" "}
                  <ProfilePicture {...props} />{" "}
                </Accordion.Body>
              </Accordion.Item>
              {/*<!-- Notification Preferences -->*/}
              <Accordion.Item eventKey="notificationPreferences" key="4">
                <Accordion.Header>
                  <i className="fa fa-cogs me-2" aria-hidden="true"></i>{" "}
                  {
                    moreLangs[i18n.resolvedLanguage]?.userProfile
                      ?.notification_preferences
                  }{" "}
                </Accordion.Header>
                <Accordion.Body>
                  {" "}
                  <NotificationPreferences {...props} profileData={profileData} />{" "}
                </Accordion.Body>
              </Accordion.Item>
              {(rolePBAgScientist || roleBasedAccess) && false && (
                <Accordion.Item eventKey="dataSharing" key="5">
                  <Accordion.Header>
                    <i className="fa fa-cogs me-2" aria-hidden="true"></i>{" "}
                    {
                      moreLangs[i18n.resolvedLanguage]?.userProfile
                        ?.data_sharing
                    }{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    {" "}
                    <DataSharing
                      isAgScientist={rolePBAgScientist}
                      contractNumbers={contractNumbers}
                      {...props}
                    />{" "}
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default UserprofileDetails;
