import React, { useEffect, useState, useRef } from "react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import SingleNews from "./singleNews";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import CurrentLocation from "../global/currentLocation";
import {
  IsFunctionChecker,
  IsNumberChecker,
  IsArrayNotEmpty,
  sortByField,
  IsTokenChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../utils/helper";
import { event_order } from "../../utils/constantsVariable";
import moment from "moment";
import Loading from "../global/loading";
import { Link } from "react-router-dom";

const News = ({
  selectedNews,
  newsFor,
  setParentLoading = null,
  // setDisplayLoadMore = null,
  // limit = 5,
  // setLimit = null,
  preCategories = null,
  newsEvents = false,
  setNewsCount = null,
  setResultFound = null,
  // setLoadMoreLimit = null,
  // loadMoreLimit = 10
  limitLength = 10
}) => {
  const [news, setNews] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const [sort, setSort] = useState(0);
  const [catagories, setCatagories] = useState(null);
  const [harvestLoading, setHarvestLoading] = useState(false);
  // const currentISODate = new Date().toISOString();
  const newslimit = sessionStorage.getItem("newslimit") || 10;
  const newsloadmorelimit = sessionStorage.getItem("newsloadmorelimit") || 10;
  const newscategory = sessionStorage.getItem("newscategory");
  const [limit, setLimit] = useState(newsFor === "home" ? 5 : limitLength > parseInt(newslimit) ? limitLength : parseInt(newslimit));
  const [loadMoreLimit, setLoadMoreLimit] = useState(newsFor === "home" ? 5 : limitLength > parseInt(newsloadmorelimit) ? limitLength : parseInt(newsloadmorelimit));
  const [selectedCategory, setSelectedCategory] = useState(newscategory || selectedNews); 
  // console.log("newslimit", newscategory, selectedNews , selectedCategory);

  const newsRef = useRef(null);

  const [displayLoadMore, setDisplayLoadMore] = useState(true);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   setSelectedCategory(selectedNews);
  // }, [selectedNews]);

  const loadMore = () => {
    newsFor !== "home" && sessionStorage.setItem("newsloadmorelimit", parseInt(limit + loadMoreLimit || 10));
    setLoadMoreLimit(limit + loadMoreLimit);
  };

  useEffect(() => {
    setTimeout(() => {
      newsRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 300);    
  }, [newsRef.current]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
      if (IsTokenChecker(idToken) && (
        location.href.search("community/news") === -1 &&
        location.href.search("community/ag-sciences/region") === -1 &&
        location.href.search("community/events") === -1
      )
      ) {
        sessionStorage.removeItem("newslimit");
        sessionStorage.removeItem("newscategory");
        sessionStorage.removeItem("newsloadmorelimit");
        sessionStorage.removeItem("selecteddetailpage");
      }
    };
  }, []);

  const changeSorting = (value) => {
    setSort(value);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const News = async () => {
    const eventFilter = `event_date_from.is.null,event_date_to.is.null,event_date_to.gte.${moment().toISOString()}`;

    const catURL = `news_events?select=id,is_active,event_date_from,event_location,event_date_to,title_en,slug,title_fr,title_es,description_en,description_fr,show_comments,description_es,news_categories,news_event_comment_likes(id,like_name),like_count,posting_date,post_image,url,authors(title_en,title_fr,title_es,author_image_url,job_title),news_event_comments!fk_news_events_id(id,comment,comment_name,comments_id,created_at,like_count,user_id,is_active,news_event_comments(comment,created_at,like_count,comment_name,user_id,is_active),news_event_comment_likes(id,like_name))&news_event_comments.is_active=eq.true&news_categories=cs.{${selectedCategory}}&or=(${eventFilter})&limit=${
      IsNumberChecker(loadMoreLimit) ? loadMoreLimit : 5
    }&order=posting_date.desc&is_active=eq.true&posting_date=lte.${moment().toISOString()}`;
    const pinCatURL = `news_events?select=id,is_active,event_date_from,event_location,event_date_to,title_en,slug,title_fr,title_es,description_en,description_fr,show_comments,description_es,news_categories,news_event_comment_likes(id,like_name),like_count,posting_date,post_image,url,authors(title_en,title_fr,title_es,author_image_url,job_title),news_event_comments!fk_news_events_id(id,comment,comment_name,comments_id,created_at,like_count,user_id,is_active,news_event_comments(comment,created_at,like_count,comment_name,user_id,is_active),news_event_comment_likes(id,like_name))&news_event_comments.is_active=eq.true&news_categories=cs.{${selectedCategory}}&or=(${eventFilter})&limit=${
      IsNumberChecker(loadMoreLimit) ? loadMoreLimit : 5
    }&is_pinned=eq.true&pin_order=gt.0&order=pin_order.asc&is_active=eq.true&posting_date=lte.${moment().toISOString()}`;

    const eveURL = `news_events?select=id,is_active,event_date_from,event_location,event_date_to,title_en,slug,title_fr,title_es,description_en,description_fr,show_comments,description_es,news_categories,news_event_comment_likes(id,like_name),like_count,posting_date,post_image,url,authors(title_en,title_fr,title_es,author_image_url,job_title),news_event_comments!fk_news_events_id(id,comment,comment_name,comments_id,created_at,like_count,user_id,is_active,news_event_comments(comment,created_at,like_count,comment_name,user_id,is_active),news_event_comment_likes(id,like_name))&news_event_comments.is_active=eq.true&news_categories=cs.{${selectedCategory}}&or=(${eventFilter})&limit=${
      IsNumberChecker(loadMoreLimit) ? loadMoreLimit : 5
    }&order=${event_order}&is_active=eq.true&posting_date=lte.${moment().toISOString()}`;

    const allURL = `news_events?select=id,is_active,event_date_from,event_location,event_date_to,title_en,slug,title_fr,title_es,description_en,description_fr,show_comments,description_es,news_event_comment_likes(id,like_name),like_count,posting_date,post_image,url,authors(title_en,title_fr,title_es,author_image_url,job_title),news_event_comments!fk_news_events_id(id,comment,comment_name,comments_id,created_at,like_count,user_id,is_active,news_event_comments(comment,created_at,like_count,comment_name,user_id,is_active),news_event_comment_likes(id,like_name))&news_event_comments.is_active=eq.true&or=(${eventFilter})&limit=${
      IsNumberChecker(loadMoreLimit) ? loadMoreLimit : 5
    }&order=posting_date.desc&is_active=eq.true&posting_date=lte.${moment().toISOString()}`;

    const allPinURL = `news_events?select=id,is_active,event_date_from,event_location,event_date_to,title_en,slug,title_fr,title_es,description_en,description_fr,show_comments,description_es,news_categories,news_event_comment_likes(id,like_name),like_count,posting_date,post_image,url,authors(title_en,title_fr,title_es,author_image_url,job_title),news_event_comments!fk_news_events_id(id,comment,comment_name,comments_id,created_at,like_count,user_id,is_active,news_event_comments(comment,created_at,like_count,comment_name,user_id,is_active),news_event_comment_likes(id,like_name))&news_event_comments.is_active=eq.true&or=(${eventFilter})&limit=${
      IsNumberChecker(loadMoreLimit) ? loadMoreLimit : 5
    }&is_pinned=eq.true&pin_order=gt.0&order=pin_order.asc&is_active=eq.true&posting_date=lte.${moment().toISOString()}`;

    var url =
      selectedCategory != "0"
        ? selectedCategory === "upcoming-events"
          ? eveURL
          : catURL
        : allURL;

    if (newsFor === "home" && selectedCategory == "0") {
      fromHomePage(allPinURL, allURL, selectedCategory);
    } else {
      setHarvestLoading(true);
      await cmsDataCommonMethod(idToken, accounts, pinCatURL)
        .then(async (resp) => {
          // console.log("resp TEst", resp)
          let pinnedNews = _.get(resp, "data.data", []);
          await cmsDataCommonMethod(idToken, accounts, url)
          .then((resp) => {            
            let combineData = [
              ...pinnedNews,
              ..._.get(resp, "data.data", []),
            ];
            combineData = applyParentElementForIframeVideo(combineData);
            // console.log(combineData.length)
            combineData = _.uniqBy([...combineData], "id");
            // console.log(combineData.length, combineData)
            setNews(combineData);         
            if (
              IsFunctionChecker(setNewsCount) ||
              IsFunctionChecker(setResultFound)
            ) {
              const length = _.get(resp, "data.data.length", 0);
              IsFunctionChecker(setNewsCount) && setNewsCount(length);
              IsFunctionChecker(setResultFound) &&
                setResultFound(length && length > 0 ? true : false);
            }
            if (IsFunctionChecker(setParentLoading)) {
              setParentLoading(false);
            }
            if (IsFunctionChecker(setDisplayLoadMore)) {
              const length = _.get(resp, "data.data.length", 0);
              if (IsNumberChecker(length) && IsNumberChecker(loadMoreLimit)) {
                if (length < loadMoreLimit) {
                  setDisplayLoadMore(false);
                } else {
                  setDisplayLoadMore(true);
                }
              } else {
                setDisplayLoadMore(false);
              }
            }
            setHarvestLoading(false);
          })
          
        })
        .catch((error) => {
          setHarvestLoading(false);
          if (IsFunctionChecker(setParentLoading)) {
            setParentLoading(false);
          }
          if (IsFunctionChecker(setDisplayLoadMore)) {
            setDisplayLoadMore(false);
          }
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };
  const applyParentElementForIframeVideo = (o) => {
    return o.map((i) => {
      let el = i['description_' + i18n.language];
      if (el.indexOf("https://players.brightcove.net/") > -1 && el.indexOf("video-iframe") === -1) {
        // console.log(i.title_en);
        let element = document.createElement('div');
        element.innerHTML = el;
        let a = element.querySelectorAll("iframe");
        a.forEach((e, i) => {
          if(!e.classList.contains('ql-video')){
            let b = document.createElement('div');
            b.className = 'video-iframe';
            b.innerHTML = e.outerHTML;
            element.querySelectorAll("iframe")[i].outerHTML = b.outerHTML;
          }  
        });
        i['description_' + i18n.language] = element.innerHTML;
      }
      return i;
    })
  }
  const fromHomePage = async (withPinURL, withourPinURL) => {
    await cmsDataCommonMethod(idToken, accounts, withPinURL)
      .then(async (resp) => {
        let PinedData = _.get(resp, "data.data", [])?.filter((item) =>
          item.news_categories.indexOf("upcoming-events")
        );
        await cmsDataCommonMethod(idToken, accounts, withourPinURL)
          .then((resp) => {
            let getlength = 5 - PinedData.length;
            let combineData = [
              ...PinedData,
              ..._.get(resp, "data.data", []).splice(0, getlength),
            ];
            combineData = _.uniqBy([...combineData], "id");
            setNews(combineData);
            if (IsFunctionChecker(setParentLoading)) {
              setParentLoading(false);
            }
            if (IsFunctionChecker(setDisplayLoadMore)) {
              const length = _.get(resp, "data.data.length", 0);
              if (IsNumberChecker(length) && IsNumberChecker(loadMoreLimit)) {
                if (length < loadMoreLimit) {
                  setDisplayLoadMore(false);
                } else {
                  setDisplayLoadMore(true);
                }
              } else {
                setDisplayLoadMore(false);
              }
            }
          })
          .catch((error) => {
            if (IsFunctionChecker(setParentLoading)) {
              setParentLoading(false);
            }
            if (IsFunctionChecker(setDisplayLoadMore)) {
              setDisplayLoadMore(false);
            }
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          });
      })

      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };
  const Cats = async () => {
    var url =
      newsFor == "harvest-news"
        ? `news_event_categories?is_harvest_news=eq.true&order=title_en`
        : `news_event_categories`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let tempCategories = safeArrayCheck(
          sortByField(_.get(resp, "data.data", []))
        );
        let newscategory = sessionStorage.getItem("newscategory");
        if (newscategory !== null) {
          if (tempCategories.filter(i => i.slug === newscategory).length > 0) {
            newsFor !== "home" && sessionStorage.setItem("newscategory", newscategory);
          }
          else {
            newsFor !== "home" && sessionStorage.setItem("newscategory", selectedNews);
          }
        }
        if (newscategory === "0")
          newsFor !== "home" && sessionStorage.setItem("newscategory", newscategory);
        setCatagories(tempCategories);
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const switchCategory = (e) => {
    let value = _.get(e, "target.value", "0");
    if (value) {
      newsFor !== "home" && sessionStorage.setItem("newscategory", value);
      setSelectedCategory(value);
      if (IsFunctionChecker(setLoadMoreLimit)) {
        setLoadMoreLimit(limit);
        newsFor !== "home" && sessionStorage.setItem("newsloadmorelimit", parseInt(limit || 10));
      }
    }
  };
  useEffect(() => {
    if (IsArrayNotEmpty(preCategories)) {
      let newscategory = sessionStorage.getItem("newscategory");
      if (newscategory !== null) {
        if (preCategories.filter(i => i.slug === newscategory).length > 0) {
          newsFor !== "home" && sessionStorage.setItem("newscategory", newscategory);
        }
        else {
          newsFor !== "home" && sessionStorage.setItem("newscategory", selectedNews);
        }
      }
      setCatagories(preCategories);
    } else {
      IsTokenChecker(idToken) && Cats();
    }
  }, [idToken, preCategories]);
  useEffect(() => {
    if (IsFunctionChecker(setParentLoading)) {
      setParentLoading(true);
    }
    IsTokenChecker(idToken) && News();
  }, [refresh, selectedCategory, loadMoreLimit, idToken]);
  return i18n.resolvedLanguage === undefined ? null : (
    <div className="row">
      {harvestLoading ? (
        <>
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </>
      ) : null}
      {news !== null ? (
        <div className="col-xl-12">
          <div
            className="card bot-mspace-20 card-shadow news_block  mt-lg-0


"
          >
            <h4 className="card-header white_color py-2 px-2 px-lg-3">
              {" "}
              <img
                src={`${window.location.origin}/assets/images/news_icon.svg`}
                alt="news_icon"
                className="svg-image-1 my-2"
              />
              <span className="left-space-5 news_text text-14-sm">
                {moreLangs[i18n.language]?.home_page?.in_the_news}
              </span>
              <span className="float-end">
                <select
                  className="form-select text-14 filter_text"
                  aria-label="Default select example"
                  onChange={(e) => switchCategory(e)}
                  value={selectedCategory}
                >
                  {!IsArrayNotEmpty(preCategories) && (
                    <option value={0}>
                      {moreLangs[i18n.language]?.home_page?.all_news}
                    </option>
                  )}
                  {IsArrayNotEmpty(catagories) &&
                    catagories.map((cat, index) => {
                      return (
                        <option value={cat.slug} key={index}>
                          {cat["title_" + i18n.resolvedLanguage]}
                        </option>
                      );
                    })}
                </select>
              </span>
              {
                newsFor !== "home" && <span className="float-end initial-loadmore">
                  <span>View by: </span>
                  <select
                    className="form-select text-14 initial-loadmore-select"
                    aria-label="Default select example"
                    onChange={e=>{
                      setLimit(parseInt(e.target.value || 10));
                      setLoadMoreLimit(parseInt(e.target.value || 10))
                      newsFor !== "home" && sessionStorage.setItem("newslimit", parseInt(e.target.value || 10));
                      newsFor !== "home" && sessionStorage.setItem("newsloadmorelimit", parseInt(e.target.value || 10));
                    }
                    }
                    value={limit}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </span>
              }
            </h4>
          </div>
        </div>
      ) : null}
      {news !== null
        ? <>{news.map((post, index) => (
            <div className="col-xl-12" key={index}>
              <SingleNews
                changeSorting={changeSorting}
                post={post}
                setRefresh={setRefresh}
                refresh={refresh}
                comments={_.orderBy(
                  post.news_event_comments,
                  ["id"],
                  [sort === "1" ? "asc" : "desc"]
                )}
                newsEvents={newsEvents}
                newsSelectedRef={newsRef}
              />
            </div>
          ))} {newsFor === "home" && <Link className="view-all-data text-center fw-bold ver-mspace-10 text-16 mb-4" to="/community/news">{moreLangs[i18n.language]?.home_page?.view_all}</Link>}
          {displayLoadMore && newsFor !== "home" && (
            <div className="mb-4">
              <button
                className="btn btn-primary width-100"
                onClick={loadMore}
              >
                {_.get(
                  moreLangs,
                  `${i18n.resolvedLanguage}.home_page.load_more`,
                  "Load More"
                )}
              </button>
            </div>
          )}
          </>
        : null}
    </div>
  );
};

export default News;
