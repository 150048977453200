/**
 * @component_Name : ContractPerformanceMobile
 * @description : Show contracts and downloads respective documents
 * @company : Photon
 * @author : Ashish Ranjan/05-07-2022
 **/
import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import Moment from "react-moment";
import {ConstantsVar} from "../../../utils/constants";

const ContractPerformanceMobile = ({ listingData }) => {
  
  const pagingArray = Object.keys(listingData).map((x) => x)
  const listLength = pagingArray.length;
  return (
    <>
      <Accordion defaultActiveKey ="0">
        {(listLength > 0 && Object.values(listingData).map((data, index) => {
          const report_Date = <Moment utc format={ConstantsVar.dateFormate}>{data['Report_Date']}</Moment>
          return (
            <Fragment key={index}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                Crop Year: {data['Crop_Year']}, Contract #: {data['Contract_#']}
                </Accordion.Header>
                <Accordion.Body>     
                  <div className="tbody">
                    <div className="tr">
                      <div className="td"></div>
                      <div className="td" data-header="Crop Year:"><span>{data['Crop_Year']}</span></div>
                      <div className="td" data-header="Contract #:"><span>{data['Contract_#']}</span></div>
                      <div className="td acreage_table" data-header="Contract Name:"><span>{data['Contract_Name']}</span></div>
                      <div className="td" data-header="Report Name:"><span className="breakWord">{data['Report_Name']}</span></div>
                      <div className="td" data-header="Report Date:"><span>{report_Date}</span></div>
                      <div className="td" data-header="Download Excel:">{data['Download_Excel']}</div>
                    </div>
                  </div> 	
                </Accordion.Body>
              </Accordion.Item>
            </Fragment>
          );
        }))}
      </Accordion>
    </>
  );
};

export default ContractPerformanceMobile;
