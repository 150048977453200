import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { cmsDataCommonMethod } from "../../services/gbsData";
import CurrentLocation from "../global/currentLocation";
import {
  IsTokenChecker,
  getResolvedLanguage,
  IsArrayNotEmpty,
  IsStringChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../utils/helper";
import EventTime from "../community/events/eventTime";
import EventLocation from "../community/events/eventLocation";
import {
  // defaultEventImageURL,
  event_order,
} from "../../utils/constantsVariable";
import _ from "lodash";
import { Link } from "react-router-dom";
import ImageView from "../global/imageView";
import moment from "moment";

const UpcomingEvents = () => {
  const [upcomingevents, setUpcomingEvents] = useState(null);
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const dispatch = useDispatch();

  const pinEvent = async () => {
    const catURL = `news_events?select=title_en,is_active,title_fr,title_es,event_location,event_date_to,event_date_from,description_es,post_image,url,news_categories,posting_date,pin_order,is_pinned,authors(title_en,title_fr,title_es,author_image_url,job_title)&news_categories=cs.{upcoming-events}&is_pinned=eq.true&pin_order=gt.0&order=pin_order.asc&posting_date=lte.${moment().toISOString()}`;

    await cmsDataCommonMethod(idToken, accounts, catURL)
      .then((resp) => {
        let data = safeArrayCheck(_.get(resp, "data.data", []));
        if (data.length > 0) setUpcomingEvents(data);
        else upcoming();
      })
      .catch((error) => {
        upcoming();
        setUpcomingEvents([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const upcoming = async () => {
    const currentISODate = new Date().toISOString();
    const catURL = `news_events?select=title_en,is_active,title_fr,title_es,event_location,event_date_to,event_date_from,description_es,post_image,url,news_categories,posting_date,authors(title_en,title_fr,title_es,author_image_url,job_title)&news_categories=cs.{upcoming-events}&event_date_to=gte.${currentISODate}&order=${event_order}&limit=1&posting_date=lte.${moment().toISOString()}`;

    await cmsDataCommonMethod(idToken, accounts, catURL)
      .then((resp) => {
        let data = safeArrayCheck(_.get(resp, "data.data", []));
        setUpcomingEvents(data);
      })
      .catch((error) => {
        setUpcomingEvents([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    IsTokenChecker(idToken) && pinEvent();
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);
  const title = _.get(
    moreLangs,
    `${resolvedLanguage}.home_page.upcoming_events`,
    "Upcoming Events"
  );
  const view_all_events = _.get(
    moreLangs,
    `${resolvedLanguage}.home_page.view_all_events`,
    "View All Events"
  );
  return (
    <div>
      {IsArrayNotEmpty(upcomingevents) && (
        <div className="card card-shadow">
          <h4 className="card-header white_color ver-space-15">
            {" "}
            <i className="fa fa-calendar text-18"></i>{" "}
            <span className="left-space-5">{title}</span>
          </h4>
        </div>
      )}
      {IsArrayNotEmpty(upcomingevents) && (
        <div className="card bot-mspace-20 card-shadow">
          {/* { _.get(upcomingevents[0], "post_image", null) && <img
            src={
              _.get(upcomingevents[0], "post_image", null)
            }
            className="card-img-top space-20"
            alt="..."
          />} */}
          
          { _.get(upcomingevents[0], "post_image", null)
          && <ImageView
          className="card-img-top space-20"
          url={_.get(upcomingevents[0], "post_image", null)}
          setAutoPlay={true}
          component='upcoming-event'
        />
          }
          
          <div className="card-body pt-0">
            <div className="row">
              <div className="col-11 ms-1">
                {IsStringChecker(
                  _.get(upcomingevents[0], `title_${resolvedLanguage}`, null)
                ) && (
                  <h5 className="fw-bold">
                    {_.get(
                      upcomingevents[0],
                      `title_${resolvedLanguage}`,
                      null
                    )}
                  </h5>
                )}
                <div className="upcoming_location">
                  {
                    (_.get(upcomingevents[0],`event_date_from`,null) !== null && _.get(upcomingevents[0],`event_date_to`,null)) &&<>
                    <i className="fa fa-clock-o marker_icon"></i>
                  <p>
                    <EventTime
                      event_date_from={_.get(
                        upcomingevents[0],
                        `event_date_from`,
                        null
                      )}
                      event_date_to={_.get(
                        upcomingevents[0],
                        `event_date_to`,
                        null
                      )}
                      locale={true}
                      timezone={true}
                    />
                  </p></>
                  }
                </div>
                <div className="upcoming_location">                  
                  {
                    upcomingevents[0]?.event_location !== null && upcomingevents[0]?.event_location?.replace( /(<([^>]+)>)/ig, '') !== '' && (
                    <><i className="fa fa-map-marker marker_icon ms-1"></i>
                    <EventLocation
                      event_location={_.get(
                        upcomingevents[0],
                        `event_location`,
                        null
                      )}
                    />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="ver-mspace-20 horizontal-line"></div>
            <div>
              <Link
                to={moreLangs[i18n.language]?.menu_events?.url}
                className="space-10 cursor_pointer read_more_click"
              >
                {view_all_events}
              </Link>
              {/* <a
                href="#"
                className="d-none d-md-block text-center fw-bold ver-mspace-10 text-16"
              >
                {view_all_events}
              </a> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
