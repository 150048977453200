import React from "react";
import Breadcrumb from "../../global/breadCrumb";
import Main from "./main";

const Body = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-12 d-none d-lg-block">
          <Breadcrumb {...props} fullWidth={true} />
        </div>
        <Main {...props} />
	  </div>
    </>
  );
};
export default Body;
