import React, { useEffect, useState } from "react";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import BreadCrumb from "../../global/breadCrumb";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import {
  sortByField,
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsStringChecker,
  IsTokenChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  compareString,
  lowStr,
} from "../../../utils/helper";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import CurrentLocation from "../../global/currentLocation";
import { EasternCanadaRegionConsolidation } from "../../../utils/constants";

const AGSciencesDetails = (props) => {
  const { slugs: ec_slugs = [], slug: ec_slug } =
    EasternCanadaRegionConsolidation;
  const { SpecialRoleExist, RoleCBPrivileges, DropDownCBRoles } = props;
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = accounts[0].idTokenClaims.gbsPersonId;

  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  function RegionArrayCreator(region_arr) {
    try {
      const temp = region_arr.map((it) => {
        const { RegionDesc } = it;
        const region = lowStr(RegionDesc).replace(/ /g, "-");
        const found = ec_slugs.some((it) => compareString(it, region));
        return found ? ec_slug : region;
      });
      return _.uniq(temp.filter((li) => IsStringChecker(li)));
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const fetchContract = async (region_arr) => {
    setLoading(true);
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `persons/${personId}/contracts`
    )
      .then((resp) => {
        const resultData = safeArrayCheck(resp?.data);
        const filteredData = IsArrayNotEmpty(resultData)
          ? FilterContractByRole(resultData, accounts, DropDownCBRoles)
          : [];
        // console.log({ filteredData });
        if (IsArrayNotEmpty(filteredData)) {
          const region_filter_arr = RegionArrayCreator(filteredData);
          // console.log({ region_filter_arr });
          if (IsArrayNotEmpty(region_filter_arr)) {
            const arr = region_arr.filter((li) => {
              const { slug } = li;
              const found = ec_slugs.some((it) => compareString(it, slug))
                ? ec_slug
                : slug;
              return region_filter_arr.some((it) => compareString(it, found));
            });
            // console.log({ arr });
            setRegions(arr);
          } else {
            setRegions([]);
          }
        } else {
          setRegions([]);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setRegions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRegions = async (special_role, privilegdes) => {
    const url = "regions";
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let tempRegions = safeArrayCheck(
          sortByField(_.get(resp, "data.data", []))
        );
        if (IsArrayNotEmpty(tempRegions)) {
          if (special_role) {
            const { roleCBThirdPartyFarmService } = privilegdes;
            if (roleCBThirdPartyFarmService) {
              const ec_region = tempRegions.find((li) =>
                compareString(li?.slug, ec_slug)
              );
              if (ec_region) {
                const altered_regions = tempRegions.map((li) => {
                  return ec_slugs.some((it) => compareString(li?.slug, it))
                    ? ec_region
                    : li;
                });
                fetchContract(_.uniqBy(altered_regions, "slug"));
              } else {
                const altered_regions = tempRegions.filter((li) => {
                  return !ec_slugs.some((it) => compareString(li?.slug, it));
                });
                fetchContract(altered_regions);
              }
            } else {
              setRegions(tempRegions);
            }
          } else {
            setRegions(tempRegions);
          }
        } else {
          setRegions([]);
        }
      })
      .catch((error) => {
        setRegions([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getRegions(SpecialRoleExist, RoleCBPrivileges);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken, SpecialRoleExist, RoleCBPrivileges]);
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <LeftMenu {...props} slug="ag-sciences" />
            <QuickLinks {...props} slug="ag-sciences" />
          </div>
          <div className="col-12 col-lg-10 region_list">
            <div className="row">
              {IsArrayNotEmpty(regions) ? (
                regions.map((list, index) => {
                  const { id, feature_image_url, url = "/" } = list;
                  const title = _.get(list, `title_${resolvedLanguage}`, "");
                  return (
                    <div
                      key={id || `region-${index}`}
                      className={`col-12 col-lg-4 ${
                        index === 0 ? "mt-5" : ""
                      } mt-lg-0`}
                    >
                      <Link
                        className="position-relative d-block"
                        to={IsStringChecker(url) ? url : "/"}
                      >
                        {IsStringChecker(feature_image_url) && (
                          <img
                            src={feature_image_url}
                            className="img-fluid border-radius-6"
                          />
                        )}
                        <div className="position-absolute outer-grey-bg"></div>
                        {IsStringChecker(title) && (
                          <h4 className="position-absolute white_color fw-bold">
                            {title}
                          </h4>
                        )}
                      </Link>
                    </div>
                  );
                })
              ) : loading ? (
                ""
              ) : (
                <div className="p-2 red_color text-center border-1 border-radius-6 mt-5 mt-lg-2">
                  <i className="fa fa-info-circle pe-2"></i>
                  {_.get(
                    moreLangs,
                    `${resolvedLanguage}.community_ag_science_pages.no_data_to_show`,
                    "Thank you for checking. We are adding details here for you soon."
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AGSciencesDetails;
