import React from "react";
import PageLayout from "../../pageLayout";
import OSContractsDetail from "../../osContact/osContactsDetail";

const BoardCouncilContactsDetails = (props) => {
  return (
    <PageLayout {...props} body={<OSContractsDetail {...props} showCommittee={true} />} />
  );
};

export default BoardCouncilContactsDetails;
