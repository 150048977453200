import getAdJwtToken from "./redux/actions";
import { loginRequest } from "../configs/authConfig";
import { HandleErrorTrackAndToast } from "../utils/helper";

const callAdJwtToken = (instance, accounts) => {
  const req = {
    ...loginRequest,
    account: accounts[0],
  };

  return (dispatch) => {
    instance
      .acquireTokenSilent(req)
      .then((res) => {
        dispatch(getAdJwtToken(res.idToken));
        document.cookie = `x-jwt=${res.idToken}; path=/;secure`;
      })
      .catch((e) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        instance.acquireTokenPopup(req).then((res) => {
          dispatch(getAdJwtToken(res.idToken));
        });
        HandleErrorTrackAndToast(e, true);
        // HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
};

export default callAdJwtToken;
