import { useHistory } from "react-router-dom";
import {
  en,
  fr,
  es,
} from "../../localization/index"; /*importing localiation file from localization folder*/
const CurrentLocation = () => {
  /*getting url and convert into array*/
  const location = useHistory().location;
  const getPath = location.pathname;
  const moreLangs = { en, fr, es };
  return [getPath, moreLangs];
};
export default CurrentLocation;
