import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../global/loading";
import CurrentLocation from "../global/currentLocation";
import { gbsDataCommonMethod } from "../../services/gbsData";
import moment from "moment";
import QuickLinks from "../home/quickLinks";
import LeftMenu from "../global/leftMenu";
import DataGridPagination from "../global/dataGridPagination";
import { useTranslation } from "react-i18next";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { GbsBaseUrl } from "../../utils/constants";
import {
  IsTokenChecker,
  getResolvedLanguage,
  IsArrayNotEmpty,
  IsStringChecker,
  safeArrayCheck,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import Breadcrumb from "../global/breadCrumb";
import _ from "lodash";
import parse from "html-react-parser";
import { MapContractsWithRole, ImpersonationAccountsSwap } from "../../permissionWrapper";
import {
  roleCBDecisionMaker,
  roleCBThirdPartyFarmService,
} from "../../utils/roles";

const MyContractsDetails = (props) => {
  // console.log({ props });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, `0.idTokenClaims.gbsPersonId`, null);
  const { DropDownCBRoles = [] } = props;

  const tokenId = useSelector((state) => state.reducerToken);
  // const limitval = 10;
  // const [currentResultCount, setCurrentResultCount] = useState(limitval);
  /* const tempDescription =
    "Lorem Ipsum is simply dummy text of the printing and typesetting";
  const tempAttachment =
    "https://osgrowerportal.blob.core.windows.net/cta-content-blocks/sample-file.pdf?sv=2021-10-04&st=2022-11-04T09%3A25%3A57Z&se=2022-11-04T10%3A26%3A57Z&sr=c&sp=racw&sig=odUy82KaaThVp0YqSOWcZ67aZXPW%2FuDF%2FQcx78%2BXaNo%3D";
  const tempAttachments = [
    {
      Description: tempDescription,
      Attachment: tempAttachment,
      FileName: "Attachment",
      Index: 0,
    },
  ]; */

  const [resultContract, setResultContract] = useState([]);

  const decoratedOnClick = useAccordionButton("", (index) => {
    resultContract[index].isOpen = resultContract[index]?.isOpen ? false : true;
    setAccordionSet(index);
  });
  const [accordionSet, setAccordionSet] = useState("");

  const entityColumns = [
    {
      key: "Entity",
      name: "Entity Type",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: "25%",
    },
    {
      key: "StateDesc",
      name: "State Registration",
      width: "25%",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "TaxType",
      name: "Tax ID Type",
      width: "25%",
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "Last4Tax",
      name: "Last 4 Your Tax ID",
      // width: 226,
      headerCellClass: "enablesTextWrappingHeading",
      width: "25%",
    },
  ];
  const ownershipColumns = [
    {
      key: "Name",
      name: "Name",
      width: "50%",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
    },
    {
      key: "Percentage",
      name: "Percentage",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
      width: "50%",
    },
  ];
  const positionColumns = [
    {
      key: "Person",
      name: "Person",
      width: "30%",
      cellClass: "enablesTextWrapping ",
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "Position",
      name: "Position",
      width: "70%",
      cellClass: "enablesTextWrapping ",
      headerCellClass: "enablesTextWrappingHeading",
    },
  ];
  const attachmentColumns = [
    {
      key: "FileName",
      name: "Description",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: "65%",
      formatter(props) {
        let temp = _.get(props, "row.FileName", null);
        let temp_exten = _.get(props, "row.FileExtension", null);
        temp = temp.replace(temp_exten, "");
        temp = temp.replace(".", "");
        return safeStringRenderer(temp);
      },
    },
    {
      key: "Url",
      name: "Attachment",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: "35%",
      formatter(props) {
        let temp1 = _.get(props, "row.Url", null); //,
        //temp2 = _.get(props, "row.Name", null);
        let temp_exte = _.get(props, "row.FileExtension", null);
        let temp2_exte = temp_exte.replace(".", "");
        return (
          <>
            {IsStringChecker(temp1) ? (
              <span>
                <a
                  href={GbsBaseUrl + temp1}
                  target={"_blank"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Export"
                  rel="noreferrer"
                >
                  <i
                    className={`fa ${
                      temp_exte == ".pdf" ? "fa-file-pdf-o" : "fa-file-excel-o"
                    }`}
                    alt="ExcelIcon"
                  ></i>
                  &ensp;
                  {temp2_exte}
                </a>
              </span>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];

  // const attachmentMobileColumns = [
  //   {
  //     key: "FileName",
  //     name: "Description",
  //     height: 60,
  //     headerCellClass: "left-border-radius right-border-radius",
  //     cellClass: "enablesTextWrapping break_all_word",
  //     formatter(props) {
  //       let temp = _.get(props, "row.FileName", null);
  //       let temp_exten = _.get(props, "row.FileExtension", null);
  //       temp = temp.replace(temp_exten, "");
  //       temp = temp.replace(".", "");

  //       let temp1 = _.get(props, "row.Url", null);
  //       return (
  //         <>
  //           {IsStringChecker(temp1) ? (
  //             <>
  //               <span>
  //                 <br />{" "}
  //                 <a
  //                   href={GbsBaseUrl + temp1}
  //                   target={"_blank"}
  //                   data-bs-toggle="tooltip"
  //                   data-bs-placement="bottom"
  //                   title="Export"
  //                   rel="noreferrer"
  //                 >
  //                   {safeStringRenderer(temp)}
  //                 </a>
  //               </span>
  //             </>
  //           ) : (
  //             "-"
  //           )}
  //         </>
  //       );
  //     },
  //   },
  // ];

  const safeStringRenderer = (str) => {
    try {
      if (IsStringChecker(str)) {
        return str;
      } else {
        return "-";
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "-";
    }
  };

  const joinArrToString = (arr = []) => {
    try {
      if (IsArrayNotEmpty(arr)) {
        return arr.join(`, `);
      } else {
        return ``;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return ``;
    }
  };

  const fetchContract = async () => {
    setLoading(true);
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/${personId}/contracts`
    )
      .then(async (response) => {
        const data_arr = safeArrayCheck(_.get(response, "data", []));
        const mapped_array = MapContractsWithRole(
          data_arr,
          accounts,
          DropDownCBRoles
        );
        const final_array = mapped_array.filter((contract) => {
          const { AssignedRoles = {} } = contract;
          const ThirdPartyFarmService = _.get(
            AssignedRoles,
            roleCBThirdPartyFarmService,
            false
          );
          return !ThirdPartyFarmService;
        });
        // console.log({ final_array });
        if (IsArrayNotEmpty(final_array)) {
          const resp = await Promise.all(
            final_array.map(async (contract, index) => {
              const { RelationshipId: ContractId = null, AssignedRoles = {} } =
                contract;
              const DecisionMaker = _.get(
                AssignedRoles,
                roleCBDecisionMaker,
                false
              );
              if (DecisionMaker) {
                await gbsDataCommonMethod(
                  tokenId,
                  accounts,
                  `contracts/${ContractId}/entities`
                )
                  .then((resp) => {
                    let temp = safeArrayCheck(_.get(resp, "data", []));
                    const output = temp.map((li, ind) => {
                      return { ...li, Index: ind };
                    });
                    final_array[index]["entities"] = safeArrayCheck(output);
                  })
                  .catch((e) => {
                    final_array[index]["entities"] = [];
                    HandleErrorTrackAndToast(e, true, "replace", dispatch);
                  });
                await gbsDataCommonMethod(
                  tokenId,
                  accounts,
                  `contracts/${ContractId}/ownership`
                )
                  .then((resp) => {
                    let temp = safeArrayCheck(_.get(resp, "data", []));
                    let person = [];
                    let contract = [];
                    temp.map((li, ind) => {
                      if (li.IsCompanyBased === 0) {
                        li.FirstName = li.Name.split(" ")[0];
                        li.LastName =
                          li.Name.split(" ")[li.Name.split(" ").length - 1];
                        person.push({ ...li, Index: ind });
                      } else {
                        contract.push({ ...li, Index: ind });
                      }

                      return { ...li, Index: ind };
                    });
                    person = IsArrayNotEmpty(person)
                      ? _.orderBy(
                          person,
                          ["Percentage", "LastName", "FirstName"],
                          ["desc", "asc", "asc"]
                        )
                      : [];

                    contract = IsArrayNotEmpty(contract)
                      ? _.orderBy(
                          contract,
                          ["Percentage", "Name"],
                          ["desc", "asc"]
                        )
                      : [];

                    //For ownership table sorting
                    final_array[index]["ownership"] = [...person, ...contract];
                  })
                  .catch((e) => {
                    final_array[index]["ownership"] = [];
                    HandleErrorTrackAndToast(e, true, "replace", dispatch);
                  });
                await gbsDataCommonMethod(
                  tokenId,
                  accounts,
                  `contracts/${ContractId}/positions`
                )
                  .then((resp) => {
                    let temp = safeArrayCheck(_.get(resp, "data", []));
                    let arr = temp.filter((item) => !item.Deceased);
                    let mapped = IsArrayNotEmpty(arr)
                      ? arr.map((item, ind) => {
                          const {
                            FirstName = null,
                            LastName = null,
                            MiddleName = null,
                            Description = null,
                          } = item;
                          const Person = safeStringRenderer(
                            `${
                              IsStringChecker(FirstName) ? `${FirstName}` : ``
                            }${
                              IsStringChecker(MiddleName)
                                ? ` ${MiddleName}`
                                : ``
                            }${IsStringChecker(LastName) ? ` ${LastName}` : ``}`
                          );
                          return {
                            Index: ind,
                            Person: Person,
                            Position: safeStringRenderer(Description),
                            LastName: safeStringRenderer(LastName),
                            FirstName: safeStringRenderer(FirstName),
                            MiddleName: safeStringRenderer(MiddleName),
                          };
                        })
                      : [];
                    let output = mapped.reduce(
                      (accumulator, value, index, arr) => {
                        try {
                          const { Person = null } = value;
                          let dups = arr.filter(
                            (li) => li["Person"] === Person
                          );
                          if (IsArrayNotEmpty(dups)) {
                            if (dups.length > 1) {
                              let newObj = { ...value };
                              let Desc = _.uniq(dups.map((li) => li.Position));
                              newObj.Position = joinArrToString(Desc);
                              return [...accumulator, newObj];
                            } else {
                              return [...accumulator, value];
                            }
                          } else {
                            return accumulator;
                          }
                        } catch (e) {
                          HandleErrorTrackAndToast(e, true);
                          return [];
                        }
                      },
                      []
                    );
                    let uniq = IsArrayNotEmpty(output)
                      ? _.uniqBy(output, "Person")
                      : [];
                    final_array[index]["positions"] = IsArrayNotEmpty(uniq)
                      ? _.orderBy(
                          uniq,
                          ["LastName", "FirstName"],
                          ["asc", "asc"]
                        )
                      : [];
                  })
                  .catch((e) => {
                    final_array[index]["positions"] = [];
                    HandleErrorTrackAndToast(e, true, "replace", dispatch);
                  });
                await gbsDataCommonMethod(
                  tokenId,
                  accounts,
                  `contracts/${ContractId}/attachments/8900`
                )
                  .then((resp) => {
                    let temp = safeArrayCheck(_.get(resp, "data", []));
                    const output = temp.map((li, ind) => {
                      return { ...li, Index: ind };
                    });
                    final_array[index]["attachments"] = IsArrayNotEmpty(output)
                      ? _.orderBy(output, ["ReportDate"], ["desc"])
                      : [];
                  })
                  .catch((e) => {
                    final_array[index]["attachments"] = [];
                    HandleErrorTrackAndToast(e, true, "replace", dispatch);
                  });
              } else {
                final_array[index]["entities"] = [];
                final_array[index]["ownership"] = [];
                final_array[index]["positions"] = [];
                final_array[index]["attachments"] = [];
              }
              return final_array[index];
            })
          );
          var sorted = IsArrayNotEmpty(resp)
            ? _.orderBy(
                resp,
                ["ContractStatus", "RelationshipNumber"],
                ["asc", "asc"]
              )
            : [];
          setResultContract(safeArrayCheck(sorted));
        } else {
          setResultContract([]);
        }
      })
      .catch((error) => {
        setResultContract([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fetchContract();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const displayNoRecord = `${_.get(
    moreLangs,
    `${resolvedLanguage}.display_no_record`,
    "No records to display"
  )}`;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row my-contracts-page">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
            <Breadcrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <LeftMenu {...props} slug="my-contracts" />
            <QuickLinks {...props} slug="my-contracts" />
          </div>
          <div className="col-12 col-lg-10 zooming_block">
            {IsArrayNotEmpty(resultContract) ? (
              resultContract.map((contract, index) => {
                const {
                  Name = null,
                  Name2 = null,
                  Name3 = null,
                  RelationshipNumber: Contract = null,
                  ContractStatus: Status = null,
                  ContractStatusDesc: StatusDesc = null,
                  ContractIdentifier = null,
                  CountryDesc = null,
                  AreaDesc = null,
                  RegionDesc = null,
                  StateDesc = null,
                  Street1 = null,
                  Street2 = null,
                  City = null,
                  MailingState = null,
                  PostalCode = null,
                  PoolDesc = null,
                  ContractBeginDate = null,
                  ContractEndDate = null,
                  isOpen = false,
                  entities = [],
                  ownership = [],
                  positions = [],
                  attachments = [],
                  AssignedRoles = {},
                } = contract;
                const display = Status === 1000 || Status === 1001;
                const open = index === accordionSet || isOpen;
                const access = open && display;
                const ContractName = safeStringRenderer(
                  `${IsStringChecker(Name) ? `${Name}` : ``}${
                    IsStringChecker(Name2) ? `<br>${Name2}` : ``
                  }${IsStringChecker(Name3) ? `<br>${Name3}` : ``}`
                );
                const DecisionMaker = _.get(
                  AssignedRoles,
                  roleCBDecisionMaker,
                  false
                );
                return (
                  <Accordion
                    className={index === 0 ? `mt-5 mt-lg-0` : `mt-0`}
                    key={index}
                  >
                    <Accordion.Item
                      eventKey={index}
                      onClick={() => decoratedOnClick(index)}
                    >
                      <Accordion.Header>
                        <div className="container p-0">
                          <div className="row">
                            <div className="col-md-3 col-5">
                              <h5>Contract Number</h5>
                              <h5 className="fw-bold mb-md-0">
                                {safeStringRenderer(Contract)}
                              </h5>
                            </div>
                            <div className="col-md-3 col-5">
                              <h5>Contract Name</h5>
                              <h5 className="fw-bold mb-md-0">
                                {parse(safeStringRenderer(ContractName))}
                              </h5>
                            </div>
                            <div className="col-md-3 col-5">
                              <h5>Contract Identifier</h5>
                              <h5 className="fw-bold mb-md-0">
                                {safeStringRenderer(ContractIdentifier)}
                              </h5>
                            </div>
                            <div className="col-md-2 col-5">
                              <h5>Status</h5>
                              <h5
                                className={
                                  display
                                    ? "fw-bold light_green_color mb-md-0"
                                    : "fw-bold red_color mb-md-0"
                                }
                              >
                                <i
                                  className={
                                    display
                                      ? "fa fa-check-circle right-space-5"
                                      : "fa fa-times-circle right-space-5"
                                  }
                                  aria-hidden="true"
                                ></i>
                                {safeStringRenderer(StatusDesc)}
                              </h5>
                            </div>
                            <div className="col-md-1 col-2">
                              <span className="accordion_icon_add"></span>
                            </div>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="p-2 p-sm-4">
                        <div className="hor-space-20 light_bg ver-space-30 border-radius-6 bot-mspace-20">
                          <div className="container p-0">
                            <div className="row bot-space-20">
                              <div className="col-md-3 col-6">
                                <h5 className="fw-bold">Contract Status</h5>
                                <h5>{safeStringRenderer(StatusDesc)}</h5>
                              </div>
                              <div className="col-md-2 col-6">
                                <h5 className="fw-bold">Pool</h5>
                                <h5>{safeStringRenderer(PoolDesc)}</h5>
                              </div>
                              <div className="col-md-2 col-6">
                                <h5 className="fw-bold">Country</h5>
                                <h5>{safeStringRenderer(CountryDesc)}</h5>
                              </div>
                              <div className="col-md-2 col-6">
                                <h5 className="fw-bold">Area</h5>
                                <h5>{safeStringRenderer(AreaDesc)}</h5>
                              </div>
                              <div className="col-md-3 col-6">
                                <h5 className="fw-bold">Region</h5>
                                <h5>{safeStringRenderer(RegionDesc)}</h5>
                              </div>
                            </div>
                          </div>
                          <div className="container p-0">
                            <div className="row">
                              <div className="col-md-3 col-6">
                                <h5 className="fw-bold">State/Province</h5>
                                <h5>{safeStringRenderer(StateDesc)}</h5>
                              </div>
                              <div className="col-md-2 col-6">
                                <h5 className="fw-bold">Start Date</h5>
                                <h5>
                                  {ContractBeginDate
                                    ? moment
                                        .utc(ContractBeginDate)
                                        .format("MM/DD/YYYY")
                                    : "-"}
                                </h5>
                              </div>
                              <div className="col-md-2 col-6">
                                <h5 className="fw-bold">Renew Date</h5>
                                <h5>
                                  {ContractEndDate
                                    ? moment
                                        .utc(ContractEndDate)
                                        .format("MM/DD/YYYY")
                                    : "-"}
                                </h5>
                              </div>
                              <div className="col-md-5 col-6">
                                <h5 className="fw-bold">Mailing Address</h5>
                                <h5>
                                  {IsStringChecker(Street1) ? `${Street1}` : ""}
                                  {IsStringChecker(Street1) ? <br /> : ""}
                                  {IsStringChecker(Street2) ? `${Street2}` : ""}
                                  {IsStringChecker(Street2) ? <br /> : ""}
                                  {IsStringChecker(City) ? `${City}, ` : ""}
                                  {IsStringChecker(MailingState)
                                    ? `${MailingState}, `
                                    : ""}
                                  {IsStringChecker(PostalCode)
                                    ? `${PostalCode} `
                                    : ""}
                                  {IsStringChecker(PostalCode) ? <br /> : ""}
                                  {IsStringChecker(CountryDesc)
                                    ? `${CountryDesc}`
                                    : ""}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        {access && (
                          <>
                            {IsArrayNotEmpty(entities) &&
                              DecisionMaker &&
                              (!displayAccordian ? (
                                <div
                                  className={`hor-space-20 pt-3 bot-space-10 light_bg border-radius-6 bot-mspace-20 overflow-auto receipt_table max_content_table p-2 p-lg-4 mob-grid-template-row-column`}
                                >
                                  <DataGridPagination
                                    uniqueIdentifier="Index"
                                    gridData={entities}
                                    columns={entityColumns}
                                    isPagination={true}
                                    itemsPerPage={10}
                                    columnResize={false}
                                    classNames="contain-block-size-reset bot-mspace-20 border-radius-6 mt-3"
                                  />
                                </div>
                              ) : (
                                <div className="accorfion-background pt-2 hor-space-20 accordion-btn-size-16 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 desktop-accordion-table">
                                  <div className="table1 mobile-accordion">
                                    <Accordion
                                      defaultActiveKey="0"
                                      className="table mobile-accordion accordion_space"
                                    >
                                      {IsArrayNotEmpty(entities) ? (
                                        entities.map((data, index) => {
                                          return (
                                            <Accordion.Item
                                              key={index}
                                              eventKey={index}
                                            >
                                              <Accordion.Header>
                                                Entity Type: {data.Entity}
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <div className="tbody">
                                                  <div className="tr">
                                                    <div className="td"></div>
                                                    <div
                                                      className="td"
                                                      data-header="State Registration:"
                                                    >
                                                      <span>
                                                        {safeStringRenderer(
                                                          data.StateDesc
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="td"
                                                      data-header="Tax ID Type:"
                                                    >
                                                      <span>
                                                        {safeStringRenderer(
                                                          data.TaxType
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="td"
                                                      data-header="SLast 4 Your Tax ID:"
                                                    >
                                                      <span>
                                                        {safeStringRenderer(
                                                          data.Last4Tax
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          );
                                        })
                                      ) : (
                                        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                                          <i className="fa fa-info-circle pe-2"></i>
                                          {displayNoRecord}
                                        </div>
                                      )}
                                    </Accordion>
                                  </div>
                                </div>
                              ))}
                            {DecisionMaker && (
                              <div className="hor-space-20 light_bg pt-4 pb-2 border-radius-6 bot-mspace-20 p-2 p-lg-4 mob-grid-template-row-column">
                                <h5 className="fw-bold bot-space-5">
                                  Ownership
                                </h5>
                                {!displayAccordian ? (
                                  <div
                                    className={`visible_print_mob_desktop top-space-20 light_bg border-radius-6 overflow-auto accordion_tabs_bg_space `}
                                  >
                                    {IsArrayNotEmpty(ownership) ? (
                                      <DataGridPagination
                                        uniqueIdentifier="Index"
                                        gridData={ownership}
                                        columns={ownershipColumns}
                                        isPagination={true}
                                        itemsPerPage={ownership.length}
                                        columnResize={false}
                                        classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                                      />
                                    ) : (
                                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
                                        <i className="fa fa-info-circle pe-2"></i>
                                        {displayNoRecord}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className="top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion-btn-size-16 accordion_tabs_bg_space mob-space-0 desktop-accordion-table">
                                    <div className="table1 mobile-accordion">
                                      <Accordion
                                        defaultActiveKey="0"
                                        className="table mobile-accordion accordion_space"
                                      >
                                        {IsArrayNotEmpty(ownership) ? (
                                          ownership.map((data, index) => {
                                            return (
                                              <Accordion.Item
                                                key={index}
                                                eventKey={index}
                                              >
                                                <Accordion.Header>
                                                  Name: {data.Name}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                  <div className="tbody">
                                                    <div className="tr">
                                                      <div className="td"></div>
                                                      <div
                                                        className="td"
                                                        data-header="Percentage:"
                                                      >
                                                        <span>
                                                          {data.Percentage ||
                                                            "-"}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            );
                                          })
                                        ) : (
                                          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                                            <i className="fa fa-info-circle pe-2"></i>
                                            {displayNoRecord}
                                          </div>
                                        )}
                                      </Accordion>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {DecisionMaker && (
                              <div className="hor-space-20 light_bg pt-4 bot-space-10 border-radius-6 bot-mspace-20">
                                <h5 className="fw-bold bot-space-5">
                                  People Associated
                                </h5>
                                {!displayAccordian && (
                                  <div
                                    className={`visible_print_mob_desktop top-space-20 light_bg border-radius-6 overflow-auto accordion_tabs_bg_space d-none d-lg-block`}
                                  >
                                    {IsArrayNotEmpty(positions) ? (
                                      <DataGridPagination
                                        uniqueIdentifier="Index"
                                        gridData={positions}
                                        columns={positionColumns}
                                        isPagination={true}
                                        itemsPerPage={10}
                                        columnResize={false}
                                        classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                                        dynamicHeight={45}
                                      />
                                    ) : (
                                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
                                        <i className="fa fa-info-circle pe-2"></i>
                                        {displayNoRecord}
                                      </div>
                                    )}
                                  </div>
                                )}
                                <div
                                  className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 accordion-btn-size-16  ${
                                    !displayAccordian
                                      ? "d-lg-none"
                                      : "desktop-accordion-table"
                                  }`}
                                >
                                  <div className="table1 mobile-accordion">
                                    <Accordion
                                      defaultActiveKey="0"
                                      className="table mobile-accordion accordion_space"
                                    >
                                      {IsArrayNotEmpty(positions) ? (
                                        positions.map((data, index) => {
                                          const {
                                            Position = null,
                                            Person = null,
                                          } = data;
                                          return (
                                            <Accordion.Item
                                              key={index}
                                              eventKey={index}
                                            >
                                              <Accordion.Header>
                                                Person: {Person}
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <div className="tbody">
                                                  <div className="tr">
                                                    <div className="td"></div>
                                                    <div
                                                      className="td"
                                                      data-header="Position:"
                                                    >
                                                      <span>
                                                        {safeStringRenderer(
                                                          Position
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          );
                                        })
                                      ) : (
                                        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                                          <i className="fa fa-info-circle pe-2"></i>
                                          {displayNoRecord}
                                        </div>
                                      )}
                                    </Accordion>
                                  </div>
                                </div>
                              </div>
                            )}
                            {DecisionMaker && (
                              <div className="hor-space-20 light_bg pt-4 bot-space-10 border-radius-6 bot-mspace-20">
                                <h5 className="fw-bold bot-space-5">
                                  Attachments
                                </h5>
                                {!displayAccordian && (
                                  <div
                                    className={`table-responsive visible_print_mob_desktop top-space-20 light_bg border-radius-6 overflow-auto accordion_tabs_bg_space d-none d-lg-block`}
                                  >
                                    {IsArrayNotEmpty(attachments) ? (
                                      <DataGridPagination
                                        uniqueIdentifier="Index"
                                        gridData={attachments}
                                        columns={attachmentColumns}
                                        isPagination={true}
                                        itemsPerPage={10}
                                        columnResize={false}
                                        classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                                        dynamicHeight={45}
                                      />
                                    ) : (
                                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
                                        <i className="fa fa-info-circle pe-2"></i>
                                        {displayNoRecord}
                                      </div>
                                    )}
                                  </div>
                                )}
                                {/* {!displayAccordian &&<div className="hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0  d-lg-none position-relative">
                                  <DataGridLoadMore
                                    uniqueIdentifier="id"
                                    gridData={attachments}
                                    columns={attachmentMobileColumns}
                                    isPagination={false}
                                    itemsPerPage={10}
                                    columnResize={false}
                                    classNames={`contain-block-size-reset ver-mspace-20 border-radius-6 grid-load-more-height-mob`}
                                    dynamicHeight={65}
                                    currentResultCount={currentResultCount}
                                    setCurrentResultCount={
                                      setCurrentResultCount
                                    }
                                  />
                                </div>
                                } */}
                                <div
                                  className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 accordion-btn-size-16  ${
                                    !displayAccordian
                                      ? "d-lg-none"
                                      : "desktop-accordion-table"
                                  }`}
                                >
                                  <div className="table1 mobile-accordion">
                                    <Accordion
                                      defaultActiveKey="0"
                                      className="table mobile-accordion accordion_space"
                                    >
                                      {IsArrayNotEmpty(positions) ? (
                                        attachments.map((data, index) => {
                                          let { FileName = null } = data;
                                          FileName.split(".")?.pop();
                                          return (
                                            <Accordion.Item
                                              key={index}
                                              eventKey={index}
                                            >
                                              <Accordion.Header>
                                                Description:{" "}
                                                {FileName?.toString()}
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <div className="tbody">
                                                  <div className="tr">
                                                    <div className="td"></div>
                                                    <div
                                                      className="td"
                                                      data-header="Attachment:"
                                                    >
                                                      <a
                                                        href={`${GbsBaseUrl}${data.url}`}
                                                      >
                                                        {" "}
                                                        gbs
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          );
                                        })
                                      ) : (
                                        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                                          <i className="fa fa-info-circle pe-2"></i>
                                          {displayNoRecord}
                                        </div>
                                      )}
                                    </Accordion>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                );
              })
            ) : (
              <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                <i className="fa fa-info-circle pe-2"></i>
                {displayNoRecord}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyContractsDetails;
