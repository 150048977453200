/** 
@CreatedBy : Ashish Ranjan/15-07-2022
@Function : Object.freeze 
@Description : Defined all constants variable here.
*/
module.exports = Object.freeze({
  dateFormate: "MM/DD/YYYY",
  timeFormate: "HH:mm",
  timeFormateAMPM: "HH:mm A",
  exportdateTimeFormate: "MM/DD/YYYY HH:mm",
  exportdateTimeFormateAMPM: "MM/DD/YYYY hh:mm:ss A",
  dateTimeFormate: "MM/DD/YYYY [at] HH:mm",
  startingDateOfYear: "Jan 01",
  endingDateOfYear: "Dec 31",
  notFoundMsg: "Record not found",
  defaultEventImageURL: "/assets/images/default_image.png",
  defaultLoadingImageURL: "/assets/images/default_loading_image.jpg",
  defaultLoadingImageVertURL: "/assets/images/default_loading_image_vert.jpg",
  croppedHarvestImageURL: "/assets/images/cropped_image_harvest.jpg",
  croppedHarvestHomeImageURL: "/assets/images/cropped_image.jpg",
  croppedHarvestZoomImageURL: "/assets/images/new_image.png",
  report_options: {
    Barrels: "Barrels",
    Yield: "Yield",
    // Acres: "Acres",
  },
  interactive_exhibit_report_options: {
    Barrels: "Barrels",
    // Acres: "Acres",
    Yield: "Yield",
    PercentDryTrash: "%Dry Trash",
    PercentMoisture: "%Moisture",
    // PercentTotalTrash: "%Total Trash",
    PercentPoor: "%Poor",
    PercentClassOne: "%Class1",
    PercentUsable: "%Usable",
    Firmness: "Firmness",
    "Percent>1/2": "%>1/2",
  },
  cashAssignmentsNotFoundMsg: `No Active Cash Assignments for this contract. 
  Contact the Grower Finance department for more information`,
  DirectDepositNotFoundMsg: `You do not currently have an active direct deposit. Please contact Grower Finance if you would like to enroll in electronic payments or <a href="#">click here</a> for the authorization form. 
  Please note: all checks are printed by Bank of America and medical directly to recipients. 
  `,
  statement_type: [
    {
      name: "Payment Statement",
      id: "payment",
    },
    {
      name: "Incentive Statement",
      id: "incentive",
    },
    {
      name: "Assessment Statement",
      id: "assessment",
    },
    {
      name: "Incentive and Assessments by receipt",
      id: "incentive_assessment_receipt",
    },
  ],
  equity_type: [
    {
      id: "1",
      name: "Allocated Retained Earnings Statement",
    },
    {
      id: "2",
      name: "Statement of Growers Equity Transactions",
    },
  ],
  tax_type: [
    {
      id: "tax-1099-div",
      name: "1099 DIV",
    },
    {
      id: "tax-1099-patr",
      name: "1099 PATR",
    },
    {
      id: "tax-1099-int",
      name: "1099 INT",
    },
    {
      id: "tax-1099-recon",
      name: "1099 Reconciliation",
    },
    {
      id: "tax-1099-section",
      name: "Section 199 Notice",
    },
  ],
  event_order: `event_date_from.asc`,
  board_council_contacts_tabs: [
    "Board Of Directors",
    // "Board Commitee",
    "Grower Council",
    "Strategic Advisors",
  ],
  my_os_contacts_tabs: [
    "Grower Relations",
    "Receiving",
    "Ag Team",
    "Grower Finance",
  ],
  notification_preference_headers: [
    { active: true, disable_text: true, disable_email: true, hide_text: false, hide_email: false, name: "General News" },
    { active: true, disable_text: true, disable_email: true, hide_text: false, hide_email: false, name: "MUST SEE Communications" },
    { active: false, disable_text: true, disable_email: true, hide_text: false, hide_email: false, name: "Harvest Announcements" },
    { active: true, disable_text: true, disable_email: true, hide_text: false, hide_email: false, name: "News Feed" },
    { active: true, disable_text: false, disable_email: false, hide_text: true, hide_email: false, name: "PSP Approval" },
  ],
  component_listing_dropdowns: {
    accordion: "accordion",
    grid: "grid",
    slider: "slider",
    plainText: "plain-text",
    blueTitleBox: "blue-title-box",
  },
  component_accordion_en_path: "component_listings.accordion_name_en",
  course_types_title_en_path: "academy_course_types.title_en",
  defaultToastProperties: {
    showToast: true,
    animation: true,
    position: "top-end",
    containerPosition: "fixed",
    closeButton: true,
    closeLabel: "Close toast message",
  },
  defaultToastMsgProperties: {
    title: "",
    message: "",
    bg: "danger",
    color: "white",
    display: "content",
    delay: 5000,
    autoHide: true,
  },
  defaultSiteTitle: "Ocean Spray®",
  defaultPageTilte: {
    title_en: "Home",
    title_fr: "Maison",
    title_es: "Hogar",
  },
});
