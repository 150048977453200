import React from "react";
import Body from "./body";
import PageLayout from "../../pageLayout";
import Main from "./main";

const HarvestNews = (props) => {
  return <PageLayout {...props} body={<Body {...props} contentbody={<Main {...props} />} />} fullWidth={true} />;
};

export default HarvestNews;
