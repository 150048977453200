import React, { Fragment, useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import DataGridPagination from "../../global/dataGridPagination";
import MultiDropDown from "../../selectBox/multiDropDown";
import WhiteCircleLoader from "../../whiteCircleLoader";
import { setObjToString, getResolvedLanguage } from "../../../utils/helper";
import CurrentLocation from "../../global/currentLocation";
import { useTranslation } from "react-i18next";
const MyPeopleSearch = (props) => {
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [myPeopleSearchData, setMyPeopleSearchData] = useState([]);
  const [myPeopleSearchResult, setMyPeopleSearchResult] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading] = useState(false);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [clearstatusOther, setClearstatusOther] = useState(false);
  const [ContractNumbers, setContractNumbers] = useState([]);
  const [CN_MultiValue, setCN_MultiValue] = useState({});
  const [multiValue, setMultiValue] = useState({});
  const [firstNameFilter, setFirstNameFilter] = useState([]);
  const [lastNamesFilter, setLastNamesFilter] = useState([]);
  const [emailFilter, setEmailFilter] = useState([]);
  const [securityTypeFilter, setSecurityTypeFilter] = useState([]);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);
  const [disable, setDisable] = useState(true);
  const [defContracts] = useState(["all"]);
  const [preselectValuesFirstName, setPreselectValuesFirstName] = useState([
    "",
  ]);
  const [preselectValuesLastName, setPreselectValuesLastName] = useState([""]);
  const [preselectValuesEmail, setPreselectValuesEmail] = useState([""]);
  const [preselectValuesSecurityType, setPreselectValuesSecurityType] =
    useState([""]);
  const [forwordOrder, setForwordOrder] = useState(0);
  const [myPeopleSearchResultStatus, setMyPeopleSearchResultStatus] =
    useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const securityTypeRef = useRef(null);
  const [setHeight, setHeighter] = useState("");

  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    let data = props?.data?.map((item) => {
      // delete item.Description;
      // delete item.DescriptionMerged;
      delete item.PositionId;
      return item;
    });
    data = _.uniqWith([...data], _.isEqual);
    data = data.map((item, index) => {
      item.ItemId = index;
      item.ContractIdentifier =
        item.ContractIdentifier?.toString().replace("#", "") || "";
      item.ExtranetRole = item.ExtranetRole?.replace(/,/g, ", ");
      item.ExtranetRoleModified = item.ExtranetRole?.replace(/,/g, "_");
      return item;
    });
    data = data.filter((item) => !item.Deceased);
    data = _.orderBy(
      data,
      ["LastName", "ContractStatus", "RelationshipNumber"],
      ["asc", "asc", "asc"]
    );
    // console.log("data", data);

    const descriptionData = _.groupBy(data, "PersonId");
    let buildTableData = [];
    Object.keys(descriptionData).map((i) => {
      let personArray = _.groupBy(descriptionData[i], "RelationshipId");
      // console.log("descriptionData",descriptionData[i])
      Object.keys(personArray).map((k) => {
        let data = personArray[k][0];
        data.DescriptionMerged = _.uniqWith(
          [_.map(personArray[k], "Description")],
          _.isEqual
        )
          .toString()
          ?.replace(/,/g, ", ");
        buildTableData.push(data);
      });
    });
    // console.log("personArray buildTableData",buildTableData);

    setMyPeopleSearchData(buildTableData);
    buildTableData = _.orderBy(buildTableData,
      ["ContractStatus", "RelationshipNumber"],
      ["asc", "asc"]
    );
    setContractNumbers(
      setOptions(
        buildTableData,
        "RelationshipId",
        false,
        "RelationshipNumber",
        "Name"
      )
    );
    setFirstNameFilter(setOptions(buildTableData, "FirstName", true));
    setLastNamesFilter(setOptions(buildTableData, "LastName", true));
    setEmailFilter(setOptions(buildTableData, "ExtranetUsername", true));
    setSecurityTypeFilter(setOptions(buildTableData, "ExtranetRole", true));
    setFilterData(buildTableData);
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
  }, []);
  //Defining Grid Column
  const columns = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
    },
    {
      key: "Name",
      name: "Contract Name",
      width: 130,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
    },
    {
      key: "LastName",
      name: "Last Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
      width: 100,
    },
    {
      key: "MiddleName",
      name: "Middle Initial",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
      width: 80,
    },
    {
      key: "FirstName",
      name: "First Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
      width: 80,
    },
    {
      key: "DescriptionMerged",
      name: "Position",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping break_all_word",
      width: 310,
      formatter(props) {
        return <>{props.row.DescriptionMerged || "-"}</>;
      },
    },
    {
      key: "ExtranetRole",
      name: "Extranet Security Role",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
      width: 148,
      formatter(props) {
        return <>{props.row.ExtranetRole || "-"}</>;
      },
    },
  ];

  const setMultiselectData = (filterData) => {
    onClearfields();
    setFirstNameFilter(setOptions(filterData, "FirstName", true));
    setLastNamesFilter(setOptions(filterData, "LastName", true));
    setEmailFilter(setOptions(filterData, "ExtranetUsername", true));
    setSecurityTypeFilter(setOptions(filterData, "ExtranetRole", true));
  };

  // Mobile filter change event
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const onContractNumberChange = (val) => {
    setDisableSubmitCancel(false);
    setDisable(false);
    let contractId = setObjToString(val);
    let data = myPeopleSearchData.filter((item) => {
      return contractId.toString().indexOf(item.RelationshipId.toString()) > -1;
    });
    setFilterData([...data]);
    setMultiselectData(data);
  };
  const onContractNumberRemove = (selectedList, removedItem) => {
    onClearfields();
    setMultiValue({});
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisableSubmitCancel(true);
      setDisable(true);
      setFilterData([...myPeopleSearchData]);
      setMultiselectData(myPeopleSearchData);
    } else {
      let contractId = setObjToString(selectedList);
      let data = myPeopleSearchData.filter((item) => {
        return (
          contractId.toString().indexOf(item.RelationshipId.toString()) > -1
        );
      });
      setFilterData([...data]);
      setDisableSubmitCancel(false);
      setMultiselectData(data);
    }
  };
  const filter = (data, filterText, property) => {
    return data.filter((i) => {
      return filterText.indexOf(i[property]) > -1;
    });
  };
  function cascadingFilter() {
    if (arguments[10] === "select") {
      if (!(arguments[16] >= forwordOrder)) {
        setForwordOrder(arguments[16]);
        setMultiselectData(arguments[1]);
        let a = {};
        setMultiValue(null);
        a[arguments[3]] = setObjToString(arguments[2]);
        arguments[13]("");
        arguments[14]("");
        arguments[15]("");
        arguments[12](setObjToString(arguments[2])?.split(","));
        setMultiValue({ ...a });
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[7](setOptions(arguments[1], arguments[4], true));
        arguments[8](setOptions(arguments[1], arguments[5], true));
        arguments[9](setOptions(arguments[1], arguments[6], true));
        setClearstatusOther(true);
        setTimeout(() => {
          setClearstatusOther(false);
        }, 0);
        return;
      }
      setForwordOrder(arguments[16]);
      if (
        arguments[0].hasOwnProperty(arguments[4]) == false &&
        arguments[0].hasOwnProperty(arguments[5]) == false &&
        arguments[0].hasOwnProperty(arguments[6]) == false
      ) {
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[7](setOptions(arguments[1], arguments[4], true));
        arguments[8](setOptions(arguments[1], arguments[5], true));
        arguments[9](setOptions(arguments[1], arguments[6], true));
      } else if (
        arguments[0].hasOwnProperty(arguments[4]) == true &&
        arguments[0].hasOwnProperty(arguments[5]) == false &&
        arguments[0].hasOwnProperty(arguments[6]) == false
      ) {
        arguments[1] = filter(
          arguments[1],
          arguments[0][arguments[4]],
          arguments[4]
        );
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[8](setOptions(arguments[1], arguments[5], true));
        arguments[9](setOptions(arguments[1], arguments[6], true));
      } else if (
        arguments[0].hasOwnProperty(arguments[4]) == false &&
        arguments[0].hasOwnProperty(arguments[5]) == true &&
        arguments[0].hasOwnProperty(arguments[6]) == false
      ) {
        arguments[1] = filter(
          arguments[1],
          arguments[0][arguments[5]],
          arguments[5]
        );
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[7](setOptions(arguments[1], arguments[4], true));
        arguments[9](setOptions(arguments[1], arguments[6], true));
      } else if (
        arguments[0].hasOwnProperty(arguments[4]) == false &&
        arguments[0].hasOwnProperty(arguments[5]) == false &&
        arguments[0].hasOwnProperty(arguments[6]) == true
      ) {
        arguments[1] = filter(
          arguments[1],
          arguments[0][arguments[6]],
          arguments[6]
        );
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[7](setOptions(arguments[1], arguments[4], true));
        arguments[8](setOptions(arguments[1], arguments[5], true));
      } else if (
        arguments[0].hasOwnProperty(arguments[4]) == true &&
        arguments[0].hasOwnProperty(arguments[5]) == true &&
        arguments[0].hasOwnProperty(arguments[6]) == false
      ) {
        arguments[1] = filter(
          arguments[1],
          arguments[0][arguments[4]],
          arguments[4]
        );
        arguments[1] = filter(
          arguments[1],
          arguments[0][arguments[5]],
          arguments[5]
        );
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[9](setOptions(arguments[1], arguments[6], true));
      }
    } else {
      if (!(arguments[16] >= forwordOrder)) {
        setForwordOrder(arguments[16]);
        setMultiselectData(arguments[1]);
        let a = {};
        setMultiValue(null);
        a[arguments[3]] = setObjToString(arguments[2]);
        arguments[13]("");
        arguments[14]("");
        arguments[15]("");
        arguments[12](setObjToString(arguments[2])?.split(","));
        setMultiValue({ ...a });
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[7](setOptions(arguments[1], arguments[4], true));
        arguments[8](setOptions(arguments[1], arguments[5], true));
        arguments[9](setOptions(arguments[1], arguments[6], true));
        setClearstatusOther(true);
        setTimeout(() => {
          setClearstatusOther(false);
        }, 0);
        return;
      }
      setForwordOrder(arguments[16]);
    }
  }

  // common handler for multiselect
  const handlerOtherFilter = (selectedList, selectedItem, property, option) => {
    let filterArray = [...filterData];
    let mutiSelectVal =
      option === "deselect" || multiValue === null ? {} : multiValue;
    switch (property) {
      case "LastName":
        cascadingFilter(
          mutiSelectVal,
          filterArray,
          selectedList,
          "LastName",
          "FirstName",
          "ExtranetUsername",
          "ExtranetRole",
          setFirstNameFilter,
          setEmailFilter,
          setSecurityTypeFilter,
          option,
          setLastNamesFilter,
          setPreselectValuesLastName,
          setPreselectValuesFirstName,
          setPreselectValuesEmail,
          setPreselectValuesSecurityType,
          1
        );
        break;
      case "FirstName":
        cascadingFilter(
          mutiSelectVal,
          filterArray,
          selectedList,
          "FirstName",
          "LastName",
          "ExtranetUsername",
          "ExtranetRole",
          setLastNamesFilter,
          setEmailFilter,
          setSecurityTypeFilter,
          option,
          setFirstNameFilter,
          setPreselectValuesFirstName,
          setPreselectValuesLastName,
          setPreselectValuesEmail,
          setPreselectValuesSecurityType,
          2
        );
        break;
      case "ExtranetUsername":
        cascadingFilter(
          mutiSelectVal,
          filterArray,
          selectedList,
          "ExtranetUsername",
          "FirstName",
          "LastName",
          "ExtranetRole",
          setFirstNameFilter,
          setLastNamesFilter,
          setSecurityTypeFilter,
          option,
          setEmailFilter,
          setPreselectValuesEmail,
          setPreselectValuesFirstName,
          setPreselectValuesLastName,
          setPreselectValuesSecurityType,
          3
        );
        break;
      case "ExtranetRole":
        cascadingFilter(
          mutiSelectVal,
          filterArray,
          selectedList,
          "ExtranetRole",
          "FirstName",
          "LastName",
          "ExtranetUsername",
          setFirstNameFilter,
          setLastNamesFilter,
          setEmailFilter,
          option,
          setSecurityTypeFilter,
          setPreselectValuesSecurityType,
          setPreselectValuesFirstName,
          setPreselectValuesLastName,
          setPreselectValuesEmail,
          4
        );
        break;
    }
    setMyPeopleSearchResult([]);
    setMyPeopleSearchResultStatus(false);
    setNoResultsFound(false);
  };

  // On click search
  const onAllSearch = () => {
    let filter = [...myPeopleSearchData];
    if (CN_MultiValue?.RelationshipId) {
      filter = filter.filter((item) => {
        return (
          CN_MultiValue?.RelationshipId?.toLowerCase()?.indexOf(
            item?.RelationshipId?.toString().toLowerCase()
          ) !== -1
        );
      });
    }
    if (multiValue?.FirstName) {
      filter = filter.filter((item) => {
        return (
          multiValue?.FirstName?.toLowerCase()?.indexOf(
            item?.FirstName?.toLowerCase()
          ) !== -1
        );
      });
    }
    if (multiValue?.LastName) {
      filter = filter.filter((item) => {
        return (
          multiValue?.LastName?.toLowerCase()?.indexOf(
            item?.LastName?.toLowerCase()
          ) !== -1
        );
      });
    }
    if (multiValue?.ExtranetRole) {
      let a = [];
      multiValue?.ExtranetRole.split(",").map((i) => {
        let fil = filter.filter((item) => {
          return (
            item.ExtranetRole?.toLowerCase().indexOf(i.toLowerCase()) > -1 ||
            false
          );
        });
        a.push(fil);
      });
      filter = _.uniqWith([...a.flat()], _.isEqual);
    }
    if (multiValue?.ExtranetUsername) {
      filter = filter.filter((item) => {
        return (
          multiValue?.ExtranetUsername?.toLowerCase()?.indexOf(
            item?.ExtranetUsername?.toLowerCase()
          ) !== -1
        );
      });
    }
    if (
      filter.length > 0 &&
      filter !== "Contract and CropYear must be provided"
    ) {
      setMyPeopleSearchResult(filter);
      setMyPeopleSearchResultStatus(true);
      setNoResultsFound(false);
    } else {
      setMyPeopleSearchResult([]);
      setMyPeopleSearchResultStatus(false);
      setNoResultsFound(true);
    }
    handlerMobFilter("cancel");
  };

  const onClearfields = () => {
    setForwordOrder(0);
    setMyPeopleSearchResult([]);
    setMyPeopleSearchResultStatus(false);
    setMultiValue(null);
    setFirstNameFilter([]);
    setLastNamesFilter([]);
    setEmailFilter([]);
    setSecurityTypeFilter([]);
    setNoResultsFound(false);
    setPreselectValuesFirstName("");
    setPreselectValuesLastName("");
    setPreselectValuesEmail("");
    setPreselectValuesSecurityType("");
  };
  const onClearSearch = () => {
    setForwordOrder(0);
    setMyPeopleSearchResult([]);
    setMyPeopleSearchResultStatus(false);
    setMultiValue(null);
    setFirstNameFilter([]);
    setLastNamesFilter([]);
    setEmailFilter([]);
    setSecurityTypeFilter([]);
    setNoResultsFound(false);
    setDisable(true);
    setDisableSubmitCancel(true);
    setPreselectValuesFirstName("");
    setPreselectValuesLastName("");
    setPreselectValuesEmail("");
    setPreselectValuesSecurityType("");
    setCN_MultiValue(null)
  };

  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  // creating option for mulit select
  const setOptions = (data, keyPrram, unique, concatValue1, concatValue2) => {
    if (unique && keyPrram === "ExtranetRole") {
      return data
        .filter(
          (i) =>
            i[keyPrram] !== null &&
            i[keyPrram] !== undefined &&
            i[keyPrram] !== ""
        )
        .map((li) => li[keyPrram].replace(/, /g, ",").trim())
        .filter(onlyUnique)
        .map((li) => {
          return li.replace(/, /g, ",").split(",");
        })
        .flat(1)
        .filter(onlyUnique)
        .map((li) => {
          return { name: li, id: li?.replace(/,/g, "_") };
        });
    } else if (unique && keyPrram !== "RelationshipId") {
      return data
        .filter(
          (i) =>
            i[keyPrram] !== null &&
            i[keyPrram] !== undefined &&
            i[keyPrram] !== ""
        )
        .map((li) => li[keyPrram])
        .filter(onlyUnique)
        .map((li) => {
          return {
            name: li,
            id: li?.replace(/,/g, "_")?.replace(/ /g, ""),
          };
        });
    } else {
      const contracts = data.map((li) => {
        return concatValue1 && concatValue2
          ? {
              name: li[concatValue1] + " " + li[concatValue2],
              id: li[keyPrram],
            }
          : { name: li[keyPrram], id: li[keyPrram] };
      });
      return [...new Map(contracts.map((item) => [item["id"], item])).values()];
    }
  };

  securityTypeRef?.current?.childNodes[0]?.childNodes[0]?.childNodes[0]?.children[0].addEventListener(
    "focus",
    () => {
      window.innerWidth < 992 && setHeighter("setHeightForMobile");
    }
  );
  securityTypeRef?.current?.childNodes[0]?.childNodes[0]?.childNodes[0]?.children[0].addEventListener(
    "focusout",
    () => {
      setHeighter("");
    }
  );
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  // console.log(securityTypeRef?.current?.childNodes[0]?.childNodes[0]?.childNodes[0]?.children[0])
  return (
    <>
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            {moreLangs[i18n.resolvedLanguage]?.button_text.cancel_text}
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            {moreLangs[i18n.resolvedLanguage]?.button_text.reset}
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 p-2 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">
            {moreLangs[i18n.resolvedLanguage]?.button_text.filter}
          </span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? "show" : ""
          } ${setHeight} setHeight`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="contract_number_Label custom_dropdown white_bg border-radius-5">
                  <MultiDropDown
                    optionList={ContractNumbers}
                    prompt="Select"
                    value={CN_MultiValue}
                    onChange={(val) =>
                      onContractNumberChange(val, "RelationshipId")
                    }
                    onDelete={(selectedList, removedItem) =>
                      onContractNumberRemove(
                        selectedList,
                        removedItem,
                        "RelationshipId"
                      )
                    }
                    label="RelationshipId"
                    usestate={setCN_MultiValue}
                    disabled={""}
                    preSelectedOpt={defContracts}
                    optionNotFound={{ RelationshipId: noContractsFound }}
                  />
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="last_name_Label custom_dropdown white_bg border-radius-5">
                {!clearstatusOther && (
                  <MultiDropDown
                    optionList={lastNamesFilter}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "LastName",
                        "select"
                      )
                    }
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "LastName",
                        "deselect"
                      )
                    }
                    label="LastName"
                    usestate={setMultiValue}
                    disabled={disable}
                    preSelectedOpt={preselectValuesLastName}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="first_name_Label custom_dropdown white_bg border-radius-5">
                {!clearstatusOther && (
                  <MultiDropDown
                    optionList={firstNameFilter}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "FirstName",
                        "select"
                      )
                    }
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "FirstName",
                        "deselect"
                      )
                    }
                    label="FirstName"
                    usestate={setMultiValue}
                    disabled={disable}
                    preSelectedOpt={preselectValuesFirstName}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="email_Label custom_dropdown white_bg border-radius-5">
                {!clearstatusOther && (
                  <MultiDropDown
                    optionList={emailFilter}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "ExtranetUsername",
                        "select"
                      )
                    }
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "ExtranetUsername",
                        "deselect"
                      )
                    }
                    label="ExtranetUsername"
                    usestate={setMultiValue}
                    disabled={disable}
                    preSelectedOpt={preselectValuesEmail}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div
                className="security_type_Label custom_dropdown white_bg border-radius-5"
                ref={securityTypeRef}
              >
                {!clearstatusOther && (
                  <MultiDropDown
                    optionList={securityTypeFilter}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "ExtranetRole",
                        "select"
                      )
                    }
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "ExtranetRole",
                        "deselect"
                      )
                    }
                    label="ExtranetRole"
                    usestate={setMultiValue}
                    disabled={disable}
                    preSelectedOpt={preselectValuesSecurityType}
                  />
                )}
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={onAllSearch}
                disabled={disableSubmitCancel}
              >
                {moreLangs[i18n.resolvedLanguage]?.my_people.search}
                {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block clear-btn-grey"
                onClick={() => onClearSearch()}
                disabled={disableSubmitCancel}
              >
                {moreLangs[i18n.resolvedLanguage]?.button_text.clear}
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
      </nav>
      <div
        className={`bottom-0  filter_apply_button pt-2 white_bg ${
          isMobFilter ? "show d-block " : " d-lg-none "
        }`}
      >
        {/* <button className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none" disabled="">Apply   </button> */}
        <button
          className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
          onClick={onAllSearch}
          disabled={disableSubmitCancel}
        >
          {moreLangs[i18n.resolvedLanguage]?.button_text.apply}
          {!loading ? "" : <WhiteCircleLoader />}{" "}
        </button>
      </div>
      {myPeopleSearchResult.length > 0 && myPeopleSearchResultStatus && !displayAccordian && (
        <div className="white_bg p-2 p-lg-4 border-radius-6 mt-2 mb-3 d-none d-lg-block">
          <div
            className={`visible_print_mob_desktop hor-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block`}
          >
            <DataGridPagination
              uniqueIdentifier="ItemId"
              gridData={myPeopleSearchResult}
              columns={columns}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset top-mspace-20 bot-mspace-20 border-radius-6"
              autoRowHeight={true}
              paddingRowHeight={90}
              dynamicHeaderHeight={60}
            />
          </div>
        </div>
      )}
      {/* <!-- Mobile table starts --> */}
      {myPeopleSearchResult.length > 0 && myPeopleSearchResultStatus && (
        <div className={`white_bg p-2 p-lg-4 border-radius-6 mt-2 mb-3  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}>
          <div
            className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0`}
          >
            <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
              <Accordion defaultActiveKey="0">
                {myPeopleSearchResult.length > 0 &&
                  Object.values(myPeopleSearchResult).map((data, index) => {
                    return (
                      <Fragment key={index}>
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header>
                            Contract: {data.RelationshipNumber}{" "}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="tbody">
                              <div className="tr">
                                <div
                                  className="td"
                                  data-header="Contract #:"
                                ></div>
                                <div className="td" data-header="Contract #:">
                                  {data.RelationshipNumber}
                                </div>
                                <div
                                  className="td"
                                  data-header="Contract Name:"
                                >
                                  <span>{data.Name}</span>
                                </div>
                                {/* <div className="td" data-header="Contract Identifier:">
                                                                {data.ContractIdentifier ? `#${data.ContractIdentifier}` : '-'}
                                                            </div>
                                                            <div className="td" data-header="Contract Status:">
                                                                {data.ContractStatusDesc || '-'}
                                                            </div> */}

                                <div className="td" data-header="Last Name:">
                                  {data.LastName || "-"}
                                </div>
                                <div
                                  className="td"
                                  data-header="Middle Initial:"
                                >
                                  {data.MiddleName || "-"}
                                </div>
                                <div className="td" data-header="First Name:">
                                  {data.FirstName || "-"}
                                </div>
                                <div
                                  className="td break_all_word"
                                  data-header="Position:"
                                >
                                  {data.DescriptionMerged || "-"}
                                </div>
                                <div
                                  className="td"
                                  data-header="Extranet Security Role:"
                                >
                                  {data.ExtranetRole || "-"}
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Fragment>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        </div>
      )}
      {/* <!-- Mobile table ends --> */}
      {/* no record found section start */}
      {myPeopleSearchResult.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-2">
          <i className="fa fa-info-circle pe-2"></i>
          {moreLangs[i18n.resolvedLanguage]?.my_people?.my_people_notFoundMsg}
        </div>
      )}
      {/* no record found section End */}
    </>
  );
};
export default MyPeopleSearch;
