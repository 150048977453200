/**
 * @component_Name : GoverningDoc Body
 * @description : Governing Doc Body
 * @company : Photon
 * @author : Ashish Ranjan/28-10-2022
 **/
import React from "react";
import LeftMenu from "../../global/leftMenu";
import Breadcrumb from "../../global/breadCrumb";
import LandingPageCtaContentBlock from "../../global/landingPageCtaContentBlock";
const Body = (props) => {
  const { isLeftMenu, ...rest } = props;
  return (
    <>
      <div className="row">
        {isLeftMenu ? (
          <>
            <div className="row">
              <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
                <Breadcrumb {...rest} />
              </div>
            </div>
            <div className="row">
              <div className="d-none d-lg-block col-lg-2 hidden_print">
                <LeftMenu {...rest} slug={"governing-documents"} />
              </div>
              <div className="col-12 col-lg-10 zooming_block">
                <LandingPageCtaContentBlock
                  slug={"governing-documents"}
                  {...rest}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-12 d-none d-lg-block">
              <Breadcrumb {...rest} />
            </div>
            <LandingPageCtaContentBlock
              slug={"governing-documents"}
              {...rest}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Body;
