import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion } from "react-bootstrap";
import MultiDropDown from "../../../selectBox/multiDropDown";
import DataGridPagination from "../../../global/dataGridPagination";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import CurrentLocation from "../../../global/currentLocation";
import {
  IsTokenChecker,
  IsArrayNotEmpty,
  IsStringChecker,
  getResolvedLanguage,
  IsObjectChecker,
  StringToInteger,
  HandleErrorTrackAndToast,
} from "../../../../utils/helper";
import Loading from "../../../global/loading";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import { ConstantsVar, GbsBaseUrl } from "../../../../utils/constants";
import DatePickerComp from "../../../global/datepicker";
import { ImpersonationAccountsSwap } from "../../../../permissionWrapper";

const Equity = ({ contracts = [], selectedContract = null }) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);

  const { dateFormate, equity_type } = ConstantsVar;

  const [loading, setLoading] = useState(false);
  const [circleLoader, setCircleLoader] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);
  const [gridstatus, setGridStatus] = useState(false);

  const [statements] = useState(equity_type);
  const [dateDisabled] = useState(false);
  const [minDate] = useState(null);
  const [maxDate] = useState(new Date());

  const [reqStatement, setReqStatement] = useState({});
  const [reqContract, setReqContract] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());

  const [defaultContract, setDefaultContract] = useState([]);

  const [clrBtnDisabled, setClrBtnDisabled] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [viewBtnDisabled, setViewBtnDisabled] = useState(true);

  const [tableList, setTableList] = useState([]);
  const [nameKeeper, setNameKeeper] = useState(null);

  const [freshPage, setFreshPage] = useState(true);

  const [isMobFilter, setisMobFilter] = useState(false);

  const [isViewButtonClicked, setIsViewButtonClicked] = useState(false);

  const MobileViewRenderer = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  useEffect(() => {
    if (selectedContract) {
      setReqContract({ contract: `${selectedContract}` });
      setDefaultContract([selectedContract]);
      setClrBtnDisabled(false);
    } else {
      setReqContract({});
      setDefaultContract([]);
      setClrBtnDisabled(true);
    }
    MultiDropDownFix();
  }, [selectedContract]);

  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    // setTimeout(() => {
    //   if(equity_type.length ===1)
    //     document.querySelector(".eqityStatementType .optionListContainer .highlightOption").click();
    // }, 100);
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const setObjArrToString = (val) => {
    try {
      if (IsArrayNotEmpty(val)) {
        let ids = val.map((x) => x.id);
        ids = ids.filter((x) => x != "all");
        return `${ids}`;
      } else {
        return ``;
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return ``;
    }
  };

  const joinedStringToArr = (val) => {
    try {
      if (IsStringChecker(val)) {
        let ids = val.split(",");
        return ids;
      } else {
        return [];
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  const getFormattedUTCDate = (date = null, dateFormat = dateFormate) => {
    try {
      if (date) {
        return moment.utc(date).format(dateFormat);
      } else {
        return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const URLSuffixGen = (
    startDate = null,
    endDate = null,
    dateFormat = "YYYY-MM-DD"
  ) => {
    try {
      let datefrom = startDate
        ? `&datefrom=${moment(startDate).format(dateFormat)}`
        : "";
      let dateto = endDate
        ? `&dateto=${moment(endDate).format(dateFormat)}`
        : "";
      return `${datefrom}${dateto}`;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return ``;
    }
  };

  const RegulateFileURL = (val) => {
    try {
      if (IsStringChecker(val)) {
        if (val.includes("https://")) {
          return val;
        } else {
          return `${GbsBaseUrl}${val}`;
        }
      } else {
        return val;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return val;
    }
  };

  const getStatementName = (id = null) => {
    try {
      if (IsStringChecker(id)) {
        let statement_obj = equity_type.find((li) => li.id === id);
        return _.get(statement_obj, "name", null);
      } else {
        return id;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return id;
    }
  };

  const MultiDropDownFix = () => {
    setClearStatus(true);
    setTimeout(() => {
      setClearStatus(false);
    }, 0);
  };

  const DataGridFix = () => {
    setGridStatus(true);
    setTimeout(() => {
      setGridStatus(false);
    }, 0);
  };

  const DateReset = () => {
    setStartDate("");
    setEndDate("");
  };

  const onMultiSelectChange = (selectedList, selectedItem, type) => {
    const selected = _.get(selectedItem, "id", null);
    const list = setObjArrToString(selectedList);
    if (type === "statement") {
      let contract = _.get(reqContract, "contract", "");
      FetchButtonHandler(selected, contract, startDate, endDate);
    } else if (type === "contract") {
      let statement = _.get(reqStatement, "statement", "");
      FetchButtonHandler(statement, list, startDate, endDate);
    }
  };

  const onMultiSelectRemove = (removedList, removedItem, type) => {
    const removed = _.get(removedItem, "id", null);
    const list = setObjArrToString(removedList);
    if (type === "statement") {
      let contract = _.get(reqContract, "contract", "");
      FetchButtonHandler(false, contract, startDate, endDate);
    } else if (type === "contract") {
      let statement = _.get(reqStatement, "statement", "");
      if (removed === "all" || !IsArrayNotEmpty(removedList)) {
        FetchButtonHandler(statement, false, startDate, endDate);
      } else {
        FetchButtonHandler(statement, list, startDate, endDate);
      }
    }
  };

  const onDateChange = (param, type) => {
    let statement = _.get(reqStatement, "statement", "");
    let contract = _.get(reqContract, "contract", "");
    try {
      if (type == "start") {
        setStartDate(param);
        if (endDate) {
          try {
            let isb = moment(param).isSameOrBefore(moment(endDate));
            if (!isb) {
              setEndDate(param);
            }
            FetchButtonHandler(statement, contract, param, true);
          } catch (e) {
            HandleErrorTrackAndToast(e, true);
            setEndDate("");
            FetchButtonHandler(statement, contract, param, false);
          }
        } else {
          setEndDate(new Date());
          // setEndDate(param);
          FetchButtonHandler(statement, contract, param, param);
        }
      } else if (type == "end") {
        setEndDate(param);
        FetchButtonHandler(statement, contract, startDate, param);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      DateReset();
      FetchButtonHandler(statement, contract, false, false);
    }
  };

  const fetchTableData = async (viewSGT = false) => {
    try {
      let contract = _.get(reqContract, "contract", null);
      let statement = _.get(reqStatement, "statement", null);
      const contractIds = joinedStringToArr(contract);

      const view = viewSGT ? true : false;
      const access = view ? true : startDate && endDate ? true : false;
      setIsViewButtonClicked(viewSGT);

      const urlPrefix =
        statement === "2" ? `equity-sgets` : `equity-are-statements`;
      if (access && IsArrayNotEmpty(contractIds) && IsTokenChecker(tokenId)) {
        setLoading(true);
        setCircleLoader(true);
        let responseGroup = [];
        var response = [];
        if (statement === "2") {          
          const urlSuffix = statement === "2" && view ? `` : URLSuffixGen(startDate, endDate);
          responseGroup = Promise.all(
            contractIds.map(async (contractNum) => {
              const reqURL = `${urlPrefix}?contractid=${contractNum}${urlSuffix}`;
              var arr = await gbsDataCommonMethod(tokenId, accounts, reqURL)
                .then((res) => {
                  return res.data;
                })
                .catch((e) => {
                  HandleErrorTrackAndToast(e, true, "replace", dispatch);
                });
              return arr;
            })
          )
          .then((result) => {
            return result;
          })
          .catch((e) => {
            HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
          });
          response = await responseGroup
          .then((res) => {
            return res;
          })
          .catch((e) => {
            HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
          })
          .finally(() => {
            setCircleLoader(false);
            setLoading(false);
          });
        }
        else if (statement === "1") {
          let urlArray = [`equity-are-redemptions?contractid=` + contractIds + URLSuffixGen(startDate, endDate), `equity-are-issuances?contractid=` + contractIds + URLSuffixGen(startDate, endDate)];
          responseGroup = await Promise.all(
            urlArray.map(async (url) => {
              return await gbsDataCommonMethod(tokenId, accounts, url)
                .then((res) => {
                  return res.data.map(item => {
                    if (res.request.responseURL.indexOf("equity-are-issuances") > -1) {
                      item.AreType = "ARE Issuance Notice";
                    }
                    else {
                      item.AreType = "ARE Redemption Notice";
                    }
                    return item;
                  })
                })
                .catch((error) => {
                  HandleErrorTrackAndToast(error, true, "replace", dispatch);
                });
            })
          )
          .then((result) => {
            return result;
          })
          .catch((e) => {
            HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
          });
          setCircleLoader(false);
          setLoading(false);
          response = responseGroup
        }
        var final_array = IsArrayNotEmpty(response)
          ? response.reduce((accumulator, current) => {
            return [...accumulator, ...current];
          }, [])
          : [];
        var output_array = final_array.filter((li) => IsObjectChecker(li));
        var resArr = IsArrayNotEmpty(output_array)
          ? output_array.map((item, index) => {
            const Contract = _.get(item, "RelationshipNumber", "-");
            const Id =
              StringToInteger(_.get(item, "RelationshipId", null)) || "-";
            return {
              Index: index,
              ContractName: _.get(item, "Name", null),
              Contract: IsStringChecker(Contract) ? Contract : "-",
              Id: Id,
              DateFrom: getFormattedUTCDate(_.get(item, "DateFrom", null)),
              DateTo: getFormattedUTCDate(_.get(item, "DateTo", null)),
              AreDate: getFormattedUTCDate(_.get(item, "AreDate", null)),
              FileUrl: RegulateFileURL(_.get(item, "FileUrl", null)),
              FileName: statement === "1" ? _.get(item, "AreType", null) : "SGET",
              CropYear: _.get(item, "PoolYear", null)
            };
          })
          : [];
        const result_Arr = IsArrayNotEmpty(resArr)
          ? _.orderBy(resArr, ["Contract", "AreDate"], ["asc", "desc"])
          : [];
        setTableList(result_Arr);
        setNameKeeper(statement);
        MobileViewRenderer("cancel");
        DataGridFix();
        setFreshPage(false);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setTableList([]);
      setNameKeeper(null);
      setLoading(false);
      setCircleLoader(false);
      MobileViewRenderer("cancel");
      DataGridFix();
      setFreshPage(false);
    }
  };

  const onClearSearch = () => {
    setDefaultContract([]);

    setReqContract({});
    setReqStatement({});
    DateReset();

    ClearTableData();

    FetchButtonHandler();

    MultiDropDownFix();
  };

  const FetchButtonHandler = (
    statement = false,
    contract = false,
    startDate = false,
    endDate = false
  ) => {
    // console.log({ statement, contract, startDate, endDate });
    if (statement || contract || startDate || endDate) {
      const access = IsTokenChecker(statement, contract);
      const date = startDate && endDate;
      if (access) {
        if (date) {
          setSubmitBtnDisabled(false);
        } else {
          setSubmitBtnDisabled(true);
        }
        setViewBtnDisabled(false);
      } else {
        setSubmitBtnDisabled(true);
        setViewBtnDisabled(true);
      }
      setClrBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
      setViewBtnDisabled(true);
      setClrBtnDisabled(true);
    }
    ClearTableData();
  };

  const ClearTableData = () => {
    setNameKeeper(null);
    setTableList([]);
    setFreshPage(true);
  };

  // console.log({
  //   reqStatement,
  //   reqContract,
  //   defaultContract,
  //   minDate,
  //   maxDate,
  //   startDate,
  //   endDate,
  //   nameKeeper,
  //   tableList,
  // });

  const statement = _.get(reqStatement, "statement", null);
  const columns = [
    {
      key: "Contract",
      name: "Contract #",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.Contract", "-");
        return temp || "-";
      },
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: "30%",
      formatter(props) {
        let temp = _.get(props, "row.ContractName", "-");
        return temp || "-";
      },
    },
    {
      key: "CropYear",
      name: "Crop Year",
      width: "10%",
      formatter(props) {
        let temp = _.get(props, "row.CropYear", "-");
        return temp || "-";
      },
    },
    {
      key: "AreDate",
      name: "ARE Date",
      width: "20%",
      formatter(props) {
        let temp = _.get(props, "row.AreDate", "-");
        return temp || "-";
      },
    },
    {
      key: "FileName",
      name: "Download PDF",
      width: "25%",
      formatter(props) {
        return (
          <span>
            <a
              href={props.row.FileUrl}
              target={"_blank"}
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Export"
              rel="noreferrer"
            >
              <i className="fa fa-file-o" alt="ExcelIcon"></i>&ensp;
              {props.row.FileName}
            </a>
          </span>
        );
      },
      sortable: false,
      headerCellClass: "fw-normal",
    },
  ];
  const columns2 = [
    {
      key: "Contract",
      name: "Contract #",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.Contract", "-");
        return temp || "-";
      },
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: "30%",
      formatter(props) {
        let temp = _.get(props, "row.ContractName", "-");
        return temp || "-";
      },
    },
    {
      key: "DateFrom",
      name: "Date From",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.DateFrom", "-");
        return temp || "-";
      },
    },
    {
      key: "DateTo",
      name: "Date To",
      width: "15%",
      formatter(props) {
        let temp = _.get(props, "row.DateTo", "-");
        return temp || "-";
      },
    },
    {
      key: "FileName",
      name: "Download PDF",
      width: "25%",
      formatter(props) {
        return (
          <span>
            <a
              href={props.row.FileUrl}
              target={"_blank"}
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Export"
              rel="noreferrer"
            >
              <i className="fa fa-file-o" alt="ExcelIcon"></i>&ensp;
              {props.row.FileName}
            </a>
          </span>
        );
      },
      sortable: false,
      headerCellClass: "fw-normal",
    },
  ];
  const noData = IsStringChecker(nameKeeper) && !IsArrayNotEmpty(tableList);
  const reportMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.report_msg`,
    "Your report is being generated. Please check back within 24 hours."
  )}`;
  const statementMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.statement_msg`,
    "No Statement Found"
  )}`;
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;

  const view_available_statement_no_result = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.view_available_statement_no_result`,
    "No reports were found for this date range.  Please select Request SGET to request a statement."
  )}`;


  // const noDataMsg = nameKeeper === "2" ? (isViewButtonClicked ? view_available_statement_no_result : reportMsg) : statementMsg;
  const finalColumns = nameKeeper === "2" ? columns2 : columns;
  const taxMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.equity_msg`,
    "Equity Statements can be requested but will not be available immediately.  Complete the parameters and click the Request SGET button.  Statements will be available within 24 hours.  Click View Available Statements to retrieve a list of statements previously generated."
  )}`;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="card-body mob-space-7 ver-space-10 align-flex border-1 border-radius-6 white_bg mb-3">
        <span className="d-contents ver-space-10">
          <i className="fa fa-info-circle margin-right-5 d-none d-lg-inline-block"></i>
          <i className="fa fa-info margin-right-5 d-lg-inline-block d-lg-none info_icon_mobile"></i>
          <span className="mob_blue_text">{taxMsg}</span>
        </span>
      </div>
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => MobileViewRenderer("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={() => onClearSearch()}
          >
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${isMobFilter ? "active" : ""
            }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => MobileViewRenderer("filter")}
        >
          <span className="fw-bold px-2">{` Filter `}</span>
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock ${isMobFilter ? "show" : ""
            }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3 statement_type eqityStatementType">
              <div className="payment_number_Label custom_dropdown white_bg">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={statements}
                    prompt="Statement Type"
                    value={reqStatement}
                    onChange={(selectedList, selectedItem) =>
                      onMultiSelectChange(
                        selectedList,
                        selectedItem,
                        "statement"
                      )
                    }
                    onDelete={(removedList, removedItem) =>
                      onMultiSelectRemove(removedList, removedItem, "statement")
                    }
                    label="statement"
                    usestate={setReqStatement}
                    preSelectedOpt={[]}
                    disabled=""
                    singleSelect={true}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contract_number_Label custom_dropdown white_bg">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={contracts}
                    prompt="Select"
                    value={reqContract}
                    onChange={(selectedList, selectedItem) =>
                      onMultiSelectChange(
                        selectedList,
                        selectedItem,
                        "contract"
                      )
                    }
                    onDelete={(removedList, removedItem) =>
                      onMultiSelectRemove(removedList, removedItem, "contract")
                    }
                    label="contract"
                    usestate={setReqContract}
                    disabled=""
                    preSelectedOpt={defaultContract}
                    optionNotFound={{ contract: noContractsFound }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div
                className={`form-floating date_picker_aln cursor_pointer date_from ${dateDisabled && "opacity_0_5"
                  }`}
              >
                <DatePickerComp
                  id="datepickerFromEquity"
                  selected={startDate}
                  onChange={(date) => onDateChange(date, "start")}
                  placeholderText={dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={dateDisabled}
                  minDate={minDate}
                  maxDate={endDate || maxDate}
                />
                <label htmlFor="datepickerFromEquity">
                  Date From{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div
                className={`form-floating date_picker_aln cursor_pointer date_to ${dateDisabled && "opacity_0_5"
                  }`}
              >
                <DatePickerComp
                  id="datepickerToEquity"
                  selected={endDate}
                  onChange={(date) => onDateChange(date, "end")}
                  placeholderText={dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={dateDisabled}
                  minDate={startDate || minDate}
                  // maxDate={maxDate}
                />
                <label htmlFor="datepickerToEquity">
                  Date To{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
          </div>
          <div
            className={`bottom-0  filter_apply_button white_bg ${isMobFilter ? "show d-block " : " d-lg-none "
              }`}
          >
            {/* {statement === "2" && ( */}
            <button
              className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none mb-2"
              onClick={() => fetchTableData(true)}
              disabled={statement === "2" ? viewBtnDisabled : submitBtnDisabled}
            >
              {`View Available Statements `}
              {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
            </button>
            {/* )} */}
            {statement === "2" &&
              <button
                className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
                onClick={() => fetchTableData()}
                disabled={submitBtnDisabled}
              >
                {statement === "2" ? `Submit SGET Request` : ` `}
                {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
              </button>
            }
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              {statement === "2" &&
                <button
                  className="btn btn-primary text-16 float-end d-none d-lg-block"
                  onClick={() => fetchTableData()}
                  disabled={submitBtnDisabled}
                >
                  {/* {statement === "2" ? `Submit SGET Request ` : `Submit ARES Request `} */}
                  {statement === "2" ? `Submit SGET Request ` : `Submit SGET Request `}
                  {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
                </button>
              }

              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block clear-btn-grey"
                onClick={() => onClearSearch()}
                disabled={clrBtnDisabled}
              >
                Clear
              </button>
              {/* {statement === "2" && ( */}
              <button
                className="btn btn-primary text-16 float-end me-2 d-none d-lg-block"
                onClick={() => fetchTableData(true)}
                disabled={statement === "2" ? viewBtnDisabled : submitBtnDisabled}
              >
                View Available Statements
                {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
              </button>
              {/* )} */}
            </div>
          </div>
        </div>
      </nav>
      {IsStringChecker(nameKeeper) &&  !displayAccordian && (
        <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
          <h3 className="pt-3 pb-1 pb-lg-4">
            <span className="modify_text text-capitalize">
              {getStatementName(nameKeeper)}
            </span>
          </h3>
          {IsArrayNotEmpty(tableList) && (
            <>
              {!gridstatus && (
                <DataGridPagination
                  uniqueIdentifier="Index"
                  gridData={tableList}
                  columns={finalColumns}
                  isPagination={true}
                  itemsPerPage={10}
                  columnResize={false}
                  classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
                  dynamicHeight={45}
                />
              )}
            </>
          )}
          {noData && (
            <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
              <i className="fa fa-info-circle pe-2"></i>
              {nameKeeper === "2" ? (isViewButtonClicked ? view_available_statement_no_result : reportMsg) : statementMsg}
            </div>
          )}
        </div>
      )}
      {IsArrayNotEmpty(tableList) && <div
        className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
      >
        <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
          <Accordion
            defaultActiveKey="0"
            className="table mobile-accordion accordion_space"
          >
            {IsArrayNotEmpty(tableList) ? (
              tableList.map((data, index) => {
                const {
                  FileUrl = null,
                  FileName = null,
                  Contract = "-",
                  ContractName = "-",
                  DateFrom = null,
                  DateTo = null,
                  AreDate = null,
                  CropYear = null,
                } = data;

                const DateLine =
                  nameKeeper === "1"
                    ? `ARE Date: ${AreDate || "-"}`
                    : nameKeeper === "2"
                      ? `Date Range: ${DateFrom} - ${DateTo}`
                      : "";
                const DateTD = ({ nameKeeper = null }) => {
                  if (nameKeeper === "1") {
                    return (
                      <div className="td" data-header="ARE Date:">
                        <span>{AreDate || "-"}</span>
                      </div>
                    );
                  } else if (nameKeeper === "2") {
                    return (
                      <>
                        <div className="td" data-header="Date From:">
                          <span>{DateFrom || "-"}</span>
                        </div>
                        <div className="td" data-header="Date To:">
                          <span>{DateTo || "-"}</span>
                        </div>
                      </>
                    );
                  } else {
                    return <></>;
                  }
                };
                return (
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header>
                      {`Contract #: ${Contract}`}
                      <br />
                      {DateLine}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tbody">
                        <div className="tr">
                          <div className="td"></div>
                          {IsStringChecker(Contract) && (
                            <div className="td" data-header="Contract #:">
                              <span>{Contract}</span>
                            </div>
                          )}
                          {IsStringChecker(ContractName) && (
                            <div className="td" data-header="Contract Name:">
                              <span>{ContractName}</span>
                            </div>
                          )}
                          {IsStringChecker(CropYear) && (
                            <div className="td" data-header="Crop Year:">
                              <span>{CropYear}</span>
                            </div>
                          )}
                          <DateTD nameKeeper={nameKeeper} />
                          {IsTokenChecker(FileUrl, FileName) && (
                            <div className="td" data-header="Download PDF:">
                              <span>
                                <a
                                  href={FileUrl}
                                  target={"_blank"}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Export"
                                  rel="noreferrer"
                                >
                                  <i
                                    className="fa fa-file-o"
                                    alt="ExcelIcon"
                                  ></i>
                                  &ensp;
                                  {FileName}
                                </a>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            ) : freshPage ? (
              <></>
            ) : (
              <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                <i className="fa fa-info-circle pe-2"></i>
                {nameKeeper === "2" ? (isViewButtonClicked ? view_available_statement_no_result : reportMsg) : statementMsg}
              </div>
            )}
          </Accordion>
        </div>
      </div>
    }
    </>
  );
};

export default Equity;
