import React, { Fragment, useEffect, useState } from "react";
import { BreadcrumbWithParams } from "../../global/breadCrumb";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import {
  IsNumberChecker,
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsObjectChecker,
  IsTokenChecker,
  IsStringChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
} from "../../../utils/helper";
import { Link, useParams } from "react-router-dom";
import CurrentLocation from "../../global/currentLocation";
import ContentModifier from "../../global/contentModifier";
import EventTime from "../../community/events/eventTime";
import OSAcademyCourseModal from "./osAcademyCourseModal";
import { Button } from "react-bootstrap";
import moment from "moment";
const OSAcademyCoursesDetail = (props) => {
  const [loading, setLoading] = useState(false);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const user_id = _.get(accounts, `0.localAccountId`, null);
  const personId = _.get(accounts, `0.idTokenClaims.gbsPersonId`, null);

  const params = useParams();
  const { courseId = null } = params;
  const [courses, setCourses] = useState({});

  const initialPostData = {
    firstname: "",
    lastname: "",
    email: "",
    academy_schedule_id: "",
    schedule_date_from: "",
    schedule_date_to: "",
    course: "",
  };
  const [postData, setPostData] = useState(initialPostData);
  const [enrolled, setEnrolled] = useState([]);
  const [signedUp, setSignedUp] = useState(false);
  const [approved, setApproved] = useState(false);
  const [error, setError] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [ModalTitleContent, setModalTitleContent] = useState(false);
  const [ModalBodyContent, setModalBodyContent] = useState(false);
  const [ModalFootContent, setModalFootContent] = useState(false);

  const IsBooleanChecker = (bool = null) => {
    try {
      return typeof bool === "boolean";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const SafeTypeChecker = (value = null) => {
    try {
      if (IsBooleanChecker(value)) {
        return true;
      } else if (IsStringChecker(value)) {
        return true;
      } else if (IsNumberChecker(value)) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const SafeFieldChecker = (value = null) => {
    try {
      if (IsBooleanChecker(value)) {
        return value;
      } else if (IsStringChecker(value)) {
        return value;
      } else if (IsNumberChecker(value)) {
        return value;
      } else {
        return ``;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "os_academy"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const ValueProvider = (obj = null, prefix = "title_") => {
    return IsObjectChecker(obj)
      ? _.get(obj, `${prefix}${resolvedLanguage}`, null)
      : null;
  };

  /* Enrollment Form */
  const FormTitle = () => {
    return (
      <>
        {LocalTranslator("enroll_modal_title", "OS Academy Course Enrollment")}
      </>
    );
  };
  const FormBody = ({ postData = {} }) => {
    try {
      const {
        firstname = null,
        lastname = null,
        email = null,
        schedule_date_from = null,
        schedule_date_to = null,
        course = null,
      } = postData;

      return (
        <>
          <div className="row mb-2">
            <div className="col-4 fw-bold">
              {LocalTranslator("enroll_modal_form.first_name", "First Name")}
              {": "}
            </div>
            <div className="col-8">{SafeFieldChecker(firstname)}</div>
          </div>
          <div className="row mb-2">
            <div className="col-4 fw-bold">
              {LocalTranslator("enroll_modal_form.last_name", "Last Name")}
              {": "}
            </div>
            <div className="col-8">{SafeFieldChecker(lastname)}</div>
          </div>
          <div className="row mb-2">
            <div className="col-4 fw-bold">
              {LocalTranslator("enroll_modal_form.email", "Email")}
              {": "}
            </div>
            <div className="col-8">{SafeFieldChecker(email)}</div>
          </div>
          <div className="row mb-2">
            <div className="col-4 fw-bold">
              {LocalTranslator("enroll_modal_form.course", "Course")}
              {": "}
            </div>
            <div className="col-8">{SafeFieldChecker(course)}</div>
          </div>
          <div className="row mb-2">
            <div className="col-4 fw-bold">
              {LocalTranslator("enroll_modal_form.schedule", "Schedule")}
              {": "}
            </div>
            <div className="col-8">
              <div className="upcoming_location">
                <i className="fa fa-clock-o marker_icon"></i>
                <p className="mt-0">
                  <EventTime
                    event_date_from={schedule_date_from}
                    event_date_to={schedule_date_to}
                    locale={true}
                    timezone={true}
                  />
                </p>
              </div>
            </div>
          </div>
          <p className="os-academy-enroll-message">
            {LocalTranslator(
              "enroll_modal_form.message.prefix",
              `You are signing up for this course.  Click`
            )}
            <span className="fw-bold">
              {` `}
              {LocalTranslator("enroll_modal_form.message.midfix", "Submit")}
              {` `}
            </span>
            {LocalTranslator("enroll_modal_form.message.suffix", `to confirm.`)}
          </p>
        </>
      );
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return <></>;
    }
  };
  const FormFoot = ({ postData = {} }) => {
    return (
      <>
        <Button variant="light" onClick={onModalHide}>
          {LocalTranslator("cancel_text", "Cancel", "button_text")}
        </Button>
        <Button onClick={(e) => onSubmit(e, postData)}>
          {LocalTranslator("submit", "Submit", "home_page")}
        </Button>
      </>
    );
  };

  /* Enrollment Results */
  const ResultSuccessTitle = () => {
    return <>{LocalTranslator("enroll_modal_success_title", "Success")}</>;
  };
  const ResultFailTitle = () => {
    return (
      <>
        {LocalTranslator(
          "enroll_modal_error_title",
          "Sorry! Unable to submit the form."
        )}
      </>
    );
  };
  const ResultSuccessBody = () => {
    return (
      <p>
        {LocalTranslator(
          "enroll_modal_success_body",
          `Your form has been submitted successfully. Thank you!`
        )}
      </p>
    );
  };
  const ResultFailBody = () => {
    return (
      <p>
        {LocalTranslator(
          "enroll_modal_error_body",
          `There seems to be some problem with form submission now. Please, try again later.`
        )}
      </p>
    );
  };
  const ResultSuccessFoot = () => {
    return (
      <Link
        className="btn btn-primary"
        as="button"
        onClick={onModalHide}
        to={`/os-academy`}
      >
        {LocalTranslator("closeBtn", "Close")}
      </Link>
    );
  };
  const ResultFoot = () => {
    return (
      <Button onClick={onModalHide}>
        {LocalTranslator("closeBtn", "Close")}
      </Button>
    );
  };

  /* Signup Modal */
  const SignUpFormBody = () => {
    return (
      <p>
        <span>
          {LocalTranslator(
            "enroll_modal_signup.prefix",
            "You must be enrolled as an academy member in order to signup for courses. Please,"
          )}
          {` `}
        </span>
        <Link className="fw-bold" to={`/os-academy/sign-up`}>
          {LocalTranslator("enroll_modal_signup.midfix", "click here")}
        </Link>
        <span>
          {` `}
          {LocalTranslator("enroll_modal_signup.suffix", "to signup.")}
        </span>
      </p>
    );
  };

  /* Approved Modal */
  const ApprovedBody = () => {
    return (
      <p>
        {LocalTranslator(
          "enroll_modal_approval",
          "Your application is pending with approval. Please, come back again."
        )}
      </p>
    );
  };

  /* Error Modal */
  const ErrorBody = () => {
    return (
      <p>
        {LocalTranslator(
          "enroll_modal_error",
          "Some error occurred. Please, come back again."
        )}
      </p>
    );
  };

  /* Enrolled Modal */
  const EnrolledFormBody = () => {
    return (
      <p>
        {LocalTranslator(
          "enroll_modal_enroll",
          "You are already signed up on schedule page."
        )}
      </p>
    );
  };

  const getCourses = async () => {
    if (IsStringChecker(courseId)) {
      setLoading(true);
      const url = `academy_courses?select=*,academy_course_types(id,title_en,title_fr,title_es,slug,order_by),academy_schedules(id,schedule_date_from,schedule_date_to)&id=eq.${courseId}`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          let tempForms = safeArrayCheck(_.get(resp, "data.data", []));
          let tempObj = IsArrayNotEmpty(tempForms) ? tempForms[0] : {};
          setCourses(IsObjectChecker(tempObj) ? tempObj : {});
        })
        .catch((error) => {
          setCourses({});
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const ScheduleFilter = (arr = [], limit = 10) => {
    try {
      if (IsArrayNotEmpty(arr)) {
        const currentDate = moment();
        const filtered = arr.filter((li) => {
          const { schedule_date_to } = li;
          return moment(schedule_date_to).isSameOrAfter(currentDate);
        });
        const sorted =
          filtered.length > 1
            ? filtered.sort(
                (a, b) =>
                  new Date(a.schedule_date_from) -
                  new Date(b.schedule_date_from)
              )
            : filtered;
        if (IsNumberChecker(limit) && limit > 0) {
          return sorted.slice(0, limit);
        } else {
          return sorted;
        }
      } else {
        return [];
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  const getAcademySignUps = async () => {
    const url = `academy_course_signups?select=*&user_id=eq.${user_id}`;
    return await cmsDataCommonMethod(idToken, accounts, url);
  };

  const getAcademyApplications = async () => {
    const url = `academy_applications?select=*&user_id=eq.${user_id}`;
    return await cmsDataCommonMethod(idToken, accounts, url);
  };

  const FetchPreData = async () => {
    try {
      if (IsNumberChecker(personId)) {
        await gbsDataCommonMethod(idToken, accounts, `persons/${personId}`)
          .then((resp) => {
            const data = _.get(resp, `data.0`, {});
            if (IsObjectChecker(data)) {
              const { Email = null, FirstName = null, LastName = null } = data;

              let resultData = {
                ...initialPostData,
                firstname: SafeFieldChecker(FirstName),
                lastname: SafeFieldChecker(LastName),
                email: SafeFieldChecker(Email),
              };
              setPostData(resultData);
              setError(false);
            } else {
              ErrorData();
            }
          })
          .catch((error) => {
            ErrorData();
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          });
      } else {
        HandleErrorTrackAndToast(`Problem with Person ID: ${personId}`, true);
        ErrorData();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      ErrorData();
    }
  };

  const DecideModalContent = () => {
    try {
      if (IsStringChecker(user_id)) {
        getAcademyApplications()
          .then((resp) => {
            let applicationData = safeArrayCheck(_.get(resp, "data.data", []));
            if (IsArrayNotEmpty(applicationData)) {
              let is_approved = applicationData.every((li) => li.is_approved);
              setSignedUp(true);
              if (is_approved) {
                setApproved(true);
                getAcademySignUps()
                  .then((resp) => {
                    let signUpData = safeArrayCheck(
                      _.get(resp, "data.data", [])
                    );
                    if (IsArrayNotEmpty(signUpData)) {
                      setEnrolled(signUpData);
                    } else {
                      setEnrolled([]);
                    }
                    FetchPreData();
                  })
                  .catch((error) => {
                    ErrorData();
                    HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
                  });
              } else {
                setApproved(false);
                ResetData();
              }
            } else {
              setSignedUp(false);
              ResetData();
            }
          })
          .catch((error) => {
            ErrorData();
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          });
      } else {
        HandleErrorTrackAndToast(`Problem with user id: ${user_id}`, true);
        ErrorData();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      ErrorData();
    }
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getCourses();
      DecideModalContent();
    }
  }, [idToken, personId]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const onModalHide = () => {
    setShowModal(false);
  };

  const ResetData = () => {
    setPostData(initialPostData);
    setError(false);
  };

  const ErrorData = () => {
    setPostData(initialPostData);
    setError(true);
  };

  const onModalOpen = (
    academy_schedule_id = "",
    course = "",
    schedule_date_from = "",
    schedule_date_to = ""
  ) => {
    const tempData = {
      ...postData,
      academy_schedule_id,
      course,
      schedule_date_from,
      schedule_date_to,
    };
    ModalSwapLogic(tempData);
  };

  const ModalSwapLogic = (tempData = {}) => {
    if (!error) {
      if (signedUp) {
        if (approved) {
          if (IsArrayNotEmpty(enrolled)) {
            let temp_schedule_id = _.get(tempData, "academy_schedule_id", null);
            let temp_schedule = enrolled.find(
              (li) => li.academy_schedule_id === temp_schedule_id
            );
            if (temp_schedule) {
              setModalTitleContent(FormTitle);
              setModalBodyContent(EnrolledFormBody);
              setModalFootContent(ResultFoot);
            } else {
              setModalTitleContent(FormTitle);
              setModalBodyContent(FormBody({ postData: tempData }));
              setModalFootContent(FormFoot({ postData: tempData }));
            }
          } else {
            setModalTitleContent(FormTitle);
            setModalBodyContent(FormBody({ postData: tempData }));
            setModalFootContent(FormFoot({ postData: tempData }));
          }
        } else {
          setModalTitleContent(FormTitle);
          setModalBodyContent(ApprovedBody);
          setModalFootContent(ResultFoot);
        }
      } else {
        setModalTitleContent(FormTitle);
        setModalBodyContent(SignUpFormBody);
        setModalFootContent(ResultFoot);
      }
      setShowModal(true);
    } else {
      setModalTitleContent(FormTitle);
      setModalBodyContent(ErrorBody);
      setModalFootContent(ResultFoot);
    }
  };

  const ValidatePostData = (requestData = {}) => {
    try {
      if (IsObjectChecker(requestData)) {
        return Object.values(requestData).every((div) => SafeTypeChecker(div));
      } else {
        return false;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const onSubmit = async (e, postData = {}) => {
    try {
      e.preventDefault();
      if (IsTokenChecker(user_id) && IsObjectChecker(postData)) {
        let requestData = { ...postData };
        delete requestData.course;
        delete requestData.schedule_date_from;
        delete requestData.schedule_date_to;
        requestData.user_id = user_id;

        // console.log("Request Data", requestData);
        let valid = ValidatePostData(requestData);
        // console.log({ valid });
        if (valid) {
          setLoading(true);
          const url = "academy-course-signups";
          await cmsDataCommonMethod(idToken, accounts, url, "POST", requestData)
            .then(() => {
              setModalTitleContent(ResultSuccessTitle);
              setModalBodyContent(ResultSuccessBody);
              setModalFootContent(ResultSuccessFoot);
            })
            .catch((err) => {
              setModalTitleContent(ResultFailTitle);
              setModalBodyContent(ResultFailBody);
              setModalFootContent(ResultFoot);
              HandleErrorTrackAndToast(err, true, "dispatch", dispatch);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          let msg = `Error in Post Data: ${requestData}`;
          HandleErrorTrackAndToast(msg, true);
          setModalTitleContent(ResultFailTitle);
          setModalBodyContent(ResultFailBody);
          setModalFootContent(ResultFoot);
        }
      } else {
        let msg = IsObjectChecker(postData)
          ? `Error in User ID: ${user_id}`
          : `Error in Post Data: ${postData}`;
        HandleErrorTrackAndToast(msg, true);
        onModalHide();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      onModalHide();
    }
  };

  const { academy_schedules = [] } = courses;
  const title = ValueProvider(courses);
  const description = ValueProvider(courses, `description_`);
  const schedule_arr = ScheduleFilter(academy_schedules);
  // console.log({ error, postData });
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadcrumbWithParams
              params={IsStringChecker(title) ? title : null}
              {...props}
            />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="col-12">
            {IsStringChecker(description) && (
              <div className="white_bg left_side_block pb-0">
                <h4 className="card-header white_color ver-space-15 left-border-radius right-border-radius">
                  {`${_.get(
                    moreLangs,
                    `${resolvedLanguage}.os_academy.course_details`,
                    `About this course details`
                  )}`}
                </h4>
                <div className="card-body mob-pleft-pright-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-16 px-2 px-lg-0">
                        <ContentModifier str={description} />
                      </div>
                    </div>
                    {IsArrayNotEmpty(schedule_arr) && (
                      <Fragment>
                        <div className="horizontal-line px-3"></div>
                        <h4 className="fw-bold pt-4 pt-lg-2 pb-3 mt-3 px-2">
                          {`${_.get(
                            moreLangs,
                            `${resolvedLanguage}.os_academy.next_course_schedule`,
                            `Next Course Schedule`
                          )}`}
                        </h4>
                        <div className="pt-0 p-2">
                          {schedule_arr.map((list, index) => {
                            const { id, schedule_date_from, schedule_date_to } =
                              list;
                            return (
                              <div
                                className={`light_bg_grey_color p-3 border-radius-6 mb-2`}
                                key={index}
                              >
                                <div className={`row`}>
                                  <div className="col-12 ps-3">
                                    <h4>{title}</h4>
                                    <div className="upcoming_location">
                                      <i className="fa fa-clock-o marker_icon"></i>
                                      <p>
                                        <EventTime
                                          event_date_from={schedule_date_from}
                                          event_date_to={schedule_date_to}
                                          locale={true}
                                          timezone={true}
                                        />
                                      </p>
                                    </div>
                                    <p
                                      className="my-2 red_color cursor_pointer"
                                      onClick={() =>
                                        onModalOpen(
                                          id,
                                          title,
                                          schedule_date_from,
                                          schedule_date_to
                                        )
                                      }
                                    >
                                      {LocalTranslator(
                                        "sign_up_reserve_spot",
                                        `Sign-up to reserve your spot!`
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <OSAcademyCourseModal
            showModal={showModal}
            onModalHide={onModalHide}
            TitleContent={ModalTitleContent}
            BodyContent={ModalBodyContent}
            FootContent={ModalFootContent}
          />
        </div>
      </div>
    </>
  );
};

export default OSAcademyCoursesDetail;
