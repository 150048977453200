/**
 * @component_Name : DDDailysumary -Delivery Data Dailysumary
 * @description : how Delivery Data Daily Summary as grid and accodion
 * @company : Photon
 * @author : Ashish Ranjan/22-07-2022
 **/

import React, { useEffect, useState } from "react";
import "../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
// import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  getCurrentYear,
  toDecimal,
  setOptions,
  setObjToString,
  IsTokenChecker,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  IsStringChecker,
  safeArrayCheck,
  compareString,
} from "../../../utils/helper";
import { cascadeFilterFunc } from "../../../utils/cascadeFilter";
import DataGridPagination from "../../global/dataGridPagination";
import { ConstantsVar } from "../../../utils/constants";
// import ExportFile from "../../exportFile/exportFile";
import WhiteCircleLoader from "../../whiteCircleLoader";
import DDDailysumaryMobile from "./dDDailysumaryMobile";
// import PdfFile from "../../exportFile/pdfFile";
import Loading from "../../../components/global/loading";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import _ from "lodash";
import { useGbsDataCache } from "../../../services/gbsDataCache";
import { DailySummaryPDFFile } from "../../exportFile/pdfFile";
import { DailySummaryExportFile } from "../../exportFile/exportFile";

const DDDailysumary = (props) => {
  const {
    DropDownCBRoles = [],
    noContractsFound = "",
    listCropYear: propListCropYear = [],
    ContractIds: propContractIds = "",
    currentCropYear: propCurrentCropYear = 0,
    ContractOption: propContractOption = [],
    filterItems: propFilterItems = [],
  } = props;

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);

  const ongoingYear = getCurrentYear();
  const defaultRadioFilter = {
    ["limithistory"]: "AllBeds",
    ["isdaily"]: "true",
  };
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [reloadCropYear, setReloadCropYear] = useState(true);
  const [currentCropYear, setCurrentCropYear] = useState(0);
  const [cropyearPreselect, setCropYearPreselect] = useState([]);
  const [listCropYear, setListCropYear] = useState([]);
  const [multiCrop, setMultiCrop] = useState({});

  const [ContractIds, setContractIds] = useState([]);
  const [reloadContract, setReloadContract] = useState(true);
  const [contractPreselect, setContractPreselect] = useState(["all"]);
  const [ContractOption, setContractOption] = useState([]);
  const [multiContId, setMultiContId] = useState({});

  const [radioFilter, setRadioFilter] = useState(defaultRadioFilter);
  const [bogNameOption, setBogNameOption] = useState([]);
  const [harvestbedOption, setHarvestbedOption] = useState([]);
  const [verityOptionDD, setVerityOptionDDS] = useState([]);
  const [multiValue, setMultiValue] = useState({});
  const [otherPreselect, setOtherPreselect] = useState([]);
  const [reloadOtherFilter, setReloadOtherFilter] = useState(true);

  const [isMobFilter, setisMobFilter] = useState(false);
  const [isListingHide, setIsListingHide] = useState(true);
  const [isReset, setIsReset] = useState(true);
  const [hideExport, setHideExport] = useState(true);
  const [disabled, setDisabled] = useState("disabled");
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);

  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [filterItems, setFilterItems] = useState([]);
  const [gridItems, setGridItems] = useState([]);
  const [resultItems, setResultItems] = useState([]);

  const [showReportOption, setShowReportOption] = useState("");
  const [exportFileHeader, setExportFileHeader] = useState([]);
  const [exportFileContent, setExportFileContent] = useState([]);

  const [initial, setInitial] = useState(true);

  const columns = [
    {
      key: "DeliveryDay",
      name: `MM/DD`,
      width: "16%",
    },
    {
      key: "CropYear1",
      name: `${cropyearPreselect[0]}`,
      width: "17%",
      headerCellClass: "text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return <>{toDecimal(props.row.CropYear1.toFixed(4), 4) || "0"}</>;
      },
    },
    {
      key: "CropYear2",
      name: `${cropyearPreselect[0] - 1}`,
      width: "17%",
      headerCellClass: "text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return <>{toDecimal(props.row.CropYear2.toFixed(4), 4) || "0"}</>;
      },
    },
    {
      key: "CropYear3",
      name: `${cropyearPreselect[0] - 2}`,
      width: "17%",
      headerCellClass: "text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return <>{toDecimal(props.row.CropYear3.toFixed(4), 4) || "0"}</>;
      },
    },
    {
      key: "CropYear4",
      name: `${cropyearPreselect[0] - 3}`,
      width: "17%",
      headerCellClass: "text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return <>{toDecimal(props.row.CropYear4.toFixed(4), 4) || "0"}</>;
      },
    },
    {
      key: "CropYear5",
      name: `${cropyearPreselect[0] - 4}`,
      width: "16%",
      headerCellClass: "text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return <>{toDecimal(props.row.CropYear5.toFixed(4), 4) || "0"}</>;
      },
    },
  ];

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  const [responseType, setResponseType] = useState(0);
  useEffect(() => {
    if (responseType === "withoutSubmit") {
      if (data?.data) {
        fetchAllBedsData(data);
      }
      if (status === "error") {
        setLoader(false);
        setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
        // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      }
    } else {
      if (data?.data) {
        fetchAllDailySummary(data);
      }
      if (status === "error") {
        setLoader(false);
        setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
        // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      }
    }
  }, [data, changeEvent, status]);
  //Cache Implementation End

  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    setDisplayAccordian(
      localStorage.getItem("safariLowerVersion") === "true" || false
    );
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  /*validation on mention filter options*/
  useEffect(() => {
    const ContractAccess = multiContId && multiContId?.contract_id;
    const CropAccess = multiCrop && multiCrop?.cropyear;
    const defaultAccess =
      radioFilter &&
      radioFilter?.limithistory &&
      radioFilter?.isdaily != "undefined";
    if (defaultAccess) {
      if (ContractAccess && CropAccess) {
        if (multiValue && multiValue?.reporttype) {
          setSearchBtnDisabled(false);
        } else {
          setSearchBtnDisabled(true);
        }
        setDisabled(false);
        setIsReset(false);
      } else if (ContractAccess || CropAccess) {
        setDisabled(true);
        setIsReset(false);
        setSearchBtnDisabled(true);
      } else {
        setDisabled(true);
        setIsReset(true);
        setSearchBtnDisabled(true);
      }
    } else {
      setSearchBtnDisabled(true);
    }
  }, [multiValue, multiContId, multiCrop, radioFilter]); /*End*/

  useEffect(() => {
    setLoader(true);
    SetOtherFilterOptions([]);
    if (IsTokenChecker(tokenId) && IsArrayNotEmpty(propListCropYear)) {
      setMultiValue({});
      setListCropYear([...propListCropYear]);
      setCurrentCropYear(propCurrentCropYear);
      setCropYearPreselect([propCurrentCropYear]);
      setMultiCrop({ cropyear: `${propCurrentCropYear}` });
      setContractIds(propContractIds);
      setContractOption([...propContractOption]);
      setFilterItems([...propFilterItems]);
      setLoader(false);
    }
  }, [
    propFilterItems,
    propContractOption,
    propCurrentCropYear,
    propListCropYear,
    tokenId,
  ]);

  const fetchBedsData = async (url) => {
    if (IsTokenChecker(tokenId)) {
      setSearchURL(url);
      url === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const fetchAllBedsData = (resp) => {
    let resultData = safeArrayCheck(resp?.data);
    if (IsArrayNotEmpty(resultData)) {
      let filteredData = FilterContractByRole(
        resultData,
        accounts,
        DropDownCBRoles
      );
      if (IsArrayNotEmpty(filteredData)) {
        filteredData = _.orderBy(
          filteredData,
          ["ContractStatus", "RelationshipNumber"],
          ["asc", "asc"]
        );
        const contractList = setOptions(
          filteredData,
          "RelationshipId",
          "RelationshipNumber",
          "Name"
        );
        setContractOption(contractList);
        ListDataViewReset();
        setFilterItems(filteredData);
        handlerContract(propContractIds, "normal", [...filteredData]);
      } else {
        setContractOption([]);
        SetOtherFilterOptions();
        ListDataViewReset(true);
      }
    } else {
      setContractOption([]);
      SetOtherFilterOptions();
      ListDataViewReset(true);
    }
    setLoader(false);
  };

  const fetchDailySummary = async (apiUrl) => {
    if (tokenId != "") {
      setLoader(true);
      setLoading(true);

      setSearchURL(apiUrl);
      setResponseType("submit");
      apiUrl === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const fetchAllDailySummary = (resp) => {
    let response = safeArrayCheck(resp?.data);
    let sortByDay = _.orderBy(response, ["DeliveryDay"], ["asc"]);
    const result = Object.values(sortByDay).map((item, index) => ({
      Id: index,
      "Day Of Year": item?.DeliveryDay,
      "CropYear-4": toDecimal(item?.CropYear5.toFixed(4), 4),
      "CropYear-3": toDecimal(item?.CropYear4.toFixed(4), 4),
      "CropYear-2": toDecimal(item?.CropYear3.toFixed(4), 4),
      "CropYear-1": toDecimal(item?.CropYear2.toFixed(4), 4),
      CropYear: toDecimal(item?.CropYear1.toFixed(4), 4),
    }));

    const CYear = _.get(cropyearPreselect, "0", 0);
    const headerData = [
      "MM/DD",
      `${CYear - 4}`,
      `${CYear - 3}`,
      `${CYear - 2}`,
      `${CYear - 1}`,
      `${CYear}`,
    ];
    const contentData = IsArrayNotEmpty(sortByDay)
      ? sortByDay.map((item = {}) => {
          const {
            DeliveryDay = null,
            CropYear5 = null,
            CropYear4 = null,
            CropYear3 = null,
            CropYear2 = null,
            CropYear1 = null,
          } = item;
          return [
            DeliveryDay,
            CropYear5,
            CropYear4,
            CropYear3,
            CropYear2,
            CropYear1,
          ];
        })
      : [];

    // console.log({ headerData, contentData });
    setExportFileHeader(headerData);
    setExportFileContent(contentData);

    setResultItems(result);
    setGridItems(sortByDay);
    response.length > 0 ? setIsListingHide(false) : setIsListingHide(true);
    response.length > 0 ? setHideExport(false) : setHideExport(true);
    response.length > 0
      ? setNoRecordErrorMsg("")
      : setNoRecordErrorMsg(ConstantsVar.notFoundMsg);

    setLoader(false);
    setLoading(false); /*remove loader from search button*/
    setSearchBtnDisabled(false); /*disabled search button*/
    setisMobFilter(false);
  };
  /*ReportType*/
  const multiReportType = Object.keys(ConstantsVar.report_options).map((x) => ({
    id: x,
    name: ConstantsVar.report_options[x],
  }));

  function ListDataViewReset(full = false) {
    setIsListingHide(true);
    setHideExport(true);
    setGridItems([]);
    setNoRecordErrorMsg("");
    if (full) {
      setFilterItems([]);
    }
  }

  function SetOtherFilterOptions(filter = []) {
    if (IsArrayNotEmpty(filter)) {
      setBogNameOption(setOptions(filter, "BogName", "BogName"));
      setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
      setVerityOptionDDS(
        setOptions(filter, "VarietyId", "LegacyVarietyCode", "", "Variety")
      );
      if (initial) {
        setInitial(false);
        OtherFilterClick();
      }
    } else {
      setBogNameOption([]);
      setHarvestbedOption([]);
      setVerityOptionDDS([]);
    }
  }

  function GetQueryString(otherFilter, ContractDD, CropYearDD, Radio) {
    const arrayQueryString = [];
    if (otherFilter) {
      Object.keys(otherFilter).map((i, index) => {
        otherFilter[i] != ""
          ? (arrayQueryString[index] = `${i}=${_.orderBy(
              otherFilter[i]?.split(","),
              [],
              ["asc"]
            ).toString()}`)
          : "";
      });
    }

    const radioQueryString = [];
    if (Radio) {
      Object.keys(Radio).map((i, index) => {
        Radio[i] != ""
          ? (radioQueryString[index] = `${i}=${_.orderBy(
              Radio[i]?.split(","),
              [],
              ["asc"]
            ).toString()}`)
          : "";
      });
    }

    let QueryString = ``;
    if (IsArrayNotEmpty(arrayQueryString)) {
      QueryString = `&${arrayQueryString.join("&")}`;
    }

    let RadioQueryString = ``;
    if (IsArrayNotEmpty(radioQueryString)) {
      RadioQueryString = `&${radioQueryString.join("&")}`;
    }

    let qCropyear = ``;
    if (CropYearDD?.cropyear) {
      qCropyear = CropYearDD?.cropyear;
    } else {
      qCropyear = currentCropYear > 0 ? `${currentCropYear}` : `${ongoingYear}`;
    }
    let cropYearString = ``;
    if (IsStringChecker(qCropyear)) {
      const tarr = _.orderBy(qCropyear?.split(",") || [], [], ["asc"]);
      if (IsArrayNotEmpty(tarr)) {
        cropYearString = `cropyear=${tarr}`;
      }
    }

    let contractString = ``;
    if (ContractDD?.contract_id) {
      const tarr = _.orderBy(
        ContractDD?.contract_id?.split(",") || [],
        [],
        ["asc"]
      );
      if (IsArrayNotEmpty(tarr)) {
        contractString = `&contractid=${tarr}`;
      }
    }

    if (
      IsStringChecker(cropYearString) &&
      IsStringChecker(contractString) &&
      IsStringChecker(RadioQueryString)
    ) {
      return `deliveries/by-daily-summary?${cropYearString}${contractString}${RadioQueryString}${
        IsStringChecker(QueryString) ? QueryString : ""
      }`;
    } else {
      return ``;
    }
  }

  function FilteredItemsByContracts(filteredData, val, convert = true) {
    const tempVal = convert ? setObjToString(val) : val;
    if (IsArrayNotEmpty(filteredData) && IsStringChecker(tempVal)) {
      return filteredData
        .filter((item) => {
          return (
            tempVal
              ?.toLowerCase()
              ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
          );
        })
        .map((i) => {
          i.HarvestBed = i.HarvestBed?.trim();
          return i;
        });
    } else {
      return [];
    }
  }

  function EmptyWithRefreshDropDowns(
    contractR = true,
    cropyearR = true,
    otherR = true
  ) {
    if (otherR && contractR && cropyearR) {
      setReloadOtherFilter(false);
      setReloadContract(false);
      setReloadCropYear(false);
      setTimeout(() => {
        setMultiCrop({});
        setCropYearPreselect([]);
        setMultiContId({});
        setContractPreselect([]);
        setMultiValue({});
        setOtherPreselect([]);

        setReloadOtherFilter(true);
        setReloadContract(true);
        setReloadCropYear(true);
      }, 0);
    } else if (otherR && contractR) {
      setReloadOtherFilter(false);
      setReloadContract(false);
      setTimeout(() => {
        setMultiContId({});
        setContractPreselect([]);
        setMultiValue({});
        setOtherPreselect([]);

        setReloadOtherFilter(true);
        setReloadContract(true);
      }, 0);
    } else if (otherR && cropyearR) {
      setReloadOtherFilter(false);
      setReloadCropYear(false);
      setTimeout(() => {
        setMultiCrop({});
        setCropYearPreselect([]);
        setMultiValue({});
        setOtherPreselect([]);

        setReloadOtherFilter(true);
        setReloadCropYear(true);
      }, 0);
    } else if (contractR && cropyearR) {
      setReloadContract(false);
      setReloadCropYear(false);
      setTimeout(() => {
        setMultiCrop({});
        setCropYearPreselect([]);
        setMultiContId({});
        setContractPreselect([]);

        setReloadContract(true);
        setReloadCropYear(true);
      }, 0);
    } else {
      if (otherR) {
        setReloadOtherFilter(false);
        setTimeout(() => {
          setMultiValue({});
          setOtherPreselect([]);

          setReloadOtherFilter(true);
        }, 0);
      } else if (contractR) {
        setReloadContract(false);
        setTimeout(() => {
          setMultiContId({});
          setContractPreselect([]);

          setReloadContract(true);
        }, 0);
      } else if (cropyearR) {
        setReloadCropYear(false);
        setTimeout(() => {
          setMultiCrop({});
          setCropYearPreselect([]);

          setReloadCropYear(true);
        }, 0);
      }
    }
  }

  function OtherFilterClick() {
    //need to update
    setTimeout(() => {
      if (
        document.querySelector(
          "#pills-daily-summary #bognames .optionListContainer .highlightOption"
        ) !== null
      ) {
        document
          .querySelector(
            "#pills-daily-summary #bognames .optionListContainer .highlightOption"
          )
          .click();
      }
    }, 500);
    setTimeout(() => {
      if (
        document.querySelector(
          "#pills-daily-summary #harvestbeds .optionListContainer .highlightOption"
        ) !== null
      ) {
        document
          .querySelector(
            "#pills-daily-summary #harvestbeds .optionListContainer .highlightOption"
          )
          .click();
      }
    }, 500);
    setTimeout(() => {
      if (
        document.querySelector(
          "#pills-daily-summary #varietyids .optionListContainer .highlightOption"
        ) !== null
      ) {
        document
          .querySelector(
            "#pills-daily-summary #varietyids .optionListContainer .highlightOption"
          )
          .click();
      }
    }, 500);
  }

  const handlerCropYear = (val) => {
    EmptyWithRefreshDropDowns(false, false, true);
    ListDataViewReset();
    if (IsArrayNotEmpty(val)) {
      const cropIds = val.map((x) => x.id);
      const maxYear = Math.max(...cropIds);
      setCropYearPreselect([maxYear]);
    }
    let cropyears = setObjToString(val);
    if (IsStringChecker(cropyears) && IsStringChecker(ContractIds)) {
      let url = `deliveries/by-bed?contractid=${_.orderBy(
        ContractIds.toString().split(","),
        [],
        ["asc"]
      ).toString()}&cropyear=${_.orderBy(
        cropyears.toString().split(","),
        [],
        ["asc"]
      ).toString()}&filtersonly=1`;
      setResponseType("withoutSubmit");
      fetchBedsData(url);
    }
  };

  // Contract select change event
  const handlerContract = (val, from = "changeEvent", filteredData) => {
    ListDataViewReset();
    EmptyWithRefreshDropDowns(false, false);
    if (IsArrayNotEmpty(filteredData)) {
      let filter = FilteredItemsByContracts(
        filteredData,
        val,
        compareString(from, "changeEvent")
      );
      SetOtherFilterOptions(filter);
      // OtherFilterClick();
    }
  };

  const handlerContractDel = (selectedList, removedItem) => {
    ListDataViewReset();
    if (selectedList.length === 0 || removedItem.id === "all") {
      EmptyWithRefreshDropDowns(true, false, true);
    } else {
      let filter = FilteredItemsByContracts(filterItems, selectedList);
      EmptyWithRefreshDropDowns(false, false, true);
      SetOtherFilterOptions(filter);
    }
    // OtherFilterClick();
  };

  const handlerOtherFilter = (
    selectedList,
    currentItem,
    action,
    request = ""
  ) => {
    ListDataViewReset();
    let multival = { ...multiValue };

    const searchParam = {
      Contract_id: ["RelationshipId"],
      Bog_name: ["BogName", "BogName"],
      Harvest_bed: ["HarvestBed", "HarvestBed"],
      Varity_id: ["VarietyId", "Variety"],
    };
    let filter = [...filterItems];
    cascadeFilterFunc({
      filter,
      selectedList,
      action,
      request,
      multiContId,
      multival,
      searchParam,
      setBogNameOption,
      setHarvestbedOption,
      setVerityOptionDDS,
    });
  };

  const handlerReportFilter = (
    opration,
    param = "",
    val = "",
    sectedVal = ""
  ) => {
    val;
    if (opration == "selected" && param == "reporttype") {
      const report_options = ConstantsVar.report_options[sectedVal?.id];
      setShowReportOption(report_options);
    }
    ListDataViewReset();
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setIsReset(true); /*disabled Reset button*/
    setDisabled(true); /*disabled filter dropdown*/
    setSearchBtnDisabled(true); /*disabled search button*/
    ListDataViewReset();
    setRadioFilter(defaultRadioFilter);
    EmptyWithRefreshDropDowns();
  };

  const handlerSubmit = () => {
    ListDataViewReset();
    const apiUrl = GetQueryString(
      multiValue,
      multiContId,
      multiCrop,
      radioFilter
    );
    if (IsStringChecker(apiUrl)) {
      fetchDailySummary(apiUrl);
    }
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const handleRadioFilterChanges = (e, name) => {
    ListDataViewReset();
    setRadioFilter({ ...radioFilter, [name]: e?.target?.value });
  };
  const DataGridList = gridItems.slice(0, 120);
  return (
    <>
      {/*<!-- Dailysummary block starts -->*/}
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? " show " : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 p-2 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? " active " : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap2"
          aria-controls="selectWrap2"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"></span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>

        <div
          className={`collapse navbar-collapse selectWrapBlock  ${
            isMobFilter ? " show " : ""
          }`}
          id="selectWrap2"
        >
          <div className="row">
            <div className="col-12 col-lg-3 hide_first_option">
              <div className="crop_year_label custom_dropdown">
                {reloadCropYear && (
                  <MultiDropDown
                    optionList={listCropYear}
                    prompt="Select"
                    value={multiCrop}
                    onChange={(val) => handlerCropYear(val, "selected")}
                    label="cropyear"
                    usestate={setMultiCrop}
                    disabled=""
                    onDelete={(val, removedItem) =>
                      handlerCropYear(val, "deSelected", removedItem)
                    }
                    preSelectedOpt={cropyearPreselect}
                    // disablePreSelected={disablePreSelected}
                    singleSelect={true}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {reloadContract && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) =>
                      handlerContract(val, "changeEvent", filterItems)
                    }
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled={false}
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    optionNotFound={{ contract_id: noContractsFound }}
                    preSelectedOpt={contractPreselect}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="bog_name custom_dropdown">
                {/*<!-- <span>Bog Name</span> -->*/}
                {reloadOtherFilter && (
                  <MultiDropDown
                    optionList={bogNameOption}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "selected",
                        "bogname"
                      )
                    }
                    label="bognames"
                    usestate={setMultiValue}
                    disabled={disabled}
                    preSelectedOpt={otherPreselect}
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "deselected",
                        "bogname"
                      )
                    }
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="harvest_bed custom_dropdown">
                {/*<!-- <span>HarvestBed</span> -->*/}
                {reloadOtherFilter && (
                  <MultiDropDown
                    optionList={harvestbedOption}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "selected",
                        "harvestbed"
                      )
                    }
                    label="harvestbeds"
                    usestate={setMultiValue}
                    disabled={disabled}
                    preSelectedOpt={otherPreselect}
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "deselected",
                        "harvestbed"
                      )
                    }
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="variety custom_dropdown">
                {/*<!-- <span>Variety </span> -->*/}
                {reloadOtherFilter && (
                  <MultiDropDown
                    showTitle={true}
                    optionList={verityOptionDD}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "selected",
                        "varityid"
                      )
                    }
                    label="varietyids"
                    usestate={setMultiValue}
                    disabled={disabled}
                    preSelectedOpt={otherPreselect}
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "deselected",
                        "varityid"
                      )
                    }
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="report_type placeholder-red custom_dropdown dropdown_mandate">
                {/*<!-- <span>Report options</span> -->*/}
                {reloadOtherFilter && (
                  <MultiDropDown
                    optionList={multiReportType}
                    prompt="REQUIRED"
                    value={multiValue}
                    onChange={(val, sectedVal) =>
                      handlerReportFilter(
                        "selected",
                        "reporttype",
                        val,
                        sectedVal
                      )
                    }
                    label="reporttype"
                    usestate={setMultiValue}
                    disabled={disabled}
                    singleSelect={true}
                    onDelete={() => handlerReportFilter("deselected")}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div
                className="limit_history_to daily_or_ytd custom_dropdown"
                disabled={disabled}
              >
                <span className="d-none d-lg-block">Daily or YTD </span>
                <span className="d-block d-lg-none px-3 p-2 p-lg-0">
                  Daily or YTD:{" "}
                  <a className="fw-bold radio_box1 grey_text_color"></a>{" "}
                  <i className="fa fa-chevron-down p-2 px-1 blue_color position-relative float-end text-14 l_2"></i>
                </span>

                <div
                  className="ps-1 ms-2 ps-lg-0 ms-lg-0 p-3 pt-0 p-lg-0 d-lg-block  my_delivery_data_radio_button_daily"
                  onChange={(e) => handleRadioFilterChanges(e, "isdaily")}
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name="isdaily"
                    id="success-outlined1"
                    autoComplete="off"
                    value="true"
                    disabled={disabled}
                    checked={radioFilter["isdaily"] == "true"}
                  />
                  <label
                    className="btn btn-primary btn-sm"
                    htmlFor="success-outlined1"
                  >
                    Daily
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="isdaily"
                    id="danger-outlined1"
                    value="false"
                    autoComplete="off"
                    disabled={disabled}
                    checked={radioFilter["isdaily"] == "false"}
                  />
                  <label
                    className="btn btn-primary btn-sm"
                    htmlFor="danger-outlined1"
                  >
                    YTD
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div
                className="limit_history_to custom_dropdown mb-5 mb-lg-3"
                disabled={disabled}
              >
                <span className="d-none d-lg-block">Limit history to</span>
                <span className="d-block d-lg-none px-3 p-2 p-lg-0">
                  Limit history to:{" "}
                  <a className="fw-bold radio_box grey_text_color"></a>{" "}
                  <i className="fa fa-chevron-down p-2 px-1 blue_color position-relative float-end text-14 l_2"></i>
                </span>

                <div
                  className="ps-1 ms-2 ps-lg-0 ms-lg-0 p-3 pt-0 p-lg-0 d-lg-block  my_delivery_data_radio_button"
                  onChange={(e) => handleRadioFilterChanges(e, "limithistory")}
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name="limithistory"
                    id="success-outlined"
                    autoComplete="off"
                    value="AllBeds"
                    disabled={disabled}
                    checked={radioFilter["limithistory"] === "AllBeds"}
                  />
                  <label
                    className="btn btn-primary btn-sm"
                    htmlFor="success-outlined"
                  >
                    All Selected Beds
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="limithistory"
                    id="danger-outlined"
                    value="BedsDelivered"
                    autoComplete="off"
                    disabled={disabled}
                    checked={radioFilter["limithistory"] === "BedsDelivered"}
                  />
                  <label
                    className="btn btn-primary btn-sm"
                    htmlFor="danger-outlined"
                  >
                    Beds That Have Delivered
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="allFieldRequired pe-4 d-inline-block pb-3 pe-lg-3">
              <span className="red_star_label position-relative"></span>{" "}
              Required (additional report types including Acres, Yield, and
              Quality metrics will be added soon)
            </div>
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? " show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>
      {/*<!-- Daily Summary ends -->*/}
      {/*<!-- Filter Dropdown ends -->*/}

      {isListingHide == false ? (
        <>
          {/*<!-- Print, CSV and Excel Icons starts -->*/}
          <div className="row mt-2">
            <div className={`col-12 d-none ${hideExport ? "" : "d-lg-block"} `}>
              <div className="float-end mb-2">
                <span>
                  <DailySummaryPDFFile
                    header={exportFileHeader}
                    content={exportFileContent}
                    fileName={`dailySummary-${showReportOption}${
                      radioFilter["isdaily"] == "true" ? "Daily" : "YTD"
                    }`}
                    /* headerAction={'dailySummery'} */
                  />
                </span>
                <span>
                  <DailySummaryExportFile
                    downloadFormate="csv"
                    exportData={[exportFileHeader, ...exportFileContent]}
                    fileName="dailySummary"
                    sheetTitle={`${showReportOption}${
                      radioFilter["isdaily"] == "true" ? "Daily" : "YTD"
                    }`}
                  />
                </span>
                <span>
                  <DailySummaryExportFile
                    downloadFormate="xlsx"
                    exportData={[exportFileHeader, ...exportFileContent]}
                    fileName="dailySummary"
                    sheetTitle={`${showReportOption}${
                      radioFilter["isdaily"] == "true" ? "Daily" : "YTD"
                    }`}
                  />
                </span>
              </div>
            </div>
          </div>
          {/*<!-- Print, CSV and Excel Icons ends -->*/}
          {/*<!-- Responsive table starts -->*/}
          <h5 className="fw-bold mt-3 mt-lg-2 mb-3 mob-text-16 ps-0 ps-lg-4 text-18 daily-summery-isDaily">
            {showReportOption}{" "}
            {radioFilter["isdaily"] == "true" ? "Daily" : "YTD"}
          </h5>
          {!displayAccordian && (
            <div
              className={`daily-summery-table visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space ${
                isListingHide == true ? "d-none" : "d-none d-lg-block"
              }`}
            >
              <DataGridPagination
                uniqueIdentifier="Id"
                gridData={DataGridList}
                columns={columns}
                isPagination={false}
                itemsPerPage={10}
                columnResize={false}
                classNames="contain-block-size-reset top-mspace-20 bot-mspace-20 border-radius-6 grid-load-more-height"
              />
            </div>
          )}
          {/*<!-- Sticky header table ends -->*/}
          {/*<!-- Responsive table starts -->*/}
          <div
            className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 ${
              !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
            }`}
          >
            <div className="delivery_approval_mob_acc mobile-accordion accordion-space beds_table accordion-fonts">
              <DDDailysumaryMobile
                listingData={resultItems}
                showReportOption={showReportOption}
                radioFilter={radioFilter}
                preSelectedArray={cropyearPreselect}
              />
            </div>
          </div>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
      {/*<!-- Responsive table ends -->*/}
    </>
  );
};
export default DDDailysumary;
