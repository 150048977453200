/**
 * @component_Name : UserNotification
 * @description : Users can see thier own Notification
 * @company : Photon
 * @author : Gobi/03-10-2022
 **/
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import { useSelector, useDispatch } from "react-redux";
import {
  compareString,
  IsArrayNotEmpty,
  IsStringChecker,
  IsTokenChecker,
  HandleErrorTrackAndToast,
  IsObjectChecker,
  safeArrayCheck,
} from "../../utils/helper";
import CurrentLocation from "../global/currentLocation";
import { useTranslation } from "react-i18next";
import { Alert, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import {
  ContractsFromJSONParser,
  CheckArrayRoleExist,
  FilterContractByRole,
  RoleReducerFromContractsArray,
  ImpersonationAccountsSwap,
  DetermineImpersonation,
} from "../../permissionWrapper";
import { NotificationPreferencesRoleMappings } from "../../utils/roles";
import _ from "lodash";
import { notification_preference_headers } from "../../utils/constantsVariable";
import { Prompt } from "react-router-dom";

const NotificationPreferences = (props) => {
  const impersonated = DetermineImpersonation();
  const { profileData, CommonPageCBRoles = [] } = props;
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);
  const [tableActive, settableActie] = useState(false);
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });

  const [ContractNumbers, setContractNumbers] = useState([]);
  const [constructCheckedObj, setConstructCheckedObj] = useState([]);
  const [masterHead, setMasterHead] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [sortedMasterData, setSortedMasterData] = useState([]);

  const [showModal, setShowModal] = useState({
    isOpen: false,
    message: "",
  });
  const gbsRolesByContract = ContractsFromJSONParser(accounts, false);
  // const gbsRolesByPerson = _.get(
  //   accounts,
  //   `0.idTokenClaims.gbsRolesByPerson`,
  //   []
  // );
  // console.log({ContractNumbers, gbsRolesByPerson, gbsRolesByContract});

  const masterCheckboxHeaders = [...notification_preference_headers];

  const EmptyRoles = {
    header: null,
    RolesToCheck: [],
  };

  const [refresh, setRefresh] = useState(true);
  const [initialConstructCheckedObj, setInitialConstructCheckedObj] = useState(
    []
  );

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getNotificationEnrollments(`notification-enrollments`, "master");
      fetchContract();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  // Fetching Contracts
  const fetchContract = async () => {
    if (tokenId != "") {
      setLoader(true);
      await gbsDataCommonMethod(
        tokenId,
        accounts,
        `/persons/${personId}/contracts?r.ContractStatus=1000,1001`
      )
        .then((resp) => {
          const data = safeArrayCheck(_.get(resp, `data`, []));
          let filteredData = FilterContractByRole(
            data,
            accounts,
            CommonPageCBRoles
          );
          filteredData = _.orderBy(
            filteredData,
            ["ContractStatus", "RelationshipNumber"],
            ["asc", "asc"]
          );
          if (IsArrayNotEmpty(filteredData)) {
            let contracts = filteredData
              .map((item) => item.RelationshipId)
              .toString();
            if (IsStringChecker(contracts)) {
              let url = `notification-enrollments/opt-ins?personid=${personId}&relationshipid=${contracts}`;
              getNotificationEnrollments(url, "tableData");
              setContractNumbers(filteredData);
              // setContractNumbers(_.orderBy(resp.data,['ContractStatus'],['asc']));
            }
          }
        })
        .catch((error) => {
          setLoader(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };

  const FunctionDescriptionProvider = (EmailMasterFunctionId) => {
    try {
      if (EmailMasterFunctionId) {
        const headData = headerData.find(
          (li) => li.Id == EmailMasterFunctionId
        );
        return _.get(headData, "FunctionDescription", "");
      } else {
        return "";
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const ObtainRoleDetails = (FunctionDescription = null) => {
    try {
      if (IsStringChecker(FunctionDescription)) {
        let roleData = NotificationPreferencesRoleMappings.find((li) =>
          compareString(li.header, FunctionDescription)
        );
        if (roleData && !_.isEmpty(roleData)) {
          return { ...roleData, found: true };
        } else {
          return { found: false, ...EmptyRoles };
        }
      } else {
        return { found: false, ...EmptyRoles };
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return { found: false, ...EmptyRoles };
    }
  };

  const DisableTheCheckBox = (RelationshipId, RolesToCheck) => {
    try {
      if (RelationshipId) {
        const contractFound = gbsRolesByContract.find(
          (li) => li.contract == RelationshipId
        );
        return !CheckArrayRoleExist(RolesToCheck, contractFound?.roles, false);
      } else {
        return true;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return true;
    }
  };

  const DisableTheCheckBoxALL = (RolesToCheck) => {
    try {
      if (
        IsArrayNotEmpty(RolesToCheck) &&
        IsArrayNotEmpty(gbsRolesByContract)
      ) {
        const ExistingRoles = RoleReducerFromContractsArray(gbsRolesByContract);
        return !CheckArrayRoleExist(RolesToCheck, ExistingRoles, false);
      } else {
        return true;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return true;
    }
  };

  const GetSortedData = (originalData = {}) => {
    try {
      if (IsObjectChecker(originalData)) {
        const objArr = Object.keys(originalData).map((list) => {
          let data = _.get(originalData, `${list}.0`, {});
          return IsObjectChecker(data) ? data : {};
        });
        const filtered = objArr.filter((li) => li.SortOrder);
        const sorted = IsArrayNotEmpty(filtered)
          ? _.sortBy(filtered, ["SortOrder"], ["asc"])
          : [];
        const final = IsArrayNotEmpty(sorted)
          ? sorted.map((li) => li?.FunctionDescription)
          : [];
        return _.compact(final);
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  // Get Grid API values
  const getNotificationEnrollments = async (url, datatype, data, method) => {
    setLoader(true);
    let isSMSSelected =
      data?.data.filter((i) => i.notification_type === 8800).length > 0 || 0; //8800
    await gbsDataCommonMethod(tokenId, accounts, url, method, data)
      .then((resp) => {
        if (resp.status === 200) {
          let resultData;
          switch (datatype) {
            case "master":
              let tempResultData = resp.data.filter((a) => {
                return ![8801].includes(a.NotificationId);
              });
              resultData = tempResultData.map((li) => {
                const { FunctionDescription, NotificationTypeDesc } = li;
                // console.log({ li });

                let child = masterCheckboxHeaders.find((li) =>
                  compareString(li?.name, FunctionDescription)
                );
                if (child === undefined) {
                  return { ...li, child: true, hide: false };
                } else {
                  const {
                    active,
                    disable_text,
                    disable_email,
                    hide_text,
                    hide_email,
                  } = child;
                  if (active) {
                    const child_access = compareString(NotificationTypeDesc, "Email")
                      ? !disable_email
                      : compareString(NotificationTypeDesc, "Text")
                      ? !disable_text
                      : false;
                    const hide = compareString(NotificationTypeDesc, "Email")
                      ? hide_email
                      : compareString(NotificationTypeDesc, "Text")
                      ? hide_text
                      : false;
                    return { ...li, child: child_access, hide };
                  } else {
                    return { ...li, child: true, hide: false };
                  }
                }
              });
              // console.log("resp.data => ", resultData);
              var data = _.groupBy(resultData, "FunctionDescription");
              // console.log({ data });
              setMasterHead(data);
              const sortedData = GetSortedData(data);
              // console.log({ sortedData });
              setSortedMasterData(sortedData);
              setHeaderData(resultData);
              settableActie(true);
              break;
            case "tableData":
              let tempData = [...resp.data];
              let optinArray = tempData.map((item) => {
                const { EmailMasterFunctionId, RelationshipId } = item;
                const FunctionDescription = FunctionDescriptionProvider(
                  EmailMasterFunctionId
                );
                const { found, RolesToCheck } =
                  ObtainRoleDetails(FunctionDescription);
                if (found) {
                  return {
                    ...item,
                    disable: DisableTheCheckBox(RelationshipId, RolesToCheck),
                  };
                } else {
                  return { ...item, disable: false };
                }
              });
              const filteredArr = optinArray.filter((li) => !li.disable);
              const finalArray = filteredArr.map((item) => {
                const {
                  EmailMasterFunctionId,
                  RelationshipId,
                  NotificationId,
                } = item;
                return {
                  email_master_function_id: EmailMasterFunctionId,
                  relationship_id: RelationshipId,
                  notification_type: NotificationId,
                };
              });
              setConstructCheckedObj([...finalArray]);
              setInitialConstructCheckedObj([...finalArray]);
              break;
            case "save":
              setAlertOpen({
                isActive: true,
                message:
                  moreLangs[i18n.resolvedLanguage].userProfile
                    .notification_preferences_save_msg,
              });
              setTimeout(() => {
                setAlertOpen({ isActive: false, message: "" });
              }, 5000);
              setDisableSubmitCancel(true);
              if (isSMSSelected) {
                let hasMobileNo =
                  profileData.phone_number !== "" &&
                  profileData.phone_number !== null &&
                  profileData.phone_number !== undefined;
                setShowModal({
                  isOpen: true,
                  message: hasMobileNo
                    ? `${profileData.phone_number} is the mobile number we have on file, this is where text notifications will be sent, if you need to correct this, please update mobile phone number on your profile`
                    : "Thank you for selecting text messages, we do not have a mobile number on file for you, please go to your profile and enter a mobile number",
                });
              }

              break;
          }
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const saveChanges = () => {
    let url = `persons/${personId}/notification-enrollments`;
    setInitialConstructCheckedObj([...constructCheckedObj]);
    getNotificationEnrollments(
      url,
      "save",
      { data: constructCheckedObj },
      "PUT"
    );
  };

  const checkboxChecked = (obj) => {
    const { Id, NotificationId, FunctionDescription, hide } = obj;
    let checked = false;

    const { found, RolesToCheck } = ObtainRoleDetails(FunctionDescription);
    const disabled = found ? DisableTheCheckBoxALL(RolesToCheck) : false;
    const hypenate = hide || disabled;
    if (hypenate) {
      checked = false;
    } else {
      let count = 0;
      let lengthToCheck = ContractNumbers.length;
      if (found) {
        let filteredArr = FilterContractByRole(
          ContractNumbers,
          accounts,
          RolesToCheck
        );
        lengthToCheck = filteredArr.length;
      }
      constructCheckedObj.map((item) => {
        if (
          Id === item.email_master_function_id &&
          NotificationId === item.notification_type
        ) {
          return count++;
        }
      });
      checked = count === lengthToCheck && lengthToCheck > 0;
    }
    return (
      <>
        {(hypenate) ? (
          "-"
        ) : (
          <>
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                sllectAll(e.target.checked, obj);
              }}
            />
            <span className="slider_progress round"></span>
          </>
        )}
      </>
    );
  };

  const sllectAll = (e, obj) => {
    const { Id, NotificationId, FunctionDescription } = obj;
    setDisableSubmitCancel(false);

    if (e) {
      const { found, RolesToCheck } = ObtainRoleDetails(FunctionDescription);
      let filteredArr = found
        ? FilterContractByRole(ContractNumbers, accounts, RolesToCheck)
        : [...ContractNumbers];

      let optinArray = filteredArr.map((item) => {
        return {
          email_master_function_id: Id,
          notification_type: NotificationId,
          relationship_id: item.RelationshipId,
        };
      });
      let uniqueArr = _.uniqWith(
        [...constructCheckedObj, ...optinArray],
        _.isEqual
      );
      setConstructCheckedObj([...uniqueArr]);
      if (
        JSON.stringify(initialConstructCheckedObj) === JSON.stringify(uniqueArr)
      ) {
        setDisableSubmitCancel(true);
      }
    } else {
      let optinArray = constructCheckedObj.filter((item) => {
        return (
          Id !== item.email_master_function_id ||
          NotificationId !== item.notification_type
        );
      });
      setConstructCheckedObj([...optinArray]);
      if (
        JSON.stringify(initialConstructCheckedObj) ===
        JSON.stringify(optinArray)
      ) {
        setDisableSubmitCancel(true);
      }
    }
  };

  const checkboxCheckedChild = (mast, i, contract) => {
    let checked = false;
    const { RelationshipId } = contract;
    const { Id, NotificationId, FunctionDescription, child, hide } = mast;
    // console.log({Id, NotificationId, FunctionDescription, child});
    const { found, RolesToCheck } = ObtainRoleDetails(FunctionDescription);
    const disabled = found
      ? DisableTheCheckBox(RelationshipId, RolesToCheck)
      : false;
    const hypenate = hide || disabled;
    if (hypenate) {
      checked = false;
    } else {
      constructCheckedObj.map((item) => {
        if (
          RelationshipId === item.relationship_id &&
          Id === item.email_master_function_id &&
          NotificationId === item.notification_type
        ) {
          checked = true;
        }
      });
    }
    return (
      <td key={i} className="text-center">
        {hypenate ? (
          "-"
        ) : child ? (
          <input
            onChange={(e) => checkboxclick(e.target.checked, mast, contract)}
            checked={checked}
            className={`form-check-input cursor-pointer`}
            type="checkbox"
          />
        ) : (
          <input
            onChange={() => null}
            checked={checked}
            className={`form-check-input cursor-pointer`}
            type="checkbox"
            disabled
          />
        )}
      </td>
    );
  };

  const checkboxclick = (e, mtObj, contract) => {
    setDisableSubmitCancel(false);
    const { RelationshipId } = contract;
    const { Id, NotificationId } = mtObj;

    if (e) {
      let newObj = {
        email_master_function_id: Id,
        relationship_id: RelationshipId,
        notification_type: NotificationId,
      };
      setConstructCheckedObj([...constructCheckedObj, newObj]);
      if (
        JSON.stringify(initialConstructCheckedObj) ===
        JSON.stringify([...constructCheckedObj, newObj])
      ) {
        setDisableSubmitCancel(true);
      }
    } else {
      constructCheckedObj.splice(
        constructCheckedObj.findIndex(
          (item) =>
            RelationshipId === item.relationship_id &&
            Id === item.email_master_function_id &&
            NotificationId === item.notification_type
        ),
        1
      );
      setConstructCheckedObj([...constructCheckedObj]);
      if (
        JSON.stringify(initialConstructCheckedObj) ===
        JSON.stringify([...constructCheckedObj])
      ) {
        setDisableSubmitCancel(true);
      }
    }
    // console.log("before", {
    //   constructCheckedObj,
    //   sortedMasterData,
    //   masterHead,
    // });
  };

  const access = IsArrayNotEmpty(sortedMasterData);
  const cancelSave = () => {
    setConstructCheckedObj([...initialConstructCheckedObj]);
    setDisableSubmitCancel(true);
    return;
    setCount(count + 1);
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 100);
  };
  useEffect(() => {
    const unloadCallback = (event) => {
      if (!disableSubmitCancel) {
        if (!disableSubmitCancel) {
          const confirmationMessage =
            moreLangs[i18n.resolvedLanguage].forum_page.browser_back_button_msg;
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      }
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [disableSubmitCancel]);

  const onHide = () => {
    setShowModal({ isOpen: false, message: "" });
  };
  return (
    <>
      <Prompt
        when={!disableSubmitCancel}
        message={(location, action) => {
          if (action === "POP" || action === "PUSH") {
            return disableSubmitCancel
              ? true
              : moreLangs[i18n.resolvedLanguage].forum_page
                  .browser_back_button_msg;
          } else {
            return true;
          }
        }}
      />
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {alertOpen.isActive && (
        <Alert
          variant="success"
          onClose={() => setAlertOpen({ isActive: false, message: "" })}
          dismissible
        >
          <b>{alertOpen.message}</b>
        </Alert>
      )}
      {tableActive && refresh && (
        <div className="hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 max_content_table payments_equity_table notifications_table">
          <h3 className="mb-4">
            {" "}
            {
              moreLangs[i18n.resolvedLanguage].userProfile
                .notification_preferences_title
            }
            :{" "}
          </h3>
          <div className="mb-4 hor-space-20 notification-button">
            <button
              className="btn btn-primary text-16 me-3 cursor-for-btn"
              disabled={impersonated ? impersonated : disableSubmitCancel}
              onClick={saveChanges}
            >
              {moreLangs[i18n.resolvedLanguage].button_text.save}
            </button>
            <button
              className="btn text-16 btn-primary cursor-for-btn"
              disabled={impersonated ? impersonated : disableSubmitCancel}
              onClick={cancelSave}
            >
              Cancel
              {/* {moreLangs[i18n.resolvedLanguage].button_text.cancel_text} */}
            </button>
          </div>
          <div className="hor-space-20 light_bg ver-space-20 border-radius-6 bot-mspace-20 max_content_table payments_equity_table relationship_table">
            <div className="table-responsive bordered-table">
              <table className="grid_block mb-0 width-100">
                <thead>
                  <tr className="blue_bg">
                    <th>
                      {
                        moreLangs[i18n.resolvedLanguage].userProfile
                          .Relationship
                      }
                    </th>
                    {access &&
                      sortedMasterData.map((item, i) => (
                        <th key={i} colSpan="2">
                          {item}{" "}
                          {masterHead[item][0].FriendlyDescription !== null &&
                            masterHead[item][0].FriendlyDescription !== "" &&
                            masterHead[item][0].FriendlyDescription !==
                              undefined && (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip className="tooltip-control" id={i}>
                                    {masterHead[item][0].FriendlyDescription}
                                  </Tooltip>
                                }
                              >
                                <i className="fa fa-info-circle ps-2"></i>
                              </OverlayTrigger>
                            )}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    {access &&
                      sortedMasterData.map((item) => {
                        return masterHead[item].map((noti, i) => (
                          <td key={i} className="text-center">
                            Select All {noti.NotificationTypeDesc}
                            <label className="switch toggle_on_off">
                              {checkboxChecked(noti)}
                            </label>
                          </td>
                        ));
                      })}
                  </tr>
                  <tr>
                    <td></td>
                    {access &&
                      sortedMasterData.map((item) => {
                        return masterHead[item].map((noti, i) => (
                          <td key={i} className="text-center">
                            {noti.NotificationTypeDesc}
                          </td>
                        ));
                      })}
                  </tr>
                  {ContractNumbers.length > 0 &&
                    ContractNumbers.map((contract, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-start">{`${contract.RelationshipNumber}-${contract.RelationshipSuffix}-${contract.Name}`}</td>
                          {access &&
                            sortedMasterData.map((item) => {
                              return masterHead[item].map((mast, i) => {
                                return checkboxCheckedChild(mast, i, contract);
                              });
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mb-4 hor-space-20 notification-button">
            <button
              className="btn btn-primary text-16 me-3 cursor-for-btn"
              disabled={impersonated ? impersonated : disableSubmitCancel}
              onClick={saveChanges}
            >
              {moreLangs[i18n.resolvedLanguage].button_text.save}
            </button>
            <button
              className="btn text-16 btn-primary cursor-for-btn"
              disabled={impersonated ? impersonated : disableSubmitCancel}
              onClick={cancelSave}
            >
              Cancel
              {/* {moreLangs[i18n.resolvedLanguage].button_text.cancel_text} */}
            </button>
          </div>
        </div>
      )}
      <Modal
        show={showModal.isOpen}
        className={`notification-modal`}
        id={"notificationModal"}
        aria-labelledby={"notificationModal"}
      >
        <Modal.Header className={" blue_bg"}>
          <div className="modal-title h4">
            <h4 className="modal-title white_color">Mobile number</h4>
          </div>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onHide}
          ></button>
        </Modal.Header>
        <Modal.Body className={"modalBodyImage"}>
          <div
            style={{
              maxWidth: "960px",
              position: "relative",
              margin: "0 auto",
            }}
            id="hide_video"
          >
            {showModal.message}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default NotificationPreferences;
