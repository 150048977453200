/**
 * @component_Name : PdfFile
 * @description : library for pdf create.
 * @company : Photon
 * @author : Ashish Ranjan/08-08-2022
 *
 */
import React from "react";
import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  base64LogoImg,
  base64CrossImg,
  base64CheckImg,
  base64UpImg,
  base64DownImg,
} from "./baseImageEncode";
import {
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  IsStringChecker,
  compareString,
} from "../../utils/helper";

const imageDisplay = (doc, data, expression) => {
  switch (expression) {
    case "crossCheck":
      let cross_td = data.cell.raw;
      let cross_img = cross_td == 0 ? base64CrossImg : base64CheckImg;
      let cross_dim = data.cell.height - data.cell.padding("vertical");
      let cross_textPos = data.cell.getTextPos();
      doc.addImage(
        cross_img,
        cross_textPos.x,
        cross_textPos.y,
        cross_dim,
        cross_dim
      );
      break;

    case "UpDownArrow":
      let arrow_td = data.cell.raw;
      let arrow_raw = data.row.raw[44];
      let arrow_img = arrow_td > arrow_raw ? base64UpImg : base64DownImg;
      let arrow_dim = data.cell.height - data.cell.padding("vertical");
      let arrow_textPos = data.cell.getTextPos();
      doc.addImage(
        arrow_img,
        parseInt(arrow_textPos.x) + 11,
        arrow_textPos.y,
        arrow_dim,
        arrow_dim
      );
      break;
  }
};

const PdfFile = ({ pdfData, fileName, tooltip }) => {
  const handleClickPdf = (e) => {
    const doc = new jsPDF("l");
    doc.setFontSize(8);
    e.stopPropagation();
    if (pdfData.length > 0) {
      const header = Object.keys(pdfData[0]).map((x) => x.replace("_", " "));
      const content = pdfData.map((x) => {
        const arr = Object.values(x).map((y) => y);
        return arr;
      });
      autoTable(doc, {
        head: [header],
        body: content,
        theme: "grid",
        horizontalPageBreak: true,
        headStyles: {
          fillColor: [0, 81, 148],
          /*fontSize: 7,*/
        },
        /*footStyles: {
          fillColor: [241, 196, 15],
          fontSize: 15,
        },*/
        /*bodyStyles: {
          fontSize: 7,
          valign: 'top'
        },*/

        columnStyles: {
          7:
            fileName === "DeliveryApproval"
              ? { halign: "center", fontColor: "#fff" }
              : "",
          8:
            fileName === "DeliveryApproval"
              ? { halign: "center", fontColor: "#fff" }
              : "",
          28:
            fileName === "BedsData" ? { cellWidth: 15 } : { cellWidth: "auto" },
        },

        styles: { cellPadding: 0.5, fontSize: 7 },
        //startY: 20,
        // Override the default above for the text column
        //columnStyles: { text: { cellWidth: 'auto' } },
        /*=====Logo=====*/
        didDrawPage: function () {
          // Header
          doc.setFontSize(8);
          doc.setTextColor(40);
          doc.addImage(base64LogoImg, "PNG", 129, 2, 30, 15);
        },
        margin: { top: 20 },
        /* set column Image*/
        didDrawCell: function (data) {
          if (
            data.column.index === 7 &&
            data.cell.section === "body" &&
            fileName === "DeliveryApproval"
          ) {
            imageDisplay(doc, data, "crossCheck");
          }
          if (
            data.column.index === 8 &&
            data.cell.section === "body" &&
            fileName === "DeliveryApproval"
          ) {
            imageDisplay(doc, data, "crossCheck");
          }
          if (
            data.column.index === 28 &&
            data.cell.section === "body" &&
            fileName === "BedsData"
          ) {
            imageDisplay(doc, data, "UpDownArrow");
          }
        },
        /*Change text color*/
        didParseCell: function (data) {
          if (
            data.section === "body" &&
            data.column.index === 7 &&
            fileName === "DeliveryApproval"
          ) {
            data.cell.styles.textColor = "#fff";
          }
          if (
            data.section === "body" &&
            data.column.index === 8 &&
            fileName === "DeliveryApproval"
          ) {
            data.cell.styles.textColor = "#fff";
          }
        },
      });
      doc.save(fileName + ".pdf");
    }
  };

  return (
    <>
      <NavLink
        to="#"
        className=" printIconPadding d-inline-block"
        onClick={handleClickPdf}
        data-tip={tooltip ?? "Print"}
        data-for="main"
        data-iscapture="true"
      >
        <img
          src="/assets/images/print_icon.svg"
          className="img-40 me-2"
          alt="Print_Icon"
        />
      </NavLink>
      <ReactTooltip
        id="main"
        place="bottom"
        effect="float"
        type="dark"
        multiline={true}
      />
    </>
  );
};

export const KeepingQualityPdfFile = ({ pdfData, fileName, tooltip }) => {
  const handleClickPdf = (e) => {
    const doc = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });
    doc.setFontSize(8);
    e.stopPropagation();
    if (IsArrayNotEmpty(pdfData)) {
      const header = Object.keys(pdfData[0]).map((x) => x.replace("_", " "));
      const content = pdfData.map((x) => {
        const arr = Object.values(x).map((y) => y);
        return arr;
      });
      autoTable(doc, {
        head: [header],
        body: content,
        theme: "grid",
        horizontalPageBreak: true,
        headStyles: {
          fillColor: [0, 81, 148],
          cellWidth: 10,
          fontSize: 8,
          lineColor: [255, 255, 255],
          lineWidth: 0.1,
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 15 },
          2: { cellWidth: 22 },
          3: { cellWidth: 15 },
          4: { cellWidth: 15 },
          5: { cellWidth: 12 },
          6: { cellWidth: 17 },
          7: { cellWidth: 17 },
          8: { cellWidth: 17 },
          9: { cellWidth: 16 },
          10: { cellWidth: 16 },
          11: { cellWidth: 16 },
          12: { cellWidth: 16 },
          13: { cellWidth: 16 },
          14: { cellWidth: 16 },
          15: { cellWidth: 16 },
          16: { cellWidth: 16 },
        },
        styles: { cellPadding: 0.5, fontSize: 8 },
        /*=====Logo=====*/
        didDrawPage: function () {
          doc.setFontSize(8);
          doc.setTextColor(40);
          doc.addImage(base64LogoImg, "PNG", 129, 2, 30, 15);
        },
        margin: { top: 20 },
      });
      doc.save(fileName + ".pdf");
    }
  };

  return (
    <>
      <NavLink
        to="#"
        className=" printIconPadding d-inline-block"
        onClick={handleClickPdf}
        data-tip={tooltip ?? "Print"}
        data-for="main"
        data-iscapture="true"
      >
        <img
          src="/assets/images/print_icon.svg"
          className="img-40 me-2"
          alt="Print_Icon"
        />
      </NavLink>
      <ReactTooltip
        id="main"
        place="bottom"
        effect="float"
        type="dark"
        multiline={true}
      />
    </>
  );
};

export const DailySummaryPDFFile = ({
  header = [],
  content = [],
  fileName,
  tooltip,
}) => {
  const handleClickPdf = (e) => {
    e.stopPropagation();
    if (IsArrayNotEmpty(header) && IsArrayNotEmpty(content)) {
      const doc = new jsPDF("l");
      doc.setFontSize(8);
      autoTable(doc, {
        head: [header],
        body: content,
        theme: "grid",
        horizontalPageBreak: true,
        headStyles: {
          fillColor: [0, 81, 148],
          /*fontSize: 7,*/
        },
        /*footStyles: {
          fillColor: [241, 196, 15],
          fontSize: 15,
        },*/
        /*bodyStyles: {
          fontSize: 7,
          valign: 'top'
        },*/

        columnStyles: {
          7:
            fileName === "DeliveryApproval"
              ? { halign: "center", fontColor: "#fff" }
              : "",
          8:
            fileName === "DeliveryApproval"
              ? { halign: "center", fontColor: "#fff" }
              : "",
          28:
            fileName === "BedsData" ? { cellWidth: 15 } : { cellWidth: "auto" },
        },

        styles: { cellPadding: 0.5, fontSize: 7 },
        //startY: 20,
        // Override the default above for the text column
        //columnStyles: { text: { cellWidth: 'auto' } },
        /*=====Logo=====*/
        didDrawPage: function () {
          // Header
          doc.setFontSize(8);
          doc.setTextColor(40);
          doc.addImage(base64LogoImg, "PNG", 129, 2, 30, 15);
        },
        margin: { top: 20 },
        /* set column Image*/
        didDrawCell: function (data) {
          if (
            data.column.index === 7 &&
            data.cell.section === "body" &&
            fileName === "DeliveryApproval"
          ) {
            imageDisplay(doc, data, "crossCheck");
          }
          if (
            data.column.index === 8 &&
            data.cell.section === "body" &&
            fileName === "DeliveryApproval"
          ) {
            imageDisplay(doc, data, "crossCheck");
          }
          if (
            data.column.index === 28 &&
            data.cell.section === "body" &&
            fileName === "BedsData"
          ) {
            imageDisplay(doc, data, "UpDownArrow");
          }
        },
        /*Change text color*/
        didParseCell: function (data) {
          if (
            data.section === "body" &&
            data.column.index === 7 &&
            fileName === "DeliveryApproval"
          ) {
            data.cell.styles.textColor = "#fff";
          }
          if (
            data.section === "body" &&
            data.column.index === 8 &&
            fileName === "DeliveryApproval"
          ) {
            data.cell.styles.textColor = "#fff";
          }
        },
      });
      doc.save(fileName + ".pdf");
    }
  };

  return (
    <>
      <NavLink
        to="#"
        className=" printIconPadding d-inline-block"
        onClick={handleClickPdf}
        data-tip={tooltip ?? "Print"}
        data-for="main"
        data-iscapture="true"
      >
        <img
          src="/assets/images/print_icon.svg"
          className="img-40 me-2"
          alt="Print_Icon"
        />
      </NavLink>
      <ReactTooltip
        id="main"
        place="bottom"
        effect="float"
        type="dark"
        multiline={true}
      />
    </>
  );
};

export const PaymentEstimatorPdfFile = ({
  pdfData,
  fileName,
  tooltip,
  FileTitle = "Payment Estimate",
  SubTitleArray = [],
}) => {
  const handleClickPdf = (e) => {
    e.stopPropagation();

    const doc = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });

    if (IsArrayNotEmpty(pdfData)) {
      const header = Object.keys(pdfData[0]).map((x) => x.replace("_", " "));
      const content = pdfData.map((x) => {
        const arr = Object.values(x).map((y) => y);
        return arr;
      });
      let columnStyles = {};
      let headStyles = {
        fillColor: [0, 81, 148],
        lineColor: [255, 255, 255],
        lineWidth: 0.1,
      };
      let titleFontSize = 20,
        headFontSize = 10,
        topTitlePosition = 15;
      function GetMarginTop() {
        try {
          const def = 20;
          if (IsArrayNotEmpty(SubTitleArray)) {
            const len = SubTitleArray.length * 8;
            return len + def;
          }
          return def;
        } catch (e) {
          HandleErrorTrackAndToast(e, true);
          return 20;
        }
      }
      const marginTop = GetMarginTop();

      if (compareString("PaymentEstimateWithPaymentDeferrals", fileName)) {
        columnStyles = {
          0: { cellWidth: 13 },
          1: { cellWidth: 22 },
          2: { cellWidth: 40 },
          3: { cellWidth: 17 },
          4: { cellWidth: 15 },
          5: { cellWidth: 40 },
          6: { cellWidth: 40 },
          7: { cellWidth: 40 },
          8: { cellWidth: 40 },
        };
        headStyles = {
          fillColor: [0, 81, 148],
          cellWidth: 10,
          fontSize: headFontSize,
          lineColor: [255, 255, 255],
          lineWidth: 0.1,
        };
      } else if (compareString("InterestPaymentEstimate", fileName)) {
        columnStyles = {
          0: { cellWidth: 13 },
          1: { cellWidth: 22 },
          2: { cellWidth: 18 },
          3: { cellWidth: 18 },
          4: { cellWidth: 20 },
          5: { cellWidth: 40 },
          6: { cellWidth: 20 },
          7: { cellWidth: 35 },
          8: { cellWidth: 40 },
          9: { cellWidth: 40 },
        };
        headStyles = {
          fillColor: [0, 81, 148],
          cellWidth: 10,
          fontSize: headFontSize,
          lineColor: [255, 255, 255],
          lineWidth: 0.1,
        };
      } else if (compareString("OverallPaymentEstimate", fileName)) {
        columnStyles = {
          0: { cellWidth: 15 },
          1: { cellWidth: 38 },
          2: { cellWidth: 18 },
          3: { cellWidth: 30 },
          4: { cellWidth: 15 },
          5: { cellWidth: 38 },
          6: { cellWidth: 38 },
          7: { cellWidth: 38 },
          8: { cellWidth: 38 },
        };
        headStyles = {
          fillColor: [0, 81, 148],
          cellWidth: 10,
          fontSize: headFontSize,
          lineColor: [255, 255, 255],
          lineWidth: 0.1,
        };
      }
      autoTable(doc, {
        head: [header],
        body: content,
        theme: "grid",
        horizontalPageBreak: true,
        headStyles,
        columnStyles,
        styles: { cellPadding: 0.5 },
        /*=====Logo=====*/
        didDrawPage: function () {
          if (IsArrayNotEmpty(SubTitleArray)) {
            doc.setFontSize(15);
            const ordered = _.orderBy(SubTitleArray, "order", "asc");
            ordered.forEach((item, index) => {
              const { line } = item;
              if (IsStringChecker(line)) {
                const calculatedTopPosition = 15 + (index + 1) * 8;
                doc.text(line, 13, calculatedTopPosition);
              }
            });
          }
          doc.setFontSize(titleFontSize);
          doc.setTextColor(0, 38, 127);
          doc.addImage(base64LogoImg, "PNG", 254, 5, 30, 15);
          doc.text(
            IsStringChecker(FileTitle) ? FileTitle : "Payment Estimate",
            13,
            topTitlePosition
          );
        },
        margin: { top: marginTop },
      });
      doc.save(fileName + ".pdf");
    }
  };

  return (
    <>
      <NavLink
        to="#"
        className=" printIconPadding d-inline-block"
        onClick={handleClickPdf}
        data-tip={tooltip ?? "Print"}
        data-for="main"
        data-iscapture="true"
      >
        <img
          src="/assets/images/print_icon.svg"
          className="img-40 me-2"
          alt="Print_Icon"
        />
      </NavLink>
      <ReactTooltip
        id="main"
        place="bottom"
        effect="float"
        type="dark"
        multiline={true}
      />
    </>
  );
};

export const DeliveryDataPdfFile = ({
  summaryHeader,
  summaryContent,
  parameterData,
  pdfData,
  fileName,
  tooltip,
}) => {
  const handleClickPdf = (e) => {
    e.stopPropagation();

    const doc = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });
    doc.setFontSize(12);

    const params = parameterData.map((li, ind) => {
      if (ind === 0) {
        return Object.values(li)
          .filter((it) => it !== "Parameters: ")
          .join(",");
      }
      return Object.values(li).join(",");
    });

    if (IsArrayNotEmpty(summaryHeader) && IsArrayNotEmpty(summaryContent)) {
      // doc.text(params, 5, 5);
      autoTable(doc, {
        head: [summaryHeader],
        body: summaryContent,
        theme: "grid",
        horizontalPageBreak: true,
        headStyles: {
          fillColor: [0, 81, 148],
          cellWidth: 22,
          fontSize: 10,
          lineColor: [255, 255, 255],
          lineWidth: 0.1,
        },
        columnStyles: {},
        styles: {
          cellPadding: 0.5,
          fontSize: 10,
        },
        /*=====Logo=====*/
        didDrawPage: function () {
          // Header
          doc.text(params, 15, 10);
          doc.setFontSize(8);
          doc.setTextColor(40);
          doc.addImage(base64LogoImg, "PNG", 228, 5, 50, 30);
        },
        margin: { top: 55 },
        /* set column Image*/
        didDrawCell: function (data) {
          if (
            data.column.index === 28 &&
            data.cell.section === "body" &&
            fileName === "BedsData"
          ) {
            imageDisplay(doc, data, "UpDownArrow");
          }
        },
      });
      if (IsArrayNotEmpty(pdfData)) {
        doc.addPage("a4", "landscape");
        // doc.text(params, 10, 10);
        const header = Object.keys(pdfData[0]).map((x) => x.replace("_", " "));
        const content = pdfData.map((x) => {
          const arr = Object.values(x).map((y) => y);
          return arr;
        });
        autoTable(doc, {
          head: [header],
          body: content,
          theme: "grid",
          horizontalPageBreak: true,
          headStyles: {
            fillColor: [0, 81, 148],
            cellWidth: 11.5,
            fontSize: 10,
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
          },
          columnStyles: {
            3: { cellWidth: 20 },
            6: { cellWidth: 16 },
            8: { cellWidth: 17 },
            9: { cellWidth: 17 },
            10: { cellWidth: 15 },
            14: { cellWidth: 15 },
            15: { cellWidth: 15 },
            16: { cellWidth: 15 },
            17: { cellWidth: 15 },
            18: { cellWidth: 15 },
          },
          styles: { cellPadding: 0.5, fontSize: 10 },
          /*=====Logo=====*/
          didDrawPage: function () {
            // Header
            doc.text(params, 15, 10);
            doc.setFontSize(8);
            doc.setTextColor(40);
            doc.addImage(base64LogoImg, "PNG", 228, 5, 50, 30);
            // doc.addImage(base64LogoImg, "PNG", 129, 2, 30, 15);
          },
          margin: { top: 55 },
          /* set column Image*/
          didDrawCell: function (data) {
            if (
              data.column.index === 28 &&
              data.cell.section === "body" &&
              fileName === "BedsData"
            ) {
              imageDisplay(doc, data, "UpDownArrow");
            }
          },
        });
      }
      doc.save(fileName + ".pdf");
    }
  };

  return (
    <>
      <NavLink
        to="#"
        className=" printIconPadding d-inline-block"
        onClick={handleClickPdf}
        data-tip={tooltip ?? "Print"}
        data-for="main"
        data-iscapture="true"
      >
        <img
          src="/assets/images/print_icon.svg"
          className="img-40 me-2"
          alt="Print_Icon"
        />
      </NavLink>
      <ReactTooltip
        id="main"
        place="bottom"
        effect="float"
        type="dark"
        multiline={true}
      />
    </>
  );
};

export const EPURSubmitPDFFile = (pdfData, returnMethod) => {
    const doc = new jsPDF({
      orientation: "landscape",
      format: "a4",
    });
    doc.setFontSize(8);
    if (IsArrayNotEmpty(pdfData)) {
      const header = Object.keys(pdfData[0]).map((x) => x.replace("_", " "));
      const content = pdfData.map((x) => {
        const arr = Object.values(x).map((y) => y);
        return arr;
      });
      autoTable(doc, {
        head: [header],
        body: content,
        theme: "grid",
        horizontalPageBreak: true,
        headStyles: {
          fillColor: [0, 81, 148],
          cellWidth: 10,
          fontSize: 8,
          lineColor: [255, 255, 255],
          lineWidth: 0.1,
        },
        columnStyles: {
          0: { cellWidth: 15 },
          1: { cellWidth: 20 },
          2: { cellWidth: 16 },
          3: { cellWidth: 25 },
          4: { cellWidth: 13 },
          5: { cellWidth: 20 },
          6: { cellWidth: 17 },
          7: { cellWidth: 17 },
          8: { cellWidth: 35 },
          9: { cellWidth: 15 },
          10: { cellWidth: 15 },
          11: { cellWidth: 16 },
          12: { cellWidth: 25 },
          13: { cellWidth: 15 }
        },
        styles: { cellPadding: 0.5, fontSize: 8 },
        /*=====Logo=====*/
        didDrawPage: function () {
          doc.setFontSize(8);
          doc.setTextColor(40);
          doc.addImage(base64LogoImg, "PNG", 129, 2, 30, 15);
        },
        margin: { top: 20 },
      });
      returnMethod(doc);      
    }
};


export default PdfFile;
