import axios from "axios";
import _ from "lodash";
import { CMSUrl, GbsBaseUrl } from "../utils/constants";
import { getCookie, HandleErrorTrackAndToast, IsStringChecker } from "../utils/helper";

/** 
@CreatedBy : Nivethithan Kannan
@Function : GetImpersonateHeaderDetails
@Description : Method that returns data of the user who is going to get impersonated based on Impersonation Access
*/
function GetImpersonateHeaderDetails() {
  try {
    let impersonate_item;
    if(getCookie("selected_one") === 'true')
      impersonate_item = localStorage.getItem("selected_one");
    else
      localStorage.removeItem("selected_one");
    if (IsStringChecker(impersonate_item)) {
      const impersonate_item_json = JSON.parse(impersonate_item);
      if (impersonate_item_json) {
        const { rolePBAllowImpersonation, ImpersonationId } =
          impersonate_item_json;
        if (rolePBAllowImpersonation) {
          if (IsStringChecker(ImpersonationId)) {
            return { ImpersonationId };
          }
          return {};
        }
        return {};
      }
      return {};
    }
    return {};
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

/** 
@CreatedBy : Nivethithan Kannan
@Function : cmsDataCommonMethod
@Description : Common method to GET data from CMS. Parameters in order - token, account id, 
url, method, request data, additional configs
*/
export const cmsDataCommonMethod = async (
  idToken = null,
  accounts = [],
  url = "",
  method = "GET",
  data = {},
  config = { "Content-Type": "application/json" }
) => {
  const localAccountId = _.get(accounts, `0.localAccountId`, "");
  const impersonate_headers = GetImpersonateHeaderDetails();
  return await axios({
    url: `${CMSUrl}${url}`,
    data: data,
    method: method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      UserId: localAccountId,
      ...impersonate_headers,
    },
    ...config,
  });
};

/** 
@CreatedBy : Nivethithan Kannan
@Function : gbsDataCommonMethod
@Description : Common method to GET data from GBS. Parameters in order - token, account id, 
url, method, request data, additional configs
*/
export const gbsDataCommonMethod = async (
  idToken = null,
  accounts = [],
  url = "",
  method = "GET",
  data = {},
  config = { "Content-Type": "application/json" }
) => {
  const localAccountId = _.get(accounts, `0.localAccountId`, "");
  const impersonate_headers = GetImpersonateHeaderDetails();
  return await axios({
    url: `${GbsBaseUrl}${url}`,
    data: data,
    method: method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      UserId: localAccountId,
      ...impersonate_headers,
    },
    ...config,
  });
};
