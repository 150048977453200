import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import React, { useState, useEffect } from "react";
import Comments from "./comments";
import { useMsal } from "@azure/msal-react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import moment from "moment";
import CurrentLocation from "../global/currentLocation";
import {
  IsStringChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import EventTime from "../community/events/eventTime";
import EventLocation from "../community/events/eventLocation";
import { CommentImageUrl } from "../../utils/constants";
import _ from "lodash";
import ImageView from "../global/imageView";
import { NewsContentToModify } from "../global/contentModifier";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const SingleNews = ({
  post,
  setRefresh,
  refresh,
  comments,
  changeSorting,
  newsEvents = false,
  newsSelectedRef = null
}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [, moreLangs] = CurrentLocation();
  const [toggleComment, setToggleComment] = useState(false);
  const [text, setText] = useState("");
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const [loading, setLoading] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const accountId = _.get(accounts, "0.localAccountId", null);
  const mainProfileImage = IsStringChecker(accountId)
    ? `${CommentImageUrl}${accountId}.jpg`
    : `assets/images/profie-img.jpg`;
  
  const alreadySelected = sessionStorage.getItem("selecteddetailpage") ?  JSON.parse(sessionStorage.getItem("selecteddetailpage")) : null;

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const submitComment = async (id) => {
    setLoading(true);
    const body = {
      user_id: accounts[0].localAccountId,
      comment_name: accounts[0].name,
      comment: text,
    };
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `news-event-comments/` + id,
      "POST",
      body
    )
      .then(() => {
        setRefresh(refresh + 1);
        setText("");
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      })
      .finally(() => setLoading(false));
  };

  const clickOnLikePost = async (pst) => {
    const body = {
      user_id: accounts[0].localAccountId,
      like_name: accounts[0].name,
      news_events_id: pst.id,
    };
    if (pst?.news_event_comment_likes?.filter(i=>i.like_name == accounts[0].name).length === 0) {
      // setIsLiked(true);
      setLikeLoading(true);
      await cmsDataCommonMethod(
        idToken,
        accounts,
        `news-events/${pst.id}/like`,
        "POST",
        body
      )
        .then(() => {
          // setIsLiked(true);
          setRefresh(refresh + 1);          
        })
        .catch((e) => {
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLikeLoading(false);
          // setIsLiked(true);
        });
    } else {
      // setIsLiked(false);
      setLikeLoading(true);      
      let like_id = 0;
      for (let i = 0; i < post.news_event_comment_likes.length; i++) {
        if (post.news_event_comment_likes[i].like_name == accounts[0].name) {
          like_id = post.news_event_comment_likes[i].id;
          break;
        }
      }
      await cmsDataCommonMethod(
        idToken,
        accounts,
        `news-event-comment-likes/${like_id}?userid=` +
          accounts[0].localAccountId,
        "DELETE",
        body
      )
        .then(() => {
          // setIsLiked(false);
          setRefresh(refresh + 1);         
        })
        .catch((e) => {
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLikeLoading(false);
          // setIsLiked(false);
        });
    }
  };

  const desc = _.get(post, `description_${i18n.resolvedLanguage}`, "");
  const newsLink = `${
    newsEvents ? "/community/news-events/" : "/community/news/"
  }${moment(post.posting_date).format("YYYY/MM/DD")}/${post.id}/${post.slug}`;
  const readMore = moreLangs[i18n.language]?.home_page?.read_more;
  return post.event_date_from == null ? (
    <div className="card bot-mspace-20 card-shadow news_block font-reset" ref={alreadySelected?.id === post.id ? newsSelectedRef : null}>
      <div className="card-body">
        <div className="row mb-2">
          <div className="col-8 ">
            {/* update design for ticket 49346,49346 */}
            <div className="d-flex align-items-center justify-content-lg-start pe-0 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none">
              <img
                src={
                  post.authors.author_image_url === "image-url-here" ||
                  post.authors.author_image_url === "" ||
                  post.authors.author_image_url === null
                    ? `${window.location.origin}/assets/images/os-avatar.jpg`
                    : post.authors.author_image_url
                }
                alt="chamber_person"
                className="card-img-top border-radius-50 news_profile_img"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = `../../assets/images/os-avatar.jpg`;
                }}
              />
              <h5 className="mt-sm-3">
                <span className="d-block fw-bold ms-3 mb-1">
                  {post.authors["title_" + i18n.resolvedLanguage]}
                </span> 
                {/* <span className="d-block fw-bold ms-3 mb-1">
                  {post.authors["title_" + i18n.resolvedLanguage]}
                </span> */}
                <span className="d-block ms-3">{post.authors.job_title}</span>
              </h5>
            </div>
          </div>
          <div className="col-4">
            <p className="float-end">
              <Moment local utc format="MM.DD.YY">{post.posting_date}</Moment>
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className={
              post.post_image === "" || post.post_image === null
                ? "col-xl-3 right-space-0 d-none order-lg-first order-last mt-3 mt-lg-0"
                : "col-xl-3 right-space-0 order-lg-first order-last mt-3 mt-lg-0"
            }
          >
            {post.post_image !== "" && post.post_image !== null && (
              <ImageView
                url={post.post_image}
                setAutoPlay={true}
                component="plain-img"
                width={"100%"}
                height={"auto"}
              />
            )}
            {/* <img
              src={
                post.post_image === "" || post.post_image === null
                  ? `${window.location.origin}assets/images/os-avatar.jpg`
                  : post.post_image
              }
              className="card-img-top"
              alt="upcoming_news"
            /> */}
          </div>
          <div
            className={
              post.post_image === "" || post.post_image === null
                ? "col-xl-9 width-100 news-description News-Tile-Videos News-Tile-HTML-Ellipsis"
                : "col-xl-9 news-description News-Tile-Videos News-Tile-HTML-Ellipsis desktop-news-padding"
            }
          >
            <h5 className="fw-bold mt-0">
              <Link to={newsLink} onClick={()=>sessionStorage.setItem("selecteddetailpage", JSON.stringify(post))} className="color-black"> {post["title_" + i18n.resolvedLanguage]}</Link>
            </h5>
            <NewsContentToModify
              str={desc}
              allVideos={false}
              videoCount={2}
              newsLink={newsLink}
              readMore={readMore}
              post = {post}
            />
          </div>
        </div>
        <div className="row mb-2 mt-2 mb-lg-0">
          <div className="col-12">
            {post.show_comments ? (
              <button
                type="button"
                className="btn btn-sm btn-light float-end hor-space-20"
                id="comment_btn"
                onClick={() => setToggleComment(!toggleComment)}
              >
                <i className="fa fa-comment" aria-hidden="true"></i>{" "}
                <span className="comment-count">
                  {
                    post.news_event_comments.filter(
                      (com) => com.comments_id == null
                    ).length
                  }
                </span>{" "}
                {moreLangs[i18n.language]?.home_page?.comments}
              </button>
            ) : null}

            {post.show_comments ? (
              <button
                disabled={likeLoading}
                onClick={() => clickOnLikePost(post)}
                type="button"
                className="btn btn-sm btn-light float-end space_left_right margin-right-10"
              >
                {likeLoading ? (
                  <img  src="/assets/images/red-loader-small.svg" height={20} width={20}/>
                ) : (
                  <>
                    {" "}
                    {post?.news_event_comment_likes?.filter(i=>i.like_name == accounts[0].name).length === 0 ? (
                      <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                    ) : (
                      <i
                        className="fa fa-thumbs-up"
                        aria-hidden="true"
                        style={{ color: "blue" }}
                      ></i>
                    )}
                    <span className="like-count">{post.news_event_comment_likes.length}</span>
                    {post.news_event_comment_likes.length <= 1 ? moreLangs[i18n.language]?.home_page?.like : moreLangs[i18n.language]?.home_page?.likes}
                  </>
                )}
              </button>
            ) : null}
          </div>
        </div>

        <div
          key={post.id}
          id="comment_detail_section"
          className={toggleComment ? "active" : ""}
        >
          <div className="row">
            <div className="col-12">
              <div className="width-100 float-start mt-4">
                <div className="float-end">
                  <select
                    onChange={(value) => {
                      setSelectedOrder(value.target.value);
                      changeSorting(value.target.value);
                    }}
                    className="form-select text-14 filter_text"
                    aria-label="Default select example"
                    value={selectedOrder}
                  >
                    <option value="0">
                      {moreLangs[i18n.language]?.home_page?.latest}
                    </option>
                    <option value="1">
                      {moreLangs[i18n.language]?.home_page?.oldest}
                    </option>
                  </select>
                </div>
                <span className="fw-bold float-end p-2">
                  {moreLangs[i18n.language]?.home_page?.sort_by}:{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-1">
            <div className="col-2 col-sm-1">
              <img
                src={mainProfileImage}
                alt="comment_profile_img"
                className="card-img-top border-radius-50 comment_profile_img"
              />
            </div>
            <div className="col-10 col-sm-11">
              <textarea
                className="form-control news_area"
                id="Textarea1"
                rows="3"
                placeholder={
                  moreLangs[i18n.language]?.home_page?.enter_comments_here
                }
                value={text}
                onChange={(val) => setText(val.target.value)}
              ></textarea>
              <button
                type="submit"
                className={"btn btn-primary float-end mt-2"}
                id="comment_submit"
                onClick={() => submitComment(post.id)}
                style={{ marginRight: "4px" }}
              >
                {loading ? (
                  <>
                    {moreLangs[i18n.language]?.home_page?.submit}
                    <img
                      src={`${window.location.origin}/assets/images/white-loader-small.svg`}
                      alt=""
                      height={20}
                      width={20}
                      style={{ color: "white" }}
                    />
                  </>
                ) : (
                  moreLangs[i18n.language]?.home_page?.submit
                )}
              </button>
            </div>
          </div>

          <div className="row">
            {comments
              .filter((com) => com.comments_id === null)
              .slice(0, 5 * page)
              .map((comment, index) => (
                comment.is_active &&
                <Comments
                  key={index}
                  comment={comment}
                  postId={post.id}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              ))}
          </div>

          {comments.filter((com) => com.comments_id === null).length > 5 &&
          loadMore ? (
            <div className="row">
              <div className="col-12 px-0">
                <button
                  type="submit"
                  className="btn btn-primary mt-3 width-100"
                  id="comment_submit"
                  onClick={() => {
                    setPage(page + 1);
                    const total_pages = Math.floor(
                      comments.filter((com) => com.comments_id === null)
                        .length / 5
                    );
                    if (page >= total_pages) {
                      setLoadMore(false);
                    }
                  }}
                >
                  {moreLangs[i18n.language]?.home_page?.load_more}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <div className="card bot-mspace-20 card-shadow news_block  mt-lg-0">
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-10 col-sm-8">
            <div className="d-flex align-items-center justify-content-lg-start pe-0 pe-lg-4 mb-4 mb-lg-0 float-start float-lg-none">
              <img
                src={
                  post.authors.author_image_url === "image-url-here" ||
                  post.authors.author_image_url === "" ||
                  post.authors.author_image_url === null
                    ? `${window.location.origin}/assets/images/os-avatar.jpg`
                    : post.authors.author_image_url
                }
                alt="chamber_person"
                className="card-img-top border-radius-50 news_profile_img float-start"
              />
              <h5 className="float-start ps-3 d-block print_font">
                <span className="fw-bold">
                  {post.authors["title_" + i18n.resolvedLanguage]}
                </span>
                <span className="d-block pt-1 text-14">
                  {post.authors.job_title}
                </span>{" "}
              </h5>
            </div>
          </div>
          <div className="col-2 col-sm-4">
            <p className="float-end">
              {" "}
              <Moment local utc format="MM.DD.YY">{post.posting_date}</Moment>
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className={
              post.post_image === "" || post.post_image === null
                ? "col-xl-3 right-space-0 d-none order-lg-first order-last"
                : "col-xl-3 right-space-0 order-lg-first order-last"
            }
          >
            <img
              src={
                post.post_image === "" || post.post_image === null
                  ? `${window.location.origin}assets/images/os-avatar.jpg`
                  : post.post_image
              }
              className="card-img-top mb-3 mt-1"
              alt="upcoming_news"
            />
          </div>
          <div
            className={
              post.post_image === "" || post.post_image === null
                ? "col-xl-9 width-100 news-description"
                : "col-xl-9 news-description"
            }
          >
            <div className="p-2 ps-3 pt-0">
              <h5 className="fw-bold">
                <Link to={newsLink} onClick={()=>sessionStorage.setItem("selecteddetailpage", JSON.stringify(post))} className="color-black">{post["title_" + i18n.resolvedLanguage]}</Link>
              </h5>
              {/* <p className="mb-0">Cranberry Festival 2019</p> */}
              <div className="upcoming_location ">
                {
                  (post["event_date_from"] !== null && post["event_date_to"] !== null) && <>
                    <i className="fa fa-clock-o marker_icon"></i>
                    <p>
                      <EventTime
                        event_date_from={post["event_date_from"]}
                        event_date_to={post["event_date_to"]}
                        locale={true}
                        timezone={true}
                      />
                    </p>
                  </>
                }

              </div>
              <div className="upcoming_location">
                {
                  post?.event_location !== null && post?.event_location?.replace(/(<([^>]+)>)/ig, '') !== '' && <>
                    <i className="fa fa-map-marker marker_icon ms-1"></i>
                    {IsStringChecker(post["event_location"]) && (
                      <EventLocation event_location={post["event_location"]} />
                    )}
                  </>}
              </div>
              <NewsContentToModify
              str={desc}
              allVideos={false}
              videoCount={2}
              newsLink={newsLink}
              readMore={readMore}
              post = {post}
            />
              {/* <Link
                to={`${newsEvents ? "/community/news-events/" : "/community/events/"
                  }${moment(post.posting_date).format("YYYY/MM/DD")}/${post.id}/${post.slug
                  }`}
                className="space-10 cursor_pointer read_more_click"
              >
                View details
              </Link> */}
              {/* <a
                onClick={() =>
                  window.open(
                    `${window.location.origin}/community/news-events/${moment(
                      post.posting_date
                    ).format("YYYY/MM/DD")}/${post.id}/${post.slug}`
                  )
                }
                className="space-10 cursor_pointer read_more_click"
              >
                View details
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleNews;
