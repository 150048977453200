import React, { useState, useEffect, Fragment } from "react";
import { Accordion } from "react-bootstrap";
import Events from "../../home/siteNotification";
import {
  cmsDataCommonMethod,
  gbsDataCommonMethod,
} from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import News from "../../home/news";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  sortByField,
  IsArrayNotEmpty,
  compareString,
  IsStringChecker,
  lowStr
} from "../../../utils/helper.js";
import WebCams from "../../global/webCams.js";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper.js";
import { EasternCanadaRegionConsolidation } from "../../../utils/constants.js";
import _ from "lodash";

const Main = (props) => {
  const { slugs: ec_slugs = [], slug: ec_slug } =
    EasternCanadaRegionConsolidation;
  const { RoleCBPrivileges = {}, SpecialRoleExist, DropDownCBRoles } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const idToken = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [preCategories, setPreCategories] = useState(null);

  function RoleBasedAccessDS(special_role, priviledges) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService = false } = priviledges;
        return roleCBThirdPartyFarmService;
      } else {
        return false;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function RoleBasedAccessNDS(special_role, priviledges) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService = false } = priviledges;
        return !roleCBThirdPartyFarmService;
      } else {
        return true;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function RegionArrayCreator(region_arr) {
    try {
      const temp = region_arr.map((it) => {
        const { RegionDesc } = it;
        const region = lowStr(RegionDesc).replace(/ /g, "-");
        const found = ec_slugs.some((it) => compareString(it, region));
        return found ? ec_slug : region;
      });
      return _.uniq(temp.filter((li) => IsStringChecker(li)));
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const fetchContract = async (region_arr) => {
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `persons/${personId}/contracts`
    )
      .then((resp) => {
        const resultData = safeArrayCheck(resp?.data);
        const filteredData = IsArrayNotEmpty(resultData)
          ? FilterContractByRole(resultData, accounts, DropDownCBRoles)
          : [];
        // console.log({ filteredData });
        if (IsArrayNotEmpty(filteredData)) {
          const region_filter_arr = [
            "harvest-news",
            ...RegionArrayCreator(filteredData),
          ];
          // console.log({ region_filter_arr });
          if (IsArrayNotEmpty(region_filter_arr)) {
            const ec_region = region_arr.find((li) =>
              compareString(li?.slug, ec_slug)
            );
            let filtered_categories = [];
            if (ec_region) {
              const altered_regions = region_arr.map((li) => {
                return ec_slugs.some((it) => compareString(li?.slug, it))
                  ? ec_region
                  : li;
              });
              filtered_categories = CategoriesFilter(
                _.uniqBy(altered_regions, "slug"),
                region_filter_arr
              );
              
            } else {
              const altered_regions = region_arr.filter((li) => {
                return !ec_slugs.some((it) => compareString(li?.slug, it));
              });
              filtered_categories = CategoriesFilter(
                altered_regions,
                region_filter_arr
              );
            }
            if(newscategory !== null){
              if(filtered_categories.filter(i=>i.slug === newscategory).length > 0){
                sessionStorage.setItem("newscategory", newscategory);
              }
              else{
                sessionStorage.setItem("newscategory", 'harvest-news');
              }
            }
            setPreCategories(filtered_categories);
          } else {
            setPreCategories(null);
          }
        } else {
          setPreCategories(null);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setPreCategories(null);
      });
  };

  function CategoriesFilter(catagories, allowed_categories) {
    try {
      if (IsArrayNotEmpty(allowed_categories)) {
        return catagories.filter((li) => {
          return allowed_categories.some((it) => compareString(it, li?.slug));
        });
      }
      return catagories;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const display_section = RoleBasedAccessNDS(
    SpecialRoleExist,
    RoleCBPrivileges
  );

  const getCategories = async () => {
    var url = `news_event_categories?is_harvest_news=eq.true&order=title_en`;
    await cmsDataCommonMethod(idToken, msal_accounts, url)
      .then((resp) => {
        let tempCategories = safeArrayCheck(
          sortByField(_.get(resp, "data.data", []))
        );
        fetchContract(tempCategories);
      })
      .catch((e) => {
        setPreCategories(null);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const [harvest, setHarvest] = useState([]);
  const harvestUpdate = async () => {
    await cmsDataCommonMethod(
      idToken,
      msal_accounts,
      "call_to_actions?select=*,page_listings!inner(slug)&page_listings.slug=eq.regional-harvest-updates&order=order_by.asc"
    )
      .then((resp) => {
        setHarvest(resp.data.data);
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  
  // removing news sesstion
  if(sessionStorage.getItem("newsfrom") !== "harvest"){
    sessionStorage.setItem("newsfrom","harvest");
    sessionStorage.removeItem("newslimit");
    sessionStorage.removeItem("newscategory");
    sessionStorage.removeItem("newsloadmorelimit");
    sessionStorage.removeItem("selecteddetailpage");
  }

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      harvestUpdate();
      const access = RoleBasedAccessDS(SpecialRoleExist, RoleCBPrivileges);
      if (access) {
        getCategories();
      }
    }
  }, [idToken, SpecialRoleExist, RoleCBPrivileges]);
  return (
    <div className="col-12 ">
      <div className="mt-5 mb-3 mt-lg-0 mb-lg-4">
        <Events {...props} type={"my-harvest/harvest-news"} />
      </div>

      <Accordion className="mt-0 harvest_news">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <img
              src="../assets/images/icon-harvest-data-black.svg"
              className="img-35 right-space-10"
              alt="Contracts_icon"
            />
            Harvest News
          </Accordion.Header>

          <Accordion.Body>
            <News
              {...props}
              selectedNews={"harvest-news"}
              newsFor={"harvest-news"}
              preCategories={preCategories}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {display_section && (
        <Accordion className="harvest_acc">
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <img
                src="../assets/images/icon_recent_delivery.svg"
                className="img-30 right-space-10"
                alt="Contracts_icon"
              />
              Regional Harvest Updates
            </Accordion.Header>

            <Accordion.Body>
              <h5>
                The following updates provides year-to-date trends for barrel
                deliveries, fruit quality and yield. If you would like to
                comment or have questions about this information, please email
                Adam Korejwa at akorejwa@oceanspray.com or call (508-923-3777).
              </h5>
              <div className="region_list regional_harvest_updates">
                <div className="row">
                  {harvest.length > 0
                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8].map((update, index) => {
                        // console.log(update > harvest.length - 1);
                        return update > harvest.length - 1 ? (
                          <Fragment key={index}>
                            <div className="col-12 col-lg-4">
                              <div
                                className="card bot-mspace-20 card-shadow"
                                style={{ height: "100%" }}
                              >
                                <div className="card-body pt-0 min-height-180"></div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment key={index}>
                            <div className="col-12 col-lg-4 mt-5 mt-lg-0">
                              <div className="position-relative block_height">
                                <div className="position-relative block_height">
                                  <a
                                    rel="noreferrer"
                                    target={"_blank"}
                                    href={harvest[index].url}
                                    className="d-block"
                                  >
                                    <img
                                      src={harvest[index].feature_image_url}
                                      className="img-fluid border-radius-6"
                                    />
                                    <div className="position-absolute outer-grey-bg"></div>
                                    <h4 className="position-absolute white_color fw-bold">
                                      {
                                        harvest[index][
                                          "title_" + i18n.resolvedLanguage
                                        ]
                                      }
                                    </h4>
                                  </a>
                                </div>
                                <div className="position-absolute bottom-0 end-0">
                                  <a
                                    rel="noreferrer"
                                    target={"_blank"}
                                    href={harvest[index].url}
                                    className="white_bg red_color m-1 px-2 py-1 border-radius-6 d-block"
                                  >
                                    <i
                                      className="fa fa-file-pdf-o me-1"
                                      aria-hidden="true"
                                    ></i>
                                    PDF
                                  </a>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        );
                      })
                    : null}
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <Accordion className="harvest_acc mb-5">
        <WebCams {...props} />
      </Accordion>
    </div>
  );
};

export default Main;
