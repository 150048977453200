import React from "react";
import PageLayout from "../../pageLayout";
import OSContractsDetail from "../../osContact/osContactsDetail";

const MyOSContactDetail = (props) => {
  return (
    <PageLayout {...props} body={<OSContractsDetail {...props} showRegion={true} />} />
  );
};

export default MyOSContactDetail;
