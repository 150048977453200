const getAdJwtToken = (data) => {
  return {
    type: "GET_AD_JWTTOKEN",
    payload: data,
  };
};

export default getAdJwtToken;

export const activeCropYear = (data) => {
  return {
    type: "ACTIVE_CROP_YEAR",
    payload: data,
  };
};

export const ReplaceToastMsg = (data) => {
  return {
    type: "REPLACE_TOAST_MSG",
    payload: data,
  };
};

export const SendToastMsg = (data) => {
  return {
    type: "SEND_TOAST_MSG",
    payload: data,
  };
};

export const DeleteToastMsg = (data) => {
  return {
    type: "DELETE_TOAST_MSG",
    payload: data,
  };
};

export const EmptyToastMsg = () => {
  return {
    type: "EMPTY_TOAST_MSG",
  };
};
