/* eslint-disable */
/**
 * @component_Name : PaymentEstimatorDetail
 * @description : Payment Estimator
 * @company : Photon
 * @author : Nivethithan Kannan/02-01-2024
 **/

import React, { useState, Fragment, useEffect } from "react";
import _ from "lodash";
import {
  IsArrayNotEmpty,
  IsStringChecker,
  HandleErrorTrackAndToast,
  compareString,
  IsNumberChecker,
} from "../../../../utils/helper";
import {
  CommaNumberToNormal,
  IsNumber,
  WholeRateValidation,
  MinRateValidation,
  NumberFormaterWithValidation,
  Digit4NumberFormaterWithValidation,
  BarrelsNumberFormater,
  KeyDownHandler,
  NineMillionLimit,
  OneMillionLimit,
  HundredLimit,
  OnChangeDecimalWrapper,
} from "./paymentEstimatorFunctionsCommon";
import { Form, Accordion, InputGroup, Button, Alert } from "react-bootstrap";
const PaymentEstimatorAREStockRetain = (props) => {
  const {
    enable_calculate_section = null,
    SectionHandler,
    dispatchInputComponentMapping,
    inputComponentArr = [],
    are_stock_preload = [],
    EmptyAREStockPreloadData,
  } = props;
  // console.log({ previous_barrel_data });
  // console.log({ inputComponentArr });

  const inital_tab = GetInitialTab(inputComponentArr);
  const [selectedTab, setSelectedTab] = useState(inital_tab);
  const changeTab = (index) => {
    setSelectedTab(index);
  };

  function GetInitialTab(arr) {
    try {
      const year = _.get(arr, "0.cropyear", "");
      return year || "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  }

  const max_limit_err_msg = `A valid number [0 - 9,000,000] is required`;
  const percent_limit_err_msg = "A valid number [0 - 100] is required";
  const lakh_limit_err_msg = "A valid number [0 - 1,000,000] is required";
  const lakh_min_limit_err_msg = "A valid number > 0 is required";

  const common_barrels_delivered_name = "common_barrels_delivered";
  const are_rate_name = "are_rate";
  const are_issuance_name = "are_issuance";
  const are_redemption_name = "are_redemption";
  const average_deliveries_name = "average_deliveries";
  const quota_rate_name = "quota_rate";
  const par_value_name = "par_value";
  const quota_shares_name = "quota_shares";
  const common_stock_shares_held_name = "common_stock_shares_held";
  const percent_to_quota_name = "percent_to_quota";
  const retain_rate_name = "retain_rate";
  const retain_dollars_name = "retain_dollars";
  const potential_retain_shares_name = "potential_retain_shares";
  const shares_under_quota_name = "shares_under_quota";
  const shares_under_quota_value_name = "shares_under_quota_value";
  const retain_shares_to_be_issued_name = "retain_shares_to_be_issued";
  const retain_shares_to_be_issued_value_name =
    "retain_shares_to_be_issued_value";
  const cash_in_lieu_of_stock_name = "cash_in_lieu_of_stock";
  const percent_list = [
    quota_rate_name,
    par_value_name,
    percent_to_quota_name,
    retain_rate_name,
  ];
  const lakh_list = [shares_under_quota_name, retain_shares_to_be_issued_name];
  const info_box = `
Clicking the "Copy To" feature buttons will automatically transfer the values of the following fields to the corresponding sections for subsequent crop years: ARE Rate, Quota Rate, Par Value, Common Stock Shares Held, and Retain Rate. Additionally, the remaining field values will be automatically calculated based on these inputs`;

  const [are_stock_error_msg, set_are_stock_error_msg] = useState("");

  function GetCurrentTabData(arr = [], parent) {
    try {
      return arr.find((li) => li.key == parent) || {};
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {};
    }
  }

  function onAREChange(e, parent) {
    const { name, value } = OnChangeDecimalWrapper(e);
    AREChangeHandler(parent, name, value);
  }

  function onStockRetainChange(e, parent) {
    const { name, value } = OnChangeDecimalWrapper(e);
    StockRetainChangeHandler(parent, name, value, "event");
  }

  function AREChangeHandler(parent, name, value) {
    const num = compareString("are_rate", name)
      ? Digit4NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          0,
          HundredLimit
        )
      : NumberFormaterWithValidation(
          value,
          compareString("are_issuance", name)
            ? MinRateValidation
            : WholeRateValidation,
          0,
          OneMillionLimit
        );
    const error = IsNumber(
      num,
      true,
      compareString("are_issuance", name) ? false : true,
      true,
      0,
      compareString("are_rate", name) ? HundredLimit : OneMillionLimit
    )
      ? false
      : true;

    if (compareString("are_rate", name)) {
      const current_data = GetCurrentTabData(inputComponentArr, parent);
      const common_barrels_delivered = _.get(
        current_data,
        "common_data.common_barrels_delivered",
        ""
      );
      const new_num =
        CommaNumberToNormal(common_barrels_delivered) * parseFloat(num);
      const new_num_val = NumberFormaterWithValidation(
        `${new_num}`,
        MinRateValidation,
        0,
        OneMillionLimit
      );
      const new_num_error = IsNumber(
        `${new_num_val}`,
        true,
        false,
        true,
        0,
        OneMillionLimit
      )
        ? false
        : true;
      dispatchInputComponentMapping({
        type: "AREChange",
        payload: {
          parent,
          data_list: [
            {
              name,
              value: num,
              error,
            },
            {
              name: "are_issuance",
              value: new_num_val,
              error: new_num_error,
            },
          ],
        },
      });
    } else {
      dispatchInputComponentMapping({
        type: "AREChange",
        payload: {
          parent,
          data_list: [
            {
              name,
              value: num,
              error,
            },
          ],
        },
      });
    }
  }

  function StockRetainChangeHandler(parent, name, value) {
    const percent_access = percent_list.some((li) => compareString(li, name));
    const lakh_access = lakh_list.some((li) => compareString(li, name));
    const max_limit = percent_access
      ? HundredLimit
      : lakh_access
      ? OneMillionLimit
      : NineMillionLimit;
    const num = compareString(name, retain_rate_name)
      ? Digit4NumberFormaterWithValidation(
          value,
          WholeRateValidation,
          0,
          max_limit
        )
      : NumberFormaterWithValidation(value, WholeRateValidation, 0, max_limit);
    const error = IsNumber(num, true, true, true, 0, max_limit) ? false : true;

    if (error) {
      dispatchInputComponentMapping({
        type: "StockRetainChange",
        payload: {
          parent,
          data_list: [{ name, value: num, error }],
        },
      });
    } else {
      const common_data =
        GetCurrentTabData(inputComponentArr, parent)?.common_data || {};
      const current_data =
        GetCurrentTabData(inputComponentArr, parent)?.stock_retain_data || {};
      const state = { ...current_data };

      state[name] = num;
      state[`${name}_error`] = error;

      const data_list = [];
      data_list.push({ name, value: num, error });
      StockRetainMapper(data_list, state, common_data, name);

      dispatchInputComponentMapping({
        type: "StockRetainChange",
        payload: {
          parent,
          data_list,
        },
      });
    }
  }

  function StockRetainMapper(data_list, state, common_data, name) {
    try {
      const {
        average_deliveries = "",
        quota_rate = "",
        par_value = "",
        quota_shares = "",
        common_stock_shares_held = "",
        retain_rate = "",
        retain_dollars = "",
        potential_retain_shares = "",
        shares_under_quota = "",
        retain_shares_to_be_issued = "",
        retain_shares_to_be_issued_value = "",
      } = state;

      if (
        compareString(name, average_deliveries_name) ||
        compareString(name, quota_rate_name) ||
        compareString(name, par_value_name)
      ) {
        StockRetainCalculatorLevel1(
          data_list,
          common_data,
          average_deliveries,
          quota_rate,
          par_value,
          common_stock_shares_held,
          retain_rate
        );
      } else if (
        compareString(name, quota_shares_name) ||
        compareString(name, common_stock_shares_held_name)
      ) {
        return StockRetainCalculatorLevel2(
          data_list,
          common_data,
          par_value,
          quota_shares,
          common_stock_shares_held,
          retain_rate
        );
      } else if (compareString(name, retain_rate_name)) {
        StockRetainCalculatorLevel3(
          data_list,
          common_data,
          par_value,
          retain_rate,
          shares_under_quota
        );
      } else if (
        compareString(name, retain_dollars_name) ||
        compareString(name, potential_retain_shares_name) ||
        compareString(name, shares_under_quota_name)
      ) {
        return StockRetainCalculatorLevel4(
          data_list,
          par_value,
          retain_dollars,
          potential_retain_shares,
          shares_under_quota
        );
      } else if (compareString(name, retain_shares_to_be_issued_name)) {
        return StockRetainCalculatorLevel5(
          data_list,
          par_value,
          retain_dollars,
          retain_shares_to_be_issued
        );
      } else if (compareString(name, retain_shares_to_be_issued_value_name)) {
        return StockRetainCalculatorLevel6(
          data_list,
          par_value,
          retain_dollars,
          retain_shares_to_be_issued_value
        );
      } else {
        return state;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  function StockRetainCalculatorLevel1(
    data_list,
    common_data,
    average_deliveries,
    quota_rate,
    par_value,
    common_stock_shares_held,
    retain_rate
  ) {
    try {
      const n_average_deliveries = CommaNumberToNormal(average_deliveries),
        n_quota_rate = parseFloat(quota_rate),
        n_par_value = parseFloat(par_value);
      if (
        IsNumberChecker(n_average_deliveries) &&
        IsNumberChecker(n_quota_rate) &&
        IsNumberChecker(n_par_value)
      ) {
        const n_quota_shares = Math.round(
          (n_average_deliveries * n_quota_rate) / n_par_value
        );
        const t_quota_shares = WholeRateValidation(
          n_quota_shares,
          0,
          NineMillionLimit
        );
        const e_quota_shares = IsNumber(
          t_quota_shares,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: quota_shares_name,
          value: t_quota_shares,
          error: e_quota_shares,
        });

        if (!e_quota_shares) {
          return StockRetainCalculatorLevel2(
            data_list,
            common_data,
            par_value,
            n_quota_shares,
            common_stock_shares_held,
            retain_rate
          );
        }
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel2(
    data_list,
    common_data,
    par_value,
    quota_shares,
    common_stock_shares_held,
    retain_rate
  ) {
    try {
      const n_par_value = parseFloat(par_value);
      const n_quota_shares = parseFloat(quota_shares);
      const n_common_stock_shares_held = CommaNumberToNormal(
        common_stock_shares_held
      );
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_quota_shares) &&
        IsNumberChecker(n_common_stock_shares_held)
      ) {
        const n_percent_to_quota =
          (n_common_stock_shares_held / n_quota_shares) * 100;
        const t_percent_to_quota = NumberFormaterWithValidation(
          `${n_percent_to_quota}`,
          WholeRateValidation,
          0,
          HundredLimit
        );
        const e_percent_to_quota = IsNumber(
          t_percent_to_quota,
          true,
          true,
          true,
          0,
          HundredLimit
        )
          ? false
          : true;

        const n_shares_under_quota =
          n_quota_shares > n_common_stock_shares_held
            ? n_quota_shares - n_common_stock_shares_held
            : 0;
        const t_shares_under_quota = NumberFormaterWithValidation(
          `${n_shares_under_quota}`,
          WholeRateValidation,
          0,
          OneMillionLimit
        );
        const e_shares_under_quota = IsNumber(
          t_shares_under_quota,
          true,
          true,
          true,
          0,
          OneMillionLimit
        )
          ? false
          : true;

        const n_shares_under_quota_value = n_shares_under_quota * n_par_value;
        const t_shares_under_quota_value = NumberFormaterWithValidation(
          `${n_shares_under_quota_value}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const e_shares_under_quota_value = IsNumber(
          t_shares_under_quota_value,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: percent_to_quota_name,
          value: t_percent_to_quota,
          error: e_percent_to_quota,
        });
        data_list.push({
          name: shares_under_quota_name,
          value: t_shares_under_quota,
          error: e_shares_under_quota,
        });
        data_list.push({
          name: shares_under_quota_value_name,
          value: t_shares_under_quota_value,
          error: e_shares_under_quota_value,
        });

        if (!e_shares_under_quota) {
          StockRetainCalculatorLevel3(
            data_list,
            common_data,
            par_value,
            retain_rate,
            n_shares_under_quota
          );
        }
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel3(
    data_list,
    common_data,
    par_value,
    retain_rate,
    shares_under_quota
  ) {
    try {
      const n_barrels_delivered = CommaNumberToNormal(
          common_data?.common_barrels_delivered
        ),
        n_par_value = parseFloat(par_value),
        n_retain_rate = parseFloat(retain_rate),
        n_shares_under_quota = parseFloat(shares_under_quota);
      if (
        IsNumberChecker(n_barrels_delivered) &&
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_rate) &&
        IsNumberChecker(n_shares_under_quota)
      ) {
        const n_retain_dollars = n_barrels_delivered * n_retain_rate;
        const n_potential_retain_shares = Math.round(
          n_retain_dollars / n_par_value
        );
        const n_retain_shares_to_be_issued =
          n_shares_under_quota < n_potential_retain_shares
            ? n_shares_under_quota
            : n_potential_retain_shares;
        const n_retain_shares_to_be_issued_value =
          n_retain_shares_to_be_issued * n_par_value;
        const n_cash_in_lieu_of_stock =
          n_retain_dollars - n_retain_shares_to_be_issued_value;

        const t_retain_dollars = NumberFormaterWithValidation(
          `${n_retain_dollars}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const t_potential_retain_shares = WholeRateValidation(
          n_potential_retain_shares,
          0,
          NineMillionLimit
        );
        const e_retain_dollars = IsNumber(
          t_retain_dollars,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const e_potential_retain_shares = IsNumber(
          t_potential_retain_shares,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued}`,
          WholeRateValidation,
          0,
          OneMillionLimit
        );
        const e_retain_shares_to_be_issued = IsNumber(
          t_retain_shares_to_be_issued,
          true,
          true,
          true,
          0,
          OneMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued_value = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued_value}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const e_retain_shares_to_be_issued_value = IsNumber(
          t_retain_shares_to_be_issued_value,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const t_cash_in_lieu_of_stock = NumberFormaterWithValidation(
          `${n_cash_in_lieu_of_stock}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const e_cash_in_lieu_of_stock = IsNumber(
          t_cash_in_lieu_of_stock,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: retain_dollars_name,
          value: t_retain_dollars,
          error: e_retain_dollars,
        });
        data_list.push({
          name: potential_retain_shares_name,
          value: t_potential_retain_shares,
          error: e_potential_retain_shares,
        });
        data_list.push({
          name: retain_shares_to_be_issued_name,
          value: t_retain_shares_to_be_issued,
          error: e_retain_shares_to_be_issued,
        });
        data_list.push({
          name: retain_shares_to_be_issued_value_name,
          value: t_retain_shares_to_be_issued_value,
          error: e_retain_shares_to_be_issued_value,
        });
        data_list.push({
          name: cash_in_lieu_of_stock_name,
          value: t_cash_in_lieu_of_stock,
          error: e_cash_in_lieu_of_stock,
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel4(
    data_list,
    par_value,
    retain_dollars,
    potential_retain_shares,
    shares_under_quota
  ) {
    try {
      const n_par_value = parseFloat(par_value),
        n_retain_dollars = parseFloat(retain_dollars),
        n_potential_retain_shares = parseFloat(potential_retain_shares),
        n_shares_under_quota = parseFloat(shares_under_quota);
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_dollars) &&
        IsNumberChecker(n_potential_retain_shares) &&
        IsNumberChecker(n_shares_under_quota)
      ) {
        const n_retain_shares_to_be_issued =
          n_shares_under_quota < n_potential_retain_shares
            ? n_shares_under_quota
            : n_potential_retain_shares;
        const n_retain_shares_to_be_issued_value =
          n_retain_shares_to_be_issued * n_par_value;
        const n_cash_in_lieu_of_stock =
          n_retain_dollars - n_retain_shares_to_be_issued_value;

        const t_shares_under_quota = NumberFormaterWithValidation(
          `${n_shares_under_quota}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const t_retain_dollars = NumberFormaterWithValidation(
          `${n_retain_dollars}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const t_potential_retain_shares = WholeRateValidation(
          n_potential_retain_shares,
          0,
          NineMillionLimit
        );

        const e_shares_under_quota = IsNumber(
          t_shares_under_quota,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const e_retain_dollars = IsNumber(
          t_retain_dollars,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const e_potential_retain_shares = IsNumber(
          t_potential_retain_shares,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued}`,
          WholeRateValidation,
          0,
          OneMillionLimit
        );
        const e_retain_shares_to_be_issued = IsNumber(
          t_retain_shares_to_be_issued,
          true,
          true,
          true,
          0,
          OneMillionLimit
        )
          ? false
          : true;
        const t_retain_shares_to_be_issued_value = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued_value}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const e_retain_shares_to_be_issued_value = IsNumber(
          t_retain_shares_to_be_issued_value,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const t_cash_in_lieu_of_stock = NumberFormaterWithValidation(
          `${n_cash_in_lieu_of_stock}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const e_cash_in_lieu_of_stock = IsNumber(
          t_cash_in_lieu_of_stock,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: shares_under_quota_name,
          value: t_shares_under_quota,
          error: e_shares_under_quota,
        });
        data_list.push({
          name: retain_dollars_name,
          value: t_retain_dollars,
          error: e_retain_dollars,
        });
        data_list.push({
          name: potential_retain_shares_name,
          value: t_potential_retain_shares,
          error: e_potential_retain_shares,
        });
        data_list.push({
          name: retain_shares_to_be_issued_name,
          value: t_retain_shares_to_be_issued,
          error: e_retain_shares_to_be_issued,
        });
        data_list.push({
          name: retain_shares_to_be_issued_value_name,
          value: t_retain_shares_to_be_issued_value,
          error: e_retain_shares_to_be_issued_value,
        });
        data_list.push({
          name: cash_in_lieu_of_stock_name,
          value: t_cash_in_lieu_of_stock,
          error: e_cash_in_lieu_of_stock,
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel5(
    data_list,
    par_value,
    retain_dollars,
    retain_shares_to_be_issued
  ) {
    try {
      const n_par_value = parseFloat(par_value),
        n_retain_dollars = parseFloat(retain_dollars),
        n_retain_shares_to_be_issued = parseFloat(retain_shares_to_be_issued);
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_dollars) &&
        IsNumberChecker(n_retain_shares_to_be_issued)
      ) {
        const t_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued}`,
          WholeRateValidation,
          0,
          OneMillionLimit
        );
        const e_retain_shares_to_be_issued = IsNumber(
          t_retain_shares_to_be_issued,
          true,
          true,
          true,
          0,
          OneMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: retain_shares_to_be_issued_name,
          value: t_retain_shares_to_be_issued,
          error: e_retain_shares_to_be_issued,
        });

        const n_retain_shares_to_be_issued_value =
          n_retain_shares_to_be_issued * n_par_value;

        return StockRetainCalculatorLevel6(
          data_list,
          par_value,
          retain_dollars,
          n_retain_shares_to_be_issued_value
        );
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function StockRetainCalculatorLevel6(
    data_list,
    par_value,
    retain_dollars,
    retain_shares_to_be_issued_value
  ) {
    try {
      const n_par_value = parseFloat(par_value),
        n_retain_dollars = parseFloat(retain_dollars),
        n_retain_shares_to_be_issued_value = parseFloat(
          retain_shares_to_be_issued_value
        );
      if (
        IsNumberChecker(n_par_value) &&
        IsNumberChecker(n_retain_dollars) &&
        IsNumberChecker(n_retain_shares_to_be_issued_value)
      ) {
        const n_cash_in_lieu_of_stock =
          n_retain_dollars - n_retain_shares_to_be_issued_value;

        const t_retain_shares_to_be_issued_value = NumberFormaterWithValidation(
          `${n_retain_shares_to_be_issued_value}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const e_retain_shares_to_be_issued_value = IsNumber(
          t_retain_shares_to_be_issued_value,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;
        const t_cash_in_lieu_of_stock = NumberFormaterWithValidation(
          `${n_cash_in_lieu_of_stock}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const e_cash_in_lieu_of_stock = IsNumber(
          t_cash_in_lieu_of_stock,
          true,
          true,
          true,
          0,
          NineMillionLimit
        )
          ? false
          : true;

        data_list.push({
          name: retain_shares_to_be_issued_value_name,
          value: t_retain_shares_to_be_issued_value,
          error: e_retain_shares_to_be_issued_value,
        });

        data_list.push({
          name: cash_in_lieu_of_stock_name,
          value: t_cash_in_lieu_of_stock,
          error: e_cash_in_lieu_of_stock,
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function CopyToAre(e, parent) {
    const current = parseInt(parent) + 1;

    const are_data = GetCurrentTabData(inputComponentArr, parent)?.are_data;
    const common_data = GetCurrentTabData(
      inputComponentArr,
      current
    )?.common_data;

    const data_list = [];

    AREStockRetainCalculatorForCopying(
      data_list,
      are_data,
      {},
      {},
      common_data,
      "are_data"
    );

    dispatchInputComponentMapping({
      type: "AREChange",
      payload: {
        parent: current,
        data_list,
      },
    });
  }

  function CopyToStockRetain(e, parent) {
    const current = parseInt(parent) + 1;

    const stock_retain_data = GetCurrentTabData(
      inputComponentArr,
      parent
    )?.stock_retain_data;
    const current_stock_retain_data = GetCurrentTabData(
      inputComponentArr,
      current
    )?.stock_retain_data;
    const common_data = GetCurrentTabData(
      inputComponentArr,
      current
    )?.common_data;

    const data_list = [];

    AREStockRetainCalculatorForCopying(
      data_list,
      {},
      stock_retain_data,
      current_stock_retain_data,
      common_data,
      "stock_retain_data"
    );

    dispatchInputComponentMapping({
      type: "StockRetainChange",
      payload: {
        parent: current,
        data_list,
      },
    });
  }

  function CopyToAreStockRetain(e, parent) {
    try {
      const parent_are_data = GetCurrentTabData(
        inputComponentArr,
        parent
      )?.are_data;
      const parent_stock_retain_data = GetCurrentTabData(
        inputComponentArr,
        parent
      )?.stock_retain_data;

      const master_data_list = inputComponentArr
        ?.filter((li) => li.key != parent)
        ?.map((list) => {
          const { key, common_data, stock_retain_data } = list || {};
          const data_list = [];
          AREStockRetainCalculatorForCopying(
            data_list,
            parent_are_data,
            parent_stock_retain_data,
            stock_retain_data,
            common_data,
            "all"
          );
          return { parent: key, data_list };
        });

      dispatchInputComponentMapping({
        type: "AREStockRetainChange",
        payload: {
          master_data_list,
        },
      });
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  }

  function AREStockPreloadMultiYear(from_preload = true, data = []) {
    try {
      const master_data_list = [];
      data.forEach((item) => {
        const {
          year,
          are_redemption = "0",
          are_rate = "0",
          quota_rate = "0",
          par_value = "0",
          common_stock_shares_held = "0",
          retain_rate = "0",
          average_deliveries = "0",
          common_barrels_delivered = "0",
        } = item || {};
        const input_data = inputComponentArr.find((li) => li.cropyear == year);
        if (input_data) {
          const { key, are_data, stock_retain_data } = input_data || {};

          const final_are_data = from_preload
            ? {
                are_rate,
                are_rate_error: IsNumber(
                  are_rate,
                  true,
                  true,
                  true,
                  0,
                  HundredLimit
                )
                  ? false
                  : true,
              }
            : are_data;
          const final_stock_retain_data = from_preload
            ? {
                quota_rate,
                quota_rate_error: IsNumber(
                  quota_rate,
                  true,
                  true,
                  true,
                  0,
                  HundredLimit
                )
                  ? false
                  : true,
                par_value,
                par_value_error: IsNumber(
                  par_value,
                  true,
                  true,
                  true,
                  0,
                  HundredLimit
                )
                  ? false
                  : true,
                common_stock_shares_held,
                common_stock_shares_held_error: IsNumber(
                  common_stock_shares_held,
                  true,
                  true,
                  true,
                  0,
                  NineMillionLimit
                )
                  ? false
                  : true,
                retain_rate,
                retain_rate_error: IsNumber(
                  retain_rate,
                  true,
                  true,
                  true,
                  0,
                  HundredLimit
                )
                  ? false
                  : true,
              }
            : stock_retain_data;

          const data_list = [];
          data_list.push({
            field: "common_data",
            name: common_barrels_delivered_name,
            value: `${common_barrels_delivered}`,
            error: false,
          });
          if (from_preload) {
            data_list.push({
              field: "are_data",
              name: are_redemption_name,
              value: are_redemption,
              error: IsNumber(
                are_redemption,
                true,
                true,
                true,
                0,
                OneMillionLimit
              )
                ? false
                : true,
            });
          }

          AREStockRetainCalculatorForCopying(
            data_list,
            final_are_data,
            final_stock_retain_data,
            {
              average_deliveries: BarrelsNumberFormater(
                `${average_deliveries}`,
                true,
                true
              ),
            },
            { common_barrels_delivered: `${common_barrels_delivered}` },
            "all"
          );

          master_data_list.push({ parent: key, data_list });
        }
      });
      // console.log({ master_data_list });
      dispatchInputComponentMapping({
        type: "AREStockRetainChange",
        payload: {
          master_data_list,
        },
      });
      EmptyAREStockPreloadData();
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      EmptyAREStockPreloadData();
    }
  }

  function AREStockRetainCalculatorForCopying(
    data_list,
    parent_are_data,
    parent_stock_retain_data,
    current_stock_retain_data,
    common_data,
    area = "all"
  ) {
    try {
      const { common_barrels_delivered: t_common_barrels_delivered = "" } =
        common_data || {};
      const common_barrels_delivered = CommaNumberToNormal(
        t_common_barrels_delivered
      );

      if (area === "are_data" || area === "all") {
        const { are_rate, are_rate_error } = parent_are_data || {};
        data_list.push({
          field: "are_data",
          name: are_rate_name,
          value: are_rate,
          error: are_rate_error,
        });

        const raw_are_issuance =
          common_barrels_delivered * parseFloat(are_rate);
        const value_are_issuance = NumberFormaterWithValidation(
          `${raw_are_issuance}`,
          MinRateValidation,
          0,
          OneMillionLimit
        );
        const are_issuance = parseFloat(value_are_issuance);

        data_list.push({
          field: "are_data",
          name: are_issuance_name,
          value: value_are_issuance,
          error: IsNumber(are_issuance, true, false, true, 0, OneMillionLimit)
            ? false
            : true,
        });
      }

      if (area === "stock_retain_data" || area === "all") {
        const {
          quota_rate: t_quota_rate = "",
          quota_rate_error,
          par_value: t_par_value = "",
          par_value_error,
          common_stock_shares_held: t_common_stock_shares_held = "",
          common_stock_shares_held_error,
          retain_rate: t_retain_rate = "",
          retain_rate_error,
        } = parent_stock_retain_data;

        const {
          average_deliveries: t_average_deliveries = "",
          average_deliveries_error,
        } = current_stock_retain_data;

        data_list.push({
          field: "stock_retain_data",
          name: quota_rate_name,
          value: t_quota_rate,
          error: quota_rate_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: par_value_name,
          value: t_par_value,
          error: par_value_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: common_stock_shares_held_name,
          value: t_common_stock_shares_held,
          error: common_stock_shares_held_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_rate_name,
          value: t_retain_rate,
          error: retain_rate_error,
        });
        data_list.push({
          field: "stock_retain_data",
          name: average_deliveries_name,
          value: t_average_deliveries,
          error: average_deliveries_error,
        });

        const quota_rate = parseFloat(t_quota_rate);
        const par_value = parseFloat(t_par_value);
        const common_stock_shares_held = CommaNumberToNormal(
          t_common_stock_shares_held
        );
        const retain_rate = parseFloat(t_retain_rate);
        const average_deliveries = CommaNumberToNormal(t_average_deliveries);

        const raw_quota_shares = Math.round(
          (average_deliveries * quota_rate) / par_value
        );
        const value_quota_shares = NumberFormaterWithValidation(
          `${raw_quota_shares}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const quota_shares = parseFloat(value_quota_shares);

        const raw_percent_to_quota =
          (common_stock_shares_held / quota_shares) * 100;
        const value_percent_to_quota = NumberFormaterWithValidation(
          `${raw_percent_to_quota}`,
          WholeRateValidation,
          0,
          HundredLimit
        );
        const percent_to_quota = parseFloat(value_percent_to_quota);

        const raw_shares_under_quota =
          quota_shares > common_stock_shares_held
            ? quota_shares - common_stock_shares_held
            : 0;
        const value_shares_under_quota = NumberFormaterWithValidation(
          `${raw_shares_under_quota}`,
          WholeRateValidation,
          0,
          OneMillionLimit
        );
        const shares_under_quota = parseFloat(value_shares_under_quota);

        const raw_shares_under_quota_value = shares_under_quota * par_value;
        const value_shares_under_quota_value = NumberFormaterWithValidation(
          `${raw_shares_under_quota_value}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const shares_under_quota_value = parseFloat(
          value_shares_under_quota_value
        );

        const raw_retain_dollars = common_barrels_delivered * retain_rate;
        const value_retain_dollars = NumberFormaterWithValidation(
          `${raw_retain_dollars}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const retain_dollars = parseFloat(value_retain_dollars);

        const raw_potential_retain_shares = Math.round(
          retain_dollars / par_value
        );
        const value_potential_retain_shares = NumberFormaterWithValidation(
          `${raw_potential_retain_shares}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const potential_retain_shares = parseFloat(
          value_potential_retain_shares
        );

        const raw_retain_shares_to_be_issued =
          shares_under_quota < potential_retain_shares
            ? shares_under_quota
            : potential_retain_shares;
        const value_retain_shares_to_be_issued = NumberFormaterWithValidation(
          `${raw_retain_shares_to_be_issued}`,
          WholeRateValidation,
          0,
          OneMillionLimit
        );
        const retain_shares_to_be_issued = parseFloat(
          value_retain_shares_to_be_issued
        );

        const raw_retain_shares_to_be_issued_value =
          retain_shares_to_be_issued * par_value;
        const value_retain_shares_to_be_issued_value =
          NumberFormaterWithValidation(
            `${raw_retain_shares_to_be_issued_value}`,
            WholeRateValidation,
            0,
            NineMillionLimit
          );
        const retain_shares_to_be_issued_value = parseFloat(
          value_retain_shares_to_be_issued_value
        );

        const raw_cash_in_lieu_of_stock =
          retain_dollars - retain_shares_to_be_issued_value;
        const value_cash_in_lieu_of_stock = NumberFormaterWithValidation(
          `${raw_cash_in_lieu_of_stock}`,
          WholeRateValidation,
          0,
          NineMillionLimit
        );
        const cash_in_lieu_of_stock = parseFloat(value_cash_in_lieu_of_stock);

        data_list.push({
          field: "stock_retain_data",
          name: quota_shares_name,
          value: value_quota_shares,
          error: IsNumber(quota_shares, true, true, true, 0, NineMillionLimit)
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: percent_to_quota_name,
          value: value_percent_to_quota,
          error: IsNumber(percent_to_quota, true, true, true, 0, HundredLimit)
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: shares_under_quota_name,
          value: value_shares_under_quota,
          error: IsNumber(
            shares_under_quota,
            true,
            true,
            true,
            0,
            OneMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: shares_under_quota_value_name,
          value: value_shares_under_quota_value,
          error: IsNumber(
            shares_under_quota_value,
            true,
            true,
            true,
            0,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_dollars_name,
          value: value_retain_dollars,
          error: IsNumber(retain_dollars, true, true, true, 0, NineMillionLimit)
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: potential_retain_shares_name,
          value: value_potential_retain_shares,
          error: IsNumber(
            potential_retain_shares,
            true,
            true,
            true,
            0,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_shares_to_be_issued_name,
          value: value_retain_shares_to_be_issued,
          error: IsNumber(
            retain_shares_to_be_issued,
            true,
            true,
            true,
            0,
            OneMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: retain_shares_to_be_issued_value_name,
          value: value_retain_shares_to_be_issued_value,
          error: IsNumber(
            retain_shares_to_be_issued_value,
            true,
            true,
            true,
            0,
            NineMillionLimit
          )
            ? false
            : true,
        });
        data_list.push({
          field: "stock_retain_data",
          name: cash_in_lieu_of_stock_name,
          value: value_cash_in_lieu_of_stock,
          error: IsNumber(
            cash_in_lieu_of_stock,
            true,
            true,
            true,
            0,
            NineMillionLimit
          )
            ? false
            : true,
        });
      }

      return data_list;
    } catch (e) {
      HandleErrorTrackAndToast(e);
      return [];
    }
  }

  function EnableCalculateSection(action) {
    if (compareString(action, "edit")) {
      SectionHandler(false, "calculate_reverse");
    } else {
      // console.log({ action });
      dispatchInputComponentMapping({
        type: "AREValidate",
      });
      dispatchInputComponentMapping({
        type: "StockRetainValidate",
      });

      const allow_access = inputComponentArr.every((element) => {
        const { cropyear, are_data, stock_retain_data } = element;

        const {
          are_redemption_month,
          are_redemption_year,
          are_redemption_crop_year,
          are_issuance_month,
          are_issuance_year,
        } = are_data;
        const { stock_retain_month, stock_retain_year } = stock_retain_data;
        const are_redemption_time =
          are_redemption_month &&
          are_redemption_year &&
          are_redemption_crop_year;

        if (are_redemption_time) {
          const are_issuance_time = are_issuance_month && are_issuance_year;
          if (are_issuance_time) {
            const are_redemption_access = IsNumber(
              are_data.are_redemption,
              true,
              true,
              true,
              0,
              OneMillionLimit
            );
            if (are_redemption_access) {
              const are_rate_access = IsNumber(
                are_data.are_rate,
                true,
                true,
                true,
                0,
                HundredLimit
              );
              const are_issuance_access = IsNumber(
                are_data.are_issuance,
                true,
                false,
                true,
                0,
                OneMillionLimit
              );
              if (are_issuance_access && are_rate_access) {
                const stock_retain_time =
                  stock_retain_month && stock_retain_year;
                if (stock_retain_time) {
                  const average_deliveries_access = IsNumber(
                    stock_retain_data.average_deliveries,
                    true,
                    true,
                    true,
                    0,
                    NineMillionLimit
                  );
                  const quota_rate_access = IsNumber(
                    stock_retain_data.quota_rate,
                    true,
                    true,
                    true,
                    0,
                    HundredLimit
                  );
                  const par_value_access = IsNumber(
                    stock_retain_data.par_value,
                    true,
                    true,
                    true,
                    0,
                    HundredLimit
                  );
                  const quota_shares_access = IsNumber(
                    stock_retain_data.quota_shares,
                    true,
                    true,
                    true,
                    0,
                    NineMillionLimit
                  );
                  const top_level_access =
                    average_deliveries_access &&
                    quota_rate_access &&
                    par_value_access &&
                    quota_shares_access;

                  if (top_level_access) {
                    const common_stock_shares_held_access = IsNumber(
                      stock_retain_data.common_stock_shares_held,
                      true,
                      true,
                      true,
                      0,
                      NineMillionLimit
                    );
                    const percent_to_quota_access = IsNumber(
                      stock_retain_data.percent_to_quota,
                      true,
                      true,
                      true,
                      0,
                      HundredLimit
                    );
                    const retain_rate_access = IsNumber(
                      stock_retain_data.retain_rate,
                      true,
                      true,
                      true,
                      0,
                      HundredLimit
                    );
                    const retain_dollars_access = IsNumber(
                      stock_retain_data.retain_dollars,
                      true,
                      true,
                      true,
                      0,
                      NineMillionLimit
                    );
                    const potential_retain_shares_access = IsNumber(
                      stock_retain_data.potential_retain_shares,
                      true,
                      true,
                      true,
                      0,
                      NineMillionLimit
                    );
                    const second_level_access =
                      common_stock_shares_held_access &&
                      percent_to_quota_access &&
                      retain_rate_access &&
                      retain_dollars_access &&
                      potential_retain_shares_access;

                    if (second_level_access) {
                      const shares_under_quota_access = IsNumber(
                        stock_retain_data.shares_under_quota,
                        true,
                        true,
                        true,
                        0,
                        OneMillionLimit
                      );
                      const shares_under_quota_value_access = IsNumber(
                        stock_retain_data.shares_under_quota_value,
                        true,
                        true,
                        true,
                        0,
                        NineMillionLimit
                      );
                      const retain_shares_to_be_issued_access = IsNumber(
                        stock_retain_data.retain_shares_to_be_issued,
                        true,
                        true,
                        true,
                        0,
                        OneMillionLimit
                      );
                      const retain_shares_to_be_issued_value_access = IsNumber(
                        stock_retain_data.retain_shares_to_be_issued_value,
                        true,
                        true,
                        true,
                        0,
                        NineMillionLimit
                      );
                      const cash_in_lieu_of_stock_access = IsNumber(
                        stock_retain_data.cash_in_lieu_of_stock,
                        true,
                        true,
                        true,
                        0,
                        NineMillionLimit
                      );

                      const access =
                        shares_under_quota_access &&
                        shares_under_quota_value_access &&
                        retain_shares_to_be_issued_access &&
                        retain_shares_to_be_issued_value_access &&
                        cash_in_lieu_of_stock_access;

                      if (access) {
                        set_are_stock_error_msg("");
                        return true;
                      } else {
                        set_are_stock_error_msg(
                          `Error: Under Crop Year ${cropyear} in Stock Retain details`
                        );
                        return false;
                      }
                    } else {
                      set_are_stock_error_msg(
                        `Error: Under Crop Year ${cropyear} in Stock Retain details`
                      );
                      return false;
                    }
                  } else {
                    set_are_stock_error_msg(
                      `Error: Under Crop Year ${cropyear} in Stock Retain details`
                    );
                    return false;
                  }
                } else {
                  set_are_stock_error_msg(
                    `Error: Under Crop Year ${cropyear} in Stock Retain payout year and month`
                  );
                  return false;
                }
              } else {
                set_are_stock_error_msg(
                  `Error: Under Crop Year ${cropyear} in ARE Issuance details`
                );
                return false;
              }
            } else {
              set_are_stock_error_msg(
                `Error: Under Crop Year ${cropyear} in ARE Redemption details`
              );
              return false;
            }
          } else {
            set_are_stock_error_msg(
              `Error: Under Crop Year ${cropyear} in ARE Issuance payout year and month`
            );
            return false;
          }
        } else {
          set_are_stock_error_msg(
            `Error: Under Crop Year ${cropyear} in ARE Redemption payout year and month`
          );
          return false;
        }
      });

      if (allow_access) {
        SectionHandler(true, "calculate");
      } else {
        SectionHandler(false, "calculate_reverse");
      }
    }
  }

  useEffect(() => {
    const { from_preload = null, merged_data = [] } = are_stock_preload;
    const load = from_preload === true || from_preload === false;
    // console.log({ load, are_stock_preload });
    if (load && IsArrayNotEmpty(merged_data)) {
      AREStockPreloadMultiYear(from_preload, merged_data);
    }
  }, [are_stock_preload]);

  // console.log({ stock_retain_data, are_data, common_data });
  // console.log({ are_stock_error_msg });
  return (
    <Fragment>
      <div className="row mb-2">
        <div className="col">
          <h4 className={`fw-bold`}>
            ARE & Stock Retain
            <span className="drop-offinfo">
              (Enter crop year ARE Redemption, ARE Issuance & Stock Retain
              details below)
            </span>
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Alert
            variant="primary"
            style={{
              textAlign: "justify",
              fontStyle: "italic",
            }}
          >
            <Alert.Heading>
              <i className="fa fa-info"></i> Note:
            </Alert.Heading>
            <p>{info_box}</p>
          </Alert>
        </div>
      </div>
      {IsArrayNotEmpty(inputComponentArr) && (
        <div className="row mb-2">
          <div className="col">
            <ul
              className="nav nav-pills mb-2 mb-lg-3 bordered_nav_tabs cyear-arestocktabs"
              id="pills-tab"
              role="tablist"
            >
              {inputComponentArr.map((list) => {
                const { cropyear, key } = list;
                return (
                  <li key={key} className="nav-item" role="presentation">
                    <button
                      className={
                        selectedTab == cropyear
                          ? "nav-link active"
                          : "nav-link "
                      }
                      id={`pills-cyear-arestock-${cropyear}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#pills-cyear-arestock-${cropyear}`}
                      type="button"
                      role="tab"
                      aria-controls={`pills-cyear-arestock-${cropyear}`}
                      aria-selected="true"
                      onClick={() => changeTab(cropyear)}
                    >
                      {cropyear}
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {inputComponentArr.map((list, parentIndex) => {
                const {
                  cropyear,
                  key,
                  common_data = {},
                  stock_retain_data = {},
                  are_data = {},
                } = list;

                const {
                  common_barrels_delivered = "0",
                  barrels_delivered_crop_year = "",
                } = common_data;

                const {
                  are_redemption_crop_year = "",
                  are_issuance = "",
                  are_issuance_error = false,
                  are_redemption = "",
                  are_redemption_error = false,
                  are_rate = "",
                  are_rate_error = false,
                } = are_data;

                const {
                  average_deliveries = "",
                  quota_rate = "",
                  par_value = "",
                  quota_shares = "",
                  common_stock_shares_held = "",
                  percent_to_quota = "",
                  retain_rate = "",
                  retain_dollars = "",
                  potential_retain_shares = "",
                  shares_under_quota = "",
                  shares_under_quota_value = "",
                  retain_shares_to_be_issued = "",
                  retain_shares_to_be_issued_value = "",
                  cash_in_lieu_of_stock = "",
                  average_deliveries_error,
                  quota_rate_error,
                  par_value_error,
                  quota_shares_error,
                  common_stock_shares_held_error,
                  percent_to_quota_error,
                  retain_rate_error,
                  retain_dollars_error,
                  potential_retain_shares_error,
                  shares_under_quota_error,
                  shares_under_quota_value_error,
                  retain_shares_to_be_issued_error,
                  retain_shares_to_be_issued_value_error,
                  cash_in_lieu_of_stock_error,
                } = stock_retain_data;

                const nextIndex =
                  inputComponentArr.length > 1 ? parentIndex + 1 : false;
                const {
                  cropyear: nextCropYear = false,
                  key: nextParent = undefined,
                } = nextIndex ? inputComponentArr[nextIndex] || {} : {};
                const copyBtn =
                  nextIndex && nextCropYear && (nextParent || nextParent == 0);
                return (
                  <div
                    key={key}
                    className={
                      selectedTab == cropyear
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                    id={`pills-cyear-arestock-${cropyear}`}
                    role="tabpanel"
                    aria-labelledby={`pills-cyear-arestock-${cropyear}-tab`}
                  >
                    <div
                      className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 desktop-accordion-table`}
                    >
                      <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                        <Accordion
                        // defaultActiveKey="1"
                        >
                          <Accordion.Item eventKey={"1"}>
                            <Accordion.Header>
                              {`Crop Year ${selectedTab}: Enter ARE Redemption & ARE Issuance details for calculation`}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row m-2">
                                <div className="col">
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <h5 className="fw-bold">
                                        ARE Redemption
                                        {` (from ${are_redemption_crop_year} Crop Year)`}
                                      </h5>
                                      <Form.Control
                                        className="white_bg"
                                        type="text"
                                        name={are_redemption_name}
                                        value={are_redemption}
                                        onChange={(e) => onAREChange(e, key)}
                                        onKeyDown={KeyDownHandler}
                                        isInvalid={
                                          !IsStringChecker(are_redemption)
                                        }
                                        required
                                        placeholder={"Enter the ARE Redemption"}
                                        readOnly={enable_calculate_section}
                                        autoComplete="off"
                                      />
                                      {are_redemption_error && (
                                        <Form.Text style={{ color: "red" }}>
                                          {lakh_limit_err_msg}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </div>
                                  <hr className="Payment-Estimator-Segment" />
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                      <h5 className="fw-bold">
                                        Barrels Delivered
                                        {` (${barrels_delivered_crop_year} Crop Year)`}
                                      </h5>
                                      <Form.Control
                                        type="text"
                                        name={common_barrels_delivered_name}
                                        value={BarrelsNumberFormater(
                                          `${common_barrels_delivered}`
                                        )}
                                        onKeyDown={KeyDownHandler}
                                        placeholder={
                                          "Enter the Barrels Delivered"
                                        }
                                        readOnly={true}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <h5 className="fw-bold">
                                        ARE Issuance Rate
                                      </h5>
                                      <Form.Control
                                        className="white_bg"
                                        type="text"
                                        name={are_rate_name}
                                        value={are_rate}
                                        onChange={(e) => onAREChange(e, key)}
                                        onKeyDown={KeyDownHandler}
                                        isInvalid={!IsStringChecker(are_rate)}
                                        required
                                        placeholder={
                                          "Enter the ARE Issuance Rate"
                                        }
                                        readOnly={enable_calculate_section}
                                        autoComplete="off"
                                      />
                                      {are_rate_error && (
                                        <Form.Text style={{ color: "red" }}>
                                          {percent_limit_err_msg}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <h5 className="fw-bold">ARE Issuance</h5>
                                      <Form.Control
                                        className="white_bg"
                                        type="text"
                                        name={are_issuance_name}
                                        value={are_issuance}
                                        onChange={(e) => onAREChange(e, key)}
                                        onKeyDown={KeyDownHandler}
                                        isInvalid={
                                          !IsStringChecker(are_issuance)
                                        }
                                        required
                                        placeholder={"Enter the ARE Issuance"}
                                        readOnly={enable_calculate_section}
                                        autoComplete="off"
                                      />
                                      {are_issuance_error && (
                                        <Form.Text style={{ color: "red" }}>
                                          {lakh_min_limit_err_msg}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          {copyBtn && (
                            <Button
                              variant="primary"
                              className={`text-16 mb-2 ${
                                enable_calculate_section
                                  ? "cursor-not-allowed"
                                  : ""
                              }`}
                              style={{
                                boxShadow: `0 0 5px #ccc`,
                              }}
                              onClick={(e) => CopyToAre(e, key)}
                              disabled={enable_calculate_section}
                            >
                              {`Copy ARE Details To Crop Year: ${nextCropYear}`}
                            </Button>
                          )}
                          <Accordion.Item eventKey={"2"}>
                            <Accordion.Header>
                              {`Crop Year ${selectedTab}: Enter Stock Retain details for calculation`}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row m-2">
                                <div className="col">
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          Average Deliveries
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={average_deliveries_name}
                                          value={BarrelsNumberFormater(
                                            `${average_deliveries}`
                                          )}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Average Deliveries"
                                          }
                                          isInvalid={
                                            !IsStringChecker(average_deliveries)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {average_deliveries_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {max_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          Quota Rate
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={quota_rate_name}
                                          value={quota_rate}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={"Enter the Quota Rate"}
                                          isInvalid={
                                            !IsStringChecker(quota_rate)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {quota_rate_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {percent_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          Par Value
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={par_value_name}
                                          value={par_value}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={"Enter the Par Value"}
                                          isInvalid={
                                            !IsStringChecker(par_value)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {par_value_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {percent_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          Quota Shares
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={quota_shares_name}
                                          value={quota_shares}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={"Enter the Quota Shares"}
                                          isInvalid={
                                            !IsStringChecker(quota_shares)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {quota_shares_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {max_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <h5 className="fw-bold">
                                        Barrels Delivered
                                        {` (${barrels_delivered_crop_year} Crop Year)`}
                                      </h5>
                                      <Form.Control
                                        type="text"
                                        name={common_barrels_delivered_name}
                                        value={BarrelsNumberFormater(
                                          `${common_barrels_delivered}`
                                        )}
                                        onKeyDown={KeyDownHandler}
                                        placeholder={
                                          "Enter the Barrels Delivered"
                                        }
                                        readOnly={true}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          Common Stock Shares Held
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={common_stock_shares_held_name}
                                          value={common_stock_shares_held}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Common Stock Shares Held"
                                          }
                                          isInvalid={
                                            !IsStringChecker(
                                              common_stock_shares_held
                                            )
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {common_stock_shares_held_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {max_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          % to Quota
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={percent_to_quota_name}
                                          value={percent_to_quota}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={"Enter the % to Quota"}
                                          isInvalid={
                                            !IsStringChecker(percent_to_quota)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {percent_to_quota_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {percent_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 mb-3 mb-lg-0 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          Retain Rate
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={retain_rate_name}
                                          value={retain_rate}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={"Enter the Retain Rate"}
                                          isInvalid={
                                            !IsStringChecker(retain_rate)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {retain_rate_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {percent_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-4 text-wrap text-start">
                                          Retain Dollars
                                        </InputGroup.Text>
                                        <InputGroup.Text className="text-wrap text-start">
                                          $
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={retain_dollars_name}
                                          value={retain_dollars}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Retain Dollars"
                                          }
                                          isInvalid={
                                            !IsStringChecker(retain_dollars)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {retain_dollars_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {max_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <InputGroup>
                                        <InputGroup.Text className="col-5 text-wrap text-start">
                                          Potential Retain Shares
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={potential_retain_shares_name}
                                          value={potential_retain_shares}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Potential Retain Shares"
                                          }
                                          isInvalid={
                                            !IsStringChecker(
                                              potential_retain_shares
                                            )
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {potential_retain_shares_error && (
                                        <div className="row">
                                          <Form.Text
                                            className="col"
                                            style={{ color: "red" }}
                                          >
                                            {max_limit_err_msg}
                                          </Form.Text>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 payment-select-input mb-3 mb-lg-0">
                                      <h5 className="fw-bold">
                                        Shares Under Quota
                                      </h5>
                                      <InputGroup>
                                        <Form.Text
                                          className="col-6"
                                          style={{
                                            visibility: "hidden",
                                          }}
                                        ></Form.Text>
                                        <Form.Text className="col-6 fw-bold">
                                          (Dollar Value)
                                        </Form.Text>
                                      </InputGroup>
                                      <InputGroup>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={shares_under_quota_name}
                                          value={shares_under_quota}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Shares Under Quota"
                                          }
                                          isInvalid={
                                            !IsStringChecker(shares_under_quota)
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                        <Form.Control
                                          className="white_bg col-5"
                                          type="text"
                                          name={shares_under_quota_value_name}
                                          value={shares_under_quota_value}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Final Shares Under Quota Value"
                                          }
                                          isInvalid={
                                            !IsStringChecker(
                                              shares_under_quota_value
                                            )
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {(shares_under_quota_error ||
                                        shares_under_quota_value_error) && (
                                        <InputGroup>
                                          <Form.Text
                                            className={`col-6`}
                                            style={{
                                              color: "red",
                                              visibility:
                                                shares_under_quota_error
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                          >
                                            {lakh_limit_err_msg}
                                          </Form.Text>
                                          <Form.Text
                                            className={`col-6`}
                                            style={{
                                              color: "red",
                                              visibility:
                                                shares_under_quota_value_error
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                          >
                                            {max_limit_err_msg}
                                          </Form.Text>
                                        </InputGroup>
                                      )}
                                    </div>
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <h5 className="fw-bold">
                                        Retain Shares to be Issued
                                      </h5>
                                      <InputGroup>
                                        <Form.Text
                                          className="col-6"
                                          style={{
                                            visibility: "hidden",
                                          }}
                                        ></Form.Text>
                                        <Form.Text className="col-6 fw-bold">
                                          (Dollar Value)
                                        </Form.Text>
                                      </InputGroup>
                                      <InputGroup>
                                        <Form.Control
                                          className="white_bg col-3"
                                          type="text"
                                          name={retain_shares_to_be_issued_name}
                                          value={retain_shares_to_be_issued}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Retain Shares to be Issued"
                                          }
                                          isInvalid={
                                            !IsStringChecker(
                                              retain_shares_to_be_issued
                                            )
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={
                                            retain_shares_to_be_issued_value_name
                                          }
                                          value={
                                            retain_shares_to_be_issued_value
                                          }
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          placeholder={
                                            "Enter the Final Retain Shares to be Issued Value"
                                          }
                                          isInvalid={
                                            !IsStringChecker(
                                              retain_shares_to_be_issued_value
                                            )
                                          }
                                          required
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {(retain_shares_to_be_issued_error ||
                                        retain_shares_to_be_issued_value_error) && (
                                        <InputGroup>
                                          <Form.Text
                                            className="col-6"
                                            style={{
                                              color: "red",
                                              visibility:
                                                retain_shares_to_be_issued_error
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                          >
                                            {lakh_limit_err_msg}
                                          </Form.Text>
                                          <Form.Text
                                            className="col-6"
                                            style={{
                                              color: "red",
                                              visibility:
                                                retain_shares_to_be_issued_value_error
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                          >
                                            {max_limit_err_msg}
                                          </Form.Text>
                                        </InputGroup>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-12 col-lg-6 payment-select-input">
                                      <h5 className="fw-bold">
                                        Cash in lieu of Stock
                                      </h5>
                                      <InputGroup>
                                        <InputGroup.Text className="text-wrap text-start">
                                          $
                                        </InputGroup.Text>
                                        <Form.Control
                                          className="white_bg"
                                          type="text"
                                          name={cash_in_lieu_of_stock_name}
                                          value={cash_in_lieu_of_stock}
                                          onChange={(e) =>
                                            onStockRetainChange(e, key)
                                          }
                                          onKeyDown={KeyDownHandler}
                                          isInvalid={
                                            !IsStringChecker(
                                              cash_in_lieu_of_stock
                                            )
                                          }
                                          required
                                          placeholder={
                                            "Enter the Cash in lieu of Stock"
                                          }
                                          readOnly={enable_calculate_section}
                                          autoComplete="off"
                                        />
                                      </InputGroup>
                                      {cash_in_lieu_of_stock_error && (
                                        <Form.Text style={{ color: "red" }}>
                                          {max_limit_err_msg}
                                        </Form.Text>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          {copyBtn && (
                            <Button
                              variant="primary"
                              className={`text-16 mb-2 ${
                                enable_calculate_section
                                  ? "cursor-not-allowed"
                                  : ""
                              }`}
                              style={{
                                boxShadow: `0 0 5px #ccc`,
                              }}
                              onClick={(e) => CopyToStockRetain(e, key)}
                              disabled={enable_calculate_section}
                            >
                              {`Copy Stock Retain Details To Crop Year: ${nextCropYear}`}
                            </Button>
                          )}
                        </Accordion>
                      </div>
                    </div>
                    {parentIndex == 0 && inputComponentArr.length > 1 && (
                      <Button
                        variant="primary"
                        className={`text-16 mb-2 ${
                          enable_calculate_section ? "cursor-not-allowed" : ""
                        }`}
                        style={{
                          boxShadow: `0 0 5px #ccc`,
                        }}
                        onClick={(e) => CopyToAreStockRetain(e, key)}
                        disabled={enable_calculate_section}
                      >
                        {`Copy To All Year`}
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {IsStringChecker(are_stock_error_msg) && (
        <div className="row mt-2 mb-2">
          <div className="col">
            <h5 className={`mb-0 text-center red_color`}>
              * {are_stock_error_msg}
            </h5>
          </div>
        </div>
      )}
      <div className="row mt-2 mb-2">
        <div className="col">
          <Button
            variant="primary"
            className="text-16"
            onClick={() =>
              EnableCalculateSection(
                enable_calculate_section ? "edit" : "enter"
              )
            }
            style={{
              boxShadow: `0 0 5px #ccc`,
            }}
          >
            {`${
              enable_calculate_section ? "Edit This" : "Proceed To Next"
            } Section`}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentEstimatorAREStockRetain;
