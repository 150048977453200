/**
 * @component_Name : DDDailysumaryMobile -Delivery Data daily summary component
 * @description : Show Delivery Data daily summary as accodion
 * @company : Photon
 * @author : Ashish Ranjan/26-07-2022
 **/
import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";

const DDDailysumaryMobile = ({ listingData, preSelectedArray }) => {
  const pagingArray = Object.keys(listingData).map((x) => x)
  const listLength = pagingArray.length;
  return (
    <>
      <Accordion defaultActiveKey="0">
        {(listLength > 0 && Object.values(listingData).map((data, index) => {
          return (
            <Fragment key={index}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                MM/DD : {data['Day Of Year'] }
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td"></div>
                      <div className="td" data-header={`${preSelectedArray[0]}:`}><span>{data['CropYear']}</span></div>
                      <div className="td" data-header={`${preSelectedArray[0]-1}:`}><span>{data['CropYear-1']}</span></div>
                      <div className="td" data-header={`${preSelectedArray[0]-2}:`}><span>{data['CropYear-2']}</span></div>
                      <div className="td" data-header={`${preSelectedArray[0]-3}:`}><span>{data['CropYear-3']}</span></div>
                      <div className="td" data-header={`${preSelectedArray[0]-4}:`}><span>{data['CropYear-4']}</span></div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Fragment>
          );
        }))}
      </Accordion>
    </>
  );
};

export default DDDailysumaryMobile;