import React, { useState, useEffect, Fragment } from "react";
import "../../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  gbsDataCommonMethod,
  cmsDataCommonMethod,
} from "../../../../services/gbsData";
import { Accordion } from "react-bootstrap";
import Loading from "../../../../components/global/loading";
import DataGridPagination from "../../../global/dataGridPagination";
import { IsTokenChecker, HandleErrorTrackAndToast } from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import { ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../../../permissionWrapper";

export const DirectDeposit = (props) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);
  const [loader, setLoader] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [noResultsFoundMsg, setNoResultsFoundMsg] = useState();

  const [directDepositData, setDirectDepositData] = useState([]);
  const [directDepositDataMobile, setDirectDepositDataMobile] = useState([]);
  const [directDepositDataStatus, setDirectDepositDataStatus] = useState(true);

  const { DropDownCBRoles = [] } = props;

  //Defining Grid Column
  const columnsArray = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      width: 150,
    },
    {
      key: "Name",
      name: "Contract Name",
      cellClass: "enablesTextWrapping",
      width: 165,
    },
    {
      key: "FinancialInstitutionName",
      name: "Financial Institution",
      cellClass: "enablesTextWrapping",
      width: 180,
      formatter(props) {
        return <>{props.row.FinancialInstitutionName || "-"}</>;
      },
    },
    {
      key: "RouteNumber",
      name: "Route Id",
      headerCellClass: "text-lg-center",
      cellClass: "text-lg-center",
      width: 110,
      formatter(props) {
        return <>{props.row.RouteNumber || "-"}</>;
      },
    },
    {
      key: "AccountNumber",
      name: "Account Number",
      headerCellClass: "text-lg-end enablesTextWrappingHeading",
      cellClass: "text-lg-end",
      width: 100,
      formatter(props) {
        return <>{props.row.AccountNumber || "-"}</>;
      },
    },
    {
      key: "AccountType",
      name: "Account Type",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        return <>{props.row.AccountType || "-"}</>;
      },
    },
    {
      key: "AccountOwnerType",
      name: "Account Owner Type",
      width: 150,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        return <>{props.row.AccountOwnerType || "-"}</>;
      },
    },
  ];
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getDeferments();
      getContentBlock();
      setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    }

    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  //get Content block
  const getContentBlock = async () => {
    setLoader(true);
    await cmsDataCommonMethod(
      tokenId,
      msal_accounts,
      "content_blocks?select=id,content_en,content_fr,content_es,order_by,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id)&component_listings.component_id=eq.933caa0a-e093-4a15-bc79-6900bc6bafc9"
    )
      .then((resp) => {
        // console.log(resp?.data?.data)
        if (resp?.data?.data[0]) {
          setNoResultsFoundMsg(resp?.data?.data[0]);
          setLoader(false);
        }
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  // Get Grid API values
  const getDeferments = async () => {
    const filteredContracts = ValidGBSRoleByContracts(accounts, DropDownCBRoles, true, false);
    const contractids = filteredContracts.join();
    const url = `payments/direct-deposits?relationshipid=${contractids}`;

    setLoader(true);
    await gbsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        let resultData = [...resp.data];
        if (
          resultData.length > 0 &&
          resp.data !== "Contract and CropYear must be provided"
        ) {
          resultData = resultData.filter((a) => {
            return [1000, 1001].includes(a.ContractStatus);
          });
          resultData = _.orderBy(resultData, ["RelationshipNumber"], ["asc"]);
          if (resultData.length > 0) {
            setDirectDepositData(resultData);
            setDirectDepositDataMobile(resultData);
            setDirectDepositDataStatus(true);
            setNoResultsFound(false);
          } else {
            setDirectDepositData([]);
            setDirectDepositDataMobile([]);
            setDirectDepositDataStatus(false);
            setNoResultsFound(true);
          }
        } else {
          setDirectDepositData([]);
          setDirectDepositDataMobile([]);
          setDirectDepositDataStatus(false);
          setNoResultsFound(true);
        }
        setLoader(false);
      })

      .catch((error) => {
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const createMarkup = (cashAssignmentsNotFoundMsg) => {
    return { __html: cashAssignmentsNotFoundMsg };
  };
  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Desktop table starts -->*/}
      {directDepositData.length > 0 && directDepositDataStatus && !displayAccordian &&(
        <div
          className={`
      hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table`}
        >
          <DataGridPagination
            uniqueIdentifier="Id"
            gridData={directDepositData}
            columns={columnsArray}
            isPagination={true}
            itemsPerPage={10}
            columnResize={false}
            classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
            autoRowHeight={true}
          />
        </div>
      )}
      {/*<!-- Desktop table ends -->*/}
      {/* <!-- Mobile table starts --> */}
      {directDepositDataMobile.length > 0 && directDepositDataStatus && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion defaultActiveKey="0">
              {directDepositDataMobile.length > 0 &&
                Object.values(directDepositDataMobile).map((data, index) => {
                  return (
                    <Fragment key={data.id}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          Contract #: {data.RelationshipNumber}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div
                                className="td"
                                data-header="Contract #:"
                              ></div>
                              <div
                                className="td"
                                data-header="Contract Number:"
                              >
                                {data.RelationshipNumber}
                              </div>
                              <div className="td" data-header="Contract Name:">
                                <span>{data.Name}</span>
                              </div>
                              <div
                                className="td"
                                data-header="Financial Institution:"
                              >
                                {data.FinancialInstitutionName || "-"}
                              </div>
                              <div className="td" data-header="Route Id:">
                                {data.RouteNumber || "-"}
                              </div>
                              <div className="td" data-header="Account Number:">
                                {data.AccountNumber || "-"}
                              </div>
                              <div className="td" data-header="Account Type:">
                                {data.AccountType || "-"}
                              </div>
                              <div
                                className="td"
                                data-header="Account Owner Type:"
                              >
                                {data.AccountOwnerType || "-"}
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
          </div>
        </div>
      )}
      {/* <!-- Mobile table ends --> */}
      {/* no record found section start */}
      {directDepositData.length == 0 && noResultsFound && (
        <div
          className="p-2 red_color text-center border-1 border-radius-6 mt-2 direct-deposit-error-msg"
          dangerouslySetInnerHTML={createMarkup(
            noResultsFoundMsg
              ? noResultsFoundMsg["content_" + i18n.language]
              : ""
          )}
        />
      )}
      {/* no record found section End */}
      {/*<!-- Keeping quality block ends -->*/}
    </>
  );
};
