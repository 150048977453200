import React, { useState, useEffect, Fragment, useRef } from "react";
import Loading from "../../global/loading";
import MultiDropDown from "../../selectBox/multiDropDown";
// import DatePicker from "react-datepicker";
import { dateFormate } from "../../../utils/constantsVariable";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  IsTokenChecker,
  setObjToString,
  toDecimal,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsFunctionChecker,
  safeArrayCheck,
  IsArrayNotEmpty,
  IsStringChecker,
} from "../../../utils/helper";
import { useGbsDataCache } from "../../../services/gbsDataCache";
// import Moment from "react-moment";
import { Accordion } from "react-bootstrap";
import DataGridPagination from "../../global/dataGridPagination";
import CurrentLocation from "../../global/currentLocation";
import { useTranslation } from "react-i18next";
import moment from "moment";
import WhiteCircleLoader from "../../whiteCircleLoader";
import DatePickerComp from "../../global/datepicker";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import { ImpersonationAccountsSwap } from "../../../permissionWrapper";

const PesticideUseHistory = (props) => {
  const { roleAgScientist = false } = props;
  const myRef = useRef(null);
  const asyncTypeaheadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [contractOptions, setContractOptions] = useState([]);
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  // const personId = accounts[0].idTokenClaims.gbsPersonId;
  const tokenId = useSelector((state) => state.reducerToken);

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  const [CropYears, setCropYears] = useState([]);
  const [multiValue, setMultiValue] = useState({});
  const [CY_MultiValue, setCY_MultiValue] = useState({});
  const [defCropYearArr, setdefCropYearArr] = useState([]);

  const [contractNumbers, setContractNumbers] = useState([]);
  const [defContracts, setdefContracts] = useState([""]);
  const [CN_MultiValue, setCN_MultiValue] = useState({});

  const [applicationFromDate, setApplicationFromDate] = useState(null);
  const [applicationToDate, setApplicationToDate] = useState(null);

  const [allFilterData, setAllfiltersData] = useState([]);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);
  const [clearBtnDisable, setClearBtnDisable] = useState(true);
  const [, setDisabled] = useState(false);
  const [multiSelectReset, setMultiSelectReset] = useState(true);

  const [pesticideHistorySearchResult, setPesticideHistorySearchResult] =
    useState([]);
  const [
    pesticideHistorySearchResultStatus,
    setPesticideHistorySearchResultStatus,
  ] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);

  // Forward filter
  const [clearstatusOther, setClearstatusOther] = useState(false);
  const [bogName, setBogName] = useState([]);
  const [harvestBed, setHarvestBed] = useState([]);
  const [pesticideName, setPEsticideName] = useState([]);
  const [preselectValuesBogName, setPreselectValuesBogName] = useState([""]);
  const [preselectValuesHarvestBed, setPreselectValuesHarvestBed] = useState([
    "",
  ]);
  const [preselectValuesPesticideName, setPreselectValuesPesticideName] =
    useState([""]);
  const [forwordOrder, setForwordOrder] = useState(0);

  //Cache Implementation
  const [paymentSearchURL, setPaymentSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    paymentSearchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  // const [minDate, setMinDate] = useState(new Date());
  // const [maxDate, setMaxDate] = useState(new Date());

  useEffect(() => {
    if (data.data) {
      let respData = data?.data?.filter((i) => i.IsFinalise === true) || [];
      setAllfiltersData(formatSearchData(respData));
      setMultiselectData(respData);
    }
    // if (status === "error") {
    //   HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    // }
  }, [data, changeEvent, status]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const formatSearchData = (data = []) => {
    let PesticideArray = [];
    data
      .filter((i) => i.IsFinalise === true)
      ?.map((item, index) => {
        let hiddenData =
          item.Beds.length > 5
            ? item.Beds?.map((i) => i.HarvestBed)
                .splice(0, 5)
                ?.toString()
                ?.replace(/,/g, ", ") + "..."
            : item.Beds?.map((i) => i.HarvestBed)
                ?.toString()
                ?.replace(/,/g, ", ")
                ?.trim();
        let obj = {
          ItemId: `${item.Id}-${index}`,
          Id: item.Id,
          RelationshipId: item.RelationshipId,
          RelationshipNumber: item.RelationshipNumber,
          Name: item.Name,
          CropYear: item.CropYear,
          AppliedDate: item.AppliedDate,
          PesticideId: item.PesticideId,
          PesticideCode: item.PesticideCode,
          PesticideName: item.PesticideName,
          PesticideCodeName: `${item.PesticideCode}-${item.PesticideName}`,
          Rate: item.Rate,
          RateUomId: item.RateUomId,
          Description: item.Description,
          MethodId: item.MethodId,
          MethodName: item.MethodName,
          TypeId: item.TypeId,
          TypeName: item.TypeName,
          IsFinalise: item.IsFinalise || false,
          BogName: item.Beds
            ? _.uniqWith([...item.Beds.map((i) => i.BogName)], _.isEqual)
                .toString()
                .replace(/,/g, ", ")
            : "",
          HarvestBedList: hiddenData,
          HarvestBedListView: item.Beds?.map((i) => i.HarvestBed)
            ?.toString()
            ?.replace(/,/g, ", ")
            ?.trim(),
          HarvestBedListHiddenView: hiddenData,
          HarvestBedListIsExpanded: false,
          ContractName: `${item.RelationshipNumber} - ${item.Name}`,
          ExhibitAcres: _.sumBy(item.Beds, "ExhibitAcres"),
          AcresTreated: _.sumBy(item.Beds, "AcresTreated"),
          PURContactEmail: item.ContactEmail || "-",
          Beds: item.Beds,
        };
        PesticideArray.push(obj);
      });
    PesticideArray = _.orderBy(PesticideArray, ["AppliedDate"], ["asc"]);
    return PesticideArray;
  };
  const dateFormat = (date) => {
    date = date?.split("T")[0]?.split("-");
    return `${date[1]}/${date[2]}/${date[0]}`;
  };
  const columns = [
    {
      key: "ContractName",
      name: "Contract #",
      cellClass: "enablesTextWrapping",
      width: 100,
    },
    {
      key: "AppliedDate",
      name: "Application Date",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 100,
      dateFormate: dateFormate,
      formatter({ row }) {
        return <>{dateFormat(row.AppliedDate)}</>;
      },
    },
    {
      key: "PesticideCodeName",
      name: "EPA Or PCP Reg.# / Chemical",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "Rate",
      name: "Rate (Formulated Product Per Acre)",
      minWidth: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "Description",
      name: "Rate Unit Of Measure Per Acres(E.G. Oz/Acre)",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "MethodName",
      name: "Method Of Application",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "TypeName",
      name: "Fruit Type",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "BogName",
      name: "Bog Name",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "HarvestBedList",
      childkey: "HarvestBed",
      name: "Bed(S)",
      width: 120,
      cellClass: "enablesTextWrapping text-lg-center",
      formatter({ row }) {
        return (
          <>
            <span className="d-block expandedValue">
              {row.HarvestBedList}{" "}
              {(row.HarvestBedList.indexOf("...") > -1 ||
                row.HarvestBedListIsExpanded) && (
                <button
                  title={!row.HarvestBedListIsExpanded ? "Show more" : "Hide"}
                  className="show-more"
                  onClick={() => showMore(row)}
                >
                  {!row.HarvestBedListIsExpanded ? "+" : "-"}
                </button>
              )}{" "}
            </span>
          </>
        );
      },
    },
    {
      key: "ExhibitAcres",
      name: "Total Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return <>{toDecimal(row.ExhibitAcres, 2)}</>;
      },
    },
    {
      key: "AcresTreated",
      name: "Treated Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return <>{toDecimal(row.AcresTreated, 2)}</>;
      },
    },
    {
      key: "IsFinalise",
      name: "Status",
      width: 90,
      headerCellClass: "grid_column_center",
      cellClass: "enablesTextWrapping",
      formatter({ row }) {
        return (
          <>
            <span className="enablesTextWrapping">
              {row.IsFinalise ? (
                <span className="light_green_color">Submitted</span>
              ) : (
                <span className="red_dark_color">Not Submitted</span>
              )}
            </span>
          </>
        );
      },
    },
  ];

  const showMore = (row) => {
    const gridData = pesticideHistorySearchResult;
    let index = gridData.findIndex((item) => row.ItemId === item.ItemId);
    if (index > -1) {
      if (!row.HarvestBedListIsExpanded) {
        gridData[index].HarvestBedList = gridData[index].HarvestBedListView;
        gridData[index].HarvestBedListIsExpanded = true;
        // myRef.current.classList.add('expanded-row')
      } else {
        gridData[index].HarvestBedList =
          gridData[index].HarvestBedListHiddenView;
        gridData[index].HarvestBedListIsExpanded = false;
        // myRef.current.classList.add('expanded-row')
      }
    }
    myRef?.current?.scrollIntoView();
    setPesticideHistorySearchResult([...gridData]);
    setTimeout(() => {
      let height = "";
      document.querySelectorAll('[role="grid"] [role="row"]').forEach((i) => {
        let height1 = [],
          expandValue = "";
        i.childNodes.forEach((k) => {
          if (
            k.querySelectorAll(".expandedValue").length == 1 &&
            k.querySelectorAll('[title="Hide"').length == 1
          ) {
            expandValue = k.querySelectorAll(".expandedValue")[0].offsetHeight;
            height1.push(k.querySelectorAll(".expandedValue")[0].offsetHeight);
          } else {
            height1.push(k.offsetHeight);
          }
        });
        if (expandValue != "") {
          height += expandValue + 30 + "px ";
        } else if (height1.sort()[0] > 0) {
          height += height1.sort()[0] + "px ";
        }
      });
      document.querySelector('[role="grid"]').style.gridTemplateRows = height;
    }, 500);
  };

  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fetchCropYearAndContract();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
  }, [tokenId, props.GBSContracts]);

  const handleSearch = (query) => {
    if (/^\d+$/.test(query)) {
      setIsLoading(true);
      gbsDataCommonMethod(
        tokenId,
        accounts,
        `/contracts?r.relationshipnumber=${query}`,
        "get",
        {}
      )
        .then((res) => {
          let data = res.data.filter((a) => {
            return [1000, 1001].includes(a.ContractStatus);
          });
          data = res.data.map((i) => {
            i.ContractRelationshipNumber = `${i.RelationshipNumber} ${
              i.Name || ""
            } ${i.Name2 || ""} ${i.Name3 || ""}`;
            return i;
          });
          setContractOptions(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setLoader(false);
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        });
    }
  };

  const onAsyncContractNumberChange = (data) => {
    if (data.length === 1) {
      onClearfields();
      const cropyear_sent = CY_MultiValue?.cropyear;
      const ContractIds = data[0]?.RelationshipId;
      setCN_MultiValue({ contractid: ContractIds });
      setClearBtnDisable(false);
      if (
        ContractIds &&
        ContractIds !== "" &&
        cropyear_sent &&
        cropyear_sent !== ""
      ) {
        setDisabled(false);
        setDisableSubmitCancel(false);
        let url = `pesticides/e-pur?relationshipid=${ContractIds}&cropyear=${cropyear_sent}`;
        paymentSearchURL === url && setChangeEvent(changeEvent + 1);
        setPaymentSearchURL(url);
      }
    }
  };

  const onAsyncContractNumberClear = (onClear) => {
    onClear();
    setCN_MultiValue({ contractid: "" });
    onClearfields();
    setDisabled(true);
    setDisableSubmitCancel(true);
  };

  const AsyncContractNumberReset = () => {
    try {
      if (roleAgScientist) {
        const tempClear = asyncTypeaheadRef?.current?.clear;
        if (IsFunctionChecker(tempClear)) {
          onAsyncContractNumberClear(tempClear);
        }
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  };

  async function fetchCropYearAndContract() {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then((resp) => {
        const response = safeArrayCheck(resp?.data);
        const cropYear = IsArrayNotEmpty(response)
          ? response.splice(0, 15)
          : [];
        if (IsArrayNotEmpty(cropYear)) {
          setCropYears(setOptions(cropYear, "CropYear", true));
          const currentcropData = response.find((x) => x.CurrentCrop === true);
          const getcurrentcrop =
            currentcropData?.CropYear || cropYear[0]?.CropYear;
          const defaultCropyear = `${getcurrentcrop}`;
          if (IsArrayNotEmpty(props?.GBSContracts)) {
            const respContracts = props?.GBSContracts;
            setContractNumbers(
              setOptions(
                respContracts,
                "RelationshipId",
                false,
                "RelationshipNumber",
                "Name"
              )
            );
            const getContract =
              respContracts.length === 1
                ? respContracts[0]?.RelationshipId
                : null;
            const defaultContractNumer = getContract ? `${getContract}` : null;
            if (
              IsStringChecker(defaultCropyear) &&
              IsStringChecker(defaultContractNumer)
            ) {
              let url = `pesticides/e-pur?relationshipid=${defaultContractNumer}&cropyear=${defaultCropyear}`;
              setPaymentSearchURL(url);
              setDisableSubmitCancel(false);
            }
            setMultiSelectReset(false);
            setTimeout(() => {
              IsStringChecker(defaultContractNumer) &&
                setdefContracts([defaultContractNumer]);
              IsStringChecker(defaultContractNumer) &&
                setCN_MultiValue({ contractid: defaultContractNumer });
              setdefCropYearArr([defaultCropyear]);
              setCY_MultiValue({ cropyear: defaultCropyear });
              setClearBtnDisable(false);
              setMultiSelectReset(true);
            }, 0);
          } else {
            // if (IsStringChecker(defaultCropyear)) {
            //   let url = `pesticides/e-pur?relationshipid=${defaultContractNumer}&cropyear=${defaultCropyear}`;
            //   setPaymentSearchURL(url);
            //   setDisableSubmitCancel(false);
            // }
            setMultiSelectReset(false);
            setTimeout(() => {
              setdefCropYearArr([defaultCropyear]);
              setCY_MultiValue({ cropyear: defaultCropyear });
              setClearBtnDisable(false);
              setMultiSelectReset(true);
            }, 0);
          }
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  //crop year select change event
  const onCropYearChange = (val) => {
    onClearfields();
    let cropYears = setObjToString(_.orderBy(val, ["id"], ["asc"]));
    let contracts_sent = CN_MultiValue?.contractid;
    if (
      cropYears &&
      cropYears !== "" &&
      contracts_sent &&
      contracts_sent !== ""
    ) {
      setDisabled(false);
      setDisableSubmitCancel(false);
      let url = `pesticides/e-pur?relationshipid=${contracts_sent}&cropyear=${cropYears}`;
      paymentSearchURL === url && setChangeEvent(changeEvent + 1);
      setPaymentSearchURL(url);
    }
    setClearBtnDisable(false);
  };
  //crop year deselect change event
  const onCropYearRemove = (selectedList, removedItem) => {
    onClearfields();
    if (selectedList.length === 0 || removedItem.id === "all") {
      // AsyncContractNumberReset();
      setClearBtnDisable(CN_MultiValue?.contractid ? false : true);
      setDisabled(true);
      setDisableSubmitCancel(true);
    } else {
      let cropYears = setObjToString(_.orderBy(selectedList, ["id"], ["asc"]));
      let contracts_sent = CN_MultiValue?.contractid;
      if (
        cropYears &&
        cropYears !== "" &&
        contracts_sent &&
        contracts_sent !== ""
      ) {
        let url = `pesticides/e-pur?relationshipid=${contracts_sent}&cropyear=${cropYears}`;
        paymentSearchURL === url && setChangeEvent(changeEvent + 1);
        setPaymentSearchURL(url);
      }
      setClearBtnDisable(false);
    }
  };

  // Contract select change event
  const onContractNumberChange = (val) => {
    onClearfields();
    let ContractIds = setObjToString(_.orderBy(val, ["id"], ["asc"]));
    let cropyear_sent = CY_MultiValue?.cropyear;
    if (
      ContractIds &&
      ContractIds !== "" &&
      cropyear_sent &&
      cropyear_sent !== ""
    ) {
      setDisabled(false);
      setDisableSubmitCancel(false);
      let url = `pesticides/e-pur?relationshipid=${ContractIds}&cropyear=${cropyear_sent}`;
      paymentSearchURL === url && setChangeEvent(changeEvent + 1);
      setPaymentSearchURL(url);
    }
    setClearBtnDisable(false);
  };

  // Contract deselect change event
  const onContractNumberRemove = (selectedList, removedItem) => {
    onClearfields();
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      setClearBtnDisable(CY_MultiValue?.cropyear ? false : true);
      setDisableSubmitCancel(true);
    } else {
      setDisabled(false);
      setDisableSubmitCancel(false);
      let ContractIds = setObjToString(
        _.orderBy(selectedList, ["id"], ["asc"])
      );
      let cropyear_sent = CY_MultiValue?.cropyear;
      if (
        ContractIds &&
        ContractIds !== "" &&
        cropyear_sent &&
        cropyear_sent !== ""
      ) {
        let url = `pesticides/e-pur?relationshipid=${ContractIds}&cropyear=${cropyear_sent}`;
        paymentSearchURL === url && setChangeEvent(changeEvent + 1);
        setPaymentSearchURL(url);
      }
      setClearBtnDisable(false);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    let filter = [...allFilterData];
    if (multiValue?.BogName) {
      let a = [];
      multiValue?.BogName.split(",").map((i) => {
        let fil = filter.filter((item) => {
          return (
            item.BogName?.toLowerCase()?.split(",").indexOf(i.toLowerCase()) >
              -1 || false
          );
        });
        a.push(fil);
      });
      filter = _.uniqWith([...a.flat()], _.isEqual);
    }
    if (multiValue?.HarvestBed) {
      let a = [];
      multiValue?.HarvestBed.split(",").map((i) => {
        let fil = filter.filter((item) => {
          return (
            item.HarvestBedList?.toLowerCase()
              ?.split(",")
              .indexOf(i.toLowerCase()) > -1 || false
          );
        });
        a.push(fil);
      });
      filter = _.uniqWith([...a.flat()], _.isEqual);
    }
    if (multiValue?.PesticideName) {
      filter = filter.filter((item) => {
        return (
          multiValue?.PesticideName?.toLowerCase()?.indexOf(
            item?.PesticideName?.toString()?.toLowerCase()
          ) !== -1
        );
      });
    }

    if (multiValue?.PesticideName) {
      filter = filter.filter((item) => {
        return (
          multiValue?.PesticideName?.toLowerCase()?.indexOf(
            item?.PesticideName?.toString()?.toLowerCase()
          ) !== -1
        );
      });
    }
    if (applicationFromDate || applicationToDate) {
      filter = filter.filter((item) => {
        let dateFrom =
          applicationFromDate &&
          moment(applicationFromDate).format(dateFormate);
        let dateto =
          applicationToDate && moment(applicationToDate).format(dateFormate);
        let ApplicationDate = moment(item.AppliedDate).format(dateFormate);
        return moment(ApplicationDate).isBetween(
          dateFrom,
          dateto,
          undefined,
          "[]"
        );
      });
    }

    if (filter.length > 0) {
      setPesticideHistorySearchResult(filter);
      setPesticideHistorySearchResultStatus(true);
      setNoResultsFound(false);
    } else {
      setPesticideHistorySearchResult([]);
      setPesticideHistorySearchResultStatus(false);
      setNoResultsFound(true);
    }
    setLoading(false);
    handlerMobFilter("cancel");
  };

  const setMultiselectData = (filterData) => {
    onClearfields();
    let beds = filterData.map((i) => i.Beds);
    let bogName = [];
    let harvestBed = [];
    try {
      Object.keys(beds).map((i) =>
        beds[i]?.map((k) => {
          k.BogName && bogName.push({ name: k.BogName, id: k.BogName });
          k.HarvestBed &&
            harvestBed.push({ name: k.HarvestBed, id: k.HarvestBed });
        })
      );
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      bogName = [];
      harvestBed = [];
    }

    setBogName(_.uniqWith(getBedBogName(filterData, "BogName"), _.isEqual));
    setHarvestBed(
      sortingObject(
        _.uniqWith(getBedBogName(filterData, "HarvestBed"), _.isEqual),
        "name"
      )
    );
    setPEsticideName(
      setOptions(_.uniqWith([...filterData], _.isEqual), "PesticideName", true)
    );
  };

  const sortingObject = (data, propertyname) => {
    return data.sort(function (a, b) {
      return a[propertyname].localeCompare(b[propertyname], undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  };
  const getBedBogName = (data, property) => {
    let beds = data.map((i) => i.Beds);
    let selectOptionObj = [];
    try {
      Object.keys(beds).map((i) =>
        beds[i]?.map((k) => {
          k[property] &&
            selectOptionObj.push({ name: k[property], id: k[property] });
        })
      );
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      selectOptionObj = [];
    }
    return selectOptionObj;
  };
  const filter = (data, filterText, property) => {
    if ("BogName" === property || "HarvestBed" === property) {
      if ("HarvestBed" === property) property = "HarvestBedList";
      let a = [];
      filterText.split(",").map((i) => {
        let fil = data.filter((item) => {
          return (
            item[property]?.toLowerCase()?.split(",").indexOf(i.toLowerCase()) >
              -1 || false
          );
        });
        a.push(fil);
      });
      data = _.uniqWith([...a.flat()], _.isEqual);
      return data;
    } else
      return data.filter((i) => {
        return filterText.indexOf(i[property]) > -1;
      });
  };

  function cascadingFilter() {
    if (arguments[8] === "select") {
      if (!(arguments[13] >= forwordOrder)) {
        setForwordOrder(arguments[13]);
        setMultiselectData(arguments[1]);
        let a = {};
        setMultiValue(null);
        a[arguments[3]] = setObjToString(arguments[2]);
        arguments[11]("");
        arguments[12]("");
        arguments[10](setObjToString(arguments[2])?.split(","));
        setMultiValue({ ...a });
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        if ("BogName" === arguments[3] || "HarvestBed" === arguments[3]) {
          arguments[6](
            sortingObject(
              _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual),
              "name"
            )
          );
          arguments[7](
            sortingObject(
              setOptions(
                _.uniqWith(arguments[1], _.isEqual),
                arguments[5],
                true
              ),
              "name"
            )
          );
        } else {
          arguments[6](
            sortingObject(
              _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual),
              "name"
            )
          );
          arguments[7](
            sortingObject(
              _.uniqWith(getBedBogName(arguments[1], arguments[5]), _.isEqual),
              "name"
            )
          );
        }
        setClearstatusOther(true);
        setTimeout(() => {
          setClearstatusOther(false);
        }, 0);
        return;
      }
      setForwordOrder(arguments[13]);
      if (
        arguments[0].hasOwnProperty(arguments[4]) == false &&
        arguments[0].hasOwnProperty(arguments[5]) == false
      ) {
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        if ("BogName" === arguments[3] || "HarvestBed" === arguments[3]) {
          arguments[6](
            sortingObject(
              _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual),
              "name"
            )
          );
          arguments[7](
            sortingObject(
              setOptions(
                _.uniqWith(arguments[1], _.isEqual),
                arguments[5],
                true
              ),
              "name"
            )
          );
        } else {
          arguments[6](
            sortingObject(
              _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual),
              "name"
            )
          );
          arguments[7](
            sortingObject(
              _.uniqWith(getBedBogName(arguments[1], arguments[5]), _.isEqual),
              "name"
            )
          );
        }
      } else if (
        arguments[0].hasOwnProperty(arguments[4]) == true &&
        arguments[0].hasOwnProperty(arguments[5]) == false
      ) {
        arguments[1] = filter(
          arguments[1],
          arguments[0][arguments[4]],
          arguments[4]
        );
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[7](
          setOptions(_.uniqWith(arguments[1], _.isEqual), arguments[5], true)
        );
      }
    } else {
      if (!(arguments[13] >= forwordOrder)) {
        setForwordOrder(arguments[13]);
        setMultiselectData(arguments[1]);
        let a = {};
        setMultiValue(null);
        a[arguments[3]] = setObjToString(arguments[2]);
        arguments[11]("");
        arguments[12]("");
        arguments[10](setObjToString(arguments[2])?.split(","));
        setMultiValue({ ...a });
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        if ("BogName" === arguments[3] || "HarvestBed" === arguments[3]) {
          arguments[6](
            _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual)
          );
          arguments[7](
            setOptions(_.uniqWith(arguments[1], _.isEqual), arguments[5], true)
          );
        } else {
          arguments[6](
            _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual)
          );
          arguments[7](
            _.uniqWith(getBedBogName(arguments[1], arguments[5]), _.isEqual)
          );
        }
        setClearstatusOther(true);
        setTimeout(() => {
          setClearstatusOther(false);
        }, 0);
        return;
      }
      setForwordOrder(arguments[13]);
      if (
        arguments[0].hasOwnProperty(arguments[4]) == false &&
        arguments[0].hasOwnProperty(arguments[5]) == false
      ) {
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        if ("BogName" === arguments[3] || "HarvestBed" === arguments[3]) {
          arguments[6](
            _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual)
          );
          arguments[7](
            setOptions(_.uniqWith(arguments[1], _.isEqual), arguments[5], true)
          );
        } else {
          arguments[6](
            _.uniqWith(getBedBogName(arguments[1], arguments[4]), _.isEqual)
          );
          arguments[7](
            _.uniqWith(getBedBogName(arguments[1], arguments[5]), _.isEqual)
          );
        }
      } else if (
        arguments[0].hasOwnProperty(arguments[4]) == true &&
        arguments[0].hasOwnProperty(arguments[5]) == false
      ) {
        arguments[1] = filter(
          arguments[1],
          arguments[0][arguments[4]],
          arguments[4]
        );
        if (arguments[2].length > 0) {
          arguments[1] = filter(
            arguments[1],
            setObjToString(arguments[2]),
            arguments[3]
          );
        }
        arguments[7](
          setOptions(_.uniqWith(arguments[1], _.isEqual), arguments[5], true)
        );
      }
    }
  }

  const handlerOtherFilter = (selectedList, selectedItem, property, option) => {
    let filterArray = [...allFilterData];
    let mutiSelectVal =
      option === "deselect" || multiValue === null ? {} : multiValue;
    switch (property) {
      case "BogName":
        cascadingFilter(
          mutiSelectVal,
          filterArray,
          selectedList,
          "BogName",
          "HarvestBed",
          "PesticideName",
          setHarvestBed,
          setPEsticideName,
          option,
          setBogName,
          setPreselectValuesBogName,
          setPreselectValuesHarvestBed,
          setPreselectValuesPesticideName,
          1
        );
        break;
      case "HarvestBed":
        cascadingFilter(
          mutiSelectVal,
          filterArray,
          selectedList,
          "HarvestBed",
          "BogName",
          "PesticideName",
          setBogName,
          setPEsticideName,
          option,
          setHarvestBed,
          setPreselectValuesHarvestBed,
          setPreselectValuesBogName,
          setPreselectValuesPesticideName,
          2
        );
        break;
      case "PesticideName":
        cascadingFilter(
          mutiSelectVal,
          filterArray,
          selectedList,
          "PesticideName",
          "BogName",
          "HarvestBed",
          setBogName,
          setHarvestBed,
          option,
          setPEsticideName,
          setPreselectValuesPesticideName,
          setPreselectValuesBogName,
          setPreselectValuesHarvestBed,
          3
        );
        break;
    }
    setPesticideHistorySearchResult([]);
    setPesticideHistorySearchResultStatus(false);
    setNoResultsFound(false);
  };

  const onClearfields = () => {
    setForwordOrder(0);
    setPesticideHistorySearchResult([]);
    setPesticideHistorySearchResultStatus(false);
    setMultiValue(null);
    setBogName([]);
    setHarvestBed([]);
    setPEsticideName([]);
    setNoResultsFound(false);
    setPreselectValuesBogName("");
    setPreselectValuesHarvestBed("");
    setPreselectValuesPesticideName("");
  };

  const clearAll = () => {
    setForwordOrder(0);
    setPesticideHistorySearchResult([]);
    setPesticideHistorySearchResultStatus(false);
    setMultiValue(null);
    setBogName([]);
    setHarvestBed([]);
    setPEsticideName([]);
    setNoResultsFound(false);
    setPreselectValuesBogName("");
    setPreselectValuesHarvestBed("");
    setPreselectValuesPesticideName("");

    AsyncContractNumberReset();

    setApplicationFromDate("");
    setApplicationToDate("");
    setDisabled(true);
    setDisableSubmitCancel(true);

    setCY_MultiValue(null);
    setCN_MultiValue(null);
    setClearBtnDisable(true);
    setdefCropYearArr([]);
    setdefContracts([]);
  };

  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  // creating option for mulit select
  const setOptions = (
    data,
    keyPrram,
    unique,
    concatValue1 = "",
    concatValue2 = ""
  ) => {
    if (unique && keyPrram !== "RelationshipId" && concatValue1 === "") {
      return data
        .map((li) => li[keyPrram])
        .filter(onlyUnique)
        .map((li) => {
          return { name: li, id: li };
        });
    } else {
      let contracts = [];
      if (concatValue1 !== "" && concatValue2 !== "") {
        contracts = data.map((li) => {
          return concatValue1 && concatValue2
            ? {
                name: li[concatValue1] + " " + li[concatValue2],
                id: li[keyPrram],
              }
            : { name: li[keyPrram], id: li[keyPrram] };
        });
      } else if (concatValue1 !== "") {
        contracts = data.map((li) => {
          return { name: li[concatValue1], id: li[keyPrram] };
        });
      }
      return [...new Map(contracts.map((item) => [item["id"], item])).values()];
    }
  };

  // Mobile filter change event
  const [isMobFilter, setisMobFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  return (
    <>
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={clearAll}>
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 p-1 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock payment-page-mobile-filter ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="crop_year_label custom_dropdown mb-2 mb-lg-3">
                {multiSelectReset && (
                  <MultiDropDown
                    optionList={CropYears}
                    prompt="Select"
                    value={CY_MultiValue}
                    onChange={(selectedList, selectedItem) =>
                      onCropYearChange(selectedList, selectedItem)
                    }
                    onDelete={(selectedList, removedItem) =>
                      onCropYearRemove(selectedList, removedItem)
                    }
                    label="cropyear"
                    usestate={setCY_MultiValue}
                    preSelectedOpt={defCropYearArr}
                    disabled=""
                  />
                )}
              </div>
            </div>
            {roleAgScientist ? (
              <div className="col-12 col-lg-3 personal_info">
                <div className="position-relative mb-2 mb-lg-3 mb-lg-0">
                  <span className="text-14 z-index-1">
                    Contract Number & Name
                  </span>
                  {/* {fieldReset && */}
                  <AsyncTypeahead
                    // clearButton
                    ref={asyncTypeaheadRef}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey="ContractRelationshipNumber"
                    minLength={3}
                    onSearch={handleSearch}
                    options={contractOptions}
                    placeholder="Search contract number"
                    // filterBy={["RelationshipNumber", "Name","Name2","Name3"]}
                    className="AG_contracts"
                    onChange={onAsyncContractNumberChange}
                  >
                    {({ onClear, selected }) =>
                      !!selected.length && (
                        <div className="rbt-aux">
                          <ClearButton
                            onClick={() => onAsyncContractNumberClear(onClear)}
                          />
                        </div>
                      )
                    }
                  </AsyncTypeahead>
                  {/* } */}
                  {/* <input type="text" aria-label="epa-pcp" className="form-control mb-3" /> */}
                </div>
              </div>
            ) : (
              <div className="col-12 col-lg-3">
                <div className="contact_number_Label custom_dropdown mb-2 mb-lg-3">
                  {multiSelectReset && (
                    <MultiDropDown
                      optionList={contractNumbers}
                      prompt="Select"
                      value={CN_MultiValue}
                      onChange={(selectedList, selectedItem) => {
                        onContractNumberChange(selectedList, selectedItem);
                      }}
                      label="contractid"
                      usestate={setCN_MultiValue}
                      onDelete={(selectedList, removedItem) =>
                        onContractNumberRemove(selectedList, removedItem)
                      }
                      disabled={false}
                      preSelectedOpt={defContracts}
                      optionNotFound={{ contractid: noContractsFound }}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="col-12 col-lg-3">
              <div className="bog_Name_Label custom_dropdown mb-2 mb-lg-3">
                {!clearstatusOther && (
                  <MultiDropDown
                    optionList={bogName}
                    prompt="Select"
                    value={multiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "BogName",
                        "select"
                      )
                    }
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "BogName",
                        "deselect"
                      )
                    }
                    label="BogName"
                    usestate={setMultiValue}
                    disabled={false}
                    preSelectedOpt={preselectValuesBogName}
                    optionNotFound={{
                      BogName:
                        moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="harvest_bed_Label custom_dropdown mb-2 mb-lg-3">
                {!clearstatusOther && (
                  <MultiDropDown
                    optionList={harvestBed}
                    prompt="Select"
                    value={multiValue}
                    label="HarvestBed"
                    usestate={setMultiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "HarvestBed",
                        "select"
                      )
                    }
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "HarvestBed",
                        "deselect"
                      )
                    }
                    disabled={false}
                    preSelectedOpt={preselectValuesHarvestBed}
                    optionNotFound={{
                      HarvestBed:
                        moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="pesticide_Label custom_dropdown mb-2 mb-lg-3">
                {!clearstatusOther && (
                  <MultiDropDown
                    optionList={pesticideName}
                    prompt="Select"
                    value={multiValue}
                    label="PesticideName"
                    usestate={setMultiValue}
                    onChange={(selectedList, selectedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        selectedItem,
                        "PesticideName",
                        "select"
                      )
                    }
                    onDelete={(selectedList, removedItem) =>
                      handlerOtherFilter(
                        selectedList,
                        removedItem,
                        "PesticideName",
                        "deselect"
                      )
                    }
                    disabled={false}
                    preSelectedOpt={preselectValuesPesticideName}
                    optionNotFound={{
                      PesticideName:
                        moreLangs[i18n.resolvedLanguage].ePUR.optionNotFound,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="position-relative mb-2 mb-lg-3 mb-lg-0 datepicker-field">
                <span className="lable-text text-14 z-index-2">Date From</span>
                <DatePickerComp
                  id="fromData"
                  selected={applicationFromDate}
                  onChange={(date) => setApplicationFromDate(date)}
                  placeholderText={dateFormate}
                  className="datepicker_input form-control z-index-1"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // maxDate={new Date()}
                />
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="position-relative mb-2 mb-lg-3 mb-lg-0 datepicker-field">
                <span className="lable-text text-14 z-index-2">Date To</span>
                <DatePickerComp
                  id="applicationDate1"
                  selected={applicationToDate}
                  onChange={(date) => setApplicationToDate(date)}
                  placeholderText={dateFormate}
                  className="datepicker_input form-control z-index-1"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // maxDate={new Date()}
                />
              </div>
            </div>
          </div>
          <div
            className={`bottom-0  filter_apply_button white_bg ${
              isMobFilter ? "show d-block " : " d-lg-none "
            }`}
          >
            {/* <button className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none" disabled="">Apply   </button> */}
            <button
              className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
              onClick={onSubmit}
              disabled={disableSubmitCancel}
            >
              Apply{!loading ? "" : <WhiteCircleLoader />}{" "}
            </button>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                disabled={disableSubmitCancel}
                onClick={() => onSubmit()}
              >
                Search{!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={() => clearAll()}
                disabled={clearBtnDisable}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </nav>
      {pesticideHistorySearchResult.length > 0 &&
        pesticideHistorySearchResultStatus &&
        !displayAccordian && (
          <div
            ref={myRef}
            className="expanded-row hor-space-20 top-space-20 light_bg  mb-lg-4 bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table"
          >
            <DataGridPagination
              uniqueIdentifier="PaymentId"
              gridData={pesticideHistorySearchResult}
              columns={columns}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
              autoRowHeight={true}
              dynamicHeaderHeight={80}
              // summaryRows = {summaryRows}
            />
          </div>
        )}

      {pesticideHistorySearchResult.length > 0 &&
        pesticideHistorySearchResultStatus && (
          <div
            className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${
              !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
            }`}
          >
            <div className="e-purEdit delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
              <Accordion>
                {pesticideHistorySearchResult.length > 0 &&
                  Object.values(pesticideHistorySearchResult).map((data) => {
                    return (
                      <Fragment key={data.Id}>
                        <Accordion.Item eventKey={data.Id}>
                          <Accordion.Header>
                            <div>
                              Contract #: {data.RelationshipNumber} <br />
                              Application Date: {dateFormat(data.AppliedDate)}
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="tbody">
                              <div className="tr">
                                <div className="td" data-header="expand_icon">
                                  <i className="fa fa-plus-circle cursor_pointer table_accordion"></i>
                                </div>
                                <div className="td" data-header="Contract #:">
                                  <span>
                                    {data.RelationshipNumber} - {data.Name}
                                  </span>
                                </div>
                                <div
                                  className="td"
                                  data-header="Application Date:"
                                >
                                  <span>{dateFormat(data.AppliedDate)}</span>
                                </div>
                                <div
                                  className="td"
                                  data-header="EPA or PCP Reg.#/Chemical:"
                                >
                                  <span>
                                    {data.PesticideCode}-{data.PesticideName}
                                  </span>
                                </div>
                                <div
                                  className="td"
                                  data-header="Rate(formulated product per acre):"
                                >
                                  <span>{data.Rate}</span>
                                </div>
                                <div
                                  className="td"
                                  data-header="Rate unit of measure per acres(e.g. oz/acre):"
                                >
                                  <span>{data.Description}</span>
                                </div>
                                <div
                                  className="td"
                                  data-header="Method of Application:"
                                >
                                  <span>{data.MethodName}</span>
                                </div>
                                <div className="td" data-header="Fruit Type:">
                                  <span>{data.TypeName}</span>
                                </div>
                                <div className="td" data-header="Bed(s):">
                                  <span>
                                    {data.HarvestBedList}
                                    <span>
                                      {(data.HarvestBedList.indexOf("...") >
                                        -1 ||
                                        data.HarvestBedListIsExpanded) && (
                                        <button
                                          title={
                                            !data.HarvestBedListIsExpanded
                                              ? "Show more"
                                              : "Hide"
                                          }
                                          className="show-more"
                                          onClick={() => showMore(data)}
                                        >
                                          {!data.HarvestBedListIsExpanded
                                            ? "+"
                                            : "-"}
                                        </button>
                                      )}{" "}
                                    </span>
                                  </span>
                                </div>
                                <div className="td" data-header="Total Acres:">
                                  <span>{toDecimal(data.ExhibitAcres, 2)}</span>
                                </div>
                                <div
                                  className="td"
                                  data-header="Treated Acres:"
                                >
                                  <span>{toDecimal(data.AcresTreated, 2)}</span>
                                </div>
                                <div className="td" data-header="Status:">
                                  {data.IsFinalise ? (
                                    <span className="light_green_color">
                                      Submitted
                                    </span>
                                  ) : (
                                    <span className="red_dark_color">
                                      Not Submitted
                                    </span>
                                  )}
                                </div>
                                <div
                                  className="td "
                                  data-header="PUR Contact Email:"
                                >
                                  <span className="emailBreak">
                                    {data.PURContactEmail}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Fragment>
                    );
                  })}
              </Accordion>
            </div>
          </div>
        )}
      {/* no record found section start */}
      {pesticideHistorySearchResult.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4">
          <i className="fa fa-info-circle pe-2"></i>
          {moreLangs[i18n.resolvedLanguage].ePUR.noRecordFound}
        </div>
      )}
      {/* no record found section End */}
    </>
  );
};

export default PesticideUseHistory;
