import { getFilteredData, setOptions, setObjToString } from "./helper";
/**
 * @function: cascadeFilterFunc
 * @description: 
 * @author:Ashish Ranjan
 */
export const cascadeFilterFunc = ({ ...param }) => {

  let { filter, selectedList, action, request, multiContId, multival, searchParam, setBogNameOption = '', setHarvestbedOption = '', setVerityOption = '', setMapIdOption = '' } = { ...param };
  
  /*console.log("param",param);  
    console.log("setVerityOption",setVerityOption) */
  //console.log("setMapIdOption", setMapIdOption)
  //console.log("multival",multival)//mapids
  
  if (action === 'selected') {
    if (request == 'bogname') {
      /*-------------------------------------------
            -----This block is for "Select Bogname"-----
            ---------------------------------------------*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('varityids') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("bogname both no") */
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
  
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
  
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));

        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
  
      } else if (multival.hasOwnProperty('varityids') == true && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('mapids') == true) {
        /* //console.log("bogname both yes") */
  
      } else if (multival.hasOwnProperty('varityids') == true && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("bogname only varityid") */
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        filter = getFilteredData(filter, multival.varityids, searchParam.Varity_id[0]);
  
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
  
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
  
      }else if (multival.hasOwnProperty('varityids') == false && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("bogname only harvestbed") */
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        filter = getFilteredData(filter, multival.harvestbeds, searchParam.Harvest_bed[0]);
  
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
  
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      }else if (multival.hasOwnProperty('varityids') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == true) {
        /* console.log("bogname varityids=no, harvestbed=no, mapids=yes") */
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        filter = getFilteredData(filter, multival.mapids, searchParam.Map_id[0]);
  
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
  
      }else if (multival.hasOwnProperty('varityids') == true && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('mapids') == false) {
        /* console.log("bogname varityids=yes, harvestbed=yes, mapids=no") */
      }else if (multival.hasOwnProperty('varityids') == true && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == true) {
        /* console.log("bogname varityids=yes, harvestbed=no, mapids=yes") */
      }else if (multival.hasOwnProperty('varityids') == false && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('mapids') == true) {
        /* console.log("bogname varityids=no, harvestbed=yes, mapids=yes") */
      }
  
    } else if (request == 'harvestbed') {
      /*-------------------------------------------
            ---This block is for "Select Harvestbed"---
            ---------------------------------------------*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('varityids') == false) {
        /* //console.log("harvestbed both no") */
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Harvest_bed[0]);
  
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
  
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('varityids') == true) {
        /* //console.log("harvestbed both yes") */
  
  
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('varityids') == false) {
        /* //console.log("harvestbed only bog") */
        filter = getFilteredData(filter, multival.bognames, searchParam.Bog_name[0]);
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Harvest_bed[0]);
  
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
  
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('varityids') == true) {
  
        /* //console.log("harvestbed only varityid") */
        filter = getFilteredData(filter, multival.varityids, searchParam.Varity_id[0]);
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Harvest_bed[0]);
  
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
      }
  
    } else if (request == 'varityid') {
  
      /*-------------------------------------------
            -----This block is for "Select varityid"-----
            ---------------------------------------------*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == false) {
        /*  console.log("varityid both no")*/
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Varity_id[0]);
  
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
  
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('mapids') == true) {
  
        /* //console.log("varityid both yes") */
  
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("varityid only bog") */
        filter = getFilteredData(filter, multival.bognames, searchParam.Bog_name[0]);
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Varity_id[0]);
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
  
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('mapids') == false) {
  
        /* //console.log("varityid only harvestbed") */
        filter = getFilteredData(filter, multival.harvestbeds, searchParam.Harvest_bed[0]);
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Varity_id[0]);
  
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
      }else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == true) {
        /* console.log("bogname varityids=no, harvestbed=no, mapids=yes") */
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        filter = getFilteredData(filter, multival.mapids, searchParam.Map_id[0]);
    
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));  
      }
    }else if (request == 'mapid') {
  
      /*-------------------------------------------
            -----This block is for "Select mapid"-----
            ---------------------------------------------*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('varityids') == false) {
        /*  console.log("varityid both no")*/
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Map_id[0]);
  
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
  
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('varityids') == true) {
  
        /* //console.log("varityid both yes") */
  
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('varityids') == false) {
        /* //console.log("varityid only bog") */
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Map_id[0]);
        filter = getFilteredData(filter, multival.bognames, searchParam.Bog_name[0]);
        
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
       
  
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('varityids') == true) {
        /* console.log("bogname varityids=no, harvestbed=no, mapids=yes") */ 
        filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Map_id[0]);       
        filter = getFilteredData(filter, multival.varityids, searchParam.Varity_id[0]);
    
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));  
      }
    }
  } else if (action === 'deselected') {
  
    /*=========================================
              This block is for " Deselected "
          ==========================================*/
  
    if (request == 'bogname') {
      /*=========================================
              This block is for " Deselected bogname"
            ==========================================*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('varityids') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("bogname, harvestbed= no, varityid = no") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        }
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      } else if (multival.hasOwnProperty('varityids') == true && multival.hasOwnProperty('harvestbeds') == false  && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("bogname, harvestbed= no, varityid = yes") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        }
        filter = getFilteredData(filter, multival.varityids, searchParam.Varity_id[0]);
  
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
  
      } else if (multival.hasOwnProperty('varityids') == false && multival.hasOwnProperty('harvestbeds') == true && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("bogname, harvestbed= yes, varityid = no") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        }
        filter = getFilteredData(filter, multival.harvestbeds, searchParam.Harvest_bed[0]);
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      } else if (multival.hasOwnProperty('varityids') == false && multival.hasOwnProperty('harvestbeds') == false && multival.hasOwnProperty('mapids') == true) {
        /* //console.log("bogname, harvestbed= yes, varityid = no") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Bog_name[0]);
        }
        filter = getFilteredData(filter, multival.mapids, searchParam.Map_id[0]);
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      }
  
    } else if (request == 'harvestbed') {
      /*=========================================
              This block is for " Deselected harvestbed"
            ==========================================*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('varityids') == false) {
        /* //console.log("harvestbed bogname no, varityid = no") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Harvest_bed[0]);
        }
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('varityids') == false) {
        /* //console.log("harvestbed bog yes, varityid = no") */
        filter = getFilteredData(filter, multival.bognames, searchParam.Bog_name[0]);
  
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Harvest_bed[0]);
        }
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('varityids') == true) {
        /* //console.log("harvestbed bog = no, varityid = yes") */
        filter = getFilteredData(filter, multival.varityids, searchParam.Varity_id[0]);
  
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Harvest_bed[0]);
        }
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
      }
  
    } else if (request == 'varityid') {
      /*=========================================
              This block is for " Deselected varityid"
            ==========================================*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('harvestbeds') == false  && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("varityid bog = yes, harvestbed = no") */
        filter = getFilteredData(filter, multival.bognames, searchParam.Bog_name[0]);
  
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Varity_id[0]);
        }
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
  
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == true  && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("varityid bog = no, harvestbed = yes") */
        filter = getFilteredData(filter, multival.harvestbeds, searchParam.Harvest_bed[0]);
  
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Varity_id[0]);
        }
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
  
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == false  && multival.hasOwnProperty('mapids') == false) {
        /* //console.log("varityid both no") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Varity_id[0]);
        }
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setMapIdOption!='' && setMapIdOption(setOptions(filter, searchParam.Map_id[0], searchParam.Map_id[1])));
        (setHarvestbedOption != '' && setHarvestbedOption(setOptions(filter, searchParam.Harvest_bed[0], searchParam.Harvest_bed[1])));
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('harvestbeds') == false  && multival.hasOwnProperty('mapids') == true) {
        /* //console.log("bogname, harvestbed= yes, varityid = no") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Varity_id[0]);
        }
        filter = getFilteredData(filter, multival.mapids, searchParam.Map_id[0]);
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
      }
    } else if (request == 'mapid') {
      /*=========================================
              This block is for " Deselected mapid"
            ==========================================*/
  
      filter = getFilteredData(filter, multiContId.contract_id, searchParam.Contract_id[0]);
  
      if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('varityids') == false) {
        /* //console.log("harvestbed bogname no, varityid = no") */
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Map_id[0]);
        }
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      } else if (multival.hasOwnProperty('bognames') == true && multival.hasOwnProperty('varityids') == false) {
        /* //console.log("harvestbed bog yes, varityid = no") */
        filter = getFilteredData(filter, multival.bognames, searchParam.Bog_name[0]);
  
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Map_id[0]);
        }
        (setVerityOption != '' && setVerityOption(setOptions(filter, searchParam.Varity_id[0], searchParam.Varity_id[1], searchParam.Varity_id[2],  searchParam.Varity_id[3])));
      } else if (multival.hasOwnProperty('bognames') == false && multival.hasOwnProperty('varityids') == true) {
        /* //console.log("harvestbed bog = no, varityid = yes") */
        filter = getFilteredData(filter, multival.varityids, searchParam.Varity_id[0]);
  
        if (selectedList.length > 0) {
          filter = getFilteredData(filter, setObjToString(selectedList), searchParam.Map_id[0]);
        }
        (setBogNameOption != '' && setBogNameOption(setOptions(filter, searchParam.Bog_name[0], searchParam.Bog_name[1])));
      }  
    }
  }
}