/**
 * @component_Name : Contract PerformanceData
 * @description : Show contracts and downloads respective documents
 * @company : Photon
 * @author : Ashish Ranjan/22-07-2022
 **/

import React, { useEffect, useState } from "react";
import "../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  getCurrentYear,
  convertFilterArray,
  IsTokenChecker,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  IsStringChecker,
  safeArrayCheck,
} from "../../../utils/helper";
import { ConstantsVar, GbsBaseUrl } from "../../../utils/constants";
import WhiteCircleLoader from "../../whiteCircleLoader";
import DataGridPagination from "../../global/dataGridPagination";
import ContractPerformanceMobile from "./contractPerformanceMobile";
import Moment from "react-moment";
import Loading from "../../../components/global/loading";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import _ from "lodash";
import moment from "moment";

const ContractPerformanceData = (props) => {
  const { DropDownCBRoles = [], noContractsFound = "" } = props;

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const [listCropYear, setListCropYear] = useState([]);
  const [ListContracts, setListContracts] = useState([]);
  const [multiCrop, setMultiCrop] = useState({});
  const [multiContId, setMultiContId] = useState([]);
  const [preSelectedArray, setPreSelectedArray] = useState([]);
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [gridItems, setGridItems] = useState([]);
  const [resultFilter, setResultFilter] = useState({});
  // const [disablePreSelected, setDisablePreSelected] = useState(true);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [isReset, setIsReset] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const ongoingYear = getCurrentYear();
  const [preSelectedContracts, setPreSelectedContracts] = useState([]);
  const [reloadContract, setReloadcontract] = useState(true);
  const [reloadCropyear, setReloadCropyear] = useState(true);

  const columns = [
    {
      key: "FileName",
      name: "Crop Year",
      width: "10%",
      formatter(props) {
        return <>{props.row.FileName.substr(-9).substr(0, 4)}</>;
      },
    },
    {
      key: "RelationshipNumber",
      name: "Contract #",
      width: "10%",
    },
    {
      key: "Name",
      name: "Contract Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: "25%",
    },
    {
      key: "ReportName",
      name: "Report Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: "35%",
    },
    {
      key: "ReportDate",
      name: "Report Date",
      headerCellClass: "enablesTextWrappingHeading",
      width: "10%",
      formatter(props) {
        return (
          <>
            {
              <Moment utc format={ConstantsVar.dateFormate}>
                {props.row.ReportDate}
              </Moment>
            }
          </>
        );
      },
    },
    {
      key: "Url",
      name: "Download Excel",
      headerCellClass: "enablesTextWrappingHeading",
      width: "10%",
      formatter(props) {
        return (
          <>
            {
              <span>
                <a
                  href={GbsBaseUrl + props.row.Url}
                  target={"_blank"}
                  className="border-1 border-radius-6 csvIconPadding"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Export"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-file-excel-o text-16 excelIconPadding position-relative"
                    alt="ExcelIcon"
                  ></i>
                </a>
              </span>
            }
          </>
        );
      },
    },
  ];

  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      setLoader(true);
      SetCropYearExistingData();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const SetCropYearExistingData = async () => {
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/${personId}/contracts`
    )
      .then(async (resp) => {
        const data = safeArrayCheck(resp?.data);
        let filteredData = FilterContractByRole(data, accounts, DropDownCBRoles);
        filteredData = _.orderBy(filteredData,
          ["ContractStatus", "RelationshipNumber"],
          ["asc", "asc"]
        );
        if (IsArrayNotEmpty(filteredData)) {
          setListContracts(filteredData);
          await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
            .then(async (res) => {
              const cdata = safeArrayCheck(res?.data);
              const cropYear = cdata.length > 0 && cdata.splice(0, 15);
              if (IsArrayNotEmpty(cropYear)) {
                let qCropyear = cropYear.map((li) => li.CropYear);
                const urlArray = filteredData.map(
                  (x) =>
                    `contracts/${x?.RelationshipId}/scorecards?cropyear=${qCropyear}`
                );
                await getDDContractPerformMultiContract(
                  tokenId,
                  accounts,
                  urlArray
                )
                  .then((response) => {
                    const gridArray = Object.values(response).map((item) => ({
                      CropYear: getCropYearFromFileName(item?.FileName),
                    }));
                    const setCropYear = cropYear.filter((li) => {
                      const exist = gridArray.find(
                        (list) => list.CropYear == li.CropYear
                      );
                      return exist;
                    });
                    const cropDatas = setCropYear.find(
                      (x) => x.CurrentCrop === true
                    )?.CropYear;
                    const years = setCropYear.map((li) => li?.CropYear);
                    const currentcropData =
                      cropDatas || `${Math.max(...years)}`;
                    setListCropYear(setCropYear);
                    const contra =
                      filteredData.length === 1
                        ? filteredData[0]?.RelationshipId
                        : false;
                    setReloadcontract(false);
                    setReloadCropyear(false);
                    setTimeout(() => {
                      if (contra && IsStringChecker(currentcropData)) {
                        setMultiContId({
                          contractid: `${contra}`,
                        });
                        setPreSelectedContracts([`${contra}`]);
                        setMultiCrop({ cropyear: `${currentcropData}` });
                        setPreSelectedArray([`${currentcropData}`]);
                        setIsReset(false);
                        setSearchBtnDisabled(false);
                      } else if (contra || IsStringChecker(currentcropData)) {
                        if (IsStringChecker(currentcropData)) {
                          setMultiCrop({ cropyear: `${currentcropData}` });
                          setPreSelectedArray([`${currentcropData}`]);
                        } else {
                          setMultiContId({
                            contractid: `${contra}`,
                          });
                          setPreSelectedContracts([`${contra}`]);
                        }
                        setIsReset(false);
                        setSearchBtnDisabled(true);
                      }
                      setReloadCropyear(true);
                      setReloadcontract(true);
                    }, 0);
                  })
                  .catch((error) => {
                    HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
                  });
              }
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            });
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getDDContractPerformMultiContract = async (
    tokenId,
    accounts,
    urlArray
  ) => {
    var resp = await Promise.all(
      urlArray.map(async (url) => {
        return await gbsDataCommonMethod(tokenId, accounts, url)
          .then((res) => res.data)
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    let allContractData = [];
    resp.map((x) => {
      x.map((y) => {
        allContractData.push({ ...y });
      });
    });
    return allContractData;
  };

  const ReportDateSort = (
    Arr = [],
    CropYearField = "FileName",
    ContractField = "RelationshipNumber",
    DateField = "ReportDate"
  ) => {
    try {
      const result_Arr = IsArrayNotEmpty(Arr)
        ? _.orderBy(Arr, [CropYearField, ContractField], ["desc", "asc"])
        : [];
      let groupContract = _.groupBy(result_Arr, (item) => {
        return [item[ContractField]];
      });
      let result = Object.keys(groupContract).map((i) =>
        _.groupBy(
          _.orderBy(groupContract[i], [DateField], ["desc"]),
          CropYearField
        )
      );
      // console.log({result});
      let finalResult = [];
      result.map((i) => Object.keys(i).map((k) => finalResult.push(i[k][0])));
      return _.orderBy(
        finalResult,
        [CropYearField, ContractField],
        ["desc", "asc"]
      );
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return Arr;
    }
  };

  const getCropYearFromFileName = (filename = null) => {
    try {
      if (IsStringChecker(filename)) {
        const fn = filename.split(".");
        const text = fn[0];
        const year = text.slice(-4);
        return IsStringChecker(year) ? year : null;
      }
      return null;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };

  const fetchContractPerfData = async (apiUrl) => {
    if (IsTokenChecker(tokenId) && apiUrl != "") {
      setLoader(true);
      setLoading(true); /*set loader to search button*/
      const fetchApiUrl = apiUrl;
      await getDDContractPerformMultiContract(tokenId, accounts, fetchApiUrl)
        .then((resp) => {
          const gridArray = Object.values(resp).map((item) => ({
            FileName: item.FileName.substr(-9).substr(0, 4),
            RelationshipNumber: item.RelationshipNumber,
            Name: item.Name,
            ReportName: item.FileName ? item.FileName.split(".")[0] : "",
            ReportDate: item.ReportDate,
            DateReportFormat: item.ReportDate
              ? moment.utc(item.ReportDate).format("YYYYMMDDHHmmss")
              : null,
            Url: item.Url,
          }));

          /*Multi column sort*/
          multiColumnSort(gridArray, "FileName", "RelationshipNumber");
          /*end*/

          const reSetcolumnArray = Object.values(resp).map((item) => ({
            Crop_Year: item.FileName.substr(-9).substr(0, 4),
            "Contract_#": item.RelationshipNumber,
            Contract_Name: item.Name,
            Report_Name: item.FileName ? item.FileName.split(".")[0] : "",
            Report_Date: item.ReportDate,
            DateReportFormat: item.ReportDate
              ? moment.utc(item.ReportDate).format("YYYYMMDDHHmmss")
              : null,
            Download_Excel: (
              <span>
                <a
                  href={GbsBaseUrl + item.Url}
                  target={"_blank"}
                  className="border-1 border-radius-6 csvIconPadding"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Export"
                  rel="noreferrer"
                >
                  {item.FileExtension === "xlsx" ? (
                    <i
                      className="fa fa-file-excel-o text-16 excelIconPadding position-relative"
                      alt="ExcelIcon"
                    ></i>
                  ) : (
                    <img
                      src="../../../assets/images/icon_csv.svg"
                      className="img-17 position-relative"
                      alt="CSVcon"
                    />
                  )}
                </a>
              </span>
            ),
          }));
          /*Multi column sort*/
          multiColumnSort(reSetcolumnArray, "Crop_Year", "Contract_#");
          /*end*/
          // console.log({ gridArray, reSetcolumnArray });
          const mobileData = ReportDateSort(
            reSetcolumnArray,
            "Crop_Year",
            "Contract_#",
            "DateReportFormat"
          );
          const gridData = ReportDateSort(
            gridArray,
            "FileName",
            "RelationshipNumber",
            "DateReportFormat"
          );
          // console.log({ gridData, mobileData });
          setResultFilter(mobileData);
          setGridItems(gridData);
          resp.length > 0 ? setIsListingHide(false) : setIsListingHide(true);
          resp.length > 0
            ? setNoRecordErrorMsg("")
            : setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
      setLoading(false); /*remove loader from search button*/
      setSearchBtnDisabled(false); /*disabled search button*/
      setisMobFilter(false);
    }
  };

  /*multi select cropYears*/
  const multiSelectCropYears = convertFilterArray(listCropYear, {
    id: "CropYear",
    name: "CropYear",
  });
  /*multi select ContractId*/
  const multiSelectContractId = [
    ...new Map(
      Object.values(ListContracts).map((item) => [
        item.RelationshipId,
        {
          name: item.RelationshipNumber + " " + item.Name,
          id: item.RelationshipId,
        },
      ])
    ).values(),
  ];

  const handlerCropYear = (val, type, removedItem = null) => {
    setIsListingHide(true);
    if (type === "deSelected") {
      if (val.length === 0 || removedItem.id === "all") {
        setSearchBtnDisabled(true);
        setIsReset(multiContId?.contractid ? false : true);
      } else {
        setSearchBtnDisabled(multiContId?.contractid ? false : true);
        setIsReset(false);
      }
    } else {
      setSearchBtnDisabled(multiContId?.contractid ? false : true);
      setIsReset(false);
    }
  };

  const handlerContract = async () => {
    setIsReset(false);
    setSearchBtnDisabled(
      multiCrop?.cropyear ? false : true
    ); /*disabled search button*/
  };

  const handlerContractDel = async (selectedList, removedItem) => {
    if (selectedList.length === 0 || removedItem.id === "all") {
      setGridItems([]); /*set empty to grid Item */
      // setHideExport(true);
      setIsListingHide(true);
      setSearchBtnDisabled(true);
      setIsReset(multiCrop?.cropyear ? false : true);
      setMultiContId(null); /*set null to contract filter */
    } else {
      setSearchBtnDisabled(false);
      setIsReset(false);
    }
  };

  const handlerSubmit = () => {
    setGridItems([]); /*set empty to grid Item */
    const qCropyear = IsStringChecker(multiCrop?.cropyear)
      ? multiCrop?.cropyear
      : `${ongoingYear}`;
    const contractIdsArray = IsStringChecker(multiContId?.contractid)
      ? multiContId?.contractid.split(",")
      : [];
    let urlArray = [];
    if (IsStringChecker(qCropyear)) {
      if (IsArrayNotEmpty(contractIdsArray)) {
        contractIdsArray.map((x) => {
          urlArray.push(`contracts/${x}/scorecards?cropyear=${qCropyear}`);
        });
      }
    }
    fetchContractPerfData(urlArray);
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setIsReset(true); /*disabled Reset button*/
    setSearchBtnDisabled(true); /*disabled search button*/
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    setNoRecordErrorMsg("");
    // setDisablePreSelected(true);
    setMultiCrop(null);
    setPreSelectedArray([]);
    setMultiContId(null); /*set null to contract filter */
    setPreSelectedContracts([]);
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };
  return (
    <>
      {/*<!-- Contract Performance Report block starts -->*/}
      {loader && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? " show " : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 p-2 border-1 white_bg filter_dropdown border-radius-6  ${
            isMobFilter ? " active " : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"></span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock  ${
            isMobFilter ? " show " : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3 hide_first_option">
              <div className="crop_year_label custom_dropdown">
                {/*<!-- <span>Crop Year</span> -->*/}
                {reloadCropyear && (
                  <MultiDropDown
                    optionList={multiSelectCropYears}
                    prompt="Select"
                    value={multiCrop}
                    onChange={(val) => handlerCropYear(val, "selected")}
                    label="cropyear"
                    usestate={setMultiCrop}
                    disabled=""
                    onDelete={(val, item) =>
                      handlerCropYear(val, "deSelected", item)
                    }
                    preSelectedOpt={preSelectedArray}
                    // disablePreSelected={disablePreSelected}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {/*<!-- <span>Contract Number</span> -->*/}
                {reloadContract && (
                  <MultiDropDown
                    optionList={multiSelectContractId}
                    prompt="Select"
                    value={multiContId}
                    onChange={() => handlerContract()}
                    label="contractid"
                    usestate={setMultiContId}
                    disabled=""
                    onDelete={(selectedList, removedItem) =>
                      handlerContractDel(selectedList, removedItem)
                    }
                    optionNotFound={{ contract_id: noContractsFound }}
                    preSelectedOpt={preSelectedContracts}
                  />
                )}
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? " show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}{" "}
          </button>
        </div>
      </nav>
      {/*<!-- Filter Dropdown ends -->*/}
      {isListingHide == false ? (
        <>
          {/*<!-- Responsive table starts -->*/}
          {!displayAccordian && (
            <div className="visible_print_mob_desktop hor-space-20 top-mspace-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block">
              <DataGridPagination
                uniqueIdentifier="Id"
                gridData={gridItems}
                columns={columns}
                isPagination={true}
                itemsPerPage={10}
                columnResize={false}
                classNames="contain-block-size-reset top-mspace-20 bot-mspace-20 border-radius-6"
                //defaultSort = {["FileName","DESC"]}
                dynamicHeight={50}
              />
            </div>
          )}
          <div
            className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0  ${
              !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
            }`}
          >
            <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
              <ContractPerformanceMobile listingData={resultFilter} />
            </div>
          </div>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
      {/*<!-- Responsive table ends -->*/}
    </>
  );
};

/*multiColumnSort*/
const multiColumnSort = (gridArray, column1, column2) => {
  gridArray.sort((a, b) => {
    if (a[column1] === b[column1]) {
      return a[column2] < b[column2] ? -1 : 1;
    } else {
      return a[column1] > b[column1] ? -1 : 1;
    }
  });
};
export default ContractPerformanceData;
