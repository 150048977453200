import {
  IsStringChecker,
  HandleErrorTrackAndToast,
  NumConverter,
  IsNumberChecker,
} from "../../../../utils/helper";

export const NineMillionLimit = 9000000,
  OneMillionLimit = 1000000,
  HundredLimit = 100;
export const maxBarrels = 9000000;

export function WholeRateValidation(value, min = 0, max = 100) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num >= min && num <= max) {
        return `${value}`;
      } else if (num <= max) {
        return `${min}`;
      } else if (num >= min) {
        return `${max}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function MinRateValidation(value, min = 0) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num >= min) {
        return `${value}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function MaxRateValidation(value, max = 100) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num <= max) {
        return `${value}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function IsNumber(
  value,
  oMinCheck = false,
  oMaxCheck = false,
  isEquals = false,
  oMin = 0,
  oMax = 100
) {
  try {
    if (IsNumberChecker(value)) {
      return CalcValidate(value, isEquals, oMinCheck, oMin, oMaxCheck, oMax);
    } else if (IsStringChecker(value)) {
      const num = parseFloat(value);
      if (IsNumberChecker(num)) {
        return CalcValidate(num, isEquals, oMinCheck, oMin, oMaxCheck, oMax);
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function CommaNumberToNormal(num, float = true) {
  try {
    const new_num = `${num}`.replace(/,/g, "");
    return float ? parseFloat(new_num) : parseInt(new_num);
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0;
  }
}

export function EventMGMT(e, def = true, propagate = true) {
  try {
    if (def && propagate) {
      e.stopPropagation();
      e.preventDefault();
    } else if (def || propagate) {
      if (def) {
        e.preventDefault();
      } else {
        e.stopPropagation();
      }
    }
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
  }
}

export function CalcValidate(val, equals, minCheck, min, maxCheck, max) {
  try {
    if (minCheck && maxCheck) {
      if (equals) {
        return val >= min && val <= max;
      } else {
        return val > min && val < max;
      }
    } else if (minCheck || maxCheck) {
      if (minCheck) {
        if (equals) {
          return val >= min;
        } else {
          return val > min;
        }
      } else {
        if (equals) {
          return val <= max;
        } else {
          return val < max;
        }
      }
    } else {
      return true;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function NumberFormaterWithValidation(
  value,
  Validator = () => {},
  min = 0,
  max = 100
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      if (isDecimal) {
        if (IsStringChecker(decimalSeq)) {
          const decimals = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 2)
            : "";
          const num = `${inputNum}.${decimals}`;
          if (num === "0.0") {
            return num;
          } else {
            return Validator(num, min, max);
          }
        } else {
          const num = Validator(inputNum, min, max);
          return IsStringChecker(num) ? `${num}.` : "";
        }
      } else {
        return Validator(inputNum, min, max);
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function Digit4NumberFormaterWithValidation(
  value,
  Validator = () => {},
  min = 0,
  max = 100
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      if (isDecimal) {
        if (IsStringChecker(decimalSeq)) {
          const decimals = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 4)
            : "";
          const num = `${inputNum}.${decimals}`;
          if (num === "0.0" || "0.00" || "0.000") {
            return num;
          } else {
            return Validator(num, min, max);
          }
        } else {
          const num = Validator(inputNum, min, max);
          return IsStringChecker(num) ? `${num}.` : "";
        }
      } else {
        return Validator(inputNum, min, max);
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function RemoveNumberFormater(value) {
  try {
    if (IsStringChecker(value)) {
      const withoutComma = value.split(",").join("");
      const isDecimal = withoutComma.includes(".");
      const formattedArr = withoutComma.split(".");
      const inputNum = formattedArr[0],
        decimalSeq = formattedArr[1];
      return { inputNum, decimalSeq, isDecimal };
    }
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  }
}

export function BarrelsNumberFormater(
  value,
  validate = false,
  decimal = false
) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      const options = {};
      if (decimal) {
        options.style = "decimal";
        options.maximumFractionDigits = 2;
      }
      const USNum = new Intl.NumberFormat("en-US", { ...options });

      const num = USNum.format(inputNum);
      if (num == "NaN") {
        return "";
      } else {
        if (validate) {
          const { inputNum: toValidate } = RemoveNumberFormater(num);
          const inputValidate = NumConverter(toValidate);
          if (inputValidate < maxBarrels) {
            if (isDecimal) {
              const decimals = IsStringChecker(decimalSeq)
                ? decimalSeq.substring(0, 2)
                : "";
              return `${num}.${decimals}`;
            } else {
              return num;
            }
          } else {
            return `9,000,000`;
          }
        } else {
          if (isDecimal) {
            const decimals = IsStringChecker(decimalSeq)
              ? decimalSeq.substring(0, 2)
              : "";
            return `${num}.${decimals}`;
          } else {
            return num;
          }
        }
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function OnChangeDecimalWrapper(e) {
  try {
    const { name, value } = e?.target || {};
    if (value === ".") {
      return { name, value: "0." };
    } else if (value.startsWith(".")) {
      return { name, value: `0${value}` };
    }
    return { name, value };
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    return e?.target || {};
  }
}

export function KeyDownHandler(e) {
  const { key, target = {} } = e;
  const { value } = target;
  if (key.length === 1) {
    if (key === ".") {
      const isDecimal = value.includes(".");
      if (isDecimal) {
        EventMGMT(e);
      }
    } else {
      if (isNaN(key)) {
        EventMGMT(e);
      }
    }
  }
}

export function NumberFormater(value) {
  const { inputNum, decimalSeq, isDecimal } = RemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      if (isDecimal) {
        if (IsStringChecker(decimalSeq)) {
          const decimals = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 2)
            : "";
          const num = `${inputNum}.${decimals}`;
          return num;
        } else {
          return IsStringChecker(inputNum) ? `${inputNum}.` : "";
        }
      } else {
        return IsStringChecker(inputNum) ? inputNum : "";
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function RateValidation(value, min = 0, max = 100) {
  try {
    const num = parseFloat(`${value}`);
    if (IsNumberChecker(num)) {
      if (num > min && num <= max) {
        return `${value}`;
      } else if (num <= max) {
        return `${min}`;
      } else if (num > min) {
        return `${max}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

export function EstimateNumberValidation(value) {
  try {
    if (IsNumberChecker(value)) {
      return value > 0;
    } else if (IsStringChecker(value)) {
      const num = parseFloat(value);
      return num > 0;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

export function ExportNumberConverter(value, fixed = 2) {
  try {
    if (IsNumberChecker(value) || IsStringChecker(value)) {
      const str = parseFloat(value).toFixed(fixed);
      return parseFloat(str);
    } else {
      return 0.0;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0.0;
  }
}

export function EstimateRemoveNumberFormater(value) {
  try {
    if (IsStringChecker(value)) {
      const withoutComma = value.split(",").join("");
      const isDecimal = withoutComma.includes(".");
      const formattedArr = withoutComma.split(".");
      const inputNum = formattedArr[0],
        decimalSeq = formattedArr[1];
      return { inputNum, decimalSeq, isDecimal };
    } else if (IsNumberChecker(value)) {
      const withoutComma = `${value}`.split(",").join("");
      const isDecimal = withoutComma.includes(".");
      const formattedArr = withoutComma.split(".");
      const inputNum = formattedArr[0],
        decimalSeq = formattedArr[1];
      return { inputNum, decimalSeq, isDecimal };
    }
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return { inputNum: "", decimalSeq: "", isDecimal: false };
  }
}

export function EstimateNumberFormater(value, decimal = false) {
  const { inputNum, decimalSeq, isDecimal } =
    EstimateRemoveNumberFormater(value);
  try {
    if (IsStringChecker(inputNum)) {
      const options = {};
      if (decimal) {
        options.style = "decimal";
        options.maximumFractionDigits = 2;
      }
      const USNum = new Intl.NumberFormat("en-US", { ...options });

      const num = USNum.format(inputNum);
      if (num == "NaN") {
        return "";
      } else {
        if (isDecimal) {
          const decimalsArrange = IsStringChecker(decimalSeq)
            ? decimalSeq.substring(0, 2)
            : "";
          const decimals =
            decimalsArrange.length >= 2
              ? decimalsArrange
              : decimalsArrange.length === 1
              ? `${decimalsArrange}0`
              : `00`;
          return `${num}.${decimals}`;
        } else {
          return `${num}.00`;
        }
      }
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}
