import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { cmsDataCommonMethod } from "../services/gbsData";
import { HandleErrorTrackAndToast } from "../utils/helper";

const loadTranslations = async (lng, ns, account, jwt) => {
  return await cmsDataCommonMethod(jwt, account, ns)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      HandleErrorTrackAndToast(e, true);
      return { data: "Error" };
    });
};

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}",
  request: (options, url, payload, callback) => {
    try {
      const [lng, ns] = url.split("|");
      let account = [];
      let jwt = "";
      if (Object.keys(sessionStorage).length > 0) {
        Object.keys(sessionStorage).map((item) => {
          if (sessionStorage[item].search("localAccountId") > -1) {
            account = [JSON.parse(sessionStorage[item])];
          }
          if (sessionStorage[item].search("IdToken") > -1) {
            jwt = JSON.parse(sessionStorage[item]).secret;
          }
        });
      }
      if (ns !== "" && account.length > 0 && jwt !== null && jwt !== "") {
        loadTranslations(lng, ns, account, jwt).then((response) => {
          callback(null, {
            data: response,
            status: 200,
          });
        });
      } else {
        callback(null, {
          status: 500,
          data: { data: "Error" },
        });
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      callback(null, {
        status: 500,
        data: { data: "Error" },
      });
    }
  },
};
i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    fallbackLng: "en",
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    debug: true,
    load: "languageOnly", // en vs en-us
    ns: ["header_navigations"], // loaded in each component explicitly
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    detection: {
      order: ["localStorage"],
      lookupQuerystring: "lng",
      lookupLocalStorage: "I18N_LANGUAGE",
      caches: ["localStorage"],
    },
    react: {
      wait: true,
    },
  });

export default i18n;
