import React, { useEffect, useState, useContext, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Search from "./search";
import _ from "lodash";
import { Link, useHistory, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import SubMenu from "./subMenu";
import CurrentLocation from "./currentLocation";
import { gbsDataCommonMethod } from "../../services/gbsData";
import Moment from "react-moment";
import ReactTooltip from "react-tooltip";
import { CommentImageUrl } from "../../utils/constants";
import imageContext from "../context/profile/imageContext";
import { NotificationContext } from "../context/contextVariable";
import useLogoutPathBuilder from "./logoutPathBuilder"; // 55546 -NK
import {
  compareString,
  IsStringChecker,
  IsTokenChecker,
  lowStr,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
} from "../../utils/helper";
import {
  RoleCheckerForArrayData,
  ContractsFromJSONParser,
  ImpersonationAccountsSwap
} from "../../permissionWrapper";
import useToaster from "../customHooks/useToaster";

const Header = (props) => {
  // console.log({ props });
  // const { body = {} } = props;
  // const { props: bodyProps = {} } = body || {};
  const {
    RoleAccessDefinitions = null,
    SpecialRoleExist,
    RoleCBPrivileges = {},
  } = props || {};
  const { t, i18n } = useTranslation("header_navigations");
  const [showSearch, setShowSearch] = useState(
    location.pathname.indexOf("search-results") > -1 ? true : false
  );
  const queryPeram = new URLSearchParams(location.search).get("q") || "#";
  const SearchURL =
    location.pathname.indexOf("search-results") > -1 && queryPeram !== "#"
      ? `search-results?q=${queryPeram}`
      : "#";
  // const [showLang, setShowLang] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.pathname.indexOf("search-results") > -1)
      document.body.classList.value += " searchopen";
    else
      document.body.classList.value = document.body.classList.value
        .replace(/searchopen/g, "")
        .trim();

    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);
  const onClick = () => {
    setShowSearch(!showSearch);
    if (!showSearch) document.body.classList.value += " searchopen";
    else
      document.body.classList.value = document.body.classList.value
        .replace(/searchopen/g, "")
        .trim();
  };
  const [mobileToggler, setMobileToggler] = useState(false);
  const [notificationToggler, setNotificationToggler] = useState(false);
  const [notify, setNotify] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const image_context = useContext(imageContext);
  const { statusSuccess, currentProfileImage } = image_context;
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);
  const idToken = useSelector((state) => state.reducerToken);

  // const [display, setDisplay] = useState(false);
  const userAgent = window?.navigator?.userAgent;
  useEffect(() => {
    try {
      const ua = lowStr(userAgent);
      const chrome = ua.includes("chrome");
      const safari = ua.includes("safari");
      if (safari && !chrome) {
        const safVersion = ua.split(" ")?.find((li) => li.includes("version"));
        if (IsStringChecker(safVersion)) {
          const temp = safVersion.split("/")?.[1];
          const ver = temp ? parseInt(temp) : false;
          if (ver && ver < 15) {
            localStorage.setItem("safariLowerVersion", true);
            // setDisplay(true);
          } else {
            localStorage.setItem("safariLowerVersion", false);
            // setDisplay(false);
          }
        } else {
          localStorage.setItem("safariLowerVersion", false);
          // setDisplay(false);
        }
      } else {
        localStorage.setItem("safariLowerVersion", false);
        // setDisplay(false);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      localStorage.setItem("safariLowerVersion", false);
      // setDisplay(false);
    }
  }, []);

  const header_arr = t("data", { returnObjects: true });
  useEffect(() => {
    if (!IsArrayNotEmpty(header_arr) && lowStr("error", header_arr)) {
      HandleErrorTrackAndToast({ header_arr }, true, "dispatch", dispatch);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [header_arr]);
  // eslint-disable-next-line no-unused-vars
  // console.log({header_arr});
  const Final_arr = RoleCheckerForArrayData(
    header_arr,
    "link",
    gbsRolesByPerson,
    gbsRolesByContract,
    SpecialRoleExist,
    RoleAccessDefinitions
  );
  // console.log({ Final_arr });
  const data = _.groupBy(Final_arr, "column_number");
  // const data = _.groupBy(header_arr, "column_number");

  const history = useHistory(); //55546 - NK
  const logout = useLogoutPathBuilder(); //55546 - NK

  const localAccountId = _.get(accounts, "0.localAccountId", "");
  const profileName = msal_accounts[0].username
    ? msal_accounts[0].username
    : msal_accounts[0].name;
  const mainProfileImage = localAccountId
    ? CommentImageUrl + localAccountId + ".jpg"
    : "";
  const [profileImage, setProfileImage] = useState(
    mainProfileImage !== null
      ? mainProfileImage + "?" + new Date().getTime()
      : mainProfileImage
  );

  const notificationContext = useContext(NotificationContext);
  const { notificationSuccess, getNotification } = notificationContext;

  const { notificationIndex, tabName } = useParams();
  useEffect(() => {
    if (statusSuccess == true) {
      setProfileImage(currentProfileImage);
    }
  }, [statusSuccess]);

  //Notification Context
  useEffect(() => {
    if (notificationSuccess.length > 0) {
      setNotify([...notificationSuccess]);
    } else {
      setNotify([]);
    }
  }, [notificationSuccess]);

  const langs = {
    en: {
      nativeName: "USA",
      flag: `${window.location.origin}/assets/images/icons_us/us@2x.png`,
      code: "ENG",
    },
    fr: {
      nativeName: "Canada",
      flag: `${window.location.origin}/assets/images/icons_ca/ca@2x.png`,
      code: "FRE",
    },
    es: {
      nativeName: "Chile",
      flag: `${window.location.origin}/assets/images/icons_cl/cl@2x.png`,
      code: "ESP",
    },
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getNotification();
    }
    window.scrollTo(0, 0);
  }, [refresh, idToken]);

  const onMarkAsRead = async (id, all = "") => {
    await gbsDataCommonMethod(
      idToken,
      accounts,
      `persons/notifications/read/${localAccountId}/${id}`,
      "put"
    )
      .then(() => {
        all === "" && setRefresh(refresh + 1);
      })
      .catch((e) => {
        HandleErrorTrackAndToast(e, true, "replace", dispatch);
      });
  };
  const getAllData = (notification) => {
    return Promise.all(
      notification.map((item) => onMarkAsRead(item.id, "all"))
    );
  };
  const handleMarkAllRead = async () => {
    if (notify.length > 0) {
      getAllData(notify)
        .then(() => {
          setRefresh(refresh + 1);
        })
        .catch((e) => {
          HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
        });
    }
  };
  const handleLogout = async () => {
    localStorage.setItem("sync-logout", true);
    history.push(logout);
  };

  onstorage = (e) => {
    try {
      const { storageArea, key, newValue, currentTarget } = e;
      // console.log({
      //   e,
      //   storageArea,
      //   newValue,
      //   currentTarget,
      //   origin: location.origin,
      // });
      if (storageArea === localStorage) {
        if (compareString(location.origin, currentTarget?.location?.origin)) {
          if (key === "sync-logout" && newValue === "true") {
            // console.log("localStorage", key);
            history.push(logout);
          }
        }
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
    }
  };

  // const handleMobileLang = (lng) => {
  //   i18n.changeLanguage(lng);

  //   setShowLang(false);
  // };
  /* "47236" getting url and convert into array*/
  const [getPath, moreLangs] = CurrentLocation();
  const getLocation = getPath.slice(1).split("/");
  if (i18n.language == undefined) {
    setDefault();
  }
  if (notificationIndex !== "") {
    getLocation.indexOf(notificationIndex) > -1 &&
      getLocation.splice(getLocation.indexOf(notificationIndex), 1);
  }
  if (tabName !== "") {
    getLocation.indexOf(tabName) > -1 &&
      getLocation.splice(getLocation.indexOf(tabName), 1);
  }
  /*to show mobile top menu*/
  const mobTopMenuTitle =
    getLocation.length <= 1
      ? moreLangs[i18n.language].menu_home.title
      : moreLangs[i18n.language]["menu_" + getLocation[0].replace("-", "_")] ===
        undefined
      ? getLocation[0]
      : moreLangs[i18n.language]["menu_" + getLocation[0].replace("-", "_")]
          .title;

  let mobTopSubmenuTitle = "";
  let mobTopMenuUrl = "/"; /* '/' is a default*/
  if (getLocation != "") {
    /* if not empty, then get url*/
    if (getLocation.length <= 1) {
      /* show mobile submenu title below hamburger*/
      mobTopSubmenuTitle =
        moreLangs[i18n.language]["menu_" + getLocation[0].replace("-", "_")] ===
        undefined
          ? getLocation[0]
          : moreLangs[i18n.language]["menu_" + getLocation[0].replace("-", "_")]
              .title;
      /* in url '/' is a default*/
      mobTopMenuUrl = "/";
    } else {
      /* show mobile submenu title below hamburger*/
      mobTopSubmenuTitle =
        moreLangs[i18n.language][
          "menu_" + getLocation[getLocation.length - 1].replace(/-/g, "_")
        ] === undefined
          ? getLocation[getLocation.length - 1]
          : moreLangs[i18n.language][
              "menu_" + getLocation[getLocation.length - 1].replace(/-/g, "_")
            ].title;
      /* set url*/
      mobTopMenuUrl =
        moreLangs[i18n.language][
          "menu_" + getLocation[getLocation.length - 1].replace(/-/g, "_")
        ] === undefined
          ? getLocation[getLocation.length - 1]
          : moreLangs[i18n.language][
              "menu_" + getLocation[getLocation.length - 1].replace(/-/g, "_")
            ].url;
    }
  }

  // const browser_switch_msg = _.get(
  //   moreLangs,
  //   `${i18n.language}.browser_switch_msg`,
  //   "Sorry - the version of this browser isn't currently supported. Please use another browser to navigate this site."
  // );
  function FilterAccess(special_role, privilegdes) {
    if (special_role) {
      if (privilegdes?.roleCBThirdPartyFarmService) {
        return false;
      }
      return true;
    }
    return true;
  }

  const ToastMessage = () => useToaster();
  const access = FilterAccess(SpecialRoleExist, RoleCBPrivileges);
  return (
    <div>
      {/* {display && (
        <div className="row browser-switch-msg">
          <div className="col">{browser_switch_msg}</div>
        </div>
      )} */}
      <ToastMessage />
      <nav
        className="navbar navbar-expand-lg navbar-light primary_bg oceanspray_navbar"
        style={{ maxHeight: "70px" }}
      >
        <div className="container mob-height">
          <div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setMobileToggler(!mobileToggler)}
            >
              <div className="mobile_navbar_toggle">
                <a className="navbar_inner">
                  <span></span>
                </a>
              </div>
            </button>
            <a
              to={mobTopMenuUrl}
              className="position-relative white_color text-capitalize left-20 hover_color_white d-inline-block d-lg-none fw-bold breadcrumb-label hidden_print"
            >
              {mobTopMenuTitle}
            </a>
          </div>
          <span className="navbar-brand me-0">
            <Link to="/">
              <img
                src={`${window.location.origin}/assets/images/logo/logo_latest@2x.png`}
                alt="Logo"
                className="header_logo"
                style={{ width: "105px" }}
              />
            </Link>
          </span>
          <div
            className={
              mobileToggler
                ? "nav-mobile-overlay-display"
                : "nav-mobile-overlay"
            }
          ></div>
          <div
            className={
              mobileToggler
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarNavDropdown"
            style={{ width: "75%" }}
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown user-nav-item-mobile remove_plus ">
                <img
                  src={`${window.location.origin}/assets/images/icons-close/ico-close@2x.png`}
                  alt="searchClose"
                  className="searchClose mobile-lang-close"
                  onClick={() => setMobileToggler(false)}
                />
                <a className="dropdown-item border_bottom_none profile_img">
                  <img
                    src={profileImage}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                    }}
                  />
                </a>
                <a className="profile_name border_bottom_none">{profileName}</a>
                <Link
                  className="profile_picture_edit border-0"
                  to="/user-profile"
                >
                  <span className="edit_button">
                    <i className="fa fa-pencil"></i>
                  </span>
                </Link>
                {/* <div>
                  <a
                    href="#"
                    className={
                      showLang
                        ? "location_top border_bottom_none active"
                        : "location_top border_bottom_none"
                    }
                    onClick={() => setShowLang(!showLang)}
                  >
                    <img
                      src={
                        i18n.language === undefined
                          ? `{${window.location.origin}/assets/images/icons_us/us@2x.png`
                          : `${langs[i18n.language].flag}`
                      }
                      className="header_icon mobile-flag"
                      alt="us_icon"
                    />
                  </a>
                  <div
                    className={
                      showLang
                        ? "mob_location_popup show"
                        : "mob_location_popup"
                    }
                  >
                    {Object.keys(langs).map((lng, i) => (
                      <Fragment key={i}>
                        <div
                          className="location_icons"
                          onClick={() => handleMobileLang(lng)}
                        >
                          <img
                            src={langs[lng].flag}
                            className="mobile_header_icon"
                            alt="ca_icon"
                          />
                        </div>
                        <h6 className="white_color">
                          {langs[lng].nativeName + "(" + langs[lng].code + ")"}{" "}
                        </h6>
                      </Fragment>
                    ))}
                  </div>
                </div> */}
                <div>
                  <a href="#" className={"location_top border_bottom_none"}>
                    <img
                      src={
                        i18n.language === undefined
                          ? `{${window.location.origin}/assets/images/icons_us/us@2x.png`
                          : `${langs[i18n.language].flag}`
                      }
                      className="header_icon mobile-flag"
                      alt="us_icon"
                    />
                  </a>
                </div>
              </li>
              <li className="nav-item home_link remove_plus">
                <Link className="nav-link" to="/">
                  {moreLangs[i18n.language].home_page.home}
                </Link>
              </li>

              {Object.keys(data).map((item, ind) => (
                <Fragment key={ind}>
                  {data[item]
                    .sort((a, b) => (a.order_by > b.order_by ? 1 : -1))
                    .map((link, index) =>
                      index == 0 ? (
                        <li className="nav-item dropdown" key={index}>
                          <Link
                            className={`nav-link dropdown-toggle ${link.new_content && 'new-content-flag'}`}
                            to={link["link"]}
                            id={`navbarDropdown-${index}`}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {link["title_" + i18n.language]}
                            {link.new_content && <img className="new-content-flag-img" src={`${window.location.origin}/assets/images/new_burst_gradient.png`}/>}
                          </Link>
                          {data[item].length > 1 && (
                            <SubMenu
                              data={data}
                              item={item}
                              index={index}
                              i18n={i18n}
                            />
                          )}
                        </li>
                      ) : (
                        <Fragment key={index} />
                      )
                    )}
                </Fragment>
              ))}
              <li className="nav-item dropdown remove_plus d-block d-lg-none">
                <a className="nav-link" href="#" onClick={handleLogout}>
                  Logout
                </a>
              </li>
            </ul>
          </div>

          {/* <!-- Right Side Icons Starts--> */}

          <div className="nav_icons_right">
            <ul className="navbar-nav">
              {access ? (
                <li className="nav-item dropdown search-nav-item">
                  <Link
                    className="nav-link dropdown-toggle"
                    aria-current="page"
                    to={SearchURL}
                    id="navIconSearch"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={onClick}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </Link>
                </li>
              ) : (
                <></>
              )}
              {IsArrayNotEmpty(notify) ? (
                <li className="nav-item dropdown bell-nav-item">
                  <a
                    className="nav-link icon-nav-link dropdown-toggle"
                    aria-current="page"
                    href="#"
                    id="navIconBell"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => setNotificationToggler(!notificationToggler)}
                  >
                    <i className="fa fa-bell" aria-hidden="true">
                      {IsArrayNotEmpty(notify) ? (
                        <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger ps-2 gotham border-1 notification_icon">
                          {notify.length > 10 ? "10+" : notify.length}
                          <span className="visually-hidden">
                            {moreLangs[i18n.language].home_page.unread_messages}
                          </span>
                        </span>
                      ) : null}
                    </i>
                  </a>
                  <ul
                    className={
                      notificationToggler
                        ? "dropdown-menu oceanspray_submenu notification_list show"
                        : "dropdown-menu oceanspray_submenu notification_list "
                    }
                    aria-labelledby="navIconBell"
                  >
                    {notify.slice(0, 5).map((not) => {
                      const { id, createdAt } = not;
                      const title = IsStringChecker(not?.title)
                        ? not.title
                        : "";
                      return (
                        <li key={id} className="p-2">
                          <h5>
                            <Link
                              to={
                                moreLangs[i18n.language].menu_user_profile.url +
                                "/notifications/" +
                                id
                              }
                            >
                              {title}
                            </Link>
                          </h5>
                          <h6>
                            <span className="light_grey_color mob_date_color">
                              <Moment unix format="MM-DD-Y">
                                {createdAt / 1000}
                              </Moment>
                            </span>{" "}
                            <a
                              className="red_color cursor-pointer text-12 fw-bold"
                              onClick={() => {
                                onMarkAsRead(id);
                              }}
                            >
                              {moreLangs[i18n.language].home_page.mark_as_read}
                            </a>
                          </h6>
                        </li>
                      );
                    })}
                    <li>
                      <h5 className="p-2 notification-popup-foot">
                        <a
                          className="red_color cursor-pointer text-12 fw-bold"
                          onClick={handleMarkAllRead}
                        >
                          {moreLangs[i18n.language].home_page.mark_all_as_read}
                        </a>
                        <Link
                          className="blue_color cursor-pointer text-12 fw-bold view-all-btn"
                          to={
                            moreLangs[i18n.language].menu_user_profile.url +
                            "/notifications"
                          }
                        >
                          {moreLangs[i18n.language].home_page.view_all}
                        </Link>
                      </h5>
                    </li>
                    <span className="position-absolute top-0 end-0 p-1">
                      <img
                        src={`${window.location.origin}/assets/images/icons-close/ico-close@2x.png`}
                        alt="searchClose"
                        className="svg-image searchClose"
                        onClick={() =>
                          setNotificationToggler(!notificationToggler)
                        }
                      />
                    </span>
                  </ul>
                </li>
              ) : (
                <li className="nav-item dropdown bell-nav-item bell-nav-normal">
                  <Link
                    className="nav-link cursor-pointer"
                    to={
                      moreLangs[i18n.language].menu_user_profile.url +
                      "/notifications"
                    }
                  >
                    <i className="fa fa-bell" aria-hidden="true"></i>
                  </Link>
                </li>
              )}
              <li className="nav-item dropdown user-nav-item">
                <a
                  className="nav-link icon-nav-link dropdown-toggle "
                  aria-current="page"
                  href="#"
                  id="navIconUser"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-user-circle" aria-hidden="true"></i>
                </a>
                <ul
                  className="dropdown-menu oceanspray_submenu user_dropdown"
                  aria-labelledby="navIconUser"
                >
                  <li>
                    <a className="dropdown-item postion-relative">
                      <img
                        className="profile_picture"
                        src={profileImage}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = `${window.location.origin}/assets/images/profie-img.jpg`;
                        }}
                      />
                      <Link className="profile_picture_edit" to="/user-profile">
                        <input type="file" />
                        <span className="edit_button">
                          <i className="fa fa-pencil"></i>
                        </span>
                      </Link>
                    </a>
                    <a className="profile_name">{profileName}</a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown location-nav-item">
                <a
                  className="nav-link icon-nav-link dropdown-toggle cursor-default bg-transparent"
                  aria-current="page"
                  href="#"
                  id="navIconCard"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      i18n.language === undefined
                        ? `{${window.location.origin}/assets/images/icons_us/us@2x.png`
                        : `${langs[i18n.language].flag}`
                    }
                    className="header_icon"
                    alt="us_icon"
                  />
                </a>
                {/* <a
                  className="nav-link icon-nav-link dropdown-toggle"
                  aria-current="page"
                  href="#"
                  id="navIconCard"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      i18n.language === undefined
                        ? `{${window.location.origin}/assets/images/icons_us/us@2x.png`
                        : `${langs[i18n.language].flag}`
                    }
                    className="header_icon"
                    alt="us_icon"
                  />
                </a>
                <ul
                  className="dropdown-menu oceanspray_submenu location_dropdown"
                  aria-labelledby="navIconCard"
                >
                  {Object.keys(langs).map((lng, i) => (
                    <li key={i}>
                      <a
                        onClick={() => {
                          i18n.changeLanguage(lng);
                        }}
                        className="dropdown-item"
                        href="#"
                      >
                        <img
                          src={langs[lng].flag}
                          className="header_icon"
                          alt="cl_icon"
                        />{" "}
                        {langs[lng].nativeName + "(" + langs[lng].code + ")"}
                      </a>
                    </li>
                  ))}
                </ul> */}
              </li>
              <li className="nav-item dropdown  search-nav-item d-none d-lg-block">
                <a
                  data-tip={"Logout"}
                  data-for="main"
                  className="nav-link dropdown-toggle"
                  aria-current="page"
                  href="#"
                  id="navIconSearch"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={handleLogout}
                >
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                  <ReactTooltip
                    id="main"
                    place="bottom"
                    effect="float"
                    type="dark"
                  />
                </a>
              </li>
            </ul>
          </div>
          {/* Right Side Icons Ends */}
        </div>
        <div className="mob_header dark_red_bg hidden_print">
          <p className="px-3 pt-2 text-12 fw-bold text-capitalize white_color d-block d-lg-none mb-2">
            {mobTopSubmenuTitle.replace(/-/g, " ")}
          </p>
        </div>
      </nav>

      <Search onclose={onClick} isOpen={showSearch} />
    </div>
  );
};

export default Header;
