import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import {
  IsTokenChecker,
  IsArrayNotEmpty,
  HandleErrorTrackAndToast,
  IsObjectChecker,
  getResolvedLanguage,
} from "../../../utils/helper";
// import SurveyWidgetDetails from "./surveysWidgetDetails";
import moment from "moment";

import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import SurveyWidgeForm from "./surveysWidgetDetails";


const SurveyWidget = (props) => {
  const { slug, designType } = props;
  const { accounts } = useMsal();

  // const localAccountId = _.get(accounts, `0.localAccountId`, "");
  const GBSRegions = _.get(accounts, `0.idTokenClaims.gbsRegions`, "");
  // console.log("GBSRegions", GBSRegions);

  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const [, setAnswered] = useState(true);
  const [loading, setLoading] = useState(false);
  const [surveyData, setSurveyData] = useState({});
  const [surveyQuestions, setSurveyQuestions] = useState([]);

  const [selectedObj, setSelectedObj] = useState({});
  const [initialSelectedObj, setInitialSelectedObj] = useState({});
  const [requiredObjects, setRequiredObject] = useState([]);
  const [showhideSurvey, setShowhideSurvey] = useState([]);
  
  const ObjDataChecker = (data = {}) => {
    try {
      if (IsObjectChecker(data) && !_.isEmpty(data)) {
        return true;
      }
      return false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const GetSurveyData = async (data = {}) => {
    var originalData = { ...data };
    const masterData = _.get(originalData, "survey_questions", []);
    if (IsArrayNotEmpty(masterData)) {
      const SurveyData = await Promise.all(
        masterData.map(async (threadId, index) => {
          const url = `survey_questions?id=eq.${threadId}`;
          return await cmsDataCommonMethod(idToken, accounts, url)
            .then((res) => {
              const ArrData = _.get(res, "data.data", []);
              const mainData = _.get(ArrData, "0", {});
              return { ...mainData, custom_order_by: index + 1 };
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "replace", dispatch);
              return false;
            });
        })
      ).catch((e) => {
        HandleErrorTrackAndToast(e, true, "replace", dispatch);
        return [];
      });
      delete originalData["survey_questions"];
      setSurveyData({ ...originalData });
      const ResultData = SurveyData.filter((li) => ObjDataChecker(li));
      GetSurveyQuestions(ResultData, originalData);
    } else {
      EmptyMasterData(true, false);
    }
  };

  // useEffect(() => {
  //   console.log({ loading, surveyQuestions });
  // }, [loading, surveyQuestions]);

  const GetSurveyQuestions = (survey_questions = [], originalData = {}) => {
    if (IsArrayNotEmpty(survey_questions)) {
      let data = [...requiredObjects];
      survey_questions.forEach(async (element) => {
        element.is_required && data.push(element);

        const id = _.get(element, "id", null);
        const masterData = _.get(element, "survey_options", []);
        const SurveyData = await Promise.all(
          masterData.map(async (threadId, index) => {
            const url = `survey_options?id=eq.${threadId}`;
            return await cmsDataCommonMethod(idToken, accounts, url)
              .then((res) => {
                const ArrData = _.get(res, "data.data", []);
                const mainData = _.get(ArrData, "0", {});
                return { ...mainData, custom_order_by: index + 1 };
              })
              .catch((error) => {
                HandleErrorTrackAndToast(error, true, "replace", dispatch);
                return false;
              });
          })
        ).catch((e) => {
          HandleErrorTrackAndToast(e, true, "replace", dispatch);
          return [];
        });
        const FilterData = SurveyData.filter((li) => ObjDataChecker(li));
        const ResultData = _.orderBy(FilterData, ["custom_order_by"], ["asc"]);

        if (element.survey_design_template === "contract-based-region") {
          const options = [];
          ResultData.map((i) => {
            if (originalData?.survey_type === "grower" && GBSRegions.includes(i.option_en?.replace(/ /g, "-")?.toLowerCase())) {
              options.push({ name: i?.['option_' + resolvedLanguage], id: i.id, option_value: i.option_value });
            }
          });
          if (options.length > 0) {
            setSelectedObj({ ...selectedObj, [element.id]: [{ value: options[0].option_value, "optionID": options[0].id }] });
            setInitialSelectedObj({ ...initialSelectedObj, [element.id]: [{ value: options[0].option_value, "optionID": options[0].id }] });
          }
        }

        setSurveyQuestions((prev) => {
          var temp = [...prev];
          const index = temp.findIndex((li) => li.id == id);
          if (index > -1) {
            temp.splice(index, 1, { ...element, survey_options: ResultData });
          } else {
            temp.push({ ...element, survey_options: ResultData });
          }
          if (temp.length == survey_questions.length) {
            setLoading(false);
          }
          return temp;
        });
      });
      setRequiredObject([...data])
      // setLoading(false);
    } else {
      EmptyMasterData(true, false);
    }
  };

  const EmptyMasterData = (child = true, master = true) => {
    if (master && child) {
      setSurveyData({});
      setSurveyQuestions([]);
      setLoading(false);
    } else if (master || child) {
      if (master) {
        setSurveyData({});
      } else {
        setSurveyQuestions([]);
      }
      setLoading(false);
    }
  };

  const GetSurveyWidgetData = async () => {
    const today = moment().toISOString();
    const surveyFilter = `survey_date_from.lte.${today},survey_date_to.gte.${today}`;
    const surveyURL = `surveys?select=*,page_listings!inner(*)&page_listings.slug=eq.${slug}&is_active=eq.true&and=(${surveyFilter})`;
    await cmsDataCommonMethod(idToken, accounts, surveyURL)
      .then((resp) => {
        const responseData = _.get(resp, "data.data.0", {});
        setShowhideSurvey(resp.data.data);
        GetSurveyData(responseData);
        // CheckAlreadyAnswered(responseData);
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        EmptyMasterData();
        setAnswered(true);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      setLoading(true);
      GetSurveyWidgetData();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const OrderedQuestions = !loading
    ? _.orderBy(surveyQuestions, ["custom_order_by"], ["asc"])
    : surveyQuestions;

  if (GBSRegions.length === 0 || showhideSurvey.length === 0) {
    return false;
  }
  else if (designType !== "accordion") {
    if (loading || JSON.stringify(surveyData) === "{}") {
      return (
        <div className={`survey-card card bot-mspace-20 card-shadow `}>
          <h4 className="card-header white_color ver-space-15">
            <svg className="survey-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z" /></svg>
            <span className="left-space-5">{surveyData?.['survey_title_' + resolvedLanguage] || "Survey"}</span>
          </h4>
          <div className="card-body">
            <p className="survey-success">
              <span>{surveyData?.['survey_title_' + resolvedLanguage] || "Survey"}</span>
              <span> is loading... Please, wait.</span>
            </p>
          </div>
        </div>
      );
    } else {
        return (
          <div className={`survey-card card bot-mspace-20 card-shadow `}>
            <h4 className="card-header white_color ver-space-15">
              <svg className="survey-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z" /></svg>
              <span className="left-space-5">{surveyData?.['survey_title_' + resolvedLanguage] || "Survey"}</span>
            </h4>
            <SurveyWidgeForm
              surveyData={surveyData}
              OrderedQuestions={OrderedQuestions}
              EmptyMasterData={EmptyMasterData}
              requiredObjects={requiredObjects}
              setSelectedObj={setSelectedObj}
              initialSelectedObj={initialSelectedObj}
              selectedObj={selectedObj}
              resolvedLanguage={resolvedLanguage}
              GBSRegions={GBSRegions}
              {...props}
            />
          </div>
        );
    }
  }
  else {
    return (
      <>
        <Accordion className="surveyAccordion quickLinkAccorion mobile-survey" defaultActiveKey="0">
          <Accordion.Item>
            <Accordion.Header>
              <svg className="survey-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm64 192c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm64-64c0-17.7 14.3-32 32-32s32 14.3 32 32V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V160zM320 288c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V320c0-17.7 14.3-32 32-32z" /></svg> {` ${surveyData?.['survey_title_' + resolvedLanguage] || 'Survey'}`}
            </Accordion.Header>
            <Accordion.Body>
              {
                (loading || JSON.stringify(surveyData) === "{}") ? <><p className="survey-success">
                  <span>{surveyData?.['survey_title_' + resolvedLanguage] || "Survey"}</span>
                  <span> is loading... Please, wait.</span>
                </p></>
                  :
                  <>
                    <div className={`survey-card bot-mspace-20 `}>
                      <SurveyWidgeForm
                        surveyData={surveyData}
                        OrderedQuestions={OrderedQuestions}
                        EmptyMasterData={EmptyMasterData}
                        requiredObjects={requiredObjects}
                        setSelectedObj={setSelectedObj}
                        initialSelectedObj={initialSelectedObj}
                        selectedObj={selectedObj}
                        resolvedLanguage={resolvedLanguage}
                        GBSRegions={GBSRegions}
                        {...props}
                      />
                    </div>
                  </>
              }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </>
    )
  }

};

export default SurveyWidget;
