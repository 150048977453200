/**
 * @component_Name : NewThread
 * @description : This component used to create a new thread for a pariticular forum
 * @company : Photon
 * @author : Gobi
 **/
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { useMsal } from "@azure/msal-react";
import { cmsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import { useSelector, useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../global/currentLocation";
import { HandleErrorTrackAndToast } from "../../utils/helper";

const NewThread = ({
  data,
  cancel,
  handleRefreshData,
  handleRefreshDataCount,
}) => {
  const { accounts } = useMsal();
  const tokenId = useSelector((state) => state.reducerToken);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState({
    title: "",
    description: "",
    forum_type_id: data?.id,
    user_id: accounts[0]?.localAccountId,
    user_fullname: accounts[0]?.name,
  });
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [descriptionLenght, setDescriptionLength] = useState(0);

  //React Quil tool options
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link','image'],
    ],
  };

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const saveThread = async () => {
    setLoader(true);
    await cmsDataCommonMethod(tokenId, accounts, "forum-posts", "post", value)
      .then((res) => {
        if (res?.data?.status === "success") {
          handleRefreshData(handleRefreshDataCount + 1);
          cancel();
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };
  const rteChange = (content, delta, source, editor) => {
    if (editor.getText() !== "\n" || content.search("<img") > -1){
      setValue({ ...value, description: content });
      setDescriptionLength(new Blob([content]).size/(1024*1024));
    } 
    else{
      setValue({ ...value, description: "" });
      setDescriptionLength(0);
    } 
    return;
  };
  return (
    <>
      <Prompt
        message={(location, action) => {
          if (action === "POP" || action === "PUSH") {
            return value.title === "" && value.description === ""
              ? true
              : moreLangs[i18n.resolvedLanguage].forum_page
                  .browser_back_button_msg;
          } else {
            return true;
          }
        }}
      />
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="white_bg p-3 p-lg-4 border-radius-6 mb-4">
        <div className="row mt-2">
          <div className="col-12">
            <h3 className="mob-fw-bold">
              {moreLangs[i18n.resolvedLanguage]?.forum_page?.new_thread}
            </h3>
          </div>
          <div className="col-12 col-sm-4">
            <div className="mb-3">
              <label
                htmlFor="subject_Text"
                className="form-label text-16 fw-bold mt-5"
              >
                {moreLangs[i18n.resolvedLanguage]?.forum_page?.subject_text}:
              </label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder={
                  moreLangs[i18n.resolvedLanguage]?.forum_page?.subject_text
                }
                onChange={(e) => setValue({ ...value, title: e.target.value })}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-8">
            <div className="forums-new-thread mb-3">
              <label
                htmlFor="post_Text"
                className="form-label text-16 fw-bold mt-2"
              >
                {moreLangs[i18n.resolvedLanguage]?.forum_page?.post_text}:
              </label>
              <ReactQuill
                theme="snow"
                // placeholder={
                //   moreLangs[i18n.resolvedLanguage]?.forum_page?.post_text
                // }
                modules = {modules}
                value={value.description}
                onChange={rteChange}
              />
            </div>
          </div>
        </div>
        <p className="">{moreLangs[i18n.resolvedLanguage]?.forum_page.description_size} {descriptionLenght.toFixed(2)}MB</p>
        {
          descriptionLenght >= 5 && <p className="forum-detail-error">{moreLangs[i18n.resolvedLanguage]?.forum_page.description_size_warning}</p>
        }
        <div className="row">
          <div className="col-12 col-sm-4">
            <div className="mb-3 mt-4">
              <button
                className="btn btn-primary"
                id="ok_button"
                disabled={value.title === "" || (value.description === "" || descriptionLenght >= 5)}
                data-aat={value.description}
                onClick={saveThread}
              >
                Save
              </button>
              <button
                className="btn btn-default card-shadow ms-2"
                id="ok_button"
                onClick={cancel}
              >
                {moreLangs[i18n.resolvedLanguage]?.button_text?.cancel_text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewThread;
