import React, { Fragment, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  IsTokenChecker,
  IsArrayNotEmpty,
  HandleErrorTrackAndToast,
  IsStringChecker,
  compareString,
  getResolvedLanguage,
} from "../../../utils/helper";
import { useSelector, useDispatch } from "react-redux";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import CurrentLocation from "../../global/currentLocation";
import { Form, Button } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import WhiteCircleLoader from "../../whiteCircleLoader";

const PollWidgetDetails = (props) => {
  const { accounts } = useMsal();
  const {
    className = null,
    poll_data = {},
    poll_questions = [],
    loading,
    answered,
  } = props;

  const commentLimit = 500;
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const LocalTranslator = (
    pathName = ``,
    defaultValue = null,
    prefix = "home_page"
  ) => {
    try {
      if (IsTokenChecker(pathName, defaultValue)) {
        const str = _.get(
          moreLangs,
          `${resolvedLanguage}.${prefix}.${pathName}`
        );
        return IsStringChecker(str) ? str : defaultValue;
      } else {
        return defaultValue;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultValue;
    }
  };

  const CommentValidation = (comment) => {
    try {
      if (IsStringChecker(comment)) {
        return comment.substring(
          0,
          comment.length <= commentLimit ? comment.length : commentLimit
        );
      } else {
        return "";
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const {
    id: poll_id,
    poll_header = LocalTranslator("poll_header", "Quick Poll"),
  } = poll_data;
  const user_id = _.get(accounts, `0.localAccountId`, "");
  const user_fullname = _.get(accounts, `0.name`, "");
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const [err, setErr] = useState(false);
  const [submitted, setSubmitted] = useState(0);
  const [loader, setLoader] = useState(false);

  const InitialAnswers = [];
  const PollWidgetReducer = (state = [], action = {}) => {
    const GetModifiedArr = (oarr, item) => {
      if (item) {
        const oarrInd = oarr.findIndex((li) => li == item);
        if (oarrInd > -1) {
          oarr.splice(oarrInd, 1);
          return oarr;
        } else {
          oarr.push(item);
          return oarr;
        }
      } else {
        return oarr;
      }
    };
    try {
      const { Type, Command, QuestionId, Payload = "" } = action;
      if (Type === "comment" || Type === "single-select") {
        switch (Command) {
          case "Edit":
            const newState = [...state];
            const StateIndex = newState.findIndex(
              (li) => li?.question_id === QuestionId
            );
            const result = {
              question_id: QuestionId,
              question_type: Type,
              answer: [Payload || ""],
            };
            if (StateIndex > -1) {
              newState.splice(StateIndex, 1, result);
              return _.compact(newState);
            } else {
              newState.push(result);
              return _.compact(newState);
            }
          case "Remove":
            const removeState = [...state];
            const index = removeState.findIndex(
              (li) => li?.question_id === QuestionId
            );
            if (index > -1) {
              removeState.splice(index, 1);
              return _.compact(removeState);
            } else {
              return _.compact(state);
            }
          default:
            return _.compact(state);
        }
      } else if (Type === "multi-select") {
        switch (Command) {
          case "Edit":
            const newState = [...state];
            const result = Payload || "";
            const multiIndex = newState.findIndex(
              (li) => li?.question_id === QuestionId
            );
            if (multiIndex > -1) {
              const original = newState[multiIndex];
              const originalArr = original?.answer;
              const modifiedArr = IsArrayNotEmpty(originalArr)
                ? GetModifiedArr([...originalArr], result)
                : [result];
              const newData = {
                question_id: QuestionId,
                question_type: Type,
                answer: modifiedArr,
              };
              newState.splice(multiIndex, 1, newData);
              return _.compact(newState);
            } else {
              newState.push({
                question_id: QuestionId,
                answer: [result],
                question_type: Type,
              });
              return _.compact(newState);
            }
          case "Remove":
            const removeState = [...state];
            const index = removeState.findIndex(
              (li) => li?.question_id === QuestionId
            );
            if (index > -1) {
              removeState.splice(index, 1);
              return _.compact(removeState);
            } else {
              return _.compact(state);
            }
          default:
            return _.compact(state);
        }
      } else if (Type === "reset") {
        return _.compact(InitialAnswers);
      } else {
        return _.compact(state);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return _.compact(InitialAnswers);
    }
  };

  const [Answers, DispatchAnswers] = useReducer(
    PollWidgetReducer,
    InitialAnswers
  );

  const SubmitPollData = async (masterData = []) => {
    var SubmittedPollData = await Promise.all(
      masterData.map(async (postData) => {
        return await cmsDataCommonMethod(
          idToken,
          accounts,
          `custom-poll-entries`,
          "POST",
          postData
        )
          .then(() => {
            return true;
          })
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
            return false;
          });
      })
    ).catch((e) => {
      HandleErrorTrackAndToast(e, true, "replace", dispatch);
      return false;
    });
    const DataSubmitted = () => {
      if (IsArrayNotEmpty(SubmittedPollData)) {
        return SubmittedPollData.every((li) => !!li);
      } else {
        return false;
      }
    };
    const success = DataSubmitted();
    if (success) {
      setSubmitted(1);
      setLoader(false);
    } else {
      setSubmitted(2);
      setLoader(false);
    }
  };

  const onPollSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const ValidateAnswers = (Answers) => {
      if (IsArrayNotEmpty(Answers)) {
        return poll_questions.every((poll) => {
          const { id } = poll;
          const exist = Answers.find((li) => li?.question_id === id);
          if (exist) {
            const data = _.get(exist, "answer", []);
            return IsArrayNotEmpty(data) ? true : false;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    };
    const GetSubmitData = (Answers) => {
      const SubmitData = [];
      Answers.forEach((list) => {
        const masterPostData = {
          poll_id,
          user_id,
          user_fullname,
          poll_question_id: _.get(list, "question_id", null),
          is_active: true,
        };
        const options = _.get(list, "answer", []);
        const question_type = _.get(list, "question_type", null);
        if (IsArrayNotEmpty(options)) {
          options.forEach((li) => {
            let postData = { ...masterPostData };
            if (compareString(question_type, "comment")) {
              postData.poll_option_comment = CommentValidation(li);
              postData.poll_option_id = null;
            } else {
              postData.poll_option_comment = null;
              postData.poll_option_id = li;
            }
            SubmitData.push(postData);
          });
        }
      });
      return SubmitData;
    };
    const data_exist = ValidateAnswers(Answers);
    if (data_exist) {
      const SubmitData = GetSubmitData(Answers);
      if (IsArrayNotEmpty(SubmitData)) {
        setErr(false);
        setLoader(true);
        SubmitPollData(SubmitData);
      } else {
        setErr(true);
      }
    } else {
      setErr(true);
    }
  };

  const onCommentReducer = (e, question_id, question_type) => {
    const { value } = e?.target || {};
    const dispatchValue = {
      Type: question_type,
      QuestionId: question_id,
      Command: IsStringChecker(value) ? "Edit" : "Remove",
      Payload: CommentValidation(value),
    };
    DispatchAnswers(dispatchValue);
  };

  const onChoiceSelectReducer = (value, question_id, question_type) => {
    const dispatchValue = {
      Type: question_type,
      QuestionId: question_id,
      Command: value ? "Edit" : "Remove",
      Payload: value,
    };
    DispatchAnswers(dispatchValue);
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dispatchValue = {
      Type: "reset",
      QuestionId: "",
      Command: "",
      Payload: "",
    };
    DispatchAnswers(dispatchValue);
    setErr(false);
  };

  const submit_btn = LocalTranslator("submit", "Submit");
  const cancel_btn = LocalTranslator("cancel", "Cancel");
  const poll_error = LocalTranslator(
    "poll_error",
    "Please, answer all questions"
  );
  const poll_success = LocalTranslator(
    "poll_success",
    "Thank you. Your response has been submitted successfully"
  );
  const poll_fail = LocalTranslator(
    "poll_fail",
    "Sorry, unable to submit your response now. Please, try again later."
  );
  const poll_loading = LocalTranslator(
    "poll_loading",
    "component is loading... Please, wait."
  );

  if (loading) {
    return (
      <div
        className={`card bot-mspace-20 Quick_link card-shadow ${
          IsStringChecker(className) ? className : ``
        }`}
      >
        <h4 className="card-header white_color ver-space-15">
          <i className="fa fa-question-circle text-18"></i>
          <span className="left-space-5">{poll_header}</span>
        </h4>
        <div className="card-body">
          <p className="poll-success">
            <span>{poll_header}</span>
            <span> {poll_loading}</span>
          </p>
        </div>
      </div>
    );
  } else {
    if (submitted) {
      return (
        <div
          className={`card bot-mspace-20 Quick_link card-shadow ${
            IsStringChecker(className) ? className : ``
          }`}
        >
          <h4 className="card-header white_color ver-space-15">
            <i className="fa fa-question-circle text-18"></i>
            <span className="left-space-5">{poll_header}</span>
          </h4>
          <div className="card-body">
            <p className="poll-success">
              <span>
                <i
                  className={`fa grid_status_icon ${
                    submitted === 1
                      ? "fa-check-circle light_green_color"
                      : "fa-times-circle red_color"
                  }`}
                  aria-hidden="true"
                ></i>
              </span>
              <span> {submitted === 2 ? poll_fail : poll_success}</span>
            </p>
          </div>
        </div>
      );
    } else if (answered || !IsArrayNotEmpty(poll_questions)) {
      return <></>;
    } else {
      return (
        <div
          className={`card bot-mspace-20 Quick_link card-shadow ${
            IsStringChecker(className) ? className : ``
          }`}
        >
          <h4 className="card-header white_color ver-space-15">
            <i className="fa fa-question-circle text-18"></i>
            <span className="left-space-5">{poll_header}</span>
          </h4>
          <div className="card-body">
            <Form className="poll-widget-form" onSubmit={onPollSubmit}>
              {poll_questions.map((poll, index) => {
                const { id, question_type } = poll;
                const options = _.get(poll, "poll_options", []);
                const question = _.get(
                  poll,
                  `question_${resolvedLanguage}`,
                  null
                );
                const AnswerState =
                  Answers.find((li) => li?.question_id === id) || {};
                if (question_type === "comment") {
                  const value = _.get(AnswerState, "answer.0", "");
                  return (
                    <div key={index} className="row">
                      <div className="col">
                        <h5 className="poll-question">
                          <span className="fw-bold">{`Q${index + 1}. `}</span>
                          <span>{question}</span>
                        </h5>
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={value}
                            onChange={(e) =>
                              onCommentReducer(e, id, question_type)
                            }
                            maxLength={commentLimit}
                            minLength={1}
                          />
                          {value && value.length >= commentLimit && (
                            <p
                              className={`${
                                value.length > commentLimit
                                  ? "poll-error"
                                  : "text-end"
                              }`}
                            >
                              Maximum {commentLimit} characters allowed
                            </p>
                          )}
                        </Form.Group>
                        <hr className="poll-ruler height05" />
                      </div>
                    </div>
                  );
                } else if (question_type === "single-select") {
                  const value = _.get(AnswerState, "answer.0", "");
                  return (
                    <div key={index} className="row">
                      <div className="col">
                        <h5 className="poll-question">
                          <span className="fw-bold">{`Q${index + 1}. `}</span>
                          <span>{question}</span>
                        </h5>
                        {IsArrayNotEmpty(options)
                          ? options.map((opt) => {
                              const { id: opt_id } = opt || {};
                              const option = _.get(
                                opt,
                                `option_${resolvedLanguage}`,
                                null
                              );
                              return (
                                <Fragment key={opt_id}>
                                  <Form.Check
                                    type={"radio"}
                                    name={option}
                                    label={option}
                                    value={value}
                                    id={`${opt_id}-${option}`}
                                    checked={opt_id == value}
                                    onClick={() =>
                                      onChoiceSelectReducer(
                                        opt_id,
                                        id,
                                        question_type
                                      )
                                    }
                                  />
                                </Fragment>
                              );
                            })
                          : null}
                        <hr className="poll-ruler height05" />
                      </div>
                    </div>
                  );
                } else if (question_type === "multi-select") {
                  const valueArr = _.get(AnswerState, "answer", []);
                  return (
                    <div key={index} className="row">
                      <div className="col">
                        <h5 className="poll-question">
                          <span className="fw-bold">{`Q${index + 1}. `}</span>
                          <span>{question}</span>
                        </h5>
                        {IsArrayNotEmpty(options)
                          ? options.map((opt) => {
                              const { id: opt_id } = opt || {};
                              const label = _.get(
                                opt,
                                `option_${resolvedLanguage}`,
                                null
                              );
                              const value =
                                valueArr.find((li) => li == opt_id) || "";
                              return (
                                <Form.Group key={opt_id}>
                                  <Form.Check
                                    type={"checkbox"}
                                    name={label}
                                    label={label}
                                    id={`${opt_id}-${label}`}
                                    value={value}
                                    checked={opt_id == value}
                                    onClick={() =>
                                      onChoiceSelectReducer(
                                        opt_id,
                                        id,
                                        question_type
                                      )
                                    }
                                  />
                                </Form.Group>
                              );
                            })
                          : null}
                        <hr className="poll-ruler height05" />
                      </div>
                    </div>
                  );
                } else {
                  return <Fragment key={index} />;
                }
              })}
              {err && (
                <div className="row">
                  <div className="col">
                    <p className="poll-error">{poll_error}</p>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col">
                  <Button
                    className="btn btn-primary min-width-95 float-end"
                    type="submit"
                  >
                    {submit_btn} {loader ? <WhiteCircleLoader /> : ""}
                  </Button>
                  <Button
                    className="btn mx-2 btn-primary min-width-95 float-end"
                    type="button"
                    onClick={onCancelClick}
                  >
                    {cancel_btn}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      );
    }
  }
};

export default PollWidgetDetails;
