import React from "react";
import { Modal } from "react-bootstrap";
import { IsFunctionChecker, HandleErrorTrackAndToast } from "../../../utils/helper";

const ValidReactElementChecker = (element = null) => {
  try {
    if (element) {
      return React.isValidElement(element);
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

const OSAcademyCourseModal = (props) => {
  const {
    showModal = false,
    onModalHide = false,
    TitleContent = false,
    BodyContent = false,
    FootContent = false,
  } = props;

  //   console.log({ TitleContent, BodyContent, FootContent });
  //   console.log(typeof TitleContent, typeof BodyContent, typeof FootContent);
  // console.log(
  //   ValidReactElementChecker(TitleContent),
  //   ValidReactElementChecker(BodyContent),
  //   ValidReactElementChecker(FootContent)
  // );

  const ModalTitleContent = ValidReactElementChecker(TitleContent)
    ? () => TitleContent
    : false;
  const ModalBodyContent = ValidReactElementChecker(BodyContent)
    ? () => BodyContent
    : false;
  const ModalFootContent = ValidReactElementChecker(FootContent)
    ? () => FootContent
    : false;

  const Access =
    IsFunctionChecker(onModalHide) && ModalTitleContent && ModalBodyContent;
  //   console.log({ Access });

  try {
    if (Access) {
      return (
        <Modal
          show={showModal}
          onHide={onModalHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <h4 className="fw-bold mb-0" id="contained-modal-title-vcenter">
              <ModalTitleContent />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <ModalBodyContent />
            </div>
          </Modal.Body>
          {ModalFootContent && (
            <Modal.Footer>
              <ModalFootContent />
            </Modal.Footer>
          )}
        </Modal>
      );
    } else {
      return <></>;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return <></>;
  }
};

export default OSAcademyCourseModal;
