/**
 * @component_Name : MyAcreageDetails
 * @description : My acreage ->  MyAcreageDetails
 * @company : Photon
 * @author : Ashish Ranjan/18-08-2022
 **/
import React, { useEffect, useState } from "react";
import Events from "../../home/siteNotification";
import { useHistory } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
//import parse from "html-react-parser";
//import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import AcreageHistory from "./acreageHistory";
import ExhibitMaps from "./exhibitMaps";
import InteractiveExhibit from "./interactiveExhibit";
import {
  sortByField,
  groupArrayByKey,
  IsObjectChecker,
  IsTokenChecker,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import constantsVariable from "../../../utils/constantsVariable";
import ComponentListRenderer from "../../global/ContentBlockRenderSwitch";

const MyAcreageDetails = (props) => {
  const slug = "my-acreage";
  const { component_accordion_en_path, component_listing_dropdowns } =
    constantsVariable;
  const [loading, setLoading] = useState(true);

  const [groupedDataByAccordion, setGroupedDataByAccordion] = useState({});
  const [componentList, setComponentList] = useState([]);
  const dispatch = useDispatch();

  const location = useHistory().location;
  const arrayLocation = location.hash;
  const [selectedTab, setSeletedTab] = useState([]);

  const idToken = useSelector((state) => state.reducerToken);
  const { accounts } = useMsal();
  //const [contentBlock, setcontentBlock] = useState(null);
  //const { i18n } = useTranslation();

  const handlerAccordion = (e) => {
    //e.stopPropagation
    if (e !== null) {
      // if e === null, that means that an accordion item was collapsed rather than expanded. e will be non-null when an item is expanded
      localStorage.setItem("expandedItem", e);
      setSeletedTab(e);
    } else {
      localStorage.setItem("expandedItem", [5]);
    }
  };

  let retainAccordion = "";
  if (arrayLocation != "" || localStorage.expandedItem != "") {
    if (arrayLocation != "") {
      retainAccordion = arrayLocation.replace(/#/g, "");
    } else {
      retainAccordion = localStorage.expandedItem
        ? localStorage.expandedItem.split(",")
        : "5";
    }
  }

  let retainAccordionForSet = "";
  useEffect(() => {
    // document.title = "Pre Harvest";
    if (arrayLocation != "" || localStorage.expandedItem != "") {
      if (arrayLocation != "") {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        retainAccordionForSet = arrayLocation.replace(/#/g, "");
        setSeletedTab([retainAccordionForSet]);
      } else {
        retainAccordionForSet = localStorage.expandedItem
          ? localStorage.expandedItem.split(",")
          : ["5"];
        setSeletedTab(retainAccordionForSet);
      }
    }
    if (IsTokenChecker(idToken)) {
      getContentBlocks();
    }
  }, [idToken]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const getContentBlocks = async () => {
    const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));

        const groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        const componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );
        setComponentList(componentArr);

        const groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        setGroupedDataByAccordion(
          IsObjectChecker(groupedData) ? groupedData : {}
        );
      })
      // eslint-disable-next-line
      .catch((error) => {
        setGroupedDataByAccordion({});
        setComponentList([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RenderProperties = {
    AdjustmentClassName: "mt-0",
    AccordionClassNames: {
      accordionIconClassName: "img-35 right-space-10",
    },
  };
  const DefaultCssClass = component_listing_dropdowns?.plainText;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/* <!-- Accordion blocks starts --> */}
      <div className="mt-5 mb-3 mt-lg-0 mb-lg-4">
        {/* <!-- Accordion blocks starts --> */}
        <Events type={"my-acreage"} {...props} />
        <Accordion
          defaultActiveKey={retainAccordion}
          alwaysOpen
          id="accordionAcreage"
          onSelect={handlerAccordion}
          className="mt-0 harvest_news"
        >
          {/*---------Acreage History------*/}
          <Accordion.Item eventKey="acreageHistory">
            <Accordion.Header>
              <img
                src="/assets/images/shape_icon.svg"
                className="img-35 right-space-10"
                alt="Contracts_icon"
              />{" "}
              Acreage History
            </Accordion.Header>

            <Accordion.Body>
              {/*Acreage History body*/}
              {selectedTab &&
              selectedTab.filter((x) => x === "acreageHistory").length > 0 ? (
                <AcreageHistory {...props} />
              ) : null}
              {/*End */}
            </Accordion.Body>
          </Accordion.Item>

          {/*---------Exhibit Maps------*/}
          <Accordion.Item eventKey="exhibitMaps">
            <Accordion.Header>
              <img
                src="/assets/images/icon_my_acerage.svg"
                className="img-35 right-space-10"
                alt="Contracts_icon"
              />{" "}
              Exhibit Maps
            </Accordion.Header>

            <Accordion.Body>
              {/*Exhibit Maps body*/}
              {selectedTab &&
                selectedTab.filter((x) => x === "exhibitMaps").length > 0 && (
                  <ExhibitMaps {...props} />
                )}
              {/*End */}
            </Accordion.Body>
          </Accordion.Item>

          {/*---------Interactive Exhibit A------*/}
          <Accordion.Item eventKey="interactiveExhibitA">
            <Accordion.Header>
              <img
                src="/assets/images/profit_report.svg"
                className="img-35 right-space-10"
                alt="Contracts_icon"
              />{" "}
              Interactive Exhibit A
            </Accordion.Header>

            <Accordion.Body>
              {/*Interactive Exhibit A body*/}
              {selectedTab &&
                selectedTab.filter((x) => x === "interactiveExhibitA").length >
                  0 && <InteractiveExhibit {...props} />}
              {/*End */}
            </Accordion.Body>
          </Accordion.Item>

          {/*---------Make Changes to My Acreage------*/}
          {/* <Accordion.Item eventKey="makeChangestoMyAcreage">
            <Accordion.Header>
              <img
                src="/assets/images/edit_icon.svg"
                className="img-35 right-space-10"
                alt="Contracts_icon"
              />{" "}
              Make Changes to My Acreage
            </Accordion.Header>

            <Accordion.Body>
              {selectedTab &&
                selectedTab.filter((x) => x === "makeChangestoMyAcreage")
                  .length > 0 && (
                 
                )}
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
        <ComponentListRenderer
          ComponentList={componentList}
          GroupedData={groupedDataByAccordion}
          RenderProperties={RenderProperties}
          DefaultCssClass={DefaultCssClass}
        />
      </div>
    </>
  );
};

export default MyAcreageDetails;
