import React from "react";
import PageLayout from "../../pageLayout";
import OSContacts from "../../osContact/osContracts";
import { board_council_contacts_tabs } from "../../../utils/constantsVariable";
const BoardCouncilContacts = (props) => {
  return (
    <PageLayout
      {...props}
      body={
        <OSContacts
          {...props}
          tabList={board_council_contacts_tabs}
          page_slug={"board-council-contacts"}
          showCommittee={true}
        />
      }
    />
  );
};

export default BoardCouncilContacts;
