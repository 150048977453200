import React, { useEffect } from "react";
import PermissionWrapper from "./permissionWrapper";
import withAppInsights, { reactPlugin } from "./configs/appInsights";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { defaultSiteTitle } from "./utils/constantsVariable";
import {
  IsStringChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
} from "./utils/helper";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const WrapperComponent = (props) => {
  const { child, ...rest } = props;
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const title = _.get(rest, `title_${resolvedLanguage}`, null);

  useEffect(() => {
    document.title = `${
      IsStringChecker(title) ? `${title} | ` : ""
    }${defaultSiteTitle}`;
  }, [title]);

  return (
    <AppInsightsErrorBoundary
      onError={(e) => {
        HandleErrorTrackAndToast(e, true);
        return <PermissionWrapper {...rest} child={child} />;
      }}
      appInsights={reactPlugin}
    >
      <PermissionWrapper {...rest} child={child} />
    </AppInsightsErrorBoundary>
  );
};

export default withAppInsights(WrapperComponent);
