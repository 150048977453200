/**
 * @component_Name : ArchiveDetails
 * @description : ArchiveDetails
 * @company : Photon
 * @author : Ashish Ranjan/03-11-2022
 **/
import React, { useState, useEffect, useCallback, Fragment } from "react";
import "../../../css/loading.css";
import Loading from "../../global/loading";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import DataGridLoadMore from "../../global/dataGridLoadMore";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
  compareString,
  IsArrayNotEmpty,
} from "../../../utils/helper";
import { ConstantsVar } from "../../../utils/constants";
import CurrentLocation from "../../global/currentLocation";
import Moment from "react-moment";
import { useParams, useHistory } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { BreadCrumbForArchive } from "../../global/breadCrumb";
import moment from "moment";
const ArchiveDetails = (props) => {
  const { paramCategory = null, SpecialRoleExist, RoleCBPrivileges } = props;
  const spl_component_allow = "codebooks";
  const { accounts } = useMsal();
  const tokenId = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [getPath, moreLangs] = CurrentLocation();
  /*getting url and convert into array*/

  const history = useHistory();
  const { categorySlug } = useParams();
  const arrayLocation = getPath.slice(1).split("/").splice(0, 3);

  const [loader, setLoader] = useState(false);
  const [filterOption, setFilterOption] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [defaultCategoryId, setdefaultCategoryId] = useState(
    arrayLocation.length == 2 ? arrayLocation[arrayLocation.length - 1] : ""
  );
  const [categoryId, setCategoryId] = useState("");
  const [gridItems, setGridItems] = useState([]);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [isListingHide, setIsListingHide] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [showSearchText, setShowSearchText] = useState("");
  const [urlMoreData, setUrlMoreData] = useState("");
  const [viewAllStatus, setViewAllStatus] = useState(false);
  const archiveUrl =
    "archives?select=archive_category_id,id,title_en,title_fr,title_es,posting_date,url,archive_categories!inner(id,title_en,title_fr,title_es,slug)&order=posting_date.desc&archive_categories.slug=eq.";
  const limitval = 10;
  const archiveLimitUrl = `&limit=${limitval}`;
  const [currentResultCount, setCurrentResultCount] = useState(limitval);
  const [showMessage, setShowMessage] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const {
    posting_date_text,
    view_all_text,
    search_text,
    search_result_text1,
    search_result_text2,
    search_result_text3,
  } = {
    ...moreLangs[i18n.resolvedLanguage]?.archive_page?.archive_search_page,
  };

  const columns = [
    {
      key: "title_" + i18n.language,
      name: `${selectedLabel["title_" + i18n.language]}`,
      //width: 360,
      width: "60%",
      headerCellClass: "text-lg-left",
      cellClass: "enablesTextWrapping break_all_word",
      formatter(props) {
        if (props.row != undefined) {
          return (
            <>
              {
                <span title={props.row["title_" + i18n.language]}>
                  <a
                    href={props.row.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.row["title_" + i18n.language]}
                  </a>
                </span>
              }
            </>
          );
        }
      },
    },
    {
      key: "posting_date",
      name: `${posting_date_text}`,
      //width: 150,
      width: "37%",
      headerCellClass: "text-lg-end right-border-radius",
      cellClass: "text-lg-end ",
      formatter(props) {
        if (props.row != undefined) {
          return (
            <>
              {
                <Moment utc format={ConstantsVar.dateFormate}>
                  {props.row.posting_date}
                </Moment>
              }
            </>
          );
        }
      },
    },
  ];
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (filterOption.length > 0) {
      setIsListingHide(true);
      const filterSlug = categoryId != "" ? categoryId : defaultCategoryId;
      const choosedOpt = filterOption.filter((x) => x.slug == filterSlug);
      setSelectedLabel(choosedOpt[0]);
      let searchUrl = archiveUrl + filterSlug;
      if (searchText != "") {
        searchUrl = `${searchUrl}&title_${i18n.language}=plfts.${searchText}`;
        getGridData(searchUrl, "searchCount");
      } else IsTokenChecker(tokenId) && getGridData(searchUrl);
    }
    setDisplayAccordian(
      localStorage.getItem("safariLowerVersion") === "true" || false
    );
  }, [tokenId, i18n.language, filterOption]);

  useEffect(() => {
    setLoader(true);
    const access = RoleBasedArchiveAccess(
      RoleCBPrivileges,
      SpecialRoleExist,
      categorySlug
    );
    if (access) {
      IsTokenChecker(tokenId) &&
        getDropdownData(RoleCBPrivileges, SpecialRoleExist);
    } else {
      history.push("/");
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId, RoleCBPrivileges, SpecialRoleExist, categorySlug]);

  function RoleBasedArchiveAccess(privilegdes, special_role, category_slug) {
    try {
      if (special_role) {
        const { roleCBThirdPartyFarmService } = privilegdes;
        if (roleCBThirdPartyFarmService) {
          return compareString(spl_component_allow, category_slug);
        }
        return true;
      }
      return true;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  }

  function RoleBasedArchiveCategoriesFilter(
    category_arr,
    privilegdes,
    special_role
  ) {
    try {
      if (IsArrayNotEmpty(category_arr)) {
        if (special_role) {
          const { roleCBThirdPartyFarmService } = privilegdes;
          if (roleCBThirdPartyFarmService) {
            return category_arr.filter((it) =>
              compareString(spl_component_allow, it?.slug)
            );
          }
          return category_arr;
        }
        return category_arr;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  // useEffect(() => {
  //   if(arrayLocation.length == 2 && filterOption.length > 0 && arrayLocation[arrayLocation.length-1] !== defaultCategoryId){
  //     filterOption.filter(i=>i.slug === arrayLocation[arrayLocation.length-1]).length === 0 ? setdefaultCategoryId(filterOption[0].slug) : setdefaultCategoryId(arrayLocation[arrayLocation.length-1]) ;
  //   }
  // }, []);

  // useEffect(() => {
  //   setLoader(true);
  //   if (defaultCategoryId != "") {
  //     const url = archiveUrl + defaultCategoryId;
  //     IsTokenChecker(tokenId) && getGridData(url);
  //   }
  // }, [tokenId, defaultCategoryId]);

  const getDropdownData = async (privilegdes, special_role) => {
    if (IsTokenChecker(tokenId)) {
      const url = `archive_categories?order=slug`;
      await cmsDataCommonMethod(tokenId, accounts, url)
        .then((resp) => {
          const temp = resp.data.data;
          const result = RoleBasedArchiveCategoriesFilter(
            temp,
            privilegdes,
            special_role
          );

          const defaultValue = {
            id: -1,
            title_en: "Please Select",
            title_fr: "Veuillez Sélectionner",
            title_es: "Por favor selecciona",
            slug: "-",
            is_active: true,
            created_at: "2022-12-21T17:14:00.4",
            updated_at: "2022-12-21T17:14:00.4",
          };
          result.splice(0, 0, defaultValue);
          if (result.length > 0) {
            if (defaultCategoryId === "") {
              if (paramCategory != undefined || paramCategory != null) {
                setdefaultCategoryId(paramCategory);
              } else {
                setdefaultCategoryId(result[0].slug);
              }
            } else {
              result.filter((i) => i.slug === defaultCategoryId).length === 0 &&
                setdefaultCategoryId(result[0].slug);
            }
            //setSelectedLabel(result[0]['title_' + i18n.language]);
            setSelectedLabel(result[0]);
            setFilterOption(result);
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  function GetRecentYearForSpecialRole(resultData) {
    try {
      const data = resultData.find((li) => {
        const posting_date = li?.posting_date;
        const year = moment(posting_date).format("YYYY");
        return year && !compareString(year, "Invalid date");
      });
      if (data) {
        return moment(data?.posting_date).format("YYYY");
      }
      return null;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  }

  function SpecialRoleResultFilter(resultData, privilegdes, special_role) {
    try {
      if (IsArrayNotEmpty(resultData)) {
        if (special_role) {
          const { roleCBThirdPartyFarmService } = privilegdes;
          if (roleCBThirdPartyFarmService) {
            const year = GetRecentYearForSpecialRole(resultData);
            if (year) {
              const filtered_data = resultData.filter((li) => {
                const p_d = li?.posting_date;
                const y = moment(p_d).format("YYYY");
                return year === y;
              });
              return filtered_data;
            }
            return [];
          }
          return resultData;
        }
        return resultData;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  }

  const getGridData = async (url = "", action = "") => {
    if (IsTokenChecker(tokenId) && url != "") {
      let urlWithLimit = url + archiveLimitUrl;
      if (action === "viewall" || action === "searchCount") {
        urlWithLimit = url;
      }
      await cmsDataCommonMethod(tokenId, accounts, urlWithLimit)
        .then((resp) => {
          let result_data = resp?.data?.data;
          const result = SpecialRoleResultFilter(
            result_data,
            RoleCBPrivileges,
            SpecialRoleExist
          );
          if (result.length > 0) {
            if (action === "searchCount") {
              setSearchCount(result.length);
              setShowMessage(true);
              result.length > 10 && result.splice(10, result.length);
            }
            setIsListingHide(false);
            setNoRecordErrorMsg("");
            action === "loadmore"
              ? setGridItems([...gridItems, ...result])
              : setGridItems(result);
            action === "viewall"
              ? setHideLoadmore(true)
              : setHideLoadmore(result.length < 10);
          } else {
            setIsListingHide(false);
            setGridItems([]);
            setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
          }
          setUrlMoreData(urlWithLimit);
        })
        // eslint-disable-next-line no-console
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const handlerCategory = useCallback(
    (e) => {
      e.preventDefault();
      const { value } = e.target;
      if (value !== "-") {
        window.location.href = e.target.selectedOptions[0].dataset.redirect;
        return;
      }
      setSearchText("");
      setIsListingHide(true);
      setLoader(true);
      setShowMessage(false);
      setGridItems([]);
      setViewAllStatus(false);
      setdefaultCategoryId(value);
      setCategoryId(value);
      setCurrentResultCount(limitval);
      const choosedOpt = filterOption.filter((x) => x.slug == value);
      setSelectedLabel(choosedOpt[0]);
      let searchUrl = archiveUrl + value;
      getGridData(searchUrl);
    },
    [filterOption, searchText]
  );

  const handlerSearchOnSubmit = useCallback(
    (event) => {
      setOffset(10);
      event.preventDefault();
      setIsListingHide(true);
      setLoader(true);
      setShowMessage(false);
      setGridItems([]);
      setViewAllStatus(false);
      setCurrentResultCount(limitval);
      setShowSearchText(searchText);
      const filterSlug = categoryId != "" ? categoryId : defaultCategoryId;
      let searchUrl = `${archiveUrl}${filterSlug}`;
      if (searchText != "") {
        searchUrl = `${searchUrl}&title_${i18n.language}=plfts.${searchText}`;
        getGridData(searchUrl, "searchCount");
        // getGridData(searchUrl);
      } else {
        getGridData(searchUrl);
      }
    },
    [categoryId, defaultCategoryId, searchText]
  );

  const handlerViewAll = useCallback(
    (event) => {
      event.preventDefault();
      setLoader(true);
      setCurrentResultCount(limitval);
      const filterSlug = categoryId != "" ? categoryId : defaultCategoryId;
      let searchUrl = archiveUrl + filterSlug;
      setViewAllStatus(true);
      if (searchText != "") {
        searchUrl = `${searchUrl}&title_${i18n.language}=plfts.${searchText}`;
        getGridData(searchUrl, "viewall");
      } else {
        getGridData(searchUrl, "viewall");
      }
    },
    [categoryId, defaultCategoryId, searchText]
  );
  const [offset, setOffset] = useState(10);
  const [hideLoadmore, setHideLoadmore] = useState(false);
  const loadMoreRows = () => {
    setOffset(offset + 10);
    setLoader(true);
    const filterSlug = categoryId != "" ? categoryId : defaultCategoryId;
    let searchUrl = `${archiveUrl}${filterSlug}&offset=${offset}`;
    if (searchText != "") {
      searchUrl = `${searchUrl}&title_${i18n.language}=plfts.${searchText}`;
      getGridData(searchUrl, "loadmore");
    } else {
      getGridData(searchUrl, "loadmore");
    }
  };

  function FilterAccess(special_role, privilegdes){
    if(special_role){
      if(privilegdes?.roleCBThirdPartyFarmService){
        return false;
      }
      return true;
    }
    return true;
  }

  const access = FilterAccess(SpecialRoleExist, RoleCBPrivileges);
  return (
    <>
      <div className="row archive-page">
        <div className="col-12 d-none d-lg-block">
          <div className=" col-12 col-lg-12 d-none d-lg-block">
            <BreadCrumbForArchive
              {...props}
              selectedLabel={selectedLabel}
              filterOption={filterOption}
              categorySlug={categorySlug}
            />
          </div>
        </div>
        {/*<!--  Grower Benefits block starts -->*/}
        {loader && (
          <div className="zindex10000">
            {" "}
            <div className="nav-mobile-overlay-display"></div>
            <Loading />
          </div>
        )}
        {access && (
          <div className="row">
            <div className="col-12">
              <div className="float-end mb-0 mb-lg-2">
                <div className="row mt-sm-5 mt-lg-0">
                  {defaultCategoryId !== "-" && (
                    <div className="col-auto pe-0 sm-width-100 mt-5 mt-sm-0">
                      <form onSubmit={handlerSearchOnSubmit}>
                        <span className="input-group border-1 border-radius-6 my-2 my-lg-0">
                          <span
                            className="input-group-text border-0 white_bg"
                            id="basic-addon1"
                          >
                            <i
                              className="fa fa-search blue_color text-18"
                              aria-hidden="true"
                            ></i>
                          </span>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSearchText(e.target.value);
                            }}
                            className="form-control border-0 py-2 left-space-0"
                            placeholder={search_text}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={searchText}
                          />
                        </span>
                      </form>
                    </div>
                  )}
                  <div className="col-auto pe-0 mb-2 sm-width-100 position-relative">
                    <select
                      className="form-select text-14 filter_text ver-space-9 my-2 my-lg-0 remove_arrow_select"
                      id="category_list"
                      aria-label="Default select example"
                      onChange={handlerCategory}
                    >
                      {filterOption != "" &&
                        Object.values(filterOption).map((x) => {
                          x;
                          return (
                            <option
                              value={x?.slug}
                              selected={
                                defaultCategoryId == x?.slug ? "selected" : ""
                              }
                              key={x?.slug}
                              data-redirect={`/documents/archives/${x?.slug}`}
                            >
                              {x?.["title_" + i18n.language]}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showMessage && (
          <div className="row">
            <div className="alert alert-primary" role="alert">
              {`${search_result_text1} "${showSearchText}" ${search_result_text2} ${
                selectedLabel["title_" + i18n.language]
              } : ${searchCount} ${search_result_text3}.`}
            </div>
          </div>
        )}
        <div className="row position-relative">
          <div
            className={`archive-detail visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block`}
          >
            {isListingHide == false && !displayAccordian && (
              <>
                {noRecordErrorMsg == "" ? (
                  <>
                    {viewAllStatus == false && gridItems.length == 10 && (
                      <div>
                        <button
                          onClick={handlerViewAll}
                          className="btn btn-primary mt-3 view_all"
                        >
                          {view_all_text}
                        </button>
                      </div>
                    )}
                    <DataGridLoadMore
                      uniqueIdentifier="id"
                      gridData={gridItems}
                      columns={columns}
                      isPagination={false}
                      itemsPerPage={viewAllStatus == false ? 10 : 0}
                      columnResize={false}
                      classNames={`overflow-y-hidden contain-block-size-reset ver-mspace-20 border-radius-6 ${
                        viewAllStatus == false &&
                        gridItems.length == 10 &&
                        "grid-load-more-height"
                      }`}
                      autoRowHeight={true}
                      conponentName={"archive"}
                      dynamicHeaderHeight={45}
                      urlMoreData={urlMoreData}
                      accounts={accounts}
                      currentResultCount={currentResultCount}
                      setCurrentResultCount={setCurrentResultCount}
                    />
                  </>
                ) : (
                  noRecordErrorMsg != "" && (
                    <div className="p-2 red_color text-center border-1 border-radius-6 my-3">
                      <i className="fa fa-info-circle pe-2"></i>
                      {defaultCategoryId !== "-"
                        ? noRecordErrorMsg
                        : moreLangs[i18n.resolvedLanguage].archive_page
                            .please_select_category}
                    </div>
                  )
                )}
              </>
            )}
          </div>
          <div
            className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 desktop-accordion-table  ${
              !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
            }`}
          >
            {noRecordErrorMsg == "" ? (
              <>
                {viewAllStatus == false && !hideLoadmore && (
                  <div>
                    <button
                      onClick={handlerViewAll}
                      className="btn btn-primary mt-3 view_all"
                    >
                      {view_all_text}
                    </button>
                  </div>
                )}
                <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                  <Accordion
                    defaultActiveKey="0"
                    className="table mobile-accordion accordion_space"
                  >
                    {gridItems.map((data, index) => {
                      return (
                        <Accordion.Item key={index} eventKey={index}>
                          <Accordion.Header>
                            {selectedLabel["title_" + i18n.language]}:{" "}
                            {data["title_" + i18n.language]}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="tbody">
                              <div className="tr">
                                <div className="td"></div>
                                <div
                                  className="td"
                                  data-header={
                                    selectedLabel["title_" + i18n.language]
                                  }
                                >
                                  <span>
                                    <a
                                      href={data.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {data["title_" + i18n.language]}
                                    </a>
                                  </span>
                                </div>
                                <div
                                  className="td"
                                  data-header={posting_date_text}
                                >
                                  <span>
                                    <Moment
                                      utc
                                      format={ConstantsVar.dateFormate}
                                    >
                                      {data["posting_date"]}
                                    </Moment>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
                {!hideLoadmore && gridItems.length > 0 && (
                  <button
                    onClick={loadMoreRows}
                    className="btn btn-primary mb-3"
                  >
                    Load More
                  </button>
                )}
              </>
            ) : (
              noRecordErrorMsg != "" && (
                <div className="p-2 red_color text-center border-1 border-radius-6 my-3">
                  <i className="fa fa-info-circle pe-2"></i>
                  {defaultCategoryId !== "-"
                    ? noRecordErrorMsg
                    : moreLangs[i18n.resolvedLanguage].archive_page
                        .please_select_category}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ArchiveDetails;
