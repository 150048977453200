/**
 * @component_Name : AcreageHistoryMobile 
 * @description : Show AcreageHistory as accodion
 * @company : Photon
 * @author : Ashish Ranjan/22-08-2022
 **/

import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
const AcreageHistoryMobile = ({ listingData }) => {
  const pagingArray = Object.keys(listingData).map((x) => x)
  const listLength = pagingArray.length;
  return (
    <>
      <Accordion defaultActiveKey="0">
        {(listLength > 0 && Object.values(listingData).map((data, index) => {
          return (
            <Fragment key={index}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                Crop Year: {data.Crop_Year}, Contract: {data.Contract_Number}, Bed: {data.Harvest_Bed}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td"></div>
                      <div className="td" data-header="Crop Year"><span>{data.Crop_Year}</span></div>
                      <div className="td" data-header="Contract Number"><span>{data.Contract_Number}</span></div>
                      {/* <div className="td" data-header="Contract Name"><span>{data.Contract_Name}</span></div> */}
                      {/* <div className="td" data-header="Name2"><span>{data.Name2}</span></div> */}
                      {/* <div className="td" data-header="Identifier"><span>{data.Identifier}</span></div> */}
                      <div className="td" data-header="Contract Name"><span>{data.CatContractName}</span></div>
                      <div className="td" data-header="Bog Name"><span>{data.Bog_Name}</span></div>
                      <div className="td" data-header="Harvest Bed"><span>{data.Harvest_Bed}</span></div>
                      <div className="td" data-header="Variety"><span>{data.Variety}</span></div>
                      <div className="td" data-header="Plant Year"><span>{data.Plant_Year}</span></div>
                      <div className="td" data-header="Production Status"><span>{data.Production_Status}</span></div>
                      <div className="td" data-header="Acres"><span>{data.Acres}</span></div>
                      <div className="td" data-header="Active Acres"><span>{data.Active_Acres}</span></div>
                      <div className="td" data-header="Adjusted Active Acres"><span>{data.Adjusted_Active_Acres}</span></div>
                      <div className="td" data-header="Map"><span>{data.Map}</span></div>
                      <div className="td" data-header="Map Of"><span>{data.Map_Of}</span></div>
                      <div className="td" data-header="Map Id"><span>{data.Map_Id}</span></div>
                      <div className="td" data-header="Map Acres"><span>{data.Map_Acres}</span></div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Fragment>
          );
        }))}
      </Accordion>
    </>
  );
};

export default AcreageHistoryMobile;