/**
 * @component_Name : permissionWrapper
 * @description : Logic for Role based Security over the entire App
 * @company : Photon
 * @author : Nivethithan Kannan/07-12-2022
 **/
import React from "react";
import { Redirect, matchPath } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import _ from "lodash";
import Roles, {
  ContractBasedRoles,
  PersonBasedRoles,
  roleCBThirdPartyFarmService,
  SpecialRolesDefinition,
} from "./utils/roles";
import {
  safeArrayCheck,
  IsArrayNotEmpty,
  IsStringChecker,
  compareString,
  IsObjectChecker,
  IsArrayChecker,
  lowStr,
  HandleErrorTrackAndToast,
  getCookie,
} from "./utils/helper";
import routes, { RoutesInRedirect } from "./routes";
const Routes = [...routes, ...RoutesInRedirect];

/* --- General Functions Start --- */
const UniqueRolesArray = (item, index, original) => {
  try {
    return (
      original.findIndex((list) => compareString(list, item)) === index && item
    );
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};
/* --- General Functions End --- */

//Role Logic Supporting Functions Start
/**
 * @function: DoRoleExist
 * @description: Returns true if Role exists in given array else false.
 * @author: Nivethithan Kannan
 */
export const DoRoleExist = (Role = null, ArrayToCheck = []) => {
  try {
    const roleIndex = ArrayToCheck.findIndex((li) => compareString(li, Role));
    return roleIndex >= 0;
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    return false;
  }
};

/**
 * @function: CheckArrayRoleExist
 * @description: Based on the argument if all is true [all roles should exist], then executes DoRoleExist using EVERY method. if all is false [atleast one role should exist], then executes DoRoleExist using SOME method
 * @author: Nivethithan Kannan
 */
export const CheckArrayRoleExist = (
  RolesNeeded = [],
  RolesToCheck = [],
  all = false
) => {
  try {
    if (all) {
      return RolesNeeded.every((role) => {
        return DoRoleExist(role, RolesToCheck);
      });
    } else {
      return RolesNeeded.some((role) => {
        return DoRoleExist(role, RolesToCheck);
      });
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: BooleanRoleAssignToContract
 * @description: Assign Boolean values regarding the required role present in the contract roles arr.
 * Returns as an Object based on the fields in RoleToFilter
 * @author: Nivethithan Kannan
 */
export const BooleanRoleAssignToContract = (
  RoleToFilter = [],
  Roles = [],
  BooleanRoleAssign = {}
) => {
  try {
    const Temp = { ...BooleanRoleAssign };
    RoleToFilter.forEach((list) => {
      Temp[list] = DoRoleExist(list, Roles);
    });
    return Temp;
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    return BooleanRoleAssign;
  }
};

/**
 * @function: RoleReducerFromContractsArray
 * @description: Returns the array of unique roles from array of objects
 * @author: Nivethithan Kannan
 */
export const RoleReducerFromContractsArray = (contracts = []) => {
  try {
    if (IsArrayNotEmpty(contracts)) {
      const rolesArr = contracts.reduce((accumulator, list) => {
        const { roles = [] } = list;
        return [...accumulator, ...safeArrayCheck(roles)];
      }, []);
      const unique = rolesArr.filter(UniqueRolesArray);
      return unique;
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
};

/**
 * @function: ContractsFromJSONParser
 * @description: Parses and Returns the array of objects. if reduce is true then executes RoleReducerFromContractsArray.
 * @author: Nivethithan Kannan
 */
export const ContractsFromJSONParser = (accounts = null, reduce = true) => {
  try {
    const contracts = _.get(
      accounts,
      `0.idTokenClaims.gbsRolesByContract`,
      null
    );
    if (IsStringChecker(contracts)) {
      const parsed = JSON.parse(contracts);
      return reduce ? RoleReducerFromContractsArray(parsed) : parsed;
    } else if (IsArrayChecker(contracts)) {
      return reduce ? RoleReducerFromContractsArray(contracts) : contracts;
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
};

/**
 * @function: GetRoutes
 * @description: Returns the Route object based on the given path from given Routes Array
 * @author: Nivethithan Kannan
 */
export const GetRoutes = (pathName = null, RouteArr = []) => {
  try {
    if (IsStringChecker(pathName) && IsArrayNotEmpty(RouteArr)) {
      const role = RouteArr.find((list) => {
        const { path = "" } = list;
        const match = matchPath(pathName, { path, exact: true });
        // console.log({ match, pathName, path });
        return match !== null;
        // return compareString(path, pathName);
      });
      if (IsObjectChecker(role)) {
        role.pathName = pathName;
        return role;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return null;
  }
};

/**
 * @function: GetRoles
 * @description: Returns the Role object based on the given pathname from given Roles Array
 * @author: Nivethithan Kannan
 */
export const GetRoles = (pathName = null, RolesArr = []) => {
  try {
    if (IsStringChecker(pathName) && IsArrayNotEmpty(RolesArr)) {
      const role = RolesArr.find((list) => {
        const { pathNames = [] } = list;
        if (IsArrayNotEmpty(pathNames)) {
          return pathNames.find((li) => {
            const match = matchPath(pathName, { path: li, exact: true });
            // console.log({ match, pathName, li });
            // return compareString(pathName, li);
            return match !== null;
          });
        } else {
          return false;
        }
      });
      if (IsObjectChecker(role)) {
        role.path = pathName;
        return role;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return null;
  }
};

/**
 * @function: GetRoles
 * @description: Returns the Role object based on the given pathname from given Roles Array
 * @author: Nivethithan Kannan
 */
export const GetRolesWithParamCheck = (pathName = null, RolesArr = []) => {
  try {
    if (IsStringChecker(pathName) && IsArrayNotEmpty(RolesArr)) {
      const role = RolesArr.find((list) => {
        const { pathNames = [], RouteParamCBRoles = [] } = list;
        if (IsArrayNotEmpty(pathNames)) {
          return pathNames.find((li) => {
            const match = matchPath(pathName, { path: li, exact: true });
            // console.log({ match, pathName, li });
            // return compareString(pathName, li);
            if (IsArrayNotEmpty(RouteParamCBRoles)) {
              if (match !== null) {
                const { params } = match;
                const values = Object.values(params);
                // console.log({result:CheckArrayRoleExist(RouteParamCBRoles, values), RouteParamCBRoles, values});
                return CheckArrayRoleExist(RouteParamCBRoles, values);
              }
            }
            return match !== null;
          });
        } else {
          return false;
        }
      });
      if (IsObjectChecker(role)) {
        role.path = pathName;
        return role;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return null;
  }
};

/**
 * @function: ComponentDeciderObjInitialize
 * @description: Returns Object with Boolean as False For Show/Hide Accordions under Components
 * @author: Nivethithan Kannan
 */
export function ComponentDeciderObjInitialize(role = {}) {
  try {
    const rolesArr = Object.keys(role);
    if (IsArrayNotEmpty(rolesArr)) {
      return rolesArr.reduce((accumulator, list) => {
        return { ...accumulator, [list]: false };
      }, {});
    } else {
      return {};
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
}

/**
 * @function: ComponentDecider - (Not Used)
 * @description: Returns Object with Boolean For Show/Hide Accordions under Components
 * @author: Nivethithan Kannan
 */
export const ComponentDecider = (role = {}, RolesToCheck = []) => {
  try {
    if (IsArrayNotEmpty(RolesToCheck)) {
      const rolesArr = Object.keys(role);
      return IsArrayNotEmpty(rolesArr)
        ? rolesArr.reduce((accumulator, list) => {
            const value = _.get(role, list);
            const exist = IsArrayNotEmpty(value)
              ? CheckArrayRoleExist(value, RolesToCheck, true)
              : false;
            return { ...accumulator, [list]: exist };
          }, {})
        : {};
    } else {
      return {};
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
};
// Role Logic Supporting Functions End

// Role Logic Main Functions Start
/**
 * @function: PlainObjectComponentDecider
 * @description: Returns Object with Boolean For Show/Hide Accordions under Components
 * @author: Nivethithan Kannan
 */
export function PlainObjectComponentDecider(role = {}, RolesToCheck = []) {
  const empty = ComponentDeciderObjInitialize(role);
  try {
    if (IsArrayNotEmpty(RolesToCheck)) {
      const rolesArr = Object.keys(role);
      return IsArrayNotEmpty(rolesArr)
        ? rolesArr.reduce((accumulator, list) => {
            const value = _.get(role, list);
            const exist = IsStringChecker(value)
              ? DoRoleExist(value, RolesToCheck)
              : false;
            return { ...accumulator, [list]: exist };
          }, {})
        : empty;
    } else {
      return empty;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return empty;
  }
}

/**
 * @function: ValidGBSRoleByContracts
 * @description: Returns Array of Objects of gbsRolesByContract data that satisfies the given roles
 * @author: Nivethithan Kannan
 */
export const ValidGBSRoleByContracts = (
  accounts = {},
  RoleToFilter = [],
  idsOnly = true,
  matchAll = true
) => {
  try {
    const RoleByContractsArr = ContractsFromJSONParser(accounts, false);
    if (IsArrayNotEmpty(RoleByContractsArr) && IsArrayNotEmpty(RoleToFilter)) {
      const filteredContracts = RoleByContractsArr.filter((list) => {
        if (IsObjectChecker(list)) {
          const { roles } = list;
          if (IsArrayNotEmpty(roles)) {
            return CheckArrayRoleExist(RoleToFilter, roles, matchAll);
          }
        }
      });
      if (idsOnly) {
        return IsArrayNotEmpty(filteredContracts)
          ? filteredContracts.map((li) => li.contract)
          : [];
      } else {
        return filteredContracts;
      }
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
};

/**
 * @function: FilterContractByRole
 * @description: Returns Array of Objects For Show/Hide Record under DataGrid
 * @author: Nivethithan Kannan
 */
export const FilterContractByRole = (
  ContractsArr = [],
  accounts = {},
  RoleToFilter = [],
  contractField = "RelationshipId",
  matchAll = false
) => {
  try {
    const RoleByContractsArr = ContractsFromJSONParser(accounts, false);
    if (
      IsArrayNotEmpty(ContractsArr) &&
      IsArrayNotEmpty(RoleByContractsArr) &&
      IsArrayNotEmpty(RoleToFilter)
    ) {
      return ContractsArr.filter((list) => {
        const RelationshipId = _.get(list, contractField, null);
        const role = RoleByContractsArr.find(
          (li) => li.contract == RelationshipId
        );
        if (IsObjectChecker(role)) {
          const { roles } = role;
          if (IsArrayNotEmpty(roles)) {
            return CheckArrayRoleExist(RoleToFilter, roles, matchAll);
          }
        }
      });
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
};

/**
 * @function: MapContractsWithRole
 * @description: Returns the Contracts array with/without AssignedRoles Object obtained from BooleanRoleAssignToContract based on the Contracts & Roles existing in the user accounts.
 * @author: Nivethithan Kannan
 */
export const MapContractsWithRole = (
  ContractsArr = [],
  accounts = {},
  RoleToFilter = [],
  contractField = "RelationshipId"
) => {
  try {
    const RoleByContractsArr = ContractsFromJSONParser(accounts, false);
    if (
      IsArrayNotEmpty(ContractsArr) &&
      IsArrayNotEmpty(RoleByContractsArr) &&
      IsArrayNotEmpty(RoleToFilter)
    ) {
      const BooleanRoleAssign = {};
      RoleToFilter.forEach((element) => {
        BooleanRoleAssign[element] = false;
      });
      return ContractsArr.reduce((accumulator, list) => {
        const RelationshipId = _.get(list, contractField, null);
        const role = RoleByContractsArr.find(
          (li) => li.contract == RelationshipId
        );
        if (IsObjectChecker(role)) {
          const { roles } = role;
          if (IsArrayNotEmpty(roles)) {
            const AssignedRoles = BooleanRoleAssignToContract(
              RoleToFilter,
              roles,
              BooleanRoleAssign
            );
            return [...accumulator, { ...list, AssignedRoles }];
          }
          return accumulator;
        }
        return accumulator;
      }, []);
    }
    return ContractsArr;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return ContractsArr;
  }
};

/**
 * @function: RoleCheckerForArrayData
 * @description: Returns Array of Objects For Show/Hide Menus under Header,LeftNav,QuickLinks using RoleChecker
 * @author: Nivethithan Kannan
 */
export const RoleCheckerForArrayData = (
  arr = [],
  field = "url",
  gbsRolesByPerson = [],
  gbsRolesByContract = [],
  SpecialRoleExist = false,
  RolesArr = []
) => {
  try {
    // console.log({
    //   arr,
    //   field,
    //   gbsRolesByPerson,
    //   gbsRolesByContract,
    //   SpecialRoleExist,
    //   RolesArr,
    // });
    if (
      IsArrayNotEmpty(arr) &&
      IsStringChecker(field) &&
      IsArrayNotEmpty(RolesArr)
    ) {
      if (SpecialRoleExist) {
        return arr.filter((list) => {
          const path = _.get(list, field, null);
          const RoutesToCheck = GetRoutes(path, Routes);
          // console.log({ path, RoutesToCheck });
          if (RoutesToCheck) {
            const { CheckSpecialRoleCB, CheckRoleCB, IsCommonPage } =
              RoutesToCheck;
            if (IsCommonPage) {
              return true;
            } else {
              if (CheckSpecialRoleCB || CheckRoleCB) {
                const RolesToCheck = GetRolesWithParamCheck(path, RolesArr);
                // console.log({ path, RolesToCheck });
                return RoleChecker(
                  RolesToCheck,
                  gbsRolesByPerson,
                  gbsRolesByContract
                );
              }
              return false;
            }
          }
        });
      } else {
        return arr.filter((list) => {
          const path = _.get(list, field, null);
          const RoutesToCheck = GetRoutes(path, Routes);
          // console.log({ path, RoutesToCheck });
          if (RoutesToCheck) {
            const { CheckRoleCB, IsCommonPage } = RoutesToCheck;
            if (IsCommonPage) {
              return true;
            } else {
              if (CheckRoleCB) {
                const RolesToCheck = GetRoles(path, RolesArr);
                // console.log({ path, RolesToCheck });
                return RoleChecker(
                  RolesToCheck,
                  gbsRolesByPerson,
                  gbsRolesByContract
                );
              }
              return true;
            }
          }
        });
      }
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
};

/**
 * @function: RoleChecker
 * @description: Main Logic Function that manipulates the Access based on the Roles. Returns Boolean
 * @author: Nivethithan Kannan
 */
export const RoleChecker = (
  RolesToCheck = {},
  gbsRolesByPerson = [],
  gbsRolesByContract = [],
  user = "grower"
) => {
  try {
    if (IsObjectChecker(RolesToCheck)) {
      const {
        RoutePBRoles = [],
        RouteCBRoles = [],
        CheckBasedOn = "",
      } = RolesToCheck;
      // console.log({ RolesToCheck, RouteCBRoles, RoutePBRoles });
      const userTier = lowStr(user);
      switch (userTier) {
        case "grower":
          switch (CheckBasedOn) {
            case "CB":
              if (IsArrayNotEmpty(gbsRolesByContract)) {
                return CheckArrayRoleExist(RouteCBRoles, gbsRolesByContract);
              } else {
                return false;
              }
            case "PB":
              if (IsArrayNotEmpty(gbsRolesByPerson)) {
                return CheckArrayRoleExist(RoutePBRoles, gbsRolesByPerson);
              } else {
                return false;
              }
            case "BOTH":
              const rolesByPersonSatisfied = IsArrayNotEmpty(gbsRolesByPerson)
                ? CheckArrayRoleExist(RoutePBRoles, gbsRolesByPerson)
                : false;
              const rolesByContractSatisfied = IsArrayNotEmpty(
                gbsRolesByContract
              )
                ? CheckArrayRoleExist(RouteCBRoles, gbsRolesByContract)
                : false;
              return rolesByContractSatisfied && rolesByPersonSatisfied;
            default:
              const PBSatisfied = IsArrayNotEmpty(gbsRolesByPerson)
                ? CheckArrayRoleExist(RoutePBRoles, gbsRolesByPerson)
                : false;
              const CBSatisfied = IsArrayNotEmpty(gbsRolesByContract)
                ? CheckArrayRoleExist(RouteCBRoles, gbsRolesByContract)
                : false;
              return CBSatisfied || PBSatisfied;
          }
        default:
          return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};
// Role Logic Main Functions End

const PermissionWrapper = (props) => {
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  // console.log({ new_accounts: accounts });
  const {
    child,
    CheckRoleCB,
    CheckSpecialRoleCB,
    CommonPageCBRoles = [],
    ...rest
  } = props;
  const childProps = { ...rest };
  childProps.CommonPageCBRoles = IsArrayNotEmpty(CommonPageCBRoles)
    ? CommonPageCBRoles
    : [];

  const pathName = _.get(rest, "location.pathname", null);
  const matchPathName = _.get(rest, "match.path", null);
  // const matchURLName = _.get(rest, "match.url", null);
  // const path = matchURLName === pathName ? pathName : matchURLName;
  const path = matchPathName === pathName ? pathName : matchPathName;
  // console.log({pathName, matchPathName, matchURLName, path, rest});

  // const match = matchPath("/documents/archives/annual-reports", {
  //   path: "/documents/archives/:id",
  // });
  // console.log({ match });

  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);
  // console.log({
  //   path,
  //   gbsRolesByPerson,
  //   gbsRolesByContract,
  // });
  const gbsRegions = _.get(accounts, `0.idTokenClaims.gbsRegions`, []);
  childProps.gbsRegions = gbsRegions;
  childProps.rolePBEmployee =
    !IsArrayNotEmpty(gbsRolesByContract) && !IsArrayNotEmpty(gbsRolesByPerson);

  const RoleCBPrivileges = PlainObjectComponentDecider(
    { ...ContractBasedRoles },
    gbsRolesByContract
  );
  childProps.RoleCBPrivileges = RoleCBPrivileges;
  const RolePBPrivileges = PlainObjectComponentDecider(
    { ...PersonBasedRoles },
    gbsRolesByPerson
  );
  childProps.RolePBPrivileges = RolePBPrivileges;
  const RolesByPriority = RolePriorityDeciderForFarmService(RoleCBPrivileges);
  childProps.RolesByPriority = RolesByPriority;
  const SpecialRoleExist = DoRoleExist(
    roleCBThirdPartyFarmService,
    RolesByPriority
  );
  childProps.SpecialRoleExist = SpecialRoleExist;
  childProps.CheckRoleCB = CheckRoleCB;
  childProps.CheckSpecialRoleCB = CheckSpecialRoleCB;

  const RoleAccessDefinitions = SpecialRoleExist
    ? SpecialRolesDefinition[roleCBThirdPartyFarmService]
    : Roles;

  childProps.RoleAccessDefinitions = IsArrayNotEmpty(RoleAccessDefinitions)
    ? RoleAccessDefinitions
    : null;

  const RolesToCheck = GetRoles(path, RoleAccessDefinitions);
  childProps.RolesToCheck = RolesToCheck || {};
  const DropDownCBRoles = _.get(RolesToCheck, "DropDownCBRoles", []);
  childProps.DropDownCBRoles = DropDownCBRoles;

  // console.log({ RoleCBPrivileges, RolePBPrivileges, childProps });
  if (SpecialRoleExist) {
    if (CheckRoleCB || CheckSpecialRoleCB) {
      const access = RoleChecker(
        RolesToCheck,
        gbsRolesByPerson,
        gbsRolesByContract
      );
      // console.log({ access });
      if (access) {
        return <>{child(childProps)}</>;
      } else {
        return <Redirect to={"/my-farm/my-acreage"} />;
      }
    } else {
      return <>{child(childProps)}</>;
    }
  } else {
    if (CheckRoleCB) {
      const access = RoleChecker(
        RolesToCheck,
        gbsRolesByPerson,
        gbsRolesByContract
      );
      // console.log({ access });
      if (access) {
        return <>{child(childProps)}</>;
      } else {
        return <Redirect to={"/"} />;
      }
    } else {
      return <>{child(childProps)}</>;
    }
  }
};

export default PermissionWrapper;

// Role Logic - FarmService Role Functions Start
/**
 * @function: RolePriorityDeciderForFarmService
 * @description: Returns Roles Arr based on the priority of Contract Based roles
 * @author: Nivethithan Kannan
 */
export function RolePriorityDeciderForFarmService(role = {}) {
  try {
    const rolesArr = Object.values(role);
    if (IsArrayNotEmpty(rolesArr)) {
      if (rolesArr.every((li) => li)) {
        const new_obj = { ...ContractBasedRoles };
        delete new_obj.roleCBThirdPartyFarmService;
        return Object.values(new_obj);
      } else if (rolesArr.every((li) => !li)) {
        return [];
      } else {
        if (role?.roleCBThirdPartyFarmService) {
          const new_obj = { ...role };
          delete new_obj.roleCBThirdPartyFarmService;
          const keys = Object.values(new_obj);
          if (keys.every((li) => !li)) {
            return [ContractBasedRoles.roleCBThirdPartyFarmService];
          } else {
            const return_arr = [];
            for (const key in new_obj) {
              if (Object.hasOwnProperty.call(new_obj, key)) {
                if (new_obj[key]) {
                  return_arr.push(ContractBasedRoles[key]);
                }
              }
            }
            return return_arr;
          }
        } else {
          const new_obj = { ...role };
          delete new_obj.roleCBThirdPartyFarmService;
          const return_arr = [];
          for (const key in new_obj) {
            if (Object.hasOwnProperty.call(new_obj, key)) {
              if (new_obj[key]) {
                return_arr.push(ContractBasedRoles[key]);
              }
            }
          }
          return return_arr;
        }
      }
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
}

/**
 * @function: RoleCheckerForDocumentLinkArr
 * @description: Returns array of document links based on role access - For Quick Links - Its a wrapper: logic needs to be placed in future based on requirements
 * @author: Nivethithan Kannan
 */
export function RoleCheckerForDocumentLinkArr(ArrayToCheck) {
  try {
    if (IsArrayNotEmpty(ArrayToCheck)) {
      return ArrayToCheck;
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
}
// Role Logic - FarmService Role Functions End

// Role Logic - User Impersonation - Start
/** 
@CreatedBy : Nivethithan Kannan
@Function : DetermineImpersonation
@Description : Method that returns true if the user is impersonated
*/
export function DetermineImpersonation() {
  try {
    let impersonate_item;
    if(getCookie("selected_one") === 'true')
      impersonate_item = localStorage.getItem("selected_one");
    else
      localStorage.removeItem("selected_one");
    if (IsStringChecker(impersonate_item)) {
      const impersonate_item_json = JSON.parse(impersonate_item);
      if (impersonate_item_json) {
        const { rolePBAllowImpersonation, ImpersonationId } =
          impersonate_item_json;
        if (rolePBAllowImpersonation) {
          return IsStringChecker(ImpersonationId);
        }
        return false;
      }
      return false;
    }
    return false;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

/** 
@CreatedBy : Nivethithan Kannan
@Function : GetImpersonateDetails
@Description : Method that returns data of the user who is going to get impersonated based on Impersonation Access
*/
export function GetImpersonateDetails() {
  try {
    let impersonate_item;
    if(getCookie("selected_one") === 'true')
      impersonate_item = localStorage.getItem("selected_one");
    else
      localStorage.removeItem("selected_one");
    if (IsStringChecker(impersonate_item)) {
      const impersonate_item_json = JSON.parse(impersonate_item);
      if (impersonate_item_json) {
        const {
          rolePBAllowImpersonation,
          ImpersonationId,
          UserId,
          name,
          OriginalUser,
          idTokenClaims,
          username,
        } = impersonate_item_json;
        if (rolePBAllowImpersonation) {
          if (IsStringChecker(ImpersonationId)) {
            return [
              {
                OriginalUser,
                localAccountId: UserId,
                ImpersonationId,
                name,
                username,
                idTokenClaims,
              },
            ];
          }
          return [];
        }
        return [];
      }
      return [];
    }
    return [];
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
}

/** 
@CreatedBy : Nivethithan Kannan
@Function : GetOriginalUserName
@Description : Method that returns name of the original user from the accounts obj
*/
export function GetOriginalUserName(accounts) {
  try {
    if (accounts) {
      return _.get(accounts, "0.OriginalUser.name", "");
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

/** 
@CreatedBy : Nivethithan Kannan
@Function : GetOriginalUserEmail
@Description : Method that returns email of the original user from the accounts obj
*/
export function GetOriginalUserEmail(accounts) {
  try {
    if (accounts) {
      return _.get(accounts, "0.OriginalUser.email", "");
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}

/** 
@CreatedBy : Nivethithan Kannan
@Function : ImpersonationAccountsSwap
@Description : Method that returns accounts object of MSAL based on Impersonation Access
*/
export function ImpersonationAccountsSwap(msal_accounts) {
  try {
    const impersonate_details = GetImpersonateDetails();
    // console.log({ msal_accounts, impersonate_details });
    const accounts = IsArrayNotEmpty(impersonate_details)
      ? impersonate_details
      : msal_accounts;
    return accounts;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return msal_accounts;
  }
}

/** 
@CreatedBy : Nivethithan Kannan
@Function : LocalAccountIDSwapImpersonate
@Description : Method that returns localAccountId value of MSAL based on Impersonation Access
*/
export function LocalAccountIDSwapImpersonate(accounts) {
  try {
    const impersonate_item = _.get(accounts, "0.ImpersonationId", "");
    const original_item = _.get(accounts, "0.localAccountId", "");
    if (IsStringChecker(impersonate_item)) {
      return impersonate_item;
    }
    return original_item;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
}
// Role Logic - User Impersonation - End
