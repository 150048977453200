import React from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import DetailPageCtaContentBlock from "../../global/detailPageCtaContentBlock";

const CranberryManagementTopicDetails = (props) => {
  const params = useParams();
  const topic = _.get(params, "topic", "");
  const id = _.get(params, "Id", "");
  return (
    <>
      <DetailPageCtaContentBlock slugId={id} slug={'ag-sciences'} pagetitleDisplay={false} breadcrumbtitle={topic} isLeft={true} quickLinks={true} {...props} />
    </>
  );
};

export default CranberryManagementTopicDetails;
