import React from "react";
import { Modal } from "react-bootstrap";
import {
  getResolvedLanguage,
  IsFunctionChecker,
  IsStringChecker,
} from "../../../utils/helper";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../global/currentLocation";
import parse from "html-react-parser";

const OSAcademyVideoModal = (props) => {
  const {
    show = false,
    closeModal = null,
    content = null,
    fullscreen = true,
    modalClassName = "",
  } = props;

  // console.log({ show, content });

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const closeBtn = `${_.get(
    moreLangs,
    `${resolvedLanguage}.os_academy.closeBtn`,
    `Close`
  )}`;

  const onHide = () => {
    if (IsFunctionChecker(closeModal)) {
      closeModal();
    }
  };

  return IsStringChecker(content) ? (
    <Modal
      show={show}
      fullscreen={fullscreen}
      onHide={onHide}
      className={modalClassName}
      id={"RecentDeliveryModal1"}
      tabIndex={"-1"}
      aria-labelledby={"RecentDeliveryModal1"}
      aria-hidden={"true"}
    >
      <Modal.Header
        className={"border-0 p-3 pb-0 d-inline-block text-center vlight_bg"}
      >
        <button
          type="button"
          className="btn btn-primary btn-sm text-14"
          id="closeBtn"
          aria-label="Close"
          onClick={onHide}
        >
          <i className="fa fa-close pe-1"></i>
          {closeBtn}
        </button>
      </Modal.Header>
      <Modal.Body className={"vlight_bg modalBodyImage"}>
        <div
          style={{
            maxWidth: "960px",
            position: "relative",
            margin: "0 auto",
          }}
          id="hide_video"
        >
          {parse(content)}
        </div>
      </Modal.Body>
    </Modal>
  ) : (
    <></>
  );
};

export default OSAcademyVideoModal;
