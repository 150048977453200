import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion } from "react-bootstrap";
import MultiDropDown from "../../../selectBox/multiDropDown";
import DataGridPagination from "../../../global/dataGridPagination";
import CurrentLocation from "../../../global/currentLocation";
import {
  IsTokenChecker,
  IsArrayNotEmpty,
  IsStringChecker,
  getResolvedLanguage,
  StringToInteger,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  DateAsField,
} from "../../../../utils/helper";
import Loading from "../../../global/loading";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import { GbsBaseUrl } from "../../../../utils/constants";
import { useGbsDataCache } from "../../../../services/gbsDataCache";
import { ImpersonationAccountsSwap } from "../../../../permissionWrapper";

const PoolEarnings = ({
  cropYears = [],
  contracts = [],
  selectedCropYear = null,
  selectedContract = null,
}) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const dispatch = useDispatch();

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);

  const [loading, setLoading] = useState(false);
  const [circleLoader, setCircleLoader] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);

  const [currentCropYear, setCurrentCropYear] = useState([]);
  const [defaultContract, setDefaultContract] = useState([]);

  const [reqCropyear, setReqCropYear] = useState({});
  const [reqContract, setReqContract] = useState({});

  const [clrBtnDisabled, setClrBtnDisabled] = useState(true);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  const [tableList, setTableList] = useState([]);
  const [nameKeeper, setNameKeeper] = useState(null);

  const [freshPage, setFreshPage] = useState(true);

  const [isMobFilter, setisMobFilter] = useState(false);
  const MobileViewRenderer = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  useEffect(() => {
    if (data.data) {
      fetchAllTableData(data);
    }
    if (status === "error") {
      setTableList([]);
      setNameKeeper(null);
      // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    }
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
  }, [data, changeEvent, status]);
  //Cache Implementation End

  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  useEffect(() => {
    if (selectedCropYear && selectedContract) {
      setReqCropYear({ cropyear: `${selectedCropYear}` });
      setReqContract({ contract: `${selectedContract}` });
      setCurrentCropYear([selectedCropYear]);
      setDefaultContract([selectedContract]);
      setClrBtnDisabled(false);
      setSubmitBtnDisabled(false);
    } else {
      if (selectedCropYear) {
        setReqCropYear({ cropyear: `${selectedCropYear}` });
        setReqContract({});
        setCurrentCropYear([selectedCropYear]);
        setDefaultContract([]);
        setClrBtnDisabled(false);
      } else if (selectedContract) {
        setReqCropYear({});
        setReqContract({ contract: `${selectedContract}` });
        setCurrentCropYear([]);
        setDefaultContract([selectedContract]);
        setClrBtnDisabled(false);
      } else {
        setReqContract({});
        setReqCropYear({});
        setCurrentCropYear([]);
        setDefaultContract([]);
        setClrBtnDisabled(true);
      }
    }
    MultiDropDownFix();
  }, [selectedCropYear, selectedContract]);

  const setObjArrToString = (val) => {
    try {
      if (IsArrayNotEmpty(val)) {
        let ids = val.map((x) => x.id);
        ids = ids.filter((x) => x != "all");
        return `${ids}`;
      } else {
        return ``;
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return ``;
    }
  };

  const RegulateFileURL = (val) => {
    try {
      if (IsStringChecker(val)) {
        if (val.includes("https://")) {
          return val;
        } else {
          return `${GbsBaseUrl}${val}`;
        }
      } else {
        return val;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return val;
    }
  };

  const MultiDropDownFix = () => {
    setClearStatus(true);
    setTimeout(() => {
      setClearStatus(false);
    }, 0);
  };

  const FetchButtonHandler = (cropyear = false, contract = false) => {
    // console.log({ cropyear, contract });
    if (cropyear || contract) {
      const access = IsTokenChecker(cropyear, contract);
      if (access) {
        setSubmitBtnDisabled(false);
      } else {
        setSubmitBtnDisabled(true);
      }
      setClrBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
      setClrBtnDisabled(true);
    }
    ClearTableData();
  };

  const onMultiSelectChange = (selectedList, selectedItem, type) => {
    const list = setObjArrToString(selectedList);
    if (type === "cropyear") {
      let contract = _.get(reqContract, "contract", "");
      FetchButtonHandler(list, contract);
    } else if (type === "contract") {
      let cropyear = _.get(reqCropyear, "cropyear", "");
      FetchButtonHandler(cropyear, list);
    }
  };

  const onMultiSelectRemove = (removedList, removedItem, type) => {
    const removed = _.get(removedItem, "id", null);
    const list = setObjArrToString(removedList);
    if (type === "cropyear") {
      let contract = _.get(reqContract, "contract", "");
      if (removed === "all" || !IsArrayNotEmpty(removedList)) {
        FetchButtonHandler(false, contract);
      } else {
        FetchButtonHandler(list, contract);
      }
    } else if (type === "contract") {
      let cropyear = _.get(reqCropyear, "cropyear", "");
      if (removed === "all" || !IsArrayNotEmpty(removedList)) {
        FetchButtonHandler(cropyear, false);
      } else {
        FetchButtonHandler(cropyear, list);
      }
    }
  };

  const fetchTableData = async () => {
    let contract = _.get(reqContract, "contract", null);
    let cropyear = _.get(reqCropyear, "cropyear", null);

    if (IsTokenChecker(tokenId, cropyear, contract)) {
      setLoading(true);
      setCircleLoader(true);
      const reqURL = `pool-earnings-statements?contractid=${_.orderBy(
        contract.split(","),
        [],
        ["asc"]
      ).toString()}&cropyear=${_.orderBy(
        cropyear.split(","),
        [],
        ["asc"]
      ).toString()}`;
      setSearchURL(reqURL);
      reqURL === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const GetUniqueData = (arr = []) => {
    try {
      if (IsArrayNotEmpty(arr)) {
        const orderedArr = _.orderBy(
          arr,
          (obj) => DateAsField(obj, "PoolEarningsDate"),
          ["desc"]
        );
        // console.log({ orderedArr });
        const unique_array = orderedArr.filter((item, index, original) => {
          let foundIndex = original.findIndex((list) => {
            let aside =
              _.get(list, "RelationshipNumber", "a") ===
              _.get(item, "RelationshipNumber", "b");
            let bside =
              _.get(list, "CropYear", "a") === _.get(item, "CropYear", "b");
            return aside && bside;
          });
          return foundIndex === index;
        });
        // console.log({ unique_array });
        return unique_array;
      }
      return [];
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };

  const fetchAllTableData = (res) => {
    var final_array = safeArrayCheck(_.get(res, "data", []));
    var unique_array = GetUniqueData(final_array);
    var resArr = IsArrayNotEmpty(unique_array)
      ? unique_array.map((item, index) => {
          const {
            Name: ContractName = null,
            RelationshipId: Id = null,
            FileUrl = null,
            CropYear = null,
          } = item;
          const Contract = _.get(item, "RelationshipNumber", "-");
          return {
            Index: index,
            ContractName: IsStringChecker(ContractName) ? ContractName : "-",
            Contract: IsStringChecker(Contract) ? Contract : "-",
            Id: StringToInteger(Id) || "-",
            FileUrl: IsStringChecker(FileUrl) ? RegulateFileURL(FileUrl) : null,
            FileName: "Pool Earnings",
            CropYear: StringToInteger(CropYear) || "-",
          };
        })
      : [];

    // console.log("resArr => ", resArr);
    const result_Arr = IsArrayNotEmpty(resArr)
      ? _.orderBy(resArr, ["Contract", "CropYear"], ["asc", "desc"])
      : [];
    setTableList(result_Arr);
    setNameKeeper("Pool Earnings Statement");
    setLoading(false);
    setCircleLoader(false);
    MobileViewRenderer("cancel");
    setFreshPage(false);
  };

  const onClearSearch = () => {
    setSubmitBtnDisabled(true);
    setClrBtnDisabled(true);

    setReqContract({});
    setReqCropYear({});

    setCurrentCropYear([]);
    setDefaultContract([]);

    ClearTableData();

    MultiDropDownFix();
  };

  const ClearTableData = () => {
    setNameKeeper(null);
    setTableList([]);
    setFreshPage(true);
  };

  // console.log({
  //   reqContract,
  //   reqCropyear,
  //   currentCropYear,
  //   defaultContract,
  //   tableList,
  //   nameKeeper,
  // });

  const columns = [
    {
      key: "Contract",
      name: "Contract #",
      width: "20%",
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: "35%",
    },
    {
      key: "CropYear",
      name: "Crop Year",
      width: "20%",
      formatter(props) {
        let temp = _.get(props, "row.CropYear", "-");
        return temp || "-";
      },
    },
    {
      key: "FileName",
      name: "Download PDF",
      width: "25%",
      formatter(props) {
        let temp1 = _.get(props, "row.FileUrl", null),
          temp2 = _.get(props, "row.FileName", "Pool Earnings");
        return (
          <>
            {IsStringChecker(temp1) ? (
              <span>
                <a
                  href={temp1}
                  target={"_blank"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Export"
                  rel="noreferrer"
                >
                  <i className="fa fa-file-o" alt="ExcelIcon"></i>&ensp;
                  {temp2}
                </a>
              </span>
            ) : (
              "-"
            )}
          </>
        );
      },
      sortable: false,
      headerCellClass: "fw-normal",
    },
  ];
  const noData = IsStringChecker(nameKeeper) && !IsArrayNotEmpty(tableList);
  const noDataMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.statement_msg`,
    "No Statement Found"
  )}`;
  const poolEarningsMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.statements_reports_page.pool_earnings_msg`,
    "Pool Earnings statements are processed after all pool payments and equity issuances are complete. This will typically be four to six weeks after the ARE issuance. If you question whether a statement is available for a crop year, please contact the Grower Finance department."
  )}`;
  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  return (
    <>
      {(loading || status === "fetching") && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="card-body mob-space-7 ver-space-10 align-flex border-1 border-radius-6 white_bg mb-3">
        <span className="d-contents ver-space-10">
          <i className="fa fa-info-circle margin-right-5 d-none d-lg-inline-block"></i>
          <i className="fa fa-info margin-right-5 d-lg-inline-block d-lg-none info_icon_mobile"></i>
          <span className="mob_blue_text">{poolEarningsMsg}</span>
        </span>
      </div>
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => MobileViewRenderer("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => MobileViewRenderer("filter")}
        >
          <span className="fw-bold px-2">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="contract_number_Label custom_dropdown white_bg">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={contracts}
                    prompt="Select"
                    value={reqContract}
                    onChange={(selectedList, selectedItem) =>
                      onMultiSelectChange(
                        selectedList,
                        selectedItem,
                        "contract"
                      )
                    }
                    onDelete={(removedList, removedItem) =>
                      onMultiSelectRemove(removedList, removedItem, "contract")
                    }
                    label="contract"
                    usestate={setReqContract}
                    disabled=""
                    preSelectedOpt={defaultContract}
                    optionNotFound={{ contract: noContractsFound }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="crop_year_label custom_dropdown white_bg">
                {!clearstatus && (
                  <MultiDropDown
                    optionList={cropYears}
                    prompt="Select"
                    value={reqCropyear}
                    onChange={(selectedList, selectedItem) =>
                      onMultiSelectChange(
                        selectedList,
                        selectedItem,
                        "cropyear"
                      )
                    }
                    onDelete={(removedList, removedItem) =>
                      onMultiSelectRemove(removedList, removedItem, "cropyear")
                    }
                    label="cropyear"
                    usestate={setReqCropYear}
                    preSelectedOpt={currentCropYear}
                    disabled=""
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`bottom-0  filter_apply_button white_bg ${
              isMobFilter ? "show d-block " : " d-lg-none "
            }`}
          >
            <button
              className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
              onClick={fetchTableData}
              disabled={submitBtnDisabled}
            >
              {`Apply `}
              {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
            </button>
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={fetchTableData}
                disabled={submitBtnDisabled}
              >
                {`Search `}
                {!circleLoader ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block clear-btn-grey"
                onClick={() => onClearSearch()}
                disabled={clrBtnDisabled}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </nav>
      {IsStringChecker(nameKeeper) && !displayAccordian && (
        <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
          <h3 className="pt-3 pb-1 pb-lg-4">
            <span className="modify_text text-capitalize">{nameKeeper}</span>
          </h3>
          {IsArrayNotEmpty(tableList) && (
            <DataGridPagination
              uniqueIdentifier="Index"
              gridData={tableList}
              columns={columns}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
              dynamicHeight={45}
            />
          )}
          {noData && (
            <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
              <i className="fa fa-info-circle pe-2"></i>
              {noDataMsg}
            </div>
          )}
        </div>
      )}
      <div
        className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
      >
        <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
          <Accordion
            defaultActiveKey="0"
            className="table mobile-accordion accordion_space"
          >
            {IsArrayNotEmpty(tableList) ? (
              tableList.map((data, index) => {
                const {
                  FileName = "Pool Earnings",
                  FileUrl = null,
                  Contract = "-",
                  ContractName = "-",
                  CropYear = "-",
                } = data;
                return (
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header>
                      {`Contract #: ${Contract}`}
                      <br />
                      {`Crop Year: ${CropYear}`}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tbody">
                        <div className="tr">
                          <div className="td"></div>
                          {IsStringChecker(FileUrl) && (
                            <div className="td" data-header="Download PDF:">
                              <span className="sm-word-break">
                                <a
                                  href={FileUrl}
                                  target="_blank"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Export"
                                  rel="noreferrer"
                                >
                                  <i
                                    className="fa fa-file-o"
                                    alt="ExcelIcon"
                                  ></i>
                                  &ensp;
                                  {FileName}
                                </a>
                              </span>
                            </div>
                          )}
                          <div className="td" data-header="Contract #:">
                            <span>{Contract}</span>
                          </div>
                          <div className="td" data-header="Contract Name:">
                            <span>{ContractName}</span>
                          </div>
                          <div className="td" data-header="Crop Year:">
                            <span>{CropYear}</span>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })
            ) : freshPage ? (
              <></>
            ) : (
              <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                <i className="fa fa-info-circle pe-2"></i>
                {noDataMsg}
              </div>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default PoolEarnings;
