/**
 * @component_Name : Archive
 * @description : Archive
 * @company : Photon
 * @author : Ashish Ranjan/03-11-2022
 **/
import React from 'react'
import PageLayout from "../../pageLayout";
import ArchiveDetails from "./archiveDetails";
import { useParams } from "react-router-dom";

const Archive = (props) => {
  const { categorySlug } = useParams();
  return (
    <>
      <PageLayout {...props} body={<ArchiveDetails {...props} paramCategory={categorySlug} />}/>
    </>
  );
}

export default Archive