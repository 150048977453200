import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { ConstantsVar } from "../../utils/constants";
import moment from "moment";
const DataMobileGridList = ({ listingData }) => {
  const IconColorDeterminer = (operand1 = 0, operand2 = 0) => {
    if (operand1 > 0 && operand2 > 0) {
      return {
        icon1: "fa-check-circle light_green_color",
        icon2: "fa-check-circle light_green_color",
      };
    } else if (operand1 > 0 || operand2 > 0) {
      if (operand1 > 0) {
        return {
          icon1: "fa-check-circle light_grey_color",
          icon2: "fa-times-circle red_color",
        };
      } else {
        return {
          icon1: "fa-times-circle red_color",
          icon2: "fa-check-circle light_grey_color",
        };
      }
    } else {
      return {
        icon1: "fa-times-circle red_color",
        icon2: "fa-times-circle red_color",
      };
    }
  };
  return (
    <>
      <Accordion defaultActiveKey="0">
        {listingData.length > 0 &&
          Object.values(listingData).map((data, index) => {
            const operand1 = data.PspAcceptance,
              operand2 = data.PhiApproved;
            const { icon1, icon2 } = IconColorDeterminer(operand1, operand2);
            return (
              <Fragment key={data.Id}>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <span
                      className={`${
                        operand1 > 0 && operand2 > 0
                          ? "green_dark_color"
                          : "red_dark_color"
                      }`}
                    >
                      {data.Contract_Number}, {data.Harvest_Bed}, PSP:{" "}
                      <i className={`fa ${icon1} text-20`}></i>, PHI:{" "}
                      <i className={`fa ${icon2} text-20`}></i>{" "}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tbody">
                      <div className="tr">
                        <div className="td" data-header="Contract Number:">
                          {data.Contract_Number}
                        </div>
                        <div
                          className="td acreage_table"
                          data-header="Contract Name:"
                        >
                          <span>{data.Contract_Name}</span>
                        </div>

                        <div
                          className="td acreage_table mob_break_word"
                          data-header="Bog Name:"
                        >
                          <span>{data.Bog_Name}</span>
                        </div>
                        <div className="td" data-header="Harvest Bed:">
                          {" "}
                          {data.Harvest_Bed}
                        </div>
                        <div className="td" data-header="Variety:">
                          {data.Variety}
                        </div>
                        <div className="td" data-header="Acres:">
                          {data.Acres}
                        </div>
                        <div className="td" data-header="EU/NonEU:">
                          {/* <span className="border-1 blue_color border-1-blue non-eu-label"> */}
                          {data["EU/NonEU"]}
                          {/* </span> */}
                        </div>
                        <div className="td" data-header="PSP Status:">
                          <i className={`fa ${icon1} text-20`}></i>
                        </div>
                        <div className="td" data-header="PHI Status:">
                          <i className={`fa ${icon2} text-20`}></i>
                        </div>
                        <div className="td" data-header="PHI Date:">
                          {data?.PHI_Date
                            ? moment
                                .utc(data.PHI_Date)
                                .format(ConstantsVar.dateFormate)
                            : ""}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Fragment>
            );
          })}
      </Accordion>
    </>
  );
};

export default DataMobileGridList;
