import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../global/breadCrumb";
import LeftMenu from "../../../global/leftMenu";
import QuickLinks from "../../quickLinks";
// import { Link } from "react-router-dom";
import Events from "../../../home/siteNotification";
import { useHistory } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { MyRecentPayments } from "./myRecentPayments";
import { PaymentSearch } from "./paymentSearch";
import { CashAssignments } from "./cashAssignments";
import { Deferments } from "./deferments";
import { DirectDeposit } from "./directDeposit";

const Body = (props) => {
  const location = useHistory().location;
  const arrayLocation = location.hash;
  const [selectedTab, setSeletedTab] = useState([]);
  const [showDirectDeposit, setDirectDeposit] = useState(true);
  const handlerAccordion = (e) => {
    //e.stopPropagation
    if (e !== null) {
      // if e === null, that means that an accordion item was collapsed rather than expanded. e will be non-null when an item is expanded
      localStorage.setItem("expandedItem", e);
      setSeletedTab(e);
    } else {
      localStorage.setItem("expandedItem", [5]);
    }
    if (e.filter((x) => x === "cashAssignments").length > 0) {
      setDirectDeposit(false);
    } else {
      setDirectDeposit(true);
    }
  };

  let retainAccordion = "";
  if (arrayLocation != "" || localStorage.expandedItem != "") {
    if (arrayLocation != "") {
      retainAccordion = arrayLocation.replace(/#/g, "");
    } else {
      retainAccordion = localStorage.expandedItem
        ? localStorage.expandedItem.split(",")
        : "5";
    }
  }

  let retainAccordionForSet = "";
  useEffect(() => {
    if (arrayLocation != "" || localStorage.expandedItem != "") {
      if (arrayLocation != "") {
        retainAccordionForSet = arrayLocation.replace(/#/g, "");
        setSeletedTab([retainAccordionForSet]);
      } else {
        retainAccordionForSet = localStorage.expandedItem
          ? localStorage.expandedItem.split(",")
          : ["5"];
        setSeletedTab(retainAccordionForSet);
      }
      if (
        retainAccordionForSet.filter((x) => x === "cashAssignments").length > 0
      ) {
        setDirectDeposit(false);
      } else {
        setDirectDeposit(true);
      }
    }
  }, []);
  return (
    <>
      <div className="row">
        {/*Add breadCrumb*/}
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-8 d-none d-lg-block m_l_0_print">
            <Breadcrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2">
            <LeftMenu {...props} slug="my-payments-and-equity" />

            {/* <!-- Quick Links starts --> */}
            <div className=" bot-mspace-20 Quick_link ">
              <QuickLinks {...props} slug={"payments"} />
            </div>
            {/* <!-- Quick Links ends --> */}
          </div>

          <div className="col-12 col-lg-10">
            {/* <!-- Accordion blocks starts --> */}
            <div className="mt-5 mb-3 mt-lg-0 mb-lg-4">
              <Events type={"payments"} {...props} />
            </div>
            <Accordion
              className="mt-0"
              defaultActiveKey={retainAccordion}
              alwaysOpen
              id="accordionPayments"
              onSelect={handlerAccordion}
            >
              <Accordion.Item eventKey="lastPayment">
                <Accordion.Header>
                  <img
                    src="/assets/images/sack-dollar-solid.svg"
                    className="width-28 payment_icon right-space-5"
                    alt="Contracts_icon"
                  />{" "}
                  My Recent Payments
                </Accordion.Header>

                <Accordion.Body>
                  {/*My recent payment*/}
                  {selectedTab &&
                    selectedTab.filter((x) => x === "lastPayment").length >
                      0 && <MyRecentPayments {...props} />}
                  {/*End My recent payment*/}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="paymentSearch">
                <Accordion.Header>
                  <img
                    src="/assets/images/icon_payment_search.png"
                    className="width-33 right-space-10"
                    alt="Contracts_icon"
                  />{" "}
                  Payment Search
                </Accordion.Header>

                <Accordion.Body>
                  {/*Payment Search*/}
                  {selectedTab &&
                    selectedTab.filter((x) => x === "paymentSearch").length >
                      0 && <PaymentSearch {...props} />}
                  {/*End DeliveryApproval*/}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="cashAssignments">
                <Accordion.Header>
                  <img
                    src="/assets/images/money-bill-transfer-solid.svg"
                    className="width-33 right-space-10"
                    alt="Contracts_icon"
                  />{" "}
                  Cash Assignments
                </Accordion.Header>

                <Accordion.Body>
                  {/*DeliveryApproval*/}
                  {selectedTab &&
                    selectedTab.filter((x) => x === "cashAssignments").length >
                      0 && <CashAssignments {...props} />}
                  {/*End DeliveryApproval*/}
                </Accordion.Body>
              </Accordion.Item>

              {showDirectDeposit && (
                <Accordion.Item eventKey="directDeposit">
                  <Accordion.Header>
                    <img
                      src="/assets/images/icon_hand.png"
                      className="width-35 right-space-10"
                      alt="Contracts_icon"
                    />{" "}
                    Direct Deposit
                  </Accordion.Header>

                  <Accordion.Body>
                    {/*DeliveryApproval*/}
                    {selectedTab &&
                      selectedTab.filter((x) => x === "directDeposit").length >
                        0 && <DirectDeposit {...props} />}
                    {/*End DeliveryApproval*/}
                  </Accordion.Body>
                </Accordion.Item>
              )}
              <Accordion.Item eventKey="deferments">
                <Accordion.Header>
                  <img
                    src="/assets/images/icon_dollar.png"
                    className="width-35 right-space-10"
                    alt="Contracts_icon"
                  />{" "}
                  Deferments
                </Accordion.Header>

                <Accordion.Body>
                  {/*DeliveryApproval*/}
                  {selectedTab &&
                    selectedTab.filter((x) => x === "deferments").length >
                      0 && <Deferments {...props} />}
                  {/*End DeliveryApproval*/}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
