import React, { Fragment } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../../../global/loading";
import CurrentLocation from "../../../global/currentLocation";
import DataGridPagination from "../../../global/dataGridPagination";
import {
  IsTokenChecker,
  getResolvedLanguage,
  IsArrayNotEmpty,
  IsStringChecker,
  IsNumberChecker,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  toDecimal,
} from "../../../../utils/helper";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import { ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../../../permissionWrapper";
import Moment from "react-moment";
import { ConstantsVar } from "../../../../utils/constants";

const StockPledge = (props) => {
  const [loading, setLoading] = useState(false);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);

  const { DropDownCBRoles = [] } = props;

  const [stockPledge, setStockPledge] = useState([]);

  const columns = [
    {
      key: "Contract",
      name: "Contract Number",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "ContractName",
      name: "Contract Name",
      width: 250,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "PledgeName",
      name: "Pledge Name",
      width: 245,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "PledgeType",
      name: "Pledge Type",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "Shares",
      name: "Initial Shares",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading",
    },
    {
      key: "EffectiveDate",
      name: "Effective Date",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading",
    },
  ];

  const safeStringRenderer = (str) => {
    try {
      if (IsStringChecker(str)) {
        return str;
      } else {
        return "-";
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "-";
    }
  };

  const fecthStockPledge = async () => {
    const filteredContracts = ValidGBSRoleByContracts(accounts, DropDownCBRoles, true, false);
    const contractids = filteredContracts.join();
    if (IsStringChecker(contractids)) {
      setLoading(true);
      await gbsDataCommonMethod(
        tokenId,
        accounts,
        `/equity-stock-pledges?e.relationshipid=${contractids}`
      )
        .then((resp) => {
          var tempResponse = safeArrayCheck(_.get(resp, "data", []));
          const filterResponse = tempResponse.filter(
            (li) => li?.Status && li.Status !== 1003
          );
          let result_Arr = IsArrayNotEmpty(filterResponse)
            ? filterResponse.map((item, index) => {
                const {
                  Name: ContractName = null,
                  RelationshipNumber: Contract = null,
                  ContractStatusDesc: Status = null,
                  PledgeName = null,
                  PledgeType = null,
                  Shares = null,
                  EffectiveDate = null,
                } = item;
                return {
                  Index: index,
                  Contract: safeStringRenderer(Contract),
                  ContractName: ContractName,
                  PledgeName: safeStringRenderer(PledgeName),
                  Shares: IsNumberChecker(Shares)
                    ? Shares === 99999999
                      ? "All Shares"
                      : toDecimal(Shares)
                    : "-",
                  Status: safeStringRenderer(Status),
                  PledgeType: safeStringRenderer(PledgeType),
                  EffectiveDate: EffectiveDate ? (
                    <Moment utc format={ConstantsVar.dateFormate}>
                      {EffectiveDate}
                    </Moment>
                  ) : (
                    "-"
                  ),
                };
              })
            : [];
          const finalArray = IsArrayNotEmpty(result_Arr)
            ? _.orderBy(result_Arr, ["Contract"], "asc")
            : [];
          setStockPledge(finalArray);
        })
        .catch((error) => {
          setStockPledge([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fecthStockPledge();
      setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const noData = !IsArrayNotEmpty(stockPledge);
  const noDataMsg = `${_.get(
    moreLangs,
    `${resolvedLanguage}.stock_Pledge.no_results_found`,
    "No Active Pledge Agreement for this contract. Contact the Grower Finance department for more information"
  )}`;
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}

      {!displayAccordian && <div className="stock-pledge hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
        {IsArrayNotEmpty(stockPledge) && (
          <DataGridPagination
            uniqueIdentifier="Index"
            gridData={stockPledge}
            columns={columns}
            isPagination={true}
            itemsPerPage={10}
            columnResize={false}
            classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
            dynamicHeight={45}
          />
        )}
        {noData && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2 mb-4 d-none d-lg-block">
            <i className="fa fa-info-circle pe-2"></i>
            {noDataMsg}
          </div>
        )}
      </div>}
      <div
        className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
      >
        <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
          <Accordion
            defaultActiveKey="0"
            className="table mobile-accordion accordion_space"
          >
            {IsArrayNotEmpty(stockPledge) ? (
              stockPledge.map((data, index) => {
                const {
                  Contract = null,
                  ContractName = null,
                  Shares = null,
                  PledgeName = null,
                  PledgeType = null,
                  EffectiveDate = null,
                } = data;
                return (
                  <Fragment key={index}>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        Contract #: {Contract}
                        <br />
                        Pledge Name: {PledgeName}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="tbody">
                          <div className="tr">
                            <div className="td"></div>
                            <div className="td" data-header="Contract Number:">
                              <span>{Contract}</span>
                            </div>
                            <div className="td" data-header="Contract Name:">
                              <span>{ContractName}</span>
                            </div>
                            <div className="td" data-header="Pledge Name:">
                              <span>{PledgeName}</span>
                            </div>

                            <div className="td" data-header="Pledge Type:">
                              <span>{PledgeType}</span>
                            </div>

                            <div className="td" data-header="Initial Shares:">
                              <span>{Shares}</span>
                            </div>

                            <div className="td" data-header="Effective Date:">
                              <span>{EffectiveDate}</span>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Fragment>
                );
              })
            ) : (
              <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                <i className="fa fa-info-circle pe-2"></i>
                {noDataMsg}
              </div>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default StockPledge;
