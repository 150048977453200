/**
 * @component_Name : DeliveryData
 * @description : Show receipts, beds and summary tabs
 * @company : Photon
 * @author : Ashish Ranjan/22-07-2022
 **/

import React, { useState, useEffect } from "react";
import DDBeds from "./dDBeds";
import DDDailysumary from "./dDDailysumary";
import DDReceipts from "./dDReceipts";

import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  getCurrentYear,
  IsTokenChecker,
  convertFilterArray,
  // setOptions,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
} from "../../../utils/helper";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";

const DeliveryData = (props) => {
  const { DropDownCBRoles = [] } = props;

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const ongoingYear = getCurrentYear();

  const [selectedTab, setSelectedTab] = useState("receipts");

  const [ContractIds, setContractIds] = useState([]);
  const [currentCropYear, setCurrentCropYear] = useState(0);
  const [listCropYear, setListCropYear] = useState([]);
  const [minMaxYear, setMinMaxYear] = useState([]);
  const [ContractOption, setContractOption] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  useEffect(() => {
    IsTokenChecker(tokenId) && fetchCropYear();
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  useEffect(() => {
    if (IsTokenChecker(tokenId) && currentCropYear > 0) {
      const fetchContract = async () => {
        await gbsDataCommonMethod(
          tokenId,
          accounts,
          `persons/${personId}/contracts`
        )
          .then((resp) => {
            const data = [...resp.data];
            let filteredData = FilterContractByRole(
              data,
              accounts,
              DropDownCBRoles
            );
            filteredData = _.orderBy(
              filteredData,
              ["ContractStatus", "RelationshipNumber"],
              ["asc", "asc"]
            );
            const contractId = filteredData
              .map((x) => x["RelationshipId"])
              ?.toString();
            let url = `deliveries/by-bed?cropyear=${currentCropYear}&contractid=${contractId}&filtersonly=1`;
            setContractIds(contractId);
            const contractOption = IsArrayNotEmpty(filteredData)
              ? filteredData.map((li) => {
                  return {
                    id: li?.RelationshipId,
                    number: li?.RelationshipNumber,
                    name: `${li?.RelationshipNumber} ${li?.Name}`,
                  };
                })
              : [];
            setContractOption(contractOption);
            filterBedsData(url);
          })
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            setContractIds("");
            setContractOption([]);
          });
      };
      fetchContract();
    }
  }, [currentCropYear, tokenId]);

  const filterBedsData = async (url) => {
    await gbsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        let resultData = [...resp.data];
        let filteredData = FilterContractByRole(
          resultData,
          accounts,
          DropDownCBRoles
        );
        if (
          filteredData.length > 0 &&
          resp.data !== "Contract and CropYear must be provided"
        ) {
          setFilterItems(filteredData);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setFilterItems([]);
      });
  };

  const fetchCropYear = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then((resp) => {
        const currentcropData = resp.data.filter((x) => x.CurrentCrop === true);
        const getcurrentcrop = currentcropData
          ? currentcropData[0].CropYear
          : ongoingYear;
        setCurrentCropYear(getcurrentcrop);
        setMinMaxYear({ maxYear: getcurrentcrop, minYear: getcurrentcrop });
        const cropYear = resp.data.length > 0 && resp.data.splice(0, 15);
        const multiSelectCropYears = convertFilterArray(cropYear, {
          id: "CropYear",
          name: "CropYear",
        });
        setListCropYear(multiSelectCropYears);
      })
      .catch((error) => {
        setListCropYear([]);
        setMinMaxYear({ maxYear: ongoingYear, minYear: ongoingYear });
        setCurrentCropYear(ongoingYear);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const handlerTab = (params) => {
    setSelectedTab(params);
  };
  return (
    <>
      <div className="accordion-body mt-3 p-0">
        {/*<!-- Tabs for Delivery data starts -->*/}
        <ul
          className="nav nav-pills mb-3 bordered_nav_tabs"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                selectedTab === "receipts" ? " active" : ""
              }`}
              id="pills-receipts-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-receipts"
              type="button"
              role="tab"
              aria-controls="pills-receipts"
              aria-selected={selectedTab === "receipts" ? true : false}
              onClick={() => handlerTab("receipts")}
            >
              Receipts
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${selectedTab === "beds" ? " active" : ""}`}
              id="pills-beds-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-beds"
              type="button"
              role="tab"
              aria-controls="pills-beds"
              aria-selected={selectedTab === "beds" ? true : false}
              onClick={() => handlerTab("beds")}
            >
              Beds
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                selectedTab === "dailySummary" ? " active" : ""
              }`}
              id="pills-daily-summary-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-daily-summary"
              type="button"
              role="tab"
              aria-controls="pills-daily-summary"
              aria-selected={selectedTab === "dailySummary" ? true : false}
              onClick={() => handlerTab("dailySummary")}
            >
              Daily Summary
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {/*<!-- Receipts tab -->*/}
          <div
            className={`tab-pane fade ${
              selectedTab === "receipts" ? " show active" : ""
            }`}
            id="pills-receipts"
            role="tabpanel"
            aria-labelledby="pills-receipts-tab"
          >
            <DDReceipts
              {...props}
              ContractIds={ContractIds}
              listCropYear={listCropYear}
              minMaxYear={minMaxYear}
              ContractOption={ContractOption}
              currentCropYear={currentCropYear}
            />
          </div>
          {/*<!-- Receipts tab ends -->*/}

          {/*<!-- Beds tab -->*/}
          <div
            className={`tab-pane fade ${
              selectedTab === "beds" ? " show active" : ""
            }`}
            id="pills-beds"
            role="tabpanel"
            aria-labelledby="pills-beds-tab"
          >
            <DDBeds
              {...props}
              ContractIds={ContractIds}
              listCropYear={listCropYear}
              minMaxYear={minMaxYear}
              ContractOption={ContractOption}
              filterItems={filterItems}
              currentCropYear={currentCropYear}
            />
          </div>
          {/*<!-- Beds tab ends -->*/}

          {/*<!-- Dailysumary tab -->*/}
          <div
            className={`tab-pane fade ${
              selectedTab === "dailySummary" ? " show active" : ""
            }`}
            id="pills-daily-summary"
            role="tabpanel"
            aria-labelledby="pills-daily-summary-tab"
          >
            <DDDailysumary
              {...props}
              ContractIds={ContractIds}
              listCropYear={listCropYear}
              ContractOption={ContractOption}
              filterItems={filterItems}
              currentCropYear={currentCropYear}
            />
          </div>
          {/*<!-- Dailysumary tab ends -->*/}
        </div>
        {/*<!-- Tabs for Delivery data ends -->*/}
      </div>
    </>
  );
};

export default DeliveryData;
