import _ from "lodash";
import moment from "moment";
import { gbsDataCommonMethod } from "../services/gbsData";
import {
  activeCropYear,
  SendToastMsg,
  EmptyToastMsg,
  ReplaceToastMsg,
} from "../services/redux/actions/index";
import constantsVariable from "./constantsVariable";
import { AppInsights } from "../configs/appInsights";

/**
 * @function: getCurrentYear
 * @description: get current date
 * @author:Ashish Ranjan
 */
export const getCurrentYear = () => {
  const currentTime = new Date();
  const year = currentTime.getFullYear();
  return year;
};

export const getCurrentYearForPayment = () => {
  //const currentTime = new Date();
  const year = "2016"; //currentTime.getFullYear();
  return year;
};

/**
 * @function: getFullDate
 * @description: get full date as per date parameter
 * @author:Ashish Ranjan
 */
export const getFullDate = (param, formate) => {
  try {
    /*convert dateTime into date formate*/
    const date = new Date(param);
    /*exclude month from Date. Add zero if single digit*/
    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : `${month}`;
    /*exclude date from Date. Add zero if single digit*/
    let dt = date.getDate();
    dt = dt < 10 ? `0${dt}` : `${dt}`;
    const yr = date.getFullYear();
    if (formate === "date") {
      return `${dt}`;
    } else if (formate === "month") {
      return `${month}`;
    } else if (formate === "year") {
      return `${yr}`;
    } else if (formate === "fulldate") {
      return `${month}/${dt}/${yr}`;
    } else if (formate === "yyyy-mm-dd") {
      return `${yr}-${month}-${dt}`;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: currencyFormat
 * @description: get currency with comma amount ($30,492.85)
 * @author:Ashish Ranjan
 */
export const currencyFormat = (num) => {
  try {
    let converted = 0;
    if (num > 0) {
      let result =
        Number(num.toFixed(0)).toLocaleString().split(/\s/).join(",") +
        "." +
        Number(
          num.toString().slice(num.toString().indexOf(".") + 1)
        ).toLocaleString();
      converted = "$" + result;
    }
    return converted;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

export const trailZeros = (val, num) => {
  try {
    return val.toString().padStart(num, "0");
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/*check key and value is exist or not*/
export const checkValExist = (selectedList, Key, value) => {
  try {
    let flag = false;
    var found = Object.values(selectedList).filter((i) => i[Key] === value);
    flag = found.length > 0 ? true : false;
    return flag;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

export const remainFirstCharacter = (string, val = 0) => {
  try {
    return string.charAt(val);
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: getweightedAverage
 * @description: get currency with comma amount ($30,492.85)
 * @author:Ashish Ranjan
 */
export const getweightedAverage = (nums, weights) => {
  try {
    const [sum, weightSum] = weights.reduce(
      (acc, w, i) => {
        acc[0] = acc[0] + NumConverter(nums[i]) * NumConverter(w);
        acc[1] = acc[1] + NumConverter(w);
        return acc;
      },
      [0, 0]
    );
    return sum / weightSum;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: toDecimal
 * @description: it returns two or one value after decimal (65302.85222 = 65,302.85)
 * @author:Ashish Ranjan
 */
export const toDecimal = (value, n, currency = "", returnIfNull = 0) => {
  try {
    if (value > 0) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      let x = value.toString().split(".");
      x[0] = internationalNumberFormat.format(x[0]);
      if (n > 0) {
        x[1] = x[1] !== undefined ? "." + x[1]?.substring(0, n) : "";
        return currency + x[0] + x[1];
      } else {
        return currency + x[0];
      }
    }
    return returnIfNull;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return returnIfNull;
  }
};

/**
 * @function: toDecimalORCurrency
 * @description: it returns to decimal values  (65302.85222 = 65,302.85)
 * @author:Ashish Ranjan
 */
export const toDecimalORCurrency = (value, n, currency = "") => {
  try {
    if (value != 0) {
      let x = value.toString().split("."),
        decimal = "";
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      x[0] = internationalNumberFormat.format(x[0]);
      x[1] = x[1] !== undefined ? x[1]?.substring(0, n) : "";
      if (n > 0) {
        n = n - x[1].length;
        decimal = x[1];
        for (let i = 0; i < n; i++) {
          decimal += "0";
        }
      }
      if (Math.sign(value) < 0) {
        return `${currency}(${x[0].replace("-", "") + "." + decimal})`;
      } else {
        let newVal = currency + x[0];
        return newVal + "." + decimal;
      }
    }
    return 0;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: convertFilterArray
 * @description: convert data object to filter required array. eq. obj = {}, displayArray={id:'VarietyId', name:'VarietyDescription'}
 * @author:Ashish Ranjan
 */
export const convertFilterArray = (obj, displayArray) => {
  try {
    const multiDropDownData = [
      ...new Map(
        Object.values(obj).map((item) => [
          item[displayArray["id"]],
          {
            name: item[displayArray["name"]],
            id: item[displayArray["id"]],
          },
        ])
      ).values(),
    ];
    return multiDropDownData;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: getObjectLength
 * @description:
 * @author:Ashish Ranjan
 */
export const getObjectLength = (obj) => {
  try {
    return Object.keys(obj).map((x) => x).length;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: getFilteredData
 * @description:
 * @author:Ashish Ranjan
 */
export const getFilteredData = (arrayData, commaValue, apiKey) => {
  try {
    if (commaValue != "" && apiKey != "" && arrayData != "") {
      const filter = arrayData.filter((item) => {
        return commaValue
          ?.toLowerCase()
          .split(",")
          .find((x) => x === item[apiKey]?.toString().toLowerCase());
      });
      return filter;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

// convert object to string
export const setObjToString = (val) => {
  try {
    let ids = val.map((x) => x.id);
    ids = ids.filter((x) => x != "all" && x).toString();
    return ids;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: setOptions
 * @description: make an option format in ascending order.
 * @author:Ashish Ranjan
 */
export const setOptions = (
  data,
  keyPrram,
  concatValue1 = "",
  concatValue2 = "",
  tooltiptext = ""
) => {
  try {
    const optionsList = data.map((li) => {
      if (concatValue1 != "") {
        let concatName = li[concatValue1];
        if (concatValue2 != "") {
          concatName = concatName + " " + li[concatValue2];
        }
        if (tooltiptext !== "")
          return {
            name: concatName,
            id: li[keyPrram],
            tooltiptext: li[tooltiptext],
          };
        else return { name: concatName, id: li[keyPrram] };
      }
    });
    const filterData = [
      ...new Map(optionsList.map((item) => [item["id"], item])).values(),
    ];
    filterData.sort((a, b) =>
      a["name"].localeCompare(b["name"], "en", { numeric: true })
    );
    return filterData;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: IsStringChecker
 * @description: Check the given data as a String & Not Empty, return true if it is.
 * @author:Nivethithan Kannan
 */
export const IsStringChecker = (str) => {
  try {
    if (str && typeof str === "string") {
      let trimmed = str.trim();
      return trimmed ? true : false;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: IsNumberChecker
 * @description: Check the given data as a Number & Not Empty, return true if it is.
 * @author:Nivethithan Kannan
 */
export const IsNumberChecker = (num) => {
  try {
    if (
      num &&
      typeof num === "number" &&
      num !== Number.POSITIVE_INFINITY &&
      num !== Number.NEGATIVE_INFINITY &&
      num !== Number.NaN
    ) {
      return true;
    } else {
      return num === 0 ? true : false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: IsArrayChecker
 * @description: Check the given data as an Array or Not and return true if it is. It won't cares
 * whether the array has data or Not.
 * @author:Nivethithan Kannan
 */
export const IsArrayChecker = (arr) => {
  try {
    if (
      arr &&
      typeof arr === "object" &&
      arr instanceof Object &&
      arr instanceof Array &&
      !(arr instanceof Function)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: IsArrayNotEmpty
 * @description: Check the given data as an Array and Not Empty, return true if it is.
 * @author:Nivethithan Kannan
 */
export const IsArrayNotEmpty = (arr) => {
  try {
    if (IsArrayChecker(arr) && arr.length > 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: IsObjectChecker
 * @description: Check the given data as an Object or Not and return true if it is. It won't cares
 * whether the object has data or Not.
 * @author:Nivethithan Kannan
 */
export const IsObjectChecker = (obj) => {
  try {
    if (
      obj &&
      typeof obj === "object" &&
      obj instanceof Object &&
      !(obj instanceof Function) &&
      !(obj instanceof Array)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: IsFunctionChecker
 * @description: Check the given data as a Function or Not and return true if it is.
 * @author:Nivethithan Kannan
 */
export const IsFunctionChecker = (func) => {
  try {
    if (
      func &&
      typeof func === "function" &&
      func instanceof Function &&
      !(func instanceof Array)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: lowStr
 * @description: Converts string or number value to Lowercase String
 * @author:Nivethithan Kannan
 */
export const lowStr = (str) => {
  try {
    if (IsStringChecker(str)) {
      return str.toLowerCase();
    } else if (IsNumberChecker(str)) {
      return str.toString().toLowerCase();
    } else {
      return "";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
};

/**
 * @function: compareString
 * @description: Compares two strings and return true if they are same.
 * @author:Nivethithan Kannan
 */
export const compareString = (str1 = "", str2 = "") => {
  try {
    if (IsStringChecker(str1) && IsStringChecker(str2)) {
      return str1.toLowerCase() === str2.toLowerCase();
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: compareForSortFunc
 * @description: Compare Logic for Sorts Array of Objects based on Field with type string / integer
 * @author:Nivethithan Kannan
 */
export const compareForSortFunc = (a, b, field, order = 1) => {
  // console.log("sortFunc => ", a, b, field, order);
  try {
    const afield = _.get(a, field, undefined),
      bfield = _.get(b, field, undefined);
    if (IsStringChecker(afield) && IsStringChecker(bfield)) {
      let oafield = lowStr(afield),
        obfield = lowStr(bfield);
      if (oafield < obfield) {
        return order * -1;
      } else if (oafield > obfield) {
        return order * 1;
      } else {
        return order * 0;
      }
    } else if (IsNumberChecker(afield) && IsNumberChecker(bfield)) {
      if (order === 1) {
        return afield - bfield;
      } else {
        return bfield - afield;
      }
    } else {
      return 0;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0;
  }
};

/**
 * @function: sortByField
 * @description: Sorts Array of Objects based on Field with type string / integer. Here default field
 * is order_by and default sort order is 1 (asc). give order -1 for desc
 * @author:Nivethithan Kannan
 */
export const sortByField = (items, field = "order_by", order = 1) => {
  try {
    if (IsArrayNotEmpty(items)) {
      return items.sort((a, b) => compareForSortFunc(a, b, field, order));
    } else {
      return items;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return items;
  }
};

/**
 * @function: getResolvedLanguage
 * @description: Get Resolved Language from i18n. if not present or any error will return "en" [English] as resolved language
 * @author:Nivethithan Kannan
 */
export const getResolvedLanguage = (i18n = {}) => {
  try {
    let temp = _.get(i18n, "resolvedLanguage", "en");
    if (IsStringChecker(temp)) {
      return temp;
    } else {
      return "en";
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "en";
  }
};

/**
 * @function: groupArrayByKey
 * @description: Group array of objects based on the given key and return the group object. The value of the
 * key should be UNIQUE.
 * @author:Nivethithan Kannan
 */
export const groupArrayByKey = (array = [], key = "") => {
  try {
    if (IsArrayNotEmpty(array) && IsStringChecker(key)) {
      return array.reduce((group, current, index) => {
        let value = _.get(current, key, index);
        (group[value] = group[value] || []).push(current);
        return group;
      }, {});
    } else {
      return {};
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
};

/**
 * @function: checkValidEmailRegex
 * @description: to check valid email
 * @author:Ashish Ranjan
 */
export const checkValidEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

/**
 * @function: IsTokenChecker
 * @description: Check every given argument's data as a String & Not Empty, return true if it is.
 * @author:Nivethithan Kannan
 */
export function IsTokenChecker() {
  try {
    const args = [...arguments];
    if (IsArrayNotEmpty(args)) {
      return args.every((li) => IsStringChecker(li));
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
}

/**
 * @function: getproper_Atag_URLfromRichContent
 * @description: preparing proper url from Quil editor content
 * @author:Gobi
 */

export const getproper_Atag_URLfromRichContent = (string) => {
  let reg1 = new RegExp(`href="`, "g");
  let reg2 = new RegExp(`href="////`, "g");
  let reg3 = new RegExp(`href="//mailto`, "g");
  let reg4 = new RegExp(`href="///`, "g");
  let reg5 = new RegExp(`href="//htt`, "g");
  return string
    .replace(reg1, 'href="//')
    .replace(reg2, 'href="//')
    .replace(reg3, 'href="mailto')
    .replace(reg4, 'href="/')
    .replace(reg5, 'href="htt');
};

/**
 * @function: decodeHtml
 * @description: Sets and Returns the text content as a value to and from textArea
 * @author:Nivethithan Kannan
 */
export const decodeHtml = (decodedText) => {
  try {
    let textArea = document.createElement("textarea");
    textArea.innerHTML = decodedText;
    return textArea.value;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return decodedText;
  }
};

/**
 * @function: GetTextFromDecodedHTML
 * @description: Get Text Content from a HTML String.
 * @author:Nivethithan Kannan
 */
export const GetTextFromDecodedHTML = (decodedText = "") => {
  const RemoveVideoContent = (str = null) => {
    try {
      const contentOnly = decodedText.split(regExpPreTag)[0];
      return `${contentOnly}</div>`;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return str;
    }
  };
  try {
    if (IsStringChecker(decodedText)) {
      let temp = document.createElement("template");
      const contentOnly = RemoveVideoContent(decodedText);
      // console.log({ contentOnly });
      let html = `<div>${contentOnly}</div>`;
      temp.innerHTML = html;

      let firstChild = temp.content.firstChild;
      return firstChild.textContent;
      // console.log({
      //   FirstChild: firstChild,
      //   Length: firstChild.innerText.length,
      //   Text: firstChild.innerText,
      // });
      // return firstChild.innerText;
    } else {
      return decodedText;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return decodedText;
  }
};

/**
 * @description: Reg Exp for validating string surrounded by <pre></pre> tag with flag g for GLOBAL
 * @author:Nivethithan Kannan
 */
export const regExpAGM = new RegExp("<pre[^>]*>[^]*</pre>", "g");
export const regExpPreTagGlobal = new RegExp("<pre[^>]*>[^]*</pre>", "g");
export const regExpPreTag = new RegExp("<pre[^>]*>", "g");
export const regExpPreTagEnd = new RegExp("</pre>", "g");

/**
 * @function: fieldSorter
 * @description: This method will return the sorted array  based on the array passed on the function
 *                eg: resultData = resultData.sort(fieldSorter(['RelationshipNumber', 'CropYear','-Description', 'd+data', 'd-data']));
 *              'd+data' -> 1st letter 'd' represents Data field, '+' represents asc order '-' represents desc order
 *              without '+' it automatically do asc order,
 *               Note: when Date field in sorting array need to give the below format 'd+' or 'd-'
 */

export const regGlobalExpAGM = new RegExp("<pre[^>]*>(.+?)</pre>", "g");
export const regExpPreTagAGM = new RegExp("<pre[^>]*>", "g");
export const regExpPreTagEndAGM = new RegExp("</pre>", "g");

export const fieldSorter = (fields) => (a, b) =>
  fields
    .map((o) => {
      let dir = 1;
      let date = false;
      if (o[0] === "d") {
        date = true;
        o = o.substring(1);
      }
      if (o[0] === "+") {
        o = o.substring(1);
      }
      if (o[0] === "-") {
        dir = -1;
        o = o.substring(1);
      }
      if (date)
        return moment(a[o]) - moment(b[o])
          ? dir
          : moment(a[o]) - moment(b[o])
          ? -dir
          : 0;
      else return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
    })
    .reduce((p, n) => (p ? p : n), 0);

/**
 * @function: StringToInteger
 * @description: Converts the given String to integer and returns the result
 * @author:Nivethithan Kannan
 */
export const StringToInteger = (str = null) => {
  try {
    if (IsNumberChecker(str)) {
      return str;
    } else if (IsStringChecker(str)) {
      let num = parseInt(str);
      return IsNumberChecker(num) ? num : null;
    } else {
      return null;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return null;
  }
};

/**
 * @function: getYearRange
 * @description: Returns range of years from start to stop with given step value.
 * @author:Nivethithan Kannan
 */
export const getYearRange = (start = null, stop = 1) => {
  try {
    let startYear = StringToInteger(start);
    let stopYear = IsNumberChecker(stop)
      ? stop > 0
        ? startYear - stop
        : null
      : null;
    if (startYear && stopYear) {
      let arr = Array(startYear - stopYear)
        .fill("")
        .map((v, idx) => startYear - idx);
      return arr;
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
};

/**
 * @function: getCurrrentCropYear
 * @description: Returns the active current crop year.
 * @author:Nivethithan Kannan
 */

export const getCurrrentCropYear = async (tokenId, accounts, url, dispatch) => {
  try {
    if (IsTokenChecker(tokenId, url)) {
      const currentCropYearArr = await Promise.resolve(
        gbsDataCommonMethod(tokenId, accounts, url)
          .then((res) => {
            const data = _.get(res, "data", []);
            if (IsArrayNotEmpty(data)) {
              return _.get(data, "0.CropYear", null) || null;
            } else {
              return null;
            }
          })
          .catch((error) => {
            HandleErrorTrackAndToast(error, true);
            DispatchToastStack(dispatch);
            return null;
          })
      );
      // console.log("Helper : ", currentCropYearArr);
      dispatch(activeCropYear(currentCropYearArr));
      return currentCropYearArr;
    } else {
      dispatch(activeCropYear(null));
      return null;
    }
  } catch (error) {
    HandleErrorTrackAndToast(error, true);
    dispatch(activeCropYear(null));
    return null;
  }
};

/**
 * @function: InlineStyleSetter
 * @description: Returns the stringified inlinestyle for zoom in and out functionality
 * @author:Nivethithan Kannan
 */

export const InlineStyleSetter = (InlineStyle = {}) => {
  try {
    if (IsObjectChecker(InlineStyle) && !_.isEmpty(InlineStyle)) {
      return JSON.stringify(InlineStyle);
    }
    return "";
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return "";
  }
};

/**
 * @function: DateAsField
 * @description: Returns the ordering_date field from Content Block CMS for _.orderBy sorting from lodash.
 * @author:Nivethithan Kannan
 */
export const DateAsField = (
  obj,
  field = "ordering_date",
  display = "YYYYMMDDHHmmss"
) => {
  try {
    const dt = _.get(obj, field, null);
    return moment.utc(dt).format(display);
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return null;
  }
};

/**
 * @function: safeArrayCheck
 * @description: Checks and returns the given/empty array based on its type and value.
 * @author:Nivethithan Kannan
 */
export const safeArrayCheck = (items) => {
  try {
    if (IsArrayNotEmpty(items)) {
      return items;
    } else {
      return [];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return [];
  }
};

/**
 * @function: UniqueObjectArray
 * @description: Returns unique component listings
 * @author:Nivethithan Kannan
 */
export const UniqueObjectArray = (
  item,
  index,
  original,
  field = "component_listings.component_id"
) => {
  try {
    return (
      original.findIndex(
        (list) => _.get(list, field) === _.get(item, field)
      ) === index && item
    );
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

/**
 * @function: GetUniqueID
 * @description: Returns unique id
 * @author:Nivethithan Kannan
 */
export const GetUniqueID = (prefix = "ID", precision = 100) => {
  try {
    let random = IsNumberChecker(precision)
      ? precision * Math.random()
      : Math.random() * 100;
    let suffix = Math.ceil(Date.now() * random);
    return `${prefix}-${Date.now()}-${suffix}`;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return `${prefix}-${Date.now()}`;
  }
};

/**
 * @function: DispatchToastStack
 * @description: Dispatch the toast message
 * @author:Nivethithan Kannan
 */
export const DispatchToastStack = (dispatch, customToastProperties = {}) => {
  try {
    if (IsFunctionChecker(dispatch)) {
      const { defaultToastMsgProperties = {} } = constantsVariable;
      if (
        IsObjectChecker(customToastProperties) &&
        !_.isEmpty(customToastProperties)
      ) {
        let temp = { id: GetUniqueID(), ...customToastProperties };
        dispatch(SendToastMsg(temp));
      } else {
        let temp = { id: GetUniqueID(), ...defaultToastMsgProperties };
        dispatch(SendToastMsg(temp));
      }
    } else {
      HandleErrorTrackAndToast("Unable to Dispatch Toast Stack Messages", true);
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: EmptyToastStack
 * @description: Empty the toast container
 * @author:Nivethithan Kannan
 */
export const EmptyToastStack = (dispatch) => {
  try {
    if (IsFunctionChecker(dispatch)) {
      dispatch(EmptyToastMsg());
    } else {
      HandleErrorTrackAndToast("Unable to Empty the Toast Container", true);
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: ReplaceToastStack
 * @description: Replace the toast stack message
 * @author:Nivethithan Kannan
 */
export const ReplaceToastStack = (dispatch, customToastProperties = {}) => {
  try {
    if (IsFunctionChecker(dispatch)) {
      const { defaultToastMsgProperties = {} } = constantsVariable;
      if (
        IsObjectChecker(customToastProperties) &&
        !_.isEmpty(customToastProperties)
      ) {
        let temp = [{ id: GetUniqueID(), ...customToastProperties }];
        dispatch(ReplaceToastMsg(temp));
      } else {
        let temp = [{ id: GetUniqueID(), ...defaultToastMsgProperties }];
        dispatch(ReplaceToastMsg(temp));
      }
    } else {
      HandleErrorTrackAndToast("Unable to Replace Toast Stack Messages", true);
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
  }
};

/**
 * @function: NumConverter
 * @description: Convert String to Number
 * @author:Nivethithan Kannan
 */
export const NumConverter = (num, mathfunc) => {
  try {
    if (num) {
      if (IsNumberChecker(num)) {
        if (mathfunc === "round") return Math.round(num);
        else return num;
      } else {
        const temp = parseFloat(num);
        if (mathfunc === "round") return Math.round(temp);
        else return IsNumberChecker(temp) ? temp : 0;
      }
    }
    return 0;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return 0;
  }
};

/**
 * @function: dateInputMask
 * @description: Convert String to Date format
 * @author:Gobi C
 */
//Datepicket type event
export const dateInputMask = (elm) => {
  elm.addEventListener("keypress", function (e) {
    var len = elm.value.length;
    if (e.keyCode < 47 || e.keyCode > 57 || len >= 10) {
      e.preventDefault();
    }
    if (len !== 1 || len !== 3) {
      if (e.keyCode == 47) {
        e.preventDefault();
      }
    }
    if (len === 2) {
      elm.value += "/";
    }
    if (len === 5) {
      elm.value += "/";
    }
  });
};

/**
 * @function: HandleErrorTrackAndToast
 * @description: Common method to Trigger App Insights Track Exception, handle console.error and Toast Handling from catch block
 * @author:Nivethithan Kannan
 */
export const HandleErrorTrackAndToast = (
  error = false,
  track = false,
  toast = false,
  dispatch = false,
  consoleError = true,
  customToastProperties = {}
) => {
  if (consoleError && error) {
    console.error(error);
  }
  if (track && AppInsights && error) {
    // console.log({ AppInsights });
    AppInsights?.appInsights?.trackException({ exception: error });
  }
  switch (toast) {
    case "dispatch":
      DispatchToastStack(dispatch, customToastProperties);
      break;
    case "replace":
      ReplaceToastStack(dispatch, customToastProperties);
      break;
    case "empty":
      EmptyToastStack(dispatch, customToastProperties);
      break;
    default:
      break;
  }
};

/**
 * @function: getCookie
 * @description: get cookie value by name
 * @author:Gobi C
 */

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}