/**
 * @component_Name : ContentBlockRenderSwitch
 * @description : Content Block UI Switching based on CSS CLASS value [accordion,blue-title-box,plain-text] from CMS
 * @company : Photon
 * @author : Nivethithan Kannan/02-02-2023
 **/
import React, { Fragment, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  lowStr,
  getResolvedLanguage,
  IsObjectChecker,
  IsStringChecker,
  IsArrayNotEmpty,
  IsTokenChecker,
  IsFunctionChecker,
  DateAsField,
  compareString,
} from "../../utils/helper";
import ContentModifier from "./contentModifier";
import CurrentLocation from "./currentLocation";
import { HandleErrorTrackAndToast } from "../../utils/helper";

export const ValidReactElementChecker = (Element = null) => {
  try {
    if (
      Element &&
      IsFunctionChecker(Element) &&
      React.isValidElement(React.createElement(Element))
    ) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return false;
  }
};

const EmptyContentFilter = (cnt = "") => {
  try {
    if (IsStringChecker(cnt)) {
      const EmptyContents = [
        '<div class="ql-editor"></div>',
        '<div class="ql-editor"><br></div>',
        '<div class="ql-editor"><p></p></div>',
        '<div class="ql-editor"><p><br></p></div>',
      ];
      const foundEmptyContent = EmptyContents.find((li) =>
        compareString(li, cnt)
      );
      return foundEmptyContent ? null : cnt;
    }
    return cnt;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return cnt;
  }
};

const sortFieldsChecker = (sortField = ["order_by"]) => {
  try {
    if (IsArrayNotEmpty(sortField)) {
      return sortField;
    } else if (IsFunctionChecker(sortField)) {
      return sortField;
    } else {
      return ["order_by"];
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return ["order_by"];
  }
};

const SortTheList = (
  list = [],
  sortField = ["order_by"],
  sortOrder = ["asc"]
) => {
  try {
    const Field = sortFieldsChecker(sortField);
    if (IsArrayNotEmpty(list) && IsTokenChecker(...sortOrder) && Field) {
      return _.orderBy(list, Field, sortOrder);
    }
    return list;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return list;
  }
};

const InlineStyleGetter = (InlineStyle = "") => {
  try {
    if (IsStringChecker(InlineStyle)) {
      const ParsedInlineStyle = JSON.parse(InlineStyle);
      if (IsObjectChecker(ParsedInlineStyle) && !_.isEmpty(ParsedInlineStyle)) {
        return { style: { ...ParsedInlineStyle } };
      }
      return {};
    }
    return {};
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
};

const ClassNameCheker = (classNames = {}) => {
  try {
    if (_.isEmpty(classNames)) {
      return {};
    } else {
      const access = Object.values(classNames).every((li) =>
        IsStringChecker(li)
      );
      return access ? classNames : {};
    }
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return {};
  }
};

const DataProvider = (obj = {}, prop = null, cb, safeReturn = null) => {
  try {
    const data = _.get(obj, `${prop}`, safeReturn);
    return cb(data) ? data : safeReturn;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return safeReturn;
  }
};

const WrapperForBlock = (props) => {
  const {
    child = null,
    rowClassName = "row",
    appendRowClassName = "",
    colClassName = "col-12",
    appendColClassName = "",
    WrapperRow = true,
    WrapperCol = true,
    ...rest
  } = props;
  if (WrapperRow && WrapperCol) {
    return (
      <div className={`${rowClassName} ${appendRowClassName}`}>
        <div className={`${colClassName} ${appendColClassName}`}>
          {child && child(rest)}
        </div>
      </div>
    );
  } else if (WrapperRow || WrapperCol) {
    return (
      <div
        className={
          WrapperRow
            ? `${rowClassName} ${appendRowClassName}`
            : `${colClassName} ${appendColClassName}`
        }
      >
        {child && child(rest)}
      </div>
    );
  } else {
    return child && child(rest);
  }
};

const PlainTextContent = (props) => {
  const {
    title,
    RenderElement = null,
    headerClassName = "fw-bold",
    appendHeaderClassName = "",
    HeaderId = "",
  } = props;
  const HeadId = IsStringChecker(HeaderId) ? { id: HeaderId } : {};
  return (
    <Fragment>
      {IsStringChecker(title) ? (
        <h4
          {...HeadId}
          className={`${headerClassName} ${appendHeaderClassName}`}
        >
          {title}
        </h4>
      ) : (
        <></>
      )}
      {ValidReactElementChecker(RenderElement) ? <RenderElement /> : <></>}
    </Fragment>
  );
};

const AccordionContent = (props) => {
  const {
    title,
    RenderElement = null,
    icon_url,
    show_print_icon,
    id,
    component_id,
    HeaderId = "",
    accordionClassName = "",
    appendAccordionClassName = "",
    accordionItemClassName = "",
    accordionHeaderClassName = "",
    accordionBodyClassName = "",
    accordionIconClassName = "width-30 right-space-10",
    ...rest
  } = props;

  const AccordionPrint = () => {
    const HandlePrint = () => {
      window.print();
    };
    return (
      <div className="row">
        <div className="col-12">
          <div className="float-end mb-2">
            <span className="float-end">
              <button
                className="printButonCss printIconPadding d-none d-lg-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title=""
                data-bs-original-title="Print"
                aria-label="Print"
              >
                <img
                  src={`/assets/images/print_icon.svg`}
                  className="img-40 me-2"
                  alt="Print icon"
                  onClick={HandlePrint}
                />
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const eventKey = `${component_id}-${id}`;
  const HeadId = IsStringChecker(HeaderId) ? { id: HeaderId } : {};
  return (
    <Accordion className={`${accordionClassName} ${appendAccordionClassName}`}>
      <Accordion.Item className={accordionItemClassName} eventKey={eventKey}>
        {IsStringChecker(title) && (
          <Accordion.Header {...HeadId} className={accordionHeaderClassName}>
            {IsStringChecker(icon_url) && (
              <img src={icon_url} className={accordionIconClassName} alt={""} />
            )}
            {title}
          </Accordion.Header>
        )}
        <Accordion.Body className={accordionBodyClassName}>
          {ValidReactElementChecker(RenderElement) ? (
            <Fragment>
              {show_print_icon && <AccordionPrint />}
              <WrapperForBlock {...rest} child={() => <RenderElement />} />
            </Fragment>
          ) : (
            <></>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const BlueTitleBoxContent = (props) => {
  const {
    title,
    RenderElement = null,
    shadowClassName = "card-shadow white_bg border-radius-6",
    appendShadowClassName = "",
    headerClassName = "card-header white_color ver-space-15 left-border-radius right-border-radius",
    appendHeaderClassName = "",
    textClassName = "text-16 px-2 px-lg-0",
    appendTextClassName = "",
    HeaderInlineStyle = "",
    ContentInlineStyle = "",
    HeaderId = "",
  } = props;
  const headerStyle = InlineStyleGetter(HeaderInlineStyle);
  const contentStyle = InlineStyleGetter(ContentInlineStyle);
  const HeadId = IsStringChecker(HeaderId) ? { id: HeaderId } : {};
  return (
    <div {...HeadId} className={`${shadowClassName} ${appendShadowClassName}`}>
      {IsStringChecker(title) ? (
        <h4
          className={`${headerClassName} ${appendHeaderClassName}`}
          {...headerStyle}
        >
          {title}
        </h4>
      ) : (
        <></>
      )}
      {ValidReactElementChecker(RenderElement) ? (
        <div className="card-body mob-pleft-pright-0">
          <div className="row">
            <div className="col-12">
              <div
                className={`${textClassName} ${appendTextClassName}`}
                {...contentStyle}
              >
                <RenderElement />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const ContentBlockRenderSwitch = ({
  RenderObj = {},
  ContentAsElement = false,
  ContentReactElement = null,
  AdditionalContent = null,
  WrapperRow = true,
  WrapperCol = true,
  DefaultCssClass = null,
  AdjustmentClassName = "",
  HeaderId = "",
  AccordionClassNames = {},
  PlainTextClassNames = {},
  BlueTitleBoxClassNames = {},
  ExcludeTitle = false,
}) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const TranslatedDataProvider = (obj = {}, prop = null) => {
    try {
      const data = _.get(obj, `${prop}${resolvedLanguage}`, "");
      return IsStringChecker(data) ? data : "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const title = ExcludeTitle
    ? null
    : TranslatedDataProvider(RenderObj, `component_listings.accordion_name_`);

  const css_class = lowStr(
    DataProvider(
      RenderObj,
      `component_listings.css_class`,
      IsStringChecker,
      IsStringChecker(DefaultCssClass) ? DefaultCssClass : null
    )
  );

  const RenderElement = () => {
    if (ContentAsElement) {
      return ValidReactElementChecker(ContentReactElement) ? (
        <ContentReactElement />
      ) : (
        <></>
      );
    } else {
      const content = TranslatedDataProvider(RenderObj, `content_`);
      return IsStringChecker(content) ? (
        <>
          <ContentModifier str={content} />
          {ValidReactElementChecker(AdditionalContent) ? (
            <AdditionalContent />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      );
    }
  };

  switch (css_class) {
    case "plain-text":
      const TempPlainTextClassNames = {
        ...ClassNameCheker(PlainTextClassNames),
      };
      TempPlainTextClassNames.appendHeaderClassName = AdjustmentClassName;
      return (
        <WrapperForBlock
          WrapperRow={WrapperRow}
          WrapperCol={WrapperCol}
          title={title}
          RenderElement={RenderElement}
          {...TempPlainTextClassNames}
          HeaderId={HeaderId}
          child={(childProps) => <PlainTextContent {...childProps} />}
        />
      );
    case "accordion":
      const { id = null } = RenderObj;
      const component_listings = DataProvider(
        RenderObj,
        `component_listings`,
        IsObjectChecker,
        {}
      );
      const TempAccordionClassNames = {
        ...ClassNameCheker(AccordionClassNames),
      };
      TempAccordionClassNames.appendAccordionClassName = AdjustmentClassName;
      return (
        <AccordionContent
          id={id}
          WrapperRow={WrapperRow}
          WrapperCol={WrapperCol}
          title={title}
          HeaderId={HeaderId}
          RenderElement={RenderElement}
          {...component_listings}
          {...TempAccordionClassNames}
        />
      );
    case "blue-title-box":
      const TempBlueTitleBoxClassNames = {
        ...ClassNameCheker(BlueTitleBoxClassNames),
      };
      TempBlueTitleBoxClassNames.appendShadowClassName = AdjustmentClassName;
      return (
        <WrapperForBlock
          WrapperRow={WrapperRow}
          WrapperCol={WrapperCol}
          title={title}
          HeaderId={HeaderId}
          RenderElement={RenderElement}
          {...TempBlueTitleBoxClassNames}
          child={(childProps) => <BlueTitleBoxContent {...childProps} />}
        />
      );
    default:
      return <></>;
  }
};

const ContentWithButton = ({
  Name = null,
  RenderProperties = {},
  ContentList = [],
  DefaultCssClass = null,
  ...Rest
}) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const [currentIndex, setCurrentIndex] = useState(0);

  const SetNameAsID = (name = "") => {
    try {
      return IsStringChecker(name) ? name.replace(/ /g, "_") : "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const SetRenderProperty = (RenderProps = {}, name = null) => {
    try {
      const RenderProperty = IsObjectChecker(RenderProps)
        ? { ...RenderProps }
        : {};
      RenderProperty.HeaderId = name;
      return IsObjectChecker(RenderProperty) ? RenderProperty : {};
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {};
    }
  };

  const SwitchData = (e, index, direction = null) => {
    try {
      e.stopPropagation();
      const initial = 0,
        length = ContentList.length;
      if (IsStringChecker(direction)) {
        switch (direction) {
          case "prev":
            const prevAccess = index - 1 >= initial;
            if (prevAccess) {
              return setCurrentIndex(index - 1);
            }
            break;
          case "next":
            const nextAccess = index + 1 < length;
            if (nextAccess) {
              return setCurrentIndex(index + 1);
            }
            break;
          default:
            return setCurrentIndex(0);
        }
      } else {
        setCurrentIndex(0);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setCurrentIndex(0);
    }
  };

  const NameAsID = SetNameAsID(Name);
  const prevBtn = currentIndex !== 0;
  const nextBtn = currentIndex !== ContentList.length - 1;
  const DisplayData = _.get(ContentList, currentIndex, {});
  const RenderProperty = SetRenderProperty(RenderProperties, NameAsID);
  const hrefContent = IsStringChecker(NameAsID)
    ? { href: `#${NameAsID.replace(/ /g, "_")}` }
    : { href: "#" };
  return (
    <ContentBlockRenderSwitch
      RenderObj={DisplayData}
      DefaultCssClass={
        IsStringChecker(DefaultCssClass) ? DefaultCssClass : null
      }
      {...RenderProperty}
      AdditionalContent={() => {
        if (prevBtn || nextBtn) {
          return (
            <>
              <div className="horizontal-line mt-5 my-4"></div>
              <div className="overflow-hidden">
                {prevBtn && (
                  <span className="next-button d-inline-block border-1 px-1 px-sm-3 py-1 py-sm-2 border-radius-6 float-end text-end">
                    <a
                      {...hrefContent}
                      onClick={(e) => SwitchData(e, currentIndex, "prev")}
                    >
                      <span className="red_color">
                        <span className="text-16 fw-bold mob-text-13">
                          {_.get(
                            moreLangs,
                            `${resolvedLanguage}.community_ag_science_pages.next_update`,
                            "Next Update"
                          )}
                        </span>
                        {` `}
                        <i
                          className="fa fa-arrow-right text-16 ms-1"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                  </span>
                )}
                {nextBtn && (
                  <span className="prev-button d-inline-block border-1 px-1 px-sm-3 py-1 py-sm-2 border-radius-6 float-start mb-3 mb-sm-0">
                    <a
                      {...hrefContent}
                      onClick={(e) => SwitchData(e, currentIndex, "next")}
                    >
                      <span className="red_color">
                        <i
                          className="fa fa-arrow-left text-16 me-1"
                          aria-hidden="true"
                        ></i>
                        {` `}
                        <span className="text-16 fw-bold mob-text-13">
                          {_.get(
                            moreLangs,
                            `${resolvedLanguage}.community_ag_science_pages.previous_update`,
                            "Previous Update"
                          )}
                        </span>
                      </span>
                    </a>
                  </span>
                )}
              </div>
            </>
          );
        } else {
          return <></>;
        }
      }}
      {...Rest}
    />
  );
};

export const RenderContentBlockByOrder = ({
  ShowPagination = false,
  Name = null,
  DefaultCssClass = null,
  ContentList = [],
  RenderProperties = {},
  sortOrders = ["asc"],
  sortFields = ["order_by"],
  ...Rest
}) => {
  const renderAccess = IsArrayNotEmpty(ContentList) && IsStringChecker(Name);
  if (renderAccess) {
    const OrderedContents = SortTheList(
      ContentList,
      ShowPagination ? DateAsField : sortFields,
      ShowPagination ? ["desc"] : sortOrders
    );
    const FinalDefaultCssClass = IsStringChecker(DefaultCssClass)
      ? DefaultCssClass
      : null;
    if (ShowPagination) {
      const PaginationRenderProperty = IsObjectChecker(RenderProperties)
        ? { ...RenderProperties }
        : {};
      return (
        <ContentWithButton
          Name={Name}
          DefaultCssClass={FinalDefaultCssClass}
          ContentList={OrderedContents}
          RenderProperties={PaginationRenderProperty}
          {...Rest}
        />
      );
    } else {
      const RenderObj = _.get(OrderedContents, "0", {});
      const NormalRenderProperty = IsObjectChecker(RenderProperties)
        ? { ...RenderProperties }
        : {};
      return (
        <ContentBlockRenderSwitch
          RenderObj={RenderObj}
          DefaultCssClass={FinalDefaultCssClass}
          {...NormalRenderProperty}
          {...Rest}
        />
      );
    }
  } else {
    return <></>;
  }
};

const ComponentListRenderer = ({
  ComponentList: OrgComponentList = [],
  ComponentListSortField = ["order_by"],
  ComponentListSortOrder = ["asc"],
  GroupedData = {},
  DefaultCssClass = null,
  SortFields = ["order_by"],
  SortOrders = ["asc"],
  RenderProperties = {},
  DefaultCssClassGroup = [],
  SortFieldsGroup = [],
  SortOrdersGroup = [],
  RenderPropertiesGroup = [],
  ...Rest
}) => {
  try {
    if (IsArrayNotEmpty(OrgComponentList) && IsObjectChecker(GroupedData)) {
      const ComponentList = _.orderBy(OrgComponentList, ComponentListSortField, ComponentListSortOrder);
      return ComponentList.map((item, index) => {
        const ShowPagination = _.get(item, "show_previous_next", false);
        const Name = _.get(item, "accordion_name_en", null);
        const RenderData = IsStringChecker(Name)
          ? _.get(GroupedData, Name, [])
          : [];
        const FinalSortFields = ShowPagination
          ? DateAsField
          : IsArrayNotEmpty(SortFieldsGroup)
          ? _.get(SortFieldsGroup, index, SortFields)
          : SortFields;
        const FinalSortOrders = ShowPagination
          ? ["desc"]
          : IsArrayNotEmpty(SortOrdersGroup)
          ? _.get(SortOrdersGroup, index, SortOrders)
          : SortOrders;
        const FinalDefaultCssClass = IsArrayNotEmpty(DefaultCssClassGroup)
          ? _.get(DefaultCssClassGroup, index, DefaultCssClass)
          : DefaultCssClass;
        const FinalRenderProperties = IsArrayNotEmpty(RenderPropertiesGroup)
          ? _.get(RenderPropertiesGroup, index, RenderProperties)
          : RenderProperties;
        return (
          <RenderContentBlockByOrder
            key={index}
            ShowPagination={ShowPagination}
            Name={Name}
            ContentList={RenderData}
            RenderProperties={FinalRenderProperties}
            sortFields={FinalSortFields}
            sortOrders={FinalSortOrders}
            DefaultCssClass={FinalDefaultCssClass}
            {...Rest}
          />
        );
      });
    }
    return <></>;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return <></>;
  }
};

export default ComponentListRenderer;

export const ContentAndGridComponentListRenderer = ({
  ComponentList: OrgComponentList = [],
  ComponentListSortField = ["order_by"],
  ComponentListSortOrder = ["asc"],
  GroupedData = {},
  ContentPath = null,
  CallBackContent = null,
  DefaultCssClass = null,
  SortFields = ["order_by"],
  SortOrders = ["asc"],
  RenderProperties = {},
  DefaultCssClassGroup = [],
  SortFieldsGroup = [],
  SortOrdersGroup = [],
  RenderPropertiesGroup = [],
  ...Rest
}) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  try {
    if (
      IsArrayNotEmpty(OrgComponentList) &&
      IsObjectChecker(GroupedData) &&
      IsStringChecker(ContentPath)
    ) {
      const ComponentList = _.orderBy(OrgComponentList, ComponentListSortField, ComponentListSortOrder);
      return ComponentList.map((item, index) => {
        const Name = _.get(item, "accordion_name_en", null);
        const RenderData = IsStringChecker(Name)
          ? _.get(GroupedData, Name, [])
          : [];
        const FinalSortFields = IsArrayNotEmpty(SortFieldsGroup)
          ? _.get(SortFieldsGroup, index, SortFields)
          : SortFields;
        const FinalSortOrders = IsArrayNotEmpty(SortOrdersGroup)
          ? _.get(SortOrdersGroup, index, SortOrders)
          : SortOrders;
        const FinalDefaultCssClass = IsArrayNotEmpty(DefaultCssClassGroup)
          ? _.get(DefaultCssClassGroup, index, DefaultCssClass)
          : DefaultCssClass;
        const FinalRenderProperties = IsArrayNotEmpty(RenderPropertiesGroup)
          ? _.get(RenderPropertiesGroup, index, RenderProperties)
          : RenderProperties;
        const Content = EmptyContentFilter(
          _.get(item, `${ContentPath}${resolvedLanguage}`, null)
        );
        if (IsArrayNotEmpty(RenderData) && IsStringChecker(Name)) {
          const OrderedContents = SortTheList(
            RenderData,
            FinalSortFields,
            FinalSortOrders
          );
          const RenderObj = _.get(OrderedContents, "0", {});
          const RenderElement = () => {
            return (
              <>
                {IsStringChecker(Content) ? (
                  <ContentModifier str={Content} />
                ) : (
                  <></>
                )}
                {ValidReactElementChecker(CallBackContent) ? (
                  <div className="row">
                    <CallBackContent data={OrderedContents} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          };
          return (
            <ContentBlockRenderSwitch
              key={index}
              {...FinalRenderProperties}
              DefaultCssClass={FinalDefaultCssClass}
              RenderObj={RenderObj}
              ContentAsElement={true}
              ContentReactElement={RenderElement}
              {...Rest}
            />
          );
        }
        return <Fragment key={index} />;
      });
    }
    return <></>;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    return <></>;
  }
};
