/**
 * @component_Name : DDBedsMobile -Delivery Data Beds component
 * @description : Show Delivery Data Beds as accodion
 * @company : Photon
 * @author : Ashish Ranjan/20-07-2022
 **/
import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { ConstantsVar } from "../../../utils/constants";
import Moment from "react-moment";

const DDBedsMobile = ({ listingData }) => {
  const pagingArray = Object.keys(listingData).map((x) => x);
  const listLength = pagingArray.length;
  return (
    <>
      <Accordion defaultActiveKey="0">
        {listLength > 0 &&
          Object.values(listingData).map((data, index) => {
            return (
              <Fragment key={index}>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    Contract #: {data["Contract_Number"]}, Bed:{" "}
                    {data["Harvest_Bed"]}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tbody">
                      <div className="tr">
                        <div className="td"></div>
                        <div className="td" data-header="Crop Year:">
                          <span>{data["Crop_Year"]}</span>
                        </div>
                        <div className="td" data-header="Contract Number:">
                          <span>{data["Contract_Number"]}</span>
                        </div>
                        <div className="td" data-header="Harvest Bed:">
                          <span>{data["Harvest_Bed"]}</span>
                        </div>
                        <div className="td" data-header="Acres:">
                          <span>{data["Acres"]}</span>
                        </div>
                        <div className="td" data-header="Active Acres:">
                          <span>{data["Active_Acres"]}</span>
                        </div>
                        <div className="td" data-header="Variety:">
                          <span>{data["Variety"]}</span>
                        </div>
                        <div className="td" data-header="Plant Year:">
                          <span>{data["Plant_Year"]}</span>
                        </div>
                        <div className="td" data-header="Status:">
                          {data["Status"]}
                        </div>
                        <div className="td" data-header="Date Range (From):">
                          <span>
                            {
                              <Moment utc format={ConstantsVar.dateFormate}>
                                {data["Date_Range_(From)"]}
                              </Moment>
                            }
                          </span>
                        </div>
                        <div className="td" data-header="Date Range (To):">
                          <span>
                            {
                              <Moment utc format={ConstantsVar.dateFormate}>
                                {data["Date_Range_(To)"]}
                              </Moment>
                            }
                          </span>
                        </div>
                        <div className="td" data-header="Total Barrels:">
                          <span>{data["Total_Barrels"]}</span>
                        </div>
                        <div className="td" data-header="Avg % Total Trash:">
                          <span>{data["Avg_%_Total_Trash"]}</span>
                        </div>
                        <div className="td" data-header="Avg % Poor:">
                          <span>{data["Avg_%_Poor"]}</span>
                        </div>
                        <div className="td" data-header="Avg % Class1:">
                          <span>{data["Avg_%_Class1"]}</span>
                        </div>
                        <div className="td" data-header="Avg % Usable:">
                          <span>{data["Avg_%_Usable"]}</span>
                        </div>
                        <div className="td" data-header="Yield:">
                          <span>
                            {data["Yield"]}{" "}
                            {data["Yield"] >= data["Prior_Year_Yield"] ? (
                              <i
                                className="fa fa-arrow-up green_dark_color text-14"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="fa fa-arrow-down red_color text-14"
                                aria-hidden="true"
                              ></i>
                            )}
                          </span>
                        </div>
                        <div className="td" data-header="Prior Year Yield:">
                          <span>{data["Prior_Year_Yield"]}</span>
                        </div>
                        <div
                          className="td"
                          data-header="Prior 4 Year's Average Yield:"
                        >
                          <span>{data["Prior_4_Years_Average_Yield"]}</span>
                        </div>
                        <div className="td" data-header="Max Yield:">
                          <span>{data["Max_Yield"]}</span>
                        </div>
                        <div className="td" data-header="Max Yield Year:">
                          <span>{data["Max_Yield_Year"]}</span>
                        </div>
                        <div
                          className="td"
                          data-header="% Above/Below Max Yield:"
                        >
                          <span>{data["%_Above/Below_Max_Yield"]}</span>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Fragment>
            );
          })}
      </Accordion>
    </>
  );
};

export default DDBedsMobile;
