import React from "react";
import PageLayout from "../../pageLayout";
import CoopFinanceLanding from "./coopFinanceLanding";

const CoopFinance = (props) => {
  return (
    <PageLayout
      {...props}
      body={
        <CoopFinanceLanding
          slug={"coop-finance"}
          sideNav={"coop-finance"}
          {...props}
        />
      }
    />
  );
};

export default CoopFinance;
