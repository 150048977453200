/**
 * @component_Name : UserNotification
 * @description : Users can see thier own Notification
 * @company : Photon
 * @author : Gobi/03-10-2022
 **/
import React, { useState, useEffect, useContext, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../services/gbsData";
import Loading from "../global/loading";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { NotificationContext } from "../context/contextVariable";
import CurrentLocation from "../global/currentLocation";
import { useTranslation } from "react-i18next";
import { Modal, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { HandleErrorTrackAndToast, IsTokenChecker } from "../../utils/helper";
import parse from "html-react-parser";
import { htmlEncodeDecode } from "../../utils/constants";

const UserNotification = () => {
  const { accounts } = useMsal();
  const localAccountId = _.get(accounts, "0.localAccountId", "");
  const tokenId = useSelector((state) => state.reducerToken);
  const [, moreLangs] = CurrentLocation();
  const { i18n } = useTranslation();
  const [notificationList, setNotificationList] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [loader, setLoader] = useState(false);
  let loadMoreLimit = 5;
  const [numItems, setNumItems] = useState(loadMoreLimit);
  const [showModal, setShowModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });

  const notificationContext = useContext(NotificationContext);
  const {
    notificationSuccess,
    notificationLoader,
    notificationServiceCalled,
    getNotification,
  } = notificationContext;
  const { notificationIndex } = useParams();
  const notificationRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    setLoader(true);
    notificationServiceCalled && (setNoRecordFound(true), setLoader(false));
    if (notificationSuccess.length > 0) {
      setNotificationList([...notificationSuccess]);
      setNoRecordFound(false);
      setLoader(false);
    } else {
      setNotificationList([]);
    }
  }, [notificationSuccess, notificationServiceCalled]);

  const handleClose = () => {
    setShowModal(false);
  };

  // Event Effect
  useEffect(() => {
    window.addEventListener("scroll", lazyLoad);
    return () => {
      window.removeEventListener("scroll", lazyLoad);
    };
  }, [notificationList]);

  const lazyLoad = () => {
    let notificationDom = document.querySelector(".notification-body");
    if (
      window.innerWidth <= 992 &&
      document.querySelectorAll(".notification-body").length == 2
    ) {
      notificationDom = document.querySelectorAll(".notification-body")[1];
    }
    if (
      window.pageYOffset + window.innerHeight >=
        document.documentElement.scrollHeight -
          document.getElementsByTagName("footer")[0]?.offsetHeight &&
      notificationList.length >= numItems &&
      (notificationDom.offsetWidth > 0 || notificationDom.offsetHeight > 0)
    ) {
      setNumItems((prev) => prev + loadMoreLimit);
    }
  };

  const deleteNotification = (obj) => {
    setShowModal(true);
    setDeleteID(obj);
  };

  useEffect(() => {
    notificationRef.current?.scrollIntoView();
  }, [notificationRef.current]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);
  const markAsRead = async () => {
    await gbsDataCommonMethod(
      tokenId,
      accounts,
      `persons/notifications/read/${localAccountId}/${deleteID.id}`,
      "put"
    )
      .then((res) => {
        if (res?.data?.status.toLowerCase() === "success") {
          if(IsTokenChecker(tokenId)){
            getNotification();
          }
          notificationList.splice(
            notificationList.findIndex((a) => a.id === deleteID.id),
            1
          );
          handleClose();
          setAlertOpen({
            isActive: true,
            message: `${
              moreLangs[i18n.resolvedLanguage].userProfile
                .delete_success_message
            }`,
          });
          // setTimeout(() => {
          //   setAlertOpen({isActive:false,message:""});
          // }, 4000);
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const getdecodeHtml =(htmlData) => {
    let htmlEncodeData=htmlData;
    if(htmlEncodeData !=undefined && htmlEncodeData != null){
         for(const htmlTag of htmlEncodeDecode){
            htmlEncodeData=htmlEncodeData.replaceAll(htmlTag.decodeValue, htmlTag.encodekey);
        }
    }
    return htmlEncodeData; 
  }

  return (
    <>
      {(loader || notificationLoader) && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {alertOpen.isActive && (
        <Alert
          variant="success"
          onClose={() => setAlertOpen({ isActive: false, message: "" })}
          dismissible
        >
          <b>{alertOpen.message}</b>
        </Alert>
      )}
      <div
        className={`white_bg border-radius-6 p-4 ${
          notificationList.length > 0 && "pt-5"
        } mb-4 mt-3 p-mobile-0 notification-body`}
      >
        {notificationList?.slice(0, numItems).map((notify) => {
          let message = "";
          try{
            message = parse(getdecodeHtml(notify.msg));
          }
          catch(e){
            message = ""
          }
          return <div
            key={notify.id}
            className={`border-1 border-radius-6 p-3 mb-3 ${
              notificationIndex === notify.id && "highlightNotification"
            }`}
            ref={notificationIndex === notify.id ? notificationRef : null}
          >
            <h4 className="text-uppercase fw-bold">{notify.title}</h4>
            <p className="mb-0">{message}</p>
            {/* {notify.url !=="" && notify.url !== undefined && <Link to={notify.url} className="pt-2 d-inline-block me-3">{moreLangs[i18n.resolvedLanguage]?.home_page?.read_more}</Link>} */}
            <Link
              onClick={() => {
                deleteNotification(notify);
              }}
              className="red_color pt-2 d-inline-block"
              to="#"
            >
              {moreLangs[i18n.resolvedLanguage]?.delete_the_notification}
            </Link>
          </div>
        }
      )}
        {notificationList?.length === 0 && noRecordFound && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {
              moreLangs[i18n.resolvedLanguage]?.userProfile
                ?.no_notification_found
            }
          </div>
        )}
      </div>

      {showModal && (
        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="blue_bg">
            <Modal.Title>
              <h4 className="modal-title white_color">
                {
                  moreLangs[i18n.resolvedLanguage]?.userProfile
                    .delete_popup_title
                }
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer className="border-0">
            <button
              className="btn btn-primary"
              id="ok_button"
              onClick={markAsRead}
            >
              {moreLangs[i18n.resolvedLanguage]?.delete}
            </button>
            <button
              className="btn btn-default card-shadow ms-2"
              id="ok_button"
              onClick={handleClose}
            >
              {moreLangs[i18n.resolvedLanguage]?.button_text.cancel_text}
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default UserNotification;
