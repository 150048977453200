/**
 * @component_Name : Ospac Body
 * @description : Ospac Body
 * @company : Photon
 * @author : Ashish Ranjan/14-10-2022
 **/
import React, { useState, useEffect } from "react";
import "../../../css/loading.css";
import Loading from "../../global/loading";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import LeftMenu from "../../global/leftMenu";
import Breadcrumb from "../../global/breadCrumb";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import {
  IsTokenChecker,
  UniqueObjectArray,
  safeArrayCheck,
  sortByField,
  groupArrayByKey,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import ComponentListRenderer from "../../global/ContentBlockRenderSwitch";
import constantsVariable from "../../../utils/constantsVariable";
const Body = (props) => {
  const slug = "ospac";
  const { component_listing_dropdowns, component_accordion_en_path } =
    constantsVariable;

  const [loading, setLoading] = useState(true);
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const [componentList, setComponentList] = useState([]);
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getContentBlocks();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const getContentBlocks = async () => {
    const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));

        const groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        const componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );
        setComponentList(componentArr);

        const groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        setGroupedData(groupedData);
      })
      .catch((error) => {
        setGroupedData({});
        setComponentList([]);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RenderPropertiesGroup = [
    {
      ExcludeTitle: true,
      AdjustmentClassName: `mt-3 mt-lg-0`,
    },
  ];
  const RenderProperties = { AdjustmentClassName: `mb-5` };
  const DefaultCssClass = component_listing_dropdowns?.blueTitleBox;
  return (
    <>
      {/*<!--  Grower Benefits block starts -->*/}
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        {/*Add breadCrumb*/}
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-8 d-none d-lg-block m_l_0_print">
            <Breadcrumb {...props} />
          </div>
        </div>
        {/*End breadCrumb*/}
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <LeftMenu {...props} slug="ospac" />
          </div>
          {/*<!-- Accordion blocks starts -->*/}
          <div className="col-12 col-lg-10">
            <div className="row mt-5 mt-lg-0">
              {/* <div className="col-12">
                <div className="d-flex">
                  {iframdata != null && parse(iframdata)}
                </div>
              </div> */}
              <div className="col-12">
                <ComponentListRenderer
                  ComponentList={componentList}
                  GroupedData={groupedData}
                  RenderPropertiesGroup={RenderPropertiesGroup}
                  DefaultCssClass={DefaultCssClass}
                  RenderProperties={RenderProperties}
                />
                {/* <div className="card bot-mspace-20 Quick_link card-shadow">
                  <h5 className="card-header white_color ver-space-15 text-uppercase">
                    <span className="left-space-5">
                      {currentdata != null &&
                        parse(
                          currentdata?.["Cooperative - OSPAC - Contributions"]
                            ?.component_listings?.[
                            "accordion_name_" + i18n.language
                          ]
                        )}
                    </span>
                  </h5>
                  <div className="card-body">
                    {currentdata != null &&
                      parse(
                        currentdata?.["Cooperative - OSPAC - Contributions"]?.[
                          "content_" + i18n.language
                        ]
                      )}
                  </div>
                </div>
                <div className="card bot-mspace-20 Quick_link card-shadow">
                  <h5 className="card-header white_color ver-space-15 text-uppercase">
                    {" "}
                    <span className="left-space-5">
                      {currentdata != null &&
                        parse(
                          currentdata?.["Cooperative - OSPAC - About"]
                            ?.component_listings?.[
                            "accordion_name_" + i18n.language
                          ]
                        )}{" "}
                    </span>
                  </h5>
                  <div className="card-body">
                    {currentdata != null &&
                      parse(
                        currentdata?.["Cooperative - OSPAC - About"]?.[
                          "content_" + i18n.language
                        ]
                      )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
