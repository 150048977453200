/**
 * @component_Name : ExportFile
 * @description : library for export view
 * @company : Photon
 * @author : Ashish Ranjan/01-05-2022
 *
 */
import React from "react";
import ReactTooltip from "react-tooltip";
import { utils, writeFile } from "xlsx";
import { NavLink } from "react-router-dom";
import { IsArrayNotEmpty } from "../../utils/helper";

const ExportFile = ({
  downloadFormate,
  exportData,
  fileName,
  sheetTitle = "Sheet1",
  showParameter = false,
  parameterData = [],
  getData = null,
  formatArray = null
}) => {
  const handleClickExcel = async (e) => {
    e.stopPropagation();
    if (showParameter) {
      if (IsArrayNotEmpty(parameterData) && IsArrayNotEmpty(exportData)) {
        if(getData){
          exportData = getData();
          if(exportData.length === 0)
              return;
        } 
        const wb = utils.book_new();
        const wsh = utils.json_to_sheet(parameterData, {
          header: ["A", "B"],
          skipHeader: true,
        });

        const ws = utils.sheet_add_json(wsh,exportData, {
          origin: -1,
        });
        if(formatArray !== null){
          let index = formatArray.map(i=>i.index);
          for (let col of index) {
            const format = formatArray.filter(i=>i.index === col)[0]?.format || ''; 
            formatcolumn(ws, col, format)
          }
        }
        
        utils.book_append_sheet(wb, ws, sheetTitle);
        writeFile(wb, fileName + ".xlsx");
      }
    } else {
      if (IsArrayNotEmpty(exportData)) {
        if(getData){
          exportData = await getData();
          if(exportData.length === 0)
              return;
        } 
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(exportData);
        if(formatArray !== null){
          let index = formatArray.map(i=>i.index);
          for (let col of index) {
            const format = formatArray.filter(i=>i.index === col)[0]?.format || ''; 
            formatcolumn(ws, col, format)
          }
        }
        utils.book_append_sheet(wb, ws, sheetTitle);
        writeFile(wb, fileName + ".xlsx");
      }
    }
  };

  const handleClickCsv = (e) => {
    e.stopPropagation();
    if (exportData.length > 0) {
      var wb = utils.book_new(),
        ws = utils.json_to_sheet(exportData);
      utils.book_append_sheet(wb, ws, sheetTitle);
      writeFile(wb, fileName + ".csv");
    }
  };
  const formatcolumn = (worksheet, col, fmt) => {
    const range = utils.decode_range(worksheet['!ref'])
    // note: range.s.r + 1 skips the header row
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = utils.encode_cell({ r: row, c: col })
      if (worksheet[ref] && worksheet[ref].t === 'n' && worksheet[ref].v !== 0) {
        worksheet[ref].z = fmt
      }
    }
  }
  
  return (
    <>
      {downloadFormate === "xlsx" ? (
        <>
          <NavLink
            to="#"
            className="d-inline-block "
            onClick={handleClickExcel}
            data-tip="Export as Excel"
            data-for="main"
            data-iscapture="true"
          >
            {" "}
            <i
              className="fa fa-file-excel-o text-16 border-1 border-radius-6 excelIconPadding position-relative"
              alt="ExcelIcon"
            ></i>
          </NavLink>
          <ReactTooltip
            id="main"
            place="bottom"
            effect="float"
            type="dark"
            multiline={true}
          />
        </>
      ) : (
        <>
          <NavLink
            to="#"
            className="border-1 border-radius-6 csvIconPadding"
            onClick={handleClickCsv}
            data-tip="Export as CSV"
            data-for="main"
            data-iscapture="true"
          >
            <img
              src="/assets/images/icon_csv.svg"
              className="img-17 position-relative"
              alt="DownloadIcon"
            />
          </NavLink>
          <ReactTooltip
            id="main"
            place="bottom"
            effect="float"
            type="dark"
            multiline={true}
          />
        </>
      )}
    </>
  );
};
export default ExportFile;

export const DailySummaryExportFile = ({
  downloadFormate,
  exportData,
  fileName,
  sheetTitle = "Sheet1",
}) => {
  const handleClickExcel = (e) => {
    e.stopPropagation();
    if (IsArrayNotEmpty(exportData)) {
      const wb = utils.book_new();
      const ws = utils.aoa_to_sheet(exportData);
      utils.book_append_sheet(wb, ws, sheetTitle);
      writeFile(wb, fileName + ".xlsx");
    }
  };

  const handleClickCsv = (e) => {
    e.stopPropagation();
    if (IsArrayNotEmpty(exportData)) {
      const wb = utils.book_new();
      const ws = utils.aoa_to_sheet(exportData);
      utils.book_append_sheet(wb, ws, sheetTitle);
      writeFile(wb, fileName + ".csv");
    }
  };

  return (
    <>
      {downloadFormate === "xlsx" ? (
        <>
          <NavLink
            to="#"
            className="d-inline-block "
            onClick={handleClickExcel}
            data-tip="Export as Excel"
            data-for="main"
            data-iscapture="true"
          >
            {" "}
            <i
              className="fa fa-file-excel-o text-16 border-1 border-radius-6 excelIconPadding position-relative"
              alt="ExcelIcon"
            ></i>
          </NavLink>
          <ReactTooltip
            id="main"
            place="bottom"
            effect="float"
            type="dark"
            multiline={true}
          />
        </>
      ) : (
        <>
          <NavLink
            to="#"
            className="border-1 border-radius-6 csvIconPadding"
            onClick={handleClickCsv}
            data-tip="Export as CSV"
            data-for="main"
            data-iscapture="true"
          >
            <img
              src="/assets/images/icon_csv.svg"
              className="img-17 position-relative"
              alt="DownloadIcon"
            />
          </NavLink>
          <ReactTooltip
            id="main"
            place="bottom"
            effect="float"
            type="dark"
            multiline={true}
          />
        </>
      )}
    </>
  );
};
