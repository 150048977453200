import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import {
  IsTokenChecker,
  IsArrayNotEmpty,
  HandleErrorTrackAndToast,
  IsObjectChecker,
} from "../../../utils/helper";
import PollWidgetDetails from "./pollsWidgetDetails";
import moment from "moment";
const PollWidget = (props) => {
  const { slug } = props;
  const { accounts } = useMsal();
  const localAccountId = _.get(accounts, `0.localAccountId`, "");
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const [answered, setAnswered] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pollData, setPollData] = useState({});
  const [pollQuestions, setPollQuestions] = useState([]);

  const ObjDataChecker = (data = {}) => {
    try {
      if (IsObjectChecker(data) && !_.isEmpty(data)) {
        return true;
      }
      return false;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const GetPollData = async (data = {}) => {
    var originalData = { ...data };
    const masterData = _.get(originalData, "poll_questions", []);
    if (IsArrayNotEmpty(masterData)) {
      const PollData = await Promise.all(
        masterData.map(async (threadId, index) => {
          const url = `/poll_questions?id=eq.${threadId}`;
          return await cmsDataCommonMethod(idToken, accounts, url)
            .then((res) => {
              const ArrData = _.get(res, "data.data", []);
              const mainData = _.get(ArrData, "0", {});
              return { ...mainData, custom_order_by: index + 1 };
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "replace", dispatch);
              return false;
            });
        })
      ).catch((e) => {
        HandleErrorTrackAndToast(e, true, "replace", dispatch);
        return [];
      });
      delete originalData["poll_questions"];
      setPollData({ ...originalData });
      const ResultData = PollData.filter((li) => ObjDataChecker(li));
      GetPollQuestions(ResultData);
    } else {
      EmptyMasterData(true, false);
    }
  };

  // useEffect(() => {
  //   console.log({ loading, pollQuestions });
  // }, [loading, pollQuestions]);

  const GetPollQuestions = (poll_questions = []) => {
    if (IsArrayNotEmpty(poll_questions)) {
      poll_questions.forEach(async (element) => {
        const id = _.get(element, "id", null);
        const masterData = _.get(element, "poll_options", []);
        const PollData = await Promise.all(
          masterData.map(async (threadId, index) => {
            const url = `/poll_options?id=eq.${threadId}`;
            return await cmsDataCommonMethod(idToken, accounts, url)
              .then((res) => {
                const ArrData = _.get(res, "data.data", []);
                const mainData = _.get(ArrData, "0", {});
                return { ...mainData, custom_order_by: index + 1 };
              })
              .catch((error) => {
                HandleErrorTrackAndToast(error, true, "replace", dispatch);
                return false;
              });
          })
        ).catch((e) => {
          HandleErrorTrackAndToast(e, true, "replace", dispatch);
          return [];
        });
        const FilterData = PollData.filter((li) => ObjDataChecker(li));
        const ResultData = _.orderBy(FilterData, ["custom_order_by"], ["asc"]);
        setPollQuestions((prev) => {
          var temp = [...prev];
          const index = temp.findIndex((li) => li.id == id);
          if (index > -1) {
            temp.splice(index, 1, { ...element, poll_options: ResultData });
          } else {
            temp.push({ ...element, poll_options: ResultData });
          }
          if(temp.length == poll_questions.length){
            setLoading(false);
          }
          return temp;
        });
      });
      // setLoading(false);
    } else {
      EmptyMasterData(true, false);
    }
  };

  const CheckAlreadyAnswered = async (responseData) => {
    const poll_id = _.get(responseData, "id", null);
    const poll_q = _.get(responseData, "poll_questions", []);
    const reduceQuestions = (answer=[], question=[]) => {
      try {
        if(IsArrayNotEmpty(answer) && IsArrayNotEmpty(question)){
          const result = [...question];
          answer.forEach(list=>{
            const found = result.findIndex(li => li==list);
            if(found > -1){
              result.splice(found, 1);
            }
          })
          return result;
        }
        return answer;
      } catch (e) {
        return answer;
      }
    }
    if (poll_id && IsArrayNotEmpty(poll_q)) {
      const pollEntriesURL = `poll_entries?select=*&is_active=eq.true&poll_id=eq.${poll_id}&user_id=eq.${localAccountId}`;
      await cmsDataCommonMethod(idToken, accounts, pollEntriesURL)
        .then((res) => {
          const respData = _.get(res, "data.data", []);
          if (IsArrayNotEmpty(respData)) {
            const answerQ = respData.map(li => li?.poll_question_id);
            const answers = reduceQuestions(answerQ, poll_q);
            if(IsArrayNotEmpty(answers)){
              const pendingPollData = {...responseData};
              delete pendingPollData["poll_questions"];
              pendingPollData.poll_questions = [...answers];
              setAnswered(false);
              GetPollData(pendingPollData);
            } else {
              EmptyMasterData();
              setAnswered(true);
            }
          } else {
            setAnswered(false);
            GetPollData(responseData);
          }
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
          setAnswered(true);
          EmptyMasterData();
        });
    } else {
      setAnswered(true);
      EmptyMasterData();
    }
  };

  const EmptyMasterData = (child = true, master = true) => {
    if (master && child) {
      setPollData({});
      setPollQuestions([]);
      setLoading(false);
    } else if (master || child) {
      if (master) {
        setPollData({});
      } else {
        setPollQuestions([]);
      }
      setLoading(false);
    }
  };

  const GetPollWidgetData = async () => {
    const today = moment().toISOString();
    const pollFilter = `poll_date_from.lte.${today},poll_date_to.gte.${today}`;
    const pollURL = `polls?select=*,page_listings!inner(*)&page_listings.slug=eq.${slug}&is_active=eq.true&and=(${pollFilter})`;
    await cmsDataCommonMethod(idToken, accounts, pollURL)
      .then((resp) => {
        const responseData = _.get(resp, "data.data.0", {});
        CheckAlreadyAnswered(responseData);
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        EmptyMasterData();
        setAnswered(true);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      setLoading(true);
      GetPollWidgetData();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const OrderedQuestions = !loading
    ? _.orderBy(pollQuestions, ["custom_order_by"], ["asc"])
    : pollQuestions;
  return (
    <PollWidgetDetails
      loading={loading}
      answered={answered}
      poll_data={pollData}
      poll_questions={OrderedQuestions}
      EmptyMasterData={EmptyMasterData}
      {...props}
    />
  );
};

export default PollWidget;
