import React, { useState, useEffect, Fragment } from "react";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import BreadCrumb from "../../global/breadCrumb";
import Loading from "../../global/loading";
import CurrentLocation from "../../global/currentLocation";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { Accordion } from "react-bootstrap";
import {
  lowStr,
  sortByField,
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsStringChecker,
  groupArrayByKey,
  IsObjectChecker,
  IsTokenChecker,
  IsNumberChecker,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import _ from "lodash";
import Carousel from "react-bootstrap/Carousel";
import News from "../../home/news";
import ContentModifier from "../../global/contentModifier";
import { event_order } from "../../../utils/constantsVariable";
import EventTime from "./eventTime";
import EventLocation from "./eventLocation";
import { Link } from "react-router-dom";
import moment from "moment";

const EventsDetail = (props) => {
  const [, moreLangs] = CurrentLocation();

  const [loading, setLoading] = useState(true);
  const [myEvents, setMyEvents] = useState([]);
  const [preCategories, setPreCategories] = useState([]);

  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const regionEventArr = _.get(accounts, "0.idTokenClaims.gbsRegions", []);
  const regionEvent = IsArrayNotEmpty(regionEventArr)
    ? regionEventArr.map((li) => `news_categories.cs.{${li}}`)
    : [];
  const currentISODate = new Date().toISOString();
  const [resultFound, setResultFound] = useState(true);
  const [parentLoading, setParentLoading] = useState(false);

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const [activeTab, setActiveTab] = useState("");
  const [groupedDataByAccordion, setGroupedDataByAccordion] = useState({});
  const [componentList, setComponentList] = useState([]);

  const component_accordion_en_path = "component_listings.accordion_name_en";

  const getLocalImageURL = (
    name,
    extension = "png",
    url = "/assets/images/"
  ) => {
    return `${url}${lowStr(name).replace(/ /g, "_")}.${extension}`;
  };

  const accordionNameBuilder = (accordion = null) => {
    if (accordion) {
      const lowAccordion = lowStr(accordion).replace(/ /g, "_");
      const accordionPath = `${resolvedLanguage}.community_page.${lowAccordion}`;
      return `${_.get(moreLangs, accordionPath, accordion)}`;
    } else {
      return "";
    }
  };

  const altNameBuilder = (accordion = null, modify = true) => {
    const iconPath = `${resolvedLanguage}.community_ag_science_pages.icon`;
    const iconTrailName = "Icon";
    if (modify) {
      if (accordion) {
        const lowAccordion = lowStr(accordion).replace(/ /g, "_");
        const accordionPath = `${resolvedLanguage}.community_page.${lowAccordion}`;
        return `${_.get(moreLangs, accordionPath, accordion)} ${_.get(
          moreLangs,
          iconPath,
          iconTrailName
        )}`;
      } else {
        return "";
      }
    } else {
      if (accordion) {
        return `${accordion} ${_.get(moreLangs, iconPath, iconTrailName)}`;
      } else {
        return "";
      }
    }
  };

  const translationValueProvider = (path = null, alt = "") => {
    try {
      let name = `${_.get(moreLangs, `${resolvedLanguage}.${path}`, alt)}`;
      return IsStringChecker(name) ? name : "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const getCategories = async () => {
    var url = `news_event_categories`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let tempCategories = safeArrayCheck(_.get(resp, "data.data", []));
        let filteredEvents = IsArrayNotEmpty(tempCategories)
          ? tempCategories.filter((li) => {
              if (
                !lowStr(_.get(li, "title_en", "")).includes("news") &&
                !lowStr(_.get(li, "title_en", "")).includes(
                  "employee spotlight"
                )
              ) {
                return li;
              }
            })
          : [];
          let newscategory = sessionStorage.getItem("newscategory");
          if(newscategory !== null){
            if(filteredEvents.filter(i=>i.slug === newscategory).length > 0){
              sessionStorage.setItem("newscategory", newscategory);
            }
            else{
              sessionStorage.setItem("newscategory", 'upcoming-events');
            }
          }
        setPreCategories(safeArrayCheck(filteredEvents));
      })
      .catch((e) => {
        setPreCategories([]);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  };

  const getMyEvents = async () => {
    if (IsArrayNotEmpty(regionEvent)) {
      const url = `news_events?select=id,slug,title_en,title_fr,title_es,event_location,event_date_to,event_date_from,description_es,post_image,url,is_active,news_categories,posting_date,authors(title_en,title_fr,title_es,author_image_url,job_title)&news_categories=cs.{upcoming-events}&or=(${regionEvent})&event_date_to=gte.${currentISODate}&order=${event_order}`;
      await cmsDataCommonMethod(idToken, accounts, url)
        .then((resp) => {
          const tempMyEvents = safeArrayCheck(
            sortByField(_.get(resp, "data.data", []))
          );
          setMyEvents(safeArrayCheck(tempMyEvents));
        })
        .catch((error) => {
          setMyEvents([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    }
  };

  const getContentBlocks = async () => {
    const url = `content_blocks?select=id,content_en,content_fr,content_es,order_by,is_active,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,css_class,show_print_icon,icon_url)&page_listings.slug=eq.agm`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let slugRefinedData = _.get(resp, "data.data", []);
        let groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        let groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        let componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );
        setGroupedDataByAccordion(
          IsObjectChecker(groupedData) ? groupedData : {}
        );
        setComponentList(componentArr);
        setActiveTab(_.get(componentArr, `0.accordion_name_en`, ""));
      })
      .catch((error) => {
        setGroupedDataByAccordion({});
        setComponentList([]);
        setActiveTab("");
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  // removing news sesstion
  if(sessionStorage.getItem("newsfrom") !== "events"){
    sessionStorage.setItem("newsfrom","events");
    sessionStorage.removeItem("newslimit");
    sessionStorage.removeItem("newscategory");      
    sessionStorage.removeItem("newsloadmorelimit");
    sessionStorage.removeItem("selecteddetailpage");
  }

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getCategories();
      getMyEvents();
      getContentBlocks();
      
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  /* Bootstrap Carousal Start*/
  const [carousalIndex, setCarousalIndex] = useState(0);
  const bootstrapSettings = {
    activeIndex: carousalIndex,
    onSelect: (index) => handleCarousalSelect(index),
    id: "carouselExampleIndicators",
    className: "events_slider mb-lg-5",
    interval: 5000,
    slide: true,
    indicators: true,
    touch: true,
    wrap: true,
  };
  const handleCarousalSelect = (selectedIndex) => {
    setCarousalIndex(selectedIndex);
  };
  /* Bootstrap Carousal End*/

  const accessOtherEvents = resultFound && IsArrayNotEmpty(preCategories);
  // console.log({
  //   accessOtherEvents,
  //   resultFound,
  //   preCategories: IsArrayNotEmpty(preCategories),
  // });
  return (
    <>
      {(loading || parentLoading) && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <LeftMenu {...props} slug="news-and-events" />
            <QuickLinks {...props} slug="news-and-events" />
          </div>
          <div className="col-12 col-lg-10 zooming_block">
            <div className="row">
              <div className="col-12">
                <Accordion className="mt-5 mt-lg-0 mb-5 harvest_news">
                  {IsArrayNotEmpty(myEvents) && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <img
                          src={getLocalImageURL("My Events")}
                          className="width-30 right-space-10"
                          alt={altNameBuilder("My Events")}
                        />
                        {accordionNameBuilder("My Events")}
                      </Accordion.Header>
                      <Accordion.Body className="p-1 p-sm-4">
                        {IsArrayNotEmpty(myEvents) ? (
                          <Carousel {...bootstrapSettings}>
                            {myEvents.map((list, index) => {
                              const {
                                post_image = null,
                                event_date_to = null,
                                event_date_from = null,
                                event_location = null,
                                posting_date = null,
                                id = null,
                                slug = null,
                              } = list;
                              const title = _.get(
                                list,
                                `title_${resolvedLanguage}`,
                                ""
                              );
                              const url =
                                IsStringChecker(posting_date) &&
                                IsNumberChecker(id) &&
                                IsStringChecker(slug)
                                  ? `/community/news-events/${moment(
                                      posting_date
                                    ).format("YYYY/MM/DD")}/${id}/${slug}`
                                  : null;
                              return (
                                <Carousel.Item key={index}>
                                  {IsStringChecker(post_image) && (
                                    <img
                                      src={post_image}
                                      className="d-block w-100"
                                      alt={title || ""}
                                      onError={(e) => {
                                        HandleErrorTrackAndToast(e, true);
                                        const { currentTarget } = e;
                                        currentTarget.onerror = null;
                                        currentTarget.src = null;
                                      }}
                                    />
                                  )}
                                  <div className="slider_bottom_text">
                                    <div className="ps-3 mb-3">
                                      {IsStringChecker(title) && (
                                        <h4 className="fw-bold">{title}</h4>
                                      )}
                                      <div className="upcoming_location">
                                        <i className="fa fa-clock-o marker_icon"></i>
                                        <p>
                                          <EventTime
                                            event_date_from={event_date_from}
                                            event_date_to={event_date_to}
                                            locale={true}
                                            timezone={true}
                                          />
                                        </p>
                                      </div>
                                      <div className="upcoming_location">
                                        <i className="fa fa-map-marker marker_icon ms-1"></i>
                                        {IsStringChecker(event_location) && (
                                          <EventLocation
                                            event_location={event_location}
                                          />
                                        )}
                                      </div>
                                      {url && (
                                        <Link
                                          className="space-10 cursor_pointer read_more_click"
                                          to={url}
                                        >
                                          {_.get(
                                            moreLangs,
                                            `${resolvedLanguage}.community_page.view_details`,
                                            "View Details"
                                          )}
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>
                        ) : (
                          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                            <i className="fa fa-info-circle pe-2"></i>
                            {translationValueProvider(
                              "community_page.no_events_msg",
                              "There’s no events in your area at this time"
                            )}
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {IsArrayNotEmpty(componentList) && (
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <img
                          src={getLocalImageURL("AGM")}
                          className="width-30 right-space-5 status_style"
                          alt={altNameBuilder("AGM")}
                        />
                        {accordionNameBuilder("AGM")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <>
                          <ul
                            className="nav nav-pills mb-3 bordered_nav_tabs agm_tabs"
                            id="pills-tab"
                            role="tablist"
                          >
                            {componentList.map((list, index) => {
                              const name_text = _.get(
                                list,
                                `accordion_name_${resolvedLanguage}`,
                                ""
                              );
                              const {
                                component_id = null,
                                accordion_name_en: name = null,
                              } = list;
                              if (IsStringChecker(name)) {
                                return (
                                  <li
                                    className="nav-item"
                                    role="presentation"
                                    key={component_id || index}
                                  >
                                    <button
                                      className={`nav-link ${
                                        activeTab === name ? "active" : ""
                                      }`}
                                      id={`pills-${name}-tab`}
                                      data-bs-toggle="pill"
                                      data-bs-target={`#pills-${name}`}
                                      type="button"
                                      role="tab"
                                      aria-controls={`pills-${name}`}
                                      aria-selected={`${
                                        activeTab === name ? "true" : "false"
                                      }`}
                                      onClick={() => setActiveTab(name)}
                                    >
                                      {name_text}
                                    </button>
                                  </li>
                                );
                              } else {
                                return <Fragment key={component_id || index} />;
                              }
                            })}
                          </ul>
                          <div
                            className="tab-content overflow-hidden"
                            id="pills-tabContent"
                          >
                            {componentList.map((list, index) => {
                              const {
                                component_id = null,
                                accordion_name_en: name = null,
                              } = list;
                              if (IsStringChecker(name)) {
                                const renderArray = _.get(
                                  groupedDataByAccordion,
                                  name,
                                  []
                                );
                                return (
                                  <div
                                    className={`tab-pane fade ${
                                      activeTab === name ? "show active" : ""
                                    }`}
                                    id={`pills-${name}`}
                                    role="tabpanel"
                                    aria-labelledby={`pills-${name}-tab`}
                                    key={component_id || index}
                                  >
                                    {IsArrayNotEmpty(renderArray) &&
                                      renderArray.map((li, ind) => {
                                        const content = _.get(
                                          li,
                                          `content_${resolvedLanguage}`,
                                          ""
                                        );
                                        return (
                                          <div
                                            key={ind}
                                            className="quill-editor ql-container ql-snow"
                                            style={{ height: "auto", border: "none", fontFamily: "unset", fontSize: "14px" }}
                                          >
                                          <ContentModifier
                                            str={content}
                                          />
                                        </div>
                                        );
                                      })}
                                  </div>
                                );
                              } else {
                                return <Fragment key={component_id || index} />;
                              }
                            })}
                          </div>
                        </>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {accessOtherEvents && (
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <img
                          src={getLocalImageURL("Other Events")}
                          className="width-30 right-space-10"
                          alt={altNameBuilder("Other Events")}
                        />
                        {accordionNameBuilder("Other Events")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <News
                          selectedNews={`upcoming-events`}
                          preCategories={preCategories}
                          setParentLoading={setParentLoading}
                          setResultFound={setResultFound}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsDetail;
