import React, { useEffect, useState, Fragment, useRef } from "react";
import BreadCrumb from "../../global/breadCrumb";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Loading from "../../global/loading";
import DataGridPagination from "../../global/dataGridPagination";
import moment from "moment";
import Moment from "react-moment";
import CurrentLocation from "../../global/currentLocation";
import {
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsTokenChecker,
  IsStringChecker,
  safeArrayCheck,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import { Form, FloatingLabel, Accordion, Alert } from "react-bootstrap";
import WhiteCircleLoader from "../../whiteCircleLoader";
import MultiDropDown from "../../selectBox/multiDropDown";
import { SiteUrl } from "../../../utils/constants";
import { DetermineImpersonation } from "../../../permissionWrapper";

const DropoffPageDetail = (props) => {
  const impersonated = DetermineImpersonation();
  const DateFormat = "MM/DD/YYYY";
  const descriptionLimit = 250;
  const fileSizeLimit = 1024 * 1024 * 5;
  const filesAllowed = [
    ".jpg",
    ".jpeg",
    ".png",
    ".pdf",
    ".doc",
    ".docm",
    ".docx",
    ".xls",
    ".xlm",
    ".xlsx",
    ".xlsm",
  ];
  const notifyOSList = [
    {
      id: "GrowerRelations@oceanspray.com",
      name: "GrowerRelations@oceanspray.com",
    },
    {
      id: "GrowerFinance@oceanspray.com",
      name: "GrowerFinance@oceanspray.com",
    },
  ];
  const notifyOS = { notifyOS: "" };
  const notifyError = "Notify OS Team field is required";
  const notifyBodyContent = `${SiteUrl}documents/dropoff-page`;

  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", "");
  // const addedBy = _.get(accounts, "0.idTokenClaims.name", "");
  const [addedBy, setAddedBy] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const dropOffRef = useRef();
  const defaultAlert = { trigger: false, variant: "primary", message: "" };
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [uploadFiles, setUploadFiles] = useState({});
  const [uploadError, setUploadError] = useState(null);
  const [uploadAlert, setUploadAlert] = useState(defaultAlert);

  const [notifyOSArr, setNotifyOSArr] = useState(notifyOS);
  const [notifyOSErr, setNotifyOSErr] = useState("");

  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [miniLoader, setMiniLoader] = useState(false);
  const [clearstatus, setClearStatus] = useState(false);

  const [rowHeight, setRowHeight] = useState(50);

  const columns = [
    {
      key: "AddedBy",
      name: "Added By",
      cellClass: "enablesTextWrapping",
      width: "15%",
      formatter(props) {
        return (
          <>{IsStringChecker(props.row.AddedBy) ? props.row.AddedBy : "-"}</>
        );
      },
    },
    {
      key: "PickupDate",
      name: "Date",
      width: "10%",
      cellClass: "enablesTextWrapping",
      formatter(props) {
        return (
          <>
            {IsStringChecker(props.row.PickupDate) ? (
              <Moment utc format={DateFormat}>
                {props.row.PickupDate}
              </Moment>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      key: "Description",
      name: "Description",
      cellClass: "enablesTextWrapping",
      width: "25%",
      formatter(props) {
        return (
          <>
            {IsStringChecker(props.row.Description)
              ? props.row.Description
              : "-"}
          </>
        );
      },
    },
    {
      key: "FileName",
      name: "Attachment",
      cellClass: "enablesTextWrapping",
      width: "20%",
      formatter(props) {
        return (
          <>
            {IsTokenChecker(
              props.row.FileUrl,
              props.row.AttachmentFriendlyName
            ) ? (
              <span className="blue_color">
                <a href={props.row.FileUrl} target={"_blank"} rel="noreferrer">
                  <i className="fa fa-file-o"></i>
                  {` ${props.row.AttachmentFriendlyName}`}
                </a>
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      key: "NotifyOS",
      name: "Notified OS Team",
      cellClass: "enablesTextWrapping",
      headerCellClass: "enablesTextWrappingHeading",
      width: "30%",
      formatter(props) {
        const JoinedStr = props?.row?.NotifyOS;
        return <>{IsStringChecker(JoinedStr) ? JoinedStr : "-"}</>;
      },
    },
  ];

  const FilesAllowedConstructor = (type, allowed) => {
    try {
      switch (type) {
        case "regex":
          const formatted = allowed.map((li) => `\\${li}`);
          return `(${formatted.join("|")})$`;
        case "error":
          return allowed.join(" | ");
        case "accept":
          return allowed.join(",");
        default:
          return allowed.join(",");
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return allowed.join(",");
    }
  };

  const GetFileValue = (ref) => {
    try {
      if (ref && ref?.current) {
        return ref?.current?.value || null;
      }
      return null;
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return null;
    }
  };

  const SetFileValue = (ref, value) => {
    try {
      if (ref && ref?.current && ref?.current?.value) {
        ref.current.value = value;
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
    }
  };

  const FileValidationLogic = (file) => {
    try {
      // const allowedExtensions = /(\.jpg|\.jpeg|\.pdf)$/i;
      // const allowedExtensions = new RegExp("(\\.jpg|\\.jpeg|\\.pdf)$", "i");
      const allowedExtensions = new RegExp(
        FilesAllowedConstructor("regex", filesAllowed),
        "i"
      );
      const errMsg = `Please upload file having extensions ${FilesAllowedConstructor(
        "error",
        filesAllowed
      )} only.`;
      if (!allowedExtensions.exec(file.name)) {
        return {
          error: true,
          errMsg,
        };
      } else if (file.size > fileSizeLimit) {
        return {
          error: true,
          errMsg: "File should not be more than 5 mb",
        };
      } else {
        return {
          error: false,
          errMsg: null,
        };
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return {
        error: true,
        errMsg: `Please upload file having extensions ${FilesAllowedConstructor(
          "error",
          filesAllowed
        )} only.`,
      };
    }
  };

  const DescriptionValidationLogic = (str = "") => {
    try {
      if (IsStringChecker(str)) {
        const text = str.substring(
          0,
          str.length <= descriptionLimit ? str.length : descriptionLimit
        );
        return {
          text,
          textErr:
            text.length < 250
              ? ""
              : `Maximum ${descriptionLimit} characters are allowed.`,
        };
      }
      return {
        text: "",
        textErr: `File Description is required.`,
      };
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return {
        text: "",
        textErr: `File Description validation error. Please, try again.`,
      };
    }
  };

  const getPlainFileName = (name) => {
    try {
      if (IsStringChecker(name)) {
        const temp = name.split(".");
        temp.pop();
        return temp.join(".");
      } else {
        return name;
      }
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return name;
    }
  };

  function HeightSetter(List) {
    try {
      var descLength = 0,
        listLength = 0;
      List.map((li) => {
        const data = li?.Description;
        const length = IsStringChecker(data) ? data.length : 0;
        descLength = length > descLength ? length : descLength;
        const data2 = li?.NotifyOS;
        const length2 = IsStringChecker(data2) ? data2.length : 0;
        listLength = length2 > listLength ? length2 : listLength;
      });
      const Height = descLength > listLength ? descLength : listLength;
      if (Height > 0) {
        const calc = Math.ceil((Height * 10) / 15);
        return calc > 50 ? calc : 50;
      } else {
        return 50;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      return 50;
    }
  }
  // console.log({ rowHeight });

  const getEntries = async () => {
    const url = `persons/${personId}/dropoff-page-entries`;
    await gbsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let tempForms = safeArrayCheck(_.get(resp, "data", []));
        // console.log({ tempForms });
        let sortedForms = tempForms.sort(
          (a, b) => moment(b.PickupDate) - moment(a.PickupDate)
        );
        let tempEntries = IsArrayNotEmpty(sortedForms)
          ? sortedForms.map((li, i) => {
              const strArrange = IsStringChecker(li?.NotifyOS)
                ? `${li?.NotifyOS}`
                : null;
              const NotifyOS = IsStringChecker(strArrange)
                ? strArrange.split(",").join(",\n")
                : null;
              return {
                ...li,
                id: i,
                NotifyOS,
              };
            })
          : [];
        const Height = HeightSetter(tempEntries);
        setRowHeight(Height);
        setEntries(tempEntries);
      })
      .catch((error) => {
        setEntries([]);
        setRowHeight(50);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const getProfile = async () => {
    setLoading(true);
    await gbsDataCommonMethod(idToken, accounts, `persons/${personId}`)
      .then((resp) => {
        const Data = resp?.data ? resp?.data[0] : {};
        const userDetails = { ...Data };
        // console.log({ userDetails });

        const entireNameArr = [];
        IsStringChecker(userDetails?.FirstName) &&
          entireNameArr.push(userDetails?.FirstName);
        IsStringChecker(userDetails?.MiddleName) &&
          entireNameArr.push(userDetails?.MiddleName);
        IsStringChecker(userDetails?.LastName) &&
          entireNameArr.push(userDetails?.LastName);
        const entireName = entireNameArr.join(" ");

        setFirstName(
          IsStringChecker(userDetails?.FirstName) ? userDetails?.FirstName : ""
        );
        setLastName(
          IsStringChecker(userDetails?.LastName) ? userDetails?.LastName : ""
        );
        setAddedBy(IsStringChecker(entireName) ? entireName : "");
        getEntries();
      })
      .catch((error) => {
        setEntries([]);
        setFirstName("");
        setLastName("");
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(idToken) && personId) {
      getProfile();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken, personId]);

  const onDropOffDocumentsChange = (e) => {
    const file = e?.target?.files[0];
    // console.log({ file });
    const validate = FileValidationLogic(file);
    const { error, errMsg } = validate;
    if (error) {
      setUploadError(errMsg);
      SetFileValue(dropOffRef, "");
      setUploadFiles({});
    } else {
      setUploadFiles(file);
      setUploadError(errMsg);
    }
  };

  const onChange = (e) => {
    const { target } = e || {};
    const { value = "" } = target || {};
    const { text, textErr } = DescriptionValidationLogic(value);
    setDescription(text);
    setDescriptionError(textErr);
  };

  const GBSUploadFile = async (requestFormData = {}) => {
    const url = `persons/dropoff-page`;
    await gbsDataCommonMethod(idToken, accounts, url, "POST", requestFormData, {
      "Content-type": "multipart/form-data",
    })
      .then((resp) => {
        // console.log({ resp });
        if (resp?.data?.status == "success") {
          onClearButton();
          setUploadAlert({
            trigger: true,
            variant: "success",
            message: "File uploaded successfully",
          });
          getEntries();
        } else {
          setUploadAlert({
            trigger: true,
            variant: "danger",
            message: "Unable to upload file. Please, try after sometime",
          });
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        setUploadAlert({
          trigger: true,
          variant: "danger",
          message: "Unable to upload file. Please, try after sometime",
        });
      })
      .finally(() => {
        setMiniLoader(false);
        setTimeout(() => {
          setUploadAlert(defaultAlert);
        }, 10000);
      });
  };

  const onDropOffUpload = () => {
    const validate = FileValidationLogic(uploadFiles);
    const { error, errMsg } = validate;
    const { notifyOS: os_email = "" } = notifyOSArr || {};
    if (error) {
      setUploadError(errMsg);
    } else {
      setUploadError(errMsg);
      const { textErr } = DescriptionValidationLogic(description);
      if (IsStringChecker(textErr)) {
        setDescriptionError(textErr);
      } else {
        setDescriptionError("");
        const data = IsStringChecker(os_email) ? os_email.split(",") : [];
        if (IsArrayNotEmpty(data)) {
          setNotifyOSErr("");
          if (
            personId &&
            IsStringChecker(addedBy) &&
            IsStringChecker(firstName) &&
            IsStringChecker(lastName)
          ) {
            setMiniLoader(true);
            const formData = new FormData();
            formData.append("personId", personId);
            formData.append("addedBy", addedBy);
            formData.append("firstName", firstName);
            formData.append("lastName", lastName);
            formData.append("description", description);
            formData.append("siteURL", notifyBodyContent);
            formData.append("fileName", getPlainFileName(uploadFiles?.name));
            formData.append("uploaded_file", uploadFiles);
            const structData = data.map((li) => {
              return {
                email_address: li,
              };
            });
            formData.set("data", JSON.stringify(structData));
            GBSUploadFile(formData);
          } else {
            setUploadAlert({
              trigger: true,
              variant: "danger",
              message:
                "Unable to upload file. Some user details are missing. Please, try after sometime",
            });
          }
        } else {
          setNotifyOSErr(notifyError);
        }
      }
    }
  };

  const MultiDropDownFix = () => {
    setClearStatus(true);
    setTimeout(() => {
      setClearStatus(false);
    }, 0);
  };

  const onClearButton = () => {
    SetFileValue(dropOffRef, "");
    setUploadFiles({});
    setUploadError(null);
    setDescription("");
    setUploadError("");
    setDescriptionError("");
    setUploadAlert(defaultAlert);
    setNotifyOSArr(notifyOS);
    setNotifyOSErr("");
    MultiDropDownFix();
  };

  const handlerSelectOptions = (val, type, removedItem = "") => {
    // console.log({ val, type, removedItem });
    if (type === "selected") {
      setNotifyOSErr("");
    } else {
      if (removedItem?.id === "all" || val.length <= 0) {
        setNotifyOSErr(notifyError);
      } else {
        setNotifyOSErr("");
      }
    }
  };

  const value = GetFileValue(dropOffRef);
  const GetClearButtonStatus = () => {
    if (
      value ||
      description ||
      uploadError ||
      notifyOSArr?.notifyOS ||
      notifyOSErr
    ) {
      return true;
    } else {
      return false;
    }
  };
  const GetSubmitButtonStatus = () => {
    if (
      !value ||
      !IsStringChecker(description) ||
      miniLoader ||
      !notifyOSArr?.notifyOS
    ) {
      return true;
    } else {
      return false;
    }
  };
  const enableClear = GetClearButtonStatus();
  const disableSubmit = GetSubmitButtonStatus();
  const { trigger, variant, message } = uploadAlert;
  // const trigger = true, variant = "primary", message="TESTING ALERT SIZE";
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="col-12">
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-4">
              <h3 className="mob-fw-bold py-3">
                {_.get(
                  moreLangs,
                  `${resolvedLanguage}.home_page.dropoff_upload_title`,
                  "Drop Off Page - Upload a File"
                )}
                <span className="drop-offinfo">(All fields are required)</span>
              </h3>
              <div className="row mb-2">
                <div className="col">
                  {/* <Form.Group controlId="formFile" className="mb-3"> */}
                  <Form.Group
                  // controlId="formFile"
                  >
                    <Form.Control
                      type="file"
                      name="dropOff-Documents"
                      id="dropOff-Documents"
                      ref={dropOffRef}
                      onChange={onDropOffDocumentsChange}
                      required
                      multiple={false}
                      accept={FilesAllowedConstructor("accept", filesAllowed)}
                      style={{ width: "100%" }}
                      maxLength={descriptionLimit}
                      minLength={1}
                    />
                  </Form.Group>
                  {uploadError && (
                    <p className="red_dark_color position-relative d-inline-block pt-3 mb-0 t_5 text-capitalize">
                      {uploadError}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <FloatingLabel
                    // controlId="floatingTextarea2"
                    label="File Description"
                  >
                    <Form.Control
                      as="textarea"
                      // placeholder="Leave a comment here (Optional)"
                      placeholder="Leave a description for the file here"
                      style={{ height: "100px" }}
                      name="description"
                      value={description}
                      onChange={onChange}
                      required
                    />
                  </FloatingLabel>
                  {descriptionError && (
                    <p className="red_dark_color position-relative d-inline-block pt-3 mb-0 t_5 text-capitalize">
                      {descriptionError}
                    </p>
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col">
                  <div className="os_notify_label custom_dropdown mb-2 mt-2">
                    {!clearstatus && (
                      <MultiDropDown
                        optionList={notifyOSList}
                        prompt="Select"
                        value={notifyOSArr}
                        onChange={(val) =>
                          handlerSelectOptions(val, "selected")
                        }
                        label="notifyOS"
                        usestate={setNotifyOSArr}
                        disabled=""
                        onDelete={(val, removedItem) =>
                          handlerSelectOptions(val, "deSelected", removedItem)
                        }
                        optionNotFound={{ notifyOS: "No data found" }}
                      />
                    )}
                    {notifyOSErr && (
                      <p className="red_dark_color position-relative d-inline-block pt-3 mb-0 t_5 text-capitalize">
                        {notifyOSErr}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {trigger && (
                    <Alert
                      variant={variant}
                      onClose={() => setUploadAlert(defaultAlert)}
                      dismissible
                    >
                      <b>{message}</b>
                    </Alert>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <button
                    className="btn btn-default text-16 me-2 cursor-for-btn"
                    onClick={onClearButton}
                    style={{ boxShadow: `0 0 5px #ccc` }}
                    disabled={impersonated ? impersonated : !enableClear}
                  >
                    {_.get(
                      moreLangs,
                      `${resolvedLanguage}.button_text.clear`,
                      "Clear"
                    )}
                  </button>
                  <button
                    className="btn btn-primary text-16 cursor-for-btn"
                    onClick={onDropOffUpload}
                    disabled={impersonated ? impersonated : disableSubmit}
                  >
                    {_.get(
                      moreLangs,
                      `${resolvedLanguage}.home_page.submit`,
                      "Submit"
                    )}{" "}
                    {miniLoader ? <WhiteCircleLoader /> : ""}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="white_bg p-3 p-lg-4 border-radius-6 mb-4">
              <h3 className="mob-fw-bold py-3">
                {_.get(
                  moreLangs,
                  `${resolvedLanguage}.home_page.dropoff_upload_list_title`,
                  "Drop Off Page - Uploaded Files"
                )}
              </h3>
              <div className="d-none d-lg-block">
                {!displayAccordian &&
                  (IsArrayNotEmpty(entries) ? (
                    <div className="visible_print_mob_desktop hor-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block">
                      <DataGridPagination
                        uniqueIdentifier="id"
                        gridData={entries}
                        columns={columns}
                        isPagination={true}
                        itemsPerPage={10}
                        columnResize={false}
                        classNames="contain-block-size-reset top-mspace-20 bot-mspace-20 border-radius-6"
                        dynamicHeight={rowHeight}
                      />
                    </div>
                  ) : (
                    <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                      <i className="fa fa-info-circle pe-2"></i>
                      {`${_.get(
                        moreLangs,
                        `${resolvedLanguage}.noRecordErrorMsg`,
                        "Record not found"
                      )}`}
                    </div>
                  ))}
              </div>
              <div
                className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${
                  !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
                }`}
              >
                <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                  <Accordion defaultActiveKey="0">
                    {IsArrayNotEmpty(entries) ? (
                      entries.map((data, index) => {
                        const {
                          id = null,
                          AttachmentFriendlyName = null,
                          Description = null,
                          AddedBy = null,
                          PickupDate = null,
                          FileUrl = null,
                          NotifyOS: OS_Notify_Email = null,
                        } = data;
                        return (
                          <Fragment key={id}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                <a className="width-85 d-block grey_text_color">
                                  {`Attachment: `}
                                  <i className="fa fa-file-o"></i>
                                  {` ${AttachmentFriendlyName}, Date: `}
                                  <Moment utc format={DateFormat}>
                                    {PickupDate}
                                  </Moment>
                                </a>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="tbody">
                                  <div className="tr">
                                    <div className="td"></div>
                                    {IsStringChecker(AddedBy) && (
                                      <div
                                        className="td"
                                        data-header="Added By:"
                                      >
                                        <span>{AddedBy}</span>
                                      </div>
                                    )}
                                    {IsStringChecker(PickupDate) && (
                                      <div className="td" data-header="Date:">
                                        <span>
                                          <Moment utc format={DateFormat}>
                                            {PickupDate}
                                          </Moment>
                                        </span>
                                      </div>
                                    )}
                                    {IsTokenChecker(Description) && (
                                      <div
                                        className="td"
                                        data-header="Description:"
                                      >
                                        <span>{Description}</span>
                                      </div>
                                    )}
                                    {IsTokenChecker(
                                      FileUrl,
                                      AttachmentFriendlyName
                                    ) && (
                                      <div
                                        className="td"
                                        data-header="Attachment:"
                                      >
                                        <span className="blue_color">
                                          <a
                                            href={FileUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <i className="fa fa-file-o"></i>
                                            {` ${AttachmentFriendlyName}`}
                                          </a>
                                        </span>
                                      </div>
                                    )}
                                    {IsStringChecker(OS_Notify_Email) && (
                                      <div
                                        className="td"
                                        data-header="Notified OS Team:"
                                        style={{
                                          overflow: "hidden",
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        <span>{OS_Notify_Email}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Fragment>
                        );
                      })
                    ) : (
                      <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
                        <i className="fa fa-info-circle pe-2"></i>
                        {`${_.get(
                          moreLangs,
                          `${resolvedLanguage}.noRecordErrorMsg`,
                          "Record not found"
                        )}`}
                      </div>
                    )}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DropoffPageDetail;
