import React, { useEffect, useState } from "react";
import { gbsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import {
  IsTokenChecker,
  toDecimal,
  HandleErrorTrackAndToast,
  compareString,
  IsArrayNotEmpty,
  NumConverter,
} from "../../utils/helper";
import CurrentLocation from "./currentLocation";
import { useTranslation } from "react-i18next";
import Graph from "../global/newgraph";
import useActiveCropYear from "../customHooks/useActiveCropYear";
import moment from "moment";
import { useDispatch } from "react-redux";
import { ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../permissionWrapper";

const HarvestGraph = (props) => {
  const { category, height, width, CommonPageCBRoles = [] } = props;
  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  // const gbsContractIds = accounts[0].idTokenClaims.gbsContractIds.toString();

  const state = useActiveCropYear();
  const { activeCropYear: year = null, reducerToken: tokenId = null } = state;

  const CompDateFormat = (date) => {
    try {
      return moment.utc(date).format("DD/MM/YYYY");
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return null;
    }
  };
  const GetWeekData = (noOfDays = 1, start = null) => {
    try {
      const currentDay = start
        ? moment.utc(start).endOf("day")
        : moment.utc().endOf("day");
      const returnData = [];
      for (let i = 0; i < noOfDays; i++) {
        if (i === 0) {
          returnData.push({ DisplayTime: currentDay });
        } else {
          const calcDay = start
            ? moment.utc(start).subtract(i, "days").endOf("day")
            : moment.utc().subtract(i, "days").endOf("day");
          returnData.push({ DisplayTime: calcDay });
        }
      }
      return returnData;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return [];
    }
  };
  const initialData = GetWeekData(7);

  const [DayWiseGraph, setDayWiseGraph] = useState([]);
  const [YearWiseGraph, setYearWiseGraph] = useState([]);
  const [lable, setLable] = useState(
    compareString(category, "daily") ? "Daily" : "Yearly"
  );
  const [total, setTotal] = useState(0);

  const ComputeDailyData = (data = []) => {
    try {
      if (IsArrayNotEmpty(data)) {
        let dailyData = [...data].reverse();
        const ChartData = [...initialData];
        return ChartData.map((list) => {
          const APIData = dailyData.find((li) =>
            compareString(
              CompDateFormat(li?.IncomingTime),
              CompDateFormat(list?.DisplayTime)
            )
          );
          if (APIData) {
            return { ...APIData, DisplayTime: list?.DisplayTime };
          } else {
            return {
              IncomingTime: list?.DisplayTime,
              DailyAveragePercentPoor: null,
              DailyAveragePercentClass1: null,
              TotalBarrels: null,
              DisplayTime: list?.DisplayTime,
            };
          }
        }).reverse();
      }
      return initialData;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return initialData;
    }
  };

  const ComputeYearlyData = (data = []) => {
    try {
      if (IsArrayNotEmpty(data)) {
        let yearlyData = [...data].reverse();
        const ChartData = [...initialData];
        return ChartData.map((list) => {
          const APIindex = yearlyData.findIndex((li) =>
            compareString(
              CompDateFormat(li?.IncomingTime),
              CompDateFormat(list?.DisplayTime)
            )
          );
          if (APIindex >= 0) {
            const APIData = yearlyData.splice(APIindex, 1);
            return { ...APIData[0], DisplayTime: list?.DisplayTime };
          } else {
            const APIData = yearlyData[0];
            return {
              ...APIData,
              DisplayTime: list?.DisplayTime,
            };
          }
        }).reverse();
      }
      return initialData;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return initialData;
    }
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId) && year) {
      const filteredContracts = ValidGBSRoleByContracts(
        accounts,
        CommonPageCBRoles,
        true,
        false
      );
      if (IsArrayNotEmpty(filteredContracts)) {
        const apiUrl = `deliveries/calculations/${
          compareString(category, "daily") ? "daily" : "ytd"
        }-avg-weightings?cropyear=${year}&contractid=${filteredContracts}`;
        const fetchGraphData = async () => {
          await gbsDataCommonMethod(tokenId, accounts, apiUrl)
            .then((resp) => {
              if (IsArrayNotEmpty(resp?.data)) {
                const result =
                  resp.data.length <= 365
                    ? resp.data
                    : resp.data.slice(resp.data.length - 365, resp.data.length);
                if (compareString(category, "daily")) {
                  let totalBarrels = 0;
                  const finalData = ComputeDailyData(result);
                  // console.log({ Daily: finalData });
                  finalData.map((li) => {
                    totalBarrels =
                      totalBarrels + NumConverter(li?.TotalBarrels);
                  });
                  setDayWiseGraph(finalData);
                  setTotal(
                    totalBarrels ? toDecimal(NumConverter(totalBarrels), 2) : 0
                  );
                  setLable("Daily");
                } else if (compareString(category, "year")) {
                  const finalData = ComputeYearlyData(result);
                  // console.log({ Year: finalData });
                  const temp = finalData[finalData.length - 1]?.TotalBarrels;
                  setYearWiseGraph(finalData);
                  setTotal(temp ? toDecimal(NumConverter(temp), 2) : 0);
                  setLable("Yearly");
                }
              }
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            });
        };
        fetchGraphData();
      }
    }
  }, [tokenId, year]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const DisplayData = (ChartData = []) => {
    try {
      const labels = [];
      const percentPoor = [];
      const percentClass = [];
      const totaldayBarrels = [];
      if (IsArrayNotEmpty(ChartData)) {
        Object.values(ChartData).map((singleData) => {
          const month = moment.utc(singleData.DisplayTime).format("MM");
          const dt = moment.utc(singleData.DisplayTime).format("D");

          labels.push(month && dt ? `${month}/${dt}` : null);
          percentPoor.push(singleData?.DailyAveragePercentPoor || null);
          percentClass.push(singleData?.DailyAveragePercentClass1 || null);
          totaldayBarrels.push(singleData?.TotalBarrels || null);
        });
        return {
          labels,
          percentClass,
          percentPoor,
          totaldayBarrels,
        };
      }
      return {
        labels,
        percentClass,
        percentPoor,
        totaldayBarrels,
      };
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return {
        labels: [],
        percentClass: [],
        percentPoor: [],
        totaldayBarrels: [],
      };
    }
  };

  const {
    labels = [],
    percentPoor = [],
    percentClass = [],
    totaldayBarrels = [],
  } = DisplayData(compareString(lable, "Daily") ? DayWiseGraph : YearWiseGraph);
  return (
    <>
      <div
        style={{ width: width, height: `${height}px` }}
        className="width_height_graph"
      >
        {/* <div className="row horizontal-line mb-2">
          <div className="col-7">
            <h4 className="card-title fw-bold">
              {compareString(lable, "Daily")
                ? moreLangs[i18n.language]?.home_page?.total_barrel_by_day
                : moreLangs[i18n.language]?.home_page?.total_barrel_by_year}
            </h4>
          </div>
          <div className="col-5">
            <h6 className="text-start">
              {`${
                moreLangs[i18n.language]?.home_page?.total_barrels_delivered
              }: `}
              <span className="fw-bold">{total}</span>
            </h6>
          </div>
        </div> */}
        <div className="quickview-header mb-3">
          <div className="quickview-title-chart">
            {compareString(lable, "Daily")
              ? moreLangs[i18n.language]?.home_page?.total_barrel_by_day_new
              : moreLangs[i18n.language]?.home_page?.total_barrel_by_year_new}
          </div>
          <div className="quickview-subtitle-chart">
            {`${
              moreLangs[i18n.language]?.home_page?.total_barrels_delivered_new
            }: `}
            <span>{total}</span>
          </div>
        </div>
        <Graph
          lable={lable}
          labels={labels}
          percentPoor={percentPoor}
          percentClass={percentClass}
          totaldayBarrels={totaldayBarrels}
        />
      </div>
    </>
  );
};

export default HarvestGraph;
