import React, { useEffect, useState, Fragment } from "react";
import QuickLinks from "../../home/quickLinks";
import LeftMenu from "../../global/leftMenu";
import BreadCrumb from "../../global/breadCrumb";
import Loading from "../../global/loading";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  sortByField,
  IsArrayNotEmpty,
  getResolvedLanguage,
  groupArrayByKey,
  IsTokenChecker,
  IsObjectChecker,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import constantsVariable from "../../../utils/constantsVariable";
import ComponentListRenderer, {
  ContentAndGridComponentListRenderer,
} from "../../global/ContentBlockRenderSwitch";

const SustainabilityDetails = (prop) => {
  const slug = "sustainability";

  const [allData, setAllData] = useState(null);
  const [componentList, setComponentList] = useState([]);

  const [individualData, setIndividualData] = useState([]);
  const [individualComponentList, setIndividualComponentList] = useState([]);

  const { component_accordion_en_path, component_listing_dropdowns } =
    constantsVariable;

  const [loading, setLoading] = useState(false);

  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const getDDContractPerformMultiContract = async (
    idToken,
    accounts,
    urlArray
  ) => {
    var resp = await Promise.all(
      urlArray.map(async (url) => {
        return await cmsDataCommonMethod(idToken, accounts, url)
          .then((res) => res.data)
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    if (resp.length > 0) {
      const adata = _.get(resp, "0.data", []),
        bdata = _.get(resp, "1.data", []);

      // console.log({ adata, bdata });

      const newData = adata.map((li) => {
        try {
          const adataid = _.get(li, "component_listings.id", "adataid");
          const compData = bdata.find((cli) => {
            const bdataid = _.get(cli, "component_listings.id", "bdataid");
            return adataid == bdataid;
          });
          let newLi = { ...li };
          _.set(newLi, "component_listings.content_block", compData || {});
          return newLi;
        } catch (e) {
          HandleErrorTrackAndToast(e, true);
          return li;
        }
      });

      const indData = bdata.filter((li) => {
        const bdataid = _.get(li, "component_listings.id", "bdataid");
        const actData = adata.find((ali) => {
          const adataid = _.get(ali, "component_listings.id", "adataid");
          return adataid == bdataid;
        });
        return actData === undefined;
      });
      return { newData, indData };
    }
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      fetchSustainabilityDocuments();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const GetGroupedData = (tempData = []) => {
    return groupArrayByKey(tempData, component_accordion_en_path);
  };

  const GetComponentArr = (tempData = []) => {
    let groupData = safeArrayCheck(
      tempData.filter((a, b, c) => UniqueObjectArray(a, b, c))
    );
    return sortByField(
      groupData.map((list) => _.get(list, "component_listings", {}))
    );
  };

  const fetchSustainabilityDocuments = async () => {
    const fetchApiUrl = [];
    // const url = `call_to_actions?select=*,page_listings!inner(slug),component_listings(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon)&page_listings.slug=eq.${slug}&order=order_by.asc`;
    // const action_url = `call_to_actions?select=*,component_listings!inner(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by),page_listings!inner(id,title_en,slug)&page_listings.slug=eq.${slug}`;

    const action_url = `call_to_actions?select=*,page_listings!inner(slug),component_listings(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon)&page_listings.slug=eq.${slug}&order=order_by.asc`;
    const block_url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;

    fetchApiUrl.push(action_url);
    fetchApiUrl.push(block_url);

    if (IsTokenChecker(idToken) && fetchApiUrl != "") {
      setLoading(true);
      await getDDContractPerformMultiContract(idToken, accounts, fetchApiUrl)
        .then((resp) => {
          const { newData = [], indData = [] } = resp;

          let tempData = GetGroupedData(newData);
          setAllData(IsObjectChecker(tempData) ? tempData : {});
          setComponentList(GetComponentArr(newData));

          let tempData2 = GetGroupedData(indData);
          setIndividualData(IsObjectChecker(tempData2) ? tempData2 : {});
          setIndividualComponentList(GetComponentArr(indData));
        })
        .catch((error) => {
          setAllData([]);
          setComponentList([]);
          setIndividualData([]);
          setIndividualComponentList([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const GridListing = (props) => {
    const { data = [] } = props;
    return IsArrayNotEmpty(data) ? (
      <>
        {data.map((item, index) => {
          const img = _.get(item, ["feature_image_url"], "");
          const url = _.get(item, ["url"], "");
          const title = _.get(item, ["title_" + resolvedLanguage], "");
          const button = _.get(item, ["button_text_" + resolvedLanguage], "");
          const open_new_window = _.get(item, ["open_new_window"], false);
          // const new_window = button === "" ? false : open_new_window;
          const internal_url = `${window.location.pathname}${url}`;
          return (
            <div className="col-12 col-lg-4" key={index}>
              <div className="card card-shadow mb-3 mb-lg-5">
                {img && <img src={img} className="card-img-top" alt="..." />}
                <div className="card-body pt-0 px-0">
                  <div className="row">
                    <div className="col-11 ms-1">
                      <ul className="list-group text-center">
                        <li className="list-group-item border-0 pt-4">
                          <Link
                            to={
                              open_new_window ? { pathname: url } : internal_url
                            }
                            target={open_new_window ? "_blank" : "_self"}
                            className="text-18 d-block "
                          >
                            {" "}
                            {title}{" "}
                          </Link>
                          <div className={"mt-2"}>
                            <Link
                              to={
                                open_new_window
                                  ? { pathname: url }
                                  : internal_url
                              }
                              target={open_new_window ? "_blank" : "_self"}
                              className={`
                                ${
                                  button
                                    ? "btn btn-primary"
                                    : "red_color d-block"
                                }
                                  ${img !== null ? "mt-" : ""} `}
                            >
                              {button && <Fragment>{button}</Fragment>}
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    ) : (
      <></>
    );
  };

  const RenderPropertiesGroup = [
    { AdjustmentClassName: `mt-5 mt-lg-0 mb-3 mb-lg-5` },
  ];
  const RenderProperties = { AdjustmentClassName: `mt-5 mt-lg-0` };
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 offset-lg-2 col-lg-10 d-none d-lg-block hidden_print">
            <BreadCrumb {...prop} />
          </div>
        </div>
        <div className="row">
          <div className="d-none d-lg-block col-lg-2 hidden_print">
            <LeftMenu {...prop} slug={slug} />
            <QuickLinks {...prop} slug={slug} />
          </div>
          <div className="col-12 col-lg-10">
            <div className="row">
              <div className="col-12">
                <ComponentListRenderer
                  ComponentList={individualComponentList}
                  GroupedData={individualData}
                  RenderPropertiesGroup={RenderPropertiesGroup}
                  DefaultCssClass={component_listing_dropdowns?.blueTitleBox}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ContentAndGridComponentListRenderer
                  ContentPath={"content_block.content_"}
                  ComponentList={componentList}
                  GroupedData={allData}
                  RenderProperties={RenderProperties}
                  DefaultCssClass={component_listing_dropdowns?.plainText}
                  CallBackContent={GridListing}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SustainabilityDetails;
