/**
 * @component_Name : GoverningDocDetails
 * @description : Governing Doc Details
 * @company : Photon
 * @author : Ashish Ranjan/28-10-2022
 **/
import React, { Fragment, useEffect, useState } from "react";
import "../../../css/loading.css";
import Loading from "../../global/loading";
import { cmsDataCommonMethod } from "../../../services/gbsData";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import _ from "lodash";
import { Link } from "react-router-dom";

const GoverningDocumentsDetails = () => {
  const [loader, setLoader] = useState(true);
  const [allData, setAllData] = useState(null);

  const { accounts } = useMsal();
  const tokenId = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const sequenceCallToAction = (ctData, allData) => {
    const getdata = ctData?.["data"].filter(
      (item2) => allData.component_listings.id == item2.component_listing_id
    );
    let newArray = {};
    getdata.map((x, index) => {
      newArray[x.order_by + "" + index] = { ...x };
    });
    return newArray;
  };

  const getDDContractPerformMultiContract = async (
    tokenId,
    accounts,
    urlArray
  ) => {
    var resp = await Promise.all(
      urlArray.map(async (url) => {
        return await cmsDataCommonMethod(tokenId, accounts, url)
          .then((res) => res.data)
          .catch((error) => {
            HandleErrorTrackAndToast(error, true, "replace", dispatch);
          });
      })
    );
    if (resp.length > 0) {
      let newData = {};
      resp[0]["data"].map(
        (x, index) =>
          (newData[x.order_by + "" + index] = {
            ...x,
            ["call_to_actions"]: sequenceCallToAction(resp?.[1], x),
          })
      );
      return newData;
    }
  };

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      fetchGoverningDocuments();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  const fetchGoverningDocuments = async () => {
    const fetchApiUrl = [];
    fetchApiUrl.push(
      `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.governing-documents`
    );
    fetchApiUrl.push(
      `call_to_actions?select=*,page_listings!inner(slug)&page_listings.slug=eq.governing-documents&order=order_by.asc`
    );
    if (IsTokenChecker(tokenId) && fetchApiUrl != "") {
      await getDDContractPerformMultiContract(tokenId, accounts, fetchApiUrl)
        .then((resp) => {
          setAllData({ ...resp });
        })
        .catch((error) => {
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };
  //console.log("allData",allData);
  return (
    <>
      {/*<!--  Grower Benefits block starts -->*/}
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {allData != null && (
        <>
          {Object.values(allData)?.map((item, index) => {
            const accordion_name = _.get(
              item,
              ["component_listings", "accordion_name_" + i18n.language],
              ""
            );
            const content = _.get(item, ["content_" + i18n.language], "");
            return (
              <Fragment key={index}>
                <div className="row mt-5 mt-lg-0">
                  <h4 className="fw-bold my-2">
                    {accordion_name != "" ? parse(accordion_name) : ""}
                  </h4>
                  {content != "" ? parse(content) : ""}
                  <GridListing data={item} />
                </div>
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
export default GoverningDocumentsDetails;

const GridListing = ({ data = null }) => {
  const { i18n } = useTranslation();
  return (
    <>
      {data != null &&
        Object.values(data?.call_to_actions)?.map((item, index) => {
          const img = _.get(item, ["feature_image_url"], "");
          const url = _.get(item, ["url"], "");
          const title = _.get(item, ["title_" + i18n.language], "");
          const button = _.get(item, ["button_text_" + i18n.language], "");
          const open_new_window = _.get(item, ["open_new_window"], false);
          const new_window = button ? false : open_new_window;
          return (
            <Fragment key={index}>
              <div className="col-12 col-lg-4">
                <div className="card card-shadow mb-3 mb-lg-5">
                  <img src={img} className="card-img-top" alt="..." />
                  <div className="card-body pt-0 px-0">
                    <div className="row">
                      <div className="col-11 ms-1">
                        <ul className="list-group text-center">
                          <li className="list-group-item border-0 pt-4">
                            <Link
                              to={new_window ? { pathname: url } : url}
                              target={new_window ? "_blank" : "_self"}
                              className="text-18 d-block min-height-55px"
                            >
                              {" "}
                              {title}{" "}
                            </Link>
                            <div className="mt-3">
                              <Link
                                to={new_window ? { pathname: url } : url}
                                target={new_window ? "_blank" : "_self"}
                                className={
                                  button
                                    ? "btn btn-primary"
                                    : "red_color m-3 d-block"
                                }
                              >
                                {button ? (
                                  <Fragment>{button}</Fragment>
                                ) : (
                                  <Fragment>
                                    <i className="fa fa-file-o"></i>
                                    {` PDF`}
                                  </Fragment>
                                )}
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
    </>
  );
};
