import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import Moment from "react-moment";
import { ConstantsVar } from "../../../utils/constants";
import ImageView from "../../global/imageView";
import { IsArrayNotEmpty, IsStringChecker, HandleErrorTrackAndToast } from "../../../utils/helper";
const RecentDeliveryMobile = (props) => {
  const {
    RoleCBPrivileges = {},
    listingData,
    harvestArray,
    imgData = [],
  } = props;
  const { roleCBPayment = false } = RoleCBPrivileges;
  const {
    defaultEventImageURL,
    defaultLoadingImageURL,
    dateFormate,
    timeFormate,
  } = ConstantsVar;

  const imgOptimizer = (harvestImage = null) => {
    try {
      if (harvestImage) {
        return harvestImage.size <= 215 ? defaultEventImageURL : harvestImage;
      }
      return defaultEventImageURL;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return defaultEventImageURL;
    }
  };

  const pagingArray = Object.keys(listingData).map((x) => x);
  const listLength = pagingArray.length;
  return (
    <>
      <Accordion defaultActiveKey="0">
        {listLength > 0 &&
          Object.keys(listingData).map((data, index) => {
            const LDTicketNumber = listingData[data].LoadDeliveryTicketNumber;
            const harvestImageData = IsArrayNotEmpty(imgData)
              ? imgData.find((li) => li.id === listingData[data].Id)
              : {};
            const getHarvestImage = _.get(harvestImageData, "img", null);
            const harvestImage = imgOptimizer(getHarvestImage);
            const deliveryStatus =
              listingData[data].ReceiptStatus == "Paid" ||
              listingData[data].ReceiptStatus == "Released" ||
              listingData[data].ReceiptStatus == "Inventoried"
                ? "Completed"
                : "Pending";
            const deliveryClass =
              listingData[data].ReceiptStatus == "Paid" ||
              listingData[data].ReceiptStatus == "Released" ||
              listingData[data].ReceiptStatus == "Inventoried"
                ? "light_green_bg "
                : "orange_bg";
            return (
              <Fragment key={index}>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <span>
                      <a className="width-85 d-block grey_text_color text-16 pe-2">
                        Delivery #: {listingData[data].LoadDeliveryTicketNumber}
                        , Contract: {listingData[data].RelationshipNumber}
                      </a>
                      <span className="float-end icon_toggle"></span>
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tr">
                      <div className="td overflow-hidden" data-header="Pool:">
                        {getHarvestImage === null ? (
                          <img
                            src={defaultLoadingImageURL}
                            className="card-img-top cranberry_thumb cursor_pointer"
                            alt="Recent_Delivery_Image_Loading"
                          />
                        ) : (
                          <>
                            <span
                              className={`${deliveryClass} text-center ver-space-10 d-inline-block width-100 white_color left-border-radius status_style text-uppercase`}
                            >
                              {deliveryStatus}
                            </span>
                            {IsStringChecker(harvestImage) ? (
                              <img
                                src={harvestImage}
                                className="card-img-top cranberry_thumb cursor_pointer"
                                alt="Recent_Delivery_Image"
                              />
                            ) : (
                              <ImageView
                                url={harvestImage}
                                className="img-fluid mx-auto d-block"
                              />
                            )}
                          </>
                        )}

                        {/*<!-- Modal starts-->*/}
                        <div
                          className="modal fade"
                          id="RecentDeliveryModal1"
                          tabIndex="-1"
                          aria-labelledby="RecentDeliveryModal1"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-fullscreen">
                            <div className="modal-content">
                              <div className="modal-header border-0 p-3 pb-0 d-inline-block text-center vlight_bg">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm text-14"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <i className="fa fa-close pe-1"></i>Close
                                </button>
                                <div className="btnDiv mt-4 my-1">
                                  <span
                                    className="zoom-in cursor_pointer d-inline-block border-1 border-blue z-p-1 border-radius-4 me-1"
                                    /*onclick="zoomIn()"*/ data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Zoom In"
                                  >
                                    <i
                                      className="fa fa-search-plus text-20 blue_color"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span
                                    className="zoom-out cursor_pointer d-inline-block border-1 border-blue z-p-1 border-radius-4 me-1"
                                    /*onclick="zoomOut()"*/ data-bs-toggle="tooltip"
                                    data-bs-placement="bottom"
                                    title="Zoom Out"
                                  >
                                    <i
                                      className="fa fa-search-minus text-20 blue_color"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  {/*<!-- <span className="download cursor_pointer d-inline-block border-1 border-blue z-p-1 border-radius-4 me-1" /*onclick="zoomOut()"data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download"><i className="fa fa-download text-20 blue_color" aria-hidden="true"></i></span>-->*/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<!-- Modal ends -->*/}
                      </div>
                      <div className="td ps-0 ps-lg-1" data-header="Crop Year:">
                        <div className="card-body card-odd p-0 p-lg-2 pt-0 pt-lg-3">
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              Delivery #:
                            </span>{" "}
                            <span className="fw-bold">
                              {" "}
                              {listingData[data].LoadDeliveryTicketNumber}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">Facility:</span>{" "}
                            <span className="fw-bold">
                              {listingData[data].FacilityName}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              Date &amp; Time In:
                            </span>{" "}
                            <span className="fw-bold">
                              <Moment utc format={dateFormate}>
                                {listingData[data].IncomingTime}
                              </Moment>{" "}
                              <Moment utc format={timeFormate}>
                                {listingData[data].IncomingTime}
                              </Moment>
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              Contract #:
                            </span>{" "}
                            <span className="fw-bold">
                              {listingData[data].RelationshipNumber}{" "}
                              {listingData[data].Name}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">BedID(s):</span>{" "}
                            <span className="fw-bold">
                              {harvestArray[LDTicketNumber].bed}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">Variety:</span>{" "}
                            <span className="fw-bold">
                              {(deliveryStatus == "Completed" ||
                                deliveryStatus == "Pending") &&
                                listingData[data].LegacyVarietyCode}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">EU/NonEU:</span>{" "}
                            <span className="fw-bold">
                              {(deliveryStatus == "Completed" ||
                                deliveryStatus == "Pending") &&
                                listingData[data].DomesticExportType}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              Fresh/Process:
                            </span>{" "}
                            <span className="fw-bold">
                              {(deliveryStatus == "Completed" ||
                                deliveryStatus == "Pending") &&
                                listingData[data].ProcessedFreshType}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="td ps-1" data-header="Contract Number:">
                        <div className="card-body card-odd p-0 p-lg-1 pt-0 pt-lg-3">
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              % Moisture:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentMoist}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              % Dry Trash:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentDryTrash}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              % Trash Total:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentTotalTrash}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              Clean Dry Delivery Weight:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                Math.round(
                                  listingData[data].CleanDryWeight
                                )}{" "}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">% Poor:</span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentPoor}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">% Class 1:</span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].ClassOne}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              % Class 1 Less Allowance:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentClassOneLessAllowance}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              % Defect Total:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentDefectTotal}
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div className="td ps-1" data-header="Contract Number:">
                        <div className="card-body card-odd p-0 p-lg-1 pt-0 pt-lg-3">
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">% Usable:</span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentUsable}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              Delivery Weight (lbs):
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                harvestArray[LDTicketNumber].deliveryWeight}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              Total Barrels:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                harvestArray[LDTicketNumber].totalBarrels}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">Firmness:</span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].FirmnessAverage}{" "}
                            </span>
                          </h5>
                          <h5 className="overflow-hidden">
                            <span className="float-start pe-1">
                              % &gt; 1/2:
                            </span>{" "}
                            <span className="fw-bold">
                              {deliveryStatus == "Completed" &&
                                listingData[data].PercentGreaterThanHalf}
                            </span>
                          </h5>
                          {roleCBPayment && (
                            <h5 className="overflow-hidden">
                              <span className="float-start pe-1 pt-0 pt-lg-4">
                                Preliminary Assessment $s:
                              </span>{" "}
                              <span className="fw-bold">
                                {deliveryStatus == "Completed" &&
                                  listingData[data].PreliminaryAssessment}
                              </span>
                            </h5>
                          )}
                          {roleCBPayment && (
                            <h5 className="overflow-hidden">
                              <span className="float-start pe-1">
                                Preliminary Incentive $s:
                              </span>{" "}
                              <span className="fw-bold">
                                {deliveryStatus == "Completed" &&
                                  listingData[data].PreliminaryIncentive}
                              </span>
                            </h5>
                          )}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Fragment>
            );
          })}
      </Accordion>
    </>
  );
};

export default RecentDeliveryMobile;
