/**
 * @component_Name : DataGridPagination
 * @description : library for grid with pagination
 * @author : Gobi Chandran/07-26-2022
 *
 */
import React, { useState, useEffect, useRef } from "react";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { groupBy as rowGrouper } from "lodash";
import { IsNumberChecker, HandleErrorTrackAndToast } from "../../utils/helper";

const DataGridPagination = ({
  uniqueIdentifier,
  gridData,
  columns,
  isPagination,
  itemsPerPage,
  columnResize,
  classNames,
  summaryRows,
  defaultSort = "",
  groupByKey = "",
  getExpandedGroupIds = "",
  setExpandedGroupIds = "",
  isSortable = true,
  dynamicHeaderHeight = 50,
  autoRowHeight = false,
  paddingRowHeight = 0,
  dynamicHeight = 0,
  pageNumber = undefined,
  pagenationEvent = undefined,
  totalRecordCount = null,
  getNextpage=null
}) => {
  const [sortColumns, setSortColumns] = useState([]);
  const [ColumnsArray, setColumnsArray] = useState([...columns]);
  const tokenId = useSelector((state) => state.reducerToken);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(pageNumber ? (pageNumber*itemsPerPage)%gridData.length : 0);
  const [items, setItems] = useState(null);
  const data_grid = useRef();
  let endOffset = 0;
  //row key generator
  const rowKeyGetter = (row) => {
    return row[uniqueIdentifier];
  };

  /*bydefault sort on first load*/
  useEffect(() => {
    if (defaultSort != "") {
      const y = [
        { ["columnKey"]: defaultSort[0], ["direction"]: defaultSort[1] },
      ];
      setSortColumns(...sortColumns, y);
    }
    setColumnsArray([...columns]);
  }, []);
  /*End sort on first load*/

  useEffect(() => {
    if (gridData.length > 0) {
      if (isPagination === true) {
        const newgridData = sorting(gridData, sortColumns);
        setItems([...newgridData]);
        /* Fetch items from another resources.*/
        endOffset = itemOffset + itemsPerPage;
        const fetchData = newgridData.slice(itemOffset, endOffset);
        setCurrentItems(fetchData);
        gridColumnsData(fetchData);
        if(totalRecordCount === null){
          setPageCount(Math.ceil(newgridData.length / itemsPerPage));
        }         
        else{
          setPageCount(Math.ceil(totalRecordCount / itemsPerPage));
        }        
      } else if (isPagination === false) {
        const newgridData1 = sorting(gridData, sortColumns);
        setCurrentItems([...newgridData1]);
        gridColumnsData([...newgridData1]);
      }
    } else {
      setCurrentItems([]);
    }
  }, [tokenId, itemOffset, itemsPerPage, gridData, sortColumns, endOffset]);

  const gridColumnsData = (data) => {
    let datas = [...ColumnsArray];
    ColumnsArray.map((col) => {
      const longestGenre = Math.max(
        ...data.map((item) => item[col.key]?.toString()?.length)
      );
      if (col.minWidth < longestGenre * 10) {
        datas = datas.map((obj) =>
          obj.key !== col.key
            ? obj
            : {
                ...obj,
                width: longestGenre * 10,
              }
        );
        // console.log()
      } else if (col.minWidth) {
        datas = datas.map((obj) =>
          obj.key !== col.key
            ? obj
            : {
                ...obj,
                width: col.minWidth,
              }
        );
      }
    });
    setColumnsArray(datas);
    // console.log("data",data)
  };

  // Pagination page change event
  const handlePageClick = (selected) => {
    const newOffset = (selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    pagenationEvent && pagenationEvent(selected)
    getNextpage && totalRecordCount !== null && getNextpage(selected);
  };

  // Sort by column field
  const sortByField = (field, reverse) => {
    const key = (x) => {
      return x[field];
    };
    reverse = !reverse ? 1 : -1;
    return (first, second) => {
      return (
        (first = key(first)),
        (second = key(second)),
        reverse * ((first > second) - (second > first))
      );
    };
  };
  // Sorting values
  const sorting = (arrayData, sortParam = []) => {
    if (sortParam.length === 0) {
      return arrayData;
    }
    const { columnKey, direction } = sortParam[0];
    const newOrder = direction === "ASC" ? "ASC" : "DESC";
    if (newOrder === "ASC") {
      arrayData = arrayData.sort(sortByField(columnKey, false));
    } else {
      arrayData = arrayData.sort(sortByField(columnKey, true));
    }
    return arrayData;
  };
  // const percentCalculation = (a, b) = >{
  //   var c = (parseFloat(a)*parseFloat(b))/100;
  //   return parseFloat(c);
  // }

  const minimumHeight = 35;
  const MinimumHeightProvider = (height = 0) => {
    return IsNumberChecker(height) && height > minimumHeight
      ? height
      : minimumHeight;
  };
  const rowHeaderHeight = MinimumHeightProvider(dynamicHeaderHeight);
  const rowHeight = MinimumHeightProvider(dynamicHeight);

  const defaultRowHeightSetter = (
    autoRowHeight = false,
    staticHeight = 0,
    paddingRowHeight = 0,
    newHeight = 0
  ) => {
    try {
      const height = MinimumHeightProvider(newHeight);
      if (autoRowHeight) {
        return IsNumberChecker(paddingRowHeight)
          ? height + paddingRowHeight
          : height;
      } else {
        return MinimumHeightProvider(staticHeight);
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return minimumHeight;
    }
  };
  const rowHeightSetter = (row) => {
    if (autoRowHeight) {
      try {
        var heightArr = Object.keys(row.row).map((columnName) => {
          let columnProperty = ColumnsArray.filter((obj) => {
            if (obj.key === columnName) return obj;
          });
          let columnWidth = columnProperty[0]?.width || "";
          let columnValue = row.row[columnName] || "";
          const regex = /(<([^>]+)>)/gi;
          columnValue = columnValue.toString().replace(regex, "");
          if (
            columnValue != "" &&
            columnProperty[0]?.hasOwnProperty("dateFormate")
          ) {
            columnValue = columnValue.substr(
              0,
              columnProperty[0].dateFormate.length
            );
          }
          if (
            columnWidth.toString().search("%") === -1 &&
            columnValue !== "" &&
            columnWidth !== "" &&
            columnWidth < columnValue.toString().length * 10
          ) {
            let len = columnValue.toString().length;
            let value = (len > 200 ? len * 6 : len * 10) / columnWidth;
            return Math.ceil(value) * 30 > minimumHeight
              ? Math.ceil(value) * 30
              : minimumHeight;
          } else {
            return minimumHeight;
          }
        });
        let height = heightArr.sort(function (a, b) {
          return b - a;
        })[0];
        return defaultRowHeightSetter(true, 0, paddingRowHeight, height);
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        return defaultRowHeightSetter(true, 0, paddingRowHeight);
      }
    } else {
      return defaultRowHeightSetter(false, rowHeight);
    }
  };
  return (
    <>
      {/* Data grid section start */}
      {currentItems.length > 0 && (
        <DataGrid
          ref={data_grid}
          rowKeyGetter={rowKeyGetter}
          columns={ColumnsArray}
          rows={currentItems}
          defaultColumnOptions={{
            sortable: isSortable,
            resizable: columnResize,
          }}
          // selectedRows={selectedRows}
          // onSelectedRowsChange={setSelectedRows}
          // onRowsChange={setRows}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          summaryRows={summaryRows}
          className={`${classNames} test`}
          direction="ltr"
          /*group by functionality*/
          groupBy={groupByKey != "" ? groupByKey : ""}
          rowGrouper={groupByKey != "" ? rowGrouper : ""}
          expandedGroupIds={
            getExpandedGroupIds != "" ? getExpandedGroupIds : ""
          }
          onExpandedGroupIdsChange={
            setExpandedGroupIds != "" ? setExpandedGroupIds : ""
          }
          headerRowHeight={rowHeaderHeight}
          rowHeight={rowHeightSetter}
        />
      )}
      {/* Data grid section end */}

      {/* Pagination section start */}
      {(gridData.length > itemsPerPage && isPagination == true) || totalRecordCount !== null ? (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          //{...forcePageObj}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={(event)=>handlePageClick(event.selected)}
          containerClassName={"pagination float-end"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          renderOnZeroPageCount={null}
          forcePage = {pageNumber}
        />
      ) : (
        ""
      )} 
      {/* Pagination section end */}
    </>
  );
};

export default DataGridPagination;
