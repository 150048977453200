import { combineReducers } from "redux";
import reducerToken from "./reducerToken";
import activeCropYear from "./activeCropYear";
import toastMsg from "./toastMsg";

const allReducers = combineReducers({
  reducerToken,
  activeCropYear,
  ToastProperties: toastMsg,
});
export default allReducers;
