import React, { useEffect, useState } from 'react'
import EnterOrEditPesticideApplication from './enterOrEditPesticideApplication';
import SubmitEndOfSeasonReport from './submitEndOfSeasonReport';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { getCurrentYear, IsTokenChecker, HandleErrorTrackAndToast } from '../../../utils/helper';
import Loading from '../../global/loading';
import { gbsDataCommonMethod } from '../../../services/gbsData';
import { FilterContractByRole, ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import PesticideUseHistory from './pesticideUseHistory';
import PrintableReports from './printableReports';

const EPURsDetailPage = (props) => {
  const {DropDownCBRoles=[], RolePBPrivileges} = props;
  const { rolePBAgScientist = false } = RolePBPrivileges;
  const dispatch = useDispatch();
  const cropYear = getCurrentYear();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const idToken = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const [selectedTab, setSelectedTab] = useState('enterPSA');
  const handlerTab = (params) => {
    setSelectedTab(params);
  };
  const [contracts, setContracts] = useState([]);
  const [ePURsData, setEPURsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isDataAdded, setISDataAdded] = useState(false);
  useEffect(() => {
    if (IsTokenChecker(idToken) && !rolePBAgScientist) {    
      commonAjaxCall(
        `persons/${personId}/contracts?r.ContractStatus=1000,1001`,
        'get',
        {},
        (resp) => {
          let filteredData = FilterContractByRole(resp.data, accounts, DropDownCBRoles);
          filteredData = _.orderBy(filteredData,
            ["ContractStatus", "RelationshipNumber"],
            ["asc", "asc"]
          );
          setContracts(filteredData);
          setLoader(false);
          getEPURsPesticideData();
        }
      )
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const getEPURsPesticideData = () =>{
    setLoader(true);
    const filteredData = ValidGBSRoleByContracts(accounts, DropDownCBRoles, true, false);
    commonAjaxCall(
      `pesticides/e-pur?relationshipid=${filteredData}&cropyear=${cropYear}`,
      'get',
      {},
      (resp) => {
        if (resp.data.length > 0) {
          setEPURsData(resp.data);
        }
        setLoader(false);
      })
  }
  const commonAjaxCall = async (url = '', method = "GET", data = {}, successMethod) => {
    // return;
    setLoader(true);
    await gbsDataCommonMethod(idToken, accounts, url, method, data).then(successMethod)
      .catch((e) => {
        setLoader(false);
        HandleErrorTrackAndToast(e, true, "dispatch", dispatch);
      });
  }

  return (
    <>
    {loader && <div className="zindex10000"> <div className="nav-mobile-overlay-display"></div><Loading /></div>}
      <div className="accordion-body mt-3 p-0">
        {/*<!-- Tabs for Delivery data starts -->*/}
        <ul className={`nav nav-pills mb-3 bordered_nav_tabs ${'tabs-4'}`} id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${selectedTab === 'enterPSA' ? ' active' : ''}`} id="enter-pesticide-application-tab" data-bs-toggle="pill" data-bs-target="#enterPSA" type="button" role="tab" aria-controls="enterPSA" aria-selected={selectedTab === 'enterPSA' ? true : false} onClick={() => handlerTab("enterPSA")}>ENTER/EDIT PESTICIDE APPLICATION</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${selectedTab === 'submitEOSR' ? ' active' : ''}`} id="submit-end-of-season-report-tab" data-bs-toggle="pill" data-bs-target="#submitEOSR" type="button" role="tab" aria-controls="submitEOSR" aria-selected={selectedTab === 'submitEOSR' ? true : false} onClick={() => handlerTab("submitEOSR")}>SUBMIT END OF SEASON REPORT</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className={`nav-link ${selectedTab === 'PUH' ? ' active' : ''}`} id="pesticide-use-history-tab" data-bs-toggle="pill" data-bs-target="#PUH" type="button" role="tab" aria-controls="PUH" aria-selected={selectedTab === 'PUH' ? true : false} onClick={() => handlerTab("PUH")}>PESTICIDE USE HISTORY</button>
          </li>
          {<li className="nav-item" role="presentation">
            <button className={`nav-link ${selectedTab === 'PR' ? ' active' : ''}`} id="pesticide-use-history-tab" data-bs-toggle="pill" data-bs-target="#PR" type="button" role="tab" aria-controls="PR" aria-selected={selectedTab === 'PR' ? true : false} onClick={() => handlerTab("PR")}>PRINTABLE REPORTS</button>
          </li>}
        </ul>
        <div className="tab-content pesticide_application" id="pills-tabContent">
          {/*<!-- Receipts tab -->*/}
          <div className={`tab-pane fade ${selectedTab === 'enterPSA' ? ' show active' : ''}`} id="enterPSA" role="tabpanel" aria-labelledby="enter-pesticide-application">
           <EnterOrEditPesticideApplication setISDataAdded = {(val)=>setISDataAdded(val)} isDataAdded={isDataAdded} roleAgScientist={rolePBAgScientist} GBSContracts={contracts} ePURsRawData={ePURsData} getEPURsPesticideData={getEPURsPesticideData} />
          </div>
          {/*<!-- Receipts tab ends -->*/}

          {/*<!-- Beds tab -->*/}
          <div className={`tab-pane fade ${selectedTab === 'submitEOSR' ? ' show active' : ''}`} id="submitEOSR" role="tabpanel" aria-labelledby="submit-end-of-season-report">
          <SubmitEndOfSeasonReport setISDataAdded = {(val)=>setISDataAdded(val)} isDataAdded={isDataAdded} roleAgScientist={rolePBAgScientist} GBSContracts={contracts} ePURsRawData={ePURsData} getEPURsPesticideData={getEPURsPesticideData} /> 
          </div>
          {/*<!-- Beds tab ends -->*/}

          {/*<!-- Dailysumary tab -->*/}
          <div className={`tab-pane fade ${selectedTab === 'PUH' ? ' show active' : ''}`} id="PUH" role="tabpanel" aria-labelledby="pesticide-use-history">
          <PesticideUseHistory roleAgScientist={rolePBAgScientist} GBSContracts={contracts} ePURsRawData={ePURsData} />
          </div>
          {/*<!-- Dailysumary tab ends -->*/}
          {
            <div className={`tab-pane fade ${selectedTab === 'PR' ? ' show active' : ''}`} id="PR" role="tabpanel" aria-labelledby="pesticide-use-history">
              <PrintableReports roleAgScientist={rolePBAgScientist} GBSContracts={contracts} />
            </div>
          }
          
        </div>
        {/*<!-- Tabs for Delivery data ends -->*/}
      </div>
    </>
  )
}

export default EPURsDetailPage;
