import React, { useState, useEffect, Fragment } from "react";
import "../../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import { Accordion } from "react-bootstrap";
import Loading from "../../../../components/global/loading";
import DataGridPagination from "../../../global/dataGridPagination";
import { dateFormate } from "../../../../utils/constantsVariable";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../../../components/global/currentLocation";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../../../utils/helper";
import { ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../../../permissionWrapper";

export const CashAssignments = (props) => {
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  // const personId = accounts[0].idTokenClaims.gbsPersonId;
  const tokenId = useSelector((state) => state.reducerToken);
  const [loader, setLoader] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const dispatch = useDispatch();

  const [CashAssignmentsData, setCashAssignmentsData] = useState([]);
  const [CashAssignmentsDataMobile, setCashAssignmentsDataMobile] = useState(
    []
  );
  const [CashAssignmentsDataStatus, setCashAssignmentsDataStatus] =
    useState(true);

  const { DropDownCBRoles = [] } = props;

  //Defining Grid Column
  const columnsArray = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      headerCellClass: "enablesTextWrappingHeading",
      width: 100,
    },
    {
      key: "Name",
      name: "Contract Name",
      cellClass: "enablesTextWrapping",
      width: 150,
      formatter(props) {
        return <>{props.row.Name || "-"}</>;
      },
    },
    {
      key: "Reference",
      name: "Assignee",
      cellClass: "enablesTextWrapping",
      width: 165,
      formatter(props) {
        return <>{props.row.Reference || "-"}</>;
      },
    },
    {
      key: "CashAssignmentDescription",
      name: "Type",
      cellClass: "enablesTextWrapping",
      width: 100,
      formatter(props) {
        return <>{props.row.CashAssignmentDescription || "-"}</>;
      },
    },
    {
      key: "DateAssigned",
      name: "Start Date",
      headerCellClass: "grid_column_center",
      cellClass: "text-lg-center",
      width: 100,
      dateFormate: dateFormate,
      formatter(props) {
        return (
          props.row.DateAssigned !== null ? 
          <Moment utc format={dateFormate}>
            {props.row.DateAssigned}
          </Moment>
          :
          '-'
        );
      },
    },
    {
      key: "EndDate",
      name: "End Date",
      headerCellClass: "text-lg-center",
      cellClass: "text-lg-center",
      width: 100,
      dateFormate: dateFormate,
      formatter(props) {
        return (
          props.row.EndDate !== null ? 
          <Moment utc format={dateFormate}>
            {props.row.EndDate}
          </Moment>
          :
          '-'
        );
      },
    },
    {
      key: "CropYearFrom",
      name: "Crop Year From",
      headerCellClass: "text-lg-center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 120,
      formatter(props) {
        return <>{props.row.CropYearFrom || "-"}</>;
      },
    },
    {
      key: "CropYearTo",
      name: "Crop Year To",
      headerCellClass: "text-lg-center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 120,
      formatter(props) {
        return <>{props.row.CropYearTo || "-"}</>;
      },
    },
  ];
  const createMarkup = (cashAssignmentsNotFoundMsg) => {
    return { __html: cashAssignmentsNotFoundMsg };
  };
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getDeferments();
      setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  // Get Grid API values
  const getDeferments = async () => {
    const filteredContracts = ValidGBSRoleByContracts(accounts, DropDownCBRoles, true, false);
    const contractids = filteredContracts.join();
    const url = `cash-assignments?relationshipid=${contractids}&ca.status=4600`;
    setLoader(true);
    await gbsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        let resultData = [...resp.data];
        if (
          resultData.length > 0 &&
          resp.data !== "Contract and CropYear must be provided"
        ) {
          setCashAssignmentsData(resultData);
          setCashAssignmentsDataMobile(resultData);
          setCashAssignmentsDataStatus(true);
          setNoResultsFound(false);
        } else {
          setCashAssignmentsData([]);
          setCashAssignmentsDataMobile([]);
          setCashAssignmentsDataStatus(false);
          setNoResultsFound(true);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Desktop table starts -->*/}
      {CashAssignmentsData.length > 0 && CashAssignmentsDataStatus && !displayAccordian && (
        <div
          className={`
      hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table`}
        >
          <DataGridPagination
            uniqueIdentifier="Id"
            gridData={CashAssignmentsData}
            columns={columnsArray}
            isPagination={true}
            itemsPerPage={10}
            columnResize={false}
            classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
            autoRowHeight={true}
          />
        </div>
      )}
      {/*<!-- Desktop table ends -->*/}
      {/* <!-- Mobile table starts --> */}
      {CashAssignmentsDataMobile.length > 0 && CashAssignmentsDataStatus && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion defaultActiveKey="0">
              {CashAssignmentsDataMobile.length > 0 &&
                Object.values(CashAssignmentsDataMobile).map((data, index) => {
                  return (
                    <Fragment key={data.id}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          Contract #: {data.RelationshipNumber}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div
                                className="td"
                                data-header="Contract #:"
                              ></div>
                              <div
                                className="td"
                                data-header="Contract Number:"
                              >
                                {data.RelationshipNumber}
                              </div>
                              <div className="td" data-header="Contract Name:">
                                <span>{data.Name || "-"}</span>
                              </div>
                              <div className="td" data-header="Assignee:">
                                {data.Reference || "-"}
                              </div>
                              <div className="td" data-header="Type:">
                                {data.CashAssignmentDescription || "-"}
                              </div>
                              <div className="td" data-header="Start Date:">
                                {
                                   data.DateAssigned !== null ? 
                                   <Moment utc format={dateFormate}>
                                     {data.DateAssigned}
                                   </Moment>
                                   :
                                   '-'
                                }
                              </div>
                              <div className="td" data-header="End Date:">
                                {
                                   data.EndDate !== null ? 
                                   <Moment utc format={dateFormate}>
                                     {data.EndDate}
                                   </Moment>
                                   :
                                   '-'
                                }
                              </div>
                              <div className="td" data-header="Crop Year From:">
                                {data.CropYearFrom || "-"}
                              </div>
                              <div className="td" data-header="Crop Year To:">
                                {data.CropYearTo || "-"}
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
          </div>
        </div>
      )}
      {/* <!-- Mobile table ends --> */}
      {/* no record found section start */}
      {CashAssignmentsData.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
          <i className="fa fa-info-circle pe-2"></i>
          <span
            dangerouslySetInnerHTML={createMarkup(
              moreLangs[i18n.language].payments_page.cashAssignmentsNotFoundMsg
            )}
          />
        </div>
      )}
      {/* no record found section End */}
      {/*<!-- Keeping quality block ends -->*/}
    </>
  );
};
