import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import { toDecimal } from "../../utils/helper";

const EquityBalanceMobileGrid = ({ listingData }) => {
  return (
    <Accordion
      defaultActiveKey="0"
      className="table mobile-accordion accordion_space"
    >
      {listingData.length > 0 &&
      <h5 className="text-14 fw-bold mt-3 mt-lg-2 mb-3 mob-text-16">
        Total Result Count:{listingData.length}
      </h5>}
      {listingData.length > 0 &&
        listingData.map((data, index) => {
          return (
            <Fragment key={index}>
              <Accordion.Item eventKey={index}>
                <Accordion.Header>
                  Contract #: {data["Contract Number"]}<br/>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td"></div>
                      
                      <div className="td" data-header="Contract Number:">
                        <span>{data["Contract Number"]}</span>
                      </div>
                      <div className="td" data-header="Contract Name:">
                        <span>{data["Contract Name"]}</span>
                      </div>

                      <div className="td" data-header="Equity Type:">
                        <span>{data["Equity Type"]}</span>
                      </div>

                      <div className="td" data-header="Form Type:">
                        <span>{data["Form Type"]}</span>
                      </div>

                      <div className="td" data-header="Shares:">
                        <span>{toDecimal(data["Shares"], 3)}</span>
                      </div>

					            <div className="td" data-header="Value:">
                        <span>{toDecimal(data["Value"].toFixed(2), 3, "$")}</span>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Fragment>
          );
        })}
    </Accordion>
  );
};

export default EquityBalanceMobileGrid;
