import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  IsStringChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  lowStr,
} from "../../utils/helper";
const Footer = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("footer_navigations");
  const resolvedLanguage = getResolvedLanguage(i18n);
  const footer_navigations = t("data", { returnObjects: true });
  useEffect(() => {
    if (
      !IsArrayNotEmpty(footer_navigations) &&
      lowStr("error", footer_navigations)
    ) {
      HandleErrorTrackAndToast({ footer_navigations }, true, "dispatch", dispatch);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [footer_navigations]);
  const data = _.groupBy(footer_navigations, "column_number");
  return (
    <div>
      <footer>
        <div className="container-fluid mob-pleft-pright-0">
          <div className="row ver-space-20">
            <div className="col-12">
              <div className="footer-links">
                {Object.keys(data).map((link, ind) => (
                  <div key={ind} className="footer-inner-links">
                    {data[link]
                      .sort((a, b) => (a.order_by > b.order_by ? 1 : -1))
                      .map((item, index) => {
                        const url = _.get(item, "link", null);
                        const title = _.get(
                          item,
                          `title_${resolvedLanguage}`,
                          ""
                        );
                        const open_new_window = IsStringChecker(url)
                          ? url.includes("http")
                            ? true
                            : false
                          : false;
                        if (IsStringChecker(url)) {
                          return (
                            <div className="block" key={index}>
                              <Link
                                to={open_new_window ? { pathname: url } : url}
                                target={open_new_window ? "_blank" : "_self"}
                              >
                                {title}
                              </Link>
                            </div>
                          );
                        } else {
                          return <Fragment key={index} />;
                        }
                      })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid hor-space-12 mob-pleft-pright-0">
          <div className="row">
            <div className="ver-space-20 text-center blue_bg">
              <img
                src={`${window.location.origin}/assets/images/logo/logo_latest@2x.png`}
                alt="FooterLogo"
                style={{ width: "105px" }}
              />
              <p className="white_color pt-3">
                &copy; Ocean Spray Cranberries, Inc.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
