import React, { Fragment, useState, useEffect } from "react";
import {
  IsStringChecker,
  regExpPreTagGlobal,
  regExpPreTag,
  regExpPreTagEnd,
  decodeHtml,
  HandleErrorTrackAndToast,
} from "../../../utils/helper";
import parse from "html-react-parser";

const EventLocation = ({ event_location = null, format = true }) => {
  const [result, setResult] = useState(null);

  const ContentModifier = (str = null) => {
    function replacer(match) {
      try {
        // console.log("match => ",match);
        let decode = decodeHtml(match);
        let final = IsStringChecker(decode)
          ? decode.replace(regExpPreTag, "").replace(regExpPreTagEnd, "")
          : decode;
        return final;
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        return match;
      }
    }

    try {
      let content = str.replace(regExpPreTagGlobal, replacer);
      // console.log("content => ", content);
      return content;
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return str;
    }
  };

  useEffect(() => {
    if (IsStringChecker(event_location)) {
      try {
        if (format) {
          const content = ContentModifier(event_location);
          setResult(IsStringChecker(content) ? content : null);
        } else {
          setResult(event_location);
        }
      } catch (e) {
        HandleErrorTrackAndToast(e, true);
        setResult(null);
      }
    } else {
      setResult(null);
    }
  }, [event_location, format]);

  if (IsStringChecker(result)) {
    return (
      <div
        className="quill-editor ql-container ql-snow"
        style={{ height: "auto", border: "none", fontFamily: "unset", fontSize: "14px" }}
      >
        {parse(result)}
      </div>
    );
  } else {
    return <Fragment />;
  }
};

export default EventLocation;
