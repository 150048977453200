/** 
@CreatedBy : Gobi/26-07-2022
@Function : My recent payments
@Description : Show last 6 month payment
*/
import React, { useState, useEffect, Fragment } from "react";
import Loading from "../../../../components/global/loading";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import DataGridPagination from "../../../global/dataGridPagination";
import Moment from "react-moment";
import moment from "moment";
import {
  toDecimalORCurrency,
  IsTokenChecker,
  fieldSorter,
  HandleErrorTrackAndToast,
} from "../../../../utils/helper";
import { dateFormate } from "../../../../utils/constantsVariable";
import { Accordion } from "react-bootstrap";
import { ValidGBSRoleByContracts, ImpersonationAccountsSwap } from "../../../../permissionWrapper";

export const MyRecentPayments = (props) => {
  const [loader, setLoader] = useState(false);
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const { DropDownCBRoles = [] } = props;
  const tokenId = useSelector((state) => state.reducerToken);
  const [myRecentPaymentResult, setMyRecentPaymentResult] = useState([]);
  const dispatch = useDispatch();
  //Defining Grid Column
  const columns = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      width: 180,
      cellClass: "enablesTextWrapping ",
    },
    {
      key: "Name",
      name: "Contract Name",
      width: 200,
      cellClass: "enablesTextWrapping ",
    },
    {
      key: "SettlementDate",
      name: "Payment Date",
      headerCellClass: "text-lg-center",
      cellClass: "text-lg-center enablesTextWrapping",
      width: 150,
      dateFormate: dateFormate,
      formatter(props) {
        return (
          <Moment utc format={dateFormate}>
            {props.row.SettlementDate}
          </Moment>
        );
      },
    },
    {
      key: "CropYear",
      name: "Crop Year",
      headerCellClass: "text-lg-center",
      cellClass: "text-lg-center enablesTextWrapping",
      width: 100,
    },
    {
      key: "Description",
      name: "Description",
      cellClass: "enablesTextWrapping ",
      width: 350,
      formatter(props) {
        return <>{props.row.Description || "-"}</>;
      },
    },
    {
      key: "Amount",
      name: "Payment Amount",
      width: 300,
      headerCellClass: "text-lg-end",
      cellClass: "text-lg-end enablesTextWrapping",
      formatter(props) {
        return <>{toDecimalORCurrency(props.row.Amount, 2, "$") || "-"}</>;
      },
    },
  ];
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      getMyRecentPAyments();
      setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  // Get Grid API values
  const getMyRecentPAyments = async () => {
    setLoader(true);
    const filteredContracts = ValidGBSRoleByContracts(accounts, DropDownCBRoles, true, false);
    const contractids = filteredContracts.join();
    const url = `payments?relationshipid=${contractids}&onlylastpayment=1`;
    await gbsDataCommonMethod(tokenId, accounts, url)
      .then((resp) => {
        //Filtering last 6 month data
        let last6Months = moment().subtract(6, "months").format(dateFormate);
        let resultData = [...resp.data].filter((item) => {
          let date = moment(item?.SettlementDate).format(dateFormate);
          return (
            moment(date).isAfter(last6Months) ||
            moment(date).isSame(last6Months)
          );
        });
        //Remove record description has "payments assigned from" text
        resultData = resultData.filter((item) => {
          return (
            item.Description?.toString()
              ?.toLowerCase()
              ?.search("payment assigned from") === -1
          );
        });
        //Sorting based on the array order
        resultData = resultData.sort(
          fieldSorter(["RelationshipNumber", "CropYear", "Description"])
        );
        setMyRecentPaymentResult(resultData);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  return (
    <>
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {!displayAccordian && 
      <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
        <DataGridPagination
          uniqueIdentifier="PaymentId"
          gridData={myRecentPaymentResult}
          columns={columns}
          isPagination={true}
          itemsPerPage={10}
          columnResize={false}
          classNames="contain-block-size-reset bot-mspace-20 border-radius-6 accordion_tabs_bg_space summery-right-aligh"
          autoRowHeight={true}
          // summaryRows = {summaryRows}
        />
      </div>
    }  
      {myRecentPaymentResult.length > 0 && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0  ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion defaultActiveKey="0">
              {myRecentPaymentResult.length > 0 &&
                Object.values(myRecentPaymentResult).map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          <div>
                            Contract #: {data.RelationshipNumber}, {!displayAccordian && <br />}
                            Payment Amount:{" "}
                            {toDecimalORCurrency(data.Amount, 2, "$")}, <br />
                            Payment Date:{" "}
                            {
                              <Moment utc format={dateFormate}>
                                {data.SettlementDate}
                              </Moment>
                            }
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div className="td"></div>
                              <div
                                className="td"
                                data-header="Contract Number:"
                              >
                                {data.RelationshipNumber}
                              </div>
                              <div className="td" data-header="Contract Name:">
                                <span>{data.Name}</span>
                              </div>
                              <div className="td" data-header="Payment Date:">
                                <Moment utc format={dateFormate}>
                                  {data.SettlementDate}
                                </Moment>
                              </div>
                              <div className="td" data-header="Crop Year:">
                                {data.CropYear}
                              </div>
                              <div className="td" data-header="Description:">
                                {data.Description}
                              </div>
                              <div
                                className="td flex-text-align-center"
                                data-header="Payment Amount:"
                              >
                                {toDecimalORCurrency(data.Amount, 2, "$")}
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};
