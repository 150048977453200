import React from "react";
import CurrentLocation from "./currentLocation";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables,
} from "chart.js";

import { Chart } from "react-chartjs-2";
ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export function Graph({
  labels,
  lable,
  percentPoor,
  percentClass,
  totaldayBarrels,
}) {
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const {
    daily_yaxis,
    yearly_yaxis,
    daily_y2axis,
    yearly_y2axis,
    daily_poor,
    yearly_poor,
    daily_class_1,
    yearly_class_1,
    daily_barrels,
    yearly_barrels_new:yearly_barrels,
  } = moreLangs[i18n.language]?.home_page;

  const isDaily = lable.toLowerCase() === "daily";
  const options = {
    responsive: true,
    elements: {
      line: {
        borderCapStyle: "round",
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: isDaily ? daily_yaxis : yearly_yaxis,
          font: {
            size: 12,
            weight: "bold",
            lineHeight: 1,
          },
          backdropPadding: {
            y: 2,
          },
        },
        grid: {
          drawOnChartArea: true,
          drawBorder: false,
          drawTicks: false,
        },
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          display: false,
          drawOnChartArea: false,
          drawBorder: false,
          drawTicks: false,
        },
        title: {
          display: true,
          text: isDaily ? daily_y2axis : yearly_y2axis,
          font: {
            size: 12,
            weight: "bold",
            lineHeight: 1,
          },
          backdropPadding: {
            y: 2,
          },
        },
      },
    },
    plugins: {
      legend: {
        onClick: false,
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: isDaily ? true : false,
        },
        reverse: true,
      },
      Tooltip: {
        position: "nearest",
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        type: "line",
        backgroundColor: "#f4b183",
        data: percentClass,
        yAxisID: "y2",
        label: isDaily ? daily_class_1 : yearly_class_1,
        borderColor: isDaily ? "#fadac5" : "#f4b183",
        borderWidth: isDaily ? 1 : 4,
        pointRadius: isDaily ? 7 : 0,
        pointHitRadius: 7,
        pointStyle: isDaily ? "rectRot" : "circle",
        spanGaps: isDaily ? false : true,
        showLine: isDaily ? false : true,
      },
      {
        type: "line",
        backgroundColor: "#c00000",
        data: percentPoor,
        yAxisID: "y2",
        label: isDaily ? daily_poor : yearly_poor,
        borderColor: isDaily ? "#be9cad" : "#c00000",
        borderWidth: isDaily ? 1 : 4,
        pointRadius: isDaily ? 7 : 0,
        pointHitRadius: 7,
        pointStyle: isDaily ? "rectRot" : "circle",
        spanGaps: isDaily ? false : true,
        showLine: isDaily ? false : true,
      },
      {
        type: "bar",
        label: isDaily ? daily_barrels : yearly_barrels,
        backgroundColor: "rgba(189, 215, 238, 0.7)",
        data: totaldayBarrels,
        // borderColor: "rgba(238, 245, 251, 0.7)",
        borderColor: "rgba(189, 215, 238, 0.7)",
        borderWidth: 2,
        yAxisID: "y",
        pointStyle: "rect",
      },
    ],
  };

  return (
    <>
      <Chart
        type="bar"
        options={options}
        data={data}
        width={750}
        height={600}
      />
    </>
  );
}

export default Graph;
