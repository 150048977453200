import React from 'react';
import EPURsDetailPage from './epursDetailpage';
import EPURsGuid from './epursGuid'

const EPURsLandingPage = (props) =>{

    return(
        <>
            <EPURsGuid {...props} />
            <EPURsDetailPage  {...props} />
        </>
    )
}

export default EPURsLandingPage;