import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CurrentLocation from "./currentLocation";
import {
  IsArrayNotEmpty,
  IsObjectChecker,
  IsStringChecker,
  getResolvedLanguage,
} from "../../utils/helper";
import {
  ContractsFromJSONParser,
  RoleCheckerForArrayData,
  ImpersonationAccountsSwap
} from "../../permissionWrapper";
import { useMsal } from "@azure/msal-react";
import _ from "lodash";

export const BreadcrumbWithParams = (props) => {
  const {
    params = null,
    RoleAccessDefinitions = null,
    SpecialRoleExist,
  } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);

  /*getting url and convert into array*/
  const [getPath, moreLangs] = CurrentLocation();
  const arrayLocation = getPath.slice(1).split("/");

  /*Translation*/
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const bread_crumb_menus = arrayLocation.map((crumb) =>
    _.get(moreLangs, `${resolvedLanguage}.menu_${crumb.replace(/-/g, "_")}`, "")
  );
  const bread_crumbs = RoleCheckerForArrayData(
    bread_crumb_menus,
    "url",
    gbsRolesByPerson,
    gbsRolesByContract,
    SpecialRoleExist,
    RoleAccessDefinitions
  );
  // console.log({ arrayLocation, bread_crumb_menus, bread_crumbs, props });
  return (
    <>
      <nav aria-label="breadcrumb" className="">
        <ol className="breadcrumb top-mspace-30">
          <li className="breadcrumb-item text-capitalize">
            <Link
              to="/"
              className="text-decoration-underline fw-bold blue_color"
            >
              {_.get(moreLangs, `${resolvedLanguage}.menu_home.title`, "Home")}
            </Link>
          </li>
          {bread_crumbs.map((menu, index) => {
            const lastElement =
              bread_crumbs.length > 1 && index + 1 === bread_crumbs.length
                ? true
                : false;
            return (
              /*there is a condition localitation file should have menu data*/
              menu ? (
                <li
                  key={`${index}`}
                  className={`breadcrumb-item text-capitalize ${
                    lastElement ? `active last-link` : ""
                  }`}
                  aria-current="page"
                >
                  {lastElement ? (
                    <>
                      {" / "}
                      <Link
                        to={`${menu.url}`}
                        className="text-decoration-underline fw-bold blue_color"
                      >
                        {menu.title}
                      </Link>
                    </>
                  ) : (
                    <Link
                      to={`${menu.url}`}
                      className="text-decoration-underline fw-bold blue_color"
                    >
                      {menu.title}
                    </Link>
                  )}
                </li>
              ) : (
                <Fragment key={`${index}`} />
              )
            );
          })}
          {params && (
            <li className="breadcrumb-item text-capitalize last-link">
              : {params}
            </li>
          )}
        </ol>
      </nav>
    </>
  );
};

export const BreadCrumbForArchive = (props) => {
  const {
    selectedLabel = {},
    filterOption = [],
    paramCategory = null,
    RoleAccessDefinitions = null,
    SpecialRoleExist,
    categorySlug = null,
  } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);

  /*getting url and convert into array*/
  const [getPath, moreLangs] = CurrentLocation();
  const arrayLocation = getPath.slice(1).split("/");

  /*Translation*/
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const bread_crumb_menus = arrayLocation.map((crumb) =>
    _.get(moreLangs, `${resolvedLanguage}.menu_${crumb.replace(/-/g, "_")}`, "")
  );
  const bread_crumbs = RoleCheckerForArrayData(
    bread_crumb_menus,
    "url",
    gbsRolesByPerson,
    gbsRolesByContract,
    SpecialRoleExist,
    RoleAccessDefinitions
  );
  // console.log({ arrayLocation, bread_crumb_menus, bread_crumbs });
  const subtitle_arr = filterOption.filter((i) => i.slug === paramCategory);
  const subtitle = IsArrayNotEmpty(subtitle_arr)
    ? _.get(subtitle_arr, `0.title_${resolvedLanguage}`, "")
    : "";
  const another_subtitle = _.get(
    selectedLabel,
    `title_${resolvedLanguage}`,
    ""
  );
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb top-mspace-30">
          <li className="breadcrumb-item text-capitalize">
            <Link
              to="/"
              className="text-decoration-underline fw-bold blue_color"
            >
              {moreLangs[resolvedLanguage].menu_home.title}
            </Link>
          </li>
          {bread_crumbs.map((menu, index) => {
            const lastElement =
              index + 1 === bread_crumbs.length ? false : true;
            const active = index + 1 === bread_crumbs.length ? "active" : "";
            return (
              /*there is a condition localitation file should have menu data*/
              menu ? (
                <li
                  key={`${index}`}
                  className={`contact-breadcrumb breadcrumb-item text-capitalize ${active} ${
                    bread_crumbs.length > 1 && !lastElement && "last-link"
                  } ${categorySlug}`}
                  aria-current="page"
                >
                  {lastElement ? (
                    <Link
                      to={`${menu.url}`}
                      className="text-decoration-underline fw-bold blue_color"
                    >
                      {menu.title}
                    </Link>
                  ) : bread_crumbs.length > 1 && !lastElement ? (
                    categorySlug == null ? (
                      ` : ${menu.title}`
                    ) : (
                      <>
                        <>{` / `}</>
                        <Link
                          to={`${menu.url}`}
                          className="text-decoration-underline fw-bold blue_color"
                        >
                          {menu.title}
                        </Link>
                      </>
                    )
                  ) : (
                    ""
                  )}
                </li>
              ) : (
                <Fragment key={`${index}`} />
              )
            );
          })}
          {IsStringChecker(subtitle) ? (
            <li className="breadcrumb-item last-link text-capitalize">
              {` : ${subtitle}`}
            </li>
          ) : IsStringChecker(another_subtitle) ? (
            ` : ${another_subtitle}`
          ) : (
            ""
          )}
        </ol>
      </nav>
    </>
  );
};

export const BreadCrumbForForum = (props) => {
  const {
    RoleAccessDefinitions = null,
    SpecialRoleExist,
    originalPost = {},
  } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);

  /*getting url and convert into array*/
  const [getPath, moreLangs] = CurrentLocation();
  const arrayLocation = getPath.slice(1).split("/");

  /*Translation*/
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const bread_crumb_menus = arrayLocation.map((crumb) =>
    _.get(moreLangs, `${resolvedLanguage}.menu_${crumb.replace(/-/g, "_")}`, "")
  );
  const bread_crumbs = RoleCheckerForArrayData(
    bread_crumb_menus,
    "url",
    gbsRolesByPerson,
    gbsRolesByContract,
    SpecialRoleExist,
    RoleAccessDefinitions
  );
  // console.log({ arrayLocation, bread_crumb_menus, bread_crumbs });
  const subtitle = originalPost?.title;
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb top-mspace-30">
          <li className="breadcrumb-item text-capitalize">
            <Link
              to="/"
              className="text-decoration-underline fw-bold blue_color"
            >
              {moreLangs[resolvedLanguage].menu_home.title}
            </Link>
          </li>
          {bread_crumbs.map((menu, index) => {
            const lastElement =
              index + 1 === bread_crumbs.length ? false : true;
            const active = index + 1 === bread_crumbs.length ? "active" : "";
            return (
              /*there is a condition localitation file should have menu data*/
              menu ? (
                <li
                  key={`${index}`}
                  className={`contact-breadcrumb breadcrumb-item text-capitalize ${active} ${
                    bread_crumbs.length > 1 && !lastElement && "last-link"
                  }`}
                  aria-current="page"
                >
                  {lastElement ? (
                    <Link
                      to={`${menu.url}`}
                      className="text-decoration-underline fw-bold blue_color"
                    >
                      {menu.title}
                    </Link>
                  ) : bread_crumbs.length > 1 && !lastElement ? (
                    ` : ${menu.title}`
                  ) : (
                    menu.title
                  )}
                </li>
              ) : (
                <Fragment key={`${index}`} />
              )
            );
          })}
          {IsStringChecker(subtitle) ? (
            <li className="breadcrumb-item last-link text-capitalize">
              {`: ${subtitle}`}
            </li>
          ) : (
            ""
          )}
        </ol>
      </nav>
    </>
  );
};

export const BreadCrumbForNewsEvents = (props) => {
  const {
    RoleAccessDefinitions = null,
    SpecialRoleExist,
    harvest = {},
    newsEvents = null,
    type = null,
  } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);

  /*getting url and convert into array*/
  const [, moreLangs] = CurrentLocation();

  /*Translation*/
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const bread_crumb_menus = [];
  const subtitle = harvest["title_" + resolvedLanguage];
  if (newsEvents) {
    bread_crumb_menus.push({
      url: "/community/news-events",
      title: "Community : News & Events",
      subtitle: IsStringChecker(subtitle) ? `/ ${subtitle}` : "",
    });
  } else {
    if (type) {
      bread_crumb_menus.push({
        url: "/community/news",
        title: "Community : News",
        subtitle: IsStringChecker(subtitle) ? `/ ${subtitle}` : "",
      });
    } else {
      bread_crumb_menus.push({
        url: "/community/events",
        title: "Community : Events",
        subtitle: IsStringChecker(subtitle) ? `/ ${subtitle}` : "",
      });
    }
  }
  const bread_crumbs = RoleCheckerForArrayData(
    bread_crumb_menus,
    "url",
    gbsRolesByPerson,
    gbsRolesByContract,
    SpecialRoleExist,
    RoleAccessDefinitions
  );
  // console.log({ bread_crumb_menus, bread_crumbs });
  return (
    <>
      <nav aria-label="breadcrumb" className="">
        <ol className="breadcrumb top-mspace-30">
          <li className="breadcrumb-item">
            <Link
              to="/"
              className="text-decoration-underline fw-bold blue_color text-capitalize"
            >
              {moreLangs[resolvedLanguage].menu_home.title}
            </Link>
          </li>
          {IsArrayNotEmpty(bread_crumbs) ? (
            bread_crumbs.map((li, index) => (
              <li
                key={index}
                className="breadcrumb-item active"
                aria-current="page"
              >
                <Link
                  to={li?.url}
                  className="text-decoration-underline fw-bold"
                >
                  {li?.title}
                </Link>{" "}
                <span className="news_text_ellipsis">{li?.subtitle}</span>
                <span>...</span>
              </li>
            ))
          ) : (
            <></>
          )}
        </ol>
      </nav>
    </>
  );
};

export const BreadCrumbForOSContacts = (props) => {
  const {
    RoleAccessDefinitions = null,
    SpecialRoleExist,
    ContactDetail = {},
  } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);

  /*getting url and convert into array*/
  const [getPath, moreLangs] = CurrentLocation();
  const arrayLocation = getPath.slice(1).split("/");

  /*Translation*/
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);

  const bread_crumb_menus = arrayLocation.map((crumb) =>
    _.get(moreLangs, `${resolvedLanguage}.menu_${crumb.replace(/-/g, "_")}`, "")
  );
  const bread_crumbs = RoleCheckerForArrayData(
    bread_crumb_menus,
    "url",
    gbsRolesByPerson,
    gbsRolesByContract,
    SpecialRoleExist,
    RoleAccessDefinitions
  );
  // console.log({ arrayLocation, bread_crumb_menus, bread_crumbs });
  const subtitle = ContactDetail["name_" + resolvedLanguage];
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb top-mspace-30">
          <li className="breadcrumb-item text-capitalize">
            <Link
              to="/"
              className="text-decoration-underline fw-bold blue_color"
            >
              {moreLangs[resolvedLanguage].menu_home.title}
            </Link>
          </li>
          {bread_crumbs.map((menu, index) => {
            const lastElement =
              index + 1 === bread_crumbs.length ? false : true;
            const active = index + 1 === bread_crumbs.length ? "active" : "";
            return (
              /*there is a condition localitation file should have menu data*/
              menu ? (
                <li
                  key={`${index}`}
                  className={`contact-breadcrumb breadcrumb-item text-capitalize ${active} ${
                    bread_crumbs.length > 1 && !lastElement && "last-link"
                  }`}
                  aria-current="page"
                >
                  {lastElement ? (
                    <Link
                      to={`${menu.url}`}
                      className="text-decoration-underline fw-bold blue_color"
                    >
                      {menu.title}
                    </Link>
                  ) : bread_crumbs.length >= 1 && !lastElement ? (
                    <>
                      {bread_crumbs.length !== 1 ? " / " : ""}
                      <Link
                        to={`${menu.url}`}
                        className="text-decoration-underline fw-bold blue_color"
                      >
                        {menu.title}
                      </Link>
                    </>
                  ) : (
                    ` : ${menu.title}`
                  )}
                </li>
              ) : (
                <Fragment key={`${index}`} />
              )
            );
          })}
          {IsStringChecker(subtitle) ? (
            <li className="breadcrumb-item last-link">{`: ${subtitle}`}</li>
          ) : (
            ""
          )}
        </ol>
      </nav>
    </>
  );
};

const BreadCrumb = (props) => {
  const { RoleAccessDefinitions = null, SpecialRoleExist } = props;
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const gbsRolesByPerson = _.get(
    accounts,
    `0.idTokenClaims.gbsRolesByPerson`,
    []
  );
  const gbsRolesByContract = ContractsFromJSONParser(accounts);

  /*getting url and convert into array*/
  const [getPath, moreLangs] = CurrentLocation();
  const arrayLocation = getPath.slice(1).split("/");
  /*Translation*/
  const { i18n } = useTranslation();
  const bread_crumb_menus = arrayLocation.map(
    (crumb) =>
      moreLangs[i18n.resolvedLanguage]["menu_" + crumb.replace(/-/g, "_")]
  );
  // console.log({ arrayLocation, bread_crumb_menus });
  const bread_crumbs = RoleCheckerForArrayData(
    bread_crumb_menus,
    "url",
    gbsRolesByPerson,
    gbsRolesByContract,
    SpecialRoleExist,
    RoleAccessDefinitions
  );
  return (
    <>
      <nav aria-label="breadcrumb" className="">
        <ol className="breadcrumb top-mspace-30">
          <li className="breadcrumb-item text-capitalize">
            <Link
              to="/"
              className="text-decoration-underline fw-bold blue_color"
            >
              {moreLangs[i18n.resolvedLanguage].menu_home.title}
            </Link>
          </li>
          {bread_crumbs.map((menu, index) => {
            const lastElement =
              index + 1 === bread_crumbs.length ? false : true;
            const active = index + 1 === bread_crumbs.length ? "active" : "";
            // console.log({ menu });
            return (
              /*there is a condition localitation file should have menu data*/
              IsObjectChecker(menu) ? (
                <li
                  key={`${index}`}
                  className={`breadcrumb-item text-capitalize ${active} ${
                    bread_crumbs.length > 1 && !lastElement && "last-link"
                  }`}
                  aria-current="page"
                >
                  {lastElement ? (
                    <Link
                      to={`${menu.url}`}
                      className="text-decoration-underline fw-bold blue_color"
                    >
                      {menu.title}
                    </Link>
                  ) : bread_crumbs.length > 1 && !lastElement ? (
                    ` : ${menu.title}`
                  ) : (
                    menu.title
                  )}
                </li>
              ) : (
                <Fragment key={`${index}`} />
              )
            );
          })}
        </ol>
      </nav>
    </>
  );
};

export default BreadCrumb;
