/**
 * @component_Name : ExhibitMaps
 * @description : Show ExhibitMaps grid list
 * @company : Photon
 * @author : Ashish Ranjan/22-08-2022
 **/
import React, { useEffect, useState } from "react";
import "../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  getCurrentYear,
  convertFilterArray,
  getFilteredData,
  IsTokenChecker,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  IsArrayNotEmpty,
  setObjToString,
  safeArrayCheck,
  IsStringChecker,
  setOptions,
} from "../../../utils/helper";
import { ConstantsVar } from "../../../utils/constants";
import WhiteCircleLoader from "../../whiteCircleLoader";
import Loading from "../../../components/global/loading";
import ExhibitMapsMobile from "./exhibitMapsMobile";
import ExhibitMapsPagination from "./exhibitMapsPagination";
import moment from "moment";
//import _ from "lodash";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import { useTranslation } from "react-i18next";
import CurrentLocation from "../../global/currentLocation";
import { useGbsDataCache } from "../../../services/gbsDataCache";

const ExhibitMaps = (props) => {
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();
  const { DropDownCBRoles = [] } = props;
  const ongoingYear = getCurrentYear();

  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0]?.idTokenClaims?.gbsPersonId;
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cropyearPreselect, setCropYearPreselect] = useState([]);
  const [multiCrop, setMultiCrop] = useState({});
  const [listCropYear, setListCropYear] = useState([]);
  const [reloadCropYear, setReloadCropYear] = useState(true);

  const [multiContId, setMultiContId] = useState({});
  const [ContractOption, setContractOption] = useState([]);
  const [ContractIds, setContractIds] = useState([]);
  const [contractPreselect, setContractPreselect] = useState([]);
  const [reloadContract, setReloadContract] = useState(true);

  const [isReset, setIsReset] = useState(true);
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [isMobFilter, setisMobFilter] = useState(false);

  const [gridItems, setGridItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [mobileItems, setMobileItems] = useState([]);

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  useEffect(() => {
    if (data.data) {
      fetchExhibitMap(data?.data, changeEvent === true ? true : false);
    }
  }, [data, changeEvent, status]);
  //Cache Implementation End

  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    setLoader(true);
    IsTokenChecker(tokenId) && fetchCropYearAndContract();
    setDisplayAccordian(
      localStorage.getItem("safariLowerVersion") === "true" || false
    );
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  useEffect(() => {
    const contractAccess = multiContId && multiContId?.contract_id;
    const cropyearAccess = multiCrop && multiCrop?.cropyear;
    if (contractAccess && cropyearAccess) {
      setIsReset(false);
      setSearchBtnDisabled(false);
    } else if (contractAccess || cropyearAccess) {
      setIsReset(false);
      setSearchBtnDisabled(true);
    } else {
      setIsReset(true);
      setSearchBtnDisabled(true);
    }
  }, [multiContId, multiCrop]);

  const fetchExhibitMap = async (data) => {
    let resultData = safeArrayCheck(data);
    if (IsArrayNotEmpty(resultData)) {
      let filteredData = FilterContractByRole(
        resultData,
        accounts,
        DropDownCBRoles,
        "Id"
      );
      filteredData.forEach((r) => {
        r.GroupingKey = `${r.CropYear}_${r.MapId}`;
      });

      if (IsArrayNotEmpty(filteredData)) {
        setFilterItems(filteredData);

        const contractListopts = setOptions(
          filteredData,
          "Id",
          "RelationshipNumber",
          "Name"
        );
        const contractList = _.orderBy(
          contractListopts,
          ["ContractStatus", "RelationshipNumber"],
          ["asc", "asc"]
        );

        if (IsArrayNotEmpty(contractList)) {
          setContractOption(contractList);
          if (contractList.length === 1) {
            const temp = contractList[0];
            setReloadContract(false);
            setTimeout(() => {
              setContractPreselect([temp?.id]);
              setMultiContId({ contract_id: `${temp?.id}` });
              setReloadContract(true);
            }, 0);
            const defaultListData = filteredData.filter((item) => {
              return (
                setObjToString([temp])
                  ?.toLowerCase()
                  ?.indexOf(item?.Id?.toString().toLowerCase()) !== -1
              );
            });
            if (IsArrayNotEmpty(defaultListData)) {
              setIsListingHide(false);
              setNoRecordErrorMsg("");
              MobileDataGetter(defaultListData);
              setGridItems(defaultListData);
              setisMobFilter(false);
            } else {
              ListDataViewReset();
            }
          } else {
            ListDataViewReset();
          }
        } else {
          setContractOption([]);
          ListDataViewReset();
        }
        /*End*/
      } else {
        setContractOption([]);
        ListDataViewReset(true);
      }
    } else {
      setContractOption([]);
      ListDataViewReset(true);
    }
    setLoader(false);
  };

  const fetchCropYearAndContract = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then(async (resp) => {
        const cropy = safeArrayCheck(resp?.data);
        if (IsArrayNotEmpty(cropy)) {
          const currentcropData = cropy.find((x) => x.CurrentCrop === true);
          const currentCropYear = currentcropData?.CropYear || ongoingYear;
          const cropYear = cropy.splice(0, 15);
          const multiSelectCropYears = convertFilterArray(cropYear, {
            id: "CropYear",
            name: "CropYear",
          });
          await gbsDataCommonMethod(
            tokenId,
            accounts,
            `persons/${personId}/contracts`
          )
            .then((resp) => {
              const data = safeArrayCheck(resp?.data);
              let filteredData = FilterContractByRole(
                data,
                accounts,
                DropDownCBRoles
              );
              filteredData = _.orderBy(filteredData,
                ["ContractStatus", "RelationshipNumber"],
                ["asc", "asc"]
              );

              if (IsArrayNotEmpty(filteredData)) {
                setListCropYear(multiSelectCropYears);
                setReloadCropYear(false);
                setTimeout(() => {
                  setCropYearPreselect([`${currentCropYear}`]);
                  setMultiCrop({ cropyear: `${currentCropYear}` });
                  setReloadCropYear(true);
                }, 0);
                const contractId = filteredData
                  .map((x) => x["RelationshipId"])
                  ?.toString();
                setContractIds(contractId);
                TriggerFetchAPI(`${currentCropYear}`, contractId);
              }
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            });
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function TriggerFetchAPI(cropyears, contractIDs) {
    if (IsStringChecker(cropyears) && IsStringChecker(contractIDs)) {
      const url = `bed-histories?cropyear=${_.orderBy(
        cropyears.toString().split(","),
        [],
        ["asc"]
      )}&relationshipid=${_.orderBy(
        contractIDs.toString().split(","),
        [],
        ["asc"]
      )}&includeexhibitpdf=1`;
      setSearchURL(url);
      url === searchURL && setChangeEvent(changeEvent + 1);
    }
  }

  function ListDataViewReset(full = false) {
    setIsListingHide(true);
    setNoRecordErrorMsg("");
    setGridItems([]);
    setMobileItems([]);
    if (full) {
      setFilterItems([]);
    }
  }

  function EmptyWithRefreshDropDowns(contractR = true, cropyearR = true) {
    if (contractR && cropyearR) {
      setReloadContract(false);
      setReloadCropYear(false);
      setTimeout(() => {
        setMultiCrop({});
        setCropYearPreselect([]);
        setMultiContId({});
        setContractPreselect([]);

        setReloadContract(true);
        setReloadCropYear(true);
      }, 0);
    } else {
      if (contractR) {
        setReloadContract(false);
        setTimeout(() => {
          setMultiContId({});
          setContractPreselect([]);

          setReloadContract(true);
        }, 0);
      } else if (cropyearR) {
        setReloadCropYear(false);
        setTimeout(() => {
          setMultiCrop({});
          setCropYearPreselect([]);

          setReloadCropYear(true);
        }, 0);
      }
    }
  }

  const handlerContract = () => {
    ListDataViewReset();
  };

  const handlerContractDel = (selectedList, removedItem) => {
    ListDataViewReset();
    if (selectedList.length === 0 || removedItem.id === "all") {
      EmptyWithRefreshDropDowns(true, false);
    }
  };

  const handlerCropYear = (val) => {
    ListDataViewReset();
    const cropyears = setObjToString(val);
    TriggerFetchAPI(cropyears, ContractIds);
  };

  const handlerCropYearDel = (selectedList, removedItem) => {
    ListDataViewReset();
    if (selectedList.length === 0 || removedItem.id === "all") {
      EmptyWithRefreshDropDowns(false, true);
    } else {
      const cropyears = setObjToString(selectedList);
      TriggerFetchAPI(cropyears, ContractIds);
    }
  };

  // On click search
  const handlerSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (IsArrayNotEmpty(filterItems)) {
      setLoading(true); /*set loader to search button*/
      let filter = [...filterItems];
      if (multiContId?.contract_id) {
        filter = getFilteredData(filter, multiContId.contract_id, "Id");
      }
      if (IsArrayNotEmpty(filter)) {
        MobileDataGetter(filter);
        setIsListingHide(false);
        setGridItems(filter);
        setNoRecordErrorMsg("");
        setisMobFilter(false);
      } else {
        setIsListingHide(true);
        setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
      }
      setLoading(false); /*set loader to search button*/
    }
  };

  function MobileDataGetter(filterData) {
    try {
      if (IsArrayNotEmpty(filterData)) {
        const overWirteMobData = Object.values(filterData).map((item) => {
          let contractName = "";
          if (item.Name != null) {
            contractName = item.Name;
          }
          if (item.Name2 != null) {
            contractName = contractName + " " + item.Name2;
          }
          if (item.ContractIdentifier != null) {
            contractName = contractName + " " + item.ContractIdentifier;
          }
          return {
            CropYear: item.CropYear,
            Contract_Number: item.RelationshipNumber,
            Contract_Name: contractName,
            ExhibitAcres: item.ExhibitAcres,
            MapId: item.MapId,
            MapPage: item.MapPage,
            MapPageOf: item.MapPageOf,
            ExhibitMapDate: item.ExhibitMapDate
              ? moment.utc(item.ExhibitMapDate).format(ConstantsVar.dateFormate)
              : null,
            ExhibitSatelliteStill: item.ExhibitSatelliteStill,
            ExhibitMapPdfUrl: item.ExhibitMapPdfUrl,
            GroupingKey: item.GroupingKey,
          };
        });
        const filteredMobileData = IsArrayNotEmpty(overWirteMobData)
          ? overWirteMobData.filter((list) => list.ExhibitMapDate)
          : [];
        setMobileItems(filteredMobileData);
      } else {
        setMobileItems([]);
      }
    } catch (e) {
      setMobileItems([]);
    }
  }

  const handleReset = (e) => {
    e.stopPropagation();
    ListDataViewReset();
    EmptyWithRefreshDropDowns();
    setIsReset(true);
    setSearchBtnDisabled(true);
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  return (
    <>
      {/*<!-- Acreage history block starts -->*/}
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>
        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"></span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>

        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3 hide_first_option">
              <div className="crop_year_label custom_dropdown">
                {/*<!-- <span>Crop Year</span> -->*/}
                {reloadCropYear && (
                  <MultiDropDown
                    optionList={listCropYear}
                    prompt="Select"
                    value={multiCrop}
                    onChange={(val) => handlerCropYear(val)}
                    label="cropyear"
                    usestate={setMultiCrop}
                    disabled=""
                    onDelete={(val, removedItem) =>
                      handlerCropYearDel(val, removedItem)
                    }
                    preSelectedOpt={cropyearPreselect}
                    // disablePreSelected={disablePreSelected}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {/*<!-- <span>Contract Number</span> -->*/}
                {reloadContract && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) => handlerContract(val)}
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled=""
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    preSelectedOpt={contractPreselect}
                    optionNotFound={{ contract_id: noContractsFound }}
                  />
                )}
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                data-category="Web"
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>

        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? "show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            data-category="mobile"
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>
      {/*<!-- Filter Dropdown ends -->*/}
      {isListingHide == false ? (
        <>
          {!displayAccordian && (
            <ExhibitMapsPagination
              itemsPerPage={3}
              listingData={gridItems}
              ongoingYear={ongoingYear}
            />
          )}
          <>
            {/*<!-- Responsive table starts -->*/}
            <div
              className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0  ${
                !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
              }`}
            >
              <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
                <ExhibitMapsMobile
                  listingData={mobileItems}
                  ongoingYear={ongoingYear}
                />
              </div>
            </div>
          </>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
    </>
  );
};

export default ExhibitMaps;
