import React, { useState, useEffect } from "react";
import BreadCrumb from "../../global/breadCrumb";
import LeftMenu from "../../global/leftMenu";
import QuickLinks from "../../home/quickLinks";
import Events from "../../home/siteNotification";
import News from "../../home/news";
import Loading from "../../global/loading";
import CurrentLocation from "../../global/currentLocation";
// import { useTranslation } from "react-i18next";
// import _ from "lodash";
import {IsStringChecker } from "../../../utils/helper";

const NewsAndEventsDetails = (props) => {
  const [loading, setParentLoading] = useState(true);

  // const { i18n } = useTranslation();
  // const resolvedLanguage = getResolvedLanguage(i18n);
  const [getPath] = CurrentLocation();
  const newsEvents = IsStringChecker(getPath)
    ? getPath.includes("community/news-events")
      ? true
      : false
    : false;

  const [loadMoreLimit, setLoadMoreLimit] = useState(10);
  const [limit, setLimit] = useState(loadMoreLimit);
  // const [displayLoadMore, setDisplayLoadMore] = useState(true);

  // const loadMore = () => {
  //   setLimit((prev) => prev + loadMoreLimit);
  // };

  // removing news sesstion
  if(sessionStorage.getItem("newsfrom") !== "news"){
    sessionStorage.setItem("newsfrom","news");
    sessionStorage.removeItem("newslimit");
    sessionStorage.removeItem("newscategory");      
    sessionStorage.removeItem("newsloadmorelimit");
    sessionStorage.removeItem("selecteddetailpage");
  }

  useEffect(()=>{
    setLimit(loadMoreLimit);
  },[loadMoreLimit])

  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {
        <div className="row">
          <div className="row d-none d-lg-flex">
            <div className=" col-12 offset-lg-2 col-lg-8">
              <BreadCrumb {...props} />
            </div>
          </div>
          <div className="row">
            <div className="d-none d-lg-block col-lg-2 hidden_print">
              <LeftMenu {...props} slug="news-and-events" />
              <QuickLinks {...props} slug="news-and-events" />
            </div>
            <div className="col-12 col-lg-10 zooming_block">
              <div className="row">
                <div className="col-12">
                  <div className="mt-5 mt-lg-0 mb-lg-4 hidden_print">
                    <Events type="news-and-events" />
                  </div>
                  <div className="mt-4 mt-lg-0">
                    <News
                      selectedNews={newsEvents ? "0" : "news"}
                      setParentLoading={setParentLoading}
                      // setDisplayLoadMore={setDisplayLoadMore}
                      limit={limit}
                      setLimit={setLimit}
                      newsEvents={newsEvents}
                      setLoadMoreLimit={setLoadMoreLimit}
                      loadMoreLimit={loadMoreLimit}
                    />
                    {/* {displayLoadMore && (
                      <div className="mb-4">
                        <button
                          className="btn btn-primary width-100"
                          onClick={loadMore}
                        >
                          {_.get(
                            moreLangs,
                            `${resolvedLanguage}.home_page.load_more`,
                            "Load More"
                          )}
                        </button>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default NewsAndEventsDetails;
