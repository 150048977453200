import React, { useState, useEffect, Fragment } from "react";
import MultiDropDown from "../../../selectBox/multiDropDown";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { gbsDataCommonMethod } from "../../../../services/gbsData";
import Loading from "../../../global/loading";
import "react-datepicker/dist/react-datepicker.css";
import { dateFormate, notFoundMsg } from "../../../../utils/constantsVariable";
import {
  getFullDate,
  toDecimalORCurrency,
  IsTokenChecker,
  fieldSorter,
  getResolvedLanguage,
  HandleErrorTrackAndToast,
  safeArrayCheck,
  IsArrayNotEmpty,
  IsStringChecker,
} from "../../../../utils/helper";
import DataGridPagination from "../../../global/dataGridPagination";
import { Accordion } from "react-bootstrap";
import Moment from "react-moment";
import moment from "moment";
import ExportFile from "../../../exportFile/exportFile";
import PdfFile from "../../../exportFile/pdfFile";
import WhiteCircleLoader from "../../../whiteCircleLoader";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../../permissionWrapper";
import { useGbsDataCache } from "../../../../services/gbsDataCache";
import CurrentLocation from "../../../global/currentLocation";
import DatePickerComp from "../../../global/datepicker";

export const PaymentSearch = (props) => {
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const tokenId = useSelector((state) => state.reducerToken);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const { DropDownCBRoles = [] } = props;

  const [multiValue, setMultiValue] = useState({});
  const [CY_MultiValue, setCY_MultiValue] = useState({});
  const [CN_MultiValue, setCN_MultiValue] = useState({});

  const [filterItems, setFilterItems] = useState([]);
  const [paymentSearchResult, setPaymentSearchResult] = useState([]);
  const [paymentSearchResultStatus, setPaymentSearchResultStatus] =
    useState(false);

  const [preSelectedCropyear, setPreSelectedCropyear] = useState([]);
  const [preSelectedContract, setPreSelectedContract] = useState([]);
  const [reloadMultiSelect, setReloadMultiSelect] = useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [CropYears, setCropYears] = useState([]);
  const [ContractNumbers, setContractNumbers] = useState([]);
  const [isMobFilter, setisMobFilter] = useState(false);

  const [hideExport, setHideExport] = useState(true);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const [isReset, setIsReset] = useState(true);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  //Cache Implementation
  const [paymentSearchURL, setPaymentSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    paymentSearchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  useEffect(() => {
    if (data.data) {
      getPaymentSearchDataCahce(data);
    }
    // if (status === "error") {
    //   HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
    // }
  }, [data, changeEvent, status]);

  useEffect(() => {
    const cropAccess = CY_MultiValue && CY_MultiValue?.cropyear;
    const contractAccess = CN_MultiValue && CN_MultiValue?.contractid;
    if (cropAccess && contractAccess) {
      setIsReset(false);
    } else {
      if (cropAccess || contractAccess) {
        setIsReset(false);
      } else {
        setIsReset(true);
      }
    }
  }, [CN_MultiValue, CY_MultiValue]);

  //Defining Grid Column
  const columnsArray = [
    {
      key: "RelationshipNumber",
      name: "Contract Number",
      headerCellClass: "enablesTextWrappingHeading",
      width: 100,
    },
    {
      key: "Name",
      name: "Contract Name",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 100,
    },
    {
      key: "ContractIdentifier",
      name: "Identifier",
      cellClass: "enablesTextWrapping",
      width: 100,
      formatter(props) {
        return <>{props.row.ContractIdentifier || "-"}</>;
      },
    },
    {
      key: "SettlementDate",
      name: "Payment Date",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 100,
      dateFormate: dateFormate,
      formatter(props) {
        return (
          <Moment utc format={dateFormate}>
            {props.row.SettlementDate}
          </Moment>
        );
      },
    },
    {
      key: "CropYear",
      name: "Crop Year",
      headerCellClass: "grid_column_center enablesTextWrappingHeading",
      cellClass: "text-lg-center",
      width: 70,
    },
    {
      key: "Amount",
      name: "Amount",
      headerCellClass: "grid_column_right",
      cellClass: "text-lg-end",
      width: 110,
      formatter(props) {
        return <>{toDecimalORCurrency(props.row.Amount, 2, "$") || "-"}</>;
      },
    },
    {
      key: "UnitesORShares",
      name: "Units / Shares",
      headerCellClass: "grid_column_right enablesTextWrappingHeading",
      cellClass: "text-lg-end",
      width: 110,
      formatter(props) {
        return <>{toDecimalORCurrency(props.row.UnitesORShares, 2) || "-"}</>;
      },
    },
    {
      key: "Description",
      name: "Description",
      cellClass: "enablesTextWrapping",
      width: 255,
      formatter(props) {
        return <>{props.row.Description || "-"}</>;
      },
    },
    // {
    //   key: "Assignee",
    //   name: "Assignee / Pledgee",
    //   headerCellClass: "enablesTextWrappingHeading",
    //   cellClass: "enablesTextWrapping",
    //   width: 110,
    // },
  ];
  const [displayAccordian, setDisplayAccordian] = useState(false);

  useEffect(() => {
    if (IsTokenChecker(tokenId)) {
      setLoader(true);
      fetCropYearAndContract();
      setDisplayAccordian(
        localStorage.getItem("safariLowerVersion") === "true" || false
      );
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  async function fetCropYearAndContract() {
    await gbsDataCommonMethod(tokenId, accounts, `crop-years`)
      .then(async (resp) => {
        const cropyears = safeArrayCheck(resp?.data);
        if (IsArrayNotEmpty(cropyears)) {
          const getcurrentcrop = cropyears.find((x) => x.CurrentCrop === true);
          const currentcropData =
            getcurrentcrop?.CropYear || cropyears[0]?.CropYear;
          const tempCropyears = [...cropyears].splice(0, 15);
          setCropYears(setOptions(tempCropyears, "CropYear", true));
          await gbsDataCommonMethod(
            tokenId,
            accounts,
            `persons/${personId}/contracts`
          )
            .then((res) => {
              const resultData = safeArrayCheck(res?.data);
              if (IsArrayNotEmpty(resultData)) {
                let filteredData = FilterContractByRole(
                  resultData,
                  accounts,
                  DropDownCBRoles
                );
                if (IsArrayNotEmpty(filteredData)) {
                  const defaultContractNumber = filteredData[0]?.RelationshipId;
                  filteredData = _.orderBy(filteredData,
                    ["ContractStatus", "RelationshipNumber"],
                    ["asc", "asc"]
                  );
                  setContractNumbers(
                    setOptions(
                      filteredData,
                      "RelationshipId",
                      false,
                      "RelationshipNumber",
                      "Name"
                    )
                  );
                  if (defaultContractNumber && currentcropData) {
                    setReloadMultiSelect(false);
                    setTimeout(() => {
                      setPreSelectedCropyear([`${currentcropData}`]);
                      setPreSelectedContract([`${defaultContractNumber}`]);
                      setDisableSubmitCancel(false);
                      setDisabled(false);
                      setCN_MultiValue({
                        contractid: `${defaultContractNumber}`,
                      });
                      setCY_MultiValue({ cropyear: `${currentcropData}` });
                      setReloadMultiSelect(true);
                    }, 0);
                    let url = `payments?relationshipid=${defaultContractNumber}&cropyear=${currentcropData}`;
                    setPaymentSearchURL(url);
                    url === paymentSearchURL && setChangeEvent(changeEvent + 1);
                  }
                }
              }
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            });
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  // On click search
  const onAllSearch = () => {
    setLoading(true);
    setTimeout(() => {
      let filter = [...filterItems];
      if (CN_MultiValue?.contractid) {
        filter = filterItems.filter((item) => {
          return (
            CN_MultiValue?.contractid
              ?.toLowerCase()
              ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
          );
        });
      }
      if (multiValue?.datefrom || multiValue?.dateto) {
        filter = filter.filter((item) => {
          let dateFrom =
            multiValue?.datefrom &&
            moment.utc(multiValue.datefrom).format(dateFormate);
          let dateto =
            multiValue?.dateto &&
            moment.utc(multiValue.dateto).format(dateFormate);
          let SettlementDate = moment
            .utc(item.SettlementDate)
            .format(dateFormate);
          return moment(SettlementDate).isBetween(
            dateFrom,
            dateto,
            undefined,
            "[]"
          );
        });
      }
      filter = filter.sort(
        fieldSorter(["RelationshipNumber", "d-SettlementDate"])
      );
      if (
        filter.length > 0 &&
        filter !== "Contract and CropYear must be provided"
      ) {
        setPaymentSearchResult(filter);
        setPaymentSearchResultStatus(true);
        setHideExport(false);
        setNoResultsFound(false);
      } else {
        setPaymentSearchResult([]);
        setPaymentSearchResultStatus(false);
        setHideExport(true);
        setNoResultsFound(true);
      }
      setLoading(false);
      handlerMobFilter("cancel");
    }, 100);
  };

  // Get Grid API values
  const getPaymentSearchDataCahce = async (resp) => {
    let resultData = safeArrayCheck(resp?.data);
    let filteredData = IsArrayNotEmpty(resultData)
      ? FilterContractByRole(resultData, accounts, DropDownCBRoles)
      : [];
    if (IsArrayNotEmpty(filteredData)) {
      filteredData = filteredData.sort((a, b) => {
        return moment(b.SettlementDate) - moment(a.SettlementDate);
      });
      filteredData.map((item) => {
        item.Assignee = item.PayeeName;
        item.UnitesORShares =
          item.Units !== null && item.Units !== 0 && item.Units !== ""
            ? item.Units
            : item.Shares;
        return item;
      });
      filteredData = filteredData.filter((item) => {
        return item?.ActivityCodeKey.toString() !== "79";
      });
      filteredData = filteredData.sort(
        fieldSorter(["RelationshipNumber", "-SettlementDate"])
      );
      setFilterItems(filteredData);
    } else {
      setFilterItems([]);
    }
  };

  const onClearSearch = () => {
    setEndDate("");
    setStartDate("");
    setDisabled(true);
    setMultiValue(null);
    setCN_MultiValue(null);
    setCY_MultiValue(null);
    setDisableSubmitCancel(true);
    setPreSelectedContract([]);
    setPreSelectedCropyear([]);
    setFilterItems([]);
    clearGridData();
  };

  // Contract select change event
  const onContractNumberChange = (val) => {
    let ContractIds = setObjToString(_.orderBy(val, ["id"], ["asc"]));
    if (
      IsStringChecker(ContractIds) &&
      IsStringChecker(CY_MultiValue?.cropyear)
    ) {
      setDisabled(false);
      setDisableSubmitCancel(false);
      let url = `payments?relationshipid=${ContractIds}&cropyear=${CY_MultiValue.cropyear}`;
      setPaymentSearchURL(url);
      url === paymentSearchURL && setChangeEvent(changeEvent + 1);
    }
    clearGridData();
  };

  //crop year select change event
  const onCropYearChange = (val) => {
    let cropYears = setObjToString(_.orderBy(val, ["id"], ["asc"]));
    if (
      IsStringChecker(cropYears) &&
      IsStringChecker(CN_MultiValue?.contractid)
    ) {
      setDisabled(false);
      setDisableSubmitCancel(false);
      let url = `payments?relationshipid=${CN_MultiValue.contractid}&cropyear=${cropYears}`;
      setPaymentSearchURL(url);
      url === paymentSearchURL && setChangeEvent(changeEvent + 1);
      //getPAymentSearchData(url);
    }
    clearGridData();
  };

  // Contract deselect change event
  const onContractNumberRemove = (selectedList, removedItem) => {
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      setDisableSubmitCancel(true);
    } else {
      let ContractIds = setObjToString(
        _.orderBy(selectedList, ["id"], ["asc"])
      );
      if (
        IsStringChecker(ContractIds) &&
        IsStringChecker(CY_MultiValue?.cropyear)
      ) {
        setDisabled(false);
        setDisableSubmitCancel(false);
        let url = `payments?relationshipid=${ContractIds}&cropyear=${CY_MultiValue.cropyear}`;
        setPaymentSearchURL(url);
        url === paymentSearchURL && setChangeEvent(changeEvent + 1);
        //getPAymentSearchData(url);
      }
    }
    clearGridData();
  };

  //crop year deselect change event
  const onCropYearRemove = (selectedList, removedItem) => {
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      setDisableSubmitCancel(true);
    } else {
      let cropYears = setObjToString(_.orderBy(selectedList, ["id"], ["asc"]));
      if (
        IsStringChecker(cropYears) &&
        IsStringChecker(CN_MultiValue?.contractid)
      ) {
        setDisabled(false);
        setDisableSubmitCancel(false);
        let url = `payments?relationshipid=${CN_MultiValue.contractid}&cropyear=${cropYears}`;
        setPaymentSearchURL(url);
        url === paymentSearchURL && setChangeEvent(changeEvent + 1);
        //getPAymentSearchData(url);
      }
    }
    clearGridData();
  };

  // creating option for mulit select
  const setOptions = (data, keyPrram, unique, concatValue1, concatValue2) => {
    if (unique && keyPrram !== "RelationshipId") {
      return data
        .map((li) => li[keyPrram])
        .filter(onlyUnique)
        .map((li) => {
          return { name: li, id: li };
        });
    } else {
      const contracts = data.map((li) => {
        return concatValue1 && concatValue2
          ? {
              name: li[concatValue1] + " " + li[concatValue2],
              id: li[keyPrram],
            }
          : { name: li[keyPrram], id: li[keyPrram] };
      });
      return [...new Map(contracts.map((item) => [item["id"], item])).values()];
    }
  };

  // filter Uniqu values
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  // Mobile filter change event
  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  //Datepicket event
  const handlerDate = (param, type) => {
    if (type == "start") {
      // setendMinDate(param);
      setStartDate(param);
      let fulldate = getFullDate(param, "yyyy-mm-dd");
      setMultiValue((multiValue) => ({
        ...multiValue,
        ["datefrom"]: fulldate,
      }));
    } else if (type == "end") {
      setEndDate(param);
      // setfromMinDate(param);
      let fulldate = getFullDate(param, "yyyy-mm-dd");
      setMultiValue((multiValue) => ({ ...multiValue, ["dateto"]: fulldate }));
    }
    clearGridData();
  };

  // convert object to string
  const setObjToString = (val) => {
    let ids = val.map((x) => x.id);
    ids = ids.filter((x) => x != "all" && x).toString();
    return ids;
  };

  // Converting raw data to Arranged Data
  const ArrangedData = (resultData, type) => {
    let arrangedData = resultData.map((li) => {
      if (type === "pdf") {
        return {
          "Contract Number": li.RelationshipNumber,
          "Contract Name": li.Name,
          Identifier: li.ContractIdentifier || "-",
          "Payment Date": moment.utc(li.SettlementDate).format(dateFormate),
          "Crop Year": li.CropYear,
          Amount: toDecimalORCurrency(li.Amount, 2, "$") || "-",
          "Units / Shares": toDecimalORCurrency(li.UnitesORShares, 2) || "-",
          Description: li.Description,
          // Assignee: li.Assignee,
        };
      } else {
        return {
          "Contract Number": li.RelationshipNumber,
          "Contract Name": li.Name,
          Identifier: li.ContractIdentifier || "",
          "Payment Date": moment.utc(li.SettlementDate).format(dateFormate),
          "Crop Year": li.CropYear,
          Amount: li.Amount || "",
          "Units / Shares": li.UnitesORShares || "",
          Description: li.Description,
          // Assignee: li.Assignee,
        };
      }
    });
    return arrangedData;
  };

  const clearGridData = () => {
    setPaymentSearchResult([]);
    setPaymentSearchResultStatus(false);
    setHideExport(true);
    setNoResultsFound(false);
  };

  useEffect(() => {
    document
      .getElementById("datepickerFrom")
      ?.addEventListener("focus", function () {
        if (window.innerWidth < 772)
          document.getElementById("datepickerFrom").blur();
      });
    document
      .getElementById("datepickerTo")
      ?.addEventListener("focus", function () {
        if (window.innerWidth < 772)
          document.getElementById("datepickerTo").blur();
      });
  }, []);

  const noContractsFound = `${_.get(
    moreLangs,
    `${resolvedLanguage}.no_contracts_found`,
    "No contracts found"
  )}`;
  return (
    <>
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}

      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span
            className="float-end blue_color fw-bold"
            onClick={onClearSearch}
          >
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 border-1 white_bg filter_dropdown border-radius-6 p-1 ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock payment-page-mobile-filter ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="crop_year_label custom_dropdown">
                {reloadMultiSelect && (
                  <MultiDropDown
                    optionList={CropYears}
                    prompt="Select"
                    value={CY_MultiValue}
                    onChange={(val) => onCropYearChange(val)}
                    onDelete={(selectedList, removedItem) =>
                      onCropYearRemove(selectedList, removedItem)
                    }
                    label="cropyear"
                    usestate={setCY_MultiValue}
                    preSelectedOpt={preSelectedCropyear}
                    disabled=""
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="contract_number_Label custom_dropdown">
                {reloadMultiSelect && (
                  <MultiDropDown
                    optionList={ContractNumbers}
                    prompt="Select"
                    value={CN_MultiValue}
                    onChange={(val) => onContractNumberChange(val)}
                    onDelete={(selectedList, removedItem) =>
                      onContractNumberRemove(selectedList, removedItem)
                    }
                    label="contractid"
                    usestate={setCN_MultiValue}
                    disabled={""}
                    preSelectedOpt={preSelectedContract}
                    optionNotFound={{ contractid: noContractsFound }}
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div
                className={`form-floating date_picker_aln payment_date cursor_pointer date_from ${
                  disabled && "opacity_0_5"
                }`}
              >
                <DatePickerComp
                  id="datepickerFrom"
                  selected={startDate}
                  onChange={(date) => handlerDate(date, "start")}
                  placeholderText={dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  // maxDate={endDate ? endDate : new Date()}
                />
                <label htmlFor="datepickerFrom">
                  Payment Date From
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div
                className={`form-floating date_picker_aln payment_date  cursor_pointer date_to ${
                  disabled && "opacity_0_5"
                }`}
              >
                <DatePickerComp
                  id="datepickerTo"
                  selected={endDate}
                  onChange={(date) => handlerDate(date, "end")}
                  placeholderText={dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  minDate={startDate} //{new Date(),10}
                  // maxDate={new Date()}
                />
                <label htmlFor="datepickerTo">
                  Payment Date To{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
          </div>
          <div
            className={`bottom-0  filter_apply_button white_bg ${
              isMobFilter ? "show d-block " : " d-lg-none "
            }`}
          >
            {/* <button className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none" disabled="">Apply   </button> */}
            <button
              className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
              onClick={onAllSearch}
              disabled={disableSubmitCancel}
            >
              Apply{!loading ? "" : <WhiteCircleLoader />}{" "}
            </button>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-2">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={onAllSearch}
                disabled={disableSubmitCancel}
              >
                Search{!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={() => onClearSearch()}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
      </nav>
      {/*<!-- Filter Dropdown ends -->*/}

      {/* show grid with Export buttons*/}
      <div className="row mt-2 hidden_print">
        <div className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} `}>
          <div className="float-start">
            <h5 className="text-14 fw-bold mt-3 mt-lg-2 mb-3 mob-text-16">
              Total Result Count:{" "}
              {paymentSearchResult.length ? paymentSearchResult.length : 0}
            </h5>
          </div>
        </div>
        <div className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} `}>
          <div className="float-end mb-2">
            <span>
              <PdfFile
                pdfData={ArrangedData(paymentSearchResult, "pdf")}
                fileName="PaymentSearch"
              />
            </span>
            <span>
              <ExportFile
                downloadFormate="csv"
                exportData={ArrangedData(paymentSearchResult)}
                fileName="PaymentSearch"
              />
            </span>
            <span>
              <ExportFile
                downloadFormate="xlsx"
                exportData={ArrangedData(paymentSearchResult)}
                fileName="PaymentSearch"
              />
            </span>
          </div>
        </div>
      </div>
      {/*<!-- Desktop table starts -->*/}
      {paymentSearchResult.length > 0 &&
        paymentSearchResultStatus &&
        !displayAccordian && (
          <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
            <DataGridPagination
              uniqueIdentifier="PaymentId"
              gridData={paymentSearchResult}
              columns={columnsArray}
              isPagination={true}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6"
              autoRowHeight={true}
              // summaryRows = {summaryRows}
            />
          </div>
        )}
      {paymentSearchResult.length > 0 && paymentSearchResultStatus && (
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 ${
            !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
          }`}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion defaultActiveKey="0">
              {paymentSearchResult.length > 0 &&
                Object.values(paymentSearchResult).map((data, index) => {
                  return (
                    <Fragment key={data.id}>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          <div>
                            Contract #: {data.RelationshipNumber}, Payment Date:{" "}
                            {
                              <Moment utc format={dateFormate}>
                                {data.SettlementDate}
                              </Moment>
                            }
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tbody">
                            <div className="tr">
                              <div className="td"></div>
                              <div
                                className="td"
                                data-header="Contract Number:"
                              >
                                {data.RelationshipNumber}
                              </div>
                              <div className="td" data-header="Contract Name:">
                                <span>{data.Name}</span>
                              </div>
                              <div className="td" data-header="Identifier:">
                                {data.ContractIdentifier || "-"}
                              </div>
                              <div className="td" data-header="Payment Date:">
                                <Moment utc format={dateFormate}>
                                  {data.SettlementDate}
                                </Moment>
                              </div>
                              <div className="td" data-header="Crop Year:">
                                {data.CropYear}
                              </div>
                              <div
                                className="td flex-text-align-center"
                                data-header="Payment Amount:"
                              >
                                {toDecimalORCurrency(data.Amount, 2, "$")}
                              </div>
                              <div className="td" data-header="Units / Shares:">
                                {toDecimalORCurrency(data.UnitesORShares, 2) ||
                                  "-"}
                              </div>
                              <div className="td" data-header="Description:">
                                {data.Description || "-"}
                              </div>
                              {/* <div
                                className="td"
                                data-header="Assignee / Pledgee:"
                              >
                                {data.Assignee || "-"}
                              </div> */}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Fragment>
                  );
                })}
            </Accordion>
          </div>
        </div>
      )}
      {/* no record found section start */}
      {paymentSearchResult.length == 0 && noResultsFound && (
        <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
          <i className="fa fa-info-circle pe-2"></i>
          {notFoundMsg}
        </div>
      )}
      {/* no record found section End */}
    </>
  );
};
