/* eslint-disable */
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import allReducers from "./services/redux/reducers";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./configs/authConfig";
import { CookiesProvider } from "react-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.min.js';
import App from "./app";
import "./configs/i18n"; // TODO MIDDLEWARE BREAKING - DUE TO CORS ON CALLING NAV I BELIEVE
// import './js/bootstrap.min.js';
import "react-quill/dist/quill.snow.css";
import "./css/bootstrap-grid.css";
import "./css/bootstrap.css";
import "./css/bootstrap.min.css";
import "./css/login.css";
import "./css/navigation.css";
import "./css/style.css";
import "./css/font-awesome.min.css";
import "./css/common-styles.css";
import "./css/responsive.css";
import "./css/media_print.css";
import "./css/quil-custom.css";
import "./css/desktopAccordion.css";

const store = createStore(allReducers, applyMiddleware(thunk));
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <Suspense fallback={<div></div>}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </Suspense>
        </Provider>
      </MsalProvider>
    </Router>
  </React.StrictMode>
);

<script
  src="https://donorbox.org/widget.js"
  paypalexpress="false"
  type="text/javascript"
></script>;
