import React, { useState } from "react";
import CurrentLocation from "./currentLocation";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { IsStringChecker } from "../../utils/helper";

const Search = (props) => {
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const history = useHistory();
  const { search } = useLocation();
  const [searchText, setSearchText] = useState(
    search ? new URLSearchParams(search).get("q") : ""
  );

  const handleSearchSubmit = (event) => {
    if (searchText.length >= 3 && searchText !== "") {
      history.push(`/search-results?q=${searchText}`);
    }
    event.preventDefault();
  };

  function onClear(e) {
    e.stopPropagation();
    setSearchText("");
  }

  function onClick(e) {
    e.stopPropagation();
    handleSearchSubmit(e);
  }
  const access = IsStringChecker(searchText) && searchText.length >= 3;
  return (
    <div>
      <div
        className={
          props.isOpen
            ? "search_block dark_red_bg active"
            : "search_block dark_red_bg"
        }
      >
        <h3 className="white_color text-center">
          {moreLangs[i18n.language]?.home_page?.search_section_title}
        </h3>
        <form onSubmit={handleSearchSubmit}>
          <div className="input-group mb-3 width-80 ver-mspace-20">
            <input
              type="text"
              className="form-control border-0 space-15 fst-italic"
              placeholder={
                moreLangs[i18n.language]?.home_page?.search_section_placeholder
              }
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              name="q"
            />
            <span
              className="input-group-text border-0 white_bg"
              id="basic-addon1"
            >
              {IsStringChecker(searchText) && (
                <i
                  className="fa fa-close cursor-pointer search-clear-icon"
                  aria-hidden="true"
                  onClick={onClear}
                ></i>
              )}
              <i
                className={`fa fa-search search-icon-custom ${
                  access ? "cursor-pointer search-icon-custom-hover" : "cursor-not-allowed search-md-disabled"
                }`}
                aria-hidden="true"
                onClick={onClick}
              ></i>
              {/* <i
                className={`fa d-block d-lg-none fa-search search-icon-custom ${
                  access ? "cursor-pointer search-icon-custom-hover" : "cursor-not-allowed search-md-disabled"
                }`}
                aria-hidden="true"
                onClick={onClick}
              ></i>
              <i
                className={`fa d-none d-lg-block fa-search search-icon-custom ${
                  access ? "cursor-pointer search-icon-custom-hover" : "cursor-not-allowed"
                }`}
                aria-hidden="true"
                onClick={onClick}
              ></i> */}
            </span>
          </div>
        </form>
        <span>
          <img
            src={`${window.location.origin}/assets/images/icons-close/ico-close@2x.png`}
            alt="searchClose"
            className="svg-image searchClose"
            onClick={props.onclose}
          />
        </span>
      </div>
    </div>
  );
};

export default Search;
