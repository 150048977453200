import React from "react";
import PageLayout from "../pageLayout";
import Body from "./body";
import PreHarvestDetails from "./preHarvestDetails";

const PreHarvest = (props) => {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <PageLayout {...props} body={<Body isLeftMenu ="false"  quickLinksSlug="pre-harvest" {...props} contentbody={<PreHarvestDetails {...props} />} />} />
  );
};

export default PreHarvest;
