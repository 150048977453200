import React from "react";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import { cmsDataCommonMethod } from "../../services/gbsData";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import _ from "lodash";
import moment from "moment";
import CurrentLocation from "../global/currentLocation";
import {
  IsTokenChecker,
  HandleErrorTrackAndToast,
} from "../../utils/helper";

const Events = (props) => {
  const [siteNotification, setSiteNotification] = useState(null);
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const idToken = useSelector((state) => state.reducerToken);
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "dismissed_site_notifications",
  ]);
  const siteNot = async () => {
    await cmsDataCommonMethod(
      idToken,
      accounts,
      `site_notifications?select=id,content_en,content_es,content_fr,publish_date_from,publish_date_to,page_listings!inner(slug)&page_listings.slug=eq.${props.type}&order=created_at.desc`
    )
      .then((resp) => {
        if (cookies.dismissed_site_notifications !== undefined) {
          let noti = _.filter(
            resp.data.data,
            (notification) =>
              !cookies.dismissed_site_notifications.includes(notification.id)
          );
          noti == [] ? setSiteNotification(null) : setSiteNotification(noti);
        } else {
          setSiteNotification(resp.data.data);
        }
      })
      .catch((error) => {
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };
  useEffect(() => {
    IsTokenChecker(idToken) && siteNot();
  }, [cookies, idToken]);
  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);
  const handleDismiss = (id) => {
    if (cookies.dismissed_site_notifications !== undefined) {
      let ids = cookies.dismissed_site_notifications;      
      if (!ids.includes(id)) {
        ids = [...ids, id];
        removeCookie("dismissed_site_notifications");
        setCookie("dismissed_site_notifications", JSON.stringify(ids), { path: '/', expires: moment().add(3, 'months').toDate() });
        let noti = _.filter(
          siteNotification,
          (notification) => !ids.includes(notification.id)
        );
        if (noti === []) {
          noti = null;
        }
        setSiteNotification(noti);
      }
    } else {
      let ids = [];
      ids.push(id);
      setCookie("dismissed_site_notifications", JSON.stringify(ids), { path: '/', expires: moment().add(3, 'months').toDate() });
      let noti = _.filter(
        siteNotification,
        (notification) => !ids.includes(notification.id)
      );
      if (noti === []) {
        noti = null;
      }
      setSiteNotification(noti);
    }
  };
  return i18n.resolvedLanguage === undefined ? null : (
    <>
      {siteNotification != null
        ? _.filter(
          siteNotification,
          (noti) =>
            !moment(moment().unix()).isSameOrAfter(
              moment(noti.publish_date_to).unix()
            )
        )
          .slice(0, 5)
          .map((notification, index) => (
            <div
              className="card mb-3"
              style={
                props.type == "/" ? { marginTop: 30 } : { marginBottom: 0 }
              }
              key={index}
            >
              <div className="card-body ver-space-10 align-flex">
                <span className="d-contents ver-space-10 width-left-mob">
                  <i className="fa fa-info-circle margin-right-5 d-none d-lg-inline-block"></i>
                  <i className="fa fa-info margin-right-5 d-lg-inline-block d-lg-none info_icon_mobile"></i>
                  <span className="mob_blue_text notification_text width-left-mob">
                    {parse(notification["content_" + i18n.resolvedLanguage])}
                  </span>
                </span>
                <button
                  type="button"
                  className="btn btn-primary min-width-115 float-end h-40"
                  onClick={() => handleDismiss(notification.id)}
                >
                  {moreLangs[i18n.language]?.home_page?.dismiss}
                </button>
              </div>
            </div>
          ))
        : null}
    </>
  );
};

export default Events;
