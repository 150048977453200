import React from 'react'
import PageLayout from "../../pageLayout";
import Body from "./body";
import { useParams } from "react-router-dom";
import { getObjectLength } from "../../../utils/helper";
import DetailPageCtaContentBlock from '../../global/detailPageCtaContentBlock';

const GrowerBenefits = (props) => {
  const { ID, componentName } = useParams();
  return (
    <>
      {(getObjectLength(useParams()) == 0) ? 
        <PageLayout {...props} body={<Body {...props} isListing = {true} isLeftMenu="false"/>} /> : 
        <PageLayout {...props} body={<Body {...props} isListing = {false} isLeftMenu="false" contentbody={<DetailPageCtaContentBlock {...props} slugId={ID} pagetitleDisplay={true} breadcrumbtitle={componentName} />} />} />}
    </>
  );
}

export default GrowerBenefits