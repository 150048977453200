import { useLocation } from "react-router-dom";
import _ from "lodash";
import { HandleErrorTrackAndToast } from "../../utils/helper";

// First Argument is for TO pathname to logout and it defaults to /account/logout
// Second Argument is for FROM pathname for Search Query and it defaults to CURRENT LOCATION PATH or Home (/)
const useLogoutPathBuilder = (toPathName = "/account/logout", fromPathName) => {
  const location = useLocation();

  try {
    const path = fromPathName ? fromPathName : _.get(location, "pathname", "/");
    // console.log("Pathname", path);

    const search = new URLSearchParams({ from: path });

    const url = `${toPathName}?${search}`;
    // console.log("URL =>", url);

    return url;
  } catch (e) {
    HandleErrorTrackAndToast(e, true);
    const search = new URLSearchParams({ from: "/" });
    return `/account/logout?${search}`;
  }
};

export default useLogoutPathBuilder;
