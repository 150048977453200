/**
 * @component_Name : Profile
 * @description : Profile
 * @company : Photon
 * @author : Ashish Ranjan/27-09-2022
 **/
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { gbsDataCommonMethod } from "../../services/gbsData";
import "../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
  setOptions,
  checkValidEmailRegex,
  getObjectLength,
  IsTokenChecker,
  HandleErrorTrackAndToast,
  IsStringChecker,
} from "../../utils/helper";
import MultiDropDown from "../selectBox/multiDropDown";
import Loading from "../../components/global/loading";
import WhiteCircleLoader from "../whiteCircleLoader";
import { Alert } from "react-bootstrap";
import CurrentLocation from "../global/currentLocation";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";
import {
  ImpersonationAccountsSwap,
  DetermineImpersonation,
} from "../../permissionWrapper";
function isNumber(str) {
  if (str.trim() === "") {
    return false;
  }
  return !isNaN(str);
}

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
  try {
    if (typeof phone === "undefined") return true;
    else return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
const Profile = ({ setPrifleData }) => {
  const impersonated = DetermineImpersonation();
  const { accounts: msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const tokenId = useSelector((state) => state.reducerToken);
  const personId = accounts[0].idTokenClaims.gbsPersonId;
  const { i18n } = useTranslation();
  const [, moreLangs] = CurrentLocation();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const [firstNameTitle, setFirstNameTitle] = useState("");
  const [statesOptions, setStatesOptions] = useState([]);
  const [multiStates, setMultiStates] = useState([]);
  const [preSelectedArray, setPreSelectedArray] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [multiCountries, setMultiCountries] = useState([]);
  const [preSelectedCountry, setPreSelectedCountry] = useState([]);
  const [disableSubmitCancel, setDisableSubmitCancel] = useState(true);

  const {
    personal_text,
    information_text,
    address_text,
    number_text,
    email_text,
    home_text,
    phone_text,
    profile_description,
    phone_error = "Please provide at least one phone number below",
  } = { ...moreLangs[i18n.resolvedLanguage].profile_page };
  const [mobileError, setMobileError] = useState(phone_error);

  const initialValue = {
    first_name: "",
    last_name: "",
    middle_name: "",
    nickname: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    states: "",
    country: "",
    zip: "",
    phone_number: "",
    HomePhone: "",
    BusinessPhone: "",
    InternationalPhone: "",
  };

  const dispatch = useDispatch();
  const validErrorMsg = {
    first_name: "First Name is required!",
    last_name: "Last Name is required!",
    address1: "Home Address is required!",
    city: "City is required!",
    states: "State/Province is required!",
    country: "Country is required!",
    zip: "ZIP/Postal Code is required!",
  };
  const [forminputs, setFormInputs] = useState(initialValue);
  const [masterData, setMasterData] = useState(initialValue);
  const [email, setEmail] = useState({ email: "" });
  const [clearstatus, setClearStatus] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertOpen, setAlertOpen] = useState({ isActive: false, message: "" });
  const InternationalPhoneValid = isPhoneValid(forminputs.InternationalPhone);
  const internationPhone = useRef(null);
  // console.log("isValid", InternationalPhoneValid);

  useEffect(() => {
    if (InternationalPhoneValid) {
      setMobileError("");
      setDisableSubmitCancel(false);
      if (JSON.stringify(masterData) === JSON.stringify(forminputs)) {
        setDisableSubmitCancel(true);
      }
    } else if (
      forminputs.phone_number === "" &&
      forminputs.HomePhone === "" &&
      forminputs.BusinessPhone === ""
    ) {
      setMobileError(phone_error);
    }
    // else if(!InternationalPhoneValid && forminputs.InternationalPhone !== ""){
    //   setDisableSubmitCancel(false);
    //   if (JSON.stringify(masterData) === JSON.stringify(forminputs)) {
    //     setDisableSubmitCancel(true);
    //   }
    // }
  }, [InternationalPhoneValid, forminputs.InternationalPhone]);

  useEffect(() => {
    setLoader(true);
    if (IsTokenChecker(tokenId)) {
      getProfile();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [tokenId]);

  useEffect(() => {
    if (
      JSON.stringify(errors) !== "{}" ||
      (!InternationalPhoneValid && forminputs.InternationalPhone !== "")
    ) {
      setDisableSubmitCancel(true);
      // if (JSON.stringify(masterData) !== JSON.stringify(forminputs)) {
      //   setDisableSubmitCancel(false);
      // }
    }
  }, [errors, forminputs]);

  const getstates = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `states`)
      .then((resp) => {
        const statesList = [...resp.data];
        setStatesOptions(setOptions(statesList, "Value", "Description"));
      })
      .catch((error) => {
        /* setLoading(false); */
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const getCountries = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `countries`)
      .then((resp) => {
        const countriesList = [...resp.data];
        setCountriesOptions(setOptions(countriesList, "Value", "Description"));
      })
      .catch((error) => {
        /* setLoading(false); */
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const MobileNumberFormatRemover = (number) => {
    try {
      if (IsStringChecker(number)) {
        const num = number
          .trim()
          .replace(/[^0-9]/g, "")
          .replace(/-/g, "");
        return num;
      }
      return "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const MobileNumberFormatter = (number = "") => {
    try {
      if (IsStringChecker(number)) {
        const num = number.trim().replace(/[^0-9]/g, "");
        if (num.length <= 3) {
          return num;
        } else if (num.length > 3 && num.length <= 6) {
          return num.replace(/(\d{3})(\d{1})/, "$1-$2");
        } else {
          return num.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
        }
      }
      return "";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const getProfile = async () => {
    await gbsDataCommonMethod(tokenId, accounts, `persons/${personId}`)
      .then((resp) => {
        const userDetails = { ...resp.data[0] };
        setFirstNameTitle(userDetails.FirstName);
        setEmail({
          email: userDetails.ExtranetUsername,
        });
        let international =
          userDetails.InternationalPhone !== "" &&
          userDetails.InternationalPhone !== null
            ? "+" + userDetails.InternationalPhone
            : "";
        const MasterObj = {
          first_name: userDetails.FirstName,
          last_name: userDetails.LastName,
          middle_name: userDetails.MiddleName,
          nickname: userDetails.Nickname,
          address1: userDetails.MailingAddress_Street1,
          address2: userDetails.MailingAddress_Street2,
          address3: userDetails.MailingAddress_Street3,
          city: userDetails.MailingAddress_City,
          states: userDetails.MailingAddress_State,
          country: userDetails.MailingAddress_Country,
          zip: userDetails.MailingAddress_PostalCode,
          phone_number: MobileNumberFormatter(userDetails.MobilePhone),
          HomePhone: MobileNumberFormatter(userDetails.HomePhone),
          BusinessPhone: MobileNumberFormatter(userDetails.BusinessPhone),
          InternationalPhone: international,
        };
        setPrifleData(MasterObj);
        setFormInputs(MasterObj);
        setMasterData(MasterObj);
        getstates();
        getCountries();
        userDetails?.MailingAddress_State &&
          setPreSelectedArray([userDetails.MailingAddress_State]);
        userDetails?.MailingAddress_Country &&
          setPreSelectedCountry([userDetails.MailingAddress_Country]);
        if (
          userDetails.MobilePhone ||
          userDetails.HomePhone ||
          userDetails.BusinessPhone ||
          userDetails.InternationalPhone
        ) {
          setMobileError("");
        } else {
          setMobileError(phone_error);
        }
      })
      .catch((error) => {
        setLoading(false);
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    let feildErrors = { ...errors };
    let fieldInputs = { ...forminputs };
    switch (name) {
      case "first_name":
        feildErrors.first_name = value == "" ? validErrorMsg.first_name : "";
        fieldInputs.first_name = value;
        break;
      case "last_name":
        feildErrors.last_name = value == "" ? validErrorMsg.last_name : "";
        fieldInputs.last_name = value;
        break;
      case "address1":
        feildErrors.address1 = value == "" ? validErrorMsg.address1 : "";
        fieldInputs.address1 = value;
        break;
      case "city":
        feildErrors.city = value == "" ? validErrorMsg.city : "";
        fieldInputs.city = value;
        break;
      case "states":
        feildErrors.states = value == "" ? validErrorMsg.states : "";
        fieldInputs.states = value;
        break;
      case "country":
        feildErrors.country = value == "" ? validErrorMsg.country : "";
        fieldInputs.country = value;
        break;
      case "zip":
        feildErrors.zip =
          value == ""
            ? validErrorMsg.zip
            : value.length > 12
            ? "ZIP/Postal Code should not be more than 12 "
            : "";
        fieldInputs.zip = value;
        break;
      case "email":
        feildErrors.email =
          value != ""
            ? checkValidEmailRegex.test(value)
              ? ""
              : "Please enter a valid Email"
            : validErrorMsg.email;
        fieldInputs.email = value;
        break;
      case "phone_number":
        const testNum = MobileNumberFormatRemover(value);
        if (testNum) {
          feildErrors.phone_number = !isNumber(testNum)
            ? "Please enter a valid Mobile Phone Number"
            : testNum.length === 10
            ? ""
            : "Mobile Phone number should be 10 digits!";
          setMobileError("");
        } else {
          feildErrors.phone_number = "";
          if (
            fieldInputs.BusinessPhone ||
            fieldInputs.HomePhone ||
            InternationalPhoneValid
          ) {
            setMobileError("");
          } else {
            setMobileError(phone_error);
          }
        }
        fieldInputs.phone_number = MobileNumberFormatter(value);
        break;
      case "HomePhone":
        const homeNum = MobileNumberFormatRemover(value);
        if (homeNum) {
          feildErrors.HomePhone = !isNumber(homeNum)
            ? "Please enter a valid Home Phone Number"
            : homeNum.length === 10
            ? ""
            : "Home Phone number should be 10 digits!";
          setMobileError("");
        } else {
          feildErrors.HomePhone = "";
          if (
            fieldInputs.BusinessPhone ||
            fieldInputs.phone_number ||
            InternationalPhoneValid
          ) {
            setMobileError("");
          } else {
            setMobileError(phone_error);
          }
        }
        fieldInputs.HomePhone = MobileNumberFormatter(value);
        break;
      case "BusinessPhone":
        const BNum = MobileNumberFormatRemover(value);
        if (BNum) {
          feildErrors.BusinessPhone = !isNumber(BNum)
            ? "Please enter a valid Business Phone Number"
            : BNum.length === 10
            ? ""
            : "Business Phone number should be 10 digits!";
          setMobileError("");
        } else {
          feildErrors.BusinessPhone = "";
          if (
            fieldInputs.HomePhone ||
            fieldInputs.phone_number ||
            InternationalPhoneValid
          ) {
            setMobileError("");
          } else {
            setMobileError(phone_error);
          }
        }
        fieldInputs.BusinessPhone = MobileNumberFormatter(value);
        break;
      case "InternationalPhone":
        const IntNum = MobileNumberFormatRemover(value);
        if (IntNum) {
          feildErrors.InternationalPhone = !isNumber(IntNum)
            ? "Please enter a valid International Number"
            : IntNum.length === 15
            ? ""
            : "International number should be 10 digits!";
          setMobileError("");
        } else {
          feildErrors.InternationalPhone = "";
          if (fieldInputs.HomePhone || fieldInputs.phone_number) {
            setMobileError("");
          } else {
            setMobileError(phone_error);
          }
        }
        fieldInputs.InternationalPhone = MobileNumberFormatter(value);
        break;
      default:
        fieldInputs[name] = value;
        break;
    }

    if (feildErrors[name] == "") {
      delete feildErrors[name];
    }
    setErrors({ ...feildErrors });
    setFormInputs({ ...fieldInputs });
    setDisableSubmitCancel(false);
    if (JSON.stringify(masterData) === JSON.stringify(fieldInputs)) {
      setDisableSubmitCancel(true);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    setSaveBtnDisabled(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false || getObjectLength(errors) > 0) {
      let ermsg = { ...errors };
      let feild_validation = { ...forminputs };
      delete feild_validation.nickname; /*Not required feild*/
      delete feild_validation.address2; /*Not required feild*/
      delete feild_validation.address3; /*Not required feild*/
      Object.keys(feild_validation).filter((x) => {
        if (x === "phone_number") {
          const value = feild_validation[x];
          const testNum = MobileNumberFormatRemover(value);
          if (testNum) {
            const returnVal = !isNumber(testNum)
              ? "Please enter a valid Mobile Phone Number"
              : testNum.length === 10
              ? ""
              : "Mobile Phone number should be 10 digits!";
            ermsg[x] = returnVal;
          } else {
            ermsg[x] = "";
          }
        } else if (x === "HomePhone") {
          const value = feild_validation[x];
          const testNum = MobileNumberFormatRemover(value);
          if (testNum) {
            const returnVal = !isNumber(testNum)
              ? "Please enter a valid Home Phone Number"
              : testNum.length === 10
              ? ""
              : "Home Phone number should be 10 digits!";
            ermsg[x] = returnVal;
          } else {
            ermsg[x] = "";
          }
        } else if (x === "BusinessPhone") {
          const value = feild_validation[x];
          const testNum = MobileNumberFormatRemover(value);
          if (testNum) {
            const returnVal = !isNumber(testNum)
              ? "Please enter a valid Business Phone Number"
              : testNum.length === 10
              ? ""
              : "Business Phone number should be 10 digits!";
            ermsg[x] = returnVal;
          } else {
            ermsg[x] = "";
          }
        } else if (x === "InternationalPhone") {
          const value = feild_validation[x];
          const IntNum = MobileNumberFormatRemover(value);
          if (IntNum) {
            const returnVal = !isNumber(IntNum)
              ? "Please enter a valid International Number"
              : IntNum.length === 15
              ? ""
              : "International number should be 10 digits!";
            ermsg[x] = returnVal;
          } else {
            ermsg[x] = "";
          }
        } else {
          feild_validation[x] == "" ? (ermsg[x] = validErrorMsg[x]) : "";
        }
      });
      setErrors({ ...ermsg });
      setLoading(false);
      setSaveBtnDisabled(false);
      if (
        forminputs.phone_number ||
        forminputs.HomePhone ||
        forminputs.BusinessPhone
      ) {
        setMobileError("");
      } else {
        setMobileError(phone_error);
      }
    } else {
      if (
        forminputs.phone_number ||
        forminputs.HomePhone ||
        forminputs.BusinessPhone ||
        InternationalPhoneValid
      ) {
        setMobileError("");
        let updateData = {
          ["first_name"]: forminputs.first_name,
          ["last_name"]: forminputs.last_name,
          ["middle_name"]: forminputs.middle_name,
          ["nickname"]: forminputs.nickname,
          ["address1"]: forminputs.address1,
          ["address2"]: forminputs.address2,
          ["address3"]: forminputs.address3,
          ["city"]: forminputs.city,
          ["state"]: forminputs.states,
          ["country"]: forminputs.country,
          ["postal"]: forminputs.zip,
          ["mobile_phone"]: MobileNumberFormatRemover(forminputs.phone_number),
          ["home_phone"]: MobileNumberFormatRemover(forminputs.HomePhone),
          ["business_phone"]: MobileNumberFormatRemover(
            forminputs.BusinessPhone
          ),
          ["international_phone"]: forminputs.InternationalPhone?.replace(
            "+",
            ""
          ),
        };
        updateData = JSON.stringify(updateData);
        let objGeoJson = JSON.parse(updateData);
        const url = `persons/${personId}`;
        let mstData = {
          ["first_name"]: forminputs.first_name,
          ["last_name"]: forminputs.last_name,
          ["middle_name"]: forminputs.middle_name,
          ["nickname"]: forminputs.nickname,
          ["address1"]: forminputs.address1,
          ["address2"]: forminputs.address2,
          ["address3"]: forminputs.address3,
          ["city"]: forminputs.city,
          ["states"]: forminputs.states,
          ["country"]: forminputs.country,
          ["zip"]: forminputs.zip,
          ["phone_number"]: MobileNumberFormatter(forminputs.phone_number),
          ["HomePhone"]: MobileNumberFormatter(forminputs.HomePhone),
          ["BusinessPhone"]: MobileNumberFormatter(forminputs.BusinessPhone),
          ["InternationalPhone"]: forminputs.InternationalPhone,
        };

        await gbsDataCommonMethod(tokenId, accounts, url, "PUT", objGeoJson)
          // eslint-disable-next-line
          .then((resp) => {
            setLoading(false);
            setSaveBtnDisabled(false);
            setFirstNameTitle(forminputs.first_name);
            setAlertOpen({
              isActive: true,
              message: `${
                moreLangs[i18n.resolvedLanguage].profile_page.update_success_msg
              }`,
            });
            setTimeout(() => {
              setAlertOpen({ isActive: false, message: "" });
            }, 4000);
            setMasterData({ ...mstData });
            setDisableSubmitCancel(true);
          }) // eslint-disable-next-line no-console
          .catch((error) => {
            setLoader(false);
            HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
            setDisableSubmitCancel(false);
          });
        setLoading(false);
        setSaveBtnDisabled(false);
      } else {
        setMobileError(phone_error);
        setLoading(false);
        setSaveBtnDisabled(false);
      }
    }
  };

  const handlerSelectOptions = (field = "", val, type, removedItem = "") => {
    if (field) {
      type;
      removedItem;
      let feildErrors = { ...errors };
      let fieldInputs = { ...forminputs };
      if (val.length > 0) {
        fieldInputs[field] = val[0].id;
        setFormInputs({ ...forminputs, [field]: val[0].id });
        delete feildErrors[field];
      } else {
        feildErrors[field] = val.length == 0 ? validErrorMsg[field] : "";
        fieldInputs[field] = "";
        setFormInputs({ ...forminputs, [field]: "" });
      }
      setErrors({ ...feildErrors });
      setDisableSubmitCancel(false);
      if (JSON.stringify(masterData) === JSON.stringify(fieldInputs)) {
        setDisableSubmitCancel(true);
      }
    }
  };

  const resetChanges = () => {
    setFormInputs(masterData);
    setErrors({});

    masterData?.states && setPreSelectedArray([masterData.states]);
    masterData?.country && setPreSelectedCountry([masterData.country]);
    MultiDropDownFix();
    if (
      masterData.phone_number ||
      masterData.HomePhone ||
      masterData.BusinessPhone ||
      masterData.InternationalPhone
    ) {
      setMobileError("");
    } else {
      setMobileError(phone_error);
    }
    setDisableSubmitCancel(true);
  };

  const MultiDropDownFix = () => {
    setClearStatus(true);
    setTimeout(() => {
      setClearStatus(false);
    }, 0);
  };

  function SubmitBtnDisabled() {
    if (impersonated) {
      return true;
    } else {
      if (saveBtnDisabled || disableSubmitCancel) {
        return true;
      }
      return false;
    }
  }

  const submit_disabled = SubmitBtnDisabled();
  return (
    <>
      {alertOpen.isActive && (
        <Alert
          variant="success"
          onClose={() => setAlertOpen({ isActive: false, message: "" })}
          dismissible
        >
          <b>{alertOpen.message}</b>
        </Alert>
      )}
      {/*<!-- user profile block starts -->*/}
      {loader && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Profile tab starts -->*/}
      <div className="white_bg border-radius-6 p-0 p-lg-4 mb-4">
        <h3>
          {" "}
          {personal_text} {information_text}
        </h3>
        <p>
          Hi {firstNameTitle}, {profile_description}.
        </p>

        <form className="profile-page" onSubmit={submitHandler} noValidate>
          <div className="personal_info">
            {/*<!-- Personal info starts -->*/}
            <h4 className="mb-3 text-uppercase">{personal_text}</h4>
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14 mandatoryField ps-3">
                    First Name
                  </span>
                  <input
                    type="text"
                    name="first_name"
                    aria-label="First name"
                    className="form-control"
                    value={forminputs.first_name || ""}
                    onChange={formDataHandler}
                    required
                  />
                </div>
                {errors.first_name && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.first_name}
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">Middle Initial</span>
                  <input
                    type="text"
                    name="middle_name"
                    aria-label="Middle Initial"
                    className="form-control"
                    value={forminputs.middle_name || ""}
                    onChange={formDataHandler}
                  />
                </div>
                {errors.middle_name && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.middle_name}
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14 mandatoryField ps-3">Last Name</span>
                  <input
                    type="text"
                    name="last_name"
                    aria-label="Last name"
                    className="form-control"
                    value={forminputs.last_name || ""}
                    onChange={formDataHandler}
                    required
                  />
                </div>
                {errors.last_name && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.last_name}
                  </span>
                )}
              </div>
            </div>
            <div className="row mt-0 mt-lg-2">
              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">Nickname</span>
                  <input
                    type="text"
                    name="nickname"
                    aria-label="Nickname"
                    className="form-control"
                    value={forminputs.nickname || ""}
                    onChange={formDataHandler}
                  />
                </div>
                {errors.nickname && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.nickname}
                  </span>
                )}
              </div>
            </div>
            <div className="horizontal-line my-4"></div>
            {/*<!-- Personal info ends -->*/}

            {/*<!-- Address starts -->*/}
            <h4 className="mb-3 text-uppercase">
              {home_text} {address_text}
            </h4>
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14 mandatoryField ps-3">
                    MAILING ADDRESS 1
                  </span>
                  <input
                    type="text"
                    name="address1"
                    aria-label="MAILING ADDRESS1"
                    className="form-control mb-2"
                    value={forminputs.address1 || ""}
                    onChange={formDataHandler}
                    required
                  />
                  {errors.address1 && (
                    <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                      {errors.address1}
                    </span>
                  )}
                </div>
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">MAILING ADDRESS 2</span>
                  <input
                    type="text"
                    name="address2"
                    aria-label="MAILING ADDRESS2"
                    className="form-control mb-2"
                    value={forminputs.address2 || ""}
                    onChange={formDataHandler}
                  />
                </div>
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">MAILING ADDRESS 3</span>
                  <input
                    type="text"
                    name="address3"
                    aria-label="MAILING ADDRESS3"
                    className="form-control mb-2"
                    value={forminputs.address3 || ""}
                    onChange={formDataHandler}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14 mandatoryField ps-3">CITY</span>
                  <input
                    type="text"
                    name="city"
                    aria-label="CITY"
                    className="form-control"
                    value={forminputs.city || ""}
                    onChange={formDataHandler}
                    required
                  />
                </div>
                {errors.city && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.city}
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-2">
                <div className="states_label custom_dropdown mb-2">
                  {/* <span className="text-14">STATE</span> */}
                  {!clearstatus && (
                    <MultiDropDown
                      optionList={statesOptions}
                      prompt="Select"
                      value={multiStates}
                      onChange={(val) =>
                        handlerSelectOptions("states", val, "selected")
                      }
                      label="states"
                      usestate={setMultiStates}
                      disabled=""
                      onDelete={(val, removedItem) =>
                        handlerSelectOptions(
                          "states",
                          val,
                          "deSelected",
                          removedItem
                        )
                      }
                      preSelectedOpt={preSelectedArray}
                      singleSelect={true}
                    />
                  )}
                  {errors.states && (
                    <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                      {errors.states}
                    </span>
                  )}
                  {/* <input type="text" name="state" aria-label="STATE" className="form-control" required value={forminputs.state || ""} onChange={formDataHandler} /> */}
                </div>
                {/* <p className='red_dark_color'>{formErrors.state}</p> */}
              </div>
              <div className="col-12 col-lg-2">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14 mandatoryField ps-3">
                    ZIP/Postal Code
                  </span>
                  <input
                    type="text"
                    name="zip"
                    aria-label="ZIP"
                    className="form-control"
                    value={forminputs.zip || ""}
                    onChange={formDataHandler}
                    required
                  />
                </div>
                {errors.zip && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.zip}
                  </span>
                )}
                <div className="country_label custom_dropdown mb-2 mt-2">
                  {!clearstatus && (
                    <MultiDropDown
                      optionList={countriesOptions}
                      prompt="Select"
                      value={multiCountries}
                      onChange={(val) =>
                        handlerSelectOptions("country", val, "selected")
                      }
                      label="country"
                      usestate={setMultiCountries}
                      disabled=""
                      onDelete={(val, removedItem) =>
                        handlerSelectOptions(
                          "country",
                          val,
                          "deSelected",
                          removedItem
                        )
                      }
                      preSelectedOpt={preSelectedCountry}
                      singleSelect={true}
                    />
                  )}
                  {errors.country && (
                    <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                      {errors.country}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="horizontal-line my-4"></div>
            {/*<!-- Address ends -->*/}

            {/*<!-- E-MAIL Address starts -->*/}

            <h4 className="mb-3 text-uppercase">
              {email_text} {address_text}
            </h4>
            <div className="row">
              <div className="col-12 col-lg-4 addEmail remove_className mb-3">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">E-MAIL ADDRESS</span>
                  <input
                    type="text"
                    aria-label="E-MAIL ADDRESS"
                    className="form-control"
                    value={email.email || ""}
                    onChange={formDataHandler}
                    disabled
                  />
                </div>
                {errors.email && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.email}
                  </span>
                )}
              </div>
            </div>
            <div className="horizontal-line my-4"></div>
            {/*<!-- E-MAIL Address ends -->*/}
            <h4 className="mb-3 text-uppercase position-relative">
              {phone_text} {number_text}
              <span className="text-14 mandatoryField ps-3 text-capitalize red_dark_color">
                {`${IsStringChecker(mobileError) ? mobileError : ""}`}
              </span>
            </h4>
            <div className="row">
              <div className="col-12 col-lg-4 phone-err-fix">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">MOBILE PHONE NUMBER</span>
                  <input
                    type="tel"
                    name="phone_number"
                    aria-label="PHONE NUMBER"
                    className="form-control"
                    value={forminputs.phone_number || ""}
                    onChange={formDataHandler}
                    max="10"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  />
                </div>
                {errors.phone_number && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.phone_number}
                  </span>
                )}
              </div>

              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">HOME PHONE NUMBER</span>
                  <input
                    type="tel"
                    name="HomePhone"
                    aria-label="HOME PHONE NUMBER"
                    className="form-control"
                    value={forminputs.HomePhone || ""}
                    onChange={formDataHandler}
                    max="10"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  />
                </div>
                {errors.HomePhone && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.HomePhone}
                  </span>
                )}
              </div>

              <div className="col-12 col-lg-4">
                <div className="position-relative mb-2 mb-lg-0">
                  <span className="text-14">BUSINESS PHONE NUMBER</span>
                  <input
                    type="tel"
                    name="BusinessPhone"
                    aria-label="BUSINESS PHONE NUMBER"
                    className="form-control"
                    value={forminputs.BusinessPhone || ""}
                    onChange={formDataHandler}
                    max="10"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  />
                </div>
                {errors.BusinessPhone && (
                  <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                    {errors.BusinessPhone}
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-4 mt-2">
                <div className="position-relative mb-2 mb-lg-0 international-number">
                  <span className="text-14">INTERNATIONAL NUMBER</span>
                  {/* <input
                    type="tel"
                    name="InternationalPhone"
                    aria-label="INTERNATIONAL NUMBER"
                    className="form-control"
                    value={forminputs.InternationalPhone || ""}
                    onChange={formDataHandler}
                    max="10"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  /> */}
                  <PhoneInput
                    ref={internationPhone}
                    defaultCountry="us"
                    disableDialCodePrefill={true}
                    value={forminputs.InternationalPhone}
                    name="InternationalPhone"
                    onChange={(e) => {
                      let formInputs = { ...forminputs };
                      formInputs.InternationalPhone = e;
                      setFormInputs({ ...formInputs });
                      if (masterData.InternationalPhone !== e) {
                        if (e === "") {
                          setDisableSubmitCancel(false);
                          if (
                            JSON.stringify(masterData) ===
                              JSON.stringify(formInputs) ||
                            !InternationalPhoneValid
                          ) {
                            setDisableSubmitCancel(true);
                          }
                        } else {
                          setDisableSubmitCancel(false);
                          if (
                            JSON.stringify(masterData) ===
                              JSON.stringify(formInputs) ||
                            !InternationalPhoneValid
                          ) {
                            setDisableSubmitCancel(true);
                          }
                        }
                      } else {
                        setDisableSubmitCancel(false);
                        if (
                          JSON.stringify(masterData) ===
                          JSON.stringify(formInputs)
                        ) {
                          setDisableSubmitCancel(true);
                        }
                      }
                    }}
                  />
                </div>
                {!InternationalPhoneValid &&
                  forminputs.InternationalPhone !== "" && (
                    <span className="red_dark_color position-relative d-inline-block pt-0 t_5 text-capitalize">
                      International phone number is not valid
                    </span>
                  )}
              </div>
            </div>
            <div className="row my-4">
              <div className="col">
                <div className="float-end position-relative">
                  <span className="profile-redstar-end"></span>
                  {`fields are required.`}
                </div>
                <div className={`horizontal-line pt-4`}></div>
              </div>
            </div>
            {/*<!-- E-MAIL Address ends -->*/}
            <div className="overflow-hidden">
              {/* <input type="submit" value="Save"/> */}
              <button
                type="button"
                className={`btn btn-primary min-width-95 float-end mob-t-2 profile-cancel-btn cursor-for-btn`}
                onClick={resetChanges}
                disabled={impersonated ? impersonated : disableSubmitCancel}
              >
                Cancel
              </button>
              <button
                disabled={submit_disabled}
                type="submit"
                className={`btn btn-primary min-width-95 float-end mob-t-2 cursor-for-btn`}
              >
                Save {!loading ? "" : <WhiteCircleLoader />}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/*<!-- Profile tab ends -->*/}
    </>
  );
};
export default Profile;
