import React,{useState} from 'react';
import { Link } from "react-router-dom";

const SubSubMenu = ({ data, index,i18n }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <div>
      <i
        className={
          showSubmenu
            ? "fa fa-chevron-down dropdown-toggle nav-icon-dm nav-icon-sub-dm-2 show"
            : "fa fa-chevron-right dropdown-toggle nav-icon-dm nav-icon-sub-dm-2"
        }
        data-bs-toggle="dropdown"
        aria-expanded={showSubmenu ? "true" : "false"}
        onClick={() => setShowSubmenu(!showSubmenu)}
      ></i>
	  <ul
        className={
          showSubmenu
            ? "dropdown-menu oceanspray_submenu show"
            : "dropdown-menu oceanspray_submenu"
        }
        aria-labelledby={`navbarDropdown-${index}`}
      >
        {
          data.map((link,ind)=>{
            return  <li key={ind} className={`d-block d-lg-none ${link.new_content && 'new-content-flag'}`}>
              <Link to={link.url} className="dropdown-item">
			  {link["title_" + i18n.resolvedLanguage]}
        {link.new_content && <img className="new-content-flag-img" src={`${window.location.origin}/assets/images/new_burst_gradient.png`}/>}
              </Link>
		  </li>
          })
	 }

	  </ul>
    </div>
  );
};

export default SubSubMenu;