import React, { useState } from "react";
import { BreadcrumbWithParams } from "../../../global/breadCrumb";
import _ from "lodash";
import { useParams } from "react-router-dom";
import Loading from "../../../global/loading";

const OnlineFormDetail = (props) => {
  const params = useParams();
  const formDetail = _.get(params, "formDetail", "");
  const [loading] = useState(false);
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadcrumbWithParams
              params={formDetail ? formDetail.replace(/-/g, " ") : null}
              {...props}
            />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <div className="col-12">{formDetail}</div>
        </div>
      </div>
    </>
  );
};

export default OnlineFormDetail;
