import React, { Fragment, useEffect, useState } from "react";
import BreadCrumb from "../global/breadCrumb";
import { cmsDataCommonMethod } from "../../services/gbsData";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../global/loading";
import {
  sortByField,
  IsArrayNotEmpty,
  getResolvedLanguage,
  IsObjectChecker,
  groupArrayByKey,
  IsTokenChecker,
  IsStringChecker,
  IsNumberChecker,
  safeArrayCheck,
  UniqueObjectArray,
  HandleErrorTrackAndToast,
} from "../../utils/helper";
import CurrentLocation from "../global/currentLocation";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import QuickLinks from "../home/quickLinks";
import constantsVariable from "../../utils/constantsVariable";
import ComponentListRenderer, {
  RenderContentBlockByOrder,
} from "../global/ContentBlockRenderSwitch";

const OSAcademyLanding = (props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const slug = `os-academy`;
  const history = useHistory();
  const {
    defaultEventImageURL,
    component_accordion_en_path,
    course_types_title_en_path,
    component_listing_dropdowns,
  } = constantsVariable;

  const { i18n } = useTranslation();
  const resolvedLanguage = getResolvedLanguage(i18n);
  const [, moreLangs] = CurrentLocation();

  const { accounts } = useMsal();
  const idToken = useSelector((state) => state.reducerToken);

  const [groupedDataByAccordion, setGroupedDataByAccordion] = useState({});
  const [componentList, setComponentList] = useState([]);
  const [individualComponentList, setIndividualComponentList] = useState({});

  const [indicator, setIndicator] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const [courses, setCourses] = useState({});

  const [defaultCalendar, setDefaultCalendar] = useState([]);
  const [calendar, setCalendar] = useState(null);
  const [activeStartDate, setActiveStartDate] = useState(new Date());

  const compareDate = (date1 = null, date2 = null) => {
    try {
      if (date1 && date2) {
        let str1 = date1.toDateString();
        let str2 = date2.toDateString();
        return str1 === str2;
      } else {
        return false;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const TimeFixer = (date = null) => {
    try {
      if (date) {
        let converted = moment.utc(date).local();
        return new Date(converted);
      } else {
        return null;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return date;
    }
  };

  const RenderDataClearer = () => {
    setCourseTypes([]);
    setCourses({});
  };

  const GetOriginalData = () => {
    DataOrganiser(masterData);
    setIndicator(false);
  };

  const SearchDate = (date = null) => {
    try {
      if (date) {
        let found = defaultCalendar.find((li) => compareDate(li, date));
        return found ? true : false;
      } else {
        return false;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const DataOrganiser = (slugRefinedData = []) => {
    try {
      if (IsArrayNotEmpty(slugRefinedData)) {
        let groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) =>
            UniqueObjectArray(a, b, c, `academy_course_types.slug`)
          )
        );
        let componentArr = sortByField(
          groupData.map((list) => _.get(list, "academy_course_types", {}))
        );
        setCourseTypes(componentArr);

        let groupedData = groupArrayByKey(
          slugRefinedData,
          course_types_title_en_path
        );
        setCourses(IsObjectChecker(groupedData) ? groupedData : {});
      } else {
        RenderDataClearer();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      RenderDataClearer();
    }
  };

  const getContentBlocks = async () => {
    const url = `content_blocks?select=*,page_listings!inner!fk_page_listing_id(id,title_en,slug),component_listings!inner!fk_content_block_component_listing_id(id,name,component_id,accordion_name_en,accordion_name_es,accordion_name_fr,order_by,icon_url,css_class,show_print_icon,show_previous_next)&page_listings.slug=eq.${slug}`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        const slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));

        const groupData = safeArrayCheck(
          slugRefinedData.filter((a, b, c) => UniqueObjectArray(a, b, c))
        );
        const componentArr = sortByField(
          groupData.map((list) => _.get(list, "component_listings", {}))
        );
        const firstComps = IsArrayNotEmpty(componentArr) ? componentArr[0] : {};
        setIndividualComponentList(firstComps);
        const restComps = IsArrayNotEmpty(componentArr)
          ? componentArr.slice(1)
          : [];
        setComponentList(restComps);

        const groupedData = groupArrayByKey(
          slugRefinedData,
          component_accordion_en_path
        );
        setGroupedDataByAccordion(
          IsObjectChecker(groupedData) ? groupedData : {}
        );
      })
      .catch((error) => {
        setGroupedDataByAccordion({});
        setComponentList([]);
        setIndividualComponentList({});
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      });
  };

  const getCourseListing = async () => {
    setLoading(true);
    const url = `academy_courses?select=*,academy_course_types(id,title_en,title_fr,title_es,slug,order_by),academy_schedules(id,schedule_date_from,schedule_date_to)&order=order_by.asc`;
    await cmsDataCommonMethod(idToken, accounts, url)
      .then((resp) => {
        let slugRefinedData = safeArrayCheck(_.get(resp, "data.data", []));
        setMasterData(slugRefinedData);

        let calendarData = slugRefinedData.reduce((accumulator, value) => {
          try {
            const { academy_schedules = [] } = value;
            if (IsArrayNotEmpty(academy_schedules)) {
              let schedules = academy_schedules.filter(
                (list) => list.schedule_date_from
              );
              let result = IsArrayNotEmpty(schedules)
                ? schedules.map((list) => {
                    return TimeFixer(list.schedule_date_from);
                  })
                : [];
              return [...accumulator, ...result];
            } else {
              return accumulator;
            }
          } catch (e) {
            HandleErrorTrackAndToast(e, true);
            return [];
          }
        }, []);
        let uniqueData = IsArrayNotEmpty(calendarData)
          ? calendarData.filter((value, index, arr) => {
              return arr.findIndex((li) => compareDate(li, value)) === index;
            })
          : [];

        setDefaultCalendar(safeArrayCheck(uniqueData));
        DataOrganiser(slugRefinedData);
      })
      .catch((error) => {
        setDefaultCalendar([]);
        setMasterData([]);
        RenderDataClearer();
        HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      })
      .finally(() => {
        setLoading(false);
        setIndicator(false);
      });
  };

  const onActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
  };

  const onDayClick = (value) => {
    try {
      setCalendar(value);
      let access =
        value &&
        IsArrayNotEmpty(defaultCalendar) &&
        IsArrayNotEmpty(masterData) &&
        SearchDate(value);
      if (access) {
        let scheduleArr = masterData.filter((list) => {
          const { academy_schedules = [] } = list;
          if (IsArrayNotEmpty(academy_schedules)) {
            let schedules = academy_schedules.filter((li) => {
              let dt = TimeFixer(li?.schedule_date_from);
              return compareDate(dt, value);
            });
            return IsArrayNotEmpty(schedules) ? true : false;
          }
        });
        if (IsArrayNotEmpty(scheduleArr)) {
          if (scheduleArr.length === 1) {
            let routeData = _.get(scheduleArr, `0`, {});
            const {
              id = null,
              slug = null,
              academy_course_types: { slug: course_type_slug = null } = {},
            } = routeData;
            if (IsNumberChecker(id) && IsTokenChecker(slug, course_type_slug)) {
              history.push(`/os-academy/${id}/${course_type_slug}/${slug}`);
            }
          } else {
            DataOrganiser(scheduleArr);
            setIndicator(true);
          }
        } else {
          GetOriginalData();
        }
      } else {
        GetOriginalData();
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setCalendar(null);
      GetOriginalData();
    }
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      return !SearchDate(date);
    } else {
      return false;
    }
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      return `cal-tile${SearchDate(date) ? ` sel-range` : ``}`;
    } else {
      return `os-cal-tile`;
    }
  };

  const weekDayFormat = (locale, date) => {
    return moment(date).locale(locale).format("dd");
  };

  const onClearAll = () => {
    GetOriginalData();
    setCalendar(null);
  };

  useEffect(() => {
    if (IsTokenChecker(idToken)) {
      getContentBlocks();
      getCourseListing();
    }
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, [idToken]);

  const IndicatorTextRenderer = ({ indicator = false, calendar = null }) => {
    try {
      if (indicator && calendar) {
        let formatted = moment(calendar)
          .locale(resolvedLanguage)
          .format("MMMM DD, YYYY");
        return (
          <Fragment>
            <span className="red_color">
              {` - ${_.get(
                moreLangs,
                `${resolvedLanguage}.os_academy.indicator`,
                `Showing courses for`
              )} `}
              <span className="text-capitalize">{`${formatted} `}</span>
            </span>
            <Badge
              className="cursor_pointer mt-2 mt-lg-0"
              bg="dark"
              as={"button"}
              pill
              onClick={onClearAll}
            >
              {`${_.get(
                moreLangs,
                `${resolvedLanguage}.os_academy.clear_all`,
                `Clear All`
              )}  `}
              <i className="fa fa-close ps-2"></i>
            </Badge>
          </Fragment>
        );
      } else {
        return <Fragment />;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return <Fragment />;
    }
  };

  const ctype_access = IsArrayNotEmpty(courseTypes);
  const { accordion_name_en: Name = null, show_previous_next: ShowPagination } =
    individualComponentList;
  const ContentList = _.get(groupedDataByAccordion, Name, []);
  const RenderProperties = {
    AdjustmentClassName: "mb-4 mb-lg-0",
    BlueTitleBoxClassNames: {
      appendColClassName: "col-lg-8",
    },
  };
  const RenderPropertiesGroup = [
    {
      PlainTextClassNames: {
        appendRowClassName: "mt-3 mb-3 mb-lg-5",
      },
    },
  ];
  return (
    <>
      {loading && (
        <div className="zindex10000">
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      <div className="row">
        <div className="row">
          <div className="col-12 d-none d-lg-block">
            <BreadCrumb {...props} />
          </div>
        </div>
        <div className="row mt-5 mt-lg-0">
          <RenderContentBlockByOrder
            ContentList={ContentList}
            Name={Name}
            WrapperRow={false}
            ShowPagination={ShowPagination}
            RenderProperties={RenderProperties}
            DefaultCssClass={component_listing_dropdowns?.blueTitleBox}
          />
          {/* {IsArrayNotEmpty(defaultCalendar) && ( */}
          <div className="col-12 col-lg-4 date_inline">
            <h4 className="card-header white_color ver-space-15 left-border-radius right-border-radius">
              {`${_.get(
                moreLangs,
                `${resolvedLanguage}.os_academy.course_calendar`,
                `Course Calendar`
              )}`}
            </h4>
            <div className="card-body mob-pleft-pright-0 p-0">
              <Calendar
                className={"os-calendar"}
                calendarType={"US"}
                minDetail={"decade"}
                formatShortWeekday={weekDayFormat}
                tileClassName={tileClassName}
                locale={resolvedLanguage}
                activeStartDate={activeStartDate}
                onActiveStartDateChange={onActiveStartDateChange}
                onChange={onDayClick}
                value={calendar}
                tileDisabled={tileDisabled}
                showNeighboringMonth={false}
                // view={"month"}
                // selectRange={true}
                // returnValue={"range"}
                // allowPartialRange={true}
              />
            </div>
          </div>
          {/* )} */}
          {ctype_access && (
            <div className="col-12 col-lg-8 mt-4">
              {ctype_access &&
                courseTypes.map((list, index) => {
                  const { title_en = null, slug: course_type_slug = null } =
                    list;
                  const objList = IsStringChecker(title_en)
                    ? courses[title_en]
                    : [];
                  const title = _.get(list, `title_${resolvedLanguage}`, "");
                  if (IsArrayNotEmpty(objList)) {
                    const renderList = sortByField(objList);
                    return (
                      <div key={index} className="region_list">
                        <div className="row">
                          {IsStringChecker(title) && (
                            <>
                              <h4 className="float-start text-start fw-bold pt-2 pb-4 mt-3 mt-lg-0 line-height-24">
                                {title}
                                <IndicatorTextRenderer
                                  indicator={indicator}
                                  calendar={calendar}
                                />
                              </h4>
                            </>
                          )}
                          {IsArrayNotEmpty(renderList) &&
                            renderList.map((li, ind) => {
                              const {
                                id = null,
                                feature_image_url = null,
                                slug = null,
                              } = li;
                              const course = _.get(
                                li,
                                `title_${resolvedLanguage}`,
                                ""
                              );
                              const url =
                                IsTokenChecker(slug, course_type_slug) &&
                                IsNumberChecker(id)
                                  ? `/os-academy/${id}/${course_type_slug}/${slug}`
                                  : `/os-academy`;
                              const image = IsStringChecker(feature_image_url)
                                ? feature_image_url
                                : defaultEventImageURL;
                              return (
                                <div
                                  key={ind}
                                  className={`col-12 col-lg-4 ${
                                    ind === 0 ? `mt-0` : ``
                                  }`}
                                >
                                  <Link
                                    to={url}
                                    className="position-relative d-block"
                                  >
                                    <img
                                      src={image}
                                      className="img-fluid border-radius-6"
                                    />
                                    <div className="position-absolute outer-grey-bg"></div>
                                    <h4 className="position-absolute white_color fw-bold">
                                      {IsStringChecker(course) ? course : "-"}
                                    </h4>
                                  </Link>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  } else {
                    return <Fragment key={index} />;
                  }
                })}
              <ComponentListRenderer
                ComponentList={componentList}
                GroupedData={groupedDataByAccordion}
                RenderPropertiesGroup={RenderPropertiesGroup}
                DefaultCssClass={component_listing_dropdowns?.plainText}
              />
            </div>
          )}
          <div className="col-12 col-lg-4 mt-4 date_inline d-none d-lg-block col-lg-2">
            <QuickLinks {...props} slug="os-academy" className="my-5 t-13" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OSAcademyLanding;
