export const SiteUrl = process.env.REACT_APP_URL;
export const CMSUrl = process.env.REACT_APP_CMSURL;
export const GbsBaseUrl = process.env.REACT_APP_GBSBASEURL;
export const WeatherIcon = process.env.REACT_APP_WEATHER_ICON;
export const CommentImageUrl = process.env.REACT_APP_COMMENT_IMAGE_URL;
export const AppInsightsKey = process.env.REACT_APP_APPINSIGHTS_KEY;
export const SignInPolicyName = process.env.REACT_APP_SIGNINPOLICY_NAME;
export { default as ConstantsVar } from "./constantsVariable.js";
export const htmlEncodeDecode = [
  { encodekey: '"', decodeValue: "quot;" },
  { encodekey: "<", decodeValue: "lt;" },
  { encodekey: ">", decodeValue: "gt;" },
  { encodekey: "=", decodeValue: "eq;" },
  { encodekey: "&", decodeValue: "amp;" },
  { encodekey: "'", decodeValue: "apos;" },
];
export const EasternCanadaRegionConsolidation = {
  names: ["New Brunswick", "Quebec", "Nova Scotia", "Atlantic Province", "Atlantic Provinces"],
  slugs: ["new-brunswick", "quebec", "nova-scotia", "atlantic-province", "atlantic-provinces"],
  slug: "eastern-canada",
  name: "Eastern Canada",
};
