/**
 * @component_Name : DDBeds -Delivery Data Beds component
 * @description : Show Delivery Data Beds as grid and accodion
 * @company : Photon
 * @author : Ashish Ranjan/19-07-2022
 **/
import React, { useEffect, useState } from "react";
import "../../../css/loading.css";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
// import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import {
  getCurrentYear,
  getFullDate,
  toDecimal,
  setOptions,
  setObjToString,
  IsTokenChecker,
  remainFirstCharacter,
  HandleErrorTrackAndToast,
  NumConverter,
  IsFunctionChecker,
  IsObjectChecker,
  IsArrayNotEmpty,
  IsNumberChecker,
  IsStringChecker,
  // compareString,
} from "../../../utils/helper";
import { cascadeFilterFunc } from "../../../utils/cascadeFilter";
import DataGridPagination from "../../global/dataGridPagination";
import { ConstantsVar } from "../../../utils/constants";
import ExportFile from "../../exportFile/exportFile";
import WhiteCircleLoader from "../../whiteCircleLoader";
import "react-datepicker/dist/react-datepicker.css";
import DDBedsMobile from "./dDBedsMobile";
import Moment from "react-moment";
import moment from "moment";
import PdfFile from "../../exportFile/pdfFile";
import Loading from "../../../components/global/loading";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import { useGbsDataCache } from "../../../services/gbsDataCache";
import DatePickerComp from "../../global/datepicker";

const DDBeds = (props) => {
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const dispatch = useDispatch();
  const {
    DropDownCBRoles = [],
    noContractsFound = "",
    listCropYear: propListCropYear = [],
    ContractIds: propContractIds = "",
    minMaxYear: propMinMaxYear = [],
    currentCropYear: propCurrentCropYear = 0,
    ContractOption: propContractOption = [],
    filterItems: propFilterItems = [],
  } = props;
  const tokenId = useSelector((state) => state.reducerToken);
  const [listCropYear, setListCropYear] = useState([]);
  const [currentCropYear, setCurrentCropYear] = useState(0);
  const [multiCrop, setMultiCrop] = useState({});
  const [multiContId, setMultiContId] = useState([]);
  const [multiValue, setMultiValue] = useState({});
  /*filter default disabled**/
  const [disabled, setDisabled] = useState("disabled");
  const [gridItems, setGridItems] = useState([]);
  const [preSelectedArray, setPreSelectedArray] = useState([]);
  //const [CropYears, setCropYears] = useState([]);
  const [fromMinDate, setfromMinDate] = useState();
  const [endMinDate, setendMinDate] = useState();
  const [endMaxDate, setendMaxDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minMaxYear, setMinMaxYear] = useState([]);
  const [disablePreSelected, setDisablePreSelected] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [isReset, setIsReset] = useState(true);
  const [hideExport, setHideExport] = useState(true);
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");

  const [filterItems, setFilterItems] = useState([]);
  const [ContractOption, setContractOption] = useState([]);
  // const [reloadcontract, setReloadcontract] = useState(true);
  const [reloadcontract] = useState(true);
  const [contractPreselect, setContractPreselect] = useState(["all"]);
  const [bogNameOption, setBogNameOption] = useState([]);
  const [harvestbedOption, setHarvestbedOption] = useState([]);
  const [verityOption, setVerityOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [mobileItems, setMobileItems] = useState([]);
  const [ContractIds, setContractIds] = useState([]);
  const [reloadCropyear, setReloadCropyear] = useState(true);
  const [exportItems, setExportItems] = useState([]);
  const [exportParameter, setExportParameter] = useState([]);
  const [formatArray, setFormatArray] = useState([]);
  const [PDFExport, setPDFExport] = useState([]);
  const [disableContractField, setDisableContractField] = useState(true);

  const DisplayDataSafe = (
    propVal = {},
    field = "",
    returnVal = `-`,
    fixed = false,
    formatFunc = false,
    convertFunc = false,
    fixedVal = 2,
    currency = ""
  ) => {
    try {
      const temp = _.get(propVal, `row.${field}`, null);
      const convert = IsFunctionChecker(convertFunc);
      const format = IsFunctionChecker(formatFunc);
      if (temp) {
        if (convert && fixed) {
          const temp2 = convertFunc(temp).toFixed(fixedVal);
          const temp3 = format
            ? formatFunc(temp2, fixedVal, currency, returnVal)
            : temp2;
          return temp3 || returnVal;
        } else {
          const temp2 = convert
            ? convertFunc(temp)
            : fixed
            ? temp.toFixed(fixedVal)
            : temp;
          const temp3 = format
            ? formatFunc(temp2, fixedVal, currency, returnVal)
            : temp2;
          return temp3 || returnVal;
        }
      }
      return returnVal;
    } catch (e) {
      // HandleErrorTrackAndToast(e, true);
      return returnVal;
    }
  };
  const columns = [
    {
      key: "CropYear",
      name: "Crop Year",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-center",
    },
    {
      key: "RelationshipNumber",
      name: "Contract #",
      // name: "Contract Number",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-center",
    },
    {
      key: "HarvestBed",
      name: "Harvest Bed",
      width: 90,
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-center",
    },
    {
      key: "Acres",
      name: "Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "Acres",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "ActiveAcres",
      name: "Active Acres",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "ActiveAcres",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "LegacyVarietyCode",
      name: "Variety",
      width: 100,
      cellClass: "enablesTextWrapping text-lg-center",
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
    },
    {
      key: "PlantDate",
      name: "Plant Year",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-center",
      formatter(props) {
        let temp = _.get(props, "row.PlantDate", null);
        let xyz = temp ? getFullDate(temp, "year") : null;
        return <>{xyz ? xyz : "-"}</>;
      },
    },
    {
      key: "Status",
      name: "Status",
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-center",
      width: 80,
      formatter(props) {
        let temp = _.get(props, "row.Status", null);
        let xyz = temp ? remainFirstCharacter(temp) : "-";
        return (
          <>
            {
              <span title={temp} className="text-capitalize">
                {xyz}
              </span>
            }
          </>
        );
      },
    },
    {
      key: "EarliestDeliveryDate",
      name: "Date Range (From)",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-center",
      formatter(props) {
        let temp = _.get(props, "row.EarliestDeliveryDate", null);
        return temp ? (
          <Moment utc format={ConstantsVar.dateFormate}>
            {temp}
          </Moment>
        ) : (
          "-"
        );
      },
    },
    {
      key: "LatestDeliveryDate",
      name: "Date Range (To)",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-center",
      formatter(props) {
        let temp = _.get(props, "row.LatestDeliveryDate", null);
        return temp ? (
          <Moment utc format={ConstantsVar.dateFormate}>
            {temp}
          </Moment>
        ) : (
          "-"
        );
      },
    },
    {
      key: "TotalBarrels",
      name: "Total Barrels",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "TotalBarrels",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentTotalTrash",
      name: "Avg % Total Trash",
      width: 90,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentTotalTrash",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "AvgPercentPoor",
      name: "Avg % Poor",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "AvgPercentPoor",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "PercentClass1",
      name: "Avg % Class1",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "PercentClass1",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "AvgPercentUsable",
      name: "Avg % Usable",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        return DisplayDataSafe(
          props,
          "AvgPercentUsable",
          `0.00`,
          true,
          toDecimal,
          parseFloat
        );
      },
    },
    {
      key: "Yield",
      name: "Yield",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-center",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = Math.round(NumConverter(_.get(props, "row.Yield", null)));
        let temp2 = Math.round(
          NumConverter(_.get(props, "row.LastYearYield", null))
        );
        return (
          <>
            {temp}{" "}
            {temp >= temp2 ? (
              <i
                className="fa fa-arrow-up green_dark_color text-14"
                aria-hidden="true"
              ></i>
            ) : (
              <i
                className="fa fa-arrow-down red_color text-14"
                aria-hidden="true"
              ></i>
            )}
          </>
        );
      },
    },
    {
      key: "LastYearYield",
      name: "Prior Year Yield",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = NumConverter(_.get(props, "row.LastYearYield", null));
        let xyz = temp ? Math.round(temp) : null;
        return <>{xyz || 0}</>;
      },
    },
    {
      key: "FourYearAverageYield",
      name: "4 Year Avg Yield",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = NumConverter(_.get(props, "row.FourYearAverageYield", null));
        let xyz = temp ? Math.round(temp) : null;
        return <>{xyz || 0}</>;
      },
    },
    {
      key: "PriorFourYearAverageYield",
      name: "Prior 4 Years Avg Yield",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = NumConverter(
          _.get(props, "row.PriorFourYearAverageYield", null)
        );
        let xyz = temp ? Math.round(temp) : null;
        return <>{xyz || 0}</>;
      },
    },
    {
      key: "YieldMaximum",
      name: "Max Yield",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = NumConverter(_.get(props, "row.YieldMaximum", null));
        let xyz = temp ? Math.round(temp) : null;
        return <>{xyz || 0}</>;
      },
    },
    {
      key: "YieldMaximumReached",
      name: "Max Yield Year",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = NumConverter(_.get(props, "row.YieldMaximumReached", null));
        let xyz = temp ? Math.round(temp) : null;
        return <>{xyz || 0}</>;
      },
    },
    {
      key: "YieldPercentToMax",
      name: "% Above/ Below Max Yield",
      width: 120,
      headerCellClass: "enablesTextWrappingHeading text-lg-end",
      cellClass: "text-lg-end",
      formatter(props) {
        let temp = NumConverter(_.get(props, "row.YieldPercentToMax", null));
        let xyz = temp ? Math.round(temp) : null;
        return <>{xyz ? `${xyz}%` : 0}</>;
      },
    },
  ];
  const ongoingYear = getCurrentYear();

  //Cache Implementation Start
  const [searchURL, setSearchURL] = useState("");
  const { status, data } = useGbsDataCache(
    tokenId,
    accounts,
    searchURL,
    "get",
    {}
  );
  const [changeEvent, setChangeEvent] = useState(0);
  const [responseType, setResponseType] = useState(0);
  const [displayAccordian, setDisplayAccordian] = useState(false);
  useEffect(() => {
    setDisplayAccordian(
      localStorage.getItem("safariLowerVersion") === "true" || false
    );
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  useEffect(() => {
    if (responseType === "withoutSubmit") {
      if (data.data) {
        filterAllBedsData(data);
      }
      if (status === "error") {
        setLoader(false);
        // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      }
    } else {
      if (data.data) {
        fetchAllBedsData(data);
      }
      if (status === "error") {
        setLoader(false);
        setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
        // HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
      }
    }
  }, [data, changeEvent, status]);
  //Cache Implementation End

  useEffect(() => {
    setBogNameOption([]);
    setHarvestbedOption([]);
    setVerityOption([]);
    setLoader(true);
    if (IsTokenChecker(tokenId) && propListCropYear.length > 0) {
      setCurrentCropYear(propCurrentCropYear);
      setMinMaxYear({ ...propMinMaxYear });
      setListCropYear([...propListCropYear]);
      setPreSelectedArray([propCurrentCropYear]);
      setMultiCrop({ cropyear: `${propCurrentCropYear}` });
      setDisableContractField(false);
      setContractIds(propContractIds);
      setContractOption([...propContractOption]);
      setFilterItems([...propFilterItems]);
      setLoader(false);
      // if(propContractIds!='' && currentCropYear!=''){
      //   const url = `deliveries/by-bed?cropyear=${_.orderBy(currentCropYear?.toString()?.split(','),[] ,['asc']).toString()}&contractid=${_.orderBy(propContractIds?.toString()?.split(','),[] ,['asc']).toString()}&filtersonly=1`;
      //   setResponseType('withoutSubmit');
      //   filterBedsData(url);
      // }
    }
  }, [
    propFilterItems,
    propCurrentCropYear,
    propMinMaxYear,
    propListCropYear,
    tokenId,
    propContractOption
  ]);

  useEffect(() => {
    let minYear = minMaxYear["minYear"];
    let maxYear = minMaxYear["maxYear"];
    if (minYear) {
      setfromMinDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + minYear)
      );
      setendMinDate(new Date(ConstantsVar.startingDateOfYear + ", " + minYear));
      setStartDate(new Date(ConstantsVar.startingDateOfYear + ", " + minYear));
    } else if (currentCropYear != "") {
      setfromMinDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + currentCropYear)
      );
      setendMinDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + currentCropYear)
      );
      setStartDate(
        new Date(ConstantsVar.startingDateOfYear + ", " + currentCropYear)
      );
    }
    if (maxYear) {
      if (maxYear == ongoingYear) {
        setEndDate(new Date());
        setendMaxDate(new Date());
      } else {
        setendMaxDate(new Date(ConstantsVar.endingDateOfYear + ", " + maxYear));
        setEndDate(new Date(ConstantsVar.endingDateOfYear + ", " + maxYear));
      }
    } else if (currentCropYear != "") {
      setendMaxDate(new Date());
      setEndDate(new Date());
    }
  }, [minMaxYear, reloadCropyear]);

  /*multi select cropYears*/
  const filterBedsData = async (url) => {
    if (tokenId != "") {
      setSearchURL(url);
      url === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const filterAllBedsData = async (resp) => {
    let resultData = [...resp.data];
    let filteredData = FilterContractByRole(resultData, accounts, DropDownCBRoles);
    if (
      filteredData.length > 0 &&
      resp.data !== "Contract and CropYear must be provided"
    ) {
      // const contractList = setOptions(
      //   filteredData,
      //   "RelationshipId",
      //   "RelationshipNumber",
      //   "Name"
      // );
      // setContractOption(contractList);
      setFilterItems(filteredData);
      // if (
      //   multiContId["contract_id"] !== undefined &&
      //   multiContId["contract_id"] !== ""
      // ) {
      //   setContractPreselect([]);
      // }
      handlerContract(propContractIds, "normal", filteredData);
      //setDisabled(false);
      setFilterItems(filteredData);
    } else {
      // setContractOption([]);
      setBogNameOption([]);
      setHarvestbedOption([]);
      setVerityOption([]);
      setDisabled(true);
      setHideExport(true);
      setGridItems([]);
      setFilterItems([]);
    }
    setLoader(false);
  };

  const fetchBedsData = async (apiUrl) => {
    if (tokenId != "") {
      setSearchURL(apiUrl);
      setResponseType("submit");
      apiUrl === searchURL && setChangeEvent(changeEvent + 1);
    }
  };

  const fetchAllBedsData = (resp) => {
    let filterData = [...resp.data];
    // let completedData = [...resp.data];
    // console.log({completedData});
    // let filterData = _.filter(
    //   completedData,
    //   (obj) =>
    // obj.ReceiptStatus == "Complete" ||
    // compareString(obj.ReceiptStatus, "Released") ||
    // compareString(obj.ReceiptStatus, "Inventoried") ||
    // compareString(obj.ReceiptStatus, "Paid")
    // );
    // Filter values start
    if (multiValue?.bognames) {
      filterData = filterData.filter((item) => {
        return (
          multiValue?.bognames
            ?.toString()
            ?.toLowerCase()
            ?.split(",")
            ?.indexOf(item?.BogName?.toString()?.toLowerCase()) !== -1
        );
      });
    }
    if (multiValue?.harvestbeds) {
      filterData = filterData.filter((item) => {
        return (
          multiValue?.harvestbeds
            ?.toString()
            ?.toLowerCase()
            ?.split(",")
            ?.indexOf(item?.HarvestBed?.toString()?.toLowerCase()) !== -1
        );
      });
    }
    if (multiValue?.varietyids) {
      filterData = filterData.filter((item) => {
        return (
          multiValue?.varietyids
            ?.toString()
            ?.toLowerCase()
            ?.split(",")
            ?.indexOf(item?.VarietyId?.toString()?.toLowerCase()) !== -1
        );
      });
    }
    if (startDate || endDate) {
      filterData = filterData.filter((item) => {
        let dateFrom =
          startDate && moment(startDate).format(ConstantsVar.dateFormate);
        let dateto =
          endDate && moment(endDate).format(ConstantsVar.dateFormate);
        let EarliestDeliveryDate = moment(item.EarliestDeliveryDate).format(
          ConstantsVar.dateFormate
        );
        let LatestDeliveryDate = moment(item.LatestDeliveryDate).format(
          ConstantsVar.dateFormate
        );
        return (
          moment(dateFrom).isSameOrBefore(EarliestDeliveryDate) &&
          moment(dateto).isSameOrAfter(LatestDeliveryDate)
        );
      });
    }
    // filterData =_.map(filterData, function(item) {
    //     return _.merge(item, _.find(filterItems, { 'VarietyId' : item.VarietyId }));
    // });
    const newData = filterData.map((list) => {
      const found = filterItems.find((li) => li?.VarietyId === list?.VarietyId);
      const LegacyVarietyCode = _.get(found, "LegacyVarietyCode", null);
      return { ...list, LegacyVarietyCode };
    });

    loadFilter(newData);
    setGridItems(newData);
    newData.length > 0 ? setIsListingHide(false) : setIsListingHide(true);
    newData.length > 0 ? setHideExport(false) : setHideExport(true);
    newData.length > 0
      ? setNoRecordErrorMsg("")
      : setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
    setLoader(false);

    setLoading(false); /*remove loader from search button*/
    setSearchBtnDisabled(false); /*disabled search button*/
    setisMobFilter(false);
  };
  // Contract select change event
  const handlerContract = (val, from = "changeEvent", filteredData) => {
    // console.log({val, from, filteredData});
    setMultiValue(null);
    // let filter = filterItems.filter((item) => {
    //   return (
    //     setObjToString(val)
    //       ?.toLowerCase()
    //       ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
    //   );
    // });
    let filter = [];
    if (from === "changeEvent") {
      filter = filteredData.filter((item) => {
        return (
          setObjToString(val)
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
      setSearchBtnDisabled(false);
    } else {
      filter = filteredData.filter((item) => {
        return (
          val
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
    }
    // console.log({filter});
    setIsReset(false);
    setDisabled(false);
    setIsListingHide(true);
    setBogNameOption(setOptions(filter, "BogName", "BogName"));
    setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
    setVerityOption(
      setOptions(filter, "VarietyId", "LegacyVarietyCode", "", "Variety")
    );
  };

  const handlerContractDel = (selectedList, removedItem) => {
    setMultiValue(null);
    setGridItems([]); /*set empty to grid Item */
    setIsListingHide(true);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      if (multiCrop?.cropyear) {
        setIsReset(false);
      } else {
        setIsReset(true);
      }
      setMultiContId(null); /*set null to contract filter */
      setSearchBtnDisabled(true);
    } else {
      let filter = filterItems.filter((item) => {
        return (
          setObjToString(selectedList)
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
      setIsReset(false);
      setDisabled(false);
      setSearchBtnDisabled(false);
      setBogNameOption(setOptions(filter, "BogName", "BogName"));
      setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
      setVerityOption(
        setOptions(filter, "VarietyId", "LegacyVarietyCode", "", "Variety")
      );
    }
  };

  const handlerSubmit = () => {
    setIsListingHide(true);
    setGridItems([]); /*set empty to grid Item */
    // const arrayQueryString = [];
    // multiValue &&
    //   Object.keys(multiValue).map((i, index) => {
    //     multiValue[i] != ""
    //       ? (arrayQueryString[index] = `${i}=${multiValue[i]}`)
    //       : "";
    //   });

    // let qCropyear = ongoingYear;
    // if (!multiCrop["cropyear"]) {
    //   qCropyear = currentCropYear > 0 ? currentCropYear : ongoingYear;
    // } else {
    //   qCropyear = multiCrop["cropyear"];
    // }

    // let QueryString =
    //   arrayQueryString.length > 0 ? "&" + arrayQueryString.join("&") : "";
    // QueryString = `${QueryString}&contractid=${multiContId["contract_id"]}`; //894

    let qCropyear = ongoingYear;
    if (!multiCrop["cropyear"]) {
      qCropyear = currentCropYear > 0 ? currentCropYear : ongoingYear;
    } else {
      qCropyear = multiCrop["cropyear"];
    }
    const apiUrl = `deliveries/by-bed?cropyear=${_.orderBy(
      qCropyear.toString().split(","),
      [],
      ["asc"]
    ).toString()}&contractid=${_.orderBy(
      multiContId["contract_id"].split(","),
      [],
      ["asc"]
    ).toString()}`;
    // const apiUrl = `deliveries/by-bed?cropyear=${qCropyear}${QueryString}`;
    const reqData = {
      currentYear: multiCrop?.cropyear,
      contract_id: getContracts(multiContId),
      bognames: multiValue?.bognames,
      harvestbeds: multiValue?.harvestbeds,
      varityids: getVarieties(multiValue),
      datefrom: DateProvider(multiValue?.datefrom || startDate),
      dateto: DateProvider(multiValue?.dateto || endDate),
    };
    setExportParameter(
      ParameterDataGetter({
        ...reqData,
      })
    );
    fetchBedsData(apiUrl);
  };

  const getVarieties = (temp = {}) => {
    try {
      const varities = _.get(temp, "varietyids", "").split(",");
      return varities
        .reduce((acc, li) => {
          try {
            const found = verityOption.find((l) => l["id"] == li);
            if (found !== undefined) {
              return [...acc, found["name"]];
            } else {
              return acc;
            }
          } catch (error) {
            console.error(error);
            return [];
          }
        }, [])
        .join();
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const getContracts = (temp = {}) => {
    try {
      const contracts = temp["contract_id"].split(",");
      return contracts
        .reduce((acc, li) => {
          try {
            const found = ContractOption.find((l) => l["id"] == li);
            if (found !== undefined) {
              return [...acc, found["number"]];
            } else {
              return acc;
            }
          } catch (error) {
            console.error(error);
            return [];
          }
        }, [])
        .join();
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const DateProvider = (dat = null) => {
    try {
      return dat ? moment(dat).format(ConstantsVar.dateFormate) : "";
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  // On click search
  function loadFilter(filterData) {
    const overWirteMobData = Object.values(filterData).map((item) => {
      const {
        CropYear = null,
        RelationshipNumber = null,
        HarvestBed = null,
        Acres = null,
        ActiveAcres = null,
        // Variety = null,
        LegacyVarietyCode = null,
        PlantDate = null,
        Status = null,
        EarliestDeliveryDate = null,
        LatestDeliveryDate = null,
        TotalBarrels = null,
        PercentTotalTrash = null,
        AvgPercentPoor = null,
        PercentClass1 = null,
        AvgPercentUsable = null,
        Yield = null,
        LastYearYield = null,
        FourYearAverageYield = null,
        PriorFourYearAverageYield = null,
        YieldMaximum = null,
        YieldMaximumReached = null,
        YieldPercentToMax = null,
      } = item;
      const yieldval = NumConverter(YieldPercentToMax);
      const PercentMax = yieldval ? Math.round(yieldval) : null;
      return {
        Crop_Year: CropYear,
        Contract_Number: RelationshipNumber,
        Harvest_Bed: HarvestBed,
        Acres: toDecimal(NumConverter(Acres).toFixed(2), 2, "", `0.00`),
        Active_Acres: toDecimal(
          NumConverter(ActiveAcres).toFixed(2),
          2,
          "",
          `0.00`
        ),
        Variety: LegacyVarietyCode,
        Plant_Year: getFullDate(PlantDate, "year"),
        Status: Status,
        "Date_Range_(From)": EarliestDeliveryDate,
        "Date_Range_(To)": LatestDeliveryDate,
        Total_Barrels: toDecimal(
          NumConverter(TotalBarrels).toFixed(2),
          2,
          "",
          `0.00`
        ),
        "Avg_%_Total_Trash": toDecimal(
          NumConverter(PercentTotalTrash).toFixed(2),
          2,
          "",
          `0.00`
        ),
        "Avg_%_Poor": toDecimal(
          NumConverter(AvgPercentPoor).toFixed(2),
          2,
          "",
          `0.00`
        ),
        "Avg_%_Class1": toDecimal(
          NumConverter(PercentClass1).toFixed(2),
          2,
          "",
          `0.00`
        ),
        "Avg_%_Usable": toDecimal(
          NumConverter(AvgPercentUsable).toFixed(2),
          2,
          "",
          `0.00`
        ),
        Yield: Math.round(NumConverter(Yield)),
        Prior_Year_Yield: Math.round(NumConverter(LastYearYield)),
        "4_Year_Average_Yield": Math.round(NumConverter(FourYearAverageYield)),
        Prior_4_Years_Average_Yield: Math.round(
          NumConverter(PriorFourYearAverageYield)
        ),
        Max_Yield: Math.round(NumConverter(YieldMaximum)),
        Max_Yield_Year: Math.round(NumConverter(YieldMaximumReached)),
        "%_Above/Below_Max_Yield": PercentMax ? `${PercentMax}%` : 0,
      };
    });
    const pdfExportData = [];
    const exportData = Object.values(filterData).map((item) => {
      const {
        CropYear = null,
        RelationshipNumber = null,
        Name = null,
        Name2 = null,
        PoolDescription = null,
        BogName = null,
        HarvestBed = null,
        HarvestBedIdentifier = null,
        Acres = null,
        ActiveAcres = null,
        AdjustedAcres = null,
        LegacyVarietyCode = null,
        PlantDate = null,
        Status = null,
        AreaDescription = null,
        RegionDescription = null,
        StateDescription = null,
        Country = null,
        FreshProcessedBoth = null,
        DeliveryWeight = null,
        UsableBarrels = null,
        ShrinkBarrels = null,
        TotalBarrels = null,
        WhiteBarrels = null,
        EarlyBarrels = null,
        LateBarrels = null,
        FreshBarrels = null,
        ProcessedBarrels = null,
        Yield = null,
        YieldActive = null,
        YieldAdjustedActive = null,
        EarliestDeliveryDate = null,
        LatestDeliveryDate = null,
        DeliveryRangeInDays = null,
        AvgBrix = null,
        AvgTacy = null,
        AvgPercentPoor = null,
        AvgPercentUsable = null,
        PercentUncolored = null,
        FirmnessAverage = null,
        PercentGreaterThanHalf = null,
        PercentClass1 = null,
        FourYearAverageYield = null,
        PriorFourYearAverageYield = null,
        LastYearYield = null,
      } = item;

      const pdfData = {
        "Crop Year": CropYear,
        "Contract Number": RelationshipNumber,
        Name: Name,
        Name2: Name2 || "",
        Pool: PoolDescription,
        "Bog Name": BogName,
        "Harvest Bed": HarvestBed,
        "Harvest Bed Identifier": HarvestBedIdentifier,
        Acres: toDecimal(NumConverter(Acres)?.toFixed(4), 4, "", 0),
        "Active Acres": toDecimal(
          NumConverter(ActiveAcres)?.toFixed(4),
          4,
          "",
          0
        ),
        "Adjusted Acres": toDecimal(
          NumConverter(AdjustedAcres)?.toFixed(4),
          4,
          "",
          0
        ),
        Variety: LegacyVarietyCode,
        "Plant Year": getFullDate(PlantDate, "year"),
        Status: Status,
        Area: AreaDescription,
        Region: RegionDescription,
        State: StateDescription,
        Country: Country,
        "Fresh Processed Both": FreshProcessedBoth,
        "Delivery Weight": toDecimal(
          NumConverter(DeliveryWeight)?.toFixed(4),
          4,
          "",
          0
        ), //19
        "Usable Barrels": toDecimal(
          NumConverter(UsableBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        "Shrink Barrels": toDecimal(
          NumConverter(ShrinkBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        "Total Barrels": toDecimal(
          NumConverter(TotalBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        "White Barrels": toDecimal(
          NumConverter(WhiteBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        "Early Barrels": toDecimal(
          NumConverter(EarlyBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        "Late Barrels": toDecimal(
          NumConverter(LateBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        "Fresh Barrels": toDecimal(
          NumConverter(FreshBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        "Processed Barrels": toDecimal(
          NumConverter(ProcessedBarrels)?.toFixed(4),
          4,
          "",
          0
        ),
        Yield: toDecimal(NumConverter(Yield)?.toFixed(4), 4, "", 0),
        "Yield Active": toDecimal(
          NumConverter(YieldActive)?.toFixed(4),
          4,
          "",
          0
        ),
        "Yield Adjusted Active": toDecimal(
          NumConverter(YieldAdjustedActive)?.toFixed(4),
          4,
          "",
          0
        ), //30
        "Earliest Harvest Date": EarliestDeliveryDate
          ? moment
              .utc(EarliestDeliveryDate)
              .format(ConstantsVar.dateTimeFormate)
          : "-",
        "Latest Delivery Date": LatestDeliveryDate
          ? moment.utc(LatestDeliveryDate).format(ConstantsVar.dateTimeFormate)
          : "-",
        "Delivery Range In Days": DeliveryRangeInDays,
        "Avg Brix": toDecimal(NumConverter(AvgBrix)?.toFixed(4), 4, "", 0), //34
        "Avg Tacy": toDecimal(NumConverter(AvgTacy)?.toFixed(4), 4, "", 0),
        "Avg Percent Poor": toDecimal(
          NumConverter(AvgPercentPoor)?.toFixed(4),
          4,
          "",
          0
        ),
        "Avg Percent Usable": toDecimal(
          NumConverter(AvgPercentUsable)?.toFixed(4),
          4,
          "",
          0
        ), //37
        "Percent Uncolored": PercentUncolored,
        "Firmness Average": FirmnessAverage,
        "Percent Greater Than Half": toDecimal(
          NumConverter(PercentGreaterThanHalf)?.toFixed(4),
          4,
          "",
          0
        ), //40
        "Avg Percent Class One": toDecimal(
          NumConverter(PercentClass1)?.toFixed(4),
          4,
          "",
          0
        ),
        "4Yrs Avg. Yield": toDecimal(
          NumConverter(FourYearAverageYield)?.toFixed(4),
          4,
          "",
          0
        ),
        "Prior 4Yrs Avg. Yield": toDecimal(
          NumConverter(PriorFourYearAverageYield)?.toFixed(4),
          4,
          "",
          0
        ),
        "Last Year Yield": toDecimal(
          NumConverter(LastYearYield)?.toFixed(4),
          4,
          "",
          0
        ),
      };
      pdfExportData.push(pdfData);
      return {
        "Crop Year": CropYear,
        "Contract Number": RelationshipNumber,
        Name: Name,
        Name2: Name2 || "",
        Pool: PoolDescription,
        "Bog Name": BogName,
        "Harvest Bed": HarvestBed,
        "Harvest Bed Identifier": HarvestBedIdentifier,
        Acres: parseFloat(Acres) || 0,
        "Active Acres": parseFloat(ActiveAcres) || 0,
        "Adjusted Acres": parseFloat(AdjustedAcres) || 0,
        Variety: LegacyVarietyCode,
        "Plant Year": getFullDate(PlantDate, "year"),
        Status: Status,
        Area: AreaDescription,
        Region: RegionDescription,
        State: StateDescription,
        Country: Country,
        "Fresh Processed Both": FreshProcessedBoth,
        "Delivery Weight": parseFloat(DeliveryWeight.toFixed(4)) || 0, //19
        "Usable Barrels": parseFloat(UsableBarrels.toFixed(4)) || 0,
        "Shrink Barrels": parseFloat(ShrinkBarrels.toFixed(4)) || 0,
        "Total Barrels": parseFloat(TotalBarrels.toFixed(4)) || 0,
        "White Barrels": parseFloat(WhiteBarrels.toFixed(4)) || 0,
        "Early Barrels": parseFloat(EarlyBarrels.toFixed(4)) || 0,
        "Late Barrels": parseFloat(LateBarrels.toFixed(4)) || 0,
        "Fresh Barrels": parseFloat(FreshBarrels.toFixed(4)) || 0,
        "Processed Barrels": parseFloat(ProcessedBarrels.toFixed(4)) || 0,
        Yield: parseFloat(Yield.toFixed(4)) || 0,
        "Yield Active": parseFloat(YieldActive.toFixed(4)) || 0,
        "Yield Adjusted Active":
          parseFloat(YieldAdjustedActive.toFixed(4)) || 0,
        "Earliest Harvest Date": EarliestDeliveryDate
          ? moment
              .utc(EarliestDeliveryDate)
              .format(ConstantsVar.exportdateTimeFormateAMPM)
          : "-",
        "Latest Delivery Date": LatestDeliveryDate
          ? moment
              .utc(LatestDeliveryDate)
              .format(ConstantsVar.exportdateTimeFormateAMPM)
          : "-",
        "Delivery Range In Days": DeliveryRangeInDays,
        "Avg Brix": parseFloat(AvgBrix.toFixed(4)) || 0, //34
        "Avg Tacy": parseFloat(AvgTacy.toFixed(4)) || 0,
        "Avg Percent Poor": parseFloat(AvgPercentPoor.toFixed(4)) || 0,
        "Avg Percent Usable": parseFloat(AvgPercentUsable.toFixed(4)) || 0,
        "Percent Uncolored": PercentUncolored,
        "Firmness Average": FirmnessAverage,
        "Percent Greater Than Half":
          parseFloat(PercentGreaterThanHalf.toFixed(4)) || 0, //40
        "Avg Percent Class One": parseFloat(PercentClass1.toFixed(4)) || 0,
        "4Yrs Avg. Yield": parseFloat(FourYearAverageYield.toFixed(4)) || 0,
        "Prior 4Yrs Avg. Yield":
          parseFloat(PriorFourYearAverageYield.toFixed(4)) || 0,
        "Last Year Yield": parseFloat(LastYearYield.toFixed(4)) || 0,
      };
    });
    setPDFExport(pdfExportData);
    setExportItems(exportData);
    setMobileItems(overWirteMobData);
    let format = [
      {
        index: 8,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 9,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 10,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 19,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 20,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 21,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 22,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 23,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 24,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 25,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 26,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 27,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 28,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 29,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 30,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 34,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 35,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 36,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 37,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 40,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 41,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 42,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 43,
        format: "#,##0.0000_);(#,##0.0000)",
      },
      {
        index: 44,
        format: "#,##0.0000_);(#,##0.0000)",
      },
    ];
    setFormatArray(format);
  }

  const handlerCropYear = (val, selectedItem, type) => {
    setMultiValue(null);
    let cropyears = "";
    const cropIds = val.map((x) => x.id);
    const maxYear = Math.max(...cropIds);
    const minYear = Math.min(...cropIds);
    setMinMaxYear({ maxYear: maxYear, minYear: minYear });
    setIsListingHide(true);

    if (type === "selected") {
      setDisablePreSelected(false);
      setPreSelectedArray([]);
      setDisableContractField(false);
    }
    selectedItem;
    if (val.length > 1) {
      if (val.length == 15 && type === "deSelected") {
        setMinMaxYear({ maxYear: currentCropYear, minYear: currentCropYear });
        cropyears = currentCropYear;
        setReloadCropyear(false);
        setTimeout(() => {
          setPreSelectedArray([currentCropYear]);
          setDisableContractField(false);
          setDisablePreSelected(true);
          setReloadCropyear(true);
        }, 0);
      } else {
        cropyears = setObjToString(val);
        setDisablePreSelected(false);
        setDisableContractField(false);
      }
    } else if (val.length == 1) {
      cropyears = setObjToString(val);
      setReloadCropyear(false);
      setTimeout(() => {
        setPreSelectedArray([maxYear]);
        setDisableContractField(false);
        setDisablePreSelected(true);
        setReloadCropyear(true);
      }, 0);
    }
    if (multiContId?.contract_id) {
      setSearchBtnDisabled(false);
    } else {
      setSearchBtnDisabled(true);
    }
    if (cropyears !== "") {
      // const url = `deliveries/by-bed?contractid=${ContractIds}&cropyear=${cropyears}&filtersonly=1`;
      const url = `deliveries/by-bed?cropyear=${_.orderBy(
        cropyears.split(","),
        [],
        ["asc"]
      ).toString()}&contractid=${_.orderBy(
        ContractIds.split(","),
        [],
        ["asc"]
      ).toString()}&filtersonly=1`;
      setResponseType("withoutSubmit");
      filterBedsData(url);
    }
  };

  const handlerOtherFilter = (
    selectedList,
    currentItem,
    action,
    request = ""
  ) => {
    let multival = { ...multiValue };
    if (action == "selected") {
      setGridItems([]); /*set empty to grid Item */
      setIsListingHide(true);
      setNoRecordErrorMsg("");
    } else if (action == "deselected") {
      setGridItems([]); /*set empty to grid Item */
      setIsListingHide(true);
    }

    const searchParam = {
      Contract_id: ["RelationshipId"],
      Bog_name: ["BogName", "BogName"],
      Harvest_bed: ["HarvestBed", "HarvestBed"],
      Varity_id: ["VarietyId", "LegacyVarietyCode", "", "Variety"],
    };
    let filter = [...filterItems];
    cascadeFilterFunc({
      filter,
      selectedList,
      action,
      request,
      multiContId,
      multival,
      searchParam,
      setBogNameOption,
      setHarvestbedOption,
      setVerityOption,
    });
  };

  const handleReset = (e) => {
    e.stopPropagation();
    setIsReset(true); /*disabled Reset button*/
    setMultiValue(null); /*set null to all filter */
    setDisabled(true); /*disabled filter dropdown*/
    setSearchBtnDisabled(true); /*disabled search button*/
    setGridItems([]); /*set empty to grid Item */
    setHideExport(true);
    setStartDate();
    setEndDate();
    setIsListingHide(true);
    setNoRecordErrorMsg("");
    /*set default year, after reset*/
    setMultiCrop(null);
    setPreSelectedArray([]);
    setDisableContractField(true);
    setDisablePreSelected(true);
    setMultiContId(null); /*set null to contract filter */
    setContractPreselect([]);
    setMinMaxYear({ maxYear: "", minYear: "" });
  };

  const handlerDate = (param, type) => {
    let startFulldate = "";
    let endFulldate = "";
    if (type == "start") {
      setendMinDate(param);
      setStartDate(param);
      startFulldate = moment(param).format("YYYY/MM/DD");
      endFulldate = moment(endDate).format("YYYY/MM/DD");
    } else if (type == "end") {
      setEndDate(param);
      startFulldate = moment(startDate).format("YYYY/MM/DD");
      endFulldate = moment(param).format("YYYY/MM/DD");
    }
    setMultiValue((multiValue) => ({
      ...multiValue,
      ["datefrom"]: startFulldate,
      ["dateto"]: endFulldate,
    }));
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const ParameterDataGetter = (reqData = {}) => {
    const MasterData = {
      currentYear: {
        key: "Crop Year",
        value: "",
        index: 0,
      },
      contract_id: {
        key: "Contract Number",
        value: "",
        index: 1,
      },
      bognames: {
        key: "Bog Name",
        value: "",
        index: 2,
      },
      harvestbeds: {
        key: "Bed Name",
        value: "",
        index: 3,
      },
      varityids: {
        key: "Variety",
        value: "",
        index: 4,
      },
      datefrom: {
        key: "Date From",
        value: "",
        index: 5,
      },
      dateto: {
        key: "Date To",
        value: "",
        index: 6,
      },
    };
    try {
      const ReturnValueLogic = (param = null) => {
        if (IsStringChecker(param)) {
          const data = _.get(MasterData, param, {});
          const { key, value } = data;
          const reqValue = _.get(reqData, param, null) || value;
          switch (param) {
            case "currentYear":
              return { A: `Parameters: `, B: `${key}: ${reqValue}` };
            default:
              return { B: `${key}: ${reqValue}` };
          }
        } else {
          return { B: `` };
        }
      };

      if (IsObjectChecker(reqData) && IsObjectChecker(MasterData)) {
        const values = Object.values(reqData);
        if (IsArrayNotEmpty(values)) {
          const returnArr = [];
          for (const key in MasterData) {
            const index = _.get(MasterData, `${key}.index`);
            if (IsNumberChecker(index)) {
              _.set(returnArr, index, ReturnValueLogic(key));
            }
          }
          returnArr.push(ReturnValueLogic());
          return returnArr;
        }
        return [];
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return (
    <>
      {/*<!-- Beds block starts -->*/}
      {(loader || status === "fetching") && (
        <div className="zindex10000">
          {" "}
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </div>
      )}
      {/*<!-- Filter Dropdown starts -->*/}
      <nav className="navbar navbar-expand-lg p-0 mt-2 beds_table ">
        <div className={`cancel_reset ${isMobFilter ? " show " : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 p-2 border-1 white_bg filter_dropdown border-radius-6 ${
            isMobFilter ? " active " : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap2"
          aria-controls="selectWrap2"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>

        <div
          className={`collapse navbar-collapse selectWrapBlock ${
            isMobFilter ? " show " : ""
          }`}
          id="selectWrap2"
        >
          <div className="row">
            <div className="col-12 col-lg-3 hide_first_option">
              <div className="crop_year_label custom_dropdown">
                {reloadCropyear && (
                  <MultiDropDown
                    optionList={listCropYear}
                    prompt="Select"
                    value={multiCrop}
                    onChange={(val, selectedItem) =>
                      handlerCropYear(val, selectedItem, "selected")
                    }
                    label="cropyear"
                    usestate={setMultiCrop}
                    disabled=""
                    onDelete={(val, removedItem) =>
                      handlerCropYear(val, removedItem, "deSelected")
                    }
                    preSelectedOpt={preSelectedArray}
                    disablePreSelected={disablePreSelected}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown">
                {reloadcontract && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) => handlerContract(val, "changeEvent", filterItems)}
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled={disableContractField}
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    optionNotFound={{ contract_id: noContractsFound }}
                    preSelectedOpt={contractPreselect}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="bog_name custom_dropdown">
                {/*<!-- bognames -->*/}
                <MultiDropDown
                  optionList={bogNameOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "bogname"
                    )
                  }
                  label="bognames"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "bogname"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="harvest_bed custom_dropdown">
                {/*<!-- harvestbeds -->*/}
                <MultiDropDown
                  optionList={harvestbedOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "harvestbed"
                    )
                  }
                  label="harvestbeds"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "harvestbed"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="variety custom_dropdown">
                {/*<!-- variety -->*/}
                <MultiDropDown
                  showTitle={true}
                  optionList={verityOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "varityid"
                    )
                  }
                  label="varietyids"
                  usestate={setMultiValue}
                  disabled={disabled}
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "varityid"
                    )
                  }
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_from beds_date_from">
                <DatePickerComp
                  id="datepickerFromDDBed"
                  selected={startDate}
                  onChange={(date) => handlerDate(date, "start")}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  minDate={fromMinDate} //{new Date(),10}//{new Date(),10}
                  maxDate={endDate ? endDate : new Date()} //{new Date()}
                />
                <label htmlFor="datepickerFromDDBed">
                  Earliest Delivery Date{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_to beds_date_to">
                <DatePickerComp
                  id="datepickerToDDBed"
                  selected={endDate}
                  onChange={(date) => handlerDate(date, "end")}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  startDate={endMinDate}
                  minDate={endMinDate} //{new Date(),10}
                  maxDate={endMaxDate ? endMaxDate : new Date()}
                  showDisabledMonthNavigation
                />
                <label htmlFor="datepickerToDDBed">
                  Latest Delivery Date{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
          </div>
          {/*<!-- Clear and search button starts -->*/}
          <div className="row">
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}{" "}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
          {/*<!-- Clear and search button ends -->*/}
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? " show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>

      {/*<!-- Filter Dropdown ends -->*/}
      {isListingHide == false ? (
        <>
          {/*<!-- Beds grid starts -->*/}
          <div className="row">
            <div className="col-12 col-lg-6">
              {/*<!-- <h3>Delivery Data by Receipt</h3> -->*/}
            </div>
            <div className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} `}>
              <div className="float-end mb-2">
                <span>
                  <PdfFile pdfData={PDFExport} fileName="BedsData" />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="csv"
                    exportData={exportItems}
                    fileName="BedsData"
                    showParameter={true}
                    parameterData={exportParameter}
                    formatArray={formatArray}
                  />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="xlsx"
                    exportData={exportItems}
                    fileName="BedsData"
                    showParameter={true}
                    parameterData={exportParameter}
                    formatArray={formatArray}
                  />
                </span>
              </div>
            </div>
          </div>

          {/*<!-- Sticky Header Table starts -->*/}
          {!displayAccordian && (
            <div
              className={`visible_print_mob_desktop hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space d-none d-lg-block`}
            >
              <DataGridPagination
                uniqueIdentifier="Id"
                gridData={gridItems}
                columns={columns}
                isPagination={true}
                itemsPerPage={10}
                columnResize={false}
                dynamicHeight={50}
                dynamicHeaderHeight={90}
                classNames="contain-block-size-reset top-mspace-20 bot-mspace-20 border-radius-6"
              />
            </div>
          )}
          {/*<!-- Sticky header table ends -->*/}
          {/*<!-- Responsive table starts -->*/}
          <div
            className={`hor-space-20 top-space-20 border-radius-6 bot-mspace-20 overflow-auto accordion_tabs_bg_space mob-space-0 ${
              !displayAccordian ? "d-lg-none" : "desktop-accordion-table"
            }`}
          >
            <div className="delivery_approval_mob_acc mobile-accordion accordion-space beds_table accordion-fonts">
              {isListingHide == false && (
                <>
                  {" "}
                  <DDBedsMobile listingData={mobileItems} />
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
    </>
  );
};

export default DDBeds;
