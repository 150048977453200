import React, { Fragment, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import DataGridPagination from "../../global/dataGridPagination";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useMsal } from "@azure/msal-react";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import { roleCBDecisionMaker as CBDecisionMaker } from "../../../utils/roles";
const MyPeopleDetails = (props) => {
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  const { roleCBDecisionMaker = false, myPeopleDetailData = [] } = props;

  const [myPeopleDetails, setMyPeopleDetails] = useState({});
  const [sortingOrder, setSortingOrder] = useState([]);
  const [accordionSet, setAccordionSet] = useState("");
  const decoratedOnClick = useAccordionButton("", (index) => {
    if (window.innerWidth > 992) {
      sortingOrder[index].isOpen = sortingOrder[index]?.isOpen ? false : true;
      setAccordionSet(index);
    }
  });
  const [displayAccordian, setDisplayAccordian] =useState(false);
  useEffect(() => {
    const data = myPeopleDetailData
      ?.map((item, index) => {
        item.ItemId = index;
        return item;
      })
      ?.filter((item) => !item.Deceased);
    const dataGroup = _.groupBy(data, "PersonId");
    const userData = _.get(dataGroup, personId, {});
    let filterData = {};
    if (roleCBDecisionMaker) {
      const filteredData = FilterContractByRole(data, accounts, [
        CBDecisionMaker,
      ]);
      const filteredDataGroup = _.groupBy(filteredData, "PersonId");
      filteredDataGroup[personId] = userData;
      filterData = filteredDataGroup;
    } else {
      const masterTemp = {};
      masterTemp[personId] = userData;
      filterData = masterTemp;
      // console.log({temp, masterTemp});
    }
    // console.log({ filterData });
    setMyPeopleDetails(filterData);
    let sort = [];
    Object.keys(filterData).map((item) => {
      sort.push(filterData[item][0]);
    });
    sort = _.orderBy(sort, ["LastName"], ["asc"]);
    // console.log({ sort });
    setSortingOrder(sort);
    setDisplayAccordian(localStorage.getItem("safariLowerVersion") === 'true' || false);
  }, [props]);
  //Defining Grid Column
  const columns = [
    {
      key: "RelationshipNumber",
      name: "Contract #",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-center",
    },
    {
      key: "Name",
      name: "Contract Name",
      width: 100,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
    },
    {
      key: "ContractStatusDesc",
      name: "Contract Status",
      width: 80,
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping text-lg-center",
    },
    {
      key: "DescriptionMerged",
      name: "Position",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping",
      width: 490,
      formatter(props) {
        return <>{props.row.DescriptionMerged || "-"}</>;
      },
    },
    {
      key: "ExtranetRole",
      name: "Extranet Security Role",
      headerCellClass: "enablesTextWrappingHeading",
      cellClass: "enablesTextWrapping ",
      width: 300,
      formatter(props) {
        return <>{props.row.ExtranetRole?.replace(/,/g, ", ") || "-"}</>;
      },
    },
  ];
  const combineData = (data, con) => {
    const descriptionData = _.groupBy(data, "RelationshipId");
    let buildTableData = [],
      description = [];
    Object.keys(descriptionData).map((i, parentIndex) => {
      description = [];
      descriptionData[i].map((item, index) => {
        index === 0 && buildTableData.push(descriptionData[i][index]);
        description.push(item.Description);
      });
      description = _.uniqWith([...description], _.isEqual);
      buildTableData[parentIndex].DescriptionMerged = description
        ?.toString()
        ?.replace(/,/g, ", ");
    });
    buildTableData = buildTableData.filter((a) => {
      return [1000, 1001].includes(a.ContractStatus);
    });
    buildTableData = _.orderBy(
      buildTableData,
      ["ContractStatus", "RelationshipNumber"],
      ["asc", "asc"]
    );
    return (
      <>
        {con && !displayAccordian && (
          <div className="hor-space-20 top-space-20 light_bg  bot-mspace-20 overflow-auto  d-none d-lg-block receipt_table max_content_table">
            <DataGridPagination
              uniqueIdentifier="ItemId"
              gridData={buildTableData}
              columns={columns}
              isPagination={false}
              itemsPerPage={10}
              columnResize={false}
              classNames="contain-block-size-reset bot-mspace-20 border-radius-6 accordion_tabs_bg_space summery-right-aligh"
              autoRowHeight={true}
            />
          </div>
        )}
        <div
          className={`hor-space-20 top-space-20 light_bg border-radius-6 bot-mspace-20 accordion_tabs_bg_space mob-space-0 mypeople-search ${!displayAccordian ? 'd-lg-none' : 'desktop-accordion-table'}`}
        >
          <div className="delivery_approval_mob_acc mobile-accordion accordion-space accordion-fonts">
            <Accordion className="" defaultActiveKey="0">
              {buildTableData.map((data, index) => (
                <Fragment key={index}>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      Contract # : {data.RelationshipNumber} <br />
                      Contract Name : {data.Name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tbody">
                        <div className="tr">
                          <div className="td"></div>
                          <div className="td" data-header="Contract #:">
                            {data.RelationshipNumber || "-"}
                          </div>
                          <div className="td" data-header="Contract Name:">
                            {data.Name || "-"}
                          </div>
                          <div className="td" data-header="Contract Status:">
                            {data.ContractStatusDesc || "-"}
                          </div>
                          <div className="td" data-header="Position:">
                            {data.DescriptionMerged || "-"}
                          </div>
                          <div
                            className="td"
                            data-header="Extranet Security Role:"
                          >
                            {data?.ExtranetRole?.replace(/,/g, ", ") || "-"}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Fragment>
              ))}
            </Accordion>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="my-people-accordion accordion-space">
        <Accordion className="" defaultActiveKey="0" alwaysOpen>
          {sortingOrder.map((item, index) => {
            if (
              myPeopleDetails[item.PersonId].filter(
                (i) => i.ContractStatus !== 1003
              ).length > 0
            )
              return (
                <Fragment key={index}>
                  <Accordion.Item
                    eventKey={index}
                    onClick={() => decoratedOnClick(index)}
                  >
                    <Accordion.Header className="accordion-header-desktop-font-16 fw-bold mb-md-0">
                      {`Name: ${myPeopleDetails[item.PersonId][0].FirstName} ${
                        myPeopleDetails[item.PersonId][0].MiddleName !== null
                          ? myPeopleDetails[item.PersonId][0].MiddleName
                          : ""
                      } ${myPeopleDetails[item.PersonId][0].LastName}`}
                      <br />
                      {`Email: ${myPeopleDetails[item.PersonId][0].Email}`}
                    </Accordion.Header>
                    <Accordion.Body>
                      {combineData(
                        myPeopleDetails[item.PersonId],
                        index === accordionSet || item?.isOpen
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Fragment>
              );
          })}
        </Accordion>
      </div>
    </>
  );
};
export default MyPeopleDetails;
