/**
 * @component_Name : RecentDelivery - RecentDelivery component
 * @description : Show RecentDelivery
 * @company : Photon
 * @author : Ashish Ranjan/05-06-2022
 **/
import React, { useState, useEffect, useCallback } from "react";
import "../../../css/loading.css";
import { useMsal } from "@azure/msal-react";
import { gbsDataCommonMethod } from "../../../services/gbsData";
import MultiDropDown from "../../selectBox/multiDropDown";
import RecentDeliveryPagination from "./recentDeliveryPagination";
import WhiteCircleLoader from "../../whiteCircleLoader";
import RecentDeliveryMobile from "./recentDeliveryMobile";
import {
  trailZeros,
  getFullDate,
  setOptions,
  setObjToString,
  IsTokenChecker,
  IsNumberChecker,
  IsStringChecker,
  IsArrayNotEmpty,
  IsObjectChecker,
  HandleErrorTrackAndToast,
  toDecimal,
  NumConverter,
} from "../../../utils/helper";
import { cascadeFilterFunc } from "../../../utils/cascadeFilter";
import { ConstantsVar } from "../../../utils/constants";
import Loading from "../../global/loading";
import "react-datepicker/dist/react-datepicker.css";
import { FilterContractByRole, ImpersonationAccountsSwap } from "../../../permissionWrapper";
import useActiveCropYear from "../../customHooks/useActiveCropYear";
import PdfFile from "../../exportFile/pdfFile";
import ExportFile from "../../exportFile/exportFile";
import moment from "moment";
import _ from "lodash";
import { useDispatch } from "react-redux";
import DatePickerComp from "../../global/datepicker";

const RecentDelivery = (props) => {
  const { DropDownCBRoles = [], noContractsFound = "", RoleCBPrivileges = {} } = props;

  const state = useActiveCropYear();
  const { activeCropYear: currentYear = null, reducerToken: tokenId = null } =
    state;

  const dispatch = useDispatch();
  const { accounts:msal_accounts } = useMsal();
  const accounts = ImpersonationAccountsSwap(msal_accounts);
  const personId = _.get(accounts, "0.idTokenClaims.gbsPersonId", null);
  const [harvestData, setHarvestData] = useState({});
  const [imgData, setImgData] = useState([]);
  const [harvestArray, setHarvestArray] = useState([]);
  const [multiContId, setMultiContId] = useState([]);
  const [disabled, setDisabled] = useState("disabled");
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isMobFilter, setisMobFilter] = useState(false);
  const [beryLoading, setBeryLoading] = useState(false);
  const [multiValue, setMultiValue] = useState({});
  const [inputDelivery, setInputDelivery] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isListingHide, setIsListingHide] = useState(true);
  const [noRecordErrorMsg, setNoRecordErrorMsg] = useState("");
  const [isReset, setIsReset] = useState(true);
  const [minMaxYear, setMinMaxYear] = useState([]);

  const [filterItems, setFilterItems] = useState([]);
  const [ContractOption, setContractOption] = useState([]);
  const [bogNameOption, setBogNameOption] = useState([]);
  const [harvestbedOption, setHarvestbedOption] = useState([]);
  const [verityOption, setVerityOption] = useState([]);

  const [exportItems, setExportItems] = useState([]);
  const [exportParameter, setExportParameter] = useState([]);
  const [hideExport, setHideExport] = useState(false);

  const [preSelectedContract, setPreSelectedContract] = useState([]);
  const [reloadMultiSelect, setReloadMultiSelect] = useState(true);

  const getBeds = (arr) => {
    const HarvestBed = arr.map((item) => item["HarvestBed"]);
    const deliveryWeight = arr.reduce(
      (prev, row) => prev + row.DeliveryWeight,
      0
    );
    const totalBarrels = arr.reduce((prev, row) => prev + row.TotalBarrels, 0);
    return {
      bed: HarvestBed.join("/"),
      id: arr[0].LoadDeliveryTicketNumber,
      deliveryWeight: deliveryWeight,
      totalBarrels: totalBarrels,
    };
  };
  useEffect(() => {
    if (currentYear) {
      let minYear = minMaxYear["minYear"];
      let maxYear = minMaxYear["maxYear"];
      if (minYear) {
        setStartDate(
          new Date(ConstantsVar.startingDateOfYear + ", " + minYear)
        );
      } else {
        setStartDate(new Date());
      }
      if (maxYear) {
        if (maxYear == currentYear) {
          setEndDate(new Date());
        } else {
          setEndDate(new Date(ConstantsVar.endingDateOfYear + ", " + maxYear));
        }
      }
    }
  }, [minMaxYear, currentYear]);

  useEffect(() => {
    return () => {
      HandleErrorTrackAndToast(false, false, "empty", dispatch, false);
    };
  }, []);

  const ParameterDataGetter = (reqData = {}) => {
    const MasterData = {
      currentYear: {
        key: "Crop Year",
        value: "",
        index: 0,
      },
      contract_id: {
        key: "Contract Number",
        value: "",
        index: 1,
      },
      bognames: {
        key: "Bog Name",
        value: "",
        index: 2,
      },
      harvestbeds: {
        key: "Bed Name",
        value: "",
        index: 3,
      },
      varityids: {
        key: "Variety",
        value: "",
        index: 4,
      },
      deliverynumber: {
        key: "Delivery Number",
        value: "",
        index: 5,
      },
      datefrom: {
        key: "Date From",
        value: "",
        index: 6,
      },
      dateto: {
        key: "Date To",
        value: "",
        index: 7,
      },
    };
    try {
      const ReturnValueLogic = (param = null) => {
        if (IsStringChecker(param)) {
          const data = _.get(MasterData, param, {});
          const { key, value } = data;
          const reqValue = _.get(reqData, param, null) || value;
          switch (param) {
            case "currentYear":
              return { A: `Parameters: `, B: `${key}: ${reqValue}` };
            default:
              return { B: `${key}: ${reqValue}` };
          }
        } else {
          return { B: `` };
        }
      };

      if (IsObjectChecker(reqData) && IsObjectChecker(MasterData)) {
        const values = Object.values(reqData);
        if (IsArrayNotEmpty(values)) {
          const returnArr = [];
          for (const key in MasterData) {
            const index = _.get(MasterData, `${key}.index`);
            if (IsNumberChecker(index)) {
              _.set(returnArr, index, ReturnValueLogic(key));
            }
          }
          returnArr.push(ReturnValueLogic());
          return returnArr;
        }
        return [];
      }
      return [];
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return [];
    }
  };

  useEffect(() => {
    const fetchContract = async () => {
      await gbsDataCommonMethod(
        tokenId,
        accounts,
        `persons/${personId}/contracts`
      )
        .then((resp) => {
          const data = _.get(resp, "data", []);
          const filteredData = IsArrayNotEmpty(data)
            ? FilterContractByRole(data, accounts, DropDownCBRoles)
            : [];
          if (IsArrayNotEmpty(filteredData)) {
            const contractId = filteredData
              .map((x) => x["RelationshipId"])
              ?.toString();
            let url = `deliveries/by-receipt?cropyear=${currentYear}&contractid=${contractId}&filtersonly=1`;
            receiptFilterData(url, filteredData);
            let fetchUrl = `deliveries/by-receipt?contractid=${contractId}&cropyear=${currentYear}&limit=15`;
            fetchHarvestData(fetchUrl);

            const contractNum = filteredData
              .map((x) => x["RelationshipNumber"])
              ?.toString();
            setExportParameter(
              ParameterDataGetter({
                currentYear,
                contract_id: contractNum,
                datefrom: DateProvider(startDate),
                dateto: DateProvider(endDate),
              })
            );
          }
        })

        .catch((error) => {
          /*setBeryLoading(false);*/
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
    };
    if (IsTokenChecker(tokenId) && currentYear) {
      setVerityOption([]);
      setBogNameOption([]);
      setHarvestbedOption([]);
      setBeryLoading(true);
      setMinMaxYear({ maxYear: currentYear, minYear: currentYear });
      fetchContract();
    }
  }, [tokenId, currentYear]);

  const receiptFilterData = async (url, contractData) => {
    if (IsTokenChecker(tokenId)) {
      setBeryLoading(true);
      const fetchApiUrl = url;
      await gbsDataCommonMethod(tokenId, accounts, fetchApiUrl)
        .then((resp) => {
          let resultData = _.get(resp, "data", []);
          let filteredData = FilterContractByRole(
            resultData,
            accounts,
            DropDownCBRoles
          );
          if (
            IsArrayNotEmpty(filteredData) &&
            resp.data !== "Contract and CropYear must be provided"
          ) {
            const temp = filteredData.map((li) => {
              return {
                id: li["RelationshipId"],
                name: `${li["RelationshipNumber"]} ${li["Name"]}`,
                number: li["RelationshipNumber"],
              };
            });
            setContractOption(_.orderBy(_.uniqBy(temp, "id"), ["name"]));
            setFilterItems(filteredData);
            if (contractData.length === 1) {
              const defaultContractNumber = contractData[0]?.RelationshipId;
              if (defaultContractNumber) {
                setReloadMultiSelect(false);
                setTimeout(() => {
                  setPreSelectedContract([`${defaultContractNumber}`]);
                  setMultiContId({
                    contract_id: `${defaultContractNumber}`,
                  });
                  setDisabled(false);
                  setIsReset(false);
                  setSearchBtnDisabled(false);
                  let filter = filteredData.filter((item) => {
                    return (
                      `${defaultContractNumber}`.toLowerCase()
                        ?.indexOf(
                          item?.RelationshipId?.toString().toLowerCase()
                        ) !== -1
                    );
                  });
                  console.log({filteredData, contractData, filter});
                  setIsListingHide(true);
                  setBogNameOption(setOptions(filter, "BogName", "BogName"));
                  setHarvestbedOption(
                    setOptions(filter, "HarvestBed", "HarvestBed")
                  );
                  setVerityOption(
                    setOptions(filter, "VarietyId", "VarietyDescription")
                  );
                  setReloadMultiSelect(true);
                }, 0);
              }
            }
          } else {
            setContractOption([]);
            setBogNameOption([]);
            setHarvestbedOption([]);
            setVerityOption([]);
            setDisabled(true);
            setFilterItems([]);
          }
          setBeryLoading(false);
        })
        .catch((error) => {
          setBeryLoading(false);
          setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        });
      setisMobFilter(false);
    }
  };

  // Contract select change event
  const handlerContract = (val) => {
    setMultiValue(null);
    setHarvestData({});
    setImgData([]);
    setHarvestArray([]);
    let filter = filterItems.filter((item) => {
      return (
        setObjToString(val)
          ?.toLowerCase()
          ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
      );
    });
    setIsReset(false);
    setDisabled(false);
    setSearchBtnDisabled(false);
    setIsListingHide(true);
    setBogNameOption(setOptions(filter, "BogName", "BogName"));
    setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
    setVerityOption(setOptions(filter, "VarietyId", "VarietyDescription"));
  };

  const handlerContractDel = (selectedList, removedItem) => {
    setMultiValue(null);
    setHarvestData({});
    setImgData([]);
    setHarvestArray([]);
    setIsListingHide(true);
    if (selectedList.length === 0 || removedItem.id === "all") {
      setDisabled(true);
      setIsReset(true); /*disabled Reset button*/
      setMultiContId(null); /*set null to contract filter */
      setSearchBtnDisabled(true);
      setInputDelivery("");
    } else {
      let filter = filterItems.filter((item) => {
        return (
          setObjToString(selectedList)
            ?.toLowerCase()
            ?.indexOf(item?.RelationshipId?.toString().toLowerCase()) !== -1
        );
      });
      setIsReset(false);
      setDisabled(false);
      setSearchBtnDisabled(false);
      setBogNameOption(setOptions(filter, "BogName", "BogName"));
      setHarvestbedOption(setOptions(filter, "HarvestBed", "HarvestBed"));
      setVerityOption(setOptions(filter, "VarietyId", "VarietyDescription"));
    }
  };

  const fetchImage = async (param) => {
    try {
      const imageData = await Promise.all(
        param.map(async (getData) => {
          const { Id, LoadDeliveryTicketNumberNoMemo, CropYear, LocationCode } =
            getData;
          const id = trailZeros(LoadDeliveryTicketNumberNoMemo, 4);
          return await gbsDataCommonMethod(
            tokenId,
            accounts,
            `deliveries/photo-retain/${CropYear}-${LocationCode}/${id}`,
            "GET",
            {},
            { "Content-Type": "application/json", responseType: "blob" }
          )
            .then((resp) => {
              const testBlob = resp.data;
              return { id: Id, img: testBlob };
            })
            .catch((error) => {
              HandleErrorTrackAndToast(error, true, "replace", dispatch);
              return {};
            });
        })
      );
      setImgData([...imageData]);
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      setImgData([]);
    }
  };

  const IsBooleanChecker = (bool = null) => {
    try {
      return typeof bool === "boolean";
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return false;
    }
  };

  const SafeFieldChecker = (value = null) => {
    try {
      if (IsBooleanChecker(value)) {
        return value ? "Y" : "N";
      } else if (IsStringChecker(value)) {
        return value;
      } else if (IsNumberChecker(value)) {
        return value;
      } else {
        return ``;
      }
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
      return "";
    }
  };

  const remainFirstCharacter = (string, val = 0) => {
    try {
      return string.charAt(val);
    } catch (e) {
      HandleErrorTrackAndToast(e, true);
    }
  };

  const fetchHarvestData = async (apiUrl) => {
    if (IsTokenChecker(tokenId)) {
      setBeryLoading(true);
      setLoading(true); /*set loader to search button*/
      await gbsDataCommonMethod(tokenId, accounts, apiUrl)
        .then((resp) => {
          const resultData = _.get(resp, "data", []);
          let sortedData = _.groupBy(resultData, "LoadDeliveryTicketNumber");
          let temp = [];
          let sortedArray = [];
          Object.keys(sortedData).map((item) => {
            temp[`${item}`] = getBeds(sortedData[item]);
            sortedArray.push(sortedData[item][0]);
          });
          let sortByInTime = _.orderBy(sortedArray, ["IncomingTime"], ["desc"]);
          setHarvestArray(temp);
          setHarvestData(sortByInTime);
          if (IsArrayNotEmpty(resultData)) {
            fetchImage(resultData);
            setIsListingHide(false);
            setNoRecordErrorMsg("");
            setHideExport(false);
            const exportData = resultData.map((item) => {
              const {
                CropYear,
                LocationCode,
                LoadDeliveryTicketNumberNoMemo,
                HarvestBed,
                Percentage,
                IncomingTime,
                OutgoingTime,
                ReceiptIdentifier,
                ReceiptStatus,
                ProcessedFreshType,
                DowngradedFresh,
                HasIncident,
                RelationshipNumber,
                Name,
                Name2,
                Pool,
                Region,
                StateAbbreviation,
                LegacyVarietyCode,
                ChaffScreenedType,
                WetDry,
                StandardPie,
                GrossWeight,
                TareWeight,
                NetWeight,
                ContainerWeightSum,
                DeliveryWeight,
                UsableBarrels,
                ShrinkBarrels,
                TotalBarrels,
                ExportIncentive,
                WhiteIncentive,
                EarlySequencedIncentive,
                LateSequencedIncentive,
                GlobalGap,
                Organic,
                DomesticAssessment,
                Carrier,
                ShippingMemo,
                DropTrailer,
                SampleWeight,
                DryWeight,
                CleanDryWeight,
                CupWeight,
                PoorWeight,
                Brix,
                Tacy,
                PercentDryTrash,
                PercentMoist,
                PercentTotalTrash,
                PercentPoor,
                PercentUsable,
                ScreeningCharge,
                TrashCharge,
                TruckingAllowance,
                TestDate,
                TestedBy,
                TestBox,
                KeepingQualityAtReceipt,
                KeepingQualityThreeWeek,
                KeepingQualitySixWeek,
                LoadInfoComments,
                LabTestComments,
                PoorCode,
                UncoloredWeight,
                FirmnessAverage,
                HighContaminatedAssessment,
                HighContaminatedHandlingFeeAssessment,
                HighContaminatedCalcBarrelAdjustment,
                ColorTacyIncentive,
                FirmnessAverageIncentive,
                OrganicIncentive,
                GlobalGapFreshIncentive,
                ColorAssessmentNoOffsets,
                PercentGreaterThanHalf,
                SizePremiumIncentive,
                OnHold,
                ClassOne,
                ClassTwo,
                ClassThree,
                ClassFour,
                ClassFive,
                PercentClassOne,
              } = item;
              const returnExport = {
                "Crop Year": SafeFieldChecker(CropYear),
                Facility: SafeFieldChecker(LocationCode),
                "Delivery Number": SafeFieldChecker(
                  LoadDeliveryTicketNumberNoMemo
                ),
                Bed: SafeFieldChecker(HarvestBed),
                Percentage: SafeFieldChecker(Percentage),
                "Date In": IncomingTime
                  ? moment.utc(IncomingTime).format(ConstantsVar.dateFormate)
                  : "",
                "Time In": IncomingTime
                  ? moment.utc(IncomingTime).format(ConstantsVar.timeFormate)
                  : "",
                "Time Out": OutgoingTime
                  ? moment.utc(OutgoingTime).format(ConstantsVar.timeFormate)
                  : "",
                "Receipt Identifier": SafeFieldChecker(ReceiptIdentifier),
                Status: SafeFieldChecker(ReceiptStatus),
                "Fresh Process": remainFirstCharacter(
                  SafeFieldChecker(ProcessedFreshType)
                ),
                Downgraded: SafeFieldChecker(DowngradedFresh),
                Incident: SafeFieldChecker(HasIncident),
                "Contract Number": SafeFieldChecker(RelationshipNumber),
                "Contract Name": SafeFieldChecker(Name),
                "Contract Name2": SafeFieldChecker(Name2),
                Pool: SafeFieldChecker(Pool),
                Region: SafeFieldChecker(Region),
                State: SafeFieldChecker(StateAbbreviation),
                Variety: SafeFieldChecker(LegacyVarietyCode),
                "Chaff Screen": SafeFieldChecker(ChaffScreenedType),
                "Wet Dry": SafeFieldChecker(WetDry),
                "Standard Pie": SafeFieldChecker(StandardPie),
                "Gross Weight": toDecimal(
                  Math.round(NumConverter(GrossWeight)),
                  0,
                  "",
                  0
                ),
                "Tare Weight": toDecimal(
                  Math.round(NumConverter(TareWeight)),
                  0,
                  "",
                  0
                ),
                "Net Weight": toDecimal(
                  Math.round(NumConverter(NetWeight)),
                  0,
                  "",
                  0
                ),
                "Container Weight Sum": toDecimal(
                  Math.round(NumConverter(ContainerWeightSum)),
                  0,
                  "",
                  0
                ),
                "Delivery Weight": toDecimal(
                  Math.round(NumConverter(DeliveryWeight)),
                  0,
                  "",
                  0
                ),
                "Usable Barrels": toDecimal(
                  NumConverter(UsableBarrels)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "Shrink Barrels": toDecimal(
                  NumConverter(ShrinkBarrels)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "Total Barrels": toDecimal(
                  NumConverter(TotalBarrels)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "Export Incentive": ExportIncentive ? "Y" : "N",
                "White Incentive": WhiteIncentive ? "Y" : "N",
                "Early Sequence Incentive": EarlySequencedIncentive ? "Y" : "N",
                "Late Sequence Incentive": LateSequencedIncentive ? "Y" : "N",
                "Global Gap": SafeFieldChecker(GlobalGap),
                Organic: SafeFieldChecker(Organic),
                "Domestic Assessment": DomesticAssessment ? "Y" : "N",
                Carrier: SafeFieldChecker(Carrier),
                "Shipping Memo": SafeFieldChecker(ShippingMemo),
                "Drop Trailer": SafeFieldChecker(DropTrailer),
                "Delivery Pool": "n/a",
                "Sample Weight": toDecimal(
                  Math.round(NumConverter(SampleWeight)),
                  0,
                  "",
                  0
                ),
                "Dry Weight": toDecimal(
                  Math.round(NumConverter(DryWeight)),
                  0,
                  "",
                  0
                ),
                "Lab Clean Dry Weight": toDecimal(
                  Math.round(NumConverter(CleanDryWeight)),
                  0,
                  "",
                  0
                ),
                "Cup Weight": toDecimal(
                  Math.round(NumConverter(CupWeight)),
                  0,
                  "",
                  0
                ),
                "Poor Weight": toDecimal(
                  NumConverter(PoorWeight)?.toFixed(2),
                  2,
                  "",
                  0
                ),
                Brix: toDecimal(NumConverter(Brix)?.toFixed(4), 4, "", 0),
                Tacy: toDecimal(NumConverter(Tacy)?.toFixed(4), 4, "", 0),
                "% Dry Trash": toDecimal(
                  NumConverter(PercentDryTrash)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "% Moist": toDecimal(
                  NumConverter(PercentMoist)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "% Total Trash": toDecimal(
                  NumConverter(PercentTotalTrash)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "% Poor": toDecimal(
                  NumConverter(PercentPoor)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "% Usable": toDecimal(
                  NumConverter(PercentUsable)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "Screen Charge": toDecimal(
                  NumConverter(ScreeningCharge)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "Trash Charge": toDecimal(
                  NumConverter(TrashCharge)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "Trucking Allowance": toDecimal(
                  NumConverter(TruckingAllowance)?.toFixed(4),
                  4,
                  "",
                  0
                ),
                "Test Date": TestDate
                  ? moment
                      .utc(TestDate)
                      .format(ConstantsVar.exportdateTimeFormate)
                  : "",
                "Test Time": TestDate
                  ? moment.utc(TestDate).format(ConstantsVar.timeFormate)
                  : "",
                "Test Tech": SafeFieldChecker(TestedBy),
                "Test Box": SafeFieldChecker(TestBox),
                "Keeping Quality At Receipt": SafeFieldChecker(
                  KeepingQualityAtReceipt
                ),
                "Keeping Quality At 3 Week": SafeFieldChecker(
                  KeepingQualityThreeWeek
                ),
                "Keeping Quality Late": SafeFieldChecker(KeepingQualitySixWeek),
                "Load Comment": SafeFieldChecker(LoadInfoComments),
                "Lab Test Comment": SafeFieldChecker(LabTestComments),
                "Poor Code": SafeFieldChecker(PoorCode),
                "Firmness Average": toDecimal(
                  Math.round(NumConverter(FirmnessAverage)),
                  0,
                  "",
                  0
                ),
                "Percent Uncolored": SafeFieldChecker(UncoloredWeight),
                "High Contaminated Assessment": SafeFieldChecker(
                  HighContaminatedAssessment
                ),
                "High Contaminated Handling Fee Assessment": SafeFieldChecker(
                  HighContaminatedHandlingFeeAssessment
                ),
                "High Contaminated Calculated Barrel Adjustment":
                  SafeFieldChecker(HighContaminatedCalcBarrelAdjustment),
                "Color Tacy Incentive": SafeFieldChecker(ColorTacyIncentive),
                "Firmness Average Incentive": SafeFieldChecker(
                  FirmnessAverageIncentive
                ),
                "Early Sequence Incentive Amount": SafeFieldChecker(
                  EarlySequencedIncentive
                ),
                "Late Sequence Incentive Amount": SafeFieldChecker(
                  LateSequencedIncentive
                ),
                "Organic Incentive": SafeFieldChecker(OrganicIncentive),
                "Global Gap Fresh Incentive": SafeFieldChecker(
                  GlobalGapFreshIncentive
                ),
                "Domestic Assessment Amount":
                  SafeFieldChecker(DomesticAssessment),
                "Color Assessment No Offsets": SafeFieldChecker(
                  ColorAssessmentNoOffsets
                ),
                "Percent Greater Than Half": toDecimal(
                  NumConverter(PercentGreaterThanHalf)?.toFixed(2),
                  2,
                  "",
                  0
                ),
                "Size Premium Incentive":
                  SafeFieldChecker(SizePremiumIncentive),
                "On Hold": SafeFieldChecker(OnHold),
                ClassOne: toDecimal(
                  NumConverter(ClassOne)?.toFixed(2),
                  2,
                  "",
                  0
                ),
                ClassTwo: toDecimal(
                  NumConverter(ClassTwo)?.toFixed(2),
                  2,
                  "",
                  0
                ),
                ClassThree: toDecimal(
                  NumConverter(ClassThree)?.toFixed(2),
                  2,
                  "",
                  0
                ),
                ClassFour: toDecimal(
                  NumConverter(ClassFour)?.toFixed(2),
                  2,
                  "",
                  0
                ),
                ClassFive: toDecimal(
                  NumConverter(ClassFive)?.toFixed(2),
                  2,
                  "",
                  0
                ),
                "Percent Class One": toDecimal(
                  NumConverter(PercentClassOne)?.toFixed(2),
                  2,
                  "",
                  0
                ),
              };
              return returnExport;
            });
            setExportItems(exportData);
          } else {
            setIsListingHide(true);
            setNoRecordErrorMsg(ConstantsVar.notFoundMsg);
            setHideExport(true);
            setExportItems([]);
            setExportParameter([]);
            setImgData([]);
          }
        })
        .catch((error) => {
          setHideExport(true);
          setExportItems([]);
          setExportParameter([]);
          setHarvestData({});
          setHarvestArray([]);
          HandleErrorTrackAndToast(error, true, "dispatch", dispatch);
        })
        .finally(() => {
          setBeryLoading(false);
          setLoading(false); /*remove loader from search button*/
          setisMobFilter(false);
        });
    }
  };

  const getVarieties = (temp = {}) => {
    try {
      const varities = _.get(temp, "varityids", "").split(",");
      return varities
        .reduce((acc, li) => {
          try {
            const found = verityOption.find((l) => l["id"] == li);
            if (found !== undefined) {
              return [...acc, found["name"]];
            } else {
              return acc;
            }
          } catch (error) {
            HandleErrorTrackAndToast(error, true);
            return [];
          }
        }, [])
        .join();
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return "";
    }
  };

  const getContracts = (temp = {}) => {
    try {
      const contracts = temp["contract_id"].split(",");
      return contracts
        .reduce((acc, li) => {
          try {
            const found = ContractOption.find((l) => l["id"] == li);
            if (found !== undefined) {
              return [...acc, found["number"]];
            } else {
              return acc;
            }
          } catch (error) {
            HandleErrorTrackAndToast(error, true);
            return [];
          }
        }, [])
        .join();
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return "";
    }
  };

  const DateProvider = (dat = null) => {
    try {
      return dat ? moment(dat).format(ConstantsVar.dateFormate) : "";
    } catch (error) {
      HandleErrorTrackAndToast(error, true);
      return "";
    }
  };

  const handlerSubmit = useCallback(() => {
    setHarvestData({});
    setImgData([]);
    const arrayQueryString = [];
    multiValue &&
      Object.keys(multiValue).map((i, index) => {
        multiValue[i] != ""
          ? (arrayQueryString[index] = `${i}=${multiValue[i]}`)
          : "";
      });

    let QueryString =
      arrayQueryString.length > 0 ? "&" + arrayQueryString.join("&") : "";
    QueryString = `${QueryString}&contractid=${multiContId["contract_id"]}&limit=15`; //894
    const apiUrl = `deliveries/by-receipt?cropyear=${currentYear}${QueryString}`;
    //setLoading(true); /*set loader to search button*/

    const reqData = {
      currentYear,
      contract_id: getContracts(multiContId),
      bognames: multiValue?.bognames,
      harvestbeds: multiValue?.harvestbeds,
      varityids: getVarieties(multiValue),
      deliverynumber: multiValue?.deliverynumber,
      datefrom: DateProvider(multiValue?.datefrom || startDate),
      dateto: DateProvider(multiValue?.dateto || endDate),
    };
    setExportParameter(
      ParameterDataGetter({
        ...reqData,
      })
    );
    fetchHarvestData(apiUrl);
  });

  const handleReset = (e) => {
    e.stopPropagation();
    setHarvestData({});
    setImgData([]);
    setSearchBtnDisabled(true); /*disabled search button*/
    setMultiContId(null);
    setIsReset(true); /*disabled Reset button*/
    setMultiValue(null); /*set null to all filter */
    setDisabled(true); /*disabled filter dropdown*/
    setStartDate();
    setEndDate();
    setInputDelivery("");
    setIsListingHide(true);
    setNoRecordErrorMsg("");
    setHideExport(true);
    setExportItems([]);
    setExportParameter([]);
  };

  const handlerMobFilter = (isfilter) => {
    isfilter == "filter" ? setisMobFilter(true) : setisMobFilter(false);
  };

  const handlerInput = (e) => {
    setMultiValue((multiValue) => ({
      ...multiValue,
      ["deliverynumber"]: e.target.value,
    }));
    setInputDelivery(e.target.value);
  };

  const handlerOtherFilter = (
    selectedList,
    currentItem,
    action,
    request = ""
  ) => {
    let multival = { ...multiValue };
    setHarvestData({});
    setImgData([]);
    setHarvestArray([]);

    const searchParam = {
      Contract_id: ["RelationshipId"],
      Bog_name: ["BogName", "BogName"],
      Harvest_bed: ["HarvestBed", "HarvestBed"],
      Varity_id: ["VarietyId", "VarietyDescription"],
    };
    let filter = [...filterItems];
    cascadeFilterFunc({
      filter,
      selectedList,
      action,
      request,
      multiContId,
      multival,
      searchParam,
      setBogNameOption,
      setHarvestbedOption,
      setVerityOption,
    });
  };

  const handlerDate = (param, type) => {
    let startFulldate = "";
    let endFulldate = "";
    if (type == "start") {
      setStartDate(param);
      startFulldate = getFullDate(param, "yyyy-mm-dd");
      endFulldate = getFullDate(endDate, "yyyy-mm-dd");
    } else if (type == "end") {
      setEndDate(param);
      startFulldate = getFullDate(startDate, "yyyy-mm-dd");
      endFulldate = getFullDate(param, "yyyy-mm-dd");
    }
    setMultiValue((multiValue) => ({
      ...multiValue,
      ["datefrom"]: startFulldate,
      ["dateto"]: endFulldate,
    }));
  };
  return (
    <>
      {/* <div id="collapseOne" className="accordion-collapse collapse mob-filter-block" aria-labelledby="headingOne" data-bs-parent="#accordionMyAcreage">
       <div className="accordion-body"> */}
      {/*<!-- Filter Dropdown starts -->*/}
      {beryLoading && (
        <>
          <div className="nav-mobile-overlay-display"></div>
          <Loading />
        </>
      )}
      <nav className="navbar navbar-expand-lg p-0 mt-2 mb-4 mb-lg-0">
        <div className={`cancel_reset ${isMobFilter ? "show" : ""}`}>
          <span
            className="float-start blue_color cancel_click"
            onClick={() => handlerMobFilter("cancel")}
          >
            Cancel
          </span>
          <span className="float-end blue_color fw-bold" onClick={handleReset}>
            Reset
          </span>
        </div>

        <a
          className={`navbar-brand d-block d-lg-none blue_color text-16 w-100 me-0 space-9 p-2 border-1 white_bg filter_dropdown border-radius-6  ${
            isMobFilter ? "active" : ""
          }`}
          data-bs-toggle="collapse"
          data-bs-target="#selectWrap"
          aria-controls="selectWrap"
          aria-expanded="false"
          aria-label="Toggle navigation"
          href="#"
          onClick={() => handlerMobFilter("filter")}
        >
          <span className="fw-bold">Filter</span>{" "}
          <span className="grey_color hide_text"> </span>
          <i className="fa fa-chevron-right float-end mt-1 position-relative r-5"></i>
        </a>
        <div
          className={`collapse navbar-collapse selectWrapBlock font-reset ${
            isMobFilter ? "show" : ""
          }`}
          id="selectWrap"
        >
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="contact_number_Label custom_dropdown mb-1">
                {/*<!-- <span>Contract Number</span> -->*/}
                {reloadMultiSelect && (
                  <MultiDropDown
                    optionList={ContractOption}
                    prompt="Select"
                    value={multiContId}
                    onChange={(val) => handlerContract(val)}
                    label="contract_id"
                    usestate={setMultiContId}
                    disabled=""
                    onDelete={(val, selectedItem) =>
                      handlerContractDel(val, selectedItem)
                    }
                    preSelectedOpt={preSelectedContract}
                    /* setPreSelectedArray={setPreSelectedArray} */
                    optionNotFound={{ contract_id: noContractsFound }}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="bog_name custom_dropdown">
                {/*<!-- <span>Bog Name</span> -->*/}
                <MultiDropDown
                  optionList={bogNameOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "bogname"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "bogname"
                    )
                  }
                  label="bognames"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="harvest_bed custom_dropdown">
                {/*<!-- <span>harvestbed</span> -->*/}
                <MultiDropDown
                  optionList={harvestbedOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "harvestbed"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "harvestbed"
                    )
                  }
                  label="harvestbeds"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="variety custom_dropdown">
                {/*<!-- <span>variety</span> -->*/}
                <MultiDropDown
                  optionList={verityOption}
                  prompt="Select"
                  value={multiValue}
                  onChange={(selectedList, selectedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      selectedItem,
                      "selected",
                      "varityid"
                    )
                  }
                  onDelete={(selectedList, removedItem) =>
                    handlerOtherFilter(
                      selectedList,
                      removedItem,
                      "deselected",
                      "varityid"
                    )
                  }
                  label="varityids"
                  usestate={setMultiValue}
                  disabled={disabled}
                />
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="delivery_shipping custom_dropdown">
                <span className="d-none d-lg-block">Delivery Number</span>
                <span className="d-block d-lg-none p-2 p-lg-0 fw-normal text-16">
                  Delivery Number:{" "}
                  <a className="fw-bold radio_box grey_text_color"></a>{" "}
                  <i className="fa fa-chevron-down p-2 px-1 blue_color position-relative float-end text-14 l_2"></i>
                </span>

                <div className="ps-4 ms-2 ps-lg-0 ms-lg-0 p-3 pt-0 p-lg-0 d-lg-block my_delivery_data_radio_button">
                  <input
                    type="text"
                    value={inputDelivery}
                    name="delivery_num"
                    placeholder=""
                    id="delivery_shipping_number"
                    disabled={disabled}
                    className="delivery_mob_no"
                    onChange={handlerInput}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_from">
                {/* <input type="text" className="datepicker_input form-control border-1" id="datepickerFrom" required placeholder="DD/MM/YYYY" /> */}
                <DatePickerComp
                  id="startDate"
                  selected={startDate}
                  onChange={(date) => handlerDate(date, "start")}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  minDate={startDate} //{new Date(),10}//{new Date(),10}
                  maxDate={endDate ? endDate : new Date()} //{new Date()}
                />
                <label htmlFor="datepickerFrom">
                  Date From{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="form-floating date_picker_aln cursor_pointer date_to">
                {/* <input type="text" className="datepicker_input form-control border-1" id="datepickerTo" required placeholder="DD/MM/YYYY" /> */}
                <DatePickerComp
                  id="endDate"
                  selected={endDate}
                  onChange={(date) => handlerDate(date, "end")}
                  placeholderText={ConstantsVar.dateFormate}
                  className="datepicker_input form-control border-1"
                  disabled={disabled}
                  startDate={startDate}
                  minDate={startDate} //{new Date(),10}
                  maxDate={endDate ? endDate : new Date()}
                  showDisabledMonthNavigation
                />
                <label htmlFor="datepickerTo">
                  Date To{" "}
                  <i className="fa fa-chevron-down p-1 blue_color position-relative r-5 float-end text-14 d-block d-lg-none"></i>{" "}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <button
                className="btn btn-primary text-16 float-end d-none d-lg-block"
                onClick={handlerSubmit}
                disabled={searchBtnDisabled}
              >
                Search {!loading ? "" : <WhiteCircleLoader />}
              </button>
              <button
                className="btn btn-default text-16 float-end me-2 d-none d-lg-block"
                onClick={handleReset}
                disabled={isReset}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div
          className={`bottom-0  filter_apply_button white_bg ${
            isMobFilter ? " show d-block " : " d-lg-none "
          }`}
        >
          <button
            className="btn btn-primary width-96 position-relative l-r-2 d-block d-lg-none"
            onClick={handlerSubmit}
            disabled={searchBtnDisabled}
          >
            Apply {!loading ? "" : <WhiteCircleLoader />}
          </button>
        </div>
      </nav>
      {isListingHide == false ? (
        <>
          <div className="row mt-2">
            <div className="col-12 col-lg-6">
              {/* <h3>Recent Delivery Data</h3> */}
            </div>
            <div className={`col-6 d-none ${hideExport ? "" : "d-lg-block"} `}>
              <div className="float-end mb-2">
                <span>
                  <PdfFile
                    pdfData={exportItems}
                    fileName="RecentDeliveryData"
                  />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="csv"
                    exportData={exportItems}
                    fileName="RecentDeliveryData"
                  />
                </span>
                <span>
                  <ExportFile
                    downloadFormate="xlsx"
                    exportData={exportItems}
                    fileName="RecentDeliveryData"
                    showParameter={true}
                    parameterData={exportParameter}
                  />
                </span>
              </div>
            </div>
          </div>
          {/*<!-- Filter Dropdown ends -->*/}
          <RecentDeliveryPagination
            itemsPerPage={3}
            RoleCBPrivileges={RoleCBPrivileges}
            listingData={harvestData}
            harvestArray={harvestArray}
            imgData={imgData}
          />
          {/*accordion*/}
          <div className="exhibit_maps_table exhibit_maps_table_my_harvest mb-2 d-block d-lg-none">
            <div className="mobile-accordion">
              <RecentDeliveryMobile
                RoleCBPrivileges={RoleCBPrivileges}
                listingData={harvestData}
                harvestArray={harvestArray}
                imgData={imgData}
              />
            </div>
          </div>
        </>
      ) : (
        noRecordErrorMsg != "" && (
          <div className="p-2 red_color text-center border-1 border-radius-6 mt-2">
            <i className="fa fa-info-circle pe-2"></i>
            {noRecordErrorMsg}
          </div>
        )
      )}
    </>
  );
};

export default RecentDelivery;
